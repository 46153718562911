import { useState } from 'react';
// import { useHistory } from "react-router-dom";

// ASSETS
import pic from '../../../../Assets/Capture.PNG';
import canvasImage from '../../../../Assets/birds.png'

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from './NavBarSimulationVerification';
import TestPage from './SimulationVerificationPage';

// REDUX
// import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../PagesShareStyle/PagesShareStyle';


const MainSimulationVerificationPage = ({ details }) => {
    const [correct, setCorrect] = useState(0);
    const [incorrect, setIncorrect] = useState(0);

    // console.log("SIMULATION VERIF", details.elem);
    // JSON.parse(elem.sim_config).questions

    return (
        <WrapperRightSide>
            <NavBar
                correct={correct}
                incorrect={incorrect}
            />

            <TestPage
                // tests={tests}
                tests={details.elem}
                setCorrect={setCorrect}
                setIncorrect={setIncorrect}
            />

            {/* <FooterBar /> */}
        </WrapperRightSide>
    );
}

export default MainSimulationVerificationPage;


