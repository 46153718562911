import React from 'react';

// PACKAGES
import styled from 'styled-components';

// STYLE
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    QueContainer,
    ResContainer
} from './Style';


const VerificationTestCoerenza = ({ details }) => {
    // console.log('TEMA COERENZA VIEW', details);

    return (
        <Wrapper>
            <div>
                <QueContainer>
                    Risposta:
                </QueContainer>
                {/* <LabelCss htmlFor="asw2">Risposta</LabelCss> */}
                {JSON.parse(details.elem.senteces).map((int, i) =>
                    <ResContainer key={i} style={{ flexDirection: 'column', marginTop: '10px' }}>
                        <span>{int.title}</span>
                        <span>{int.content}</span>
                    </ResContainer>
                )}

            </div>
        </Wrapper>
    );
}

export default VerificationTestCoerenza;