import styled from 'styled-components';

export const Form = styled.form`
    position: relative;
    margin-bottom: 90px;
    /* display: flex; */
        /* >div{
            width: 350px;
        } */
`;

export const BtnWrapper = styled.div`
    width: 100%;
    display: flex;
    position: absolute;
    bottom: -80px;
        >h5{
            position: absolute;
            color:var(--err);
            bottom:-25px;
        }
`;

export const Btn = styled.button`
    height:50px;
    padding: 0 20px;
    min-width: 100px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-weight: bold;
    border: none;
    color:${props => !props.disabled ? 'var(--text5)' : 'var(--text2)'};
    border-radius: var(--radius);
    /* margin-top: 20px;
    margin-left: 10px; */
    cursor: pointer;
    transition: var(--transition) ease;
    background: ${props => !props.disabled ? 'var(--acc-color)' : 'var(--background3)'};
    outline:none;
        >img{
            height:25px;
            margin-right: 10px;
        }
        >i{
            margin-right: 10px;
        }
        &:hover{
            opacity: .7;
        }
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const SubTitle = styled.h4`
  width:100%;
  color:var(--text3);
  display: flex;
  align-items: center;
`;

export const Title = styled.h3`
  width:100%;
  color:var(--text2);
  display: flex;
  align-items: center;
`;

export const GoBack = styled.div`
  color:var(--text3);
  cursor:pointer;
  font-size:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right:10px;
  background: var(--background4);
  width: 100px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
        &:hover{
            color: var(--text2)
        }
`;

export const Input = styled.input`
    width:300px;
    height: 50px;
    padding: 6px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    margin-top:10px;
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
            /* box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%); */
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        &::-webkit-calendar-picker-indicator{
            filter: invert(50%) sepia(4%) saturate(41%) hue-rotate(313deg) brightness(112%) contrast(100%);
            cursor: pointer;
            font-size: 20px;  
        }
`;

export const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 0px 0px;
    color: var(--text2);
`;

export const ProdImg = styled.div`
	width: 300px;
    height:200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
        >img{
            width: 200px;
            height:200px;
        }
`;

export const Err = styled.div`
  position:absolute;
  top: -30px;
  color: var(--err);
`;

export const NoteContainer = styled.div`
  position:relative;
  /* width: 100%;
  padding: 20px; */
        >h3{
            margin-top: 10px;
            color: var(--text4);
        }
`;

export const NoteTitle = styled.h4`
    width:100%;
    color:var(--text5);
    padding: 10px;
    border-radius: var(--radius);
    background: rgb(248,148,6);
    /* background: linear-gradient(90deg, rgba(248,148,6,1) 0%, rgba(255,255,255,0.03265056022408963) 77%, rgba(255,255,255,0.03265056022408963) 100%); */
    >i{
        cursor: pointer;
        margin-left: 5px;
            &:hover{
                color: var(--success);
            }
    }
`;

export const colourStyles = {
    control: (styles, state) => ({
        ...styles,
        width: 300,
        marginTop: 10,
        backgroundColor: 'transparent',
        borderRadius: 'var(--radius)',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text1)',
        border: (state.isHovered ? '1px solid var(--acc-color)' : '1px solid var(--border)') ||
            (state.isFocused ? '1px solid var(--acc-color)' : '1px solid var(--border)'),
    }),
    menu: styles => ({
        ...styles,
        width: 300,
        backgroundColor: 'var(--background4)',
        border: 'none',
        fontSize: '14px',
        color: 'var(--text1)',
        zIndex: 2,
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)' }),
    placeholder: styles => ({ ...styles, fontSize: '14px', color: 'var(--text3)' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};

// export const colourStyles = {
//     control: (styles, state) => ({
//         ...styles,
//         width: 100,
//         backgroundColor: 'transparent',
//         borderRadius: 'var(--radius)',
//         border: '1px solid var(--border)',
//         minHeight: '50px',
//         fontSize: '14px',
//         paddingLeft: '4px',
//         boxShadow: 'none',
//         cursor: 'pointer',
//         color: 'var(--text1)',
//         border: state.isHovered ? '1px solid var(--acc-color)' : '1px solid var(--border)',
//         border: state.isFocused ? '1px solid var(--acc-color)' : '1px solid var(--border)',
//     }),
//     menu: styles => ({
//         ...styles,
//         width: 100,
//         backgroundColor: 'var(--background4)',
//         border: 'none',
//         fontSize: '14px',
//         color: 'var(--text1)',
//         zIndex: 2,
//     }),

//     input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)', border: '1px solid var(--border)', }),
//     placeholder: styles => ({ ...styles, fontSize: '14px' }),
//     singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
//     multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
// };

// export const colourStyles2 = {
//     control: (styles, state) => ({
//         ...styles,
//         width: 200,
//         marginRight: '10px',
//         backgroundColor: 'transparent',
//         borderRadius: 'var(--radius)',
//         border: '1px solid var(--border)',
//         minHeight: '50px',
//         fontSize: '14px',
//         paddingLeft: '4px',
//         boxShadow: 'none',
//         cursor: 'pointer',
//         color: 'var(--text1)',
//         border: state.isHovered ? '1px solid var(--acc-color)' : '1px solid var(--border)',
//         border: state.isFocused ? '1px solid var(--acc-color)' : '1px solid var(--border)',
//     }),
//     menu: styles => ({
//         ...styles,
//         width: 200,
//         backgroundColor: 'var(--background4)',
//         border: 'none',
//         fontSize: '14px',
//         color: 'var(--text1)',
//         zIndex: 2,
//     }),

//     input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)', border: '1px solid var(--border)', }),
//     placeholder: styles => ({ ...styles, fontSize: '14px' }),
//     singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
//     multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
// };