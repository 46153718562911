import { useState } from 'react';
// import { useHistory } from "react-router-dom";

// ASSETS
import pic from '../../../../../Assets/Capture.PNG';
import canvasImage from '../../../../../Assets/birds.png'

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from './NavBarQuestVerification';
import TestPage from './QuestVerificationPage';

// REDUX
// import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../../PagesShareStyle/PagesShareStyle';


const MainQuestVerificationPage = ({ details }) => {
    const [correct, setCorrect] = useState(0);
    const [incorrect, setIncorrect] = useState(0);

    // console.log("QUEST VERIF", details);
    // JSON.parse(elem.sim_config).questions

    return (
        <WrapperRightSide>
            <NavBar
                correct={correct}
                incorrect={incorrect}
            />

            <TestPage
                // tests={tests}
                que={details.que}
                asw={details.asw}
                setCorrect={setCorrect}
                setIncorrect={setIncorrect}
            />

            {/* <FooterBar /> */}
        </WrapperRightSide>
    );
}

export default MainQuestVerificationPage;


