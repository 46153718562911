import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';
import NavCard from './Nav';

// REDUX
import { useSelector } from 'react-redux';

const PopUp = styled.div`
    position:absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 10px;
    background: #6f6f6f73;
    color: var(--text5);
    /* text-align: center; */
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(8px);
    transition: var(--transition);
    /* white-space: nowrap; */
`;

const CardWrapper = styled.div`
    position: relative;
    width: 250px;
    height: 250px;
    background-color: var(--background4);
    margin: 10px 0px;
    padding: 10px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    border: ${props => props.warning === false && '2px solid var(--err)'};
`;

const TitleCard = styled.div`
    position: absolute;
    top: 10px;
    width: 130px;
    color:  var(--text2);
    font-size: 18px;
    text-align: center;
        &:hover ${PopUp} {
            opacity: 1;
            visibility: visible;
        }
`;

const Total = styled.div`
    width: 100%;
    color:  var(--text3);
    font-size: 14px;
    text-align: center;
    
`;

const Nav = styled.div`
    position: absolute;
    bottom: 10px;
    color:  var(--text2);
    display: flex;
        >span{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: var(--text4);
            cursor: default;
        }
`;

const Btn = styled.div`
    padding: 0px 5px;
    border:none;
    outline: none;
    color: ${props => props.active === 'color' ? 'var(--acc-color)' : 'var(--text3)'};
    font-weight: ${props => props.active === 'color' && 'bold'};;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    cursor: pointer;
        &:hover{
            transition: .2s ease-in-out;
            color:  var(--acc-color);
            font-weight: bold;
        }
        &:active{
                transform:translateY(2px);
        }  
`;

const InputCss = styled.input`
    width: 100%;
    height: 60px;
    /* padding: 6px 12px; */
    border: 1px solid var(--color6);
    border-radius: var(--radius);
    background: var(--color7);
    color: var(--text1);
    transition: var(--transition) ease;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-top: 55px;
    /* box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important; */
        &::placeholder { 
            color: var(--text2);
            font-size: 50px;
            font-weight: bold;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline: none;
            ${CardWrapper} {
                box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
            }
            /* caret-color: rgba(0,0,0,0); */
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
`;

const Choose = styled.div`
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
        >i{
            color: var(--text3);
            &:hover{
                color: var(--acc-color);
            }
        }
`;

const DialogBox = styled.div`
    position: absolute;
    top: 20px;
    right: -10px;
    width: 240px;
    min-height: 160px;
    max-height: 200px;
    padding: 10px 10px;
    overflow: auto;
    z-index: 1;
    text-align: left;
    color: var(--text2);
    border-radius: var(--radius);
    /* background: var(--background4); */
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    background: #5f678293;
    backdrop-filter: blur(10px);
        >div>h5{
            color: var(--text5);
            padding: 5px;
            font-size:12px;
            border-radius: var(--radius);
            &:hover{
                background: var(--acc-color);
            }
        }
        &::-webkit-scrollbar {
            width: 5px;
        };
`;

const Warning = styled.div`
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 100%;
    text-align: center;
    color: var(--err);
    font-size: 12px;
`;




const QuizCard = ({ mat, addParam, index, arr, course }) => {
    const openDialogRef = useRef();
    const profile = useSelector((state) => state.auth.user);
    const [access, setAccess] = useState({
        nr: '',
        difficulty: '',
        idDifficulty: '',
        materia: mat.subject_name,
        subjectId: mat.idSubject,
        index: index,
        option: '',
        braniNr: '',
        course: course.idCourse,
    });
    const [open, setOpen] = useState(false);

    const extractNr = (name) => {
        const makeArr = name.split(' ');
        if (makeArr[0] === 'Brani') {
            return makeArr[1]
        }
    };

    useEffect(() => {
        if (arr.length > 0) {
            setAccess({
                ...access,
                nr: Object.keys(arr[index]).length > 0 ? arr[index].nr : '',
                difficulty: Object.keys(arr[index]).length > 0 ? arr[index].difficulty : '',
                idDifficulty: Object.keys(arr[index]).length > 0 ? arr[index].idDifficulty : '',
                materia: mat.subject_name,
                index: index,
                option: Object.keys(arr[index]).length > 0 ? arr[index].option : '',
                braniNr: Object.keys(arr[index]).length > 0 ? arr[index].braniNr : extractNr(mat.subject_name),
            })
        }
    }, [arr]);

    const setDif = (props) => {
        // console.log(props, props.option, props.max);
        const maxAll = props.e.target.max * 1;

        // console.log('SET DIF', props);

        setAccess({
            ...access,
            // nr: (props.nr !== undefined) ? props.nr : access.nr,
            nr: (props.nr !== undefined) ?
                // props.nr
                (maxAll < props.nr * 1) ?
                    maxAll
                    :
                    props.nr
                :
                (props.max !== undefined && props.max < access.nr * 1) ?
                    props.max
                    :
                    access.nr,
            difficulty: (props.sts !== undefined) ? props.sts : '',
            idDifficulty: (props.e.currentTarget.id !== undefined) ? props.e.currentTarget.id : '',
            option: (props.option !== undefined) ? props.option : '',
        });

        addParam({
            ...access,
            // nr: (props.nr !== undefined) ? props.nr : access.nr,
            nr: (props.nr !== undefined) ?
                // props.nr
                (maxAll < props.nr * 1) ?
                    maxAll
                    :
                    props.nr
                :
                (props.max !== undefined && props.max < access.nr * 1) ?
                    props.max
                    :
                    access.nr,
            difficulty: (props.sts !== undefined) ? props.sts : '',
            // difficulty: (props.sts !== undefined) ? props.sts : '',
            idDifficulty: (props.e.currentTarget.id !== undefined) ? props.e.currentTarget.id : '',
            option: (props.option !== undefined) ? props.option : '',
        });
        setOpen(false);
    };

    useEffect(() => {
        let handler = (e) => {
            if (openDialogRef.current) {
                if (openDialogRef.current.contains(e.target) === false) {
                    setOpen(false)
                }
            }
        };
        document.addEventListener("mousedown", handler, true);
        return () => {
            document.removeEventListener("mousedown", handler, true);
        }
    }, [open]);

    const checkWarnings = () => {
        if (mat.subject_name.split(' ')[0] === 'Brani') {
            if (access.nr !== '') {
                const rest = access.nr * 1 / access.braniNr * 1
                if (Number.isInteger(rest)) {
                    return true
                } else return false
            }
        }
    };

    // console.log('QuizCard index', access);
    // console.log('QuizCard', arr, index);
    // console.log("MAT", mat);

    return (
        <div style={{ position: 'relative' }}>
            <CardWrapper warning={checkWarnings()}>
                {/* {(profile !== undefined && profile.accesLevel === 'admin') &&
                <Admin
                    content={content}
                />
            } */}
                {/* <TitleCard>{mat.name}</TitleCard> */}
                <TitleCard>
                    {mat.subject_name.length > 16 ?
                        mat.subject_name.slice(0, 16) + '...'
                        :
                        mat.subject_name
                    }
                    {mat.subject_name.length > 16 &&
                        <PopUp>{mat.subject_name}</PopUp>
                    }
                </TitleCard>

                <InputCss
                    placeholder="0"
                    id='nr'
                    type='number'
                    name='nr'
                    required
                    value={access.nr}
                    autoComplete='off'
                    min='1'
                    max={mat.allQuestions.length}
                    valueType="int"
                    // onChange={(e) =>
                    //     setAccess({
                    //         ...access,
                    //         nr: e.target.value
                    //     })
                    // }
                    onChange={(e) => setDif({ e, nr: e.target.value })}
                ></InputCss>

                <Total>Totale {mat.allQuestions.length} domande</Total>

                <NavCard
                    mat={mat}
                    access={access}
                    setDif={setDif}
                />

                {/* <Nav>
                <Btn
                    id='1'
                    onClick={(e) => setDif({ e, sts: 'Facile' })}
                    // onClick={(e) => setAccess({ ...access, difficulty: 'Facile', idDifficulty: e.currentTarget.id })}
                    active={access.idDifficulty === '1' && 'color'}
                >
                    <span>{mat.easyQuestions.length}</span>
                    Facile
                </Btn>
                <span>|</span>
                <Btn
                    id='2'
                    onClick={(e) => setDif({ e, sts: 'Medio' })}
                    // onClick={(e) => setAccess({ ...access, difficulty: 'Medio', idDifficulty: e.currentTarget.id })}
                    active={access.idDifficulty === '2' && 'color'}
                >
                    <span>{mat.moderateQuestions.length}</span>
                    Medio
                </Btn>
                <span>|</span>
                <Btn
                    id='3'
                    onClick={(e) => setDif({ e, sts: 'Difficile' })}
                    // onClick={(e) => setAccess({ ...access, difficulty: 'Difficile', idDifficulty: e.currentTarget.id })}
                    active={access.idDifficulty === '3' && 'color'}
                >
                    <span>{mat.difficultQuestions.length}</span>
                    Difficile
                </Btn>
            </Nav> */}

                {mat.argument.length > 0 &&
                    mat.subject_name.split(' ')[0] !== 'Brani' &&
                    <Choose ref={openDialogRef}>
                        <i onClick={() => setOpen(!open)} className="fa-solid fa-ellipsis-vertical"></i>

                        {open &&
                            <DialogBox >
                                {/* {mat.argument.length <= 0 && <span>Nessuna opzione</span>} */}
                                {mat.argument.map((ar, i) =>
                                    <div key={i}>
                                        <h5
                                            onClick={(e) => setDif({ e, option: ar.argument, nr: ar.nr })}>
                                            {ar.argument}
                                            {/* {ar.argument.length >= 30 ?
                                                `${ar.argument.slice(0, 30)}...`
                                                :
                                                ar.argument
                                            }
                                            {
                                                ar.argument.length > 20 &&
                                                <PopUp>{ar.argument}</PopUp>
                                            } */}
                                        </h5>
                                    </div>
                                )}
                                {/* <h5 id='4' onClick={(e) => setDif({ e, option: 'Option 1', nr: 44 })}>Option 1</h5>
                                <h5>Option 2</h5>
                                <h5>Option 3</h5>
                                <h5>Option 4</h5>
                                <h5>Option 5</h5>
                                <h5>Option 6</h5>
                                <h5>Option 7</h5>
                                <h5>Option 8</h5> */}
                            </DialogBox>
                        }
                    </Choose>
                }


            </CardWrapper >

            {checkWarnings() === false &&
                <Warning>
                    Inserisci un numero divisibile per {access.braniNr}
                </Warning>
            }
        </div >
    );
};

export default QuizCard;
