import { combineReducers } from "redux";

import authReducers from "./authReducers";
import otherReducers from "./otherReducers";
import coursesReducers from "./coursesReducers";
import subjectsReducers from "./subjectsReducers";
import testReducers from "./testReducers";
import usersReducers from "./usersReducers";
import tpaReducers from "./tpaReducers";
import statisticsReducer from "./statisticsReducers";
import oraleReducers from "./oraleReducers";
import physicalReducers from "./physicalReducers";
import conversationsReducers from "./conversationsReducers";
import notificationsReducers from "./notificationsReducers";
import temaReducers from "./temaReducers";

export default combineReducers({
    auth: authReducers,
    other: otherReducers,
    courses: coursesReducers,
    subjects: subjectsReducers,
    test: testReducers,
    users: usersReducers,
    tpa: tpaReducers,
    statistics: statisticsReducer,
    orale: oraleReducers,
    physical: physicalReducers,
    conversations: conversationsReducers,
    notif: notificationsReducers,
    tema: temaReducers,
});