import React, { useLayoutEffect, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router';

// PACKEGES
// import styled from 'styled-components';
import { io } from 'socket.io-client';

// ASSETS
// import logo from '../../Assets/logo.png';

// COMPONENTS
import Hamburger from './Ham';
import Reminder from './ReminderNavBar';
import ProfileOptions from './ProfileOptions';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../Store/Actions/authActions';
import { handleCalendar, handleRightSideBar, globalNotif } from '../../../Store/Actions/otherActions';
import { createSocket, setOnlineUsers } from '../../../Store/Actions/conversationsActions';

import useSocket from '../../GeneralComponents/Hooks/Socket';

// STYLE
import {
    NavWrapper,
    Nav,
    BtnWrapper,
    Btn,
    MessBtn
} from './StyleNavBar';

const NavBar = ({ setId }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const user = useSelector(({ auth }) => auth.user);
    const createSocket = useSocket(user);
    const openCalendar = useSelector(({ other }) => other.handleCalendar);
    const openRightSideBar = useSelector(({ other }) => other.handleRightSideBar);
    const socket = useSelector(({ conversations }) => conversations.socket);
    const [app, setApp] = useState(false);

    // useEffect(() => {
    //     dispatch(createSocket(io('ws://localhost:5006')))
    // }, []);

    // useEffect(() => {
    //     socket?.emit('addUser', user?.idUser)
    //     socket?.on('getOnlineUsers', users => {
    //         // console.log('ONLINE USERS', users);
    //         dispatch(setOnlineUsers(users));
    //     })
    //     socket?.on('getMessages', data => {
    //         dispatch(globalNotif(true));
    //     })
    // }, [socket]);

    const disconnect = () => {
        dispatch(logOut());
        history.push('/');
        socket.emit('disconnectUser', function () {
            socket.disconnect();
        })
        socket.on('getOnlineUsers', users => {
            // console.log('ONLINE USERS', users);
            dispatch(setOnlineUsers(users));
        })
    };

    const sideBar = () => {
        dispatch(handleCalendar(!openCalendar));
    };

    const handleOptSideBar = () => {
        dispatch(handleRightSideBar(!openRightSideBar))
    };

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth > 1100) {
                if (openRightSideBar === true) {
                    dispatch(handleRightSideBar(false));
                }
                setApp(false)
            } else {
                setApp(true)
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [dispatch, openRightSideBar]);


    return (
        <NavWrapper>
            <Nav>
                <Hamburger />
                <BtnWrapper>
                    {/* <Btn onClick={() => disconnect()}><i className="fas fa-power-off"></i></Btn> */}

                    {/* Uncomment for notifications */}
                    {/* <Reminder socket={socket} /> */}

                    {/* <Btn onClick={sideBar}>
                        {!openCalendar ?
                            <i className="far fa-calendar-alt"></i>
                            :
                            <i className="far fa-calendar-times"></i>
                        }
                    </Btn> */}

                    {app ?
                        (location.pathname === '/dashboard/create-quiz/simulation/' + location.pathname.split('/').pop()) ||
                            (location.pathname === '/dashboard/create-quiz/test/' + location.pathname.split('/').pop()) ||
                            (location.pathname === '/dashboard/tpa-bool/' + location.pathname.split('/').pop()) ||
                            (location.pathname === '/dashboard/tpa-multiple/' + location.pathname.split('/').pop()) ||
                            (location.pathname === '/dashboard/tpa-free/' + location.pathname.split('/').pop()) ||
                            (location.pathname === '/dashboard/tpa-test-verification') ||
                            (location.pathname === '/dashboard/test-verification') ||
                            (location.pathname === '/dashboard/create-quiz/wrong') ||
                            (location.pathname === '/dashboard/simulation-verification') ||
                            (location.pathname === '/dashboard/quest') ||
                            (location.pathname === '/dashboard/quest-verification') ||
                            (location.pathname === '/dashboard/teachers/view') ||
                            (location.pathname === '/dashboard/prova-physical/quest') ||
                            (location.pathname === '/dashboard/view-tema-test-done')
                            ?
                            <Btn color='notif' onClick={() => handleOptSideBar()}>
                                {!openRightSideBar ?
                                    <i className="fa-solid fa-list-ol"></i>
                                    :
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                }
                            </Btn>
                            :
                            null
                        : null
                    }

                    <ProfileOptions
                        disconnect={disconnect}
                    />
                </BtnWrapper>
            </Nav>

        </NavWrapper>
    );
}

export default NavBar;
