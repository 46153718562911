import { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
// import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedQuestionsForTest, saveTest, resetTest } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
} from '../PagesShareStyle/PagesShareStyle';


const PreTema = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const formatPath = (path) => {
        const newPath = path.split(' ').join('-');
        const final = newPath.toLowerCase();
        return final
    };

    console.log('PRE TEMA details', details);

    useEffect(() => {
        setTimeout(() => {
            let url;
            if (details.type === details.svolgere[5] ||
                details.type === details.svolgere[6]) {
                url = `/dashboard/tema-test/${formatPath(details.type)} ${details.course.idCourse}`;
                //console.log("URL 1xw", url)
            } else {

                url = `/dashboard/tema-test/${formatPath(details.type)} ${details.choose} ${details.course.idCourse}`;
                //console.log("URL", url)
            };
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
            history.goBack()
        }, 2000);
    }, [dispatch]);

    return (
        <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img src={Loading} alt="Loading..."></img>
            <div>Inizia "{details.type}" ...</div>
        </WrapperRightSide>
    );
}

export default PreTema;
