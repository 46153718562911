import { useEffect, useState, useLayoutEffect } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import NotifCard from './NotifCard';
import Tabs from './Tabs';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
    findUnreadNotifications, findReadedNotifications,
    markAllNotifAsRead, deleteAllNotif
} from '../../../Store/Actions/notificationsActions';

// STYLE
import {
    WrapperRightSide,
} from '../PagesShareStyle/PagesShareStyle';
import {
    PopUp,
    Title,
    Subtitle,
    EmptyNotif,
    Empty,
    GoBack,
    Container,
    UnreadWrapper,
    NotifContainer,
    ReadWrapper,
    ReadBtn
} from './Style';

const NotificationsPage = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const unreadNotif = useSelector(({ notif }) => notif.unreadNotif);
    const readedNotif = useSelector(({ notif }) => notif.readedNotif);
    // const [open, setOpen] = useState(true);

    // useLayoutEffect(() => {

    //     console.log('mata')
    //     function updateSize() {
    //         if (window.innerWidth > 800) {
    //             if (open === true) {
    //                 setOpen(false);
    //             }
    //         }
    //     }
    //     window.addEventListener('resize', updateSize);

    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);

    useEffect(() => {
        if (profile !== undefined) {
            dispatch(findUnreadNotifications(profile));
            dispatch(findReadedNotifications(profile));
        }
    }, [profile]);

    const markAll = () => {
        dispatch(markAllNotifAsRead(profile));
    };

    const deleteAll = () => {
        dispatch(deleteAllNotif(profile));
    };

    // console.log('unreadNotif', JSON.parse(unreadNotif[0].Test));
    // console.log('readedNotif', readedNotif);
    // console.log('open', open);

    return (
        <WrapperRightSide>
            <GoBack onClick={() => history.goBack()}>
                <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
            </GoBack>

            <Tabs
                unreadNotif={unreadNotif}
                readedNotif={readedNotif}
                markAll={markAll}
                deleteAll={deleteAll}
            />

            {unreadNotif !== undefined && readedNotif !== undefined ?
                <Container>
                    <UnreadWrapper>
                        <Title>
                            <i className="fa-solid fa-envelope"></i>
                            Non letti
                        </Title>

                        <Subtitle>
                            <span>{unreadNotif !== undefined && unreadNotif.length} notifica</span>
                            <ReadBtn onClick={markAll}>
                                <i className="fa-solid fa-list-check"></i>
                                <PopUp>Segna tutti come letti</PopUp>
                            </ReadBtn>
                        </Subtitle>
                        <NotifContainer>
                            {unreadNotif !== undefined && unreadNotif.length > 0 ?
                                unreadNotif.map((notif, i) =>
                                    <NotifCard
                                        key={i}
                                        notif={notif}
                                        flag='unread'
                                    />
                                )
                                :
                                <Empty><i className="fa-solid fa-envelope"></i> Nessuna notifica</Empty>
                            }
                        </NotifContainer>
                    </UnreadWrapper>

                    <ReadWrapper>
                        <Title>
                            <i className="fa-solid fa-envelope-open"></i>
                            Letti
                        </Title>
                        <Subtitle>
                            <span>{readedNotif !== undefined && readedNotif.length} notifica</span>
                            <ReadBtn delete onClick={deleteAll}>
                                <PopUp>Elimina tutto</PopUp>
                                <i className="fa-regular fa-trash-can" style={{ marginRight: '2px' }}></i>
                            </ReadBtn>
                        </Subtitle>
                        <NotifContainer>
                            {readedNotif !== undefined && readedNotif.length > 0 ?
                                readedNotif.map((notif, i) =>
                                    <NotifCard
                                        key={i}
                                        notif={notif}
                                    />
                                )
                                :
                                <Empty><i className="fa-solid fa-envelope-open"></i> Nessuna notifica</Empty>
                            }
                        </NotifContainer>
                    </ReadWrapper>
                </Container>
                :
                <EmptyNotif><i className="fas fa-bell"></i> Nessuna notifica</EmptyNotif>
            }
        </WrapperRightSide>
    );
}

export default NotificationsPage;