import React from 'react';

// PACKAGES
import styled from 'styled-components';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    GoBack,
} from '../../../PagesShareStyle/PagesShareStyle';
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    QueContainer,
    ResContainer,
    Section,
} from './Style';


const RevisioneView = ({ oneTema }) => {
    // console.log('TEMA VIEW', oneTema);

    return (
        <Wrapper>
            <div style={{ width: '100%' }}>
                <QueContainer>
                    Risposta:
                </QueContainer>

                <Section>
                    {/* <Title>Introduzione</Title> */}
                    <LabelCss htmlFor="asw2">Introduzione</LabelCss>
                    {JSON.parse(oneTema.idTextProcessing_TextProcessing.introduction).map((int, i) =>
                        <div key={i}>
                            <span>{int.intro}</span>  -  <span>{int.subIntro}</span>
                        </div>
                    )}
                </Section>
                <Section>
                    {/* <Title>Corpo del testo (parole chiave)</Title> */}
                    <LabelCss htmlFor="asw2">Corpo del testo (parole chiave)</LabelCss>
                    {JSON.parse(oneTema.idTextProcessing_TextProcessing.body).map((int, i) =>
                        <div key={i}>
                            <span>{int.midd}</span>  -  <span>{int.subMidd}</span>
                        </div>
                    )}
                </Section>
                <Section>
                    {/* <Title>Conclusione</Title> */}
                    <LabelCss htmlFor="asw2">Conclusione</LabelCss>
                    {JSON.parse(oneTema.idTextProcessing_TextProcessing.end).map((int, i) =>
                        <div key={i}>
                            <span>{int.conc}</span>  -  <span>{int.subConc}</span>
                        </div>
                    )}
                </Section>

                <Section style={{ minHeight: '150px', background: 'var(--background4)' }}>
                    {/* <Title>Conclusione</Title> */}
                    <LabelCss htmlFor="asw2">Argomenti</LabelCss>
                    <div style={{ marginLeft: '10px' }}>{oneTema.revisione.text}</div>
                </Section>
            </div>

            <CommentWrapper>
                {oneTema.revisione.comments !== null &&
                    JSON.parse(oneTema.revisione.comments).map((comment, i) =>
                        <div key={i}>
                            <span>{i + 1}.</span>
                            {comment.message}
                        </div>
                    )
                }
            </CommentWrapper>
        </Wrapper>
    );
}

export default RevisioneView;