import axios from 'axios';
axios.defaults.withCredentials = true;

export const getGeneralStatistics = (id) => (dispatch, getState) => {

    const body = {
        idUser: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getGeneralStatistics`, body)
        .then((res) => {
            // console.log("GENERAL_STATISTICS", res.data);
            dispatch({
                type: 'GENERAL_STATISTICS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPunctualStatistics = (flag, id) => (dispatch, getState) => {

    const body = {
        type: flag.toLowerCase(),
        idUser: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getPunctualStatistics`, body)
        .then((res) => {
            // console.log("PUNCTUAL_STATISTICS", res.data);
            dispatch({
                type: 'ALL_STATISTICS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resetStat = () => (dispatch, getState) => {
    dispatch({
        type: 'ALL_STATISTICS',
        payload: undefined,
    });
};

export const getDataForChartLine = (idUser) => (dispatch, getState) => {

    const body = {
        idUser: idUser,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getDataForChartLine`, body)
        .then((res) => {
            console.log("CHART_LINE_DATA", res.data);
            dispatch({
                type: 'CHART_LINE_DATA',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};