import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';

// REDUX
import { useSelector } from 'react-redux';

const BarWrapper = styled.div`
    position: fixed;
    top: 160px;
    right: 0;
    width: 250px;
    background-color: var(--background4);
    /* margin: 10px 0px; */
    padding: 5px;
    height: calc(100vh - 240px);
    /* border-radius: var(--radius); */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
        @media (min-width: 1650px) {
            width: 370px;
        };
        @media (max-width: 1100px) {
            transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(100%)'};
            z-index: 2;
        };
`;

const Title = styled.div`
   color:  var(--text2);
   font-size: 24px;
   font-weight: bold;
`;

const Nav = styled.div`
   color:  var(--text2);
   display: flex;
   cursor: default;
   margin-bottom: 10px;
        >span{
            color: var(--text4);
        }
`;

const BtnLink = styled.div`
    padding: 0px 5px;
    border:none;
    outline: none;
    color: ${props => props.sts === 'true' ? 'var(--acc-color)' : 'var(--text3)'};
    font-weight: ${props => props.sts === 'true' ? 'bold' : 'none'};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: .2s ease-in-out;
        /* &:hover{
            transition: .2s ease-in-out;
            color:  var(--acc-color);
            font-weight: bold;
        } */
        /* &:active{
                transform:translateY(2px);
        }   */
`;

const QuestionsContainer = styled.div`
    max-height: calc(100vh - 170px);
    width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const Box = styled.div`
    height: 50px;
    width: 50px;
    /* color: var(--text2); */
    color: ${props =>
        (props.prev === 'correct' && 'var(--text5)') ||
        (props.prev === 'incorrect' && 'var(--text5)') ||
        (props.select === 'select' ? 'var(--text5)' : 'var(--text2)')
    };
    background: ${props =>
        (props.select === 'select' && 'var(--warning)') ||
        (props.prev === 'correct' && '#ffbc5dd1') ||
        (props.prev === 'incorrect' && 'var(--err)')
    };
    font-size: 25px;
    font-weight: bold;
    margin: 10px 5px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
        &:hover{
            /* background: var(--background3); */
            background: ${props => props.select === 'select' ? 'var(--warning)' : 'var(--background3)'};
        }
        &:focus{
            background: var(--warning);
        }
`;


const AllQuestionsSideBarDone = ({ id, setId, difficulty, arr, obj }) => {
    // const profile = useSelector((state) => state.auth.user);
    const openRightSideBar = useSelector(({ other }) => other.handleRightSideBar);
    const [sts, setSts] = useState(difficulty);
    const [is, setIs] = useState();

    const setQue = (id, sts) => {
        setId(id);
    };

    useEffect(() => {
        setSts(difficulty);
    }, [difficulty]);

    const howIsIt = (elem, que) => {
        if (elem !== undefined && Object.keys(elem).length > 0) {
            let is;
            if (elem.answer_picked === que.answerOk) {
                is = 'correct';
            }

            if (elem.answer_picked !== que.answerOk) {
                is = 'incorrect';
            }

            if (elem.answer_picked === '' || que.answerOk === '') {
                is = 'empty';
            }
            return is;
        };
    };

    //console.log("ELEM", elem)

    return (
        <BarWrapper open={openRightSideBar}>
            <Title>Domande</Title>
            <QuestionsContainer>
                {arr.map((que, i) =>
                    <Box
                        key={i}
                        onClick={() => setQue(i, que)}
                        select={id === i && 'select'}
                        prev={howIsIt(obj[i], que)}
                    >
                        {i + 1}
                    </Box>
                )}
            </QuestionsContainer>
        </BarWrapper >
    );
};

export default AllQuestionsSideBarDone;