import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';
import Select from 'react-select';

// COMPONENTS
// import InputCss from '../../GeneralComponents/Inputs/Inputs';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getSimulations, resetSimulations } from '../../../Store/Actions/testActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
    colourStyles,
} from './ModalStyle';

// const InputCss = styled.input`
//     width: 100%;
//     height: 50px;
//     padding: 6px;
//     border: none;
//     border-radius: var(--radius);
//     border: 1px solid var(--border);
//     margin-top:10px;
//     color: var(--text1);
//     transition: var(--transition) ease;
//     background: transparent;
//         &::placeholder { 
//             color: var(--text3);
//             font-size: 14px;
//         }
//         &:hover{
//             border-color: var(--acc-color);
//         }
//         &:focus{
//             outline:none;
//             border: 1px solid var(--acc-color);
//             /* box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%); */
//         }
//         &:-webkit-autofill{
//             -webkit-text-fill-color: var(--text1);
//             -webkit-box-shadow: 0 0 0px 1000px #fff inset;
//             transition: background-color 5000s ease-in-out 0s; 
//         }
//         &::-webkit-calendar-picker-indicator{
//             filter: invert(50%) sepia(4%) saturate(41%) hue-rotate(313deg) brightness(112%) contrast(100%);
//             cursor: pointer;
//             font-size: 20px; 
//         }
// `;

const LabelCss = styled.label`
    display:flex;
    align-items: center;
    color: var(--text1);
    margin: 20px 10px 0;
`;


const SimulationModal = ({ title, body, setOpenModal, course }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const simOpt = useSelector(({ test }) => test.simOpt);
    const [opt, setOpt] = useState([]);
    const [choose, setChoose] = useState('');

    useEffect(() => {
        dispatch(getSimulations(course));
    }, [dispatch, course]);

    useEffect(() => {
        if (simOpt !== undefined) {
            let opt = [];
            if (simOpt.length > 0) {
                for (let i = 0; i < simOpt.length; i++) {
                    opt.push({
                        label: simOpt[i].name, value: simOpt[i].idSimulationConfig
                    });
                }
                setOpt(opt);
            } else {
                opt.push({ label: 'Non ci sono opzioni', value: 'Non ci sono opzioni' });
            }
        }
    }, [simOpt]);

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
        dispatch(resetSimulations());
    };

    // const handleChange = evt => {
    //     setTitleTemp(evt.target.value)
    // };

    const handleSubmit = evt => {
        const url = '/dashboard/create-simulation';
        const state = {
            flag: 'sim', course, choose
        }
        history.push(url, state)
    };

    // console.log(simOpt);
    // console.log(choose);

    return (
        <>
            <ModalWrapper onClick={(e) => handleClose(e)} />

            <DialogBox>
                <H3>{title}</H3>
                <Hr></Hr>

                <LabelCss htmlFor="type">Tipo di simulazione</LabelCss>
                <Select
                    name='type'
                    isMulti={false}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    // defaultValue={defaultValue()}
                    onChange={(e) => setChoose(e.value)}
                    // onChange={(e) =>
                    //     setMore({
                    //         ...more,
                    //         area: e
                    //     })
                    // }
                    options={opt}
                    styles={colourStyles}
                    placeholder={'Scegli il tipo di simulazione'}
                />

                <BtnWrapper>
                    <Btn disable onClick={handleClose}>Annulla</Btn>
                    {choose ?
                        <Btn onClick={handleSubmit}>Simulazione</Btn>
                        :
                        <Btn closed>Simulazione</Btn>
                    }
                </BtnWrapper>
            </DialogBox>
        </>

    );
}
export default SimulationModal;