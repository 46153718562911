import { useEffect, useState } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import MainTpaVerificationPage from './MainTpaVerificationPage'
// import AllQuestionsSideBar from './AllQuestions';
// import FooterBar from './FooterBarTestVerification';
// import QuestionTestVerification from './QuestionTestVerification';
// import SendWrongModal from '../../../GeneralComponents/Modals/SendWrongModal';

// REDUX
import { useDispatch } from 'react-redux';
//import { sendTestAnswers } from '../../../../Store/Actions/testActions';

// STYLE
import {
    // WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../PagesShareStyle/PagesShareStyle';


const TestVerificationPage = ({ tests, setCorrect, setIncorrect, allDetails }) => {
    const dispatch = useDispatch();
    const [id, setId] = useState(0);
    const [test, setTest] = useState();
    const [obj, setObj] = useState();

    useEffect(() => {
        if (tests !== undefined) {
            if (tests.questions !== null) {
                setTest(JSON.parse(tests.questions))
            }
            if (tests.answers !== null) {
                setObj(JSON.parse(tests.answers))
            } else {
                let createObj = [];
                const tst = JSON.parse(tests.questions)
                for (let i = 0; i < tst.length; i++) {
                    createObj.push({})
                }
                setObj(createObj);
            }
        }
    }, [tests]);


    useEffect(() => {
        if (obj !== undefined) {
            let correct = 0;
            let incorrect = 0;

            for (let i = 0; i < obj.length; i++) {
                const elem1 = obj[i];
                const elem2 = test[i];

                if (elem1.answer_picked === elem2.answerOk) {
                    correct = correct + 1
                }

                if (elem1.answer_picked !== elem2.answerOk) {
                    incorrect = incorrect + 1
                }

                if (elem1.answer_picked === '' || elem2.answerOk === '') {
                    incorrect = incorrect + 1
                }
            }
            setCorrect(correct);
            setIncorrect(incorrect);
        };
    }, [obj, setCorrect, setIncorrect]);

    const setAnswer = (e, i, letters) => {
        // console.log(i)
        const value = [...obj]
        value[id].asw = JSON.parse(test[id].answers)[e.target.id].ans;
        value[id].answer_picked = letters[i];
        value[id].aswIndex = i;
        setObj(value);

        //dispatch(sendTestAnswers(value, tests.idTestExercises));
    };

    // console.log('OBJ', obj);
    // console.log('TYPE', tests);
    // console.log('HEEEEEEEERE', tests);
    // console.log('TestVerificationPage', tests);

    return (
        test !== undefined &&
        <>
            {/* <TestWrapper> */}
            <MainTpaVerificationPage
                quest={test}
                done={obj}
                course={tests.idCourse}
                type={tests.type}
                idDone={tests.idTpaDone}
                doneAllObj = {tests}
                allDetails={allDetails}
            />

        </>
    );
}

export default TestVerificationPage;