import React, { useEffect, useState, useRef } from 'react';

// PACKAGES
import styled from 'styled-components';


// ASSETS
import userA from '../../../../../Assets/avatar.png';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { findFriends, markMessAsRead, findAllUnreadMessages } from '../../../../../Store/Actions/conversationsActions';
import { globalNotif, setMsgListen } from '../../../../../Store/Actions/otherActions';

import axios from 'axios';
import { set } from 'lodash';
axios.defaults.withCredentials = true;

const GeneralWrapper = styled.div`
    width: 99%;
    margin: 10px 0 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: var(--radius);
    background: ${props => props.select ? 'var(--background2)' : 'transparent'};
        >img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
        }
        >div{
            display: flex;
            flex-direction: column;
            justify-content: center;
                >h5{
                    color: var(--text1);
                    font-weight: bold;
                }
                >div{
                    display: flex;
                    align-items: center;
                    >h6{
                          font-weight: 100;
                    }
                    >span{
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        /* background-color: var(--success); */
                        background: ${props => props.status ? 'var(--success)' : '#6f7470'};
                        margin-right: 5px;
                    }
                }
        }
        
        &:hover{
             background:var(--background3);
        }
`;

const AvatarDisplay = styled.div`
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    color: var(--text4);
    margin-right: 10px;
    padding: 10px 0;
        >h4{
            height: 40px;
            width: 40px;
            border-radius: 40px;
            font-size: 20px;
            display:flex;
            align-items:center;
            justify-content:center;
            color: var(--text2);
            background: var(--background3);
        }
        >img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
            cursor: pointer;
            background: var(--background2);
        }
`;

const MessengerNotif = styled.div`
    position: absolute;
    right: 30px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: #0084ff;
`;


const UserCard = ({ friend, currentChat, onlineUsers, curentUserChat }) => {
    const dispatch = useDispatch();
    const mounted = useRef(false);
    const user = useSelector(({ auth }) => auth.user);
    const unreadMess = useSelector(({ conversations }) => conversations.unreadMess);
    // const [online, setOnline] = useState(false);
    const online = onlineUsers?.some(on => on.userId === friend?.idUsers);
    const [notif, setNotif] = useState(false);
    const [force, setForce] = useState(false);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    // useEffect(() => {
    //     setOnline(onlineUsers?.some(on => on.userId === friend.idUsers));
    // }, [onlineUsers]);

    const getInitials = (profile) => {
        if (profile !== undefined && profile !== null) {
            let string;

            string = profile.user_name + ' ' + profile.user_surname;

            const names = string.split(' ');
            let initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials
        }
    };

    // const listen = useSelector(({ other }) => other.msgListen);

    const socket = useSelector(({ conversations }) => conversations.socket);
    // console.log('AICI MATA', listen)
    const [listen, setListen] = useState();
    useEffect(() => {
        if (socket !== null) {
            if (mounted.current === true) {
                socket.on('getMessages', data => {
                    setListen(data);
                })
            }
        }
    }, [socket]);

    // const mess = useSelector(({ conversations }) => conversations.mess);

    useEffect(() => {
        // console.log('CURENT USER CHAT', curentUserChat);
        // console.log('LISTEN', listen);
        // console.log('FRIEND', friend);
        // console.log('CHAT', currentChat);

        if (mounted.current === true) {
            if (curentUserChat !== undefined) {
                if (curentUserChat.idUsers === friend.idUsers) {
                    console.log('READ HERE');
                    dispatch(markMessAsRead(friend.idUsers, currentChat.convId));
                    setNotif(false);
                }
            };

            if (listen !== undefined) {
                console.log('LISTEN HERE', listen);
                if (listen.senderId === friend.idUsers) {
                    if (user.idUser !== listen.senderId && curentUserChat === undefined) {
                        //CHAT UNSELECTED
                        setNotif(true);
                    } else {
                        //CHAT SELECTED
                        if (curentUserChat.idUsers !== listen.senderId) {
                            setNotif(true);
                        }
                        else {
                            dispatch(markMessAsRead(friend.idUsers, listen.chat.convId));
                            setNotif(false);
                            setListen(undefined);
                            // dispatch(setMsgListen(undefined));
                        }
                    }
                } else {

                    // setNotif(false);
                }
            } else {
                // if (mess?.SenderID === friend.idUsers && mess[mess.length - 1].receiverHasRead === 'false') {
                //     setNotif(true);
                // };
                if (unreadMess !== undefined) {
                    // console.log('MESS DEFINDED unreadMess!== undefined');
                    const find = unreadMess?.filter(un => un.SenderID !== user.idUser.toString());
                    if (find.length > 0) {
                        for (let i = 0; i < find.length; i++) {
                            if (find[i].SenderID === friend.idUsers.toString()) {
                                setNotif(true);
                            }
                        }
                        dispatch(findAllUnreadMessages(user.idUser));
                    }
                } else {
                    console.log('MESS UNDEFINDED unreadMess=== undefined');
                    dispatch(findAllUnreadMessages(user.idUser));
                    setNotif(false);
                }


                if (curentUserChat?.idUsers === friend.idUsers) {
                    console.log('CURENT HERE curentUserChat?.idUsers === friend.idUsers');
                    setNotif(false);
                    dispatch(markMessAsRead(friend.idUsers, currentChat.convId));
                }
            }
        }
    }, [listen, curentUserChat, mounted.current]);

    // useEffect(() => {
    //     // console.log('CURENT USER CHAT', curentUserChat);
    //     // console.log('LISTEN', listen);
    //     // console.log('FRIEND', friend);
    //     // console.log('CHAT', currentChat);

    //     // const find = unreadMess?.filter(un => un.SenderID !== user.idUser.toString());
    //     // console.log('FIND FIND', find)
    //     //     if (unreadMess!== undefined && find.length > 0) {
    //     //         for (let i = 0; i < find.length; i++) {
    //     //             if (find[i].SenderID === friend.idUsers.toString()) {
    //     //                 setNotif(true);
    //     //             }
    //     //         }
    //     //     }

    //     //     if (curentUserChat?.idUsers === friend.idUsers) {
    //     //         dispatch(markMessAsRead(friend.idUsers, currentChat.convId));
    //     //         setNotif(false);
    //     //     }

    //     if (curentUserChat !== undefined) {
    //         if (curentUserChat.idUsers === friend.idUsers) {
    //             console.log('READ HERE');
    //             dispatch(markMessAsRead(friend.idUsers, currentChat.convId));
    //             setNotif(false);
    //         }
    //     };


    //     if (listen !== undefined) {
    //         console.log('LISTEN HERE', listen);
    //         if (listen.senderId === friend.idUsers) {
    //             if (user.idUser !== listen.senderId && curentUserChat === undefined) {
    //                 //CHAT UNSELECTED
    //                 setNotif(true);
    //             } else {
    //                 //CHAT SELECTED
    //                 if (curentUserChat.idUsers !== listen.senderId) {
    //                     setNotif(true);
    //                 }
    //                 else {
    //                     // dispatch(markMessAsRead(friend.idUsers, listen.chat.convId));
    //                     setNotif(false);
    //                     setListen(undefined);
    //                 }
    //             }
    //         } else {

    //             // setNotif(false);
    //         }
    //     } else {
    //         // if (mess?.SenderID === friend.idUsers && mess[mess.length - 1].receiverHasRead === 'false') {
    //         //     setNotif(true);
    //         // };
    //         if(unreadMess!== undefined){
    //             console.log('MESS DEFINDED unreadMess!== undefined');
    //             const find = unreadMess?.filter(un => un.SenderID !== user.idUser.toString());
    //             if (find.length > 0) {
    //                 for (let i = 0; i < find.length; i++) {
    //                     if (find[i].SenderID === friend.idUsers.toString()) {
    //                         setNotif(true);
    //                     }
    //                 }
    //                 // dispatch(findAllUnreadMessages(user.idUser));
    //             }
    //         } else {
    //             console.log('MESS UNDEFINDED unreadMess=== undefined');
    //             // dispatch(findAllUnreadMessages(user.idUser));
    //             setNotif(false);
    //         }


    //         if (curentUserChat?.idUsers === friend.idUsers) {
    //             console.log('CURENT HERE curentUserChat?.idUsers === friend.idUsers');
    //             setNotif(false);
    //             dispatch(markMessAsRead(friend.idUsers, currentChat.convId));
    //         }
    //     }
    // }, [listen, curentUserChat, unreadMess]);



    // console.log('UserCard', currentChat, friend);
    // console.log('UserCard', online);
    // console.log('MESS', mess);
    // console.log('Friend', friend);

    useEffect(() => {
        // FOR GLOBAL NOTIFICATION
        if (mounted.current === true) {
            if (listen !== undefined) {
                if (listen.senderId === friend.idUsers) {
                    if (user.idUser !== listen.senderId && curentUserChat === undefined) {
                        dispatch(globalNotif(true));
                    } else {
                        if (curentUserChat.idUsers !== listen.senderId) {
                            dispatch(globalNotif(true));
                        }
                        else {
                            dispatch(globalNotif(false));
                            setListen(undefined);
                            // dispatch(setMsgListen(undefined));
                        }
                    }
                }
            } else {
                if (unreadMess !== undefined) {
                    const find = unreadMess?.filter(un => un.SenderID !== user.idUser.toString());
                    if (find.length > 0) {
                        dispatch(globalNotif(true));
                    }
                }
                // else {
                //     dispatch(globalNotif(false));
                // };

                if (curentUserChat?.idUsers === friend.idUsers) {
                    dispatch(globalNotif(false));
                };
            };
        }
        // return () => {
        //     dispatch(globalNotif(false));
        // }
    }, [listen, curentUserChat, unreadMess, mounted.current]);

    // console.log('MOUNT?', mounted.current);
    // console.log('unreadMess', unreadMess);

    return (
        friend !== undefined &&
        <GeneralWrapper
            select={currentChat?.userId === friend?.idUsers.toString() ? true : false}
            status={online}
        >
            {/* <img src={userA} alt='img'></img> */}
            <AvatarDisplay><h4>{getInitials(friend)}</h4></AvatarDisplay>
            <div>
                <h5>{friend?.user_name + ' ' + friend?.user_surname}</h5>
                <div >
                    <span></span>
                    <h6>{online ? 'Online' : 'Offline'}</h6>
                </div>

            </div>

            {notif &&
                <MessengerNotif />
            }

        </GeneralWrapper>
    );
}

export default UserCard;