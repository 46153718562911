const initState = {

};

const coursesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ALL_COURSES':
            return {
                ...state,
                allCourses: action.payload,
            };
        case 'A_COURSE':
            return {
                ...state,
                aCourse: action.payload,
            };

        default:
            return state;
    }
};

export default coursesReducer;