import { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";

// ASSETS
import Loading from '../../../../Assets/loading.gif';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { getSelectedQuestionsForTest, saveTest, resetTest } from '../../../Store/Actions/testActions';
// import { checkIfExists } from '../../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../PagesShareStyle/PagesShareStyle';


const PreQuest = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const questDone = useSelector(({ subjects }) => subjects.ifExistDone);
    // const savedTest = useSelector(({ test }) => test.savedTest);
    const questions = details.que.QuestionaryQuizzes;

    // console.log('PreQuest details', details);

    // useEffect(() => {
    //     dispatch(checkIfExists(details.que.idQuestionnaire))
    // }, [dispatch]);

    useEffect(() => {
        // if (questDone !== undefined) {
            // if (questDone === null) {
            setTimeout(() => {
                history.push({
                    pathname: '/dashboard/quest',
                    // state: { que: questions, asw: details.que }
                    state: { que: questions, quest: details.que, course: details.course}
                });

            }, 2000);
            // } else {
            //     setTimeout(() => {
            //         history.push({
            //             pathname: '/dashboard/quest-verification',
            //             state: { que: questions, quest: details.que, asw: JSON.parse(questDone.quezzes) }
            //         });

            //     }, 2000);
            // }
        // };
    }, []);



    // useEffect(() => {
    //     if (tests !== undefined) {
    //         dispatch(saveTest(tests, details.course, details.flag));
    //     }
    // }, [tests]);

    // useEffect(() => {
    //     if (savedTest !== undefined) {
    //         // history.push('/dashboard/create-quiz/test', savedTest);

    //         setTimeout(() => {
    //             const url = '/dashboard/create-quiz/test/' + savedTest.idTestExercises;
    //             const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    //             if (newWindow) newWindow.opener = null;
    //             dispatch(resetTest())
    //             history.goBack()
    //         }, 2000)
    //     }
    // }, [savedTest]);

    return (
        <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img src={Loading} alt="Loading..."></img>
            <div>Inizia quest...</div>
        </WrapperRightSide>
    );
}

export default PreQuest;