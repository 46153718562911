import React, { useState, useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import GoBackTitle from '../../GeneralComponents/GoBackButton/GoBackButton';
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './ViewTestsTable';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getTestsDoneForUser } from '../../../Store/Actions/tpaActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

const tableHead = [
    'Num',
    'Tipo di test',
    'Data',
    'Giudizio',
    'Azioni',
];

const ViewTests = ({ details }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    const allTpaDoneForUser = useSelector(({ tpa }) => tpa.allTpaDoneForUser);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [types, setTypes] = useState([]);
    const [type, setType] = useState([]);
    // const [idsTestsDone, setIdsTestsDone] = useState()


    useEffect(() => {
        dispatch(getTestsDoneForUser(details.idsTestsDone));
    }, [dispatch]);

    //console.log('ViewTest', type);

    return (
        <WrapperRightSide>

            <GoBackTitle
                title={details.elem.user_name + ' ' + details.elem.user_surname}
            />

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    type={allTpaDoneForUser}
                    flag={'tpaDone'}
                    setType={setType}
                />

            </ActionsWrapperSpace>

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <Btn
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/courses/newCourse'>
                        <i className="fas fa-plus"></i>Utenti
                    </Btn>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 300px)' }}>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono risultati da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono risultati da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                            index={i}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={type}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default ViewTests;