import React from 'react';

// PACKAGES
import styled from 'styled-components';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    GoBack,
} from '../../../PagesShareStyle/PagesShareStyle';
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    QueContainer,
    ResContainer
} from './Style';


const ScalettaView = ({ oneTema }) => {
    console.log('TEMA VIEW', oneTema);

    return (
        <Wrapper>
            <div style={{ width: '100%' }}>
                <QueContainer>
                    {oneTema.idTraceDone_TraceAnalysisDone.idTraceAnalysis_TraceAnalysis.title}
                </QueContainer>
                <LabelCss htmlFor="asw1">Introduzione</LabelCss>
                <ResContainer>
                    {JSON.parse(oneTema.introduction).map((int, i) =>
                        <div key={i}>
                            <span>{int.intro}</span>
                            <span>-</span>
                            <span>{int.subIntro}</span>
                        </div>
                    )}
                </ResContainer>

                <LabelCss htmlFor="asw2">Corpo del testo (parole chiave)</LabelCss>
                <ResContainer>
                    {JSON.parse(oneTema.body).map((md, i) =>
                        <div key={i}>
                            <span>{md.midd}</span>
                            <span>-</span>
                            <span>{md.subMidd}</span>
                        </div>
                    )}
                </ResContainer>

                <LabelCss htmlFor="asw2">Conclusione</LabelCss>
                <ResContainer>
                    {JSON.parse(oneTema.end).map((con, i) =>
                        <div key={i}>
                            <span>{con.conc}</span>
                            <span>-</span>
                            <span>{con.subConc}</span>
                        </div>
                    )}
                </ResContainer>
            </div>

            <CommentWrapper>
                {oneTema.comments !== null &&
                    JSON.parse(oneTema.comments).map((comment, i) =>
                        <div key={i}>
                            <span>{i + 1}.</span>
                            {comment.message}
                        </div>
                    )
                }
            </CommentWrapper>
        </Wrapper>
    );
}

export default ScalettaView;