import { newNotifications } from './notificationsActions';
import axios from 'axios';
axios.defaults.withCredentials = true;



export const bringTpaExercises = (param, choose) => (dispatch, getState) => {
    let body;

    // console.log("PARAM", param, "\n", "CHOOSE", choose)

    if (param === 'test-grafici') {
        body = {
            type: param,
            choose: choose
        };
    } else {
        body = {
            type: param,
        };
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/bringTpaExercises`, body)
        .then((res) => {
            // console.log("ALL_TPA_EXERCICES", res.data);
            dispatch({
                type: 'ALL_TPA_EXERCICES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getGrafici = (param) => (dispatch, getState) => {

    const body = {
        type: param,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getGrafici`, body)
        .then((res) => {
            // console.log("ALL_TPA_GRAFICI", res.data);
            dispatch({
                type: 'ALL_TPA_GRAFICI',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

// export const saveTpaTest = (obj, idCourse, type, tests) => (dispatch, getState) => {
export const saveTpaTest = (param, quests, ans, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType,
    verif) => (dispatch, getState) => {
        const profile = getState().auth.user;

        // const body = {
        //     type: param,
        //     questions: quests,
        //     answers: ans,
        //     idUser: idUser,
        //     idCourse: idCourse
        // };

        // console.log('OBJ ACTIONS', obj);

        let config;
        let body;

        if (ans[0].file === '' || ans[0].file === undefined) {
            config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            body = {
                // asw: JSON.stringify(obj),
                // questions: JSON.stringify(tests),
                // type: type,
                // userID: profile.idUser,
                // courseID: idCourse

                type: param,
                questions: quests,
                answers: ans,
                idUser: idUser,
                idCourse: idCourse
            };
        } else {
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            body = new FormData()
            // body.append('file', ans[0].file)
            // body.append('questions', JSON.stringify(tests))
            // body.append('type', type)
            // body.append('userID', profile.idUser)
            // body.append('courseID', idCourse)
            // body.append('flag', 'file')

            body.append('file', ans[0].file)
            body.append('questions', JSON.stringify(quests))
            body.append('type', param)
            body.append('idUser', idUser)
            body.append('idCourse', idCourse)
            body.append('flag', 'file')
        }

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveTpaTest`, body, config)
            .then((res) => {
                console.log("ALL_TPA_EXERCICES", res.data);
                // dispatch({
                //     type: 'ALL_TPA_EXERCICES',
                //     payload: res.data,
                // });

                // const newRes = {
                //     idTpaDone: res.data.idTpaDone,
                //     answers: JSON.stringify(res.data.answers),
                //     questions: JSON.stringify(res.data.questions),
                //     type: res.data.type,
                //     date: res.data.date,
                //     idUser: res.data.idUser,
                //     idCourse: res.data.idCourse
                // }

                if (verif === ans.length) {
                    dispatch(newNotifications(idUser,
                        allCenterTeachers,
                        whoAmI,
                        aCourse,
                        res.data,
                        tpaType
                    ));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

export const saveTpaVerificationWithDrawing = (obj, grade, idTpa, inputFields, whoAmI) => (dispatch, getState) => {

    const body = {
        png: obj[0].png,
        grade: grade,
        idTpa: idTpa,
        inputFields: inputFields,
        idProf: whoAmI
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveTpaVerification`, body)
        .then((res) => {
            // console.log("TPA_VERIFICATION", res.data);
            // dispatch({
            //     type: 'TPA_VERIFICATION',
            //     payload: res.data,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const saveTpaVerification = (obj, grade, idTpa, commentsArr, whoAmI) => (dispatch, getState) => {
    console.log('TPA ACTION', commentsArr)
    const body = {
        png: obj[0].png,
        grade: grade,
        idTpa: idTpa,
        inputFields: commentsArr,
        idProf: whoAmI,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveTpaVerification`, body)
        .then((res) => {
            // console.log("TPA_VERIFICATION", res.data);
            // dispatch({
            //     type: 'TPA_VERIFICATION',
            //     payload: res.data,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const saveExistingTpaTest = (param, quests, ans, idUser, idCourse, idDone) => (dispatch, getState) => {
    const profile = getState().auth.user;

    // const body = {
    //     type: param,
    //     questions: quests,
    //     answers: ans,
    //     idUser: idUser,
    //     idCourse: idCourse
    // };

    // console.log('OBJ ACTIONS', obj);

    let config;
    let body;

    if (ans[0].file === '' || ans[0].file === undefined) {
        config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        body = {
            type: param,
            questions: quests,
            answers: ans,
            idUser: idUser,
            idCourse: idCourse,
            idTpaDone: idDone
        };
    } else {
        config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        body = new FormData()

        body.append('file', ans[0].file)
        body.append('questions', JSON.stringify(quests))
        body.append('type', param)
        body.append('idUser', idUser)
        body.append('idCourse', idCourse)
        body.append('idTpaDone', idDone)
        body.append('flag', 'file')
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveExistingTpaTest`, body, config)
        .then((res) => {
            dispatch({
                type: 'ALL_TPA_EXERCICES',
                payload: res.data,
            })
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllTypologies = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllTypologies`)
        .then((res) => {
            // console.log("ALL_TPA_TYPOLOGIES", res.data);
            dispatch({
                type: 'ALL_TPA_TYPOLOGIES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const allDrawing = (id) => (dispatch, getState) => {
    const body = {
        idTpa: id
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllDrawings`, body)
        .then((res) => {
            // console.log("ALL_ALL_TPA_DONE", res.data);
            dispatch({
                type: 'ALL_ALL_TPA_DONE',
                payload: res.data,
            })
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllTpaTestDone = (id) => (dispatch, getState) => {

    const body = {
        idUser: id
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllTpaTestDone`, body)
        .then((res) => {
            //console.log("ALL_TPA_DONE", res.data);
            dispatch({
                type: 'ALL_TPA_DONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getTestsDoneForUser = (idsArr) => (dispatch, getState) => {

    const body = {
        ids: idsArr
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getTestsDoneForUser`, body)
        .then((res) => {
            // console.log("TPA_DONE_FOR_USER", res.data);
            dispatch({
                type: 'TPA_DONE_FOR_USER',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getUpdateTestDone = (type, idUser) => (dispatch, getState) => {

    const body = {
        type: type,
        idUser: idUser
        // ids: idsArr
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getUpdateTestDone`, body)
        .then((res) => {
            // console.log("TPA_DONE_FOR_USER", res.data);
            dispatch({
                type: 'TPA_UPDATE_TABLE_TEST',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

// export const getTestTpaDoneForStudent = (idTpa) => (dispatch, getState) => {

//     const body = {
//         idTpa: idTpa
//     };

//     axios
//         .post(`${process.env.REACT_APP_URL_BACKEND}/getTestTpaDoneForStudent`, body)
//         .then((res) => {
//             // console.log("TPA_DONE_FOR_USER", res.data);
//             dispatch({
//                 type: 'TPA_DONE_FOR_STUDENT',
//                 payload: res.data,
//             });
//         })
//         .catch((err) => {
//             console.log(err);
//         });
// };