import React, { useState, useEffect, useRef } from 'react';
import pic from '../../../../Assets/favpng_eraser.png';

// PACKAGES
import styled from 'styled-components';
// import CanvasDraw from "react-canvas-draw";
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { SketchPicker } from 'react-color';
// import {
//     exportComponentAsJPEG,
//     exportComponentAsPDF,
//     exportComponentAsPNG
// } from "react-component-export-image";
import html2canvas from 'html2canvas';

// COMPONENTS
import Nav from './DrawingNav';
import Tabs from './Tabs';
import Upload from './UploadInput';
import CommentModal from '../../../GeneralComponents/Modals/CommentModal';

// REDUX
// import { useSelector, useDispatch } from 'react-redux';


const TestBody = styled.div`
    margin-right: 10px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const Question = styled.div`
    color:  var(--text2);
    font-size: 25px;
    font-weight: bold;
    text-transform:uppercase ;
`;

const EditorWrapper = styled.div`
    position: relative;
    background: var(--background3);
    /* background: red; */
    padding: 10px;
    margin-top: 10px;
    height: calc(100vh - 370px);
    overflow: hidden;
    >h6{
        text-align: center;
    }
`;

const BtnWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
        /* @media (max-width: 1100px) {
            flex-wrap: wrap;
        }; */
`;

const Btn = styled.button`
    padding: 10px 30px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content:center;
    /* color: var(--text2); */
    color: ${props => props.activ === 'true' ? 'var(--text5)' : 'var(--text2)'};
    font-weight: bold;
    font-size: 14px;
    border-radius: var(--radius);
    margin-top: 10px;
    margin-bottom:10px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    /* background: var(--background4); */
    background: ${props => props.activ === 'true' ? 'var(--acc-color)' : 'var(--background4)'};
    border: none;
    outline:none;
    box-shadow: var(--shadow);
        >i{
            margin-right: 10px;
            font-size: 20px;
        }
        &:hover{
            box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
        }
        &:active{
            box-shadow: none;
        }
        @media (max-width: 1100px) {
            padding: 10px;
            >span{
                display:none;
            }
            >i{
                font-size: 18px;
                margin-right: 0px;
            }
        };
`;

const PickerWrapper = styled.div`
    position: absolute;
    top: 60px;
    right: 50%;
    transform: translateX(50%);
    overflow: auto;
    z-index: 2;
    text-align: center;
    color: var(--text2);
    border-radius: var(--radius);
    background: var(--background4);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
`;

const ReminderWrapper = styled.div`
    position: relative;
`;

const CanvasWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: calc(100% - 80px);
    max-height: 1000px;
    /* border: 1px solid var(--text3); */
    margin: auto;
    overflow: hidden;
    /* cursor: url('/src/Assets/eraser.png'), auto;	 */
        >img{
            position: absolute;
            /* width: 100%; */
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            pointer-events: none;
            user-select: none;
        }
`;

const Comment = styled.div`
 position: absolute;
    right: 10px;
    top: 0px;
    z-index: 1;

    width: 100%;
	max-width: 200px;
    height: 250px;
    resize: none;
    padding: 6px;
    margin-top: 10px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    background: transparent;
    color: red;
    font-weight: bold;
    transition: var(--transition) ease;
    font-size: 18px;
`;

const styles = {
    // border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.3rem',
    // background: '#dcdcdd38',
    zIndex: '12'
};


const Desc = styled.div`
    font-size:20px;
    color:var(--text2);
`;

const DrawingCom = ({ tests, id, setAnswers, obj }) => {
    const canvas = useRef();
    const png = useRef();
    const [color, setColor] = useState('black');
    const [erase, setErase] = useState(false);
    const [ceva, setCeva] = useState();
    const [newFile, setNewFile] = useState('');
    const [curentBtn, setCurentBtn] = useState();
    const [comment, setComment] = useState(false);
    const [message, setMessage] = useState('');

    const [img, setImg] = useState();

    // const save = () => {
    // setAnswers(canvasRef.current.getSaveData());
    // };

    // useEffect(() => {
    //     if (obj.length > 0) {
    //         const value = [...obj]
    //         if (Object.keys(value[id]).length > 0) {
    //             canvasRef.current.loadSaveData(`${obj[id].asw}`, true)
    //         } else {
    //             canvasRef.current.eraseAll();
    //         }
    //     }
    // }, [id, obj]);

    useEffect(() => {
        if (curentBtn === undefined || curentBtn === '1') {
            canvas.current.clearCanvas();
            if (obj.length > 0) {
                if (Object.keys(obj[id]).length > 0) {
                    canvas.current.loadPaths(obj[id].asw)
                } else {
                    canvas.current.clearCanvas();
                }
            }
        }
    }, [id, obj]);

    useEffect(() => {
        if (curentBtn === undefined || curentBtn === '1') {
            canvas.current.eraseMode(erase)
        }
    }, [erase]);

    // const handleChange = evt => {
    //     setMessage(evt.target.value)
    // };

    const save = () => {
        if (curentBtn === undefined || curentBtn === '1') {
            let ob = {
                e: '',
                svg: '',
                png: ''
            }
            // console.log(ob);
            canvas.current.exportPaths()
                .then(res =>
                    ob.e = res
                )
            // canvas.current.exportImage("png")
            //     .then(res => {
            //         ob.png = res
            //         setImg(res);
            //         setTimeout(() => {
            //             setAnswers(ob)
            //         }, 450)
            //     }
            //     )
            canvas.current.exportSvg()
                .then(res => {
                    ob.svg = res
                    handleCreateImage()
                }
                )
                .then(() =>
                    setTimeout(() => {
                        setAnswers(ob)
                    }, 450)
                    // console.log(ob)
                    // setAnswers(ob)
                )

            const handleCreateImage = async () => {
                const element = document.getElementById('capture'),
                    canvas = await html2canvas(element),
                    data = canvas.toDataURL('image/jpg');
                // console.log('CANVAS', data);
                setImg(data);
                ob.png = data
            };
        }

    };

    const handleFile = (e) => {
        setNewFile(e);
        let ob = {
            e: '',
            svg: '',
            file: e
        }
        setAnswers(ob);
    };

    // console.log("test", tests);

    return (
        <TestBody>
            <Question>{tests[id].title}</Question>
            <Desc>{tests[id].body}</Desc>

            <Tabs
                curentBtn={curentBtn}
                setCurentBtn={setCurentBtn}
            />

            {/* <img src={img} alt='img'></img> */}


            {/* {obj.length > 0 &&

                <div 
                    dangerouslySetInnerHTML={{ __html: obj[0].svg }}
                />
            } */}


            {(curentBtn === undefined || curentBtn === '1') &&
                <EditorWrapper onMouseLeave={save}  >
                    <Nav
                        canvas={canvas}
                        color={color}
                        setColor={setColor}
                        erase={erase}
                        setErase={setErase}
                        comment={comment}
                        setComment={setComment}
                    />

                    <CanvasWrapper ref={png} id='capture'>

                        {(tests[id].img !== undefined && tests[id].img !== null) &&
                            <img src={tests[id].img} alt='img'></img>
                        }

                        <ReactSketchCanvas
                            ref={canvas}
                            style={styles}
                            strokeWidth={4}
                            strokeColor={`${color}`}
                            // backgroundImage={(tests[id].img !== undefined && tests[id].img !== null) &&
                            //     tests[id].img}
                            // exportWithBackgroundImage={true}
                            // backgroundImage={Svg}
                            preserveBackgroundImageAspectRatio="xMidYMid meet"
                            eraserWidth={14}
                        />

                        {message !== '' &&
                            <Comment>
                                {message}
                            </Comment>
                        }
                    </CanvasWrapper>

                    {comment &&
                        <CommentModal
                            message={message}
                            setMessage={setMessage}
                            comment={comment}
                            setComment={setComment}
                        />
                    }
                </EditorWrapper>
            }

            {curentBtn === '2' &&
                <Upload
                    newFile={newFile}
                    setNewFile={handleFile}
                />
            }
        </TestBody>
    );
}

export default DrawingCom;


{/* <EditorWrapper > */ }

{/* <BtnWrapper>
                     <Btn onClick={() => canvasRef.current.undo()}>
                     <Btn onClick={() => canvas.current.undo()}>
                         <i className="fa-solid fa-reply"></i>
                         <span>Disfare</span>
                     </Btn>
                     <Btn onClick={() => canvas.current.redo()}>
                         <i style={{ transform: 'scaleX(-1)' }} className="fa-solid fa-reply"></i>
                         <span>Rifare</span>
                     </Btn>
                     <Btn onClick={() => canvasRef.current.eraseAll()}>
                     <Btn
                         onClick={() => setErase(!erase)}
                         activ={`${erase}`}
                     >
                         <i className="fa-solid fa-eraser"></i>
                         <span>Gomma</span>
                     </Btn>
 
                     <ReminderWrapper ref={openPickerRef}>
                         <Btn onClick={() => setOpenColorModal(!openColorModal)}>
                             <i className="fa-solid fa-paintbrush"></i>
                             <span>Color</span>
                         </Btn>
                         {openColorModal &&
                             <PickerWrapper >
                                 <SketchPicker
                                     color={color}
                                     onChange={handleColorChange}
                                 />
                             </PickerWrapper>
                         }
                     </ReminderWrapper>
                 </BtnWrapper> */}

//      <Nav
//      canvas={canvas}
//      color={color}
//      setColor={setColor}
//      erase={erase}
//      setErase={setErase}
//  />

{/* <h6>CTRL + Scroll per ingrandire o ridurre</h6> */ }

{/* {obj.length > 0 &&
              Object.keys(obj[id]).length > 0 &&
              <div dangerouslySetInnerHTML={{ __html: obj[id].svg }} />
          } */}


//  <CanvasWrapper >
{/* {(tests[id].img !== undefined && tests[id].img !== null) &&
                  <img src={tests[id].img} alt='img'></img>
              } */}
{/* <CanvasDraw
                  ref={canvasRef}
                  // imgSrc={(tests[id].img !== undefined && tests[id].img !== null) &&
                  //     tests[id].img
                  // }
                  style={{
                      width: '100%',
                      height: '100%',
                    }}
                  brushRadius={1}
                  brushColor={`${color}`}
                  lazyRadius={1}
                  hideGrid={true}
                  disabled= {false}
                  // enablePanAndZoom= {true}
              /> */}

{/* <ReactSketchCanvas
                     ref={canvas}
                     style={styles}
                     strokeWidth={4}
                     strokeColor={`${color}`}
                     backgroundImage={(tests[id].img !== undefined && tests[id].img !== null) &&
                         tests[id].img}
                     exportWithBackgroundImage={true}
                     // backgroundImage={Svg}
                     preserveBackgroundImageAspectRatio="xMidYMid meet"
                     eraserWidth={14}

                 />
             </CanvasWrapper> */}

{/* {comment &&
                        <TextArea
                            placeholder="Tu comentario aquí"
                            type="text"
                            name='message'
                            required
                            value={message}
                            onChange={(e) => handleChange(e)}
                        />
                    } */}