import React, { useEffect, useState } from 'react';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Search from './Search';
import Title from '../SectionsTitle';
import UserCard from './UserCard';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getConversations, findFriends } from '../../../../../Store/Actions/conversationsActions';
import { openMessSidebar } from '../../../../../Store/Actions/otherActions';

const GeneralWrapper = styled.div`
    position: relative;
`;

const Header = styled.div`
    padding: 10px;
    border-bottom: 1px solid var(--border2);
`;

const Body = styled.div`
    height: calc(100vh - 250px);
    overflow-y: scroll;
`;


const Menu = ({ handleConversation, currentChat, onlineUsers, curentUserChat }) => {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);
    const conv = useSelector(({ conversations }) => conversations.conv);
    const friends = useSelector(({ conversations }) => conversations.friends);
    // const [friends, setFriends] = useState();
    const [chat, setChat] = useState();
    const [searched, setSearched] = useState();

    useEffect(() => {
        if (user !== undefined) {
            dispatch(getConversations(user.idUser));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (user !== undefined) {
            if (conv !== undefined) {
                // let array = [];
                let array2 = []
                for (let i = 0; i < conv.length; i++) {
                    // array.push(conv[i].From, conv[i].To)
                    // console.log('conv', conv);
                    array2.push(
                        { convId: conv[i].idConversations, userId: conv[i].from },
                        { convId: conv[i].idConversations, userId: conv[i].to }
                    )
                };
                // const friendId = array.filter((m) => m !== user.userId);

                const filterFriends = array2.filter((m) => m.userId !== user.idUser.toString());
                // setFriends(filterFriends);
                setChat(filterFriends);

                dispatch(findFriends(filterFriends));
            }
        }
    }, [dispatch, conv, user]);

    // useEffect(() => {
    //     if (friends !== undefined) {
    //         dispatch(findFriends(friends));
    //     }
    // }, [friends]);

    const messSidebar = () => {
        dispatch(openMessSidebar(false))
    };

    // console.log('FRIENDS', friends);
    // console.log('MENU',
    //     conv,
    //     // user,
    //     // friends
    // );

    return (
        <GeneralWrapper >
            <Header>
                <Title title='Messaggi' />
                <Search
                    flag='chats'
                    toSearch={friends}
                    setSearched={setSearched}
                />
            </Header>
            <Body >
                {searched !== undefined &&
                    searched.map((fr, i) =>
                        <div key={i} onClick={() => { handleConversation({ chat: chat[i], user: fr }); messSidebar() }}>
                            <UserCard
                                friend={fr}
                                currentChat={currentChat}
                                onlineUsers={onlineUsers}
                                // listen={listen}
                                curentUserChat={curentUserChat}
                            // setListen={setListen}
                            />
                        </div>
                    )
                }
            </Body>
        </GeneralWrapper>
    );
}

export default Menu;