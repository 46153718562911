import { useEffect, useState, useRef } from 'react';

// ASSETS
import loading from '../../../Assets/loadingGif.gif';

// PACKAGES
import styled from 'styled-components';
import Select from 'react-select';
import {
    Chart as ChartJS,
    ChartArea,
    ArcElement,
    CategoryScale,
    LinearScale,
    LineController,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';
import {
    Line,
    getDatasetAtEvent,
    getElementsAtEvent,
    getElementAtEvent
} from 'react-chartjs-2';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getDataForChartLine } from '../../../Store/Actions/statisticsActions';

const Body = styled.div`
    position: relative;
    width: 100%;
    /* height: 350px; */
    background: white;
    /* margin-top: 40px; */
    border-radius: var(--radius);
    padding: 20px;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        >div{
            display: flex;
            justify-content: space-between;
             >h5{
                font-weight: bold;
                color: var(--text4);
                margin-bottom: 5px;
            }
        }
        >h4{
            color: var(--text2);
            font-weight: bold; 
            padding-bottom: 20px;
            >span{
                color: var(--success);
                font-size: 18px;
                font-weight: bold; 
                margin-left: 10px;
            }
        }
`;

const colourStyles = {
    control: (styles, state) => ({
        ...styles,
        width: 150,
        height: '40px',
        borderRadius: '40px',
        // backgroundImage: 'linear-gradient(-225deg,#A445B2 0%,#D41872 52%,#d32058 100%)',
        backgroundColor: 'rgba(115, 102, 255, 0.08)',
        opacity: '0.7',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        // color: 'var(--text5)',
        color: '#7366ff',
        fontWeight: 'bold',
        // boxShadow: '0 0.3125rem 0.625rem 0 rgb(0 0 0 / 12%)',
        border: 'transparent',
    }),
    menu: styles => ({
        ...styles,
        width: 150,
        padding: 10,
        backgroundColor: 'var(--background4)',
        border: 'none',
        borderRadius: 'var(--radius)',
        fontSize: '12px',
        color: 'var(--text2)',
        fontWeight: 'bold',
        zIndex: 2,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? 'var(--acc-color)'
                : isFocused
                    ? 'var(--text4)'
                    : undefined,
        borderRadius: 'var(--radius)',
        marginTop: 2,
        marginBottom: 2,
        cursor: 'pointer',
        // cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                    ? 'var(--acc-color)'
                    : 'var(--text4)'
                : undefined,
        },
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text5)!important' }),
    placeholder: styles => ({ ...styles, fontSize: '12px', fontWeight: '100', color: 'var(--text5)' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px', color: '#7366ff' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
    dropdownIndicator: (styles, { data }) => ({ ...styles, color: '#fffff' }),
    indicatorSeparator: (styles, { data }) => ({ ...styles, backgroundColor: '#ffffff4a', width: '2px' }),
};

const LoadingDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #ffffff93;
    backdrop-filter: blur(3px);
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >div{
            >img{
            width: 120px;
            }
            >h4{
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -60%);
                white-space: nowrap;
            }
        }
       
`;


const monthEN = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
// const monthIT = [
//     "Gennaio",
//     "Febbraio",
//     "Marzo",
//     "Aprile",
//     "Maggio",
//     "Giugno",
//     "Luglio",
//     "Agosto",
//     "Settembre",
//     "Ottobre",
//     "Novembre",
//     "Dicembre",
// ];

const monthIT = [
    "Gen",
    "Feb",
    "Mar",
    "Apr",
    "Mag",
    "Giu",
    "Lug",
    "Ago",
    "Set",
    "Ott",
    "Nov",
    "Dic",
];


const ChartLine = () => {
    const dispatch = useDispatch();
    const chartRefLine = useRef();
    const profile = useSelector(({ auth }) => auth.user);
    const statistics = useSelector(({ statistics }) => statistics.statChartLine);
    const [dataLine, setDataLine] = useState({
        // labels: monthIT,
        labels: monthIT,
        datasets: []
    });
    const [yersOpt, setYearsOpt] = useState();
    const [selectYear, setSelectYear] = useState({ label: new Date().getFullYear(), value: new Date().getFullYear() })

    //console.log("STS", statistics)
    ChartJS.register(
        ArcElement,
        CategoryScale,
        LinearScale,
        LineController,
        PointElement,
        LineElement,
        Tooltip,
        Legend,
        Filler,
    );

    // var date1 = new Date('2022-09-05');
    // var date2 = new Date('2024-12-31');
    // var diffDays = date2.getDate() - date1.getDate();
    // console.log(selectYear);

    useEffect(() => {
        if (statistics !== undefined) {
            let arr = [];
            statistics.forEach(el => {
                arr.push({ label: el.year, value: el.year })
            });
            setYearsOpt(arr.reverse())
        }
    }, [statistics]);

    useEffect(() => {
        if (statistics === undefined) {
            dispatch(getDataForChartLine(profile.idUser));
        }
    }, [statistics]);

    useEffect(() => {
        const chart = chartRefLine.current;

        if (!chart) {
            return;
        };

        let arrMonths = [];
        // let arrMonthsLebels = [];
        if (statistics !== undefined) {
            const filterStatistics = statistics.filter(stat => stat.year === selectYear.value);
            // console.log('filterStatistics', filterStatistics);
            const months = filterStatistics.length > 0 && filterStatistics[0].months;
            for (let i = 0; i < months.length; i++) {
                if (Object.keys(months[i]).length > 0) {
                    arrMonths.push(months[i].percentTotal);
                } else {
                    arrMonths.push('0.00');
                }
                // arrMonths.push(months[i].percentTotal);
            };

            // for (let i = 0; i < months.length; i++) {
            //     arrMonthsLebels.push(monthIT[i])
            // };
        }

        setDataLine({
            ...dataLine,
            datasets: [
                {
                    label: selectYear.value,
                    fill: true,
                    borderColor: "rgba(153, 102, 255)",
                    backgroundColor: createGradient(chart.ctx, chart.chartArea),
                    // backgroundColor: "rgba(153, 102, 255)",
                    borderWidth: 3,
                    borderDashOffset: 1,
                    pointBackgroundColor: "#fff",
                    pointRadius: 1,
                    lineTension: 0.5,
                    // data: [6, 20, 80, 40, 56, 55, 40, 66, 78, 10., 33, 15],
                    data: statistics !== undefined ? arrMonths : [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50]
                }
            ],
            // labels: arrMonthsLebels
        })

    }, [statistics, selectYear]);

    const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
        let gradient = ctx.createLinearGradient(0, 150, 0, 0);
        gradient.addColorStop(1, "rgba(153, 102, 255, 1)");
        gradient.addColorStop(0, "rgba(153, 102, 255, 0)");
        gradient.addColorStop(0.5, "rgba(153, 102, 255, 0.3)");

        return gradient;
    };

    // const lineShadow = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
    //     let draw = ctx.controllers.line.prototype.draw;
    //     ctx.controllers.line = ctx.controllers.line.extend({
    //         draw: function () {
    //             draw.apply(this, arguments);
    //             let ctx = this.chart.chart.ctx;
    //             let _stroke = ctx.stroke;
    //             ctx.stroke = function () {
    //                 ctx.save();
    //                 ctx.shadowColor = '#E56590';
    //                 ctx.shadowBlur = 10;
    //                 ctx.shadowOffsetX = 0;
    //                 ctx.shadowOffsetY = 4;
    //                 _stroke.apply(this, arguments)
    //                 ctx.restore();
    //             }
    //         }
    //     });
    //     return draw
    // };

    const options = {
        maintainAspectRatio: false,
        hitRadius: 140,
        hoverRadius: 4,
        responsive: true,
        resizeDelay: 0,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {

                grid: {
                    // display: true,
                    drawTicks: false,
                    lineWidth: 1,
                    color: '#efefef',
                },
                ticks: {
                    color: '#c1c4d1',
                    autoSkip: false,
                    maxRotation: 45,
                    minRotation: 45,
                    padding: 10,
                    font: {
                        size: 12,
                        weight: 'bold',
                    }
                }
            },
            y: {
                min: 0,
                max: 100,
                display: false,
                grid: {
                    // display: true,
                    lineWidth: 0,
                    tickBorderDash: [1, 15],
                    tickBorderDashOffset: 1,
                    borderColor: '#efefef',
                },
                ticks: {
                    color: '#c1c4d1',
                    stepSize: 50,
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                    callback: function (value) {
                        return value + '%'
                    },
                }
            }
        }

    };


    return (
        <Body>
            <div>
                <h5>Panoramica delle attività</h5>
                <Select
                    name='type'
                    isMulti={false}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    defaultValue={selectYear}
                    onChange={(e) => setSelectYear(e)}
                    // onChange={(e) =>
                    //     setDef({
                    //         ...def,
                    //         type: e
                    //     })
                    // }
                    options={yersOpt}
                    styles={colourStyles}
                    placeholder={'Anno'}
                />
            </div>
            <h4>Tendenza
                {/* <span>+90%</span> */}
            </h4>
            <div >
                <Line
                    ref={chartRefLine}
                    data={dataLine}
                    // width={300}
                    height={300}
                    options={options}
                />

                {statistics === undefined &&
                    < LoadingDiv >
                        <div>
                            <img src={loading} alt='Loading...'></img>
                            <h4 style={{ color: 'var(--text2)' }}>Caricamento dati...</h4>
                        </div>
                    </LoadingDiv>
                }
            </div>
        </Body >

    );
}

export default ChartLine;