import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringTestsDoneForUsers, bringSimulationsDoneForUsers, getAllWrongQuezzes } from '../../../Store/Actions/usersActions';
import { bringSimulationsDone } from '../../../Store/Actions/testActions';

const CardWrapper = styled(Link)`
    position: relative;
    width: 250px;
    height: ${props => props.flag === 'true' ? '250px' : '250px'};
    background-color: var(--background4);
    margin: 20px 0px;
    padding: 15px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    cursor: pointer;
    text-decoration: none;
    color: var(--text3);
    font-size: 20px;
    border:none;
    outline: none;
    /* transition: all .4s ease-in-out; */
        >i{
            font-size: 80px;
            margin-bottom: 10px;
            color: var(--text3);
        }
        &:hover{
            box-shadow: var(--shadow);
            /* transform:translateY(-2px); */
            color: var(--acc-color);
            font-weight: bold;
            /* transition: color .4s ease-in-out; */
            /* >i{
                color: var(--acc-color);
                transition: color .3s ease-in-out;
            } */
        }
`;

const Hr = styled.hr`
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--text4);
    margin: 10px 0;
`;

const Number = styled.div`
    font-size: 50px;
    font-weight: bold;
    color:  var(--text1);
    > img{
        width: 20px;
    }
`;

const LinkWrapper = styled.div`
   margin-top: 0px;
`;

const BtnLink = styled(Link)`
    border:none;
    outline: none;
    color: var(--text3);
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* background: var( --background1);
    color: var(--text5);
    border-radius: var(--radius);
    padding: 10px 30px;
    width: 230px; */
        &:hover{
            transition: .2s ease-in-out;
            color:  var(--acc-color);   
            font-weight: bold;

            /* background:var(--acc-color);
            font-weight: bold; */
        }
        &:active{
                transform:translateY(2px);
        }  
`;


//const ExerciziCardNew = ({ type, flag, course, user }) => {
const ExerciziCardNew = ({ type, flag, course, studDetails }) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    const testsDone = useSelector((state) => state.test.bringTestsDone);
    const simulationsDone = useSelector((state) => state.test.bringSimulationsDone);
    const wrong = useSelector(({ test }) => test.bringWrongQuezzes);
    // const id = 1;

    const [mono, setMono] = useState([]);
    const [misti, setMisti] = useState([]);
    const [sim, setSim] = useState([]);

    // console.log("WRONG", wrong)
    // console.log("TYPE", type)
    useEffect(() => {
        if (studDetails !== undefined) {
            dispatch(bringSimulationsDoneForUsers(course));
        } else {
            dispatch(bringSimulationsDone(course));
        }

        // dispatch(bringTestsDone(course));
        dispatch(bringTestsDoneForUsers(course));
        dispatch(getAllWrongQuezzes(course));

        // if (studDetails !== undefined) {
        //     dispatch(bringTestsDoneForUsers(course, studDetails.idUsers));
        //     dispatch(bringSimulationsDone(course, studDetails.idUsers));
        //     dispatch(getAllWrongQuezzes(course, studDetails.idUsers));
        // } else {
        //     dispatch(bringTestsDoneForUsers(course, profile.idUser));
        //     dispatch(bringSimulationsDone(course, profile.idUser));
        //     dispatch(getAllWrongQuezzes(course, profile.idUser));
        // }
    }, [dispatch, studDetails]);

    useEffect(() => {
        if (testsDone !== undefined) {
            const mon = testsDone.filter(test => test.quiz_type === 'monomateria');
            const mis = testsDone.filter(test => test.quiz_type === 'misti');
            // console.log("minn", mon)
            // const si = simulationsDone.filter(test => test.quiz_type === 'simulazione');
            setMono(mon);
            setMisti(mis);
            // setSim(si);
        }
        if (simulationsDone !== undefined) {
            setSim(simulationsDone);
        }
    }, [testsDone, simulationsDone]);

    const countWrongQuizzes = () => {
        if (wrong !== undefined) {
            let counter = 0;
            for (let i = 0; i < wrong.length; i++) {
                counter = counter + wrong[i].wrongQuestions.length
            }
            return counter;
        }

    };

    // console.log('testsDone', testsDone);
    // console.log('mon',mono);
    // console.log('course', course);
    // console.log(sim);
    // console.log(flag);
    //console.log('USER', user);
    // console.log('Hata WRONG', wrong);
    // console.log(studDetails);

    return (
        <CardWrapper
            flag={(flag === undefined && type === 'Quiz monomateria') ||
                (flag === undefined && type === 'Quiz misti') ||
                (flag === undefined && type === 'Simulazione') ||
                (flag === undefined && type === 'Quiz errati')
                ? 'true' : 'false'
            }
            to={
                flag !== undefined ?
                    {
                        pathname: type === 'Simulazione' ?
                            '/dashboard/exercises/view-simulations'
                            :
                            '/dashboard/exercises/view-exercises',
                        state: {
                            //type, course, user,
                            type, course, studDetails,
                            arr: (type === 'Quiz monomateria' && mono) ||
                                (type === 'Quiz misti' && misti) ||
                                (type === 'Simulazione' && sim) ||
                                (type === 'Quiz errati' && wrong)
                        }
                    }
                    :
                    {
                        pathname: (type === 'Quiz monomateria' && '/dashboard/create-mono-quiz') ||
                            (type === 'Quiz misti' && '/dashboard/create-mixt-quiz') ||
                            (type === 'Simulazione' && '/dashboard/create-simulation') ||
                            (type === 'Quiz errati' && '/dashboard/wrong-quezzes'),
                        state: {
                            type,
                            flag: (type === 'Quiz monomateria' && 'mono') ||
                                (type === 'Quiz misti' && 'misti') ||
                                (type === 'Simulazione' && 'sim') ||
                                (type === 'Quiz errati' && 'err'),
                            course
                        }
                    }
            }
        >

            {type === 'Quiz monomateria' && <i className="fa-regular fa-square"></i>}
            {type === 'Quiz misti' && <i className="fa-solid fa-table-cells-large"></i>}
            {type === 'Simulazione' && <i className="fa-solid fa-shapes"></i>}
            {type === 'Quiz errati' && <i className="fa-solid fa-circle-exclamation"></i>}

            {
                flag === undefined &&
                <Hr></Hr>
            }

            {
                //flag !== undefined ?
                testsDone === undefined && wrong !== undefined ?
                    <Number>
                        <img src={Loading} alt="Loading..."></img>
                    </Number>
                    :
                    <Number>
                        {type === 'Quiz monomateria' && mono.length}
                        {type === 'Quiz misti' && misti.length}
                        {type === 'Simulazione' && sim.length}
                        {type === 'Quiz errati' && countWrongQuizzes()}
                        {/* {(type === 'Quiz errati' && wrong !== undefined) && countWrongQuizzes()} */}
                    </Number>
                // :
                // null
            }

            {/* {
                flag === undefined ?
                    wrong === undefined ?
                        type === 'Quiz errati' &&
                        <Number>
                            <img src={Loading} alt="Loading..."></img>
                        </Number>
                        :
                        <Number>
                            {(type === 'Quiz errati' && wrong !== undefined) && countWrongQuizzes()}
                        </Number>
                    :
                    null
            } */}

            <LinkWrapper>
                {type}
            </LinkWrapper>

            {/* <LinkWrapper>
                {flag !== undefined ?
                    <BtnLink
                        // to='/dashboard/testVerification'
                        // to='/dashboard/exercises/view-exercises'
                        to={{
                            pathname: type === 'Simulazione' ?
                                '/dashboard/exercises/view-simulations'
                                :
                                '/dashboard/exercises/view-exercises',
                            state: {
                                type, course,
                                arr: (type === 'Quiz monomateria' && mono) ||
                                    (type === 'Quiz misti' && misti) ||
                                    (type === 'Simulazione' && sim)
                            }
                        }}
                    >
                        {type}
                    </BtnLink>
                    :
                    <BtnLink
                        // to='/dashboard/quiz/mono'
                        to={{
                            pathname: (type === 'Quiz monomateria' && '/dashboard/create-mono-quiz') ||
                                (type === 'Quiz misti' && '/dashboard/create-mixt-quiz') ||
                                (type === 'Simulazione' && '/dashboard/create-simulation') ||
                                (type === 'Quiz errati' && '/dashboard/wrong-quezzes'),
                            state: {
                                type,
                                flag: (type === 'Quiz monomateria' && 'mono') ||
                                    (type === 'Quiz misti' && 'misti') ||
                                    (type === 'Simulazione' && 'sim') ||
                                    (type === 'Quiz errati' && 'err'),
                                course
                            }
                        }}
                    >
                        {type}
                    </BtnLink>
                }
            </LinkWrapper> */}

        </CardWrapper >
    );
};

export default ExerciziCardNew;

{/* <CardWrapper
flag={(flag === undefined && type === 'Quiz monomateria') ||
    (flag === undefined && type === 'Quiz misti') ||
    (flag === undefined && type === 'Simulazione')
    ? 'true' : 'false'
}
>

{type === 'Quiz monomateria' && <i className="fa-regular fa-square"></i>}
{type === 'Quiz misti' && <i className="fa-solid fa-table-cells-large"></i>}
{type === 'Simulazione' && <i className="fa-solid fa-shapes"></i>}
{type === 'Quiz errati' && <i className="fa-solid fa-circle-exclamation"></i>}

{flag === undefined &&
    <Hr></Hr>
}

{flag !== undefined ?
    testsDone === undefined ?
        <Number>
            <img src={Loading} alt="Loading..."></img>
        </Number>
        :
        <Number>
            {type === 'Quiz monomateria' && mono.length}
            {type === 'Quiz misti' && misti.length}
            {type === 'Simulazione' && sim.length}
        </Number>
    :
    null
}

{flag === undefined ?
    wrong === undefined ?
        type === 'Quiz errati' &&
        <Number>
            <img src={Loading} alt="Loading..."></img>
        </Number>
        :
        <Number>
            {(type === 'Quiz errati' && wrong !== undefined) && countWrongQuizzes()}
        </Number>
    :
    null
}

<LinkWrapper>
    {flag !== undefined ?
        <BtnLink
            // to='/dashboard/testVerification'
            // to='/dashboard/exercises/view-exercises'
            to={{
                pathname: type === 'Simulazione' ?
                    '/dashboard/exercises/view-simulations'
                    :
                    '/dashboard/exercises/view-exercises',
                state: {
                    type, course,
                    arr: (type === 'Quiz monomateria' && mono) ||
                        (type === 'Quiz misti' && misti) ||
                        (type === 'Simulazione' && sim)
                }
            }}
        >
            {type}
        </BtnLink>
        :
        <BtnLink
            // to='/dashboard/quiz/mono'
            to={{
                pathname: (type === 'Quiz monomateria' && '/dashboard/create-mono-quiz') ||
                    (type === 'Quiz misti' && '/dashboard/create-mixt-quiz') ||
                    (type === 'Simulazione' && '/dashboard/create-simulation') ||
                    (type === 'Quiz errati' && '/dashboard/wrong-quezzes'),
                state: {
                    type,
                    flag: (type === 'Quiz monomateria' && 'mono') ||
                        (type === 'Quiz misti' && 'misti') ||
                        (type === 'Simulazione' && 'sim') ||
                        (type === 'Quiz errati' && 'err'),
                    course
                }
            }}
        >
            {type}
        </BtnLink>
    }
</LinkWrapper>

</CardWrapper> */}