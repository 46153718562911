import { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";

// ASSETS
import Loading from '../../../../../../Assets/loading.gif';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
} from '../../../../PagesShareStyle/PagesShareStyle';


const TemaPreQuest = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const questions = details.que.TemaQuestionaryQuizzs;

    useEffect(() => {
        setTimeout(() => {
            history.push({
                pathname: '/dashboard/prova-tema/quest',
                // state: { que: questions, asw: details.que }
                state: { que: questions, quest: details.que }
            });

        }, 2000);
    }, []);

    // console.log("TEMA PRE QUEST DETAILS", details);

    
    return (
        <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img src={Loading} alt="Loading..."></img>
            <div>Inizia quest...</div>
        </WrapperRightSide>
    );
}

export default TemaPreQuest;