import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// ASSETS
// import pic from '../../../Assets/Capture.PNG';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../../GeneralComponents/AllQuestions/AllQuestions';
import AllQuestionsSideBarForProfForDrawing from '../../../../GeneralComponents/AllQuestions/AllQuestionsForVerificationForDrawing';
import AllQuestionsSideBarForProf from '../../../../GeneralComponents/AllQuestions/AllQuestionsForVerificationForDrawing';
import FooterBar from '../../../../GeneralComponents/TestViewsBars/FooterBar';
import FooterBarForProf from '../../../../GeneralComponents/TestViewsBars/FooterBarFotVerification';
// import FooterBarForStud from '../../../../GeneralComponents/TestViewsBars/FooterBarForStud';
import DrawingVerification from './DrawingVerification';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { saveTpaVerificationWithDrawing, getAllTpaTestDone } from '../../../../../Store/Actions/tpaActions';
import { newNotifications, newNotificationsForProf, updateNotifications } from '../../../../../Store/Actions/notificationsActions';
import { findCourse } from '../../../../../Store/Actions/coursesActions';

// STYLE
import {
    // WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../../PagesShareStyle/PagesShareStyle';

const GoBack = styled.div`
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: var(--text3);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: var(--background4);
    /* min-width: 100px; */
    /* border-radius: var(--radius); */
    box-shadow: var(--shadow);
    /* padding: 10px; */
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
`;

const TestWrapper = styled.div`
    position: relative;
    width: calc(100% - 230px);
    margin-top: ${props => props.user === true ? '80px' : '20px'};
    height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
    background: var(--background2);
    overflow: auto;
        @media (max-width: 1100px) {
            width: 100%;
        };
`;

const TestPageWithDrawingVerification = ({
    tests,
    setRest,
    setFinishTheTest,
    idCourse,
    type,
    idTpa,
    doneAllObj,
    allDetails }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const whoAmI = useSelector(({ auth }) => auth.user);
    const [id, setId] = useState(0);
    const [obj, setObj] = useState([]);
    const [grade, setGrade] = useState(doneAllObj.grade !== null ? doneAllObj.grade : '');
    const [loading, setLoading] = useState(false);
    const socket = useSelector(({ conversations }) => conversations.socket);
    const aCourse = useSelector(({ courses }) => courses.aCourse);
    const [inputFields, setInputFields] = useState(doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : []);

    useEffect(() => {
        let createObj = [];
        for (let i = 0; i < tests.length; i++) {
            createObj.push({})
        }
        setObj(createObj);
    }, [tests]);

    const setAnswer = (e, i) => {
        // console.log(e);
        const value = [...obj]
        value[id].asw = e.e;
        value[id].svg = e.svg;
        value[id].aswIndex = i;
        value[id].file = e.file;
        value[id].png = e.png;

        setObj(value);

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);
        // console.log(res)
    };

    useEffect(() => {
        dispatch(findCourse(idCourse));
    }, [whoAmI]);


    const submitTest = () => {
        setTimeout(() => {
            // dispatch(saveTpaTest(type, tests, obj, whoAmI.idUser, idCourse, grade));
            dispatch(saveTpaVerificationWithDrawing(obj, grade, idTpa, inputFields, whoAmI.idUser));
            //dispatch(getAllTpaTestDone(whoAmI.idUser));

            socket.emit('sendNotif', {
                senderId: whoAmI.idUser,
                receiverId: allDetails?.allDetails.studDetails.idUsers,
                text: 'Verification done!',
                author: whoAmI,
                course: allDetails.allDetails.course,
                test: allDetails.elem,
                testType: type
            })

            dispatch(newNotificationsForProf(whoAmI.idUser,
                allDetails?.allDetails.studDetails.idUsers,
                whoAmI,
                allDetails.allDetails.course,
                allDetails.elem,
                type
            ));

            dispatch(updateNotifications(allDetails.elem, whoAmI.idUser));

        }, 1000);

        setFinishTheTest(true);
        setTimeout(() => {
            history.goBack();
        }, 2000);
        setLoading(true);
    };

    // console.log('OBJ', obj);
    // console.log("ID COURSE", idCourse);
    // console.log("Grade", grade);
    // console.log('type', type);
    // console.log(whoAmI.role);
    // console.log('allDetails', allDetails);

    return (
        <>
            <TestWrapper user={(type === 'wartegg' || type === 'test-grafici' && whoAmI.role !== 'STUDENTE') && true}>
                {whoAmI.role === 'STUDENTE' ?
                    <AllQuestionsSideBar
                        id={id}
                        setId={setId}
                        arr={tests}
                        obj={obj}
                        grade={grade}
                        setGrade={setGrade}
                    />
                    :
                    <AllQuestionsSideBarForProfForDrawing
                        id={id}
                        setId={setId}
                        arr={tests}
                        obj={obj}
                        grade={grade}
                        setGrade={setGrade}
                        inputFields={inputFields}
                        setInputFields={setInputFields}
                    />
                }

                {tests !== undefined &&
                    <DrawingVerification
                        tests={tests}
                        id={id}
                        setAnswers={setAnswer}
                        obj={obj}
                        idTpa={idTpa}
                    />
                }
            </TestWrapper>

            {whoAmI.role === 'STUDENTE' ?
                // (type === 'wartegg' || type === 'test-grafici') ?
                //     <FooterBarForStud />
                //     :
                <FooterBar
                    id={id}
                    setId={setId}
                    tests={tests}
                    submitTest={submitTest}
                    grade={grade}
                    loading={loading}
                />
                :
                <FooterBarForProf
                    id={id}
                    setId={setId}
                    tests={tests}
                    submitTest={submitTest}
                    grade={grade}
                    loading={loading}
                />
            }
        </>
    );
}

export default TestPageWithDrawingVerification;