import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import pic from '../../../Assets/card-pic.png';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { markNotifAsRead, deleteNotif } from '../../../Store/Actions/notificationsActions';
import { clearOnTemaFormNotif } from '../../../Store/Actions/temaActions';

const CardWrapper = styled.div`
    position: relative;
    width: 100%;
    /* height: 100px; */
    margin-top: 20px;
    padding: 10px 10px 20px 10px;
    background: var(--background4);
    border-radius: var(--radius);
    cursor: pointer;
    /* box-shadow: 0px 9px 20px rgb(46 35 94 / 7%); */
    box-shadow: 0px 5px 6px rgb(46 35 94 / 7%);
`;

const PopUp = styled.div`
    position:absolute;
    top: 0px;
    right: 20px;
    /* width: 100px; */
    padding: 10px;
    background: #000000e3;
    color: var(--text5);
    /* text-align: center; */
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(8px);
    transition: var(--transition);
    white-space: nowrap;
    letter-spacing: 1px;
`;

const Shadow = styled.div`
    height: 180px;
    padding: 10px 20px;
    border-radius: var(--radius);
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
    background: var(--background4);
        >h5{
            position: relative;
            color: var(--text1);
            font-weight: bold;
            max-height: 40px;
            overflow: hidden;
                &:hover ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: .8s;
                }
        }
        /* >h6{
            position: relative;
            color: var(--text1);
            font-weight: 100;
            padding-top: 10px;
            height: 60px;
           
            &:hover ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: .8s;
                }
        } */
`;

const Desc = styled.div`
        position: relative;
        >h6{
            color: var(--text1);
            font-weight: 100;
            padding-top: 5px;
            height: 60px;
        }
        &:hover ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: .8s;
        }
`;

const BtnLink = styled(Link)`
    position: absolute;
    bottom: 10px;
    width: 140px;
    padding: 10px 30px;
    border:none;
    outline: none;
    /* background: var(--acc-color); */
    background: var( --background1);
    color: var(--text5);
    font-weight: bold;
    text-decoration: none;
    margin: 10px 0;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
        >i{
            /* position: absolute; */
            /* right: 20px; */
            margin-left: 10px;
            font-size: 14px;
            color: var(--text5);   
        }
        &:hover{
            /* opacity: .8;
            transition: .2s ease-in-out; */
            background: var( --acc-color);
        }
        &:active{
                transform:translateY(2px);
        }  
`;

const Info = styled.div`
   display: flex;
   flex-direction: column;
        >h6{
            color: var(--text2);
            margin-top: 5px;
        }
        >h5{
            color: var(--text2);
            font-weight: bold;
            font-size: 14px;
        }
        >span{
            position: absolute;
            right: 10px;
            bottom: 5px;
            font-size: 14px;
            color: var(--text4);
        }
`;

const CardTitle = styled.div`
    width: 100%;
    min-height: 30px;
    border-bottom: 1px solid #00000017;
    font-size: 18px;
    color: var(--text1);
    font-weight: bold;
    margin-bottom: 10px;
`;

const MarkAsRead = styled.div`
            position: absolute;
            right: 10px;
            top: 15px;
            z-index: 1;
            cursor: pointer;
        >i{
            
            color: ${props => props.delete ? '#fd6ba1' : "#6b98fd"};;
            &:hover{
                color: var(--text1);
            }
        }
        &:hover{
                ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                }
            }
         &:active{
                transform:translateY(2px);
        }  
`;

const NotifLink = styled(Link)`
    text-decoration: none;
`;


const NotifCard = ({ notif, flag }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);

    const formatData = (data) => {
        // console.log("data", data);
        const extData = data.split('T');
        let d = extData[0].split('-');
        // let format = d[1] + '.' + d[2] + '.' + d[0];

        const extTime = extData[1].split('.');
        const time = extTime[0];

        // console.log("extTime", extTime);

        let format = d[1] + '.' + d[2] + '.' + d[0] + '/ ' + time;

        return format
    };

    const markAsRead = () => {
        dispatch(markNotifAsRead(notif.idNotif, profile));
    };

    const delNotif = () => {
        dispatch(deleteNotif(notif.idNotif, profile));
    };

    useEffect(() => {
        dispatch(clearOnTemaFormNotif());
    }, [dispatch])

    // const svolgere = [
    //     'Analisi della traccia',
    //     'Elaborazione del Testo o Scaletta',
    //     'Coerenza e stesura del testo',
    //     'Stesura e revisione del tema (BASE)',
    //     'Registri linguistici',
    //     'Avvio alla scrittura',
    // ];
    const svolgere = [
        'Avvio alla scrittura',
        'Analisi della traccia',
        'Elaborazione del Testo o Scaletta',
        'Stesura e revisione del tema',
        'Coerenza e stesura del testo',
        'Registri linguistici',

    ];

    const createType = () => {
        let svol;
        if (notif.TestType.split('-').join(' ').toLowerCase() === svolgere[0].toLowerCase()) {
            svol = svolgere[0]
        }
        if (notif.TestType.split('-').join(' ').toLowerCase() === svolgere[1].toLowerCase()) {
            svol = svolgere[1]
        }
        if (notif.TestType.split('-').join(' ').toLowerCase() === svolgere[2].toLowerCase()) {
            svol = svolgere[2]
        }
        if (notif.TestType.split('-').join(' ').toLowerCase() === svolgere[3].toLowerCase()) {
            svol = svolgere[3]
        }
        if (notif.TestType.split('-').join(' ').toLowerCase() === svolgere[4].toLowerCase()) {
            svol = svolgere[4]
        }
        if (notif.TestType.split('-').join(' ').toLowerCase() === svolgere[5].toLowerCase()) {
            svol = svolgere[5]
        }
        return svol
    };

    const findUser = () => {
        let id;
        if (createType() === svolgere[0]) {
            id = JSON.parse(notif.Test).idUser
        }
        if (createType() === svolgere[1]) {
            id = JSON.parse(notif.Test).idUser
        }

        if (createType() === svolgere[3]) {
            id = JSON.parse(notif.Test).userIdInProcessing
        }
        if (createType() === svolgere[3]) {
            id = JSON.parse(notif.Test).idUser
        }

        if (createType() === svolgere[4]) {
            id = JSON.parse(notif.Test).user
        }
        if (createType() === svolgere[5]) {
            id = JSON.parse(notif.Test).idUser
        }
        return id;
    }
    // console.log("NOTIF", notif);

    return (
        notif !== undefined &&
        <div style={{ position: 'relative' }}>
            {JSON.parse(notif.Test).idTpaDone === undefined ?
                <NotifLink onClick={markAsRead} to={{
                    pathname: profile.role === 'STUDENTE' ?
                        '/dashboard/view-tema-test-done' : '/dashboard/tema-test-verification',
                    state: {
                        elem: JSON.parse(notif.Test),
                        notifFlag: 'Notif',
                        allDetails: {
                            studDetails: {
                                // idUsers: createType() === svolgere[1] ?
                                //     JSON.parse(notif.Test).userIdInProcessing
                                //     :
                                //     JSON.parse(notif.Test).idUser
                                idUsers: findUser()
                            },
                            course: JSON.parse(notif.Course)
                        },
                        idNotif: notif.idNotif,
                        type: createType(),
                        svolgere: svolgere,
                        fromNotifFlag: 'fromNotif'
                    }
                }}>
                    <CardWrapper>
                        <Info>
                            <CardTitle>{profile.role === 'STUDENTE' ?
                                'Il tuo lavoro è stato corretto' : 'È stato fatto un nuovo test'}
                            </CardTitle>
                            <h5>Concorso: {JSON.parse(notif.Course).course_name}</h5>
                            <h5>Tipo di esercizio: {notif.TestType.split('-').join(' ').toUpperCase()}</h5>
                            {/* <h5>Dettagli: Il tuo lavoro è stato corretto</h5> */}
                            <h5>{profile.role === 'STUDENTE' ? 'Tutor:' : 'Studente:'} {notif.Author.firstName} {notif.Author.lastName}</h5>

                            <span>{formatData(notif.createdAt)}</span>
                        </Info>
                    </CardWrapper >
                </NotifLink>
                :
                <NotifLink onClick={markAsRead} to={{
                    // pathname: notif.TestType === 'wartegg' || notif.TestType === 'test-grafici' ?
                    //     '/dashboard/view-tpa-test-done' : '/dashboard/tpa-test-verification',
                    pathname: profile.role === 'STUDENTE' && notif.TestType === 'wartegg' || notif.TestType === 'test-grafici' ?
                        '/dashboard/view-tpa-test-done' : '/dashboard/tpa-test-verification',

                    // pathname: '/dashboard/tpa-test-verification',
                    state: {
                        elem: JSON.parse(notif.Test),
                        notifFlag: 'Notif',
                        allDetails: {
                            studDetails: { idUsers: JSON.parse(notif.Test).idUser },
                            course: JSON.parse(notif.Course)
                        },
                        idNotif: notif.idNotif
                    }
                }}>
                    <CardWrapper>
                        <Info>
                            <CardTitle>{profile.role === 'STUDENTE' ?
                                'Il tuo lavoro è stato corretto' : 'È stato fatto un nuovo test'}
                            </CardTitle>
                            <h5>Concorso: {JSON.parse(notif.Course).course_name}</h5>
                            <h5>Tipo di esercizio: {notif.TestType.toUpperCase().replace('-', ' ')}</h5>
                            {/* <h5>Dettagli: Il tuo lavoro è stato corretto</h5> */}
                            <h5>{profile.role === 'STUDENTE' ? 'Tutor:' : 'Studente:'} {notif.Author.firstName} {notif.Author.lastName}</h5>

                            <span>{formatData(notif.createdAt)}</span>
                        </Info>
                    </CardWrapper >
                </NotifLink>

                // <NotifLink onClick={markAsRead} to={{
                //     // pathname: notif.TestType === 'wartegg' || notif.TestType === 'test-grafici' ?
                //     //     '/dashboard/view-tpa-test-done' : '/dashboard/tpa-test-verification',
                //     pathname: profile.role === 'STUDENTE' && notif.TestType === 'wartegg' || notif.TestType === 'test-grafici' ?
                //         '/dashboard/view-tpa-test-done' : '/dashboard/tpa-test-verification',

                //     // pathname: '/dashboard/tpa-test-verification',
                //     state: {
                //         elem: JSON.parse(notif.Test),
                //         notifFlag: 'Notif',
                //         allDetails: {
                //             studDetails: { idUsers: JSON.parse(notif.Test).idUser },
                //             course: JSON.parse(notif.Course)
                //         },
                //         idNotif: notif.idNotif
                //     }
                // }}>
                //     <CardWrapper>
                //         <Info>
                //             <CardTitle>{profile.role === 'STUDENTE' ?
                //                 'Il tuo lavoro è stato corretto' : 'È stato fatto un nuovo test'}
                //             </CardTitle>
                //             <h5>Concorso: {JSON.parse(notif.Course).course_name}</h5>
                //             <h5>Tipo di esercizio: {notif.TestType.toUpperCase().replace('-', ' ')}</h5>
                //             {/* <h5>Dettagli: Il tuo lavoro è stato corretto</h5> */}
                //             <h5>{profile.role === 'STUDENTE' ? 'Tutor:' : 'Studente:'} {notif.Author.firstName} {notif.Author.lastName}</h5>

                //             <span>{formatData(notif.createdAt)}</span>
                //         </Info>
                //     </CardWrapper >
                // </NotifLink>
            }

            {flag === 'unread' &&
                <MarkAsRead >
                    {/* <i className="fa-solid fa-envelope-open"></i> */}
                    <i className="fa-solid fa-envelope" onClick={markAsRead}></i>
                    <PopUp>Segna come letto</PopUp>
                </MarkAsRead>
            }

            {flag === undefined &&
                <MarkAsRead delete>
                    <i className="fa-solid fa-xmark" onClick={delNotif}></i>
                    <PopUp>Eliminare</PopUp>
                </MarkAsRead>
            }
        </div>
    );
};

export default NotifCard;