import axios from 'axios';
axios.defaults.withCredentials = true;

export const getAllSubjects = (idCourse, idUser) => (dispatch, getState) => {

    const body = {
        idCourse: idCourse,
        idUser: idUser,
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllSubjects`, body)
        .then((res) => {
            // console.log("ALL_SUBJECTS", res.data);
            dispatch({
                type: 'GET_ALL_SUBJECTS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};


export const getAllQuestionnaires = (idLesson, idUser) => (dispatch, getState) => {

    const body = {
        idLesson: idLesson,
        idUser: idUser
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllQuestionnaires`, body)
        .then((res) => {
            //console.log("ALL_SUBJECTS", res.data);
            dispatch({
                type: 'GET_QUESTIONNAIRES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const checkIfExists = (id) => (dispatch, getState) => {

    const body = {
        idQuest: id
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/checkIfExists`, body)
        .then((res) => {
            console.log("CHECK_IF_EXISTS", res.data);
            if (res.data.message === 'No quest found!') {
                dispatch({
                    type: 'CHECK_IF_EXISTS',
                    payload: null,
                });
            } else {
                dispatch({
                    type: 'CHECK_IF_EXISTS',
                    payload: res.data,
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const downloadFile = (doc) => (dispatch, getState) => {
    const body = {
        file: doc.url
    };

    const config = {
        responseType: 'blob'
    };

    const fileName = doc.name
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/downloadFileE`, body, config)
        .then((res) => {
            // console.log("DOWNLOAD_FILE", res.data);
            const data = {
                name: fileName,
                data: res.data
            }
            dispatch({
                type: 'DOWNLOAD_FILE',
                payload: data,
            });
            setTimeout(() => {
                dispatch({
                    type: 'DOWNLOAD_FILE',
                    payload: undefined,
                })
            }, 2000);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const clearFile = (doc) => (dispatch, getState) => {
    dispatch({
        type: 'DOWNLOAD_FILE',
        payload: undefined,
    })
};

export const sendQuestAnswers = (obj, questInfo) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        answers: JSON.stringify(obj),
        // userID: '1',
        userID: profile.idUser,
        idQuest: questInfo.idQuestionnaire,
        name: questInfo.name,
        point: questInfo.point,
        allQuestions: JSON.stringify(questInfo.QuestionaryQuizzes)
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/sendQuestAnswers`, body)
        .then((res) => {
            console.log("SAVE_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const videoIsDone = (idLesson, idSubject, idCourse, time) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        userID: profile.idUser,
        idLesson: idLesson,
        idSubject: idSubject,
        idCourse: idCourse,
        videoTime: time
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/videoIsDone`, body)
        .then((res) => {
            console.log("SAVE_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllSubjectsOfAUser = (idUser, type) => (dispatch, getState) => {

    dispatch({
        type: 'ALL_SUBJECTS_OF_USER',
        payload: undefined,
    });
    const body = {
        idUser: idUser,
        type: type
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllSubjectsOfAUser`, body)
        .then((res) => {

            console.log("ALL_SUBJECTS_OF_USER", res.data);
            dispatch({
                type: 'ALL_SUBJECTS_OF_USER',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resetUserResults = (idUser) => (dispatch, getState) => {
    dispatch({
        type: 'ALL_SUBJECTS_OF_USER',
        payload: undefined,
    });
};


export const getNotebooks = (idCourse, idSubject) => (dispatch) => {
    const body = {
        idCourse: idCourse,
        idSubject: idSubject
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getSubjectNotebooks`, body)
        .then((res) => {
            dispatch({
                type: "GET_NOTEBOOKS",
                payload: res.data,
            });
            // console.log("DELETE_MATERIA", res);
            //dispatch(getCorsi(profile.idUsers, profile.idSociety, profile))
            //dispatch(getMateria())
        })
        .catch((err) => {
            console.log(err);
        });
};
