import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

//PACKAGES
// import styled from 'styled-components';
// import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import _ from 'lodash';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { bringTestsDone, bringSimulationsDoneForUsers, getAllWrongQuezzes } from '../../../Store/Actions/usersActions';
import { deleteUndoneSimulations, bringSimulationsDone } from '../../../Store/Actions/testActions';

import {
    WrapperRightSide,
    TitleWrapper,
    Title,
    GoBack,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './SimulationTable ';


const tableHead = [
    'Data',
    'Tempo totale',
    'Tempo impiegato',
    'Punteggio',
    'Azioni'
];

const ViewSim = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    // const services = useSelector(({ services }) => services.allServices);
    // const pageSize = useSelector(({ other }) => other.setSize);
    // const testsDone = useSelector((state) => state.test.bringTestsDone);
    const simulationsDone = useSelector((state) => state.test.bringSimulationsDone);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [type, setType] = useState([]);
    const [simulations, setSimulations] = useState([]);


    useEffect(() => {
        if (details.studDetails !== undefined) {
            dispatch(bringSimulationsDoneForUsers(details.course));
        } else {
            dispatch(bringSimulationsDone(details.course));
        }
        // dispatch(bringTestsDone(details.course));
        // dispatch(getAllWrongQuezzes(details.course));
        // dispatch(deleteUndoneSimulations(details.course));
    }, [dispatch, details]);

    useEffect(() => {
        if (simulationsDone !== undefined) {
            setSimulations(simulationsDone)
        }
    }, [simulationsDone]);

    // console.log('SIMULATIONS DETAILS', details);
    // console.log('SIMULATIONS DETAILS',simulationsDone);
    // console.log('SIMULATIONS DETAILS CONFIIG',JSON.parse(details.arr[0].sim_config));
    // console.log('SIMULATIONS DETAILS SCORE',JSON.parse(JSON.parse(details.arr[0].sim_config).score));

    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                {/* <Title>Esercizi</Title> */}

                <Title>{details.type}</Title>
                {/* <SubTitle>{desc}</SubTitle> */}
            </TitleWrapper>

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    // type={details.arr}
                    type={simulations}
                    flag={'quiz'}
                    setType={setType}
                />

            </ActionsWrapperSpace>

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <Btn
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/courses/newCourse'>
                        <i className="fas fa-plus"></i>Utenti
                    </Btn>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 300px)' }}>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono simulazioni da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono simulazioni da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                // obj={details.arr}
                obj={type}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default ViewSim;