const initState = {
    isAuthentificated: null,
    isRegistered: null,
    isLoading: false,
    authLoading: false,
    user: undefined,
    errors: null,
    forgotErr: null,
    forgotSuccess: null,
    openModal: false,
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_USER':
            return {
                ...state,
                isLoading: true,
            };

        case 'FETCHED_USER':
            return {
                ...state,
                isAuthentificated: true,
                isLoading: false,
                user: action.payload,
            };

        case 'LOADING_REGISTER':
            return {
                ...state,
                authLoading: true,
            };

        case 'REGISTER_SUCCESFUL':
            return {
                ...state,
                isRegistered: true,
                errors: null,
                authLoading: false,
            };

        case 'LOADING_SIGNIN':
            return {
                ...state,
                authLoading: true,
            };

        case 'LOGIN_SUCCESFUL':
            return {
                ...state,
                token: action.payload.accessToken,
                isAuthentificated: true,
                user: action.payload,
                errors: null,
                authLoading: false,
            };

        case 'LOADING_SET_PSW':
            return {
                ...state,
                setPswLoading: true,
            };

        case 'SET_PSW_SUCCESFUL':
            return {
                ...state,
                setPsw: action.payload,
                setPswLoading: false,
            };

        case 'LOGIN_ERROR':
        case 'REGISTER_ERROR':
        case 'CONFIRM_MAIL_ERROR':
            return {
                ...state,
                errors: action.payload,
                authLoading: false,
                setPswLoading: false,
            };

        case 'RESET_ERROR':
            return {
                ...state,
                errors: null,
                authLoading: false,
                forgotErr: null,
                forgotSuccess: null,
            };

        case 'LOGOUT_SUCCESFUL':
        case 'REGISTER_FAIL':
        case 'LOGIN_FAIL':
            return {
                ...state,
                token: null,
                isAuthentificated: null,
                isRegistered: null,
                isLoading: false,
                user: undefined,
                errors: null,
                authLoading: false,
            };

        case 'CONFIRM_EMAIL':
            return {
                ...state,
                confirmEmail: action.payload,
                authLoading: false,
            };

        case 'RESEND_EMAIL':
            return {
                ...state,
                resendEmail: action.payload,
            };

        case 'LOADING':
            return {
                ...state,
                authLoading: true,
            };

        case 'SUCCESS':
            return {
                ...state,
                authLoading: false,
            };

        case 'ERROR':
            return {
                ...state,
                authLoading: false,
            };

        case 'NOTIF_HANDLER_AUTH':
            return {
                ...state,
                handlerNotifAuth: action.payload,
            };

        default:
            return state;
    }
};

export default authReducer;