import React, { useEffect } from 'react';

// ASSETS
import brush from '../../../../../../Assets/brush.png';

// PACKAGES
import styled from 'styled-components';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// REDUX
import { useSelector } from 'react-redux';

import {
    Wrapper,
    // CommentWrapper,
    // GradeWrapper,
    // LabelCss,
    QueContainer,
    ResContainer,
    QuestionsContainer,
    Box,
    // Section,
} from './Style';

const EditorContainer = styled.div`
	   /* .editorClassName{
            height: calc(100vh - 360px);
            background: white;
        } */
        .DraftEditor-root {
            position: relative;
            /* height: calc(100vh - 451px); */
            height: calc(100vh - 535px);
        }
     
        .editorClassName{
            /* height: calc(100vh - 415px); */
            height: calc(100vh - 535px);
            background: white;
            padding: 10px;
        }
        .rdw-colorpicker-modal{
            position: absolute;
            top: 50px;
            left: -180px;
            display: flex;
            flex-direction: column;
            width: 230px;
            height: 255px;
            border: 1px solid #F1F1F1;
            padding: 15px;
            border-radius: 2px;
            z-index: 100;
            background: white;
            box-shadow: 3px 3px 5px #bfbdbd;
        }
        .rdw-emoji-modal{
            overflow: auto;
            position: absolute;
            top: 50px;
            left: -190px;
            display: flex;
            flex-wrap: wrap;
            width: 235px;
            height: 180px;
            border: 1px solid #F1F1F1;
            padding: 10px;
            border-radius: 2px;
            z-index: 100;
            background: white;
            box-shadow: 3px 3px 5px #bfbdbd;
        }
        .rdw-option-wrapper{
            width: 40px;
            height: 40px;
            border-radius: var(--radius);
        }
        .rdw-fontsize-dropdown {
            width: 110px;
        }
        .rdw-dropdown-optionwrapper{
            width: 60px;
        }
        .rdw-fontfamily-optionwrapper{
            width: 110px;
        }
        .rdw-dropdown-optionwrapper{
            width: 110px;
        }
`;


const VerificationTestAvvio = ({ details, id, setId, done, obj, setObj }) => {
    // console.log('TEMA AVVIO VERIF', details);

    const setQue = (id, sts) => {
        setId(id);
    };

    const howIsIt = (elem, que) => {
        // console.log("ELEM", elem);
        if (elem !== undefined && Object.keys(elem).length > 0) {
            let is;

            if (elem.check === true) {
                is = 'correct';
            }

            if (elem.check === false) {
                is = 'empty';
            }
            return is;
        };
    };

    useEffect(() => {
        if (details !== undefined) {
            let createObj = [];
            const answ = details.elem.profCorrection === null?JSON.parse(details.elem.answers): JSON.parse(details.elem.profCorrection);
            for (let i = 0; i < answ.length; i++) {
                createObj.push({
                    asw: EditorState.createWithContent(convertFromRaw(answ[i].aswTxt)),
                    aswIndex: answ[i].aswIndex,
                    check: answ[i].check,
                    aswTxt: answ[i].aswTxt
                })
            }
            setObj(createObj);
        }
    }, [details]);

    const setAnswerAvvio = (e, i, text) => {
        const value = [...obj]
        if (e !== null) {
            value[id].asw = e;
            value[id].aswTxt = text;
            value[id].aswIndex = i;
            value[id].check = true;
            setObj(value);
        } else {
            setObj(obj);
        }
    };

    const onEditorStateChange = (editorState) => {
        const text = convertToRaw(editorState.getCurrentContent());
        setAnswerAvvio(editorState, id, text);
    };

    // console.log('OBJ', obj);
    // console.log('doneAllObj', doneAllObj);
    // console.log(JSON.stringify(editorState));

    return (
        <Wrapper style={{ marginTop: '0px' }} >
            <div>
                <QuestionsContainer>
                    {JSON.parse(details.elem.questions).map((que, i) =>
                        <Box
                            key={i}
                            onClick={() => setQue(i, que)}
                            select={id === i && 'select'}
                            // prev={id > i && 'prev'}
                            //prev={done[i] !== undefined && Object.keys(done[i]).length > 0 && 'prev'}
                            prev={howIsIt(done[i], que)}
                        >
                            {i + 1}
                        </Box>
                    )}
                </QuestionsContainer>


                {details !== undefined &&
                    <QueContainer
                        dangerouslySetInnerHTML={{ __html: JSON.parse(details.elem.questions)[id].body }}
                    />
                }

                <EditorContainer style={{ marginTop: '20px', marginRight: '10px' }}>
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={obj.length !== 0 ? obj[id].asw : null}
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                            options: ['inline',
                                'blockType',
                                'fontSize',
                                'fontFamily',
                                'list',
                                'textAlign',
                                'colorPicker',
                                'emoji',
                                'remove',
                                'history'],
                            colorPicker: {
                                icon: brush,
                            },
                        }}
                        localization={{
                            locale: 'it',
                        }}
                    />
                </EditorContainer>
            </div>
        </Wrapper>
    );
}

export default VerificationTestAvvio;