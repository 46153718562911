import { useEffect, useState } from 'react';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import OralLessons from './TemaLessons';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllTemaSubjects } from '../../../../Store/Actions/temaActions';

// STYLE
import {
    WrapperRightSide,
    TestWrapper,
    BtnWrapper,
    Btn,
} from '../../PagesShareStyle/PagesShareStyle';

const TypologieWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Lesson = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background3);
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const Right = styled.div`
    >span {
        font-size: 14px;
    }
    >i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) ${props => props.open === 'true' && 'rotate(-90deg)'};
        color: var(--text3);

    }
`;

const Sts = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    border-radius: var(--radius);
    background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
    >div:nth-last-child(1){
        border-radius:  0 0 var(--radius) var(--radius);
    }
`;


const TemaTypologies = ({ idCourse, idCheckUser, studDetails, course }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    const subs = [];    

    useEffect(() => {
        const id = idCheckUser !== undefined ? idCheckUser : profile.idUser;
    }, [dispatch]);

    // console.log('tpa', tpa);
    // console.log("DETAILS", idCourse);
    // console.log("PROFILE", profile);


    return (
        <TypologieWrapper>
            <Header>{subs !== undefined && subs.length} Materie</Header>
            {subs !== undefined &&
                subs.map((sub, i) =>
                    <div key={i}>
                        <OralLessons
                            //type={type}
                            idCheckUser={idCheckUser}
                            studDetails={studDetails}
                            course={course}
                        />
                    </div>
                )}
        </TypologieWrapper>
    );
}

export default TemaTypologies;