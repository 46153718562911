import React, { useState, useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';
// import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import _ from 'lodash';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../Store/Actions/usersActions';
// import { getServices } from '../../../../Store/Actions/servicesActions';
// import { setSize } from '../../../Store/Actions/otherActions';

import {
  WrapperRightSide,
  Title,
  ActionsWrapper,
  ActionsWrapperSpace,
  BtnWrapper,
  Btn,
  TableWrapper,
  Thead,
  Tbody,
  Th,
  // colourStyles,
  // NavTable,
  BtnLink,
  Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './UsersTable'

// const options = [
//   { value: '5', label: '5' },
//   { value: '10', label: '10' },
//   { value: '15', label: '15' },
//   { value: '25', label: '25' },
//   { value: '50', label: '50' },
//   { value: '100', label: '100' },
// ];

const tableHead = [
  'Nome',
  'Cognome',
  'E-mail',
  'Ruolo',
  //'Stato Apprendimento',
  'Azioni',
];

// let users = [
//   {
//     idUser: 1,
//     firstName: 'Frede',
//     lastName: 'Bianchi',
//     email: 'frede@gmail.com',
//     status: '10%',
//   },
//   {
//     idUser: 2,
//     firstName: 'Rico',
//     lastName: 'Lamela',
//     email: 'rico@gmail.com',
//     status: '40%',
//   },
//   {
//     idUser: 3,
//     firstName: 'Mario',
//     lastName: 'Luca',
//     email: 'mario@gmail.com',
//     status: '20%',
//   },
//   {
//     idUser: 4,
//     firstName: 'Maribela',
//     lastName: 'Verdi',
//     email: 'maribela@gmail.com',
//     status: '70%',
//   },
// ]

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector(({ users }) => users.allUsers);
  // const profile = useSelector(({ auth }) => auth.user);
  // const services = useSelector(({ services }) => services.allServices);
  // const pageSize = useSelector(({ other }) => other.setSize);
  const [paginatedPosts, setPaginatedPosts] = useState();
  const [usr, setUsr] = useState([]);
  // console.log(users)

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <WrapperRightSide>
      <Title>UTENTI</Title>

      <ActionsWrapperSpace>
        <div></div>

        <Search
          usr={users}
          flag={'users'}
          setUsr={setUsr}
        />

      </ActionsWrapperSpace>

      <ActionsWrapper>
        {/* <BtnWrapper>
          <BtnLink
            style={{ margin: '0' }}
            theme={'users'}
            to='/dashboard/users/newUser'>
            <i className="fas fa-plus"></i>Utenti
          </BtnLink>
        </BtnWrapper> */}

        {/* <Filter
          invs={invo}
          flag={'invoices'}
          setInvoice={setInvoice}
        /> */}
      </ActionsWrapper>

      <TableWrapper>
        {paginatedPosts === undefined ?
          <>
            <Loading>
              <h2>Non ci sono Utenti da mostrare</h2>
              <i className="fas fa-clipboard-list"></i>
            </Loading>
          </>
          :
          paginatedPosts.length === 0 ?
            <>
              <Loading>
                <h2>Non ci sono Utenti da mostrare</h2>
                <i className="fas fa-clipboard-list"></i>
              </Loading>
            </>
            :
            <table style={{ borderCollapse: 'collapse' }}>
              <Thead>
                <tr >
                  {tableHead.map((th, i) =>
                    <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                  )}
                </tr>
              </Thead>
              <Tbody>
                {paginatedPosts !== undefined &&
                  paginatedPosts.map((elem, i) =>
                    <Table
                      elem={elem}
                      key={i}
                    />
                  )
                }
              </Tbody>
            </table >
        }
      </TableWrapper >

      <NavTable
        obj={usr}
        setPaginatedPosts={setPaginatedPosts}
      />

    </WrapperRightSide >
  )
}

export default Users;