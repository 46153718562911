import React, { useState } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector } from 'react-redux';


const TestBody = styled.div`
    padding-bottom: 80px;
        >img{
            max-width: 400px;
            max-height: 400px;
        }
`;

const Question = styled.div`
    /* justify-content: center;
    text-align: center; */
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

const CommentWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
        >div{
            width: 100%;
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
`;

const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 0px;
    color: var(--text2);
    font-weight: bold;
`;

const TextArea = styled.textarea`
    width: 100%;
	/* max-width: 680px; */
    height: 200px;
    resize: none;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    background: transparent;
    color: var(--text1);
    transition: var(--transition) ease;
    font-size: 16px;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

const Section = styled.div`
	background: var(--background4);
    border-radius: var(--radius);
    padding: 10px;
    margin-top: 20px;
        >div{
            display: flex;
            >span{
                margin: 0 10px;
            }
        }
`;

const Title = styled.div`
	font-size: 20px;
    font-weight: bold;
    padding: 0 10px 10px;
`;

const TestRevisione = ({ tests, id, setAnswers, obj, opt, flag, doneAllObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const commObj = doneAllObj !== undefined && doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : [];

    // console.log('OBJ', obj);
    // console.log('doneAllObj', doneAllObj);
    console.log('TEST', tests);

    const text = "Si prega di discutere quanto segue:"

    return (
        <TestBody>
            <Question
                // dangerouslySetInnerHTML={{ __html: tests[id].question }}
                dangerouslySetInnerHTML={{ __html: text }}
            />

            {tests.length > 0 &&
                <div>
                    <Section>
                        <Title>Introduzione</Title>
                        {JSON.parse(tests[0].introduction).map((int, i) =>
                            <div key={i}>
                                <span>{int.intro}</span>  -  <span>{int.subIntro}</span>
                            </div>
                        )}
                    </Section>

                    <Section>
                        <Title>Corpo del testo (parole chiave)</Title>
                        {JSON.parse(tests[0].body).map((md, i) =>
                            <div key={i}>
                                <span>{md.midd}</span> - <span>{md.subMidd}</span>
                            </div>
                        )}
                    </Section>

                    <Section>
                        <Title>Conclusione</Title>
                        {JSON.parse(tests[0].end).map((con, i) =>
                            <div key={i}>
                                <span> {con.conc}</span> -  <span>{con.subConc}</span>
                            </div>
                        )}
                    </Section>
                </div>
            }

            <LabelCss htmlFor="asw1">Scrivi</LabelCss>
            <TextArea
                placeholder='Componi un testo compreso tra 2500 e 4000 caratteri'
                inputType='textarea'
                name='asw1'
                label='Risposta'
                value={obj}
                onChange={(e) => {
                    setAnswers(e.target.value)
                }}
            />

            {flag === 'verification' &&
                whoAmI.role === 'STUDENTE' &&
                commObj.length > 0 &&
                (Object.keys(commObj[id]).length) > 0 &&
                <CommentWrapper>
                    {commObj[id].map((com, i) =>
                        <div key={i}>
                            {i + 1}. <br></br>
                            {com.message}
                        </div>
                    )
                    }
                </CommentWrapper>
            }
        </TestBody>
    );
}

export default TestRevisione;