import React, { useState } from 'react';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector } from 'react-redux';

import {
    Td,
    // ExpandTableRates,
    ExpandBtn,
    TrDiv,
    // LinkDiv,
    PopUp2,
    EditWrapper,
    Edit,
    TdHeader,
    // PopUpTd,
    // colourStyles
} from '../PagesShareStyle/PagesShareStyle';

const Sts = styled.div`
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    max-width: 90px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        (props.color === 'Incompleto' && 'var(--warning)') ||
        (props.color === 'Completato' && 'var(--success)')
    };
`;


const TpaExercisesDoneTable = ({ elem, i, details, allDetails }) => {
    const profile = useSelector(({ auth }) => auth.user);
    const [viewArea, setViewArea] = useState(false);

    const handleStatus = () => {
        if (elem.answers === null) {
            return 'Incompleto'
        } else {
            const asw = JSON.parse(elem.answers);
            const qui = JSON.parse(elem.questions);

            let rez = 0;
            for (let i = 0; i < asw.length; i++) {
                if (Object.keys(asw[i]).length > 0) {
                    rez += 1
                }
            }
            if (rez < qui.length) {
                return 'Incompleto'
            } else {
                return 'Completato'
            }
        }
    };

    const nrAnsw = () => {
        const asw = JSON.parse(elem.answers);
        const qui = JSON.parse(elem.questions);

        let rez = 0;
        for (let i = 0; i < asw.length; i++) {
            if (Object.keys(asw[i]).length > 0) {
                rez += 1
            }
        }
        return rez;
    };

    const formatData = (data) => {
        // console.log(data);
        if (data !== null) {
            const extractData = data.split('T')
            let format;
            if (extractData[0] !== '') {
                const d = extractData[0].split('-');
                format = d[2] + '/' + d[1] + '/' + d[0];
            } else {
                format = ''
            }
            return format
        } else return ''

    };

    // console.log('PROFILE', profile); 
    // console.log('TPA TABLE ', details);
    // console.log("ELEM", elem);

    return (
        <>
            < tr >
                <Td>{formatData(elem.date)}</Td>
                <Td>
                    {elem.answers === null ? '0' : nrAnsw()}/
                    {JSON.parse(elem.questions).length}
                </Td>
                <Td>
                    <Sts
                        color={handleStatus()}
                    >
                        {handleStatus()}
                    </Sts>
                </Td>
                <Td>
                    {elem.grade !== null ?
                        elem.grade
                        :
                        'In attesa'
                    }
                </Td>
                <Td >
                    <div style={{ display: 'flex' }}>
                        <EditWrapper>
                            {/* {details.type === 'WARTEGG' || details.type === 'TEST GRAFICI' ? */}
                            {elem.type === 'wartegg' || elem.type === 'test-grafici' ?
                                profile.role === 'STUDENTE' ?
                                    <Edit
                                        to={{
                                            pathname: '/dashboard/view-tpa-test-done',
                                            state: { elem }
                                        }}
                                    >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </Edit>
                                    :
                                    <Edit
                                        to={{
                                            pathname: '/dashboard/tpa-test-verification',
                                            state: { elem, allDetails }
                                        }}
                                    >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </Edit>
                                :
                                <Edit
                                    to={{
                                        pathname: '/dashboard/tpa-test-verification',
                                        state: { elem, allDetails }
                                    }}
                                >
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Edit>
                            }
                            <PopUp2>Di più</PopUp2>
                        </EditWrapper>
                    </div>
                </Td>
            </tr >
        </>
    )
}

export default TpaExercisesDoneTable;

