import React, { useState } from 'react';

// PACKAGES
import styled from 'styled-components';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

// ASSETS
// import user from '../../../../Assets/user.jpg';

// REDUX
import { useDispatch } from 'react-redux';
import { newMessage } from '../../../../../Store/Actions/conversationsActions';

const GeneralWrapper = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--border2);
        >i{
            font-size: 20px;
            color: #0084ff;
            margin-left: 10px;
        }
`;

const Form = styled.form`
    width:100%;
    margin: 10px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    background: #0000000d;
    border-radius: 200px;
`;

const TextArea = styled.input`
    width: 100%;
    resize: none;
    height: 12px;
    padding: 10px 10px;
    min-height: 50px;
    border: none;
    background-color: transparent;
    border-radius: var(--radius);
    color: var(--text2);
        &:focus{
            outline:none;
        }
        &::placeholder { 
                color: var(--text2);
                font-size: 18px;
        }
`;

const Btn = styled.button`
    border: none;
    outline: none;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: var(--radius);
    background-color: transparent;
    /* margin-left: 10px; */
        >i{
            color: #0084ff;
            color: ${props => props.msg !== '' ? '#0084ff' : 'var(--text3)'}
        }
        /* &:hover{
            background-color: #f0f2f5;
        }    */
`;

const EmojiIcon = styled.div`
    position: relative;
    padding: 10px;
    cursor: pointer;
        >i{
            font-size: 20px;
            color: var(--text3);
        }
`;

const PickerWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    right: 0;
    z-index: 1;
`;


const Chat = ({ user, conv, socket, setArrivalMsg, chat }) => {
    const dispatch = useDispatch();
    const [newMsg, setNewMsg] = useState('');
    const [pickerVisible, setPickerVisible] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newMsg !== '') {
            dispatch(newMessage(newMsg, user, conv.convId));
            setArrivalMsg({
                SenderID: user.toString(),
                Text: newMsg,
                Chat: chat,
                createdAt: Date.now(),
            });
            setNewMsg('');

            socket.emit('sendMessage', {
                senderId: user,
                receiverId: conv.userId,
                text: newMsg,
                chat: chat,
            })
        }
    };

    const addEmoji = (e) => {
        // console.log('addEmoji', e);
        const add = newMsg + e.native;
        setNewMsg(add);
    };

    // console.log('BAR', socket);

    return (
        <GeneralWrapper>
            {/* <i className="fa-solid fa-circle-plus"></i> */}
            <Form onSubmit={handleSubmit}>
                <TextArea
                    placeholder="Aa"
                    type='text'
                    name='body'
                    value={newMsg}
                    autoComplete='off'
                    // rows="3"
                    onChange={(e) => setNewMsg(e.target.value)}
                >
                </TextArea>
                {newMsg ?
                    <Btn type='submit'><i className="fas fa-paper-plane" ></i></Btn>
                    :
                    <Btn msg={newMsg}><i className="fas fa-paper-plane" ></i></Btn>
                }
            </Form>

            <EmojiIcon
                onClick={() => setPickerVisible(!pickerVisible)}
            >
                <i className="fa-regular fa-face-smile"></i>
            </EmojiIcon>

            {pickerVisible &&
                <PickerWrapper>
                    <Picker
                        data={data}
                        onEmojiSelect={(e) => {
                            addEmoji(e)
                            // setPickerVisible(!pickerVisible)
                        }}
                        onClickOutside={() => setPickerVisible(false)}
                        theme='light'
                        locale='it'
                    />
                </PickerWrapper>
            }

        </GeneralWrapper>
    );
}

export default Chat;
