import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Info from './ResultInfo';
import LoadingCard from '../../GeneralComponents/Loading/LoadingCard';

import {
    PopUp2,
    Btn
} from '../../Pages/PagesShareStyle/PagesShareStyle';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubjects } from '../../../Store/Actions/subjectsActions';
import { getAllSubjectsOfAUser } from '../../../Store/Actions/subjectsActions';

const CardWrapper = styled.div`
    position: relative;
    width: 380px;
    background-color: var(--background4);
    margin: 10px 0px;
    padding: 20px 15px;
    min-height: 250px;
    border-radius: var(--radius);
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
        >i{
            position: absolute;
            top: 15px;
            right: 15px;
            color:  var(--text3);
            cursor: pointer;
                &:hover{
                    color: var(--acc-color);
                }
        }
        @media (max-width: 600px) {
            width: 100%;
            height: auto;
        };
`;

const MoreWrapper = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
        &:hover ${PopUp2} {
                opacity: 1;
                visibility: visible;
        }
`;

const More = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
        >i{
            font-size: 16px;
            color:  var(--text3);
            cursor: pointer;
                &:hover{
                    color: var(--acc-color);
                }
        }
`;

const Title = styled.div`
   color:  var(--text2);
   font-size: 18px;
   font-weight: bold;
   padding-top: 10px;
`;

const SubTitle = styled.div`
   color:  var(--text2);
   font-size: 14px;
   height: 80px;
`;

const Hr = styled.hr`
    border: none;
    border-bottom: 1px solid var(--text4);
    margin: 10px 0;
`;

const Points = styled.div`
    position: absolute;
    right: 20px;
    font-size: 60px;
    color:  var(--text4);
    font-weight: bold;
        >span{
            font-size: 30px;
        }
`;

const InfoWrapper = styled.div`
    position: relative;
    color:  var(--text2);
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    font-size: 14px;
`;

const InfoTitle = styled.div`
   color:  var(--text2);
   font-weight: bold;
   font-size: 14px;
`;

const TypeOfTest = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    border-radius: var(--radius);
    background: var(--background2);
`;
const MiniNavBtn = styled.button`
    display: flex;
    font-size: 25px;
    padding: 10px 20px;
    /* width: 100%; */
    border: none;
    outline:none;
    background: var(--background2);
    /* background: ${(props) => props.select === 'select' ? 'var(--acc-color)' : 'var(--background3)'}; */
    /* color: ${(props) => props.select === 'select' ? 'var(--text5)' : 'var(--text2)'}; */
    font-weight: ${(props) => props.select === 'select' && 'bold'};
    color: ${(props) => props.select === 'select' ? 'var(--acc-color)' : 'var(--text2)'};
`;


const ResultCard = ({ res, idCheckUser, studDetails, userAllSubjects, ind, type, setType }) => {
    const dispatch = useDispatch();
    // const profile = useSelector((state) => state.auth.user);
    const trend = useSelector(({ subjects }) => subjects.userAllSubjects);
    // const [actualId, setActualId] = useState('preliminare');
    const availableTests = JSON.parse(res.availableTests);

    //console.log('RES CARD', res);
    // console.log("TREND", trend);
    //console.log(type);

    const handleChangeTrend = (e) => {
        setType(e.currentTarget.id)
        dispatch(getAllSubjectsOfAUser(idCheckUser, e.currentTarget.id))
    };

    const formatTime = (totalMin) => {
        console.log('DAAAA')
        //const totalMin = userAllSubjects.time;
        let days = Math.floor(totalMin / (24 * 60));
        let hours = Math.floor((totalMin - (days * 1440)) / 60);
        let minutes = Math.round(totalMin % 60);

        let time;

        if (days > 0) {
            time = days + ' giorni ' + hours + ' ore ' + minutes + ' minuti ';
        } else if (hours > 0) {
            time = hours + ' ore ' + minutes + ' minuti ';
        } else if (minutes > 0) {
            time = minutes + ' minuti ';
        } else {
            time = `Nessuna attività`;
        };

        // console.log(totalMin, 'day', days, 'h', hours, 'm', minutes);
        return time
    };

    const makeMiniNav = () => {
        let btnsMiniNav = [];

        for (let i = 0; i < availableTests.length; i++) {
            if (availableTests[i].prova === 'Preselettiva') {
                btnsMiniNav.push({
                    id: 'preliminare',
                    icon: <i className="fa-brands fa-connectdevelop"></i>
                })
            } else if (availableTests[i].prova === 'TPA') {
                btnsMiniNav.push({
                    id: 'tpa',
                    icon: <i className="fa-solid fa-atom"></i>
                })
            } else if (availableTests[i].prova === 'TEMA') {
                btnsMiniNav.push({
                    id: 'orale',
                    icon: <i className="fa-solid fa-ear-listen"></i>
                })
            } else if (availableTests[i].prova === 'Orale') {
                btnsMiniNav.push({
                    id: 'fisiche',
                    icon: <i className="fa-solid fa-person-running"></i>
                })
            } else if (availableTests[i].prova === 'Fisica') {
                btnsMiniNav.push({
                    id: 'tema',
                    icon: <i className="fa-solid fa-book-open"></i>
                })
            };
        }

        // availableTests.forEach((av, i) => {
        //     if (av[i].prova === 'Preselettiva') {
        //         btnsMiniNav.push({
        //             id: 'preliminare',
        //             icon: <i className="fa-brands fa-connectdevelop"></i>
        //         })
        //     } else if (av[i].prova === 'Test Psico Attitudinali') {
        //         btnsMiniNav.push({
        //             id: 'tpa',
        //             icon: <i className="fa-solid fa-atom"></i>
        //         })
        //     } else if (av[i].prova === 'Prova Scritta - Tema') {
        //         btnsMiniNav.push({
        //             id: 'orale',
        //             icon: <i className="fa-solid fa-ear-listen"></i>
        //         })
        //     } else if (av[i].prova === 'Prova Orale') {
        //         btnsMiniNav.push({
        //             id: 'fisiche',
        //             icon: <i className="fa-solid fa-person-running"></i>
        //         })
        //     } else if (av[i].prova === 'Prova Fisica') {
        //         btnsMiniNav.push({
        //             id: 'tema',
        //             icon: <i className="fa-solid fa-book-open"></i>
        //         })
        //     };
        // })

        return btnsMiniNav;
    };

    // const btnsMiniNav = [
    //     {
    //         id: 'preliminare',
    //         icon: <i className="fa-brands fa-connectdevelop"></i>
    //     },
    //     {
    //         id: 'tpa',
    //         icon: <i className="fa-solid fa-atom"></i>
    //     },
    //     {
    //         id: 'orale',
    //         icon: <i className="fa-solid fa-ear-listen"></i>
    //     },
    //     {
    //         id: 'fisiche',
    //         icon: <i className="fa-solid fa-person-running"></i>
    //     },
    //     {
    //         id: 'tema',
    //         icon: <i className="fa-solid fa-book-open"></i>
    //     },
    // ];

    return (
        <CardWrapper>
            {/* {(profile !== undefined && profile.accesLevel === 'admin') &&
                <Admin
                    content={content}
                />
            } */}
            <MoreWrapper>
                <More
                    to={{
                        // pathname: '/dashboard/users/tests-details',
                        pathname: '/dashboard/students/student-types',
                        state: { res, flag: 'res', idCheckUser: idCheckUser, studDetails: studDetails }
                    }}
                >
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </More>
                <PopUp2>Di più</PopUp2>
            </MoreWrapper>

            <Title>{res.course_name} - {res.course_code}</Title>
            {/* <SubTitle>{res.desc.length > 100 ? (res.desc.slice(0, 100) + "...") : res.desc}</SubTitle> */}

            <Hr></Hr>

            <TypeOfTest >
                {/* <MiniNavBtn select={type === 'preliminare' ? 'select' : null}>
                    <i className="fa-brands fa-connectdevelop" id='preliminare' onClick={(e) => handleChangeTrend(e)}></i>
                </MiniNavBtn>
                <MiniNavBtn select={type === 'tpa' ? 'select' : null}>
                    <i className="fa-solid fa-atom" id='tpa' onClick={(e) => handleChangeTrend(e)}></i>
                </MiniNavBtn>
                <MiniNavBtn select={type === 'orale' ? 'select' : null}>
                    <i className="fa-solid fa-ear-listen" id='orale' onClick={(e) => handleChangeTrend(e)}></i>
                </MiniNavBtn>
                <MiniNavBtn select={type === 'fisiche' ? 'select' : null}>
                    <i className="fa-solid fa-person-running" id='fisiche' onClick={(e) => handleChangeTrend(e)}></i>
                </MiniNavBtn>
                <MiniNavBtn select={type === 'tema' ? 'select' : null}>
                    <i className="fa-solid fa-book-open" id='tema' onClick={(e) => handleChangeTrend(e)}></i>
                </MiniNavBtn> */}
                {makeMiniNav().map((btn, i) =>
                    <MiniNavBtn
                        key={i}
                        id={btn.id}
                        select={type === btn.id ? 'select' : null}
                        onClick={(e) => handleChangeTrend(e)}
                    >
                        {btn.icon}
                    </MiniNavBtn>
                )
                }
            </TypeOfTest>

            {trend === undefined ?
                <LoadingCard />
                :
                <Info
                    info={trend[ind]}
                    type={type}
                    avaible={makeMiniNav()}
                />
            }
        </CardWrapper>
    );
};

export default ResultCard;


// /dashboard/users/results => Lezioni completate, trebuie sa tina cont daca a terminat si questionarul asociat lectii


// /dashboard/teachers/details => Test corretti, doar studenti care au acelas center ca centru
