import React, { useState, useEffect } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector } from 'react-redux';

//import InputCss from '../../GeneralComponents/Inputs/Inputs';
//import { Input } from '../../Pages/PagesShareStyle/DetailsShareStyle'
const TextArea = styled.textarea`
    width: 100%;
	max-width: 680px;
    height: 150px;
    resize: none;
    padding: 6px;
    margin-top: 10px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    background: transparent;
    color: var(--text1);
    transition: var(--transition) ease;
    font-size: 16px;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

const TestBody = styled.div`
    padding-bottom: 20px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const BigQuestion = styled.div`
    width: 80%;
    max-height: 250px;
    overflow: scroll;
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 20px;
    background: var(--background4);
    padding: 20px 10px;
    border-radius: var(--radius);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        ::-webkit-scrollbar {
            width: 4px; 
        };
        @media (max-width: 1650px) {
            width: 95%;
        };  
`;

const Question = styled.div`
    /* justify-content: center;
    text-align: center; */
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

const Letter = styled.div`
    color:  var(--text2);
    font-size: 24px;
    font-weight: bold;
    display: flex;
    padding: 20px;
    background-color: var(--background3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Answers = styled.div`

    width: 100%;
    color:  var(--text2);
    font-size: 20px;
    display: flex;
    padding: 20px;
    justify-content: center;
    text-align: center;
    background-color: var(--background4);
    >img{
        max-width: 400px;
        max-height: 400px;
        pointer-events: none;
    }
`;

const AnswerWrapper = styled.div`
    width: 80%;
    justify-content: center;
    text-align: center;
    display: flex-end;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    background: var(--background4);
    cursor: pointer;
    ${Letter}{
            background: ${props => props.selected === 'selected' && 'var(--warning)'}!important;
            color: ${props => props.selected === 'selected' && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => props.selected === 'selected' && '#f8940655'}!important;
        }
        &:hover ${Letter}{ 
            background: #99979791;
            color: var(--text5);
        }
        &:hover ${Answers}{ 
            background: #bbbbbb55;
        } 
`;

const CommentWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
        >div{
            width: 100%
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
`;



const QuestionFree = ({ tests, id, setAnswers, obj, flag, doneAllObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const [text, setText] = useState('');
    const commObj = doneAllObj !== undefined && doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : [];


    useEffect(() => {
        if (text !== '') {
            setAnswers(text, id)
        }
        // 
    }, [text]);

    useEffect(() => {
        if (obj.length > 0) {
            if (Object.keys(obj[id]).length > 0) {
                setText(obj[id].asw)
            } else {
                setText('');
            }
        }
    }, [id, obj]);

    //console.log("tests", tests);
    // console.log('doneAllObj', doneAllObj);
    // console.log('commObj', commObj);

    return (
        <TestBody>
            <Question
                dangerouslySetInnerHTML={{ __html: tests[id].question }}
            />
            <TextArea
                placeholder='Inserisci la tua risposta'
                inputType='textarea'
                name='asw'
                label='Risposta'
                value={text}
                onChange={(e) => {
                    console.log('eeeee', e);
                    setText(e.target.value)
                }}
            />


            {/* {opt.map((ans, i) =>
                <AnswerWrapper
                    key={i}
                    // onClick={(e) => setAnswers(e, i, letters)}
                    onClick={(e) => setAnswers(e, i)}
                    selected={obj[id] !== undefined && obj[id].aswIndex === i && 'selected'}
                >
                    <Answers id={i}
                        dangerouslySetInnerHTML={{ __html: ans.value }}
                    />

                </AnswerWrapper>
            )} */}

            {flag === 'verification' &&
                whoAmI.role === 'STUDENTE' &&
                commObj.length > 0 &&
                (Object.keys(commObj[id]).length) > 0 &&
                <CommentWrapper>
                    {commObj[id].map((com, i) =>
                        <div key={i}>
                            {i + 1}. <br></br>
                            {com.message}
                        </div>
                    )
                    }
                </CommentWrapper>
            }
        </TestBody>
    );
}

export default QuestionFree;