import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { bringTestsDone, bringSimulationsDone, getAllWrongQuezzes } from '../../../Store/Actions/testActions';

// COMPONENTS
import TemaModal from '../Modals/TemaModal';

const CardWrapper = styled(Link)`
    position: relative;
    width: 250px;
    height: ${props => props.flag === 'true' ? '250px' : '250px'};
    background-color: var(--background4);
    margin: 20px 0px;
    padding: 15px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    cursor: pointer;
    text-decoration: none;
    color: var(--text3);
    font-size: 20px;
    border:none;
    outline: none;
    /* transition: all .4s ease-in-out; */
        >i{
            font-size: 80px;
            margin-bottom: 10px;
            color: var(--text3);
        }
        &:hover{
            box-shadow: var(--shadow);
            /* transform:translateY(-2px); */
            color: var(--acc-color);
            font-weight: bold;
            /* transition: color .4s ease-in-out; */
            /* >i{
                color: var(--acc-color);
                transition: color .3s ease-in-out;
            } */
        }
        @media (max-width: 600px) {
            width: 100%;
        }
`;

const Hr = styled.hr`
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--text4);
    margin: 10px 0;
`;

const Number = styled.div`
    font-size: 50px;
    font-weight: bold;
    color:  var(--text1);
    > img{
        width: 20px;
    }
`;

const LinkWrapper = styled.div`
   margin-top: 0px;
   font-size: 24px;
   font-weight: bold;
   text-align: center;
        @media (max-width: 400px) {
            font-size: 6vw;
        }
`;

const BtnLink = styled(Link)`
    border:none;
    outline: none;
    color: var(--text3);
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* background: var( --background1);
    color: var(--text5);
    border-radius: var(--radius);
    padding: 10px 30px;
    width: 230px; */
        &:hover{
            transition: .2s ease-in-out;
            color:  var(--acc-color);   
            font-weight: bold;

            /* background:var(--acc-color);
            font-weight: bold; */
        }
        &:active{
                transform:translateY(2px);
        }  
`;

const CardWithModal = styled.div`
    position: relative;
    width: 250px;
    /* height: ${props => props.flag === 'true' ? '250px' : '250px'}; */
    height: 250px;
    background-color: var(--background4);
    margin: 20px 0px;
    padding: 15px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    cursor: pointer;
    text-decoration: none;
    color: var(--text3);
    font-size: 20px;
    border:none;
    outline: none;
    /* transition: all .4s ease-in-out; */
        >i{
            font-size: 80px;
            margin-bottom: 10px;
            color: var(--text3);
        }
        &:hover{
            box-shadow: var(--shadow);
            /* transform:translateY(-2px); */
            color: var(--acc-color);
            font-weight: bold;
            /* transition: color .4s ease-in-out; */
            /* >i{
                color: var(--acc-color);
                transition: color .3s ease-in-out;
            } */
        }
        @media (max-width: 600px) {
            width: 100%;
        };
`;


const TemaCardNew = ({ type, flag, course, done, allDetails, svolgere }) => {
    const dispatch = useDispatch();
    // const profile = useSelector((state) => state.auth.user);
    // const testsDone = useSelector((state) => state.test.bringTestsDone);
    // const simulationsDone = useSelector((state) => state.test.bringSimulationsDone);
    // const wrong = useSelector(({ test }) => test.bringWrongQuezzes);
    const [openModal, setOpenModal] = useState(false);

    const formatPath = (path) => {
        const newPath = path.split(' ').join('-');
        const final = newPath.toLowerCase();
        return final
    };

    // const svolgere = [
    //     'Analisi della traccia',0
    //     'Elaborazione del Testo o Scaletta',1
    //     'Coerenza e stesura del testo',2
    //     'Stesura e revisione del tema (BASE)',3
    //     'Registri linguistici',4
    //     'Avvio alla scrittura',5




    // ];
    //console.log('testsDone', done);
    // console.log(flag);
    // console.log('TYPE', type);
    //console.log("TEMA svolgere", svolgere);


    return (
        // (type === svolgere[0] ||
        //     type === svolgere[1] ||
        //     type === svolgere[3] ||
        //     type === svolgere[5]) &&
        (type === svolgere[0] ||
            type === svolgere[1] ||
            type === svolgere[2] ||
            type === svolgere[3]) &&
            flag !== 'svolti' ?
            <>
                <CardWithModal
                    onClick={() => setOpenModal(true)}
                >
                    <LinkWrapper>
                        {type}
                    </LinkWrapper>

                </CardWithModal>
                {openModal &&
                    <TemaModal
                        setOpenModal={setOpenModal}
                        title={type}
                        course={course}
                        type={type}
                        svolgere={svolgere}
                    />
                }
            </>
            :
            flag !== 'svolti' ?
                <CardWrapper
                    to={{
                        pathname: `/dashboard/tema-exercises/pre-tema`,
                        state: { type, course, svolgere }
                    }}
                >
                    <LinkWrapper>
                        {type}
                    </LinkWrapper>

                </CardWrapper >
                :
                <CardWrapper
                    to={{
                        pathname: `/dashboard/tema-exercises/done-tema`,
                        state: { type, course, done, allDetails, svolgere }
                    }}
                >
                    <LinkWrapper>
                        {type}
                    </LinkWrapper>

                </CardWrapper >
    );
};

export default TemaCardNew;