import React, { useState, useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import GoBackTitle from '../../GeneralComponents/GoBackButton/GoBackButton';
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './TestTypeDetailsTable';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getActvity } from '../../../Store/Actions/usersActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

const tableHead = [
    'Nome',
    'Cognome',
    'E-mail',
    'Centro',
    'Classe',
    'Azioni',
];

const TestTypeDetails = ({ details }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user)
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [types, setTypes] = useState([]);
    const [type, setType] = useState([]);
    const [idsTestsDone, setIdsTestsDone] = useState()

    useEffect(() => {
        const activ = details.teacherActivity;
        let arr = [];
        let arrIdTestsDone = [];
        const find = activ.filter((act) => act.test_type === details.elem.type);
        for (let i = 0; i < find.length; i++) {
            arr.push(find[i].idStudent_User);
            arrIdTestsDone.push(find[i].idTest);
        };

        const arrayUniqueByKey = [...new Map(arr.map(item =>
            [item[item.idUsers], item])).values()];
        // console.log("TestTypeDetails", arrIdTestsDone);
        setTypes(arrayUniqueByKey);
        setIdsTestsDone(arrIdTestsDone);
    }, [details]);

    // console.log('TEST DETAILS', details);

    return (
        <WrapperRightSide>

            <GoBackTitle
                title={details.elem.type}
            />

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    type={types}
                    flag={'stud'}
                    setType={setType}
                />

            </ActionsWrapperSpace>

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <Btn
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/courses/newCourse'>
                        <i className="fas fa-plus"></i>Utenti
                    </Btn>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 300px)' }}>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono risultati da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono risultati da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            idsTestsDone={idsTestsDone}
                                            key={i}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={type}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default TestTypeDetails;