import axios from 'axios';
axios.defaults.withCredentials = true;

export const getAllPhysicalSubjects = (idCourse, idUser) => (dispatch, getState) => {

    const body = {
        idCourse: idCourse,
        idUser: idUser,
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllPhysicalSubjects`, body)
        .then((res) => {
            // console.log("GET_ALL_PHYSICAL_SUBJECTS", res.data);
            dispatch({
                type: 'GET_ALL_PHYSICAL_SUBJECTS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllPhysicalQuestionnaires = (idPhisicalLessons, idUser) => (dispatch, getState) => {

    // console.log("LESSON", idPhisicalLessons, " USER ", idUser);
    const body = {
        idPhisicalLessons: idPhisicalLessons,
        idUser: idUser
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllPhysicalQuestionnaires`, body)
        .then((res) => {
            // console.log("GET_ALL_PHYSICAL_QUESTIONNAIRES", res.data);
            dispatch({
                type: 'GET_ALL_PHYSICAL_QUESTIONNAIRES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const downloadFile = (doc) => (dispatch, getState) => {
    const body = {
        file: doc.url
    };

    const config = {
        responseType: 'blob'
    };

    const fileName = doc.name;
    
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/downloadPhysicalFile`, body, config)
        .then((res) => {
            // console.log("DOWNLOAD_FILE_PHYSICAL", res.data);
            const data = {
                name: fileName,
                data: res.data
            }
            dispatch({
                type: 'DOWNLOAD_FILE_PHYSICAL',
                payload: data,
            });
            setTimeout(() => {
                dispatch({
                    type: 'DOWNLOAD_FILE_PHYSICAL',
                    payload: undefined,
                })
            }, 2000);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const sendPhysicalQuestAnswers = (obj, questInfo) => (dispatch, getState) => {
    const profile = getState().auth.user;

    // console.log("astaaaa", questInfo);
    const body = {
        answers: JSON.stringify(obj),
        // userID: '1',
        userID: profile.idUser,
        idPhisicalQuest: questInfo.idPhisicalQuestionnaire,
        name: questInfo.name,
        point: questInfo.point,
        allQuestions: JSON.stringify(questInfo.PhisicalQuestionaryQuizzs)
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/sendPhysicalQuestAnswers`, body)
        .then((res) => {
            // console.log("SAVE_PHYSICAL_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_PHYSICAL_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const videoPhysicalIsDone = (idPhisicalLesson, idPhisicalSubject, idCourse, time) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        userID: profile.idUser,
        idPhisicalLesson: idPhisicalLesson,
        idPhisicalSubject: idPhisicalSubject,
        idCourse: idCourse,
        videoTime: time
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/videoPhysicalIsDone`, body)
        .then((res) => {
            // console.log("SAVE_PHYSICAL_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_PHYSICAL_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};


