import React, { useState, memo } from 'react';

// PACKAGES
import styled from 'styled-components';
import Countdown, { zeroPad } from 'react-countdown';

// REDUX
import { useDispatch } from 'react-redux';
import { finishSimulation, finishTime } from '../../../Store/Actions/otherActions';

const TimeWrapper = styled.div`
   color:  var(--text2);
   font-size: 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 0 10px;
        >div{
            color:  var(--text2);
            font-weight: bold;
            font-size: 40px;
                >span{
                    font-size: 20px; 
                }
        }
        >span{
            color:  var(--text3);
            font-size: 14px;
        }
`;


const TimeCounter = memo(({ time }) => {
    const dispatch = useDispatch();

    // Random component
    // const Completionist = () => <span>You are good to go!</span>;

    const comp = () => {
        dispatch(finishSimulation(true));
    };

    const tick = ( e) => {
        // console.log(e);
        dispatch(finishTime(`${zeroPad(e.hours)}:${zeroPad(e.minutes)}:${zeroPad(e.seconds)}`))
    };

    // Renderer callback with condition
    // const renderer = ({ hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //         // Render a complete state
    //         // return <Completionist />;
    //     } else {
    //         // Render a countdown
    //         return (
    //             <div>
    //                 {zeroPad(hours)}:{zeroPad(minutes)}<span>{zeroPad(seconds)}</span>
    //             </div>
    //         );
    //     }
    // };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <div>
                {zeroPad(hours)}:{zeroPad(minutes)}<span>{zeroPad(seconds)}</span>
            </div>
        );
    };

    return (
        <TimeWrapper>
            {/* <div>40:00</div> */}
            <Countdown
                date={Date.now() + time * 60 * 1000}
                renderer={renderer}
                onComplete={comp}
                onTick={tick}
            />
            <span>Durata {time}min</span>

        </TimeWrapper>
    );
});

export default TimeCounter;