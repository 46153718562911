import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { allDrawing } from '../../../../../Store/Actions/tpaActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
    GoBack,
} from '../../../PagesShareStyle/PagesShareStyle';

// const Wrapper = styled.div`
//     //flex-wrap: wrap;
//     margin-top: 20px;
//     overflow: hidden;
//     border: var(--radius);
//     display: flex;
//     align-items: left;
//     justify-content: left;
//         >img{
//             width: 100%;
//             height: 100%;
//             max-width: 1000px;
//         }
// `;

// const CommentWrapper = styled.div`
//     width: 100%;
//     /* margin-top: 20px; */
//     padding:0px 10px ;
//     display: flex;
//     flex-wrap: wrap;
//     height: 100%;
//     justify-content:left;
//     //height: auto;
//         >div{
//             width: 350px;
//             height: auto;
//             min-width: 250px;
//             background: var(--background4);
//             border-radius: var(--radius);
//             padding: 10px;
//             display: flex;
//             flex-direction: column;
//             overflow: auto;
//             margin-right: 20px;
//             margin-bottom: 20px;
//             box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
//             >span{
//                 margin-bottom: 10px;
//             }
//         }
// `;

// const GradeWrapper = styled.div`
//     margin-top: 20px;
//     display: flex;
//     justify-content: flex-start;
//         >span{
//             background: ${props => props.sts ? 'var(--text1)' : 'var(--warning)'};
//             color: var(--text5);
//             border-radius: 50px;
//             padding: 5px 20px;
//         }
// `;

// const GradeWrapper = styled.div`
//     margin-top: 20px;
//     display: flex;
//     justify-content: flex-start;
//         >span{
//             background: ${props => props.sts ? 'var(--text1)' : 'var(--warning)'};
//             color: var(--text5);
//             border-radius: 50px;
//             padding: 5px 20px;
//         }
// `;

const Wrapper = styled.div`
    /* width: 70%; */
    margin-top: 20px;
    /* overflow: hidden; */
    border: var(--radius);
    display: flex;
    justify-content: start;
    /* align-items: center; */
    /* justify-content: left; */
    /* flex-wrap: wrap; */
        >img{
            width: 100%;
            height: 100%;
            max-width: 1000px;
            border-radius: var(--radius);
        }
        @media (max-width: 1490px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            /* max-width: 1000px; */
        };
`;

const CommentWrapper = styled.div`
    width: 30%;
    padding: 0 20px;
    height: 100%;
        >div{
            width: 350px;
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
        @media (max-width: 1490px) {
            padding: 20px 0;
            /* display: flex;
            flex-wrap: wrap; */
            width: 100%;
            >div{
                width: 100%;
            }
        };
`;

const GradeWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
        >span{
            background: ${props => props.sts ? 'var(--text1)' : 'var(--warning)'};
            color: var(--text5);
            border-radius: 50px;
            padding: 5px 20px;
        }
`;

const ReadOnlyDrawingTest = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const drawings = useSelector(({ tpa }) => tpa.allDrawing);

    useEffect(() => {
        dispatch(allDrawing(details.elem.idTpaDone))
    }, [dispatch]);

    // console.log('ReadOnlyDrawingTest', details);

    return (
        <WrapperRightSide>
            <TitleWrapper style={{ display: 'flex' }}>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                {/* <Title>Esercizi</Title> */}

                <Title style={{ margin: '0' }}>
                    Torna indietro
                </Title>
            </TitleWrapper>

            <GradeWrapper sts={drawings !== undefined && drawings.grade !== null}>
                <span>
                    Giudizio: {drawings !== undefined &&
                        drawings.grade !== null ?
                        drawings.grade
                        :
                        'In attesa...'
                    }
                </span>
            </GradeWrapper>

            <Wrapper>
                {drawings !== undefined ?
                    drawings.correctionPng !== null ?
                        <img src={drawings.correctionPng} alt='img'></img>
                        :
                        drawings.url !== null ?
                            <img src={drawings.url} alt='img'></img>
                            :
                            <img src={JSON.parse(drawings.answers)[0].png} alt='img'></img>
                    :
                    null
                }

                <CommentWrapper>
                    {drawings !== undefined &&
                        drawings.comments !== null &&
                        JSON.parse(drawings.comments).map((comment, i) =>
                            <div key={i}>
                                <span>{i + 1}.</span>
                                {comment.message}
                            </div>
                        )
                    }
                </CommentWrapper>
            </Wrapper>


        </WrapperRightSide>
    );
}

export default ReadOnlyDrawingTest;
