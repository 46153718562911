import React from 'react';

// PACKAGES
import styled from 'styled-components';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    GoBack,
} from '../../../PagesShareStyle/PagesShareStyle';
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    QueContainer,
    ResContainer
} from './Style';


const CoerenzaView = ({ oneTema }) => {
    // console.log('TEMA VIEW', oneTema);

    return (
        <Wrapper>
            <div style={{width: '100%'}}>
                {/* <QueContainer>
                    {oneTema.idTextProcessing_TextProcessing.topic}
                </QueContainer> */}
                <QueContainer>
                    Risposta:
                </QueContainer>
                {/* <LabelCss htmlFor="asw2">Risposta</LabelCss> */}
                {JSON.parse(oneTema.senteces).map((int, i) =>
                    <ResContainer key={i} style={{ flexDirection: 'column', marginTop: '10px' }}>
                        <span>{int.title}</span>
                        <span>{int.content}</span>
                    </ResContainer>
                )}

            </div>

            <CommentWrapper>
                {oneTema.comments !== null &&
                    JSON.parse(oneTema.comments).map((comment, i) =>
                        <div key={i}>
                            <span>{i + 1}.</span>
                            {comment.message}
                        </div>
                    )
                }
            </CommentWrapper>
        </Wrapper>
    );
}

export default CoerenzaView;