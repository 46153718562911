import { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// ASSETS
import loading from '../../../Assets/loadingGif.gif';

// COMPONENTS
import Table from './ChartTable/ChartTable';

// PACKAGES
import styled from 'styled-components';
import {
    Chart as ChartJS,
    ChartArea,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';
import {
    Pie,
    getDatasetAtEvent,
    getElementsAtEvent,
    getElementAtEvent
} from 'react-chartjs-2';
import Select from 'react-select';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getGeneralStatistics } from '../../../Store/Actions/statisticsActions';

const Body = styled.div`
    position: relative;;
    width: 60%;
    min-height: 400px;
    background: white;
    margin-top: 40px;
    border-radius: var(--radius);
    padding: 20px;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        >h5{
            font-weight: bold;
            color: var(--text4);
            margin-bottom: 5px;
        }
        @media (max-width: 1300px) {
            width: 100%;
        };
        
`;

const Head = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 20px;
   >h4{
            color: var(--text2);
            font-weight: bold; 
            >span{
                color: var(--success);
                font-size: 14px;
                font-weight: bold; 
                margin-left: 10px;
            }
        }
`;

const BodyWrapper = styled.div`
   display: flex;
   margin-bottom: 20px;
        @media (max-width: 1300px) {
            flex-direction: column;
        };
`;

const LoadingDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >img{
            width: 120px;
        }
        >h4{
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, -90%);
            white-space: nowrap;
        }
`;

const colourStyles = {
    control: (styles, state) => ({
        ...styles,
        width: 200,
        height: '40px',
        borderRadius: '40px',
        backgroundImage: 'linear-gradient(-225deg,#A445B2 0%,#D41872 52%,#d32058 100%)',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text5)',
        fontWeight: 'bold',
        boxShadow: '0 0.3125rem 0.625rem 0 rgb(0 0 0 / 12%)',
        border: 'transparent',
    }),
    menu: styles => ({
        ...styles,
        width: 300,
        padding: 10,
        backgroundColor: 'var(--background4)',
        border: 'none',
        borderRadius: 'var(--radius)',
        fontSize: '12px',
        color: 'var(--text1)',
        zIndex: 2,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? 'var(--acc-color)'
                : isFocused
                    ? 'var(--text4)'
                    : undefined,
        borderRadius: 'var(--radius)',
        marginTop: 2,
        marginBottom: 2,
        cursor: 'pointer',
        // cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                    ? 'var(--acc-color)'
                    : 'var(--text4)'
                : undefined,
        },
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text5)!important' }),
    placeholder: styles => ({ ...styles, fontSize: '12px', fontWeight: '100', color: 'var(--text5)' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px', color: '#fff' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
    dropdownIndicator: (styles, { data }) => ({ ...styles, color: '#fff' }),
};


const ChartPie = () => {
    const chartRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const statistics = useSelector(({ statistics }) => statistics.generalStatistics);
    const [dataSet, setDataSet] = useState([]);
    const label = ['Preselettiva', 'TPA', 'Orale', 'Tema', 'Fisica']
    // const [opt, setOpt] = useState();
    // const [selectCourse, setSelectCourse] = useState();

    console.log(statistics)
    ChartJS.register(
        ArcElement,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Tooltip,
        Legend,
        Filler,
    );

    useEffect(() => {
        if (statistics !== undefined) {
            let opt = [];
            for (let i = 0; i < statistics.length; i++) {
                opt.push(statistics[i].totalPercent)
            };
            setDataSet(opt);
        }
    }, [statistics]);


    const dataPie = {
        // labels: ['Preselettiva', 'TPA', 'Orale', 'Tema',  'Fisica'],
        labels: dataSet.map((nr, i) => label[i]),
        datasets: [
            {
                label: '# of Votes',
                data: dataSet,
                backgroundColor: [
                    '#fd6ba1',
                    '#6b98fd',
                    '#fdeb6b',
                    '#4ecee3',
                    '#ff9e66',
                ],
                borderColor: [
                    '#fff',
                    '#fff',
                    '#fff',
                    '#fff',
                    '#fff',
                ],
                borderWidth: 2,
                weight: 100,
            },
        ],

    };

    const options = {
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 20,
                    boxHeight: 20
                }
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
                fontSize: 25,
                color: 'red'
            },
        },
        maintainAspectRatio: false,
    };

    const onClick = (event) => {
        // console.log(getDatasetAtEvent(chartRef.current, event));
        // console.log(getElementAtEvent(chartRef.current, event));
        // console.log(getElementsAtEvent(chartRef.current, event));

        const elem = getElementAtEvent(chartRef.current, event);


        let flag;
        if (elem[0].index === 0) {
            flag = 'Preselettiva';
        } else if (elem[0].index === 1) {
            flag = 'Tpa';
        } else if (elem[0].index === 2) {
            flag = 'Orale';
        } else if (elem[0].index === 3) {
            flag = 'Tema';
        } else if (elem[0].index === 4) {
            flag = 'Fisica';
        };

        history.push({
            pathname: '/dashboard/profile/overview',
            state: { flag }
        });

        // let url;
        // if (elem[0].index === 0) {
        //     url = '/dashboard/learning';
        // } else if (elem[0].index === 1) {
        //     url = '/dashboard/tpa-learning';
        // } else if (elem[0].index === 2) {
        //     url = '/dashboard/tema';
        // } else if (elem[0].index === 3) {
        //     url = '/dashboard/orale';
        // } else if (elem[0].index === 4) {
        //     url = '/dashboard/fisica';
        // };

        // history.push({
        //     pathname: url,
        //     state: {
        //         course: selectCourse !== undefined && selectCourse.value
        //     }
        // });
    };

    useEffect(() => {
        if (statistics === undefined) {
            dispatch(getGeneralStatistics(profile.idUser));
        }
    }, [profile, statistics]);


    // console.log('CHART PIE', selectCourse);
    // console.log('CHART PIE', statistics);
    // console.log('CHART PIE PROFILE', profile);

    return (
        <BodyWrapper>
            <Body style={{ marginRight: '20px' }}>
                <h5>Panoramica del corsi</h5>
                <Head>
                    <h4>
                        Learning
                        {/* <span>+50%</span> */}
                    </h4>

                    {/* {selectCourse !== undefined &&
                    <h4>
                        {selectCourse.label.length > 20 ?
                            selectCourse.label.slice(0, 20) + '...'
                            :
                            selectCourse.label
                        }
                        <span>+50%</span>
                    </h4>
                } */}
                    {/* <Select
                    name='course'
                    isMulti={false}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    value={selectCourse}
                    onChange={(e) => setSelectCourse(e)}
                    options={opt}
                    styles={colourStyles}
                    placeholder={'Scegli un corso'}
                /> */}
                </Head>

                <div>
                    {statistics !== undefined ?
                        <  Pie
                            ref={chartRef}
                            onClick={onClick}
                            data={dataPie}
                            width={300}
                            height={300}
                            options={options}
                        />
                        :
                        <LoadingDiv>
                            <img src={loading} alt='Loading...'></img>
                            <h4 style={{ color: 'var(--text2)' }}>Caricamento dati...</h4>
                        </LoadingDiv>
                    }

                </div>
            </Body>

            {/* <Body style={{ background: 'linear-gradient(to right,#31375e,#141727)' }}> */}
            <Body>
                {statistics !== undefined ?
                    <Table statistics={statistics} />
                    :
                    <LoadingDiv>
                        <img src={loading} alt='Loading...'></img>
                        <h4>Caricamento dati...</h4>
                    </LoadingDiv>
                }
            </Body>
        </BodyWrapper>
    );
}

export default ChartPie;