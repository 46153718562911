import React from 'react';

// PACKAGES
import styled from 'styled-components';


const Points = styled.div`
    position: absolute;
    right: 20px;
    font-size: 60px;
    color:  var(--text4);
    font-weight: bold;
        >span{
            font-size: 30px;
        }
`;

const InfoWrapper = styled.div`
    position: relative;
    color:  var(--text2);
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    font-size: 14px;
`;

const InfoTitle = styled.div`
   color:  var(--text2);
   font-weight: bold;
   font-size: 14px;
`;


const ResultInfo = ({ info, type, avaible }) => {

    console.log('TOTAL', info);
    // console.log('TYPE', type);
    // console.log('AV', avaible);

    const formatTime = (timp) => {
        const totalMin = timp;

        let days = Math.floor(totalMin / (24 * 60 * 60));
        let hours = Math.floor((totalMin - (days * 1440)) / 60);
        let minutes = Math.round(totalMin % 60);

        console.log(days, hours, minutes);
        let time;

        if (days > 0) {
            time = days + ' giorni ' + hours + ' ore ' + minutes + ' minuti ';
        } else if (hours > 0) {
            time = hours + ' ore ' + minutes + ' minuti ';
        } else if (minutes > 0) {
            time = minutes + ' minuti ';
        } else {
            time = `Nessuna attività`;
        };

        // console.log(totalMin, 'day', days, 'h', hours, 'm', minutes);

        return time
    };

    const checkIfExists = () => {
        let sts = false;
        for (let i = 0; i < avaible.length; i++) {
            if (avaible[i].id === type) {
                sts = true;
            }
        }
        return sts;
    };

    return (
        checkIfExists() === true ?
            info !== undefined ?
                type === 'preliminare' ?
                    < InfoWrapper >
                        < InfoTitle>Andamento pr. Preliminare</ InfoTitle>
                        <div>Lezioni totali: {info.totalLessons}</div>
                        <div>Videolezioni completate: {info.lessonsCompleted}</div>
                        <div>Questionari totali: {info.totalQuest}</div>
                        <div>Questionari completati: {info.questDone}</div>
                        <div>Tempo in piattaforma: {formatTime(info.time)}</div>
                        {/* <div>Lezioni totali: {info.totalLessons}</div>
                     <div>Lezioni completate: {info.lessonsCompleted}</div>
                     <div>Tempo in piattaforma: {formatTime(info.time)}</div> */}

                        <Points>
                            {/* {res.score}100 <span>pt.</span> */}
                        </Points>
                    </InfoWrapper> :
                    type === 'tpa' ?
                        < InfoWrapper >
                            < InfoTitle>Andamento pr. TPA</ InfoTitle>
                            {/* <div>Lezioni totali: {info.totalLessons}</div> */}
                            {/* <div>Lezioni completate: {info.lessonsCompleted}</div> */}
                            <div>Tipologie di test disponibili: {info.totalTypologies}</div>
                            <div>Tipologie di test svolte: {info.typologiesDone}</div>
                            <div>Tempo in piattaforma: {formatTime(info.typologiesDone * 30)}</div>

                            <Points>
                                {/* {res.score}100 <span>pt.</span> */}
                            </Points>
                        </InfoWrapper> :
                        type === 'orale' ?
                            < InfoWrapper >
                                < InfoTitle>Andamento pr. Orale</ InfoTitle>
                                <div>Lezioni totali: {info.totalLessons}</div>
                                <div>Videolezioni completate: {info.lessonsCompleted}</div>
                                <div>Questionari totali: {info.totalQuest}</div>
                                <div>Questionari completati: {info.questDone}</div>
                                <div>Tempo in piattaforma: {formatTime(info.time)}</div>

                                <Points>
                                    {/* {res.score}100 <span>pt.</span> */}
                                </Points>
                            </InfoWrapper> :
                            type === 'fisiche' ?
                                < InfoWrapper >
                                    < InfoTitle>Andamento pr. Orale</ InfoTitle>
                                    <div>Lezioni totali: {info.totalLessons}</div>
                                    <div>Lezioni completate: {info.lessonsCompleted}</div>
                                    <div>Test disponibili: {info.testNumber}</div>
                                    <div>Test svolti: {info.testDone}</div>
                                    <div>Tempo in piattaforma: {formatTime()}</div>

                                    <Points>
                                        {/* {res.score}100 <span>pt.</span> */}
                                    </Points>
                                </InfoWrapper> :
                                < InfoWrapper >
                                    < InfoTitle>Andamento pr. Scritta/Tema</ InfoTitle>
                                    <div>Lezioni totali: {info.totalLessons}</div>
                                    <div>Lezioni completate: {info.lessonsCompleted}</div>
                                    <div>Test disponibili: {info.testNumber}</div>
                                    <div>Test svolti: {info.testDone}</div>
                                    <div>Tempo in piattaforma: {formatTime()}</div>

                                    <Points>
                                        {/* {res.score}100 <span>pt.</span> */}
                                    </Points>
                                </InfoWrapper>

                :
                <InfoWrapper>
                    < InfoTitle>Andamento non disponibile per questa prova</ InfoTitle>
                </InfoWrapper>
            :
            <InfoWrapper>
                < InfoTitle>Andamento non disponibile per questa prova</ InfoTitle>
            </InfoWrapper>
    );
};

export default ResultInfo;

// {
//     info !== undefined ?
//         type === 'preliminare' ?
//             < InfoWrapper >
//                 < InfoTitle>Andamento pr. Preliminare</ InfoTitle>
//                 <div>Lezioni totali: {info.totalLessons}</div>
//                 <div>Videlezioni completate: {info.lessonsCompleted}</div>
//                 <div>Questionari totali: {info.totalQuest}</div>
//                 <div>Questionari completati: {info.questDone}</div>
//                 <div>Tempo in piattaforma: {formatTime(info.time)}</div>
//                 {/* <div>Lezioni totali: {info.totalLessons}</div>
//          <div>Lezioni completate: {info.lessonsCompleted}</div>
//          <div>Tempo in piattaforma: {formatTime(info.time)}</div> */}

//                 <Points>
//                     {/* {res.score}100 <span>pt.</span> */}
//                 </Points>
//             </InfoWrapper> :
//             type === 'tpa' ?
//                 < InfoWrapper >
//                     < InfoTitle>Andamento pr. TPA</ InfoTitle>
//                     {/* <div>Lezioni totali: {info.totalLessons}</div> */}
//                     {/* <div>Lezioni completate: {info.lessonsCompleted}</div> */}
//                     <div>Tipologie di test disponibili: {info.totalTypologies}</div>
//                     <div>Tipologie di test svolte: {info.typologiesDone}</div>
//                     <div>Tempo in piattaforma: {formatTime(info.typologiesDone * 30)}</div>

//                     <Points>
//                         {/* {res.score}100 <span>pt.</span> */}
//                     </Points>
//                 </InfoWrapper> :
//                 type === 'orale' ?
//                     < InfoWrapper >
//                         < InfoTitle>Andamento pr. Orale</ InfoTitle>
//                         <div>Lezioni totali: {info.totalLessons}</div>
//                         <div>Videlezioni completate: {info.lessonsCompleted}</div>
//                         <div>Questionari totali: {info.totalQuest}</div>
//                         <div>Questionari completati: {info.questDone}</div>
//                         <div>Tempo in piattaforma: {formatTime(info.time)}</div>

//                         <Points>
//                             {/* {res.score}100 <span>pt.</span> */}
//                         </Points>
//                     </InfoWrapper> :
//                     type === 'fisiche' ?
//                         < InfoWrapper >
//                             < InfoTitle>Andamento pr. Orale</ InfoTitle>
//                             <div>Lezioni totali: {info.totalLessons}</div>
//                             <div>Lezioni completate: {info.lessonsCompleted}</div>
//                             <div>Test disponibili: {info.testNumber}</div>
//                             <div>Test svolti: {info.testDone}</div>
//                             <div>Tempo in piattaforma: {formatTime()}</div>

//                             <Points>
//                                 {/* {res.score}100 <span>pt.</span> */}
//                             </Points>
//                         </InfoWrapper> :
//                         < InfoWrapper >
//                             < InfoTitle>Andamento pr. Scritta/Tema</ InfoTitle>
//                             <div>Lezioni totali: {info.totalLessons}</div>
//                             <div>Lezioni completate: {info.lessonsCompleted}</div>
//                             <div>Test disponibili: {info.testNumber}</div>
//                             <div>Test svolti: {info.testDone}</div>
//                             <div>Tempo in piattaforma: {formatTime()}</div>

//                             <Points>
//                                 {/* {res.score}100 <span>pt.</span> */}
//                             </Points>
//                         </InfoWrapper>

//         :
//         <InfoWrapper>
//             < InfoTitle>Andamento non disponibile per questa prova</ InfoTitle>
//         </InfoWrapper>
// }
