import { useState } from 'react';
// import { useHistory } from "react-router-dom";

// ASSETS
// import pic from '../../../../Assets/Capture.PNG';
// import canvasImage from '../../../../Assets/birds.png'

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
//import AllQuestionsSideBar from '../../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../TestPage/TestVerification/NavBarTestVerification';
import TpaTestVerificationPage from './TpaTestVerificationPage';

// REDUX
// import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
    GoBack,
} from '../../PagesShareStyle/PagesShareStyle';


const TpaTestVerification = ({ details }) => {
    const [rest, setRest] = useState(localStorage.setItem('rest', 0));
    // console.log('REST', tests.length,localStorage.getItem('rest')*1);

    const [correct, setCorrect] = useState(0);
    const [incorrect, setIncorrect] = useState(0);

    // console.log("TpaTestVerification", details);

    return (
        <>
            <NavBar
                correct={correct}
                incorrect={incorrect}
            />

            <TpaTestVerificationPage
                // tests={tests}
                tests={details.elem}
                setRest={setRest}

                setCorrect={setCorrect}
                setIncorrect={setIncorrect}
                allDetails= {details}
            />

            {/* <SimpleModal
            title='Title' 
            body='Body body body' 
            // handleSubmit
            setOpenModal 
            // titleTemp
            // setTitleTemp,
            flag
            /> */}

            {/* <FooterBar /> */}
        </>
    );
}

export default TpaTestVerification;


