import React, { useEffect } from 'react';

// STYLE
import './globalCss.scss';

// PACKEGES
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// ROUTES
import Routes from './Router/Index';

// COMPONENTS
import Loading from './Dashboard/GeneralComponents/Loading/Loading';
import NotificationHandler from './Dashboard/GeneralComponents/NotificationHandler/NotificationHandler';
// import AddModal from './Dashboard/Pages/Admin/AdminComponents/AddModal';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from './Store/Actions/authActions';

const MainWrapper = styled.div`
    width: 100%;
    margin: auto;
`;


const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ auth }) => auth.isLoading);
  // const isAuthentificated = useSelector(({ auth }) => auth.isAuthentificated);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    isLoading ?
      <Loading />

      :

      <MainWrapper>
        <NotificationHandler />
        <Routes />
      </MainWrapper>
  );
}

export default withRouter(App);
