import React, { useState } from 'react';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector } from 'react-redux';

const TagsWrapper = styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* height: 60px;
    min-height: 60px;
    overflow: auto;
         &::-webkit-scrollbar {
            display: none;
        }; */
`;

const Tag = styled.div`
    position: relative;
    padding: 0px 40px 0px  10px;
    margin: 5px;
    height: 30px;
    background-color:var(--background4);
    border-radius: var(--radius);
    color:  var(--text2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >div{
            color:  var(--text2);
            
            font-size: 12px;
        }
        >div:nth-child(2){
            font-weight: bold;
            margin-right: 5px;
            font-size: 14px;
        }
        >span{
            margin: 0 5px;
        }
        >i{
            position: absolute;
            right: 10px;
            font-size: 12px;
            color:  var(--text3);
            cursor: pointer;
                &:hover{
                    color:  var(--acc-color);
                }
        }
`;

const Sts = styled.div`
    color:  var(--acc-color)!important;
    font-weight: bold;
`;


const WrongTags = ({ arr, setArr }) => {
    const profile = useSelector((state) => state.auth.user);

    // console.log('TAGS', arr)

    const deleteTag = (i) => {
        const value = [...arr];
        value[i] = {};
        setArr(value);
    };

    return (
        <TagsWrapper>
            {arr.map((tag, i) =>
                Object.keys(tag).length > 0 &&
                <Tag
                    key={i}
                >
                    <i className="fa-solid fa-xmark" onClick={() => deleteTag(i)}></i>
                    <div>{tag.nr}</div>
                    <div>{tag.materia}</div>
                </Tag>
            )}
        </TagsWrapper>
    );
};

export default WrongTags;