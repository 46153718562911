import React from 'react';

// PACKAGES
import styled from 'styled-components';


const FooterWrapper = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    margin-left: 250px;
    width: calc(100% - 250px);
    height: 80px;
    background-color: var(--background4);
    padding: 5px;
    /* border-radius: var(--radius); */
    display: flex;
    align-items: center;
    justify-content: center;
        @media (max-width: 1100px) {
            margin-left: 0px;
            width: 100%;
        };

`;

const Elem = styled.div`
   color:  var(--text2);
   font-size: 16px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
   border-left: 1px solid var(--text4);
   border-right: 1px solid var(--text4);
        >span{
            font-size: 30px;
        }
`;

const Btn = styled.button`
    height:50px;
    padding: 0 20px;
    min-width: 100px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-weight: bold;
    border: none;
    color:${props => !props.disabled ? 'var(--text5)' : 'var(--text5)'};
    background: ${props => !props.disabled ? 'var(--acc-color)' : 'var(--background1)'};
    border-radius: var(--radius);
    cursor: pointer;
    /* transition: var(--transition) ease; */
    outline:none;
    margin: 0 10px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        &:hover{
            opacity: .9;
        }
        &:active{
                transform: translateY(2px);
                box-shadow: none;
        } 
`;

const MoveBtn = styled.button`
    height: 50px;
    padding: 0 20px;
    min-width: 100px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-weight: bold;
    border: none;
    /* color:var(--text2); */
    background: ${props => props.disabled ? 'var(--accentBackground)' : 'var(--background4)'};
    color: ${props => props.disabled ? 'var(--text5)' : 'var(--text2)'};
    border-radius: var(--radius);
    cursor: pointer;
    /* transition: var(--transition) ease; */
    outline:none;
    margin: 0 10px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >i{
            margin: 0 10px;
        }
        &:hover{
            opacity: .9;
        }
        &:active{
                transform: translateY(2px);
                box-shadow: none;
        } 
        @media (max-width: 1100px) {
            min-width: 0px;
            >span{
                display:none;
            }
        };
`;


const FooterBarForDrawing = ({ id, setId, tests, submitTest, obj, temaType, rest }) => {

    // console.log(tests);
    // console.log(obj);

    const verifyCompSts = () => {
        let verif = 0;
        if (temaType === 'registri-linguistici' || temaType === 'avvio-alla-scrittura') {
            if (temaType === 'registri-linguistici') {
                for (let i = 0; i < obj.length; i++) {
                    if (Object.keys(obj[i]).length > 0) {
                        verif = verif + 1
                    }
                };
            }
            if (temaType === 'avvio-alla-scrittura') {
                for (let i = 0; i < obj.length; i++) {
                    if (obj[i].check === true) {
                        verif = verif + 1
                    }
                };
            }
        } else {
            if (obj.length > 0) {
                verif = verif + 1
            }
        }
        // console.log(verif);
        return verif;
    };

    return (
        <FooterWrapper>
            {(temaType === 'registri-linguistici' || temaType === 'avvio-alla-scrittura') ?
                (id === 0) ?
                    <MoveBtn disabled>
                        <i className="fa-solid fa-arrow-left"></i>
                        <span>Precedente</span>
                    </MoveBtn>
                    :
                    <MoveBtn
                        onClick={() => (id >= 1) && setId(id - 1)}
                    >
                        <i className="fa-solid fa-arrow-left"></i>
                        <span>Precedente</span>
                    </MoveBtn>
                :
                null
            }

            {/* {verifyCompSts() !== tests.length ? */}
            {verifyCompSts() === 0 ?
                <Btn disabled >
                    Completa il test
                </Btn>
                :
                <Btn onClick={submitTest}>
                    Completa il test
                </Btn>
            }

            {(temaType === 'registri-linguistici' || temaType === 'avvio-alla-scrittura') ?
                ((id === tests.length - 1) || rest === tests.length) ?
                    <MoveBtn disabled>
                        <span>Successiva </span>
                        <i className="fa-solid fa-arrow-right"></i>
                    </MoveBtn>
                    :
                    <MoveBtn
                        onClick={() => (id < tests.length - 1) && setId(id + 1)}
                    >
                        <span>Successiva </span>
                        <i className="fa-solid fa-arrow-right"></i>
                    </MoveBtn>
                :
                null
            }
        </FooterWrapper>
    );
};

export default FooterBarForDrawing;