import React, { useState, useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import GoBackTitle from '../../GeneralComponents/GoBackButton/GoBackButton';
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './TestDetailsTable';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getActvity } from '../../../Store/Actions/usersActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

const tableHead = [
    'Tipi di test',
    'Test corretti',
    'Azioni',
];

// let types = [
//     {
//         idUser: 1,
//         type: 'MMPI',
//         points: '100',
//         status: 'Completo'
//     },
//     {
//         idUser: 2,
//         type: 'Test grafici',
//         points: '22',
//         status: 'In attesa'
//     },
//     {
//         idUser: 3,
//         type: 'NEO',
//         points: '90',
//         status: 'In corso'
//     },
//     {
//         idUser: 4,
//         type: 'WARTEGG',
//         points: '100',
//         status: 'Completo'
//     },
// ];

const TestDetails = ({ details }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    //console.log("PROFILE", profile);
    const teacherActivity = useSelector(({ users }) => users.teacherActivity);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [types, setTypes] = useState([]);
    const [type, setType] = useState([]);
    const [activity, setActivity] = useState([]);

    useEffect(() => {
        dispatch(getActvity(details.elem.idUsers));
    }, [dispatch]);

    //console.log('TEST DETAILS', teacherActivity);
    useEffect(() => {
        if (teacherActivity !== undefined && profile !== undefined) {
            let typ = [];
            let arr = [];
            let centerStudents = teacherActivity.filter(el => el.idStudent_User.center.toLowerCase() === profile.center.toLowerCase());
            //console.log("ASTA", centerStudents)
            // for (let i = 0; i < teacherActivity.length; i++) {
            //     typ.push(teacherActivity[i].test_type)
            // };
            // let uniqueTypes = [...new Set(typ)];
            // // console.log(uniqueTypes)

            // for (let j = 0; j < uniqueTypes.length; j++) {
            //     const find = teacherActivity.filter((act) => uniqueTypes[j] === act.test_type);
            //     arr.push({
            //         type: uniqueTypes[j],
            //         total: find.length
            //     })
            // };
            for (let i = 0; i < centerStudents.length; i++) {
                typ.push(centerStudents[i].test_type)
            };
            //console.log(typ)
            let uniqueTypes = [...new Set(typ)];
            // console.log(uniqueTypes)

            for (let j = 0; j < uniqueTypes.length; j++) {
                const find = centerStudents.filter((act) => uniqueTypes[j] === act.test_type);
                arr.push({
                    type: uniqueTypes[j],
                    total: find.length
                })
            };

            //console.log('arr', arr);
            setTypes(arr);
        }
    }, [teacherActivity]);

    //console.log('TEST DETAILS', teacherActivity);

    //console.log('TYPE', types)


    return (
        <WrapperRightSide>

            <GoBackTitle
                // title='Tipi'
                title={details.elem.user_name + ' ' + details.elem.user_surname}
            />

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    type={types}
                    flag={'type'}
                    setType={setType}
                />

            </ActionsWrapperSpace>

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <Btn
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/courses/newCourse'>
                        <i className="fas fa-plus"></i>Utenti
                    </Btn>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 300px)' }}>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono risultati da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono risultati da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            teacherActivity={teacherActivity}
                                            key={i}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={type}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default TestDetails;