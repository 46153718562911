import React, { useEffect } from 'react';

// REDUX
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// PACKAGES
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTS
import Msg from './Msg';

const StyledToastContainer = styled(ToastContainer).attrs({
})`

    .Toastify__toast-container, 
    .Toastify__toast-container--top-right, 
    .toast-container {
        top: 14em!important;
        left: 1em!important;
        padding:0!important;
        min-width: 400px!important;
    }

    .Toastify__toast--default,
    .Toastify__toast--error,
    .Toastify__toast--warning,
    .Toastify__toast--success {
        /* background: var(--acc-color); */
        backdrop-filter: blur(10px);
    }
    .Toastify__toast-body {
        color: var(--text2);
        letter-spacing: 1px;
        font-size:16px;
    }
    .Toastify__close-button > svg {
        fill: var(--text3);
    }
    .Toastify__progress-bar--default {
        /* background: linear-gradient(to left,#E100FF,var(--info)); */
        background:var(--info);
    }
    .Toastify__progress-bar--error {
        /* background: linear-gradient(to left,#E100FF,var(--err)); */
        background:var(--err);
    }
    .Toastify__progress-bar--warning {
        /* background: linear-gradient(to left,#E100FF,var(--warning)); */
        background:var(--warning);
    }
    .Toastify__progress-bar--success {
        /* background: linear-gradient(to left,#E100FF,var(--success)); */
        background:var(--success);
    }
`;


const Notifications = () => {
    const notifAuth = useSelector((state) => state.auth.handlerNotifAuth);
    // const notifSch = useSelector((state) => state.schools.handlerNotifSch);
    // const notifRef = useSelector((state) => state.referents.handlerNotifRef);
    // const notifWork = useSelector((state) => state.workingProgress.handlerNotifWork);
    // const notifOri = useSelector((state) => state.orienting.handlerNotifOri);
    // const notifUpload = useSelector((state) => state.docs.handlerNotifUpload)

    // console.log('NOTIF',notifUpload);

    useEffect(() => {
        if (notifAuth !== undefined) {
            // console.log('NOTIF HANDLER AUTH', notif)
            createNotification(notifAuth.flag, notifAuth.msg);
        }
        // if (notifSch !== undefined) {
        //     createNotification(notifSch.flag, notifSch.msg);
        // };
        // if (notifRef !== undefined) {
        //     createNotification(notifRef.flag, notifRef.msg);
        // };
        // if (notifWork !== undefined) {
        //     createNotification(notifWork.flag, notifWork.msg);
        // };
        // if (notifOri !== undefined) {
        //     createNotification(notifOri.flag, notifOri.msg);
        // };
        // if (notifUpload !== undefined) {
        //     createNotification(notifUpload.flag, notifUpload.msg);
        // };

    },
        [
            notifAuth,
            // notifSch,
            // notifRef,
            // notifWork,
            // notifOri,
            // notifUpload
        ]);

    const createNotification = (flag, msg) => {
        switch (flag) {
            case 'info':
                toast(
                    <Msg
                        // icon={<i className="fas fa-info"></i>}
                        msg={msg}
                        color='var(--info)'
                    />
                );
                break;
            case 'success':
                toast.success(
                    <Msg
                        // icon={<i className="fas fa-check"></i>}
                        msg={msg}
                        color='var(--success)'
                    />
                );
                break;
            case 'warning':
                toast.warn(
                    <Msg
                        // icon={<i className="fas fa-bolt"></i>}
                        msg={msg}
                        color='var(--warning)'
                    />
                );
                break;
            case 'error':
                toast.error(
                    <Msg
                        // icon={<i className="fas fa-exclamation"></i>}
                        msg={msg}
                        color='var(--err)'
                    />
                );
                break;

            default:
        };
    };

    return (
        <StyledToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
        />
    );
}

export default Notifications;
