import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import EserciziCard from '../../GeneralComponents/Cards/EserciziCardNew';
import TpaCard from '../../GeneralComponents/Cards/TpaCardNew';


// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringTestsDone, bringSimulationsDone, getAllWrongQuezzes } from '../../../Store/Actions/testActions';

import { getAllTpaTestDone } from '../../../Store/Actions/tpaActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    TestWrapper,
    GoBack,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const CardsWrapper = styled.div`
    width:100%;
    /* height: calc(100vh - 160px); */
    /* margin: 30px 0 0; */
    padding: 20px 0;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(10, auto); */
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: flex-start;
        /* &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 1350px) {
            grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 850px) {
            grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 600px) {
            grid-template-columns: repeat(1,auto);
            grid-template-columns: 1fr;
        } */
`;

const SubTitle = styled.div`
   color:  var(--text5);
   font-size: 22px;
   text-align: center;
   background: linear-gradient(90deg,rgba(240,242,245,1) 0%,rgba(119,123,141,0.9500175070028011) 30%,rgba(108,113,132,1) 50%,rgba(96,101,122,0.8855917366946778) 70%,rgba(240,242,245,1) 100%);
   margin-bottom: 10px;
        >i{
            margin-left: 15px;
            font-size: 15px;
            cursor: pointer;
        }
`;


const TpaPreMainPage = ({ details }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(({ auth }) => auth.user);
    const tpaDone = useSelector(({ tpa }) => tpa.allTpaDone)
    const [openSvolti, setOpenSvolti] = useState(false);
    const [openSvolgere, setOpenSvolgere] = useState(false)

    // const svolgere = [
    //     'Quiz monomateria', 'Quiz misti', 'Simulazione',
    // ];
    const svolgere = [
        'MMPI', 'TEST GRAFICI', 'WARTEGG', 'FRASI DA COMPLETARE', 'BIG 5', 'NEO PI3', 'OPQ 32', 'PFS', 'TPC', 'BHS',
        'BIOGRAFICO', 'EQI', 'SIGMA03', 'TOM', 'SCID2', 'DIFESA TEST', 'CISS', 'TEMPS-A', 'MILLON', 'QE', 'TALEIA'
    ];
    // const svolti = [
    //     'Quiz monomateria', 'Quiz misti', 'Simulazione'
    // ];

    // const errati = [
    //     'Quiz errati'
    // ];

    useEffect(() => {
        dispatch(getAllTpaTestDone(auth.idUser))
        //dispatch(bringTestsDone(details.course));
        //dispatch(bringSimulationsDone(details.course));
        //dispatch(getAllWrongQuezzes(details.course));
    }, [dispatch]);

    // console.log('ExercisesPage',details);

    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                {/* <Title>Esercizi</Title> */}

                <Title>{details.title} - {details.course.course_code}</Title>
                {/* <SubTitle>{desc}</SubTitle> */}
            </TitleWrapper>

            <div style={{ marginTop: '20px' }}>
                <SubTitle
                    onClick={() => setOpenSvolgere(!openSvolgere)}
                >
                    Test da Svolgere
                    {openSvolgere === false ?
                            <i className="fa-solid fa-chevron-down"></i>
                            :
                            <i className="fa-solid fa-chevron-up"></i>
                    }
                </SubTitle>
                {
                    !openSvolgere &&

                    <CardsWrapper>
                        {svolgere !== undefined &&
                            svolgere.map((type, i) =>
                                <TpaCard
                                    key={i}
                                    type={type}
                                    course={details.course}
                                />
                            )}
                    </CardsWrapper>
                }


                <SubTitle

                    onClick={() => setOpenSvolti(!openSvolti)}
                >
                    Test Svolti
                    {
                        openSvolti === false ?
                            <i className="fa-solid fa-chevron-down"></i>
                            :
                            <i className="fa-solid fa-chevron-up"></i>
                    }
                </SubTitle>

                {
                    !openSvolti &&
                    <CardsWrapper>
                        {tpaDone !== undefined &&
                            tpaDone.map((type, i) =>
                                <TpaCard
                                    key={i}
                                    type={type.type.toUpperCase().replace('-', ' ')}
                                    course={details.course}
                                    done={type.done}
                                    flag='svolti'
                                />

                            )}
                    </CardsWrapper>
                }


            </div>

        </WrapperRightSide>
    );
}

export default TpaPreMainPage;