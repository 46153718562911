import React, { useState, useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';
// import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import _ from 'lodash';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../Store/Actions/usersActions';

// import { getServices } from '../../../../Store/Actions/servicesActions';
// import { setSize } from '../../../Store/Actions/otherActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    BtnLink,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './CentersTable'

// const options = [
//   { value: '5', label: '5' },
//   { value: '10', label: '10' },
//   { value: '15', label: '15' },
//   { value: '25', label: '25' },
//   { value: '50', label: '50' },
//   { value: '100', label: '100' },
// ];

const tableHead = [
    'Ruolo',
    'Nome del centro',
    'Descrizione',
    'E-mail',
    'Città',
    'Azioni',
];


const Centers = () => {
    const dispatch = useDispatch();
    const users = useSelector(({ users }) => users.allUsers);
    const profile = useSelector(({ auth }) => auth.user);
    // console.log("PRODILEE", profile);
    // const services = useSelector(({ services }) => services.allServices);
    // const pageSize = useSelector(({ other }) => other.setSize);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [usr, setUsr] = useState([]);
    const [centers, setCenters] = useState([])


    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    useEffect(() => {
        if (users !== undefined) {
            if (profile.role === 'ADMIN') {
                setCenters(users.filter(user => user.user_role === 'CENTRO'))
            }


        }
    }, [users])
    //console.log("TEACHERS", teachers)
    // useEffect(() => {
    //   // dispatch(getServices());
    // }, [dispatch]);

    return (
        <WrapperRightSide>
            <Title>SEDI</Title>

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    usr={centers}
                    flag={'users'}
                    setUsr={setUsr}
                />

            </ActionsWrapperSpace>

            <ActionsWrapper>
                {/* <BtnWrapper>
          <BtnLink
            style={{ margin: '0' }}
            theme={'users'}
            to='/dashboard/teachers/newTeacher'>
            <i className="fas fa-plus"></i>Professore
          </BtnLink>
        </BtnWrapper> */}

                {/* <Filter
          invs={invo}
          flag={'invoices'}
          setInvoice={setInvoice}
        /> */}
            </ActionsWrapper>

            <TableWrapper>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono sedi da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono sedi da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={usr}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default Centers;