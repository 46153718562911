import React from 'react';

// PACKAGES
import styled from 'styled-components';

// STYLE
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    QueContainer,
    ResContainer,
    Section,
} from '../Style';


const VerificationRevisione = ({ details }) => {
    // console.log('TEMA REVISIONE VIEW', details);

    return (
        <Wrapper>
            <div>
                <QueContainer>
                    Risposta:
                </QueContainer>

                <LabelCss htmlFor="asw1">Introduzione</LabelCss>
                <ResContainer>
                    {JSON.parse(details.elem.initialTest[0].introduction).map((int, i) =>
                        <div key={i}>
                            <span>{int.intro}</span>
                            <span>-</span>
                            <span>{int.subIntro}</span>
                        </div>
                    )}
                </ResContainer>

                <LabelCss htmlFor="asw2">Corpo del testo (parole chiave)</LabelCss>
                <ResContainer>
                    {JSON.parse(details.elem.initialTest[0].body).map((md, i) =>
                        <div key={i}>
                            <span>{md.midd}</span>
                            <span>-</span>
                            <span>{md.subMidd}</span>
                        </div>
                    )}
                </ResContainer>

                <LabelCss htmlFor="asw2">Conclusione</LabelCss>
                <ResContainer>
                    {JSON.parse(details.elem.initialTest[0].end).map((con, i) =>
                        <div key={i}>
                            <span>{con.conc}</span>
                            <span>-</span>
                            <span>{con.subConc}</span>
                        </div>
                    )}
                </ResContainer>

                <LabelCss htmlFor="asw2">Argomenti</LabelCss>
                <ResContainer style={{ minHeight: '150px', background: 'var(--background4)' }}>
                    <div style={{ marginLeft: '10px' }}>{details.elem.text}</div>
                </ResContainer>
            </div>
        </Wrapper>
    );
}

export default VerificationRevisione;