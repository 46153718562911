import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Header from './TpaLarningPageHeader';
import Typologies from './Typologies';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { getAllQuestionnaires } from '../../../Store/Actions/subjectsActions';
// import { getUserGeneralStatus } from '../../../Store/Actions/usersActions';
// import { getAllSubjects } from '../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
    GoBack
} from '../../PagesShareStyle/PagesShareStyle';

const Info = styled.div`
    /* margin-top: 100px; */
    width: 100%;
    font-size: var(--fontL);
    /* font-weight: bold; */
    color: var(--text2);
    margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
    position: absolute;
    left: 28px;
    top: 110px;
    z-index: 1;
        @media (max-width: 1099px) {
            left: 30px;
        };
`;

// const GoBack = styled.div`
//   color: var(--text3);
//   cursor: pointer;
//   font-size: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 10px;
//   background: var(--background4);
//   min-width: 100px;
//   border-radius: var(--radius);
//   box-shadow: var(--shadow);
//   padding: 10px;
//         &:hover{
//             color: var(--text2)
//         }
//         >i{
//             margin-right: 10px;
//         }
// `;


const TpaLearningPage = ({ details }) => {
    //console.log("DETAILS", details)

    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);

    // const quests = useSelector(({ subjects }) => subjects.allQuestionnaires);
    const materie = useSelector(({ subjects }) => subjects.allSubjects);

    const [lessonsTotal, setLessonsTotal] = useState(0);
    const [lessonsCompletedTotal, setLessonsCompletedTotal] = useState(0);
    const [total, setTotal] = useState(0);

    // useEffect(() => {
    //     const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
    //     // dispatch(getAllQuestionnaires(details.less.Lessons_idLesson, idUser));
    //     dispatch(getUserGeneralStatus(
    //         details.course.idCourse,
    //         idUser
    //     ));
    // }, [dispatch]);

    useEffect(() => {
        if (profile !== undefined) {
            const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
        }
        // const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
        // dispatch(getAllSubjects(details.course.idCourse, idUser));
        // dispatch(getAllTpaLessons());
    }, [dispatch]);

    // console.log("LEARNING PAGE DETAILS", materie);

    // useEffect(() => {
    //     if (materie !== undefined) {
    //         let subjectsNr = [];

    //         // let subjectsNr = [
    //         //     {
    //         //         nrLessons: 5,
    //         //         questCompleted: 1,
    //         //         totalQuest: 1,
    //         //         videoCompleted: 5,
    //         //     },
    //         //     {
    //         //         nrLessons: 2,
    //         //         questCompleted: 1,
    //         //         totalQuest: 1,
    //         //         videoCompleted: 2,
    //         //     },
    //         //     {
    //         //         nrLessons: 1,
    //         //         questCompleted: 0,
    //         //         totalQuest: 0,
    //         //         videoCompleted: 1,
    //         //     },
    //         // ];

    //         for (let i = 0; i < materie.length; i++) {
    //             // subjectsNr.push(materie[i].lessons.length);
    //             subjectsNr.push({
    //                 nrLessons: materie[i].lessons.length,
    //                 videoCompleted: 0,
    //                 questCompleted: 0,
    //                 totalQuest: 0,
    //             })
    //             if (materie[i].lessons.length > 0) {
    //                 let nrCompelete = 0;
    //                 let nrQuestCompleted = 0;
    //                 let totalQue = 0;
    //                 for (let j = 0; j < materie[i].lessons.length; j++) {
    //                     const nr = materie[i].lessons[j].Lessons_idLesson_Lesson.LessonCompletes.length;
    //                     if (materie[i].lessons[j].ondemand !== null) {
    //                         nrCompelete += nr;
    //                     } else {
    //                         nrCompelete += 1;
    //                     };

    //                     const questDone = materie[i].lessons[j].Lessons_idLesson_Lesson.Questionnaires;
    //                     // totalQuest = questDone.length;
    //                     const questNr = questDone.length;
    //                     // console.log('questNr',questNr);
    //                     totalQue += questNr;
    //                     for (let k = 0; k < questDone.length; k++) {
    //                         const nr = questDone[k].QuestionnairesDones.length;
    //                         nrQuestCompleted += nr;
    //                     };
    //                     // lessonsVideoCompleted.push(materie[i].lessons[j].Lessons_idLesson_Lesson.LessonCompletes.length);
    //                 };
    //                 const value = [...subjectsNr]
    //                 value[i].videoCompleted = nrCompelete;
    //                 value[i].questCompleted = nrQuestCompleted;
    //                 value[i].totalQuest = totalQue;
    //             };
    //         };

    //         // console.log('NR', subjectsNr);
    //         let totalLessons = subjectsNr.length;
    //         let totalLessonsCompleted = 0;
    //         let lessonsPercent = 0;

    //         let totalQuest = 0;
    //         let totalQuestCompleted = 0;

    //         let questsPercent = 0;

    //         for (let i = 0; i < subjectsNr.length; i++) {
    //             // totalLessons += subjectsNr[i].nrLessons;
    //             // if (subjectsNr[i].questCompleted === subjectsNr[i].totalQuest && subjectsNr[i].totalQuest > 0) {
    //             if (subjectsNr[i].questCompleted === subjectsNr[i].totalQuest) {
    //                     totalLessonsCompleted += 1;
    //             };

    //             let videoPercent = 0;
    //             if (subjectsNr[i].videoCompleted > 0) {
    //                 videoPercent += (0.5 * subjectsNr[i].videoCompleted) / subjectsNr[i].nrLessons;
    //             }
    //             lessonsPercent += videoPercent / subjectsNr.length;

    //             totalQuest += subjectsNr[i].totalQuest;
    //             totalQuestCompleted += subjectsNr[i].questCompleted;

    //             // questsPercent += (totalQuest !== 0 && totalQuestCompleted !== 0) ?
    //             //     ((totalQuestCompleted / totalQuest) * 0.5) / subjectsNr.length
    //             //     :
    //             //     0;

    //             questsPercent += (totalQuest !== totalQuestCompleted) ?
    //                 ((totalQuestCompleted / totalQuest) * 0.5) / subjectsNr.length
    //                 :
    //                 0;
    //         }
    //         // console.log('totalLessons', totalLessons);
    //         // console.log('totalLessonsCompleted', totalLessonsCompleted);
    //         // console.log('lessonsPercent', lessonsPercent);

    //         // console.log('questPercent', questsPercent);

    //         const totalPercent = (lessonsPercent + questsPercent).toFixed(2) * 100;

    //         // console.log('totalPercent', totalPercent);

    //         setLessonsTotal(totalLessons);
    //         setLessonsCompletedTotal(totalLessonsCompleted);
    //         setTotal(totalPercent);
    //     };
    // }, [materie]);

    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
            </TitleWrapper>

            <Header
                course={details.course}
                studDetails={details.studDetails}
                // lessonsTotal={lessonsTotal}
                // lessonsCompletedTotal={lessonsCompletedTotal}
                total={total}
            />

            <Info
                dangerouslySetInnerHTML={{ __html: details.course.desc }}
            />

            <Typologies
                idCourse={details.course.idCourse}
                idCheckUser={details.idCheckUser}
                studDetails={details.studDetails}
                course={details.course}
            />

        </WrapperRightSide>
    );
}

export default TpaLearningPage;