import React, { useState, useEffect } from 'react';

// REDUX
import { useSelector } from 'react-redux';

import {
    Wrapper,
    // CommentWrapper,
    // GradeWrapper,
    // LabelCss,
    QueContainer,
    ResContainer,
    QuestionsContainer,
    Box,
    // Section,
} from './Style';


const VerificationRegistri = ({ details, id, setId, done }) => {
    // console.log('TEMA REGISTRI VIEW', details);
    // const [id, setId] = useState(0);
    const [text, setText] = useState('');;

    useEffect(() => {
        const obj = JSON.parse(details.elem.answers);
        if (obj.length > 0) {
            if (Object.keys(obj[id]).length > 0) {
                setText(obj[id].asw)
            } else {
                setText('');
            }
        }
    }, [id, details]);

    const setQue = (id, sts) => {
        setId(id);
    };

    const howIsIt = (elem, que) => {
        //console.log("ELEM", elem)
        if (elem !== undefined && Object.keys(elem).length > 0) {
            let is;

            if (elem.answer_picked === que.answerOk) {
                is = 'correct';
            }

            if (elem.answer_picked !== que.answerOk) {
                is = 'incorrect';
            }

            if (elem.answer_picked === '' || que.answerOk === '') {
                is = 'empty';
            }
            return is;
        };
    };


    return (
        <Wrapper style={{ marginTop: '0px' }} >
            <div>
                <QuestionsContainer>
                    {JSON.parse(details.elem.questions).map((que, i) =>
                        <Box
                            key={i}
                            onClick={() => setQue(i, que)}
                            select={id === i && 'select'}
                            // prev={id > i && 'prev'}
                            //prev={done[i] !== undefined && Object.keys(done[i]).length > 0 && 'prev'}
                            prev={howIsIt(done[i], que)}
                        >
                            {i + 1}

                        </Box>

                    )}
                </QuestionsContainer>

                {details !== undefined &&
                    <QueContainer
                        dangerouslySetInnerHTML={{ __html: JSON.parse(details.elem.questions)[id].body }}
                    />
                }

                <ResContainer style={{ marginTop: '20px' }}>
                    {text !== '' ?
                        text
                        :
                        'Non hai scritto alcuna risposta'
                    }
                </ResContainer>
            </div>
        </Wrapper>
    );
}

export default VerificationRegistri;