import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import TracceView from './TracceView';
import ScalettaView from './ScalettaView';
import CoerenzaView from './CoerenzaView';
import RevisioneView from './RevisioneView';
import RegistriView from './RegistriView';
import AvvioView from './AvvioView';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTemaTestDone } from '../../../../../Store/Actions/temaActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    GoBack,
} from '../../../PagesShareStyle/PagesShareStyle';
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    ResContainer
} from './Style';


const ReadOnlyTemaTest = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const oneTema = useSelector(({ tema }) => tema.oneTemaDone);


    useEffect(() => {
        if (details.type === details.svolgere[0]) {
            // console.log("THISSSS", oneTema)
            //console.log("QUI", details)
            dispatch(getSelectedTemaTestDone(details.elem.idStartWritingDone, details));

            //dispatch(getSelectedTemaTestDone(details.elem.idTraceAnalysisDone, details));
        }

        if (details.type === details.svolgere[1]) {
            //console.log("QUIIIII", details)
            dispatch(getSelectedTemaTestDone(details.elem.idTraceAnalysisDone, details));
            //dispatch(getSelectedTemaTestDone(details.elem.idTextProcessing, details));
        }

        if (details.type === details.svolgere[2]) {
            dispatch(getSelectedTemaTestDone(details.elem.idTextProcessing, details))
            // dispatch(getSelectedTemaTestDone(details.elem.idConsistencyDone, details));
        }

        if (details.type === details.svolgere[3]) {
            dispatch(getSelectedTemaTestDone(details.elem.idRevision, details));
        }

        if (details.type === details.svolgere[4]) {
            dispatch(getSelectedTemaTestDone(details.elem.idConsistencyDone, details));
        }

        if (details.type === details.svolgere[5]) {
            dispatch(getSelectedTemaTestDone(details.elem.idLaguageRegistersDone, details));
        }
    }, [dispatch, details]);

    // console.log('ReadOnly DETAILS', details);
    // console.log('TEMA', oneTema);

    return (
        <WrapperRightSide>
            <TitleWrapper style={{ display: 'flex' }}>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                {/* <Title>Esercizi</Title> */}

                <Title style={{ margin: '0' }}>
                    Torna indietro
                </Title>
            </TitleWrapper>
            {
                oneTema !== undefined &&
                <GradeWrapper sts={oneTema !== undefined || oneTema.grade !== null}>
                    <span>
                        Giudizio: {oneTema !== undefined &&
                            oneTema.grade !== null ?
                            oneTema.grade !== undefined ?
                                oneTema.grade
                                :
                                oneTema.revisione.grade
                            :
                            'In attesa...'
                        }
                    </span>
                </GradeWrapper>
            }


            {oneTema !== undefined &&
                details.type === details.svolgere[0] &&
                <AvvioView
                    oneTema={oneTema}
                />
            }
            {oneTema !== undefined &&
                details.type === details.svolgere[1] &&
                <TracceView
                    oneTema={oneTema}
                />
            }

            {oneTema !== undefined &&
                details.type === details.svolgere[2] &&
                <ScalettaView
                    oneTema={oneTema}
                />
            }



            {oneTema !== undefined &&
                details.type === details.svolgere[3] &&
                <RevisioneView
                    oneTema={oneTema}
                />
            }

            {oneTema !== undefined &&
                details.type === details.svolgere[4] &&
                <CoerenzaView
                    oneTema={oneTema}
                />
            }
            {oneTema !== undefined &&
                details.type === details.svolgere[5] &&
                <RegistriView
                    oneTema={oneTema}
                />
            }



        </WrapperRightSide>
    );
}

export default ReadOnlyTemaTest;
