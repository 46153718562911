import React from 'react';

// PACKAGES
import styled from 'styled-components';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    GoBack,
} from '../../../PagesShareStyle/PagesShareStyle';
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    QueContainer,
    ResContainer
} from './Style';


const TracceView = ({ oneTema }) => {
    console.log('TracceView', oneTema);

    return (
        <Wrapper>
            <div style={{ width: '100%' }}>
                <QueContainer>
                    {oneTema !== undefined && oneTema.idTraceAnalysis_TraceAnalysis?.title}
                </QueContainer>
                <LabelCss htmlFor="asw1">tema di fondo</LabelCss>
                <ResContainer>
                    {oneTema !== undefined && oneTema.arguments}
                </ResContainer>

                <LabelCss htmlFor="asw2">Parole chiave</LabelCss>
                <ResContainer>
                    {oneTema !== undefined && oneTema.keywords}
                </ResContainer>
            </div>

            <CommentWrapper>
                {oneTema !== undefined &&
                    oneTema.comments !== null &&
                    JSON.parse(oneTema.comments).map((comment, i) =>
                        <div key={i}>
                            <span>{i + 1}.</span>
                            {comment.message}
                        </div>
                    )
                }
            </CommentWrapper>
        </Wrapper>
    );
}

export default TracceView;