import React, { useState, useEffect, useRef } from 'react';

// PACKAGES
import styled from 'styled-components';

// PACKAGES
import { SketchPicker } from 'react-color';

// REDUX
import { useSelector } from 'react-redux';

const BtnWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
        /* @media (max-width: 1100px) {
            flex-wrap: wrap;
        }; */
`;

const Btn = styled.button`
    padding: 10px 30px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content:center;
    /* color: var(--text2); */
    color: ${props => props.activ === 'true' ? 'var(--text5)' : 'var(--text2)'};
    font-weight: bold;
    font-size: 14px;
    border-radius: var(--radius);
    margin-top: 10px;
    margin-bottom:10px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    /* background: var(--background4); */
    background: ${props => props.activ === 'true' ? 'var(--acc-color)' : 'var(--background4)'};
    border: none;
    outline:none;
    box-shadow: var(--shadow);
        >i{
            margin-right: 10px;
            font-size: 20px;
        }
        &:hover{
            box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
        }
        &:active{
            box-shadow: none;
        }
        @media (max-width: 1100px) {
            padding: 10px;
            >span{
                display:none;
            }
            >i{
                font-size: 18px;
                margin-right: 0px;
            }
        };
`;

const PickerWrapper = styled.div`
    position: absolute;
    top: 60px;
    right: 50%;
    transform: translateX(50%);
    overflow: auto;
    z-index: 2;
    text-align: center;
    color: var(--text2);
    border-radius: var(--radius);
    background: var(--background4);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
`;

const ReminderWrapper = styled.div`
    position: relative;
`;

const DrawingNav = ({ tests, id, setAnswers, obj, canvas, color, setColor, erase, setErase, comment, setComment }) => {
    const openPickerRef = useRef();
    const profile = useSelector(({ auth }) => auth.user);
    const [openColorModal, setOpenColorModal] = useState(false);

    const handleColorChange = (e) => {
        setColor(e.hex);
    };

    useEffect(() => {
        let handler = (e) => {
            if (openPickerRef.current) {
                if (openPickerRef.current.contains(e.target) === false) {
                    setOpenColorModal(false)
                }
            }
        };
        document.addEventListener("mousedown", handler, true);
        return () => {
            document.removeEventListener("mousedown", handler, true);
        }
    }, [openColorModal]);


    return (
        <BtnWrapper>
            {/* <Btn onClick={() => canvasRef.current.undo()}> */}
            <Btn onClick={() => canvas.current.undo()}>
                <i className="fa-solid fa-reply"></i>
                <span>Annullare</span>
            </Btn>
            <Btn onClick={() => canvas.current.redo()}>
                <i style={{ transform: 'scaleX(-1)' }} className="fa-solid fa-reply"></i>
                <span>Fare</span>
            </Btn>
            {/* <Btn onClick={() => canvasRef.current.eraseAll()}> */}
            <Btn
                onClick={() => setErase(!erase)}
                activ={`${erase}`}
            >
                <i className="fa-solid fa-eraser"></i>
                <span>Cancella</span>
            </Btn>

            <ReminderWrapper ref={openPickerRef}>
                <Btn onClick={() => { return (setOpenColorModal(!openColorModal), setErase(false)) }}>
                    <i className="fa-solid fa-paintbrush"></i>
                    <span>Colori</span>
                </Btn>
                {openColorModal &&
                    <PickerWrapper >
                        <SketchPicker
                            color={color}
                            onChange={handleColorChange}
                        />
                    </PickerWrapper>
                }
            </ReminderWrapper>

            {/* {profile.role === 'ADMIN' &&
                < Btn onClick={() => setComment(!comment)}>
                    <i className="fa-solid fa-comment"></i>
                    <span>Add comment</span>
                </Btn>
            } */}

        </BtnWrapper >
    );
}

export default DrawingNav;