import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

// ASSETS
import pic from '../../../Assets/Capture.PNG';
import canvasImage from '../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../GeneralComponents/TestViewsBars/NavBar';
// import TestPageWithDrawing from '../TestPage/DrawingTest/TestPageWithDrawing';
import TemaSwitchTest from './TemaSwitchTest';
import FinishPage from './TemaFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
    getOneTraceExercise,
    getOneScalettaExercise,
    getAllCoerenzaExercise,
    getOneRevisioneExercise,
    getAllRegistriExercise,
    getAllAvvioExercise
} from '../../../Store/Actions/temaActions';

// STYLE
import {
    WrapperRightSide,
} from '../PagesShareStyle/PagesShareStyle';

const MainTemaTestPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const tests = useSelector(({ tema }) => tema.oneExercices);
    const [finishTheTest, setFinishTheTest] = useState(false);
    const [rest, setRest] = useState(0);
    const [theTest, setTheTest] = useState([]);

    useEffect(() => {
        if (params.type.split(' ')[0] === 'analisi-della-traccia') {
            dispatch(getOneTraceExercise(params.type.split(' ')[1]));
        }
        if (params.type.split(' ')[0] === 'elaborazione-del-testo-o-scaletta') {
            dispatch(getOneScalettaExercise(params.type.split(' ')[1]));
        }
        if (params.type.split(' ')[0] === 'coerenza-e-stesura-del-testo') {
            dispatch(getAllCoerenzaExercise());
        }
        if (params.type.split(' ')[0] === 'stesura-e-revisione-del-tema') {
            dispatch(getOneRevisioneExercise(params.type.split(' ')[1]));
        }
        if (params.type.split(' ')[0] === 'registri-linguistici') {
            dispatch(getAllRegistriExercise());
        }
        if (params.type.split(' ')[0] === 'avvio-alla-scrittura') {
            dispatch(getAllAvvioExercise(params.type.split(' ')[1]));
        }
    }, [dispatch, params]);

    useEffect(() => {
        setTheTest(tests);
    }, [tests]);

    console.log('THE TEST', theTest);
    // console.log('TEMA PARAMS', params);

    return (
        theTest !== undefined &&
        <WrapperRightSide>
            {(params.type.split(' ')[0] === 'registri-linguistici' ||
                params.type.split(' ')[0] === 'avvio-alla-scrittura') &&
                <NavBar
                    total={theTest.length}
                    flagExercises='tema'
                    rest={rest}
                />
            }

            <TemaSwitchTest
                tests={theTest}
                setRest={setRest}
                setFinishTheTest={setFinishTheTest}
                rest={rest}
                opt={''}
                course={(params.type.split(' ')[0] === 'analisi-della-traccia') ||
                    (params.type.split(' ')[0] === 'elaborazione-del-testo-o-scaletta') ||
                    (params.type.split(' ')[0] === 'stesura-e-revisione-del-tema') ||
                    (params.type.split(' ')[0] === 'avvio-alla-scrittura') ?
                    params.type.split(' ')[2]
                    :
                    params.type.split(' ')[1]}
                temaType={params.type.split(' ')[0]}
            />

            {finishTheTest &&
                <FinishPage />
            }
        </WrapperRightSide>
    );
}

export default MainTemaTestPage;