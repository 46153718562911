import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import PhysicalTabs from '../../GeneralComponents/Tabs/PhysicalTabs';

// PAGE

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';


const TitleWrapper = styled.div`
    position: relative;
    display: flex;
`;

const Title = styled.h3`
  /* width:100%; */
  color:var(--text2);
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const GoBack = styled.div`
  color: var(--text3);
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: var(--background4);
  min-width: 100px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  padding: 10px;
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
`;

const MoveBtn = styled.button`
    height: 50px;
    padding: 0 20px;
    min-width: 100px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-weight: bold;
    border: none;
    color:var(--text2);
    background: var(--background4);
    border-radius: var(--radius);
    cursor: pointer;
    /* transition: var(--transition) ease; */
    outline:none;
    margin-right: 20px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >i{
            margin: 0 10px;
        }
        &:hover{
            opacity: .9;
        }
        &:active{
                transform: translateY(2px);
                box-shadow: none;
        } 
`;


const PhysicalLessonMainPage = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    // console.log('LESSONS', details);

    return (
        <WrapperRightSide>
            <PhysicalTabs
                details={details}
            />
        </WrapperRightSide>
    );
}

export default PhysicalLessonMainPage;