import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// PACKAGES
// import Select from 'react-select';
import styled from 'styled-components';

// COMPONENTS
import DeleteModal from '../../GeneralComponents/Modals/DeleteModal';
// import ToggleSwitchButton from '../../../GeneralComponents/Button/ToggleSwitchButton';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubjects } from '../../../Store/Actions/subjectsActions';

import {
    Td,
    // ExpandTableRates,
    ExpandBtn,
    TrDiv,
    // LinkDiv,
    PopUp2,
    EditWrapper,
    Edit,
    TdHeader,
    // PopUpTd,
    // colourStyles
} from '../PagesShareStyle/PagesShareStyle';

const Sts = styled.div`
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    max-width: 90px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        (props.color === 'Incompleto' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
`;

// const type = [
//     { label: 'Ritiro in sede', value: 'Ritiro in sede' },
//     { label: 'Spedizione', value: 'Spedizione' },
// ]


const TestDetailsTable = ({ elem, i, type, user, course }) => {
    //console.log("ELEM", elem, user, course)
    const dispatch = useDispatch();
    const subs = useSelector(({ subjects }) => subjects.allSubjects);

    useEffect(() => {
        // dispatch(getAllSubjects(elem.id_course, elem.id_user))
        dispatch(getAllSubjects(course, user))
    }, [dispatch])

    //console.log("subs", subs)
    const handleStatus = () => {
        if (elem.quiz_answers === null) {
            return 'Incompleto'
        } else {
            const asw = JSON.parse(elem.quiz_answers);
            const qui = JSON.parse(elem.quiz_questions);

            let rez = 0;
            for (let i = 0; i < asw.length; i++) {
                if (Object.keys(asw[i]).length > 0) {
                    rez += 1
                }
            }
            if (rez < qui.length) {
                return 'Incompleto'
            } else {
                return 'Completo'
            }
        }
    };

    const nrAnsw = () => {
        const asw = JSON.parse(elem.quiz_answers);
        const qui = JSON.parse(elem.quiz_questions);

        let rez = 0;
        for (let i = 0; i < asw.length; i++) {
            if (Object.keys(asw[i]).length > 0) {
                rez += 1
            }
        }
        return rez;
    };

    const formatData = (data) => {
        // console.log(data);
        const extractData = data.split('T')
        let format;
        if (extractData[0] !== '') {
            const d = extractData[0].split('-');
            format = d[2] + '/' + d[1] + '/' + d[0];
        } else {
            format = ''
        }
        return format
    };

    const getSubjectName = (id) => {
        //console.log("ID", id)
        if (subs !== undefined && subs.length && id !== undefined) {
            const find = subs.find(el => el.idSubject === id);
            if (find) return find.subject_name;
            else return ''
        }
    }

    //console.log("ELEME", elem);

    return (
        <>
            {elem.subject_name &&
                <></>
            }
            < tr >
                {type !== 'Quiz errati' &&
                    <Td>
                        {formatData(elem.date)}
                    </Td>
                }
                {(type === 'Quiz monomateria' || type === 'Quiz errati') &&
                    <Td>{type === 'Quiz monomateria' ?
                        getSubjectName(JSON.parse(elem.quiz_questions)[0].Courses_has_Subjects_Subjects_idSubject)
                        :
                        elem.subject_name}
                    </Td>
                }

                <Td>
                    {type !== 'Quiz errati' ?
                        elem.quiz_answers === null ?
                            '0' :
                            nrAnsw() + '/' + JSON.parse(elem.quiz_questions).length
                        : elem.wrongQuestions.length
                    }
                </Td>

                {type !== 'Quiz errati' &&
                    <Td>
                        <Sts
                            // color={elem.status}
                            color={handleStatus()}
                        >
                            {/* {elem.status} */}
                            {handleStatus()}
                        </Sts>
                    </Td>
                }

                <Td >
                    <div style={{ display: 'flex' }}>
                        {handleStatus() === 'Incompleto' ?
                            <EditWrapper>
                                <Edit
                                    to={{
                                        pathname: '/dashboard/test-verification',
                                        state: { elem, type, flag: 'course', user, course }
                                    }}
                                >
                                    <i className="fa-solid fa-list-check"></i>
                                </Edit>
                                <PopUp2>Continua il quiz</PopUp2>
                            </EditWrapper>
                            :
                            <EditWrapper>
                                <Edit
                                    to={{
                                        pathname: '/dashboard/test-verification',
                                        state: { elem, type, flag: 'course', user, course }
                                    }}
                                >
                                    <i className="fa-solid fa-binoculars"></i>
                                </Edit>
                                <PopUp2>Visualizza il quiz</PopUp2>
                            </EditWrapper>
                        }

                        {/* <EditWrapper>
                            <Edit
                                to={{
                                    pathname: '/dashboard/users/results',
                                    state: { elem, flag: 'mat' }
                                }}
                            >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                            </Edit>
                            <PopUp2>Di più</PopUp2>
                        </EditWrapper> */}

                        {/* <DeleteModal
                            flag='course'
                            id={elem.idCourse}
                            icon={<i className="far fa-trash-alt"></i>}
                            title={'Rimuovi'}
                            body={`Stai per rimuovere ordina ${elem.name} dalla tua azienda.\nSei sicuro di voler procedere?`}
                        /> */}
                    </div>
                </Td>
            </tr >
        </>
    )
}

export default TestDetailsTable;



