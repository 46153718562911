import React, { useLayoutEffect, useState } from 'react';

// PACKAGES
import styled from 'styled-components';

// ASSETS
import user from '../../../../../Assets/avatar.png';

// COMPONENTS

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { openMessSidebar, openFriendsSidebar } from '../../../../../Store/Actions/otherActions'


const GeneralWrapper = styled.div`
    /* position: sticky; */
    /* top:0; */
    min-height: 80px;
    background: var(--background4);
    padding: 10px;
    border-bottom: 1px solid var(--border2);
    padding: 10px;
    display: flex;
    align-items: center;
    -webkit-box-shadow: 0px 10px 14px -10px rgba(0,0,0,0.22); 
    box-shadow: 0px 10px 14px -10px rgba(0,0,0,0.22);
        >img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
            cursor: pointer;
            background: var(--background2);
        }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const AvatarDisplay = styled.div`
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    color: var(--text4);
    margin-right: 10px;
    padding: 10px 0;
        >h4{
            height: 40px;
            width: 40px;
            border-radius: 40px;
            font-size: 20px;
            display:flex;
            align-items:center;
            justify-content:center;
            color: var(--text2);
            background: var(--background3);
        }
        >img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
            cursor: pointer;
            background: var(--background2);
        }
`;

const Name = styled.div`
    display: flex;
        >h4{
            color: var(--text1);
            font-weight: bold;
            cursor: default;
            margin-right: 10px;
            @media (max-width: 400px) {
                    font-size: 14px;
                };
        }
        @media (max-width: 400px) {
            font-size: 14px;
            flex-direction: column;
        };
`;

const OpenerWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
     @media (min-width: 800px) {
        display: none;
    };
    @media (max-width: 800px) {
        display: flex;
    };
`;

const Opener = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: var(--background3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    background: ${props => props.marked && '#0084ff'};
    color: ${props => props.marked && '#fff'};
    &:hover{
        background: #0084ff;
        color: #fff;
    }
`;





const ChatNavBar = ({ curentUserChat }) => {
    const dispatch = useDispatch();
    const friends = useSelector(({ other }) => other.friendsSidebar);
    const mess = useSelector(({ other }) => other.messSidebar);
    // const [typing, setTyping] = useState(false);
    // const [time, setTime] = useState();

    // const timeoutFunction = () => {
    //     setTyping(false);
    //     // socket.emit(noLongerTypingMessage);
    //     socket.emit('typing');
    // };

    // const onKeyDownNotEnter = () => {
    //     if (typing === false) {
    //         setTyping(true);
    //         socket.emit(typingMessage);
    //         setTime(setTimeout(timeoutFunction, 5000));
    //     } else {
    //         clearTimeout(time);
    //         setTime(setTimeout(timeoutFunction, 5000));
    //     }
    // };

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth > 800) {
                if (friends === true || mess === true) {
                    dispatch(openFriendsSidebar(false));
                    dispatch(openMessSidebar(false));
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [dispatch, friends, mess]);

    const getInitials = (profile) => {
        if (profile !== undefined) {
            let string;

            string = curentUserChat.user_name + ' ' + curentUserChat.user_surname;

            const names = string.split(' ');
            let initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials
        }
    };

    const messSidebar = () => {
        dispatch(openMessSidebar(!mess));
        dispatch(openFriendsSidebar(false));
    };

    const friendsSidebar = () => {
        dispatch(openFriendsSidebar(!friends));
        dispatch(openMessSidebar(false));
    };

    // console.log('curentUserChat', curentUserChat);

    return (
        <GeneralWrapper>


            {curentUserChat !== undefined &&
                <Wrapper>
                    {/* <img src={user} alt='img'></img> */}

                    <AvatarDisplay><h4>{getInitials(curentUserChat)}</h4></AvatarDisplay>
                    <Name>
                        <h4>{curentUserChat.user_name}</h4>
                        <h4>{curentUserChat.user_surname}</h4>
                    </Name>
                </Wrapper>
            }

            <OpenerWrapper>
                <Opener marked={mess} onClick={messSidebar}>
                    <i className="fa-solid fa-comment"></i>
                </Opener>

                <Opener marked={friends} onClick={friendsSidebar}>
                    <i className="fa-solid fa-user-group"></i>
                </Opener>
            </OpenerWrapper>

            {/* Digitando... */}
        </GeneralWrapper>
    );
}

export default ChatNavBar;