import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// ASSETS
import pic from '../../../../../Assets/Capture.PNG';
import canvasImage from '../../../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../../../GeneralComponents/TestViewsBars/NavBar';
// import FooterBar from '../../GeneralComponents/TestBars/FooterBar';
import TestPage from '../../../TestPage/OneAswTest/TestPageWithOneAsw';
// import TestPage from './MultipleAswTest/TestPageWithMultipleAsw';
// import TestPage from './TextEditorTest/TestPageWithTextEditor';
// import TestPage from './DrawingTest/TestPageWithDrawing';

// import TestPage from './TestVerification/TestVerificationPage';
import FinishPage from './QuestFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { getSelectedQuestionsForTest, bringTest } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../../PagesShareStyle/PagesShareStyle';


const OralMainTestPageForQuest = ({ details }) => {
    const dispatch = useDispatch();
    const params = useParams();
    // const tests = useSelector(({ test }) => test.bringTest);
    const theTest = details.que;
    const [finishTheTest, setFinishTheTest] = useState(false);

    // const [theTest, setTheTest] = useState();
    // const tests = JSON.parse(details.quiz_questions);

    // const tests = []

    // console.log('brinf', params.id)

    useEffect(() => {
        // dispatch(bringTest(params.id))
    }, [])

    // useEffect(() => {
    //     if (tests !== undefined) {
    //         // setTheTest(JSON.parse(tests.quiz_questions));
    //     }
    // }, [tests])

    // useEffect(() => {
    //     dispatch(getSelectedQuestionsForTest(details.newArr));
    // }, []);

    const [rest, setRest] = useState(0);

    console.log('TEST QUEST', details);

    return (
        theTest !== undefined &&
        <WrapperRightSide>
            <NavBar
                total={theTest.length}
                rest={theTest.length - 1}
                flagExercises='exercises'
            //rest={rest}
            />

            <TestPage
                tests={theTest}
                setRest={setRest}
                // testID={details.idTestExercises}
                testID={details.quest}
                setFinishTheTest={setFinishTheTest}
                rest={rest}
                flag='quest-oral'
            />

            {finishTheTest &&
                <FinishPage
                    // testID={tests.idTestExercises}
                    quest={details.quest}
                />
            }
        </WrapperRightSide>
    );
}

export default OralMainTestPageForQuest;


