import React, { useState, useEffect } from 'react';

// PACKEGES
import styled from 'styled-components';

// REDUX
import { useDispatch } from 'react-redux';
import { sendTestMessage, sendSimulationMessage } from '../../../Store/Actions/testActions';

// SHARE STYLE
import {
    // IconWrapper,
    PopUp,
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
} from './ModalStyle';

const H4 = styled.h4`
    width:100%;
    text-align: center;
    color: var(--text3);
    padding: 10px;
`;

const TextareaCss = styled.textarea`
    width: 100%;
    height: 150px;
    padding: 6px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    margin-top:10px;
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
    resize: none;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border: 1px solid var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

const LabelCss = styled.label`
    display:flex;
    align-items: center;
    color: var(--text1);
    margin: 20px 10px 0;
`;



const DeleteModal = ({ message, setMessage, comment, setComment }) => {

    const handleClose = (e) => {
        e.preventDefault();
        setComment(false);
    };

    const handleDelete = (e) => {
        e.preventDefault();
        setComment(false);
        setMessage('');
    };

    const handleChange = evt => {
        setMessage(evt.target.value)
    };

    // console.log('MODAL', );


    return (
        <>
            <ModalWrapper onClick={(e) => handleClose(e)} />

            <DialogBox>
                <H3>Corrección</H3>
                <Hr></Hr>

                <LabelCss htmlFor="message">Messaggio</LabelCss>
                <TextareaCss
                    placeholder="Tu comentario aquí..."
                    type="text"
                    name='message'
                    required
                    value={message}
                    onChange={(e) => handleChange(e)}
                />

                <BtnWrapper>
                    <Btn disable onClick={handleDelete}>Annulla</Btn>
                    <Btn onClick={() => setComment(!comment)}>Inviare</Btn>
                </BtnWrapper>
            </DialogBox>
        </>
    );
}

export default DeleteModal;