import React from 'react';
import { Link } from 'react-router-dom';

import pic from '../../../Assets/card-pic.png';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';

// REDUX
import { useSelector } from 'react-redux';
// import { PopUp } from '../Modals/ModalStyle';

const CardWrapper = styled.div`
    position: relative;
    width: 400px;
    /* margin: 20px 40px 20px 40px; */
`;

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: var(--radius);
    margin: 10px 0px;
    /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
    box-shadow: 0 10px 10px rgb(0 0 0 / 5%);
    overflow: hidden;
`;

const Image = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;

const PopUp = styled.div`
    position:absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 10px;
    background: #6f6f6f73;
    color: var(--text5);
    /* text-align: center; */
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(8px);
    transition: var(--transition);
    /* white-space: nowrap; */
`;

const Shadow = styled.div`
    height: 180px;
    padding: 10px 20px;
    border-radius: var(--radius);
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
    background: var(--background4);
        >h5{
            position: relative;
            color: var(--text1);
            font-weight: bold;
            max-height: 40px;
            overflow: hidden;
                &:hover ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: .8s;
                }
        }
        /* >h6{
            position: relative;
            color: var(--text1);
            font-weight: 100;
            padding-top: 10px;
            height: 60px;
           
            &:hover ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: .8s;
                }
        } */
`;

const Desc = styled.div`
        position: relative;
        >h6{
            color: var(--text1);
            font-weight: 100;
            padding-top: 5px;
            height: 60px;
        }
        &:hover ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: .8s;
        }
`;

const BtnLink = styled(Link)`
    position: absolute;
    bottom: 10px;
    width: 140px;
    padding: 10px 30px;
    border:none;
    outline: none;
    /* background: var(--acc-color); */
    background: var( --background1);
    color: var(--text5);
    font-weight: bold;
    text-decoration: none;
    margin: 10px 0;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
        >i{
            /* position: absolute; */
            /* right: 20px; */
            margin-left: 10px;
            font-size: 14px;
            color: var(--text5);   
        }
        &:hover{
            /* opacity: .8;
            transition: .2s ease-in-out; */
            background: var( --acc-color);
        }
        &:active{
                transform:translateY(2px);
        }  
`;

const Info = styled.div`
   display: flex;
   justify-content: space-between;
        >h5{
            color: var(--text3);
            font-size: 12px;
        }
`;



const CourseCard = ({ course }) => {
    //console.log("COURSE", course)
    // const profile = useSelector((state) => state.auth.user);

    const formatDesc = (desc) => {
        // let courseDesc = desc;
        if (desc.length > 200) {
            return (
                desc.slice(0, 200) + ' ...'
            )
        } else {
            return desc
        }
    };


    return (
        <CardWrapper>
            <ImageWrapper>
                {/* {(profile !== undefined && profile.accesLevel === 'admin') &&
                <Admin
                    content={content}
                />
            } */}

                <Image src={course.url} alt='img'></Image>
            </ImageWrapper>

            <Shadow>
                {course !== undefined &&
                    <>
                        <h5>
                            {course.course_name.length > 60 ? course.course_name.slice(0, 60) + '...' : course.course_name} - {course.course_code}
                            {course.course_name.length > 60 &&
                                <PopUp>{course.course_name} - {course.course_code}</PopUp>
                            }
                        </h5>

                        <Desc>
                            <h6 dangerouslySetInnerHTML={{ __html: formatDesc(course.desc) }} />
                            {course.desc.length > 200 &&
                                // <PopUp>{course.desc}</PopUp>
                                <PopUp
                                    dangerouslySetInnerHTML={{ __html: course.desc }}
                                />
                            }
                        </Desc>
                    </>
                }


                <BtnLink
                    to={{
                        pathname: '/dashboard/type',
                        state: { course }
                    }}
                >
                    Accedi
                    <i className="fa-solid fa-chevron-right"></i>
                </BtnLink>

                <Info>
                    {/* <h5>{cours !== undefined &&cours.materia.length} Materie</h5>
                    <h5>{cours !== undefined &&cours.type} </h5> */}
                </Info>
            </Shadow>
        </CardWrapper >
    );
};

export default CourseCard;