import styled, { keyframes } from 'styled-components';

// MODAL
export const PopUp = styled.div`
    position:absolute;
    width: 130px;
    top: ${props => props.new ? '-45px' : '-40px'};
    right: 0px;
    padding: 10px;
    min-width: ${props => props.new ? '150px' : null};
    background:#6f6f6f73;
    color: var(--text5);
    text-align: center;
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
`;

// export const IconWrapper = styled.div`
//     position: relative;
//         >i{
//             font-size: 18px;
//             padding: 10px 10px 10px 0;
//             cursor: pointer;
//             color:var(--text2);
//                 &:hover{
//                     color:var(--err);
//             }
//         }
//         &:hover ${PopUp}{
//                         opacity: 1;
//                         visibility: visible;
//                     }
// `;

export const IconWrapper = styled.div`
    /* position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1; */
    position: relative;
    margin: 0 5px;
    background-color: var(--background4);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >i{
            font-size: 16px;
            cursor: pointer;
            color:var(--text1);
            padding: 15px;
                &:hover{
                    color:var(--err);
            }
        }
        &:hover ${PopUp}{
                        opacity: 1;
                        visibility: visible;
                    }
`;

export const ModalWrapper = styled.div`
    position:fixed;
    width: 100%;
    height:100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index:2;
    pointer-events: auto;
    cursor: pointer;
    background: #2525256e;
    backdrop-filter: blur(10px);
    padding: 20px;
    transition: background-color .1s;
`;

export const enter = keyframes`
0% {
    transform:  translate(-50%, -100%);
}
45% {
    transform:  translate(-50%, -50%);
}
80% {
    visibility: visible;
    transform:  translate(-50%, -60%);
}

100% {
    visibility: visible;
    transform:  translate(-50%, -50%);
}
`;

export const out = keyframes`
0% {
    visibility: visible;
    transform:  translate(-50%, -50%);
}
45% {
    visibility: visible;
    transform:  translate(-50%, -55%);
}
80% {
    transform:  translate(-50%, -50%);
}
100% {
    transform:  translate(-50%, -100%);
}
`;

export const DialogBox = styled.div`
    visibility: hidden;
    position:fixed;
    top:50%;
    left:50%;
    /* transform:translate(-50%, -50%); */
    width: 50%;
    max-width: 500px;
    min-width: 350px;
    background: var(--color4);
    border-radius: var(--radius);
    z-index: 2;
    padding: 10px;
    animation: ${enter} .4s forwards;
    display: block!important;
`;

export const Hr = styled.hr`
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--text4);
`;

export const H3 = styled.h3`
    width:100%;
    text-align: center;
    color: var(--text3);
    padding: 10px;
`;

export const InputWrapper = styled.label`
	position: relative;
        >i{
            position: absolute;
            top: 5px;
            right: 20px;
            z-index:1;
        }
`;

export const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 10px;
    text-align: left;
    font-weight:bold;
    /* fon  t-style:italic; */
    color: var(--text1);
`;

export const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 12px;
    border:1px solid var(--border);
    border-radius: var(--radius);
    &:focus{
        outline:none;
        border:1px solid var(--color2);
    }
    &::placeholder { 
            color: var(--text2);
            font-size: 14px;
    }
`;

export const TextArea = styled.textarea`
    width:100%;
    resize: none;
    padding: 6px 12px;
    border:1px solid var(--border);
    border-radius: var(--radius);
    &:focus{
        outline:none;
        border:1px solid var(--color2);
    }
    &::placeholder { 
            color: var(--text2);
            font-size: 14px;
    }
`;

export const CalendarWrapper = styled.div`
   position: absolute;
   z-index:2;
   right:10px;
   /* left: 50%;
   transform: translateX(-50%); */
`;

export const CalendarBtn = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-end;
    background-color: var(--background);
    padding: 10px;
        >button{
            margin:0;
            height: auto;
            padding: 10px 20px;
            font-size: 12px;
        }
        >button:nth-child(1){
            margin-right:10px;
        }
`;

export const BtnWrapper = styled.div`
    width:100%;
    display: flex;
    justify-content: center;
`;

export const Btn = styled.button`
    height:50px;
    padding: 0 30px;
    min-width: 150px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-weight: bold;
    border: none;
    color:${props => !props.disable ? 'var(--text5)' : 'var(--color1)'};
    border-radius: var(--radius);
    margin-top: 40px;
    margin-left: 10px;
    cursor: pointer;
    transition: var(--transition) ease;
    background: ${props => props.disable && 'transparent' ||
        props.closed && 'var(--background1)' ||
        !props.disabled && 'var(--success)'
    };
    /* border: ${props => !props.disable ? '1px solid var(--success)' : '1px solid var(--color1)'}; */
    outline:none;
    border: ${props => (!props.disable && !props.closed) && '1px solid var(--success)' ||
        props.disable && '1px solid var(--color1)' ||
        props.closed && '1px solid var(--background1)'
    };
        &:hover{
            opacity: ${props => !props.disable && '.7'};;
            color:${props => !props.disable ? 'var(--text5)' : 'var(--text5)'};
            /* background: ${props => !props.disable ? 'var(--success)' : 'var(--err)'}; */
            /* border: ${props => !props.disable ? '1px solid var(--success)' : '1px solid var(--err)'}; */
            background: ${props => (!props.disable && !props.closed) && 'var(--success)' ||
        props.closed && 'var(--background1)' ||
        props.disable && 'var(--err)'

    };
            border: ${props => (!props.disable && !props.closed) && '1px solid var(--success)' ||
        props.closed && '1px solid var(--background1)' ||
        props.disable && '1px solid var(--err)'
    };
        }
`;

// export const colourStyles = {
//     control: styles => ({
//         ...styles,
//         backgroundColor: 'white',
//         borderRadius: '4px',
//         border: '1px solid var(--border)',
//         minHeight: '50px',
//         fontSize: '14px',
//         paddingLeft: '4px',
//         boxShadow: 'none',
//     }),
//     menu: styles => ({
//         ...styles,
//         backgroundColor: 'white',
//         border: '1px solid var--background4)',
//         fontSize: '14px',
//         color:'var(--text1)',
//         zIndex:2,
//     }),
//     input: styles => ({ ...styles, fontSize: '14px' }),
//     placeholder: styles => ({ ...styles, fontSize: '14px' }),
//     singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
//     multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
// };

export const colourStyles = {
    control: (styles, state) => ({
        ...styles,
        // width: 300,
        marginTop: 10,
        backgroundColor: 'transparent',
        borderRadius: 'var(--radius)',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text1)',
        border: (state.isHovered ? '1px solid var(--acc-color)' : '1px solid var(--border)') ||
            (state.isFocused ? '1px solid var(--acc-color)' : '1px solid var(--border)'),
    }),
    menu: styles => ({
        ...styles,
        // width: 300,
        backgroundColor: 'var(--background4)',
        border: 'none',
        fontSize: '14px',
        color: 'var(--text1)',
        zIndex: 2,
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)' }),
    placeholder: styles => ({ ...styles, fontSize: '14px', color: 'var(--text3)' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};