import React from 'react';

// PACKAGES
import styled from 'styled-components';

const FooterWrapper = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    margin-left: 250px;
    width: calc(100% - 250px);
    height: 80px;
    background-color: var(--background4);
    padding: 5px;
    /* border-radius: var(--radius); */
    display: flex;
    align-items: center;
    justify-content: center;
        @media (max-width: 1100px) {
            margin-left: 0px;
            width: 100%;
        };

`;

const Btn = styled.button`
    height:50px;
    padding: 0 20px;
    min-width: 100px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-weight: bold;
    border: none;
    color:${props => !props.disabled ? 'var(--text5)' : 'var(--text2)'};
    background: ${props => props.disable && 'transparent' ||
        props.closed && 'var(--background1)' ||
        !props.disabled && 'var(--err)'
    };
    border-radius: var(--radius);
    cursor: pointer;
    /* transition: var(--transition) ease; */
    outline:none;
    margin: 0 10px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        &:hover{
            opacity: .9;
        }
        &:active{
                transform: translateY(2px);
                box-shadow: none;
        } 
`;


const FooterBarForVerification = ({ submitTest, grade, loading }) => {

    return (
        <FooterWrapper>
            {grade !== '' ?
                loading ?
                    <Btn closed>
                        Salvataggio...
                    </Btn>
                    :
                    <Btn onClick={submitTest}>
                        Salva la correzione
                    </Btn>
                :
                <Btn closed>
                    Salva la correzione
                </Btn>
            }
        </FooterWrapper>
    );
};

export default FooterBarForVerification;