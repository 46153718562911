const initState = {

}

const oraleReducers = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ALL_ORAL_SUBJECTS':
            return {
                ...state,
                allOralSubs: action.payload,
            };

        case 'GET_ALL_ORAL_QUESTIONNAIRES':
            return {
                ...state,
                allOralQuest: action.payload,
            };

        case 'DOWNLOAD_FILE_ORALE':
            return {
                ...state,
                file: action.payload,
            };
        //GET_ALL_ORAL_QUESTIONNAIRES
        //GET_ALL_ORAL_SUBJECTS
        default:
            return state;
    }
};

export default oraleReducers;