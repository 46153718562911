import axios from 'axios';
axios.defaults.withCredentials = true;

export const newNotifications = (userId, reciverId, userDetails, course, test, testType, flag, initialTests) => (dispatch, getState) => {

  const body = {
    senderId: userId,
    reciverId: reciverId,
    role: userDetails.role,
    course: course,
    test: test,
    testType: testType,
    userDetails: userDetails,
    flag: flag,
    initialTests: initialTests
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/createNotifications`, body)
    .then((res) => {
      // console.log("GET_NOTIFICATIONS", res.data);
      // dispatch({
      //   type: 'GET_NOTIFICATIONS',
      //   payload: [...mess, res.data],
      // });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const 
 newNotificationsForProf = (userId, reciverId, userDetails, course, test, testType, svolgere) => (dispatch, getState) => {

  const body = {
    senderId: userId,
    reciverId: reciverId,
    role: userDetails.role,
    course: course,
    test: test,
    testType: testType,
    userDetails: userDetails,
    svolgere: svolgere,
    initialTests: test
  };

  // console.log('POST_NOTIFICATIONS', body);

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/createNotificationsForProf`, body)
    .then((res) => {
      // console.log("GET_NOTIFICATIONS", res.data);
      // dispatch({
      //   type: 'GET_NOTIFICATIONS',
      //   payload: [...mess, res.data],
      // });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateNotifications = (test, whoAmI, svolgere, flag, type) => (dispatch, getState) => {

  const body = {
    test: test,
    profileId: whoAmI,

    svolgere: svolgere,
    flag: flag,
    testType: type
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/updateNotifications`, body)
    .then((res) => {
      // console.log("GET_NOTIFICATIONS", res.data);
      // dispatch({
      //   type: 'GET_NOTIFICATIONS',
      //   payload: [...mess, res.data],
      // });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findUnreadNotifications = (profile) => (dispatch, getState) => {

  const body = {
    profile: profile,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/findAllUnreadNotifications`, body)
    .then((res) => {
      // console.log("UNREAD_NOTIFICATIONS", res);
      dispatch({
        type: 'UNREAD_NOTIFICATIONS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findReadedNotifications = (profile) => (dispatch, getState) => {

  const body = {
    idUser: profile.idUser,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/getReadedNotifications`, body)
    .then((res) => {
      // console.log("GET_READED_NOTIFICATIONS", res)
      dispatch({
        type: 'GET_READED_NOTIFICATIONS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const markAllNotifAsRead = (profile) => (dispatch, getState) => {

  const body = {
    idUser: profile.idUser,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/markNotificationsAsRead`, body)
    .then((res) => {
      // console.log("MARK_ALL_NOTIFICATIONS_AS_READ", res);
      // dispatch({
      //   type: 'MARK_ALL_NOTIFICATIONS_AS_READ',
      //   payload: res.data,
      // });
      dispatch(findUnreadNotifications(profile));
      dispatch(findReadedNotifications(profile));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteAllNotif = (profile) => (dispatch, getState) => {

  const body = {
    idUser: profile.idUser,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/deleteAllNotif`, body)
    .then((res) => {
      // console.log("DELETE_ALL_NOTIF", res);
      // dispatch({
      //   type: 'DELETE_ALL_NOTIF',
      //   payload: res.data,
      // });
      dispatch(findUnreadNotifications(profile));
      dispatch(findReadedNotifications(profile));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const markNotifAsRead = (id, profile) => (dispatch, getState) => {

  const body = {
    idNotif: id,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/markNotifAsRead`, body)
    .then((res) => {
      // console.log("MARK_NOTIF", res);
      // dispatch({
      //   type: 'MARK_NOTIF',
      //   payload: res.data,
      // });
      dispatch(findUnreadNotifications(profile));
      dispatch(findReadedNotifications(profile));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteNotif = (id, profile) => (dispatch, getState) => {

  const body = {
    idNotif: id,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/deleteNotif`, body)
    .then((res) => {
      // console.log("DELETE_NOTIF", res);
      // dispatch({
      //   type: 'DELETE_NOTIF',
      //   payload: res.data,
      // });
      dispatch(findUnreadNotifications(profile));
      dispatch(findReadedNotifications(profile));
    })
    .catch((err) => {
      console.log(err);
    });
};

