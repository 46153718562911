import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';
import axios from 'axios';

import Vimeo from '@u-wave/react-vimeo';
// import YouTube, { YouTubeProps } from 'react-youtube';

// COMPONENTS
// import Quest from './Quest/Quest';

// REDUX
import { useSelector } from 'react-redux';
// import { getAllQuestionnaires, videoIsDone } from '../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    TestWrapper,
    BtnWrapper,
    Btn,
    GoBack
} from '../../PagesShareStyle/PagesShareStyle';

const TitleWrapper = styled.div`
    position: relative;
    display: flex;
`;

const Title = styled.h3`
  /* width:100%; */
  color:var(--text2);
  display: flex;
  align-items: center;
  font-weight: bold;
  /* margin-top: 10px; */
`;

const VideoWrapper = styled.div`
    position: relative;
    /* width: 100%;
    height: calc(100vh - 300px); */
    margin-top: 20px;
    background: var(--background4);
    display: flex;
    align-items: center;
    justify-content: center;
    /* .video{
        width: 100%;
    } */
    /* >video{
        width: 100%;
    } */
`;

const InfoWrapper = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const Info = styled.div`
    margin-top: 20px;
    width: 100%;
    font-size: var(--fontL);
    color: var(--text2);
`;


const TpaLessonPage = ({ details }) => {
    // const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const props = details.less;

    // useEffect(() => {
    //     const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
    //     dispatch(getAllQuestionnaires(details.less.Lessons_idLesson, idUser));
    // }, [dispatch]);

    const endVideo = () => {
        // dispatch(videoIsDone(
        //     details.less.Lessons_idLesson,
        //     details.less.Subjects_idSubject,
        //     details.course.idCourse
        // ));
        // console.log('end');
    };

    const custom = {
        width: '100%',
        maxWidth: '2000px'
    };

    const capitalizeFirstLetter = (str) => {
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    // console.log("QUEST LESSON", quests);
    // console.log('DETAILS TPA LESSON', details);

    return (
        <WrapperRightSide>
            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                <Title>{props.lesson_name}</Title>
            </TitleWrapper>

            <br></br>
            {details.studDetails !== undefined &&
                <>
                    {capitalizeFirstLetter(details.studDetails.user_role)}: {details.studDetails.user_name} {details.studDetails.user_surname}
                </>
            }

            <VideoWrapper >
                <Vimeo
                    // video="https://player.vimeo.com/video/748050997"
                    video={props.video}
                    style={custom}
                    onEnd={endVideo}
                    autoplay={false}
                    responsive={true}
                    sbackground={true}
                />
            </VideoWrapper>

            <InfoWrapper>
                <Title>{props.title}</Title>
                <Info>{props.body}</Info>
            </InfoWrapper>
        </WrapperRightSide>
    );
}

export default TpaLessonPage;