import styled from 'styled-components';

export const TestBody = styled.div`
    padding-bottom: 80px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
    >div>ul{
        width: 200px;
        height: 200px;
        background: red;
       
    }
    >div>div>ul{
            width: 200px;
            height: 200px;
            background: blue;
        }
`;

export const BigQuestion = styled.div`
    width: 100%;
    max-height: 250px;
    overflow: scroll;
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
   
    margin-right: 20px;
    margin-left: 10px;
    background: var(--background4);
    padding: 20px 10px;
    border-radius: var(--radius);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        ::-webkit-scrollbar {
            width: 4px; 
        };
        @media (max-width: 1650px) {
            width: 95%;
        };  
`;

export const Question = styled.div`
    /* justify-content: center;
    text-align: center; */
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

export const Letter = styled.div`
    color:  var(--text2);
    font-size: 24px;
    font-weight: bold;
    display: flex;
    padding: 20px;
    background-color: var(--background3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Answers = styled.div`
    width: 100%;
    color:  var(--text2);
    font-size: 20px;
    display: flex;
    padding: 20px;
    justify-content: center;
    text-align: center;
    background-color: var(--background4);
    >img{
        max-width: 400px;
        max-height: 400px;
        pointer-events: none;
    }
`;

export const AnswerWrapper = styled.div`
    width: 80%;
    justify-content: center;
    text-align: center;
    display: flex-end;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    background: var(--background4);
    cursor: pointer;
    ${Letter}{
            background: ${props => props.selected === 'selected' && 'var(--warning)'}!important;
            color: ${props => props.selected === 'selected' && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => props.selected === 'selected' && '#f8940655'}!important;
        }
        &:hover ${Letter}{ 
            background: #99979791;
            color: var(--text5);
        }
        &:hover ${Answers}{ 
            background: #bbbbbb55;
        } 
`;

export const AnswerWrapperCompleted = styled.div`
    width: 30%;
    display: flex;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    background: var(--background4);
    cursor: pointer;
        ${Letter}{
            background: ${props => (props.selected === 'selected' && 'var(--warning)') ||
        (props.selected === 'this' && 'var(--success)') ||
        (props.selected === 'correct' && 'var(--success)') ||
        (props.selected === 'incorrect' && 'var(--err)')
    }!important;
            color: ${props => props.selected !== undefined && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => (props.selected === 'selected' && '#f8940655') ||
        (props.selected === 'this' && '#5fd07555') ||
        (props.selected === 'correct' && '#ffbc5dd1') ||
        (props.selected === 'incorrect' && '#e91e6355')
    }!important;
        }
        @media (max-width: 800px) {
            width: 95%;
        };  
`;

export const CommentWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
        >div{
            width: 100%;
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
`;

export const Label = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 0px;
    color: var(--text2);
    font-weight: bold;
    text-align: center;
`;

export const SubLabel = styled.label`
    width: 100%;
	display: flex;
	font-size: 1rem;
    color: var(--text2);
    /* font-weight: bold; */
    text-align: center;
    margin-top: 10px;
        >div{
            width: 100%;
        }
`;

export const Container = styled.div`
	position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
        >div{
            width: 100%;
            display: flex;
        }
`;

export const Input = styled.input`
    width: 100%;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    /* margin: 10px 10px 20px 10px; */
    color: var(--text1);
    background-color: transparent;
    transition: var(--transition) ease;
    margin: 10px;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
            /* box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%); */
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        &::-webkit-calendar-picker-indicator{
            filter: invert(50%) sepia(4%) saturate(41%) hue-rotate(313deg) brightness(112%) contrast(100%);
            cursor: pointer;
            font-size: 20px;  
        }
`;

export const InputTextArea = styled.textarea`
    width: 100%;
    height: 150px;
    padding: 10px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    margin-top:10px;
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
    resize: none;
    margin: 10px;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border: 1px solid var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        ::-webkit-scrollbar {
            width: 5px;
        };
`;

export const AddBtn = styled.button`
    position: absolute;
    right: 10px;
    bottom: 0;
    padding: 10px;
    border-radius: var(--radius);
    color: var(--color2);
    font-weight: bold;
    font-size: 14px;
    letter-spacing:2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--background4);
    border: none;
    outline:none;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
    >i{
        margin-right: 10px;
        font-size: 18px;
    }
`;

export const RemoveBtn = styled.button`
    position: absolute;
    right: 4px;
    top: 0px;
	width: 20px;
    height: 20px;
    border: none;
    border-radius: var(--radius);
    /* background: #79797926; */
    background: var(--background2);
    border: 1px solid #79797926;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    color: var(--text2);
    box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
        >i{
            margin-left: 2px;
        }
        &:hover{
            /* background: var(--hover); */
            >i{
                color: var(--err);
            }
        }
`;

export const DnDContainer = styled.div`
    width: 100%;
    display: flex;
    border-radius: var(--radius);
`;

export const QuestsContainer = styled.div`
	width: 50%;
    height: calc(100vh - 300px);
    margin: 10px;
    background: var(--background4);
    border-radius: var(--radius);
   overflow: auto;
`;

export const Title = styled.div`
    width: 100%;
    padding: 15px;
    text-align: center;
    background: var(--acc-color);
    color: var(--text5);
    font-size: 20px;
    font-weight: bold;
    cursor: default;
`;

export const Body = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
`;

export const TheElement = styled.div`
	position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: var(--background1);
    color: var(--text5);
    margin-top: 10px;
    border-radius: var(--radius);
    user-select: none;
        >h5 {
            font-weight: bold;
        }
`;

export const Empty = styled.div`
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text4);
    font-size: 18px;
    >i{
        font-size: 40px; 
        margin-bottom: 10px;
    }
`;
