import { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from "react-router-dom";

// ASSETS
import pic from '../../../Assets/Capture.PNG';
import canvasImage from '../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../GeneralComponents/TestViewsBars/NavBar';
import TestPage from './OneAswTest/TestPageWithOneAsw';
import FinishPage from './SimulationFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringSimulation, sendSimulationAnswers } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../PagesShareStyle/PagesShareStyle';


const MainTestPageForSimulations = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const simulation = useSelector(({ test }) => test.bringSimulation);
    const finishSimulation = useSelector(({ other }) => other.finishSimulation);
    const finishTime = useSelector(({ other }) => other.finishTime);
    // const score = useSelector(({ test }) => test.score);
    const [theSimulation, setTheSimulation] = useState();
    const [finishTheTest, setFinishTheTest] = useState(false);
    const [objForTimer, setObjForTimer] = useState([]);

    // console.log('params', params);

    useEffect(() => {
        dispatch(bringSimulation(params.id))
    }, [dispatch]);

    useEffect(() => {
        if (simulation !== undefined) {
            setTheSimulation(JSON.parse(simulation.sim_config));
        }
    }, [simulation]);

    const [rest, setRest] = useState(0);

    useEffect(() => {
        if (finishSimulation !== undefined) {
            dispatch(sendSimulationAnswers(objForTimer, simulation.idSimulations, finishTime));
            setFinishTheTest(true);
            setTimeout(() => {
                // window.close();
                history.replace({
                    pathname: '/dashboard/exercises',
                    state: { course: details, title: 'Prova Preselettiva' },
                    replace: true
                });
            }, 5000);
        }
    }, [finishSimulation]);

    // useEffect(() => {
    //     if (score !== undefined) {
    //         setFinishTheTest(true);
    //         setTimeout(() => {
    //             window.close();
    //         }, 15000);
    //     }
    // }, [score]);

    return (
        theSimulation !== undefined &&
        <WrapperRightSide>
            <NavBar
                total={theSimulation.questions.length}
                time={theSimulation.time}
                rest={rest}
            // setFinishTheTest={setFinishTheTest}
            // objForTimer={objForTimer}
            // testID={simulation.idSimulations}

            />

            <TestPage
                tests={theSimulation.questions}
                setRest={setRest}
                // testID={details.idTestExercises}
                testID={simulation.idSimulations}
                setFinishTheTest={setFinishTheTest}
                setObjForTimer={setObjForTimer}
                rest={rest}
                flag='simulation'
                params={params.id}
                details={details}
            />

            {finishTheTest &&
                <FinishPage />
            }
        </WrapperRightSide>
    );
}

export default MainTestPageForSimulations;


