import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';
import Select from 'react-select';

// COMPONENTS
import CourseCard from '../../GeneralComponents/Cards/CourseCard';

// PAGE
import MainTestPage from '../TestPage/MainTestPage';
import LearningPage from '../LearningPage/LearningPage';

// REDUX
import { useSelector, useDispatch, } from 'react-redux';
//import { getAllCourses } from '../../../Store/Actions/coursesActions';
import { getAllSubjects, getNotebooks, downloadFile } from '../../../Store/Actions/subjectsActions';
//import useSubjects from './useSubjects';
// STYLE
import {
    WrapperRightSide,
    colourStyles5,

} from '../PagesShareStyle/PagesShareStyle';

const LabelCss = styled.label`
	display: flex;
	font-size: 1.3rem;
	margin: 20px 0px 0px;
    color: var(--text2);
    width: '100%';
`;

const Title = styled.h3`
    width:100%;
    color:var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(240,242,245);
    background: linear-gradient(90deg, rgba(240,242,245,1) 0%, rgba(119,123,141,0.9500175070028011) 30%, rgba(108,113,132,1) 50%, rgba(96,101,122,0.8855917366946778) 70%, rgba(240,242,245,1) 100%);
    /* background: rgb(240,242,245);
    background: linear-gradient(90deg, rgba(240,242,245,1) 0%, rgba(211,32,88,1) 30%, rgba(211,32,88,1) 50%, rgba(211,32,88,1) 70%, rgba(240,242,245,1) 100%);
    background: rgb(240,242,245);
    background: linear-gradient(90deg, rgba(240,242,245,1) 0%, rgba(39,55,143,1) 30%, rgba(39,55,143,1) 50%, rgba(39,55,143,1) 70%, rgba(240,242,245,1) 100%); */
`;

const CardsWrapper = styled.div`
    width:100%;
    padding: 30px 0;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: left;
        &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 3000px) {
            grid-template-columns: repeat(5,auto);
        }
        @media (max-width: 2500px) {
            grid-template-columns: repeat(4,auto);
        }
        @media (max-width: 2100px) {
            grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 1600px) {
            grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 1150px) {
            grid-template-columns: repeat(1,auto);
            justify-content: space-around;
        }
        /* @media (max-width: 500px) {
            grid-template-columns: repeat(1,auto);
        } */
`;

const EmptyPage = styled.div`
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >h2{
            color: var(--text3);
        }
        >i {
            font-size: 80px;
            padding-top: 20px;
            color: var(--text3);
        }
`;

const PopUp2 = styled.div`
    position:absolute;
    top: -45px;
    left: 0;
    padding: 10px;
    background: #6f6f6f73;
    color: var(--text5);
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
`;

const Uploaded = styled.div`
    position: relative;
    width:450px;
    height: 50px;
    color: var(--color2);
    font-weight: bold;
    font-size: 16px;
    border-radius: var(--radius);
    margin-right: 20px;
    margin-top: 40px;
    padding: 10px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    background: var(--background4);
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
    &:hover ${PopUp2} {
                opacity: 1;
                visibility: visible;
        }
    >i{
        margin-right: 10px;
    }
`;

const useSelectedSubjects = () => {
    return useSelector(({ subjects }) => subjects.allSubjects);
};

const EducationalMaterials = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector(({ auth }) => auth.user);
    const subz = useSelector(({ subjects }) => subjects.allSubjects);
    const books = useSelector(({ subjects }) => subjects.note);


    const [coursesOpt, setCoursesOpt] = useState([]);
    const [subs, setSubs] = useState([]);
    const [subsOpt, setSubsOpt] = useState([]);
    const [notes, setNotes] = useState([])


    const [more, setMore] = useState({
        idCourse: '',
        //idSubject: '',
    });



    useEffect(() => {
        if (user !== undefined) {
            let arr = [];
            if (user.follow.length > 0) {
                user.follow.forEach(c => {
                    arr.push({ value: c.idCourse, label: `${c.course_code} - ${c.course_name}` })
                });
                setCoursesOpt(arr)
            }
        }
    }, [user])

    // useEffect(() => {
    //     if (user !== undefined) {
    //         setCourses(user.follow);
    //     }
    // }, [user]);

    useEffect(() => {
        setSubs(subz);
    }, [subz]);

    const handleChange = async (evt) => {
        setMore({
            ...more,
            idCourse: evt.value
        });
        try {
            //await dispatch(getAllSubjects(evt.value, user.idUser));
            await dispatch(getNotebooks(evt.value))
            //setSubs(subz);
        } catch (error) {
            // Handle error if needed
        }

    }

    useEffect(() => {
        if (books !== undefined) {
            setNotes(books);
        }

    }, [books]);

    // useEffect(() => {
    //     let arr = []
    //     if (subs.length > 0) {
    //         subs.forEach(s => {
    //             arr.push({ label: s.subject_name, value: s.idSubject })
    //         })
    //         setSubsOpt(arr)
    //     }
    // }, [subs])




    // const handleGetDocs = async (evt) => {
    //     setMore({
    //         ...more,
    //         idSubject: evt.value
    //     });
    //     try {
    //         await dispatch(getNotebooks(more.idCourse, evt.value));
    //         //setSubs(subz);
    //     } catch (error) {
    //         // Handle error if needed
    //     }
    // }

    // useEffect(() => {
    //     if (books !== undefined) {
    //         setNotes(books);
    //     }

    // }, [books]);


    const handleDownload = (doc) => {

        window.open(doc.url, 'download_window', 'toolbar=0,location=no,directories=0,status=0,scrollbars=0,resizeable=0,width=1,height=1,top=0,left=0');
        window.focus();

    };

    //console.log("COURSES", courses)
    // console.log("COURSESOPT", coursesOpt)
    //console.log("BOOKS", notes);
    //console.log("MORTE", more);

    return (
        <WrapperRightSide>
            <Title
                style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                Aggiornamento Materiali didattici
            </Title>

            <LabelCss htmlFor="type">Selezionare un corso</LabelCss>
            <Select
                name='type'
                isMulti={false}
                closeMenuOnSelect={true}
                isSearchable={true}
                // defaultValue={defaultValue()}
                //onChange={(e) => setChoose(e.value)}
                onChange={handleChange}
                // onChange={(e) => {

                options={coursesOpt}
                styles={colourStyles5}
                placeholder={'Scegli il corso'}
            />
            {/* {subs.length > 0 &&
                <> */}
            {/* <LabelCss htmlFor="type">Selezionare la materia</LabelCss>
                    <Select
                        name='type'
                        isMulti={false}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        // defaultValue={defaultValue()}
                        //onChange={(e) => setChoose(e.value)}
                        onChange={handleGetDocs}
                        // onChange={(e) => {

                        options={subsOpt}
                        styles={colourStyles5}
                        placeholder={'Scegli la materia'}
                    /> */}


            {
                //notes !== undefined &&
                notes.length > 0 ?
                    notes.map((docu, i) =>
                        <Uploaded
                            key={i}
                            //onClick={window.open(docu.url, "_blank")}
                            onClick={() => {
                                handleDownload(docu)
                            }
                            }
                        >
                            <i className="fa-solid fa-link"></i>{docu.name}
                        </Uploaded>
                    )
                    :
                    <EmptyPage>
                        <h2>Nessun documento disponibile</h2>
                        <i className="fa-solid fa-folder-open"></i>
                    </EmptyPage>
            }

            {/* </>



            } */}


        </WrapperRightSide>
    );
}

export default EducationalMaterials;