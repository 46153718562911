import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import ResultCard from '../../GeneralComponents/Cards/ResultCard';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllSubjectsOfAUser } from '../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    TestWrapper,
    GoBack,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const CardsWrapper = styled.div`
    width:100%;
    /* height: calc(100vh - 160px); */
    /* margin: 30px 0 0; */
    padding: 20px 0;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column: 50px;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: left;
    /* grid-row-gap: 20px;
    justify-content: flex-start; */
    /* justify-content: space-evenly; */
        &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 2000px) {
            grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 1550px) {
            grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 850px) {
            grid-template-columns: repeat(1,auto);
        }
`;


const ResultPage = ({ details }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userAllSubjects = useSelector(({ subjects }) => subjects.userAllSubjects);
    const results = details.elem.Courses_idCourse_Courses_Users_follow_Courses;
    const [type, setType] = useState('preliminare')

    const capitalizeFirstLetter = (str) => {
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };
    //console.log(details)

    useEffect(() => {
        dispatch(getAllSubjectsOfAUser(details.elem.idUsers, 'preliminare'));
    }, [dispatch]);

    // console.log('USER RESULTS',details.elem);
    // console.log('USER RESULTS DETAILS',details);
    // console.log('USER RESULTS', results);
    // console.log("USER ALL", userAllSubjects);

    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                <Title>{capitalizeFirstLetter(details.elem.user_role)}: {details.elem.user_name} {details.elem.user_surname}</Title>
            </TitleWrapper>

            <CardsWrapper>
                {results !== undefined &&
                    results.map((res, i) =>
                        <ResultCard
                            key={i}
                            res={res}
                            idCheckUser={details.elem.idUsers}
                            studDetails={details.elem}
                            userAllSubjects={userAllSubjects !== undefined && userAllSubjects[i]}
                            ind={i}
                            type={type}
                            setType={setType}
                        />
                    )}
            </CardsWrapper>

        </WrapperRightSide>
    );
}

export default ResultPage;