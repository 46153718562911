import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// ASSETS
// import pic from '../../../Assets/Capture.PNG';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBarForProfTPA from '../../../../GeneralComponents/AllQuestions/AllQuestionsForVerificationTPA';
// import AllQuestionsSideBar from '../../../../GeneralComponents/AllQuestions/AllQuestions';
import AllQuestionsSideBarForProfForDrawing from '../../../../GeneralComponents/AllQuestions/AllQuestionsForVerificationForDrawing';
import AllQuestionsSideBarForProf from '../../../../GeneralComponents/AllQuestions/AllQuestionsForVerificationForDrawing';
import FooterBar from '../../../../GeneralComponents/TestViewsBars/FooterBar';
import FooterBarForProf from '../../../../GeneralComponents/TestViewsBars/FooterBarFotVerification';
import NavBar from '../../../../GeneralComponents/TestViewsBars/NavBarForVerification';

import VerificationTestTraccia from './TestVerification/VerificationTestTracciaReadOnly';
import VerificationTestScaletta from './TestVerification/VerificationTestScalettaReadOnly';
import VerificationTestCoerenza from './TestVerification/VerificationTestCoerenzaReadOnly';
import VerificationTestRevision from './TestVerification/VerificationRevisioneReadOnly';
import VerificationTestRegistri from './TestVerification/VerificationRegistriReadOnly';
import VerificationTestAvvio from './TestVerification/VerificationTestAvvio';

import VerificationTestTracciaFromNotif from './TestVerification/FromNotif/VerificationTestTracciaReadOnlyFromNotif';
import VerificationTestScalettaFromNotif from './TestVerification/FromNotif/VerificationTestScalettaReadOnlyFromNotif';
import VerificationTestCoerenzaFromNotif from './TestVerification/FromNotif/VerificationTestCoerenzaReadOnlyFromNotif';
import VerificationTestRevisionFromNotif from './TestVerification/FromNotif/VerificationRevisioneReadOnlyFromNotif';
import VerificationTestRegistriFromNotif from './TestVerification/FromNotif/VerificationRegistriReadOnlyFromNotif';
import VerificationTestAvvioFromNotif from './TestVerification/FromNotif/VerificationTestAvvioFromNotif';


// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { saveTemaVerification, getAllTpaTestDone, saveTemaAvvioVerification } from '../../../../../Store/Actions/temaActions';
import { newNotifications, newNotificationsForProf, updateNotifications } from '../../../../../Store/Actions/notificationsActions';
import { findCourse } from '../../../../../Store/Actions/coursesActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../../PagesShareStyle/PagesShareStyle';

const GoBack = styled.div`
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: var(--text3);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: var(--background4);
    /* min-width: 100px; */
    /* border-radius: var(--radius); */
    box-shadow: var(--shadow);
    /* padding: 10px; */
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
`;

const TestWrapper = styled.div`
    position: relative;
    width: calc(100% - 230px);
    margin-top: ${props => props.user === true ? '80px' : '20px'};
    height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
    background: var(--background2);
    overflow: auto;
        @media (max-width: 1100px) {
            width: 100%;
        };
`;

const TestPageWithTemaVerification = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const whoAmI = useSelector(({ auth }) => auth.user);
    const socket = useSelector(({ conversations }) => conversations.socket);
    const aCourse = useSelector(({ courses }) => courses.aCourse);
    const [id, setId] = useState(0);
    const [obj, setObj] = useState([]);
    const [grade, setGrade] = useState(details.elem.grade !== null ? details.elem.grade : '');
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState(details.elem.comments !== null ? JSON.parse(details.elem.comments) : []);
    const [commentsArr, setCommentsArr] = useState([]);
    const commObj = details !== undefined && details.elem.comments !== null ? JSON.parse(details.elem.comments) : null;
    const [done, setDone] = useState([]);
    const [objAvvio, setObjAvvio] = useState([]);

    // FOR VERIF WITH MULTIPLE COMMENTS
    useEffect(() => {
        if (details.type === details.svolgere[4] || details.type === details.svolgere[5]) {
            let createObj = [];
            const comm = JSON.parse(details.elem.questions);
            for (let i = 0; i < comm.length; i++) {
                createObj.push({})
            }
            // console.log('AAAAAAAAAAAAAAAAAAAAAAAAA', commObj);
            setObj(createObj);
            if (commObj !== null) {
                setCommentsArr(commObj);
            } else {
                setCommentsArr(createObj);
            }
        }
    }, [details]);

    // FOR VERIF WITH MULTIPLE COMMENTS
    const setArrOfComments = (e) => {
        // console.log('AM INTRAT AICISHA', e)
        const arr = [...commentsArr]
        arr[id] = e;
        setCommentsArr(arr);
    };

    // FOR VERIF WITH MULTIPLE COMMENTS
    useEffect(() => {
        if (details.type === details.svolgere[0] || details.type === details.svolgere[5]) {
            const tests = details.elem;
            // if (tests.questions !== null) {
            //     setTest(JSON.parse(tests.questions))
            // }
            if (tests.answers !== null) {
                setDone(JSON.parse(tests.answers))
            } else {
                let createObj = [];
                const tst = JSON.parse(tests.questions)
                for (let i = 0; i < tst.length; i++) {
                    createObj.push({})
                }
                setDone(createObj);
            }
        }
    }, [details]);

    // useEffect(() => {
    //     let createObj = [];
    //     for (let i = 0; i < tests.length; i++) {
    //         createObj.push({})
    //     }
    //     setObj(createObj);
    // }, [tests]);

    // const setAnswer = (e, i) => {
    //     // console.log(e);
    //     const value = [...obj]
    //     value[id].asw = e.e;
    //     value[id].svg = e.svg;
    //     value[id].aswIndex = i;
    //     value[id].file = e.file;
    //     value[id].png = e.png;

    //     setObj(value);

    //     let res = 0;
    //     for (let j = 0; j < value.length; j++) {
    //         if (Object.keys(value[j]).length > 0) {
    //             res += 1
    //         }
    //     }
    //     // setRest(res);
    //     // console.log(res)
    // };
    console.log("DETAILS", details)

    useEffect(() => {
        if (whoAmI.role === 'STUDENTE') {
            dispatch(findCourse(details.allDetails.course.idCourse));
        } else {
            dispatch(findCourse(details.elem.idCourse));
        }

    }, [whoAmI]);

    const submitTest = () => {
        setTimeout(() => {
            if (details.type !== details.svolgere[0] && details.type !== details.svolgere[5]) {
                dispatch(saveTemaVerification(
                    obj,
                    grade,
                    details,
                    inputFields,
                    whoAmI.idUser,
                    details.type,
                    details.svolgere
                ));
            } else {
                const commArr = commentsArr
                if (details.type === details.svolgere[4]) {
                    dispatch(saveTemaVerification(
                        obj,
                        grade,
                        details,
                        commentsArr,
                        whoAmI.idUser,
                        details.type,
                        details.svolgere
                    ));
                }
                if (details.type === details.svolgere[5]) {
                    dispatch(saveTemaAvvioVerification(
                        obj,
                        grade,
                        details,
                        commentsArr,
                        whoAmI.idUser,
                        details.type,
                        details.svolgere,
                        objAvvio
                    ));
                }
            };

            //dispatch(getAllTpaTestDone(whoAmI.idUser));

            socket.emit('sendNotif', {
                senderId: whoAmI.idUser,
                receiverId: details.allDetails?.studDetails.idUsers,
                text: 'Verification done!',
                author: whoAmI,
                course: details.allDetails.course,
                test: details.allDetails.elem,
                testType: details.type
            })

        }, 1000);

        setTimeout(() => {
            dispatch(newNotificationsForProf(whoAmI.idUser,
                details.allDetails?.studDetails.idUsers,
                whoAmI,
                details.allDetails.course,
                details.elem,
                details.type,
                details.svolgere,
            ));

            dispatch(updateNotifications(details.elem, whoAmI.idUser, details.svolgere, 'tema', details.type));
        }, 2000);

        setTimeout(() => {
            history.goBack();
        }, 2200);
        setLoading(true);
    };

    // console.log('OBJ', obj);
    // console.log("ID COURSE", idCourse);
    // console.log("Grade", grade);
    // console.log('type', type);
    // console.log(whoAmI.role);
    // console.log('allDetails', allDetails);
    // console.log('tests', tests);
    // console.log('commentsArr', commentsArr);
    // console.log('HAAAAAAAAAAAAAAAAAAAAA', details);

    return (
        <WrapperRightSide>
            <NavBar />

            <TestWrapper user={(whoAmI.role !== 'STUDENTE') && true}>
                {(details.type !== details.svolgere[0] && details.type !== details.svolgere[5]) ?
                    <AllQuestionsSideBarForProfForDrawing
                        id={id}
                        setId={setId}
                        // arr={tests}
                        obj={obj}
                        grade={grade}
                        setGrade={setGrade}
                        inputFields={inputFields}
                        setInputFields={setInputFields}
                    />
                    :
                    <AllQuestionsSideBarForProfTPA
                        id={id}
                        setId={setId}
                        arr={JSON.parse(details.elem.questions)}
                        obj={done}
                        grade={grade}
                        setGrade={setGrade}
                        setArrOfComments={setArrOfComments}
                        doneAllObj={details.elem}
                    />
                }

                {details.type === details.svolgere[1] ?
                    details.fromNotifFlag !== undefined ?
                        <VerificationTestTracciaFromNotif
                            details={details}
                        />
                        :
                        <VerificationTestTraccia
                            details={details}
                        />
                    : null
                }

                {details.type === details.svolgere[2] ?
                    details.fromNotifFlag !== undefined ?
                        <VerificationTestScalettaFromNotif
                            details={details}
                        />
                        :
                        <VerificationTestScaletta
                            details={details}
                        />
                    : null
                }

                {details.type === details.svolgere[4] &&
                    <VerificationTestCoerenza
                        details={details}
                    />
                }

                {details.type === details.svolgere[3] ?
                    details.fromNotifFlag !== undefined ?
                        <VerificationTestRevisionFromNotif
                            details={details}
                        />
                        :
                        <VerificationTestRevision
                            details={details}
                        />
                    : null
                }

                {details.type === details.svolgere[5] &&
                    <VerificationTestRegistri
                        details={details}
                        id={id}
                        setId={setId}
                        done={done}
                    />
                }

                {details.type === details.svolgere[0] &&
                    <VerificationTestAvvio
                        details={details}
                        id={id}
                        setId={setId}
                        done={done}
                        obj={objAvvio}
                        setObj={setObjAvvio}
                    />
                }
            </TestWrapper>

            <FooterBarForProf
                submitTest={submitTest}
                grade={grade}
                loading={loading}
            />

            {/* {whoAmI.role === 'STUDENTE' ?
                // (type === 'wartegg' || type === 'test-grafici') ?
                //     <FooterBarForStud />
                //     :
                <FooterBar
                    id={id}
                    setId={setId}
                    // tests={tests}
                    submitTest={submitTest}
                    grade={grade}
                    loading={loading}
                />
                :
                <FooterBarForProf
                    // id={id}
                    // setId={setId}
                    // tests={tests}
                    submitTest={submitTest}
                    grade={grade}
                    loading={loading}
                />
            } */}
        </WrapperRightSide>
    );
}

export default TestPageWithTemaVerification;