import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

//PACKAGES
// import styled from 'styled-components';
// import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import _ from 'lodash';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringTestsDone } from '../../../Store/Actions/testActions';
import { bringTestsDoneForUsers } from '../../../Store/Actions/usersActions';
import { getAllSubjects } from '../../../Store/Actions/subjectsActions';


import {
    WrapperRightSide,
    TitleWrapper,
    Title,
    GoBack,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './/ExercisesTable';

// const options = [
//   { value: '5', label: '5' },
//   { value: '10', label: '10' },
//   { value: '15', label: '15' },
//   { value: '25', label: '25' },
//   { value: '50', label: '50' },
//   { value: '100', label: '100' },
// ];

const tableHead = [
    'Data',
    'Materia',
    'No. Domande',
    'Stato',
    'Azioni'
];

const tableHeadMisti = [
    'Data',
    'No. Domande',
    'Stato',
    'Azioni'
];

const tableHeadErrati = [
    'Materia',
    'No. Domande',

    'Azioni'
];
// let tests = [
//     {
//         idTests: 1,
//         data: '4/3/2021',
//         nr: '6/6',
//         status: 'Completo'
//     },
//     {
//         idTests: 2,
//         data: '5/3/2021',
//         nr: '1/3',
//         status: 'Incompleto'
//     },
//     {
//         idTests: 3,
//         data: '6/3/2021',
//         nr: '20/20',
//         status: 'Completo'
//     },
//     {
//         idTests: 4,
//         data: '7/3/2021',
//         nr: '8/15',
//         status: 'Incompleto'
//     },
// ];

const TestDetails = ({ details }) => {
    //console.log('DETAILS', details);
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const tests = useSelector(({ test }) => test.bringTestsDone);
    const subs = useSelector(({ subjects }) => subjects.allSubjects);

    // const pageSize = useSelector(({ other }) => other.setSize);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [type, setType] = useState([]);
    const [misti, setMisti] = useState([]);
    const [mono, setMono] = useState([]);

    // useEffect(() => {
    //   // dispatch(getServices());
    // }, [dispatch]);
    useEffect(() => {
        if (details.studDetails === undefined) {
            //console.log("DAAAAAA")
            dispatch(bringTestsDone(details.course));
            dispatch(getAllSubjects(details.course.idCourse, profile.idUser))
        } else {
            dispatch(bringTestsDoneForUsers(details.course));
            dispatch(getAllSubjects(details.course.idCourse, details.studDetails.idUsers))
        }
    }, [dispatch]);

    useEffect(() => {
        if (tests !== undefined && subs.length > 0) {
            if (profile.role === 'DOCENTE') {
                const min = tests.filter(test => test.quiz_type === 'misti');
                const mon = tests.filter(test => test.quiz_type === 'monomateria');
                //console.log("mon", mon)
                let newMono = [];
                for (let m = 0; m < mon.length; m++) {
                    // console.log("test", tests)
                    // console.log("SUBSSSSS", subs)
                    console.log(JSON.parse(mon[m].quiz_questions)[0].Courses_has_Subjects_Subjects_idSubject)
                    let find = subs.find(el => el.idSubject === Number(JSON.parse(mon[m].quiz_questions)[0].Courses_has_Subjects_Subjects_idSubject));
                    console.log("find", find)
                    let skills = JSON.parse(profile.skills);
                    skills.forEach(sk => {
                        if (sk.includes(find.area)) {
                            newMono.push(mon[m])
                        }
                    });


                    // console.log("FIND", find)
                }
                setMisti(min);
                setMono(newMono);

            } else {
                const min = tests.filter(test => test.quiz_type === 'misti');
                const mon = tests.filter(test => test.quiz_type === 'monomateria');
                setMisti(min);
                setMono(mon);
            }

        }
    }, [tests, subs]);

    // useEffect(() => {
    //     if (details.studDetails === undefined) {
    //         dispatch(bringTestsDone(details.course));
    //     } else {
    //         dispatch(bringTestsDoneForUsers(details.course));
    //         dispatch(getAllSubjects(details.course.idCourse, details.studDetails.idUsers))
    //     }
    // }, [dispatch]);


    //console.log("PROFILE", profile);
    // console.log("test", tests)
    // console.log('EXERCISES DETAILS', details);
    // console.log('MONO', mono);

    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                {/* <Title>Esercizi</Title> */}

                <Title>{details.type}</Title>
                {/* <SubTitle>{desc}</SubTitle> */}
            </TitleWrapper>

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    // type={details.arr}
                    type={
                        (details.type === 'Quiz errati' && details.arr) ||
                        (details.type === 'Quiz monomateria' && mono) ||
                        (details.type === 'Quiz misti' && misti)
                    }
                    flag={'quiz'}
                    setType={setType}
                />

            </ActionsWrapperSpace>

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <Btn
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/courses/newCourse'>
                        <i className="fas fa-plus"></i>Utenti
                    </Btn>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 300px)' }}>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono test da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono test da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                {
                                    details.type === 'Quiz monomateria' ?

                                        <tr >
                                            {tableHead.map((th, i) =>
                                                <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                            )}
                                        </tr> :
                                        details.type === 'Quiz errati' ?
                                            <tr >
                                                {tableHeadErrati.map((th, i) =>
                                                    <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                                )}
                                            </tr> :
                                            <tr >
                                                {tableHeadMisti.map((th, i) =>
                                                    <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                                )}
                                            </tr>
                                }

                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                            type={details.type}
                                            user={details.studDetails ? details.studDetails.idUsers : profile.idUser}
                                            course={details.course.idCourse}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                // obj={details.arr}
                obj={(details.type === 'Quiz errati' && details.arr) ||
                    (details.type === 'Quiz monomateria' && mono) ||
                    (details.type === 'Quiz misti' && misti)
                }
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default TestDetails;


