import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import EserciziCard from '../../GeneralComponents/Cards/EserciziCardNew';
import TemaCard from '../../GeneralComponents/Cards/TemaCard';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringTestsDone, bringSimulationsDone, getAllWrongQuezzes } from '../../../Store/Actions/testActions';
import { getAllTemaTestDone } from '../../../Store/Actions/temaActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    TestWrapper,
    GoBack,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const CardsWrapper = styled.div`
    width:100%;
    padding: 20px 0;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: flex-start;
`;

const SubTitle = styled.div`
   color:  var(--text5);
   font-size: 22px;
   text-align: center;
   background: linear-gradient(90deg,rgba(240,242,245,1) 0%,rgba(119,123,141,0.9500175070028011) 30%,rgba(108,113,132,1) 50%,rgba(96,101,122,0.8855917366946778) 70%,rgba(240,242,245,1) 100%);
   margin-bottom: 10px;
        >i{
            margin-left: 15px;
            font-size: 15px;
            cursor: pointer;
        }
`;


const TemaPreMainPage = ({ details }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(({ auth }) => auth.user);
    const temaDone = useSelector(({ tema }) => tema.allTraceDone);
    const [openSvolti, setOpenSvolti] = useState(false);
    const [openSvolgere, setOpenSvolgere] = useState(false);
    const [getDone, setGetDone] = useState([]);

    // const svolgere = [
    //     'Analisi della traccia',
    //     'Elaborazione del Testo o Scaletta',
    //     'Coerenza e stesura del testo',
    //     'Stesura e revisione del tema (BASE)',
    //     'Registri linguistici',
    //     'Avvio alla scrittura',
    // ];
    const svolgere = [
        'Avvio alla scrittura',
        'Analisi della traccia',
        'Elaborazione del Testo o Scaletta',
        'Stesura e revisione del tema',
        'Coerenza e stesura del testo',
        'Registri linguistici',

    ];


    useEffect(() => {
        dispatch(getAllTemaTestDone(auth.idUser, details.course));
    }, [dispatch]);

    console.log("TEMA DONE", temaDone)
    // useEffect(() => {
    //     if (temaDone !== undefined) {
    //         let dones = [];
    //         for (let i = 0; i < temaDone.length; i++) {
    //             if (temaDone[i].flag === 'traceAnalysis') {
    //                 dones.push(svolgere[0]);
    //             }
    //             if (temaDone[i].flag === 'textProcessing') {
    //                 dones.push(svolgere[1]);
    //             }
    //             if (temaDone[i].flag === 'consistency') {
    //                 dones.push(svolgere[2]);
    //             }
    //             if (temaDone[i].flag === 'revisione') {
    //                 dones.push(svolgere[3]);
    //             }
    //             if (temaDone[i].flag === 'registri') {
    //                 dones.push(svolgere[4]);
    //             }
    //             if (temaDone[i].flag === 'avvio') {
    //                 dones.push(svolgere[5]);
    //             }
    //         }
    //         let uniqueChars = [...new Set(dones)];
    //         setGetDone(uniqueChars);
    //     };
    // }, [temaDone]);
    useEffect(() => {
        if (temaDone !== undefined) {
            let dones = [];
            for (let i = 0; i < temaDone.length; i++) {
                if (temaDone[i].flag === 'avvio') {
                    dones.push(svolgere[0]);
                }
                if (temaDone[i].flag === 'traceAnalysis') {
                    dones.push(svolgere[1]);
                }
                if (temaDone[i].flag === 'textProcessing') {
                    dones.push(svolgere[2]);
                }
                if (temaDone[i].flag === 'revisione') {
                    dones.push(svolgere[3]);
                }
                if (temaDone[i].flag === 'consistency') {
                    dones.push(svolgere[4]);
                }
                if (temaDone[i].flag === 'registri') {
                    dones.push(svolgere[5]);
                }

            }
            let uniqueChars = [...new Set(dones)];
            setGetDone(uniqueChars);
        };
    }, [temaDone]);

    //console.log('TemaPreMainPage', details);
    console.log('TEMA DONE', getDone);


    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                {/* <Title>{details.title} / <span>{details.course.course_code}</span></Title> */}
                <Title>{details.title} / {details.course.course_code}</Title>
            </TitleWrapper>

            <div style={{ marginTop: '20px' }}>
                <SubTitle
                    onClick={() => setOpenSvolgere(!openSvolgere)}
                >
                    Test da Svolgere
                    {openSvolgere === false ?
                        <i className="fa-solid fa-chevron-down"></i>
                        :
                        <i className="fa-solid fa-chevron-up"></i>
                    }
                </SubTitle>

                {!openSvolgere &&
                    <CardsWrapper>
                        {svolgere !== undefined &&
                            svolgere.map((type, i) =>
                                <TemaCard
                                    key={i}
                                    type={type}
                                    course={details.course}
                                    svolgere={svolgere}
                                />
                            )}
                    </CardsWrapper>
                }

                <SubTitle onClick={() => setOpenSvolti(!openSvolti)}>
                    Test Svolti
                    {openSvolti === false ?
                        <i className="fa-solid fa-chevron-down"></i>
                        :
                        <i className="fa-solid fa-chevron-up"></i>
                    }
                </SubTitle>

                {!openSvolti &&
                    <CardsWrapper>
                        {temaDone !== undefined &&
                            // temaDone.map((type, i) =>
                            //     <TemaCard
                            //         key={i}
                            //         // type={type.type.toUpperCase().replace('-', ' ')}
                            //         type={type.flag === 'traceAnalysis' && svolgere[0]}
                            //         course={details.course}
                            //         done={type.done}
                            //         flag='svolti'
                            //     />
                            // )}

                            getDone.map((type, i) =>
                                <TemaCard
                                    key={i}
                                    // type={type.type.toUpperCase().replace('-', ' ')}
                                    type={type}
                                    course={details.course}
                                    done={type.done}
                                    svolgere={svolgere}
                                    flag='svolti'
                                />
                            )}
                    </CardsWrapper>
                }
            </div>

        </WrapperRightSide>
    );
}

export default TemaPreMainPage;