import React, { useState } from 'react';

// COMPONENTS
import AdminNav from '../AuthComponents/AuthNav';
// import AuthFooter from '../AuthComponents/AuthFooter';
import Checkbox from '../AuthComponents/Checkbox';

// ASSETS
import spin from '../../Assets/loadingBtn2.gif';
import avatar from '../../Assets/avatar.png';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Store/Actions/authActions';

// SHARE STYLE
import {
    AuthWrapper,
    FormWrapper,
    H1,
    // Hr,
    InputWrapper,
    PswWrapper,
    EyeWrapper,
    // LabelCss,
    InputCss,
    Label,
    CheckTxt,
    BtnWrapper,
    Btn,
    AvatarWrapper,
    Avatar,
    LinkCss,
    LinksDiv
} from '../ShareStyle/ShareStyle';


const LogIn = (props) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.auth.authLoading);
    const [showPassword, setShowPassword] = useState(true);
    const [access, setAccess] = useState({
        email: '',
        psw: '',
    });
    const [checked, setChecked] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleCheckboxChange = event => {
        setChecked(event.target.checked)
    };

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(loginUser(access, props.id));
    };

    // console.log('SIGN IN PROPS', props)

    return (
        <AuthWrapper>
            <AdminNav />

            <FormWrapper onSubmit={submitForm}>
                <H1>Log In</H1>

                <AvatarWrapper>
                    <Avatar>
                        <img src={avatar} alt='logo'></img>
                    </Avatar>
                </AvatarWrapper>

                <InputWrapper>
                    {/* <LabelCss htmlFor="email">Utente</LabelCss> */}
                    <InputCss
                        placeholder="Inserisci la tua email"
                        type="text"
                        name='email'
                        required
                        value={access.email}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                email: e.target.value
                            })
                        }
                    >
                    </InputCss>
                    <i className="fas fa-user"></i>
                </InputWrapper>

                <PswWrapper>
                    {/* <LabelCss htmlFor='psw'>Password</LabelCss> */}
                    <InputCss
                        placeholder="Inserisci la tua password"
                        type={showPassword ? 'password' : 'text'}
                        name='password'
                        required
                        value={access.psw}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                psw: e.target.value
                            })
                        }
                    ></InputCss>
                    <EyeWrapper onClick={() => handleShowPassword()}>
                        {showPassword ?
                            <i className="far fa-eye-slash"></i>
                            :
                            <i className="far fa-eye"></i>
                        }
                    </EyeWrapper>
                    <i className="fas fa-key"></i>
                </PswWrapper>


                <Label>
                    <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                    />
                    <CheckTxt>Ricordati di me</CheckTxt>
                </Label>


                <BtnWrapper>
                    {loading ?
                        <Btn disabled>
                            <img src={spin} alt='loading'></img>
                            Loading...
                        </Btn>
                        :
                        <Btn type='submit'>Accesso</Btn>
                    }
                </BtnWrapper>

                <LinksDiv>
                    <LinkCss to='/set-psw'>Reimposta password</LinkCss >
                </LinksDiv>
            </FormWrapper>

            {/* <AuthFooter /> */}
        </AuthWrapper>
    );
}
export default LogIn;
