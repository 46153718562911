import React, { memo, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';

// PACKAGES
import styled from 'styled-components';

// MIDDLEWARES
import {
    NonPrivateRoute,
    PrivateRoute,
    OtherNonPrivateRoute
} from './Utils';

// REDUX
import { useSelector } from 'react-redux';

// PAGES
import LogIn from '../Auth/LogIn/LogIn';
import SetPassword from '../Auth/SetPassword/SetPassword';
import Profile from '../Dashboard/Pages/Profile/Profile';
import MainPage from '../Dashboard/Pages/MainPage/MainPage';
import LessonMainPage from '../Dashboard/Pages/LessonPage/LessonMainPage';
import TypePage from '../Dashboard/Pages/TypePage/TypePage';
import LearningPage from '../Dashboard/Pages/LearningPage/LearningPage';
import ExercisesPage from '../Dashboard/Pages/ExercisesPage/ExercisesPage';
import ViewExercises from '../Dashboard/Pages/ExercisesPage/ViewExercises';
import CreateYourMixtQuiz from '../Dashboard/Pages/QuizPage/CreateYourMixtQuiz';
import CreateYourMonoQuiz from '../Dashboard/Pages/QuizPage/CreateYourMonoQuiz';
import PreTest from '../Dashboard/Pages/TestPage/PreTest';
import PreSimulation from '../Dashboard/Pages/TestPage/PreSimulation';
import MainTestPage from '../Dashboard/Pages/TestPage/MainTestPage';
import MainTestPageForExercises from '../Dashboard/Pages/TestPage/MainTestPageForExercises';
import MainTestPageForSimulations from '../Dashboard/Pages/TestPage/MainTestPageForSimulations';
import ViewSimulation from '../Dashboard/Pages/ExercisesPage/ViewSimulation';
import Users from '../Dashboard/Pages/Users/Users';
import Students from '../Dashboard/Pages/Users/Students';
import NewUser from '../Dashboard/Pages/Users/NewUser';
import EditUser from '../Dashboard/Pages/Users/EditUsers';
import Teachers from '../Dashboard/Pages/Center/Teachers';
import Centers from '../Dashboard/Pages/Center/Centers';
import NewTeacher from '../Dashboard/Pages/Center/NewTeacher';
import EditTeacher from '../Dashboard/Pages/Center/EditTeacher';
import TeacherTestDetails from '../Dashboard/Pages/Center/TestDetails';
import TeacherTestTypeDetails from '../Dashboard/Pages/Center/TestTypeDetails';
import ViewTestsTable from '../Dashboard/Pages/Center/ViewTests';
import ViewTests from '../Dashboard/Pages/Center/TpaTestVerificationPageView';
import Results from '../Dashboard/Pages/Users/Results';
import TestDetails from '../Dashboard/Pages/Users/TestDetails';
import TestVerification from '../Dashboard/Pages/TestPage/TestVerification/MainTestVerificationPage';
import SimulationVerification from '../Dashboard/Pages/TestPage/SimulationVerification/MainSimulationVerificationPage';
import WrongQuezzes from '../Dashboard/Pages/WrongQuezzesPage/ViewWrongQuezzes';
import PreWrong from '../Dashboard/Pages/WrongQuezzesPage/WrongPreTest';
import TestPageForWrong from '../Dashboard/Pages/WrongQuezzesPage/MainTestPageForWrong';
import PreQuest from '../Dashboard/Pages/LessonPage/Quest/PreQuest';
import MainTestPageForQuests from '../Dashboard/Pages/LessonPage/Quest/MainTestPageForQuests';
import MainQuestVerificationPage from '../Dashboard/Pages/LessonPage/Quest/QuestVerification/MainQuestVerificationPage';
import TypePageForProf from '../Dashboard/Pages/Users/TypePageForProf';
import ExercisesPageForProf from '../Dashboard/Pages/Users/ExercisesPageForProf';
import TpaMainPage from '../Dashboard/Pages/TPA/TpaMainPage';
import PreTpa from '../Dashboard/Pages/TPA/PreTpa';
import Grafici from '../Dashboard/Pages/TPA/MainTpaTestPage';
import TpaLearningPage from '../Dashboard/Pages/TPA/TpaLearning/TpaLearningPage';
//import TpaSwitchTest from '../Dashboard/Pages/TPA/TpaSwitchTest';
import TpaLessonPage from '../Dashboard/Pages/TPA/TpaLearning/TpaLessonPage';
// import MainTpaTestVerificationPage from '../Dashboard/Pages/TestPage/DrawingTest/DrawingVerification/MainTpaTestPageVerification';
import TpaPreMainPage from '../Dashboard/Pages/TPA/TpaPreMainPage';
import TpaPreMainPageForProf from '../Dashboard/Pages/TPA/TpaPreMainPageForProf';
import TpaExercisesDone from '../Dashboard/Pages/TPA/TpaExercisesDone';
import TpaTestVerification from '../Dashboard/Pages/TPA/TestVerification/TpaTestVerification';
import ReadOnlyDrawingTest from '../Dashboard/Pages/TestPage/DrawingTest/DrawingVerification/ReadOnlyDrawingTest';
import Overview from '../Dashboard/Pages/Profile/Overview';

//ORALE
import OraleLearningPage from '../Dashboard/Pages/Orale/OraleLearningPage';
import OraleLessonMainPage from '../Dashboard/Pages/Orale/OralLessonMainPage';
import OraleLessonPage from '../Dashboard/Pages/Orale/LessonPage/OraleLessonPage';
import OralPreQuest from '../Dashboard/Pages/Orale/LessonPage/Quest/OralPreQuest';
import OraleMainTestPageForQuests from '../Dashboard/Pages/Orale/LessonPage/Quest/OralMainTestPageForQuests';
//PHYSICAL
import PhysicalLearningPage from '../Dashboard/Pages/Physical/PhysicalLearningPage';
import PhysicalLessonMainPage from '../Dashboard/Pages/Physical/PhysicalLessonMainPage';
import PhysicalLessonPage from '../Dashboard/Pages/Physical/LessonPage/PhysicalLessonPage';
import PhysicalPreQuest from '../Dashboard/Pages/Physical/LessonPage/Quest/PhysicalPreQuest';
import PhysicalMainTestPageForQuests from '../Dashboard/Pages/Physical/LessonPage/Quest/PhysicalMainTestPageForQuests'
// TEMA
import TemaPreMainPage from '../Dashboard/Pages/Tema/TemaPreMainPage';
import PreTema from '../Dashboard/Pages/Tema/PreTema';
import TemaTests from '../Dashboard/Pages/Tema/MainTemaTestPage';
import TemaLearningPage from '../Dashboard/Pages/Tema/TemaLearning/TemaLearningPage';
import TemaLessonMainPage from '../Dashboard/Pages/Tema/TemaLearning/TemaLessonMainPage';
import TemaMainTestPageForQuests from '../Dashboard/Pages/Tema/TemaLearning/LessonPage/Quest/TemaMainTestPageForQuests';
import TemaPreQuest from '../Dashboard/Pages/Tema/TemaLearning/LessonPage/Quest/TemaPreQuest';
import TemaExercisesDone from '../Dashboard/Pages/Tema/TemaExercisesDone';
import ReadOnlyTemaTest from '../Dashboard/Pages/Tema/TemaVerification/ForStudents/ReadOnlyTemaTest';
import TemaPreMainPageForProf from '../Dashboard/Pages/Tema/TemaVerification/ForProf/TemaPreMainPageForProf';
import TestPageWithTemaVerification from '../Dashboard/Pages/Tema/TemaVerification/ForProf/TestPageWithTemaVerification';

// COMPONENTS
import NavBar from '../Dashboard/GeneralComponents/NavBar/NavBar';
import SideBar from '../Dashboard/GeneralComponents/SideBar/SideBar';
// import SideBarCalendar from '../Dashboard/GeneralComponents/SideBarCalendar/SideBarCalendar';
import CustomNotificationHandler from '../Dashboard/GeneralComponents/CustomNotificationHandler/CustomNotificationHandler';
import Footer from '../Dashboard/GeneralComponents/Footer/Footer';
import Messenger from '../Dashboard/Pages/Messenger/Messenger';
import Notifications from '../Dashboard/Pages/Notifications/NotificationsPage';
import EducationalMaterials from '../Dashboard/Pages/Educational/EducationalMaterials';


const GeneralWrapper = styled.div`
    background: var(--background2);
`;

const WrapperRightSide = styled.div`
    width:calc(100% - 250px);
    height:calc(100vh - 80px);
    margin-left:250px;
    margin-top: 80px;
    padding:40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
        @media (max-width: 1100px) {
            width:100%;
            margin-left:0;
        };
`;

const Routes = memo(function Routes() {
    const location = useLocation();
    const params = useParams();
    const isAuthentificated = useSelector(({ auth }) => auth.isAuthentificated);
    const user = useSelector(({ auth }) => auth.user)
    // console.log('AUTH UTILS', isAuthentificated);

    const NonPrivate = [
        {
            exact: true,
            path: '/',
            component: <LogIn />
        },
        {
            path: '/login',
            component: <LogIn />
        },
        {
            path: '/set-psw',
            component: <SetPassword />
        },
        {
            path: '/dashboard',
            component: <LogIn />
        },
    ];

    const Private = [
        {
            exact: true,
            path: '/',
            // component: user !== undefined &&
            //     user.role === 'STUDENTE' ? <MainPage /> : <Students />
            component:
                user !== undefined ?
                    user.role === 'STUDENTE' && <MainPage /> ||
                    user.role !== 'STUDENTE' && <Students />
                    :
                    null
        },
        {
            exact: true,
            path: '/dashboard',
            component: user !== undefined &&
                user.role === 'STUDENTE' ? <MainPage /> : <Students />
        },
        {
            // exact: true,
            path: '/dashboard/courses',
            component: <MainPage />
        },
        {
            // exact: true,
            path: '/dashboard/educational-materials',
            component: <EducationalMaterials />
        },
        {
            exact: true,
            path: '/dashboard/profile',
            component: <Profile />
        },
        {
            // exact: true,
            path: '/dashboard/profile/overview',
            component: <Overview details={location.state} />
        },
        {
            // exact: true,
            path: '/dashboard/lesson',
            component: <LessonMainPage details={location.state} />
        },
        {
            // exact: true,
            path: '/dashboard/type',
            component: <TypePage details={location.state} />
        },
        {
            // exact: true,
            path: '/dashboard/learning',
            component: <LearningPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/exercises',
            component: <ExercisesPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/exercises/view-exercises',
            component: <ViewExercises details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-mixt-quiz',
            component: <CreateYourMixtQuiz details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-mono-quiz',
            component: <CreateYourMonoQuiz details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-quiz/pre-test',
            component: <PreTest details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-quiz/test/:id',
            component: <MainTestPageForExercises details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-simulation',
            component: <PreSimulation details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-quiz/simulation/:id',
            component: <MainTestPageForSimulations details={location.state} />
        },
        // {
        //     exact: true,
        //     path: '/dashboard/create-quiz/simulation/:id',
        //     component: <MainTestPage details={location.state} />
        // },
        {
            exact: true,
            path: '/dashboard/exercises/view-simulations',
            component: <ViewSimulation details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/users',
            component: <Users details={location.state} />
        },
        ///dashboard/students
        {
            exact: true,
            path: '/dashboard/students',
            component: <Students details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/users/newUser',
            component: <NewUser />
        },
        {
            exact: true,
            path: '/dashboard/users/editUser',
            component: <EditUser details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/teachers',
            component: <Teachers details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/teachers/newTeacher',
            component: <NewTeacher />
        },
        {
            exact: true,
            path: '/dashboard/teachers/editTeacher',
            component: <EditTeacher details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/teachers/details',
            component: <TeacherTestDetails details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/teachers/type-details',
            component: <TeacherTestTypeDetails details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/teachers/view-verified-tests',
            component: <ViewTestsTable details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/teachers/view',
            component: <ViewTests details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/centers',
            component: <Centers details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/students/results',
            component: <Results details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/students/student-types',
            component: <TypePageForProf details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/prof-exercises',
            component: <ExercisesPageForProf details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/users/tests-details',
            component: <TestDetails details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/test-verification',
            component: <TestVerification details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/simulation-verification',
            component: <SimulationVerification details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/wrong-quezzes',
            component: <WrongQuezzes details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-quiz/pre-wrong',
            component: <PreWrong details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/create-quiz/wrong',
            component: <TestPageForWrong details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/pre-quest',
            component: <PreQuest details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/quest',
            component: <MainTestPageForQuests details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/quest-verification',
            component: <MainQuestVerificationPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-pre-exercises',
            component: <TpaPreMainPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-pre-ex-prof',
            component: <TpaPreMainPageForProf details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-exercises',
            component: <TpaMainPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-exercises/pre-tpa',
            component: <PreTpa details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-exercises/done-tpa',
            component: <TpaExercisesDone details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-test-verification',
            component: <TpaTestVerification details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-grafici/:type',
            component: <Grafici details={location.state} />
        },
        {
            // exact: true,
            path: '/dashboard/tpa-learning',
            component: <TpaLearningPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-bool/:type',
            component: <Grafici details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-free/:type',
            component: <Grafici details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-one/:type',
            component: <Grafici details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-multiple/:type',
            component: <Grafici details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tpa-lesson',
            component: <TpaLessonPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/view-tpa-test-done',
            component: <ReadOnlyDrawingTest details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/messenger',
            component: <Messenger details={location.state} />
        },

        //ORALE
        {
            // exact: true,
            path: '/dashboard/orale-learning',
            component: <OraleLearningPage details={location.state} />
        },
        {
            // exact: true,
            path: '/dashboard/prova-orale/lesson',
            component: <OraleLessonMainPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/prova-orale/quest',
            component: <OraleMainTestPageForQuests details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/prova-orale/pre-quest',
            component: <OralPreQuest details={location.state} />
        },

        //PHYSICAL
        {
            path: '/dashboard/physical-learning',
            component: <PhysicalLearningPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/prova-physical/lesson',
            component: <PhysicalLessonMainPage details={location.state} />
        },
        {
            path: '/dashboard/prova-physical/quest',
            component: <PhysicalMainTestPageForQuests details={location.state} />
        },
        {
            path: '/dashboard/prova-physical/pre-quest',
            component: <PhysicalPreQuest details={location.state} />
        },
        {
            path: '/dashboard/notifications',
            component: <Notifications details={location.state} />
        },

        //TEMA
        {
            exact: true,
            path: '/dashboard/tema-pre-exercises',
            component: <TemaPreMainPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tema-exercises/pre-tema',
            component: <PreTema details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tema-test/:type',
            component: <TemaTests details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tema-learning',
            component: <TemaLearningPage details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/prova-tema/lesson',
            component: <TemaLessonMainPage details={location.state} />
        },
        {
            path: '/dashboard/prova-tema/pre-quest',
            component: <TemaPreQuest details={location.state} />
        },
        {
            path: '/dashboard/prova-tema/quest',
            component: <TemaMainTestPageForQuests details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tema-exercises/done-tema',
            component: <TemaExercisesDone details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/view-tema-test-done',
            component: <ReadOnlyTemaTest details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tema-pre-ex-prof',
            component: <TemaPreMainPageForProf details={location.state} />
        },
        {
            exact: true,
            path: '/dashboard/tema-test-verification',
            component: <TestPageWithTemaVerification details={location.state} />
        },
    ];

    if (!isAuthentificated) {
        return (
            <Switch>
                {NonPrivate.map((route, i) =>
                    <NonPrivateRoute exact={route.exact} key={i} path={route.path}>
                        {route.component}
                    </NonPrivateRoute>
                )}
                {/* {NonPrivate.map((route, i) =>
                       <OtherNonPrivateRoute exact={route.exact} key={i} path={route.path}>
                             {route.component}
                         </OtherNonPrivateRoute>
                )} */}
            </Switch>
        )
    } else {
        return (
            <Switch>
                {/* {NonPrivate.map((route, i) =>
                    <NonPrivateRoute exact={route.exact} key={i} path={route.path}>
                        {route.component}
                    </NonPrivateRoute>
                )} */}

                <Route>
                    <NavBar />
                    <SideBar />
                    {/* <SideBarCalendar />
                    <CustomNotificationHandler /> */}

                    <Switch>

                        {Private.map((route, i) =>
                            <PrivateRoute exact={route.exact} key={i} path={route.path}>
                                {route.component}
                            </PrivateRoute>
                        )}

                        <Route path="*">
                            <GeneralWrapper>
                                <WrapperRightSide>
                                    <h1 style={{ fontSize: "200px", color: 'var(--text2)' }}> 404 </h1><br></br>
                                    <h1 style={{ fontSize: "20px", color: 'var(--text2)' }}> Page not found </h1><br></br>
                                </WrapperRightSide>
                            </GeneralWrapper>
                        </Route>
                    </Switch>

                    {/* <Footer /> */}
                    {(location.pathname === '/dashboard/create-quiz/simulation/' + location.pathname.split('/').pop()) ||
                        (location.pathname === '/dashboard/create-quiz/test/' + location.pathname.split('/').pop()) ||
                        (location.pathname === '/dashboard/tpa-bool/' + location.pathname.split('/').pop()) ||
                        (location.pathname === '/dashboard/tpa-multiple/' + location.pathname.split('/').pop()) ||
                        (location.pathname === '/dashboard/tpa-free/' + location.pathname.split('/').pop()) ||
                        (location.pathname === '/dashboard/tpa-test-verification') ||
                        (location.pathname === '/dashboard/test-verification') ||
                        (location.pathname === '/dashboard/create-quiz/wrong') ||
                        (location.pathname === '/dashboard/simulation-verification') ||
                        (location.pathname === '/dashboard/quest') ||
                        (location.pathname === '/dashboard/quest-verification') ||
                        (location.pathname === '/dashboard/teachers/view') ||
                        (location.pathname === '/dashboard/messenger') ||
                        (location.pathname === '/dashboard/notifications') ||
                        (location.pathname === '/dashboard/prova-physical/quest') ||
                        (location.pathname === '/dashboard/tema-test-verification') ||
                        (location.pathname === '/dashboard/tema-test/' + location.pathname.split('/').pop())
                        ? null : <Footer />}
                </Route>
            </Switch>
        );
    }

});

export default Routes;
