import { Link } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';


export const AuthWrapper = styled.div`
    width: 100%;
    min-height:100vh;
    /* background: var(--backLanding); */
    background: #E7E7E7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FormWrapper = styled.form`
    width: 450px;
    /* margin-top: 8%; */
    background:var(--background1);
    padding: 20px 40px;
    border-radius: var(--radius);
    backdrop-filter: var(--blur);
`;

export const H1 = styled.h1`
    width:100%;
    text-align: center;
    color: var(--text5);
`;
export const H2 = styled.h2`
    width:100%;
    text-align: center;
    color: var(--text5);
`;

export const Hr = styled.hr`
    width:100%;
    margin:auto;
    margin-top: 10px;
    border:none;
    border-bottom:1px solid var(--color2);
`
export const AvatarWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;

export const IconWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
        >i{
            font-size: 100px;
            color: var(--text5);
        }
`;

export const Avatar = styled.div`
    position:relative;
    width: 120px;
    height: 120px;
    border-radius: 120px;
    background-color: var(--background2);
    overflow: hidden;
        >img{
            position:absolute;
            top:55%;
            left:50%;
            transform:translate(-50%, -50%);
            width:90%;
            height: 90%;
        }
`;

export const H5 = styled.h5`
    width:100%;
    color: var(--text5);
    margin: 20px 0px 0;
    text-align: right;
`;

export const PswWrapper = styled.div`
   position:relative;
        >i{
            color: var(--text5);
            position: absolute;
            top: 35px;
            left: 10px;
        }
`;

export const EyeWrapper = styled.div`
   position:absolute;
   right: 15px;
   top: 35px;
   color: var(--text4);
   cursor: pointer;
`;

export const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 10px;
    color: var(--text5);
`;

export const InputWrapper = styled.label`
    position:relative;
	display: block;
    width: 100%;
        @media (max-width: 775px) {
            width: 100%;
        };
        >i{
            position: absolute;
            top: 35px;
            left: 10px;
        }
`;

export const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 12px 6px 50px;
    margin-top:20px;
    border-bottom: 1px solid var(--text4)!important;
    border: none;
    /* border-radius: var(--radius); */
    background: transparent;
    color: var(--text5);
    transition: var(--transition) ease;
    /* box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important; */
        &::placeholder { 
            color: var(--text4);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline:none;
            border-bottom: 1px solid var(--acc-color)!important;
                ~i{
                    color:var(--acc-color)!important;
                    transition: color var(--transition) ease-in-out;
                }
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            /* -webkit-box-shadow: 0 0 0px 1000px #fff inset; */
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

export const Label = styled.label`
    display:flex;
    align-items: center;
    color:var(--text5);
    margin:20px 10px 0;
`;

export const CheckTxt = styled.span`
    margin-left:18px;
    >a{
        text-decoration: none;
        margin-left: 5px;
        color: var(--text5);
        transition: var(--transition) ease;
        font-weight: bold;
            &:hover{
                /* color: #5e72e4;  */
                text-decoration: underline;
            }
    }
`;

export const BtnWrapper = styled.div`
    width: 100%;
`;

export const Btn = styled.button`
    width: 100%;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    border-radius: var(--radius);
    margin-top: 40px;
    margin-bottom:20px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    background: ${props => props.type === 'submit' ? 'var(--acc-color)' : 'var(--background3)'};
    border: none;
    outline:none;
        >img{
            width:40px;
            margin-right: 10px;
        }
        &:hover{
            filter: brightness(0.9);
            color:var(--text5)
        }
`;

export const LinkCss = styled(Link)`
    text-decoration: none;
    color: var(--text5);
    /* margin-left: 5px; */
    /* font-weight: bold; */
        &:hover{
            text-decoration: underline;
        }
`;

export const LinksDiv = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    display:flex;
    align-items: center;
    justify-content: flex-end;
`;

export const Err = styled.div`
    border: none;
    color: var(--text5); 
    opacity: 1;
    font-size: var(--fontM);
        >span{
            padding: 2px 10px;
            background: var(--warning);
            border-radius: 5px;
        }
`;