import React from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Time from './Time';

// REDUX
// import { useSelector } from 'react-redux';

const BarWrapper = styled.div`
    position: fixed;
    top: 80px;
    left: 0px;
    z-index: 1;
    margin-left: 250px;
    width: calc(100% - 250px);
    height: 80px;
    background-color: var(--background4);
    padding: 5px;
    /* border-radius: var(--radius); */
    display: flex;
    align-items: center;
    justify-content: center;
        @media (max-width: 1100px) {
            margin-left: 0px;
            width: 100%;
        };

`;

const Elem = styled.div`
   color:  var(--text2);
   font-size: 14px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
   border-left: 1px solid var(--text4);
   border-right: 1px solid var(--text4);
        >span{
            font-size: 22px;
        }
`;

const GoBack = styled.div`
    position: absolute;
    left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: var(--text3);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: var(--background4);
    /* min-width: 100px; */
    /* border-radius: var(--radius); */
    box-shadow: var(--shadow);
    /* padding: 10px; */
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
`;


const NavBar = ({ total, rest, flagExercises, time }) => {
    const history = useHistory();

    return (
        <BarWrapper>
            <GoBack onClick={() => history.goBack()}>
                <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
            </GoBack>

            {/* <Elem>Domande totali<span>{total}</span></Elem>

            {flagExercises === undefined &&
                <Time
                    time={time}
                />
            }

            <Elem>Domande restanti<span>{total - rest}</span></Elem> */}
        </BarWrapper>
    );
};

export default NavBar;