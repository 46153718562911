import React, { useLayoutEffect, useState } from 'react';
import { NavLink } from "react-router-dom";

//STYLED COMPONENTS
import styled from 'styled-components';
import { List, Icon } from './SharedStyle/Styles.css';

// ASSETS
import logo from '../../../Assets/logo-nissolino.png';

// COMPONENTS
// import SideBarButton from './SideBarComponents/SideBarNavLinks';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { handleSideBar } from '../../../Store/Actions/otherActions';


const NegativSidebar = styled.div`
    z-index: 2;
    position: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: auto;
    opacity: ${({ open }) => open ? '1' : '0'};
    transition: opacity 224ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(175 175 175 / 50%);
    backdrop-filter: var(--blur);
        @media (max-width: 1100px) {
            pointer-events: ${({ open }) => open ? 'auto' : 'none'};
        };
        @media (min-width: 1100px) {
                background-color: none;
                pointer-events: none;
        };
`;

const SideBarBody = styled.div`
    position: fixed;
    /* top: 80px; */
    top: 0px;
    width:250px;
    /* height:calc(100vh - 80px); */
    height:100vh;
    background: var(--background4);
    display:block;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
        @media (max-width: 1100px) {
            transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(-100%)'};
            box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 
            0 4px 25px 0 rgba(0, 0, 0, .12), 
            0 8px 10px -5px rgba(0, 0, 0, .2);
        };
`;

const LogoWrapper = styled.div`
    width:100%;
    padding:10px;
    display:flex;
    align-items:center;
    justify-content:center;   
`;

const Logo = styled.img`
    /* height:100px; */
    width:200px;
`;

const UnorderList = styled.ul`
    height:calc(100vh - 80px);
    list-style:none;
    padding: 30px 0;
    display:block;
    overflow: auto;
        &::-webkit-scrollbar {
            width: 5px;
        }
`;

const activeClassName = 'nav-item-active'
const NavLinks = styled(NavLink).attrs({ activeClassName })`
    position: relative;
    width:100%;
    color: var(--text2);
    padding: 0px 20px;
    height: 55px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content:left;
    /* border-bottom:1px solid rgba(250, 250, 250, 0.1); */
    transition: var(--transition) ease;
    color: var(--text5);
        &:hover {
        background: #797979;
        color: var(--text5)!important;
        }
        &.${activeClassName} {
            background: var(--acc-color);
            color: var(--text5)!important;
        }
`;

const NavBtn = styled.div`
        position:relative;
        width:100%;
        color: var(--text2);
        padding: 0px 20px;
        height: 55px;
        display:flex;
        align-items: center;
        justify-content:left;
        /* border-bottom:1px solid rgba(250, 250, 250, 0.1); */
        transition: var(--transition) ease;
        cursor:pointer;
        /* background: ${props => props.open && 'var(--hover)'}; */
            &:hover {
                background: var(--background3);
                /* color: var(--text5); */
                }
`;

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--background1);
    overflow: hidden;
    height: ${props => props.opened === 'true' ? (props.tall * 55) : 0}px;
    transition: all 0.4s ease;
`;

const SubSubMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;;
    background: var(--background4);
    overflow: hidden;
    height: ${props => props.opened === 'true' ? (props.tall * 55) : 0}px;
    transition: all 0.4s ease;
`;

const Arrow = styled.div`
    position:absolute;
    top: 50%;
    right: 20px;
    transform: translate(0,-60%) rotate(0deg);
        >span{
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid var(--background3);
            pointer-events: none;
            transform: ${props => props.open && 'rotate(180deg)'};
            border-top: ${props => !props.open && '6px solid #60657a'};
            transition: all .4s;
        }
`;


const SideBar = () => {
    const dispatch = useDispatch();
    const open = useSelector(({ other }) => other.handleSideBar);
    const profile = useSelector((state) => state.auth.user);

    // console.log('PROFILEEEEEE', profile);

    const makeButtons = [
        // {
        //     name: 'PROVA PRESELETTIVA',
        //     subMenu: [
        //         {
        //             to: '/dashboard/courses',
        //             icon: <i className="fa-regular fa-square"></i>,
        //             title: 'Corsi',
        //             // role: profile !== undefined && profile.Role
        //         },
        //         {
        //             to: '/dashboard/materials',
        //             icon: <i className="fa-solid fa-border-all"></i>,
        //             title: 'Materia',
        //             // role: profile !== undefined && profile.Role
        //         },
        //         {
        //             to: '/dashboard/lessons',
        //             icon: <i className="fa-solid fa-table-cells"></i>,
        //             title: 'Lezioni',
        //             // role: profile !== undefined && profile.Role
        //         },
        //         {
        //             to: '/dashboard/simulations',
        //             icon: <i className="fa-solid fa-vr-cardboard"></i>,
        //             title: 'Simulazioni',
        //             // role: profile !== undefined && profile.Role
        //         }
        //     ]
        // },

        {
            flag: 'link',
            to: '/dashboard/courses',
            icon: <i className="fa-solid fa-house"></i>,
            title: profile.role === 'STUDENTE' ? 'I miei corsi' : 'Testa i corsi',
            // role: profile !== undefined && profile.Role
            role: ['STUDENTE', 'DOCENTE', 'CENTRO', 'ADMIN']
        },


        {
            flag: 'link',
            to: '/dashboard/profile',
            icon: <i className="fa-solid fa-id-card-clip"></i>,
            title: 'Profilo',
            role: ['STUDENTE']
            //role: profile !== undefined && profile.Role
            //role: ['STUDENTE', 'DOCENTE', 'CENTRO', 'ADMIN']
        },


        {
            flag: 'link',
            to: '/dashboard/students',
            icon: <i className="fas fa-users"></i>,
            title: 'Studenti',
            // role: profile !== undefined && profile.Role
            role: ['DOCENTE', 'CENTRO', 'ADMIN']
        },
        {
            flag: 'link',
            to: '/dashboard/teachers',
            icon: <i className="fas fa-users"></i>,
            title: 'Tutor',
            // role: profile !== undefined && profile.Role
            role: ['CENTRO', 'ADMIN']
        },

        {
            flag: 'link',
            to: '/dashboard/centers',
            icon: <i className="fa-solid fa-users-gear"></i>,
            title: 'Sedi',
            // role: profile !== undefined && profile.Role
            role: ['ADMIN']
        },
        {
            flag: 'link',
            to: '/dashboard/users',
            icon: <i className="fa-solid fa-users-gear"></i>,
            title: 'Utenti',
            // role: profile !== undefined && profile.Role
            role: ['ADMIN']
        },
        {
            flag: 'link',
            to: '/dashboard/educational-materials',
            icon: <i className="fa-solid fa-book-open"></i>,
            title: 'Aggiornamento QdC',
            // role: profile !== undefined && profile.Role
            role: ['ADMIN', 'STUDENTE']
        },
    ];

    const handleClick = () => {
        dispatch(handleSideBar(!open))
    };

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth > 1100) {
                if (open === true) {
                    dispatch(handleSideBar(false));
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [dispatch, open]);

    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [curentBtn, setCurentBtn] = useState('')

    const filterCore = (e) => {
        setCurentBtn(e.currentTarget.id);
        if (localStorage.getItem('index') === e.currentTarget.id) {
            setOpenSubMenu(!openSubMenu);
        } else {
            localStorage.setItem('index', e.currentTarget.id);
            setOpenSubMenu(true);
        }
    };


    return (
        <>
            <NegativSidebar
                open={open}
                onClick={handleClick}
            />

            <SideBarBody
                open={open}
            // onClick={handleClick}
            >
                <LogoWrapper>
                    <Logo src={logo} alt='logo'></Logo>
                </LogoWrapper>

                <UnorderList>
                    {makeButtons.map((btns, i) =>
                        // <SideBarButton
                        //     key={i}
                        //     props={btn}
                        // />
                        <List key={i}>
                            {btns.flag === undefined ?
                                <>
                                    {
                                        btns.role.map((ro, ind) =>
                                            <React.Fragment key={ind}>
                                                {profile !== undefined &&
                                                    ro === profile.role &&
                                                    <NavBtn id={i + 1} onClick={(e) => filterCore(e)}>
                                                        {btns.name}
                                                        {btns.subMenu !== undefined &&
                                                            <Arrow open={(curentBtn === `${i + 1}` && openSubMenu) && 'open'}>
                                                                <span></span>
                                                            </Arrow>
                                                        }
                                                    </NavBtn>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                </>
                                :
                                <>
                                    {btns.role.map((ro, ind) =>
                                        <React.Fragment key={ind}>
                                            {
                                                profile !== undefined &&
                                                ro === profile.role &&
                                                <NavLinks onClick={handleClick} to={btns.to} style={{ color: 'var(--text1)' }}>
                                                    <Icon>{btns.icon}</Icon>
                                                    {btns.title}
                                                    {/* {btns.title.length > 20 ? btns.title.slice(0, 18) + '...' : btns.title} */}
                                                </NavLinks>
                                            }
                                        </React.Fragment>
                                    )
                                    }
                                </>
                                // <NavBtn id={i + 1} onClick={(e) => filterCore(e)}>
                                //     {btns.name}
                                //     {btns.subMenu !== undefined &&
                                //         <Arrow open={(curentBtn === `${i + 1}` && openSubMenu) && 'open'}>
                                //             <span></span>
                                //         </Arrow>
                                //     }
                                // </NavBtn>
                                // :
                                // btns.role !== 'admin' &&
                                // <NavLinks to={btns.to} style={{ color: 'var(--text1)' }} onClick={handleClick}>
                                //     <Icon>{btns.icon}</Icon>
                                //     {btns.title.length > 20 ? btns.title.slice(0, 18) + '...' : btns.title}

                                // </NavLinks>

                            }

                            {/* {(curentBtn === `${i + 1}` && openSubMenu) && */}
                            <SubMenu
                                tall={`${btns.subMenu !== undefined && btns.subMenu.length}`}
                                opened={`${(curentBtn === `${i + 1}` && openSubMenu) ? 'true' : 'false'}`}
                            >
                                {btns.subMenu !== undefined &&
                                    btns.subMenu.map((btn, i) =>
                                        <div key={i}>
                                            {btn.role === undefined &&
                                                <NavLinks to={btn.to} onClick={handleClick}>
                                                    <Icon>{btn.icon}</Icon>

                                                    {btn.title.length > 20 ? btn.title.slice(0, 18) + '...' : btn.title}
                                                </NavLinks>
                                            }
                                            {btn.role === 'admin' &&
                                                <NavLinks to={btn.to} onClick={handleClick}>
                                                    <Icon>{btn.icon}</Icon>
                                                    {btn.title.length > 20 ? btn.title.slice(0, 18) + '...' : btn.title}
                                                </NavLinks>
                                            }

                                            {/* <SubSubMenu
                                                tall={`${btn.subSubMenu !== undefined && btn.subSubMenu.length}`}
                                                opened={`${(curentBtn === `${i + 1}` && openSubMenu) ? 'true' : 'true'}`}
                                               
                                            >
                                                Cacaaaaaaat
                                            </SubSubMenu> */}

                                        </div>
                                    )
                                }
                            </SubMenu>
                            {/* } */}
                        </List>
                    )}
                </UnorderList>
            </SideBarBody>
        </>
    );
};

export default SideBar;
