import React, { useState } from 'react';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import Intro from './Intro';
import Middle from './Middle';
import Conc from './Conc';

// STYLE
import {
    TestBody,
    BigQuestion,
    Question,
    Letter,
    Answers,
    AnswerWrapper,
    AnswerWrapperCompleted,
    CommentWrapper,
    Label,
    SubLabel,
    Container,
    Input,
    AddBtn,
    RemoveBtn,
} from '../Style.js';

// REDUX
import { useSelector } from 'react-redux';

// const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z'];


const TestScaletta = ({ tests, id, setAnswers, obj, opt, flag, doneAllObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);


    console.log('OBJ', obj);
    console.log('doneAllObj', doneAllObj);
    console.log('tests', tests);

    // const text = "Taking responsibility - practicing 100 percent responsibility every day - is about seeing ourselves not as right or wrong, but as an agent, chooser, problem solver, and learner in the complex interrelationships of our lives so that we can better integrate with the people and world around us. When we do this, we enjoy a better and more productive way to live and lead."

    return (


        <TestBody>

            <Label>Traccia alizzata</Label>

            {
                tests.idTraceAnalysis_TraceAnalysis !== undefined &&
                <BigQuestion
                    dangerouslySetInnerHTML={{ __html: tests.idTraceAnalysis_TraceAnalysis.title }}
                />
            }


            <Label>Parole chiave trovate</Label>

            <BigQuestion
                dangerouslySetInnerHTML={{ __html: tests.keywords }}
            />

            <Label>tema di fondo riscontrati</Label>

            <BigQuestion
                dangerouslySetInnerHTML={{ __html: tests.arguments }}
            />


            {/* <Question
                        // dangerouslySetInnerHTML={{ __html: tests[id].question }}
                        dangerouslySetInnerHTML={{ __html: tests.topic }}
                    /> */}

            <Intro
                setAnswers={setAnswers}
            />

            <Middle
                setAnswers={setAnswers}
            />

            <Conc
                setAnswers={setAnswers}
            />

            {/* {flag === 'verification' &&
            whoAmI.role === 'STUDENTE' &&
            commObj.length > 0 &&
            (Object.keys(commObj[id]).length) > 0 &&
            <CommentWrapper>
                {commObj[id].map((com, i) =>
                    <div key={i}>
                        {i + 1}. <br></br>
                        {com.message}
                    </div>
                )
                }
            </CommentWrapper>
        } */}
        </TestBody>




    );
}

export default TestScaletta;