import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';
import Select from 'react-select';

// COMPONENTS
// import InputCss from '../../GeneralComponents/Inputs/Inputs';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getAllTrace, getAllScaletta, getAllRevisione, getAllAvvio } from '../../../Store/Actions/temaActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
    colourStyles,
} from './ModalStyle';

// const InputCss = styled.input`
//     width: 100%;
//     height: 50px;
//     padding: 6px;
//     border: none;
//     border-radius: var(--radius);
//     border: 1px solid var(--border);
//     margin-top:10px;
//     color: var(--text1);
//     transition: var(--transition) ease;
//     background: transparent;
//         &::placeholder { 
//             color: var(--text3);
//             font-size: 14px;
//         }
//         &:hover{
//             border-color: var(--acc-color);
//         }
//         &:focus{
//             outline:none;
//             border: 1px solid var(--acc-color);
//             /* box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%); */
//         }
//         &:-webkit-autofill{
//             -webkit-text-fill-color: var(--text1);
//             -webkit-box-shadow: 0 0 0px 1000px #fff inset;
//             transition: background-color 5000s ease-in-out 0s; 
//         }
//         &::-webkit-calendar-picker-indicator{
//             filter: invert(50%) sepia(4%) saturate(41%) hue-rotate(313deg) brightness(112%) contrast(100%);
//             cursor: pointer;
//             font-size: 20px; 
//         }
// `;

const LabelCss = styled.label`
    display:flex;
    align-items: center;
    color: var(--text1);
    margin: 20px 10px 0;
`;


// 'Avvio alla scrittura', 0
// 'Analisi della traccia', 1
// 'Elaborazione del Testo o Scaletta',2
// 'Stesura e revisione del tema',3
// 'Coerenza e stesura del testo',4
// 'Registri linguistici',5

const SimulationModal = ({ title, body, setOpenModal, course, type, svolgere }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const trace = useSelector(({ tema }) => tema.trace);
    const scaletta = useSelector(({ tema }) => tema.scaletta);
    const revision = useSelector(({ tema }) => tema.revision);
    const avvio = useSelector(({ tema }) => tema.avvio);
    const [opt, setOpt] = useState([]);
    const [choose, setChoose] = useState();

    // console.log('TRACE', trace);
    //console.log('SCALETTA', scaletta);
    // console.log('REVISION', revision);

    useEffect(() => {
        if (type === svolgere[1]) {
            dispatch(getAllTrace());
        }

        if (type === svolgere[2]) {
            dispatch(getAllScaletta());
        }

        if (type === svolgere[3]) {
            dispatch(getAllRevisione());
        }

        if (type === svolgere[0]) {
            dispatch(getAllAvvio());
        }
    }, [dispatch, type]);

    useEffect(() => {
        if (type === svolgere[0]) {
            let createOpt = [];
            if (avvio !== undefined) {
                for (let i = 0; i < avvio.length; i++) {
                    createOpt.push({ label: avvio[i].desc, value: avvio[i].idStartWriting });
                }
            };

            const key = 'label';
            const unique = [...new Map(createOpt.map(item => [item[key], item])).values()];

            setOpt(unique);


        } else if (type === svolgere[1]) {

            let createOpt = [];
            if (trace !== undefined) {
                for (let i = 0; i < trace.length; i++) {
                    createOpt.push({ label: trace[i].desc, value: trace[i].idTraceAnalysis });
                }
            };
            setOpt(createOpt);
            // setOpt([
            //     { label: 'Analitica', value: 'Analitica' },
            //     { label: 'Discorsiva', value: 'Discorsiva' },
            // ]);
        } else if (type === svolgere[2]) {
            let createOpt = [];
            if (scaletta !== undefined) {
                for (let i = 0; i < scaletta.length; i++) {
                    createOpt.push({ label: scaletta[i].idTraceAnalysis_TraceAnalysis.desc, value: scaletta[i].idTraceAnalysisDone });
                }
            };
            console.log("ASTAAAAA", createOpt)
            setOpt(createOpt);

            // setOpt([
            //     { label: 'Traccia 1', value: '1' },
            //     { label: 'Traccia 2', value: '1' },
            //     { label: 'Traccia 3', value: '1' },
            // ]);
        } else if (type === svolgere[3]) {
            let createOpt = [];
            if (revision !== undefined) {
                for (let i = 0; i < revision.length; i++) {
                    console.log("REVISION", revision)
                    createOpt.push({ label: revision[i].idTraceDone_TraceAnalysisDone.idTraceAnalysis_TraceAnalysis.desc, value: revision[i].idTextProcessing });
                }
            };
	    setOpt(createOpt);
        }
    }, [type, trace, scaletta, revision, avvio]);
    // useEffect(() => {
    //     if (type === svolgere[0]) {
    //         let createOpt = [];
    //         if (trace !== undefined) {
    //             for (let i = 0; i < trace.length; i++) {
    //                 createOpt.push({ label: trace[i].desc, value: trace[i].idTraceAnalysis });
    //             }
    //         };
    //         // const key = 'label';
    //         // const unique = [...new Map(createOpt.map(item => [item[key], item])).values()];

    //         setOpt(createOpt);
    //     } else if (type === svolgere[1]) {
    //         // let createOpt = [];
    //         // if (scaletta !== undefined) {
    //         //     console.log(scaletta)
    //         //     for (let i = 0; i < scaletta.length; i++) {
    //         //         createOpt.push({ label: scaletta[i].type, value: scaletta[i].idTextProcessing });
    //         //     }
    //         // };
    //         let createOpt = [];
    //         if (scaletta !== undefined) {
    //             for (let i = 0; i < scaletta.length; i++) {
    //                 createOpt.push({ label: scaletta[i].idTraceAnalysis_TraceAnalysis.desc, value: scaletta[i].idTraceAnalysisDone });
    //             }
    //         };
    //         console.log("ASTAAAAA", createOpt)
    //         setOpt(createOpt);
    //         // setOpt([
    //         //     { label: 'Analitica', value: 'Analitica' },
    //         //     { label: 'Discorsiva', value: 'Discorsiva' },
    //         // ]);
    //     } else if (type === svolgere[3]) {
    //         let createOpt = [];
    //         if (revision !== undefined) {
    //             for (let i = 0; i < revision.length; i++) {
    //                 console.log("REVISION", revision)
    //                 createOpt.push({ label: revision[i].idTraceDone_TraceAnalysisDone.idTraceAnalysis_TraceAnalysis.desc, value: revision[i].idTextProcessing });
    //             }
    //         };
    //         setOpt(createOpt);

    //         // setOpt([
    //         //     { label: 'Traccia 1', value: '1' },
    //         //     { label: 'Traccia 2', value: '1' },
    //         //     { label: 'Traccia 3', value: '1' },
    //         // ]);
    //     } else if (type === svolgere[5]) {
    //         let createOpt = [];
    //         if (avvio !== undefined) {
    //             for (let i = 0; i < avvio.length; i++) {
    //                 createOpt.push({ label: avvio[i].desc, value: avvio[i].idStartWriting });
    //             }
    //         };

    //         const key = 'label';
    //         const unique = [...new Map(createOpt.map(item => [item[key], item])).values()];

    //         setOpt(unique);
    //         // setOpt([
    //         //     { label: 'Fonologia, ortografia, punteggiatura', value: '1' },
    //         //     { label: 'Articolo, nome, preposizioni', value: '1' },
    //         //     { label: 'Nome, aggettivo, pronome', value: '1' },
    //         //     { label: 'Verbo', value: '1' },
    //         //     { label: 'Avverbio e congiunzione', value: '1' },
    //         //     { label: 'Analisi logica', value: '1' }
    //         // ]);
    //     }
    // }, [type, trace, scaletta, revision, avvio]);

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
    };

    const handleSubmit = evt => {
        const url = '/dashboard/tema-exercises/pre-tema';
        const state = {
            flag: 'tema', course, choose, type, svolgere
        }
        history.push(url, state)
    };

    return (
        <>
            <ModalWrapper onClick={(e) => handleClose(e)} />

            <DialogBox>
                <H3>{title}</H3>
                <Hr></Hr>

                <LabelCss htmlFor="type">Tipo di
                    {type === svolgere[1] && ' traccia'}
                    {type === svolgere[0] && ' scrittura'}
                    {type === svolgere[4] && ' stesura'}
                </LabelCss>
                <Select
                    name='type'
                    isMulti={false}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    // defaultValue={defaultValue()}
                    onChange={(e) => setChoose(e.value)}
                    // onChange={(e) =>
                    //     setMore({
                    //         ...more,
                    //         area: e
                    //     })
                    // }
                    options={opt}
                    styles={colourStyles}
                    placeholder={type === 'Analisi della traccia' ?
                        'Scegli il tipo di traccia'
                        :
                        'Scegli il tipo di scrittura'
                    }
                />

                <BtnWrapper>
                    <Btn disable onClick={handleClose}>Annulla</Btn>
                    {choose === undefined ?
                        <Btn closed>Inizia</Btn>
                        :
                        <Btn onClick={handleSubmit}>Inizia</Btn>
                    }
                    {/* <Btn onClick={handleSubmit}>Grafici</Btn> */}
                </BtnWrapper>
            </DialogBox>
        </>

    );
}
export default SimulationModal;
