import React, { useEffect, useState } from 'react';

// PACKAGES
import styled from 'styled-components';


const InputWrapper = styled.label`
	display: block;
    width: 300px;
    /* margin-bottom: 50px; */
        @media (max-width: 775px) {
            width: 100%;
        };
`;

const SrcWrapper = styled.div`
   position:relative;
`;

const SearchWrapper = styled.div`
   position:absolute;
   left: 15px;
   top: 50%;
   transform: translate(0, -50%);
   color: var(--text2);
   cursor: pointer;
`;

// const LabelCss = styled.label`
// 	display: block;
// 	font-size: 1rem;
// 	margin: 20px 10px 10px;
//     color: var(--text5);
// `;

const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 6px 6px 50px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
            /* box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%); */
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;


const Search = ({
    flag,
    usr, setUsr,
    type, setType,
}) => {
    const [search, setSearch] = useState('');

    // console.log('PROD', search);

    useEffect(() => {
        let thisSearch = [];
        if (flag === 'users') {
            if (usr !== undefined) {
                if (search === '') {
                    thisSearch = usr;
                } else {
                    // console.log(search);
                    thisSearch = usr.filter((user) =>
                        (user.user_name === null || user.user_name.toLowerCase().includes(search.toLowerCase())) ||
                        (user.user_surname === null || user.user_surname.toLowerCase().includes(search.toLowerCase())) ||
                        (user.user_email === null || user.user_email.toLowerCase().includes(search.toLowerCase())) ||
                        (user.center === null || user.center.toLowerCase().includes(search.toLowerCase()))
                    );
                };
                setUsr(thisSearch);
                // setCourse(thisSearch.sort((a, b) => a.name.localeCompare(b.name)));
            }
        };

        if (flag === 'quiz') {
            if (type !== undefined) {
                if (search === '') {
                    thisSearch = type;
                } else {
                    thisSearch = type.filter((ty) =>
                        (ty.date === null || ty.date.toLowerCase().includes(search.toLowerCase()))
                    );
                };
                setType(thisSearch);
                // setCourse(thisSearch.sort((a, b) => a.name.localeCompare(b.name)));
            }
        };

        if (flag === 'type') {
            if (type !== undefined) {
                if (search === '') {
                    thisSearch = type;
                } else {
                    thisSearch = type.filter((ty) =>
                        (ty.type === null || ty.type.toLowerCase().includes(search.toLowerCase()))
                    );
                };
                setType(thisSearch);
                // setCourse(thisSearch.sort((a, b) => a.name.localeCompare(b.name)));
            }
        };

        if (flag === 'stud') {
            if (type !== undefined) {
                if (search === '') {
                    thisSearch = type;
                } else {
                    thisSearch = type.filter((ty) =>
                        (ty.type === null || ty.user_name.toLowerCase().includes(search.toLowerCase())) ||
                        (ty.type === null || ty.user_surname.toLowerCase().includes(search.toLowerCase())) ||
                        (ty.type === null || ty.class.toLowerCase().includes(search.toLowerCase())) ||
                        (ty.type === null || ty.center.toLowerCase().includes(search.toLowerCase())) ||
                        (ty.type === null || ty.user_email.toLowerCase().includes(search.toLowerCase()))
                    );
                };
                setType(thisSearch);
                // setCourse(thisSearch.sort((a, b) => a.name.localeCompare(b.name)));
            }
        };

        if (flag === 'tpaDone') {
            if (type !== undefined) {
                if (search === '') {
                    thisSearch = type;
                } else {
                    thisSearch = type.filter((ty) =>
                        (ty.type === null || ty.date.toLowerCase().includes(search.toLowerCase()))
                    );
                };
                setType(thisSearch);
                // setCourse(thisSearch.sort((a, b) => a.name.localeCompare(b.name)));
            }
        };

        if (flag === 'statistics') {
            if (type !== undefined) {
                if (search === '') {
                    thisSearch = type;
                } else {
                    thisSearch = type.filter((ty) =>
                        (ty.type === null || ty.date.toLowerCase().includes(search.toLowerCase()))
                    );
                };
                setType(thisSearch);
                // setCourse(thisSearch.sort((a, b) => a.name.localeCompare(b.name)));
            }
        };


    }, [search,
        flag,
        usr, setUsr,
        type, setType,
    ])

    return (
        <InputWrapper>
            <SrcWrapper>
                {/* <LabelCss htmlFor='psw'>Password</LabelCss> */}
                <InputCss
                    placeholder="Ricerca..."
                    type='text'
                    name='text'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                ></InputCss>
                <SearchWrapper>
                    <i className="fas fa-search"></i>
                </SearchWrapper>
            </SrcWrapper>
        </InputWrapper>
    );
};

export default Search;