const initState = {

};

const statisticsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ALL_STATISTICS':
            return {
                ...state,
                allStatistics: action.payload,
            };
        case 'GENERAL_STATISTICS':
            return {
                ...state,
                generalStatistics: action.payload,
            };
        case 'CHART_LINE_DATA':
            return {
                ...state,
                statChartLine: action.payload,
            };


        default:
            return state;
    }
};

export default statisticsReducer;