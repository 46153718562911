import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

//PACKAGES
// import styled from 'styled-components';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateTestDone } from '../../../Store/Actions/tpaActions'
//import { getAllUsers } from '../../../Store/Actions/usersActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    GoBack,
    // colourStyles,
    // NavTable,
    BtnLink,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
//import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './TpaExercisesDoneTable';

const tableHead = [
    'Data',
    'Num. Domande',
    'Stato',
    // 'Cognome',
    // 'E-mail',
    'Valutazione',
    'Azioni',
];


const TpaExercisesDone = ({ details }) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const users = useSelector(({ users }) => users.allUsers);
    const profile = useSelector(({ auth }) => auth.user);
    const update = useSelector(({ tpa }) => tpa.table)
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [usr, setUsr] = useState([]);
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        dispatch(getUpdateTestDone(details.type.toLowerCase().replace(' ', '-'), details.done[0].idUser))
        //dispatch(getAllUsers());
    }, [dispatch]);

    // useEffect(() => {
    //     if (users !== undefined) {
    //         if (profile.role === 'ADMIN') {
    //             setTeachers(users.filter(user => user.user_role === 'DOCENTE'))
    //         }
    //         if (profile.role === 'CENTRO') {
    //             setTeachers(users.filter(user => user.user_role === 'DOCENTE' && user.center.toLowerCase() === profile.center.toLowerCase()))
    //         }
    //     }
    // }, [users])

    // console.log("TEACHERS USR", users);
    // console.log('PROFILE', profile);
    // console.log('details', details);

    return (
        update !== undefined &&
        <WrapperRightSide>

            <GoBack onClick={() => history.goBack()}>
                <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
            </GoBack>

            <Title>{details.type}</Title>

            {/* <ActionsWrapperSpace>
                <div></div>

                <Search
                    usr={teachers}
                    flag={'users'}
                    setUsr={setUsr}
                />
            </ActionsWrapperSpace> */}

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <BtnLink
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/teachers/newTeacher'>
                        <i className="fas fa-plus"></i>Professore
                    </BtnLink>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono test da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono test da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                            details={update}
                                            allDetails={details.allDetails}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={update}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default TpaExercisesDone;