import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';
// import useSound from 'use-sound';
import { Howl, Howler } from 'howler';

// COMPONENTS
import NotifRow from './NotifRow';
import mySound from '../../../Assets/notif.mp3';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { findUnreadNotifications } from '../../../Store/Actions/notificationsActions';

// STYLE
import {
    Btn,
    DialogBox,
    ReminderWrapper,
} from './StyleNavBar';

const GeneralNotif = styled.div`
    position: absolute;
    top: 2px;
    left: 40px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: var(--err);
    border: 2px solid #fff;
`;

const CheckAllLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: var(--radius);
  text-decoration: none;
  transition: var(--transition);
  color: var(--accentColor);
  background: var(--background2);
  > h5 {
    color: var(--accentColor);
    font-size: var(--font);
  }
  &:hover{
    background: var(--acc-color);
    color: var(--text5);
  }
`;


const Reminder = ({ socket }) => {
    const dispatch = useDispatch();
    const openDialogRef = useRef();
    const profile = useSelector(({ auth }) => auth.user);
    const unreadNotif = useSelector(({ notif }) => notif.unreadNotif);
    const notifNav = [];
    const [open, setOpen] = useState(false);
    const [arrivalNotif, setArrivalNotif] = useState([]);
    const { Howl, Howler } = require('howler');

    const sound = new Howl({
        src: [mySound],
        // html5: true
    });

    useEffect(() => {
        if (socket !== null) {
            socket.on('getNotif', data => {
                // console.log('DATA', data);
                setArrivalNotif((prev) => [...prev, {
                    SenderID: data.senderId,
                    ReciverID: data.reciverId,
                    Text: data.text,
                    Author: data.author,
                    Course: data.course,
                    Test: data.test,
                    TestType: data.testType,
                    createdAt: Date.now(),
                }]);
                // new Audio(mySound).play();
                sound.play();
            });

            socket.on('getNotifForProf', data => {
                // console.log('DATA FOR PRTOF', data);
                setArrivalNotif((prev) => [...prev, {
                    SenderID: data.senderId,
                    ReciverID: data.reciverId,
                    Text: data.text,
                    Author: data.author,
                    Course: data.course,
                    Test: data.test,
                    TestType: data.testType,
                    createdAt: Date.now(),
                }]);
                // new Audio(mySound).play();
                sound.play();
            });
        }
    }, [socket]);

    useEffect(() => {
        if (profile !== undefined) {
            dispatch(findUnreadNotifications(profile));
        }
    }, [profile]);

    useEffect(() => {
        if (unreadNotif !== undefined) {
            setArrivalNotif(unreadNotif);
        }
    }, [unreadNotif]);

    // useEffect(() => {
    //     const transformDate = new Date();
    //     let dd = transformDate.getDate();
    //     let mm = transformDate.getMonth() + 1;
    //     let yyyy = transformDate.getFullYear();
    //     // if (dd < 10) {
    //     //   dd = '0' + dd;
    //     // }
    //     // if (mm < 10) {
    //     //   mm = '0' + mm;
    //     // }
    //     const setDayIs = mm + '/' + dd + '/' + yyyy;
    //     // dispatch(getNotifNav(setDayIs));
    // }, []);

    useEffect(() => {
        let handler = (e) => {
            if (openDialogRef.current) {
                if (openDialogRef.current.contains(e.target) === false) {
                    setOpen(false)
                }
            }
        };
        document.addEventListener("mousedown", handler, true);
        return () => {
            document.removeEventListener("mousedown", handler, true);
        }
    }, [open]);

    // console.log('arrivalNotif', arrivalNotif);


    return (
        <ReminderWrapper ref={openDialogRef}>
            <Btn color='notif' onClick={() => setOpen(!open)}>
                {notifNav.length > 0 && <span></span>}
                <i className="fas fa-bell"></i>
            </Btn>

            {arrivalNotif.length > 0 &&
                <GeneralNotif />
            }

            {open &&
                <DialogBox >
                    {arrivalNotif.length > 0 ?
                        arrivalNotif.map((not, i) =>
                            i <= 2 &&
                            <NotifRow
                                key={i}
                                notif={not}
                                open={open}
                                setOpen={setOpen}

                            />
                        )
                        :
                        <h3>Nessuna notifica</h3>
                    }

                    {arrivalNotif.length > 3 && (
                        <h6>altre {arrivalNotif.length - 3} notifiche</h6>
                    )}

                    <CheckAllLink to="/dashboard/notifications" onClick={() => setOpen(!open)}>
                        {arrivalNotif.length > 0 ?
                            <h5>Visualizzare tutto</h5>
                            :
                            <h5>Alle notifiche</h5>
                        }
                    </CheckAllLink>
                </DialogBox>
            }

        </ReminderWrapper>
    );
}

export default Reminder;