import React, { useState, useEffect } from 'react';

// ASSETS
import brush from '../../../Assets/brush.png'

// PACKAGES
import styled from 'styled-components';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


// REDUX
import { useSelector } from 'react-redux';


const TestBody = styled.div`
    padding-bottom: 80px;
        >img{
            max-width: 400px;
            max-height: 400px;
        }
`;

const Question = styled.div`
    /* justify-content: center;
    text-align: center; */
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
    padding-bottom: 20px;
`;

const EditorContainer = styled.div`
	   /* .editorClassName{
            height: calc(100vh - 360px);
            background: white;
        } */
        .DraftEditor-root {
            position: relative;
            height: calc(100vh - 451px);
        }
     
        .editorClassName{
            height: calc(100vh - 415px);
            background: white;
            padding: 10px;
        }
        .rdw-colorpicker-modal{
            position: absolute;
            top: 50px;
            left: -180px;
            display: flex;
            flex-direction: column;
            width: 230px;
            height: 255px;
            border: 1px solid #F1F1F1;
            padding: 15px;
            border-radius: 2px;
            z-index: 100;
            background: white;
            box-shadow: 3px 3px 5px #bfbdbd;
        }
        .rdw-emoji-modal{
            overflow: auto;
            position: absolute;
            top: 50px;
            left: -190px;
            display: flex;
            flex-wrap: wrap;
            width: 235px;
            height: 180px;
            border: 1px solid #F1F1F1;
            padding: 10px;
            border-radius: 2px;
            z-index: 100;
            background: white;
            box-shadow: 3px 3px 5px #bfbdbd;
        }
        .rdw-option-wrapper{
            width: 40px;
            height: 40px;
            border-radius: var(--radius);
        }
        .rdw-fontsize-dropdown {
            width: 110px;
        }
        .rdw-dropdown-optionwrapper{
            width: 60px;
        }
        .rdw-fontfamily-optionwrapper{
            width: 110px;
        }
        .rdw-dropdown-optionwrapper{
            width: 110px;
        }
`;


const TestAvvio = ({ tests, id, setAnswers, obj, opt, flag, doneAllObj, setObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const commObj = doneAllObj !== undefined && doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : [];

    useEffect(() => {
        if (tests.length !== 0) {
            let createObj = [];
            for (let i = 0; i < tests.length; i++) {
                // Obținem conținutul inițial sub forma unui obiect JSON
                const content = EditorState.createWithContent(ContentState.createFromText(tests[i].body));
                const contentState = content.getCurrentContent();
                const rawContentState = convertToRaw(contentState);
                createObj.push({
                    asw: EditorState.createWithContent(ContentState.createFromText(tests[i].body)),
                    aswTxt: rawContentState,
                    aswIndex: i,
                    check: false,
                })
            }
            setObj(createObj);
        }
    }, [tests]);

    const onEditorStateChange = (editorState) => {
        const text = convertToRaw(editorState.getCurrentContent());
        setAnswers(editorState, id, text);
    };

    // console.log('OBJ', obj);
    // console.log('doneAllObj', doneAllObj);
    // console.log(JSON.stringify(editorState));

    return (
        <TestBody>
            {tests.length !== 0 &&
                <Question
                    dangerouslySetInnerHTML={{ __html: tests[id].title }}
                />
            }

            <EditorContainer>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    // editorState={editorState}
                    editorState={obj.length !== 0 ? obj[id].asw : null}
                    onEditorStateChange={onEditorStateChange}
                    // toolbar={toolbarOptions}
                    toolbar={{
                        options: ['inline',
                            'blockType',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'emoji',
                            'remove',
                            'history'],
                        colorPicker: {
                            icon: brush,
                        },
                    }}
                    localization={{
                        locale: 'it',
                    }}
                />
            </EditorContainer>
        </TestBody>
    );
}

export default TestAvvio;