const initState = {
    handleSideBar: false,
    handleCalendar: false,
    handleRightSideBar: false,
    setSize: 10,
    messSidebar: false,
    friendsSidebar: false,
    globalNotif: false
};

const otherReducer = (state = initState, action) => {
    switch (action.type) {
        case 'HANDLE_SIDEBAR':
            return {
                ...state,
                handleSideBar: action.payload,
            };

        case 'HANDLE_RIGHT_SIDEBAR':
            return {
                ...state,
                handleRightSideBar: action.payload,
            };

        case 'HANDLE_SIDEBAR_CALENDAR':
            return {
                ...state,
                handleCalendar: action.payload,
            };

        case 'SET_THE_DAY':
            return {
                ...state,
                setDay: action.payload,
            };

        case 'SET_PAGE_SIZE':
            return {
                ...state,
                setSize: action.payload,
            };

        case 'SET_PREV_LOCATION':
            return {
                ...state,
                prevLocation: action.payload,
            };

        case 'SET_FINISH_SIMULATION':
            return {
                ...state,
                finishSimulation: action.payload,
            };
        case 'SET_FINISH_TIME':
            return {
                ...state,
                finishTime: action.payload,
            };
        case 'SET_MESS_SIDEBAR':
            return {
                ...state,
                messSidebar: action.payload,
            };
        case 'SET_FRIENDS_SIDEBAR':
            return {
                ...state,
                friendsSidebar: action.payload,
            };
        case 'SET_GLOBAL_NOTIF':
            return {
                ...state,
                globalNotif: action.payload,
            };
        case 'SET_MSG_LISTEN':
            return {
                ...state,
                msgListen: action.payload,
            };
        case 'SET_NOTIF_LISTEN':
            return {
                ...state,
                notifListen: action.payload,
            };

        default:
            return state;
    }
};

export default otherReducer;