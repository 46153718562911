import React, { useState } from 'react';

// PACKEGES
import styled from 'styled-components';

// COMPONENTS
import NotifCard from './NotifCard';

import {
    PopUp,
    Title,
    Subtitle,
    EmptyNotif,
    Empty,
    UnreadWrapper,
    NotifContainer,
    ReadWrapper,
    ReadBtn
} from './Style';

const GeneralWrapper = styled.div`
    background: transparent;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
        @media (min-width: 800px) {
            display: none;  
         }
        @media (max-width: 800px) {
            display: flex;  
            flex-direction: column;
         }
`;

const TabWrapper = styled.div`
    /* border-bottom: 1px solid var(--background3); */
    overflow: hidden;
    margin-top: 20px;
        >button:nth-child(1){
            border-radius: var(--radius) 0 0 var(--radius);
        }
        >button:nth-child(2){
            border-radius:  0  var(--radius)  var(--radius) 0;
        }
        @media (max-width: 400px) {
            width: 100%;    
        }
`;

const Btn = styled.button`
    width: 150px;
    padding: 10px 30px;
    border: none;
    outline:none;
    background: ${(props) => props.select === 'select' ? 'var(--acc-color)' : 'var(--background3)'};
    color: ${(props) => props.select === 'select' ? 'var(--text5)' : 'var(--text2)'};
    font-weight: ${(props) => props.select === 'select' && 'bold'};
        >i{
            margin-right: 10px;
        }
        @media (max-width: 400px) {
            width: 50%;    
            font-size: 10px;
            padding: 10px 0;
         }
`;

const NotifTabs = ({ unreadNotif,
    readedNotif,
    markAll,
    deleteAll }) => {
    const [curentBtn, setCurentBtn] = useState('');

    const filterCore = (e) => {
        setCurentBtn(e.currentTarget.id)
    };


    return (
        <GeneralWrapper>
            <TabWrapper>
                <Btn
                    id='1'
                    select={curentBtn === '1' || curentBtn === '' ? 'select' : null}
                    onClick={(e) => filterCore(e)}
                ><i className="fa-solid fa-envelope"></i>Non letti
                </Btn>
                <Btn
                    id='2'
                    select={curentBtn === '2' ? 'select' : null}
                    onClick={(e) => filterCore(e)}
                ><i className="fa-solid fa-envelope-open"></i>
                    Letti
                </Btn>
            </TabWrapper >

            {(curentBtn === '' || curentBtn === '1') &&
                <UnreadWrapper>
                    {/* <Title>
                        <i className="fa-solid fa-envelope"></i>
                        Non letti
                    </Title> */}

                    <Subtitle>
                        <span>{unreadNotif !== undefined && unreadNotif.length} notifica</span>
                        <ReadBtn onClick={markAll}>
                            <i className="fa-solid fa-list-check"></i>
                            <PopUp>Segna tutti come letti</PopUp>
                        </ReadBtn>
                    </Subtitle>
                    <NotifContainer>
                        {unreadNotif !== undefined && unreadNotif.length > 0 ?
                            unreadNotif.map((notif, i) =>
                                <NotifCard
                                    key={i}
                                    notif={notif}
                                    flag='unread'
                                />
                            )
                            :
                            <Empty><i className="fa-solid fa-envelope"></i> Nessuna notifica</Empty>
                        }
                    </NotifContainer>
                </UnreadWrapper>
            }

            {curentBtn === '2' &&
                <ReadWrapper>
                    {/* <Title>
                        <i className="fa-solid fa-envelope-open"></i>
                        Letti
                    </Title> */}
                    <Subtitle>
                        <span>{readedNotif !== undefined && readedNotif.length} notifica</span>
                        <ReadBtn delete onClick={deleteAll}>
                            <PopUp>Elimina tutto</PopUp>
                            <i className="fa-regular fa-trash-can" style={{ marginRight: '2px' }}></i>
                        </ReadBtn>
                    </Subtitle>
                    <NotifContainer>
                        {readedNotif !== undefined && readedNotif.length > 0 ?
                            readedNotif.map((notif, i) =>
                                <NotifCard
                                    key={i}
                                    notif={notif}
                                />
                            )
                            :
                            <Empty><i className="fa-solid fa-envelope-open"></i> Nessuna notifica</Empty>
                        }
                    </NotifContainer>
                </ReadWrapper>
            }

        </GeneralWrapper>
    );
};

export default NotifTabs;