import React, { useEffect, useState } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector } from 'react-redux';


const TestBody = styled.div`
    padding-bottom: 20px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const Question = styled.div`
    /* justify-content: center;
    text-align: center; */
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

const CommentWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
        >div{
            width: 100%;
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
`;

const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 0px;
    color: var(--text2);
    font-weight: bold;
`;

const TextArea = styled.textarea`
    width: 100%;
	/* max-width: 680px; */
    height: 200px;
    resize: none;
    padding: 6px;
    margin-top: 10px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    background: transparent;
    color: var(--text1);
    transition: var(--transition) ease;
    font-size: 16px;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;


const TestTraccia = ({ tests,
    id,
    setAnswers,
    obj,
    opt,
    flag,
    doneAllObj,
}) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const commObj = doneAllObj !== undefined && doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : [];
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');

    useEffect(() => {
        if (text1 !== '' && text2 !== '') {
            setAnswers([{ arguments: text1, keywords: text2 }]);
        } else {
            setAnswers([]);
        }
    }, [text1, text2]);

    console.log('OBJ', tests);
    // console.log('doneAllObj', doneAllObj);


    return (
        <TestBody>
            <Question
                dangerouslySetInnerHTML={{ __html: tests.title }}
            />

            <LabelCss htmlFor="asw1">tema di fondo</LabelCss>
            <TextArea
                placeholder='Inserisci la tua risposta'
                inputType='textarea'
                name='asw1'
                label='Risposta'
                value={text1}
                onChange={(e) => {
                    setText1(e.target.value)
                }}
            />

            <LabelCss htmlFor="asw2">Parole chiave</LabelCss>
            <TextArea
                placeholder='Inserisci la tua risposta'
                inputType='textarea'
                name='asw2'
                label='Risposta'
                value={text2}
                onChange={(e) => {
                    setText2(e.target.value)
                }}
            />

            {flag === 'verification' &&
                whoAmI.role === 'STUDENTE' &&
                commObj.length > 0 &&
                (Object.keys(commObj[id]).length) > 0 &&
                <CommentWrapper>
                    {commObj[id].map((com, i) =>
                        <div key={i}>
                            {i + 1}. <br></br>
                            {com.message}
                        </div>
                    )
                    }
                </CommentWrapper>
            }
        </TestBody>
    );
}

export default TestTraccia;