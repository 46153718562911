const initState = {

};

const testReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ALL_QUESTIONS':
            return {
                ...state,
                allQuestions: action.payload,
            };
        case 'ALL_SUBJECTS':
            return {
                ...state,
                allSubjects: action.payload,
            };
        case 'SELECTED_QUESTIONS':
            return {
                ...state,
                selectedQuestions: action.payload,
            };
        case 'SAVED_TEST':
            return {
                ...state,
                savedTest: action.payload,
            };
        case 'BRING_TEST':
            return {
                ...state,
                bringTest: action.payload,
            };
        case 'BRING_TESTS_DONE':
            return {
                ...state,
                bringTestsDone: action.payload,
            };
        case 'GET_SIMULATION':
            return {
                ...state,
                simulation: action.payload,
            };
        case 'SAVED_SIMULATION':
            return {
                ...state,
                savedSimulation: action.payload,
            };
        case 'BRING_SIMULATION':
            return {
                ...state,
                bringSimulation: action.payload,
            };
        case 'BRING_SIMULATIONS_DONE':
            return {
                ...state,
                bringSimulationsDone: action.payload,
            };
        case 'GET_SIMULATIONS_OPT':
            return {
                ...state,
                simOpt: action.payload,
            };
        case 'WRONG_QUEZZES':
            return {
                ...state,
                bringWrongQuezzes: action.payload,
            };
        case 'TEST_WRONG_QUESTIONS':
            return {
                ...state,
                testWrongQuezzes: action.payload,
            };
        case 'SAVED_SIMULATION_ANSWERS':
            return {
                ...state,
                score: action.payload,
            };

        case 'WRONG_QUEZZES_BY_SUBJECT':
            return {
                ...state,
                bringWrongQuezzesBySubject: action.payload,
            };
        default:
            return state;
    }
};

export default testReducer;