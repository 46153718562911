import React from 'react';
import styled from 'styled-components';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { handleSideBar } from '../../../Store/Actions/otherActions.js';

const HamWrapper = styled.div`
    cursor: pointer;
        >i{
            font-size:25px;
            color: var(--text2);
            margin-left: 25px;
        }
        @media (min-width: 1100px) {
            display: none;
        };
`;

const Ham = () => {
    const dispatch = useDispatch();
    const open = useSelector(({ other }) => other.handleSideBar);

    // console.log('OPEN SIDEBAR', open)

    const handleClick = () => {
        dispatch(handleSideBar(!open))
    };

    return (
        <HamWrapper onClick={handleClick}>
            {!open ?
                <i className="fas fa-bars"></i>
                :
                <i className="fas fa-times"></i>
            }
        </HamWrapper>
    );
};

export default Ham;


