// PACKAGES
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    /* overflow: hidden; */
    border: var(--radius);
    display: flex;
    flex-direction: column;
    justify-content: start;
    /* align-items: center; */
    /* justify-content: left; */
    /* flex-wrap: wrap; */
        /* >img{
            width: 100%;
            height: 100%;
            max-width: 1000px;
            border-radius: var(--radius);
        } */
        @media (max-width: 1490px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            /* max-width: 1000px; */
        };
`;

export const CommentWrapper = styled.div`
    width: 30%;
    padding: 0 20px;
    height: 100%;
        >div{
            width: 350px;
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
        @media (max-width: 1490px) {
            padding: 20px 0;
            /* display: flex;
            flex-wrap: wrap; */
            width: 100%;
            >div{
                width: 100%;
            }
        };
`;

export const GradeWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
        >span{
            background: ${props => props.sts ? 'var(--text1)' : 'var(--warning)'};
            color: var(--text5);
            border-radius: 50px;
            padding: 5px 20px;
        }
`;

export const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 10px 10px 0px;
    color: var(--text2);
    font-weight: bold;
`;

export const ResContainer = styled.div`
    padding: 10px;
    border-radius: var(--radius);
    display: flex;
    justify-content: flex-start;
    background: var(--background3);
    min-height: 100px;
     margin-right: 10px;
        >div{
            display: flex;
            >span{
                margin-right: 10px;
            }
        }
`;

export const QueContainer = styled.div`
    padding: 10px;
    border-radius: var(--radius);
    color: var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
    background: var(--background4);
     margin-right: 10px;
`;

export const QuestionsContainer = styled.div`
    max-height: 180px;  
    width: calc(100% - 10px);
    margin-bottom: 20px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: var(--background4);
    padding: 10px;
        ::-webkit-scrollbar {
            width: 5px;
        };
        ::-webkit-scrollbar-thumb {
            background: #636363b5;
        };
        @media (max-width: 1100px) {
                width: 100%;
        };
`;

export const Box = styled.div`
    height: 30px;
    width: 30px;
    /* color: var(--text2); */
    color: ${props =>
        (props.prev === 'correct' && 'var(--text5)') ||
        (props.prev === 'incorrect' && 'var(--text5)') ||
        (props.select === 'select' ? 'var(--text5)' : 'var(--text2)')
    };
    background: ${props =>
        (props.select === 'select' && 'var(--warning)') ||
        (props.prev === 'correct' && '#ffbc5dd1') ||
        (props.prev === 'incorrect' && 'var(--err)')
    };
    font-size: 18px;
    font-weight: bold;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
        &:hover{
            /* background: var(--background3); */
            background: ${props => props.select === 'select' ? 'var(--warning)' : 'var(--background3)'};
        }
        &:focus{
            background: var(--warning);
        }
`;