import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// ASSETS
import pic from '../../../Assets/Capture.PNG';
import canvasImage from '../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../GeneralComponents/TestViewsBars/NavBar';
// import FooterBar from '../../GeneralComponents/TestBars/FooterBar';
import TestPageWithDrawing from '../TestPage/DrawingTest/TestPageWithDrawing';
import TpaSwitchTest from './TpaSwitchTest';
// import TestPage from './MultipleAswTest/TestPageWithMultipleAsw';
// import TestPage from './TextEditorTest/TestPageWithTextEditor';
// import TestPage from './DrawingTest/TestPageWithDrawing';

// import TestPage from './TestVerification/TestVerificationPage';
import FinishPage from './TpaFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringTpaExercises } from '../../../Store/Actions/tpaActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../PagesShareStyle/PagesShareStyle';

//     TRUE/FALSE: [BHS,MMPI,SIGMA]
//     SCRIS LIBER: [FRASI, BIOGRAFICO]
//     MULTIPLE CHOISES ONE ANS: [NEO,CISS,PFS]
//     MULTIPLE CHOISES TWO ANS: [TPC,TOM,EQI,DIFESA,BIG5]


// OPQ

const boolOptions = [
    { label: 'Vero', value: 'Vero' },
    { label: 'Falso', value: 'Falso' }
];

const boolScidOptions = [
    { label: 'SI', value: 'SI' },
    { label: 'NO', value: 'NO' }
];

const oneCissOptions = [
    { label: 'Per niente', value: 'Per niente' },
    { label: 'Poco', value: 'Poco' },
    { label: 'Abbastanza', value: 'Abbastanza' },
    { label: 'Molto', value: 'Molto' },
    { label: 'Moltissimo', value: 'Moltissimo' }
];

const oneNeoOptions = [
    { label: 'Sicuramente in disaccordo', value: 'Sicuramente in disaccordo' },
    { label: 'In disaccordo', value: 'In disaccordo' },
    { label: 'Indeciso', value: 'Indeciso' },
    { label: 'In accordo', value: 'In accordo' },
    { label: `Sicuramente d'accordo`, value: `Sicuramente d'accordo` }
];

const multipleTomOptions = [
    { label: 'Molto in disaccordo', value: 'Molto in disaccordo' },
    { label: 'Abbastanza in disaccordo', value: 'Abbastanza in disaccordo' },
    { label: 'Leggermente in disaccordo', value: 'Leggermente in disaccordo' },
    { label: `Né d'accordo né in disaccordo`, value: `Né d'accordo né in disaccordo` },
    { label: `Leggermente d'accordo`, value: `Leggermente d'accordo` },
    { label: `Abbastanza d'accordo`, value: `Abbastanza d'accordo` },
    { label: `Molto d'accordo`, value: `Molto d'accordo` },
];

const multipleTpcOptions = [
    { label: 'Completamete in disaccordo', value: 'Completamete in disaccordo' },
    { label: 'Molto in disaccordo', value: 'Molto in disaccordo' },
    { label: 'Abbastanza in disaccordo', value: 'Abbastanza in disaccordo' },
    { label: `Né d'accordo né in disaccordo`, value: `Né d'accordo né in disaccordo` },
    { label: `Abbastanza d'accordo`, value: `Abbastanza d'accordo` },
    { label: `Molto d'accordo`, value: `Molto d'accordo` },
    { label: `Completamente d'accordo`, value: `Completamente d'accordo` },
];

const multipleDifesaOptions = [
    { label: 'MAI', value: 'MAI' },
    { label: 'QUALCHE VOLTA', value: 'QUALCHE VOLTA' },
    { label: 'SPESSO', value: 'SPESSO' },
    { label: 'SEMPRE', value: 'SEMPRE' },
];

const multipleBigOptions = [
    { label: 'Assolutamente vero', value: 'Assolutamente vero' },
    { label: 'Abbastaza vero', value: 'Abbastaza vero' },
    { label: 'Né vero né falso', value: 'Né vero né falso' },
    { label: 'Abbastaza falso', value: 'Abbastaza falso' },
    { label: 'Assolutamente falso', value: 'Assolutamente falso' },
];

const oneQeOptions = [
    { label: `Sempre d'accordo`, value: `Sempre d'accordo` },
    { label: `Spesso d'accordo`, value: `Spesso d'accordo` },
    { label: `Qualche volta d'accordo`, value: `Qualche volta d'accordo` },
    { label: `Raramente d'accordo`, value: `Raramente d'accordo` },
    { label: `Mai d'accordo`, value: `Mai d'accordo` },
]

const MainTpaTestPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const tests = useSelector(({ tpa }) => tpa.allTpa);
    const [finishTheTest, setFinishTheTest] = useState(false);
    const [rest, setRest] = useState(0);
    const [theTest, setTheTest] = useState();

    useEffect(() => {
        if (params.type.split(' ')[0] === 'test-grafici') {
            const pathArr = params.type.split(' ');
            dispatch(bringTpaExercises(params.type.split(' ')[0], pathArr.slice(2, pathArr.length).join(' ')));
        } else {
            dispatch(bringTpaExercises(params.type.split(' ')[0]));
        }
    }, [dispatch]);

    // useEffect(() => {
    //     if (tests !== undefined) {
    //         setTheTest(JSON.parse(tests.quiz_questions));
    //     }
    // }, [tests]);

    useEffect(() => {
        setTheTest(tests);
    }, [tests]);

    // console.log('TPA', theTest);
    // console.log('TPA PARAMS', params);

    return (
        theTest !== undefined &&
        <WrapperRightSide>
            <NavBar
                total={theTest.length}
                // rest={theTest.length - 1}
                flagExercises='tpa'
                rest={rest}
            />

            {/* <TestPageWithDrawing
                tests={theTest}
                setRest={setRest}
                // testID={details.idTestExercises}
                // testID={tests.idTestExercises}
                setFinishTheTest={setFinishTheTest}
                rest={rest}
            /> */}

            {(params.type.split(' ')[0] === 'wartegg' || params.type.split(' ')[0] === 'test-grafici') ?
                <TestPageWithDrawing
                    tests={theTest}
                    setRest={setRest}
                    // testID={details.idTestExercises}
                    // testID={tests.idTestExercises}
                    setFinishTheTest={setFinishTheTest}
                    rest={rest}
                    idCourse={params.type.split(' ')[1]}
                    type={params.type.split(' ')[0]}
                    tpaType={params.type.split(' ')[0]}
                />
                :
                (params.type.split(' ')[0] === 'bhs' || params.type.split(' ')[0] === 'mmpi' || params.type.split(' ')[0] === 'sigma03' || params.type.split(' ')[0] === 'scid2' || params.type.split(' ')[0] === 'temps-a' || params.type.split(' ')[0] === 'millon') ?
                    <TpaSwitchTest
                        tests={theTest}
                        setRest={setRest}
                        // testID={details.idTestExercises}
                        // testID={tests.idTestExercises}
                        setFinishTheTest={setFinishTheTest}
                        rest={rest}
                        type={'bool'}
                        opt={params.type.split(' ')[0] === 'scid2' ? boolScidOptions : boolOptions}
                        course={params.type.split(' ')[1]}
                        tpaType={params.type.split(' ')[0]}
                    /> :
                    (params.type.split(' ')[0] === 'frasi-da-completare' || params.type.split(' ')[0] === 'biografico') ?
                        <TpaSwitchTest

                            tests={theTest}
                            setRest={setRest}
                            // testID={details.idTestExercises}
                            // testID={tests.idTestExercises}
                            setFinishTheTest={setFinishTheTest}
                            rest={rest}
                            type={'free'}
                            opt={''}
                            course={params.type.split(' ')[1]}
                            tpaType={params.type.split(' ')[0]}
                        /> :
                        (params.type.split(' ')[0] === 'neo-pi3' || params.type.split(' ')[0] === 'ciss' || params.type.split(' ')[0] === 'pfs' || params.type.split(' ')[0] === 'qe' || params.type.split(' ')[0] === 'taleia') ?
                            <TpaSwitchTest

                                tests={theTest}
                                setRest={setRest}
                                // testID={details.idTestExercises}
                                // testID={tests.idTestExercises}
                                setFinishTheTest={setFinishTheTest}
                                rest={rest}
                                type={'one'}
                                opt={
                                    params.type.split(' ')[0] === 'neo-pi3' ?
                                        oneNeoOptions : params.type.split(' ')[0] === 'ciss' ?
                                            oneCissOptions : params.type.split(' ')[0] === 'qe' ?
                                                oneQeOptions : multipleDifesaOptions
                                }
                                course={params.type.split(' ')[1]}
                                tpaType={params.type.split(' ')[0]}
                            /> :
                            (params.type.split(' ')[0] === 'tpc' || params.type.split(' ')[0] === 'tom' || params.type.split(' ')[0] === 'difesa-test' || params.type.split(' ')[0] === 'big-5') ?
                                <TpaSwitchTest

                                    tests={theTest}
                                    setRest={setRest}
                                    // testID={details.idTestExercises}
                                    // testID={tests.idTestExercises}
                                    setFinishTheTest={setFinishTheTest}
                                    rest={rest}
                                    type={'multiple'}
                                    opt={
                                        params.type.split(' ')[0] === 'tom' ?
                                            multipleTomOptions : params.type.split(' ')[0] === 'tpc' ?
                                                multipleTpcOptions : params.type.split(' ')[0] === 'difesa-test' ?
                                                    multipleDifesaOptions : multipleBigOptions
                                    }
                                    course={params.type.split(' ')[1]}
                                    tpaType={params.type.split(' ')[0]}
                                /> : ''
            }

            {finishTheTest &&
                <FinishPage />
            }
        </WrapperRightSide>
    );
}

export default MainTpaTestPage;


