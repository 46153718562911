import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import TypeCard from '../../GeneralComponents/Cards/TypeCard';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    Title,
    //TitleWrapper,
    //TestWrapper,
    GoBack,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const TitleWrapper = styled.div`
  /* display: flex; */
`;

const CardsWrapper = styled.div`
    width:100%;
    /* padding: 20px 0 0 110px; */
    padding: 20px 0;
    /* overflow: auto; */
    display: grid;
    /* grid-template-columns: repeat(4, auto); */
    grid-template-columns: repeat(4, 3fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: left;
    /* justify-content: space-evenly; */
    justify-content: flex-start;
    justify-items: stretch;
        &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 2900px) {
            /* grid-template-columns: repeat(3,auto); */
            grid-template-columns: repeat(3, 4fr);
        }
        @media (max-width: 1900px) {
            /* grid-template-columns: repeat(2,auto); */
            grid-template-columns: repeat(2, 6fr);
        }
        @media (max-width: 600px) {
            /* grid-template-columns: repeat(1,auto); */
            grid-template-columns: repeat(1, 12fr);
            justify-content: center;
        }
`;


const TypePage = ({ details }) => {
    const history = useHistory();
    // const [type, setType] = useState();

    // console.log('TYPE PAGE', JSON.parse(details.course.availableTests));
    // console.log("TYPE PAGE DETAILS", details);

    const setType = (flag) => {
        const initialArr = JSON.parse(details.course.availableTests);
        let newArr = [];

        if (flag !== undefined) {
            if (flag === 'Preselettiva') {
                newArr.push({
                    name: 'Prova Preselettiva',
                    icon: <i className="fa-brands fa-connectdevelop"></i>
                });
            } else if (flag === 'Tpa') {
                newArr.push({
                    name: 'Test Psico Attitudinali',
                    icon: <i className="fa-solid fa-atom"></i>
                });
            } else if (flag === 'Tema') {
                newArr.push({
                    name: 'Prova Scritta - Tema',
                    icon: <i className="fa-solid fa-book-open"></i>
                });
            } else if (flag === 'Orale') {
                newArr.push({
                    name: 'Prova Orale',
                    icon: <i className="fa-solid fa-ear-listen"></i>
                });
            } else if (flag === 'Fisica') {
                newArr.push({
                    name: 'Prova Fisica',
                    icon: <i className="fa-solid fa-person-running"></i>
                });
            };
        } else {
            for (let i = 0; i < initialArr.length; i++) {
                newArr.push({
                    // name: initialArr[i].prova,
                    name: (initialArr[i].prova === 'Preselettiva' && 'Prova Preselettiva') ||
                        (initialArr[i].prova === 'TPA' && 'Test Psico Attitudinali') ||
                        (initialArr[i].prova === 'TEMA' && 'Prova Scritta - Tema') ||
                        (initialArr[i].prova === 'Orale' && 'Prova Orale') ||
                        (initialArr[i].prova === 'Fisica' && 'Prova Fisica'),

                    icon:
                        (initialArr[i].prova === 'Preselettiva' && <i className="fa-brands fa-connectdevelop"></i>) ||
                        (initialArr[i].prova === 'TPA' && <i className="fa-solid fa-atom"></i>) ||
                        (initialArr[i].prova === 'TEMA' && <i className="fa-solid fa-book-open"></i>) ||
                        (initialArr[i].prova === 'Orale' && <i className="fa-solid fa-ear-listen"></i>) ||
                        (initialArr[i].prova === 'Fisica' && <i className="fa-solid fa-person-running"></i>)
                });
            }
        };

        return newArr;

    }

    // useEffect(() => {
    //     const initialArr = JSON.parse(details.course.availableTests);
    //     let newArr = [];

    //     if (details.flag !== undefined) {
    //         if (details.flag = 'Preselettiva') {
    //             newArr.push({
    //                 name: 'Prova Preselettiva',
    //                 icon: <i className="fa-brands fa-connectdevelop"></i>
    //             });
    //         } else if (details.flag === 'Tpa') {
    //             newArr.push({
    //                 name: 'Test Psico Attitudinali',
    //                 icon: <i className="fa-solid fa-atom"></i>
    //             });
    //         } else if (details.flag === 'Tema') {
    //             newArr.push({
    //                 name: 'Prova Scritta - Tema',
    //                 icon: <i className="fa-solid fa-book-open"></i>
    //             });
    //         } else if (details.flag === 'Orale') {
    //             newArr.push({
    //                 name: 'Prova Orale',
    //                 icon: <i className="fa-solid fa-ear-listen"></i>
    //             });
    //         } else if (details.flag === 'Fisica') {
    //             newArr.push({
    //                 name: 'Prova Fisica',
    //                 icon: <i className="fa-solid fa-person-running"></i>
    //             });
    //         };
    //     } else {
    //         for (let i = 0; i < initialArr.length; i++) {
    //             newArr.push({
    //                 // name: initialArr[i].prova,
    //                 name: (initialArr[i].prova === 'Preselettiva' && 'Prova Preselettiva') ||
    //                     (initialArr[i].prova === 'TPA' && 'Test Psico Attitudinali') ||
    //                     (initialArr[i].prova === 'TEMA' && 'Prova Scritta - Tema') ||
    //                     (initialArr[i].prova === 'Orale' && 'Prova Orale') ||
    //                     (initialArr[i].prova === 'Fisica' && 'Prova Fisica'),

    //                 icon:
    //                     (initialArr[i].prova === 'Preselettiva' && <i className="fa-brands fa-connectdevelop"></i>) ||
    //                     (initialArr[i].prova === 'TPA' && <i className="fa-solid fa-atom"></i>) ||
    //                     (initialArr[i].prova === 'TEMA' && <i className="fa-solid fa-book-open"></i>) ||
    //                     (initialArr[i].prova === 'Orale' && <i className="fa-solid fa-ear-listen"></i>) ||
    //                     (initialArr[i].prova === 'Fisica' && <i className="fa-solid fa-person-running"></i>)
    //             });
    //         }
    //     };

    //     setType(newArr);

    // }, [details])

    // const type = [
    //     {
    //         name: 'Prova Preselettiva',
    //         icon: <i className="fa-brands fa-connectdevelop"></i>,
    //     },
    //     {
    //         name: 'TPA',
    //         icon: <i className="fa-solid fa-atom"></i>,
    //     },
    //     {
    //         name: 'Tema',
    //         icon: <i className="fa-solid fa-suitcase"></i>,
    //     },
    //     {
    //         name: 'Prova Orale',
    //         icon: <i className="fa-solid fa-ear-listen"></i>,
    //     },
    //     {
    //         name: 'Prova Fisiche',
    //         icon: <i className="fa-solid fa-person-running"></i>,
    //     },
    // ];

    const goBack = () => {
        if (details.flag !== undefined) {
            history.goBack()
        } else {
            history.replace({
                pathname: '/dashboard',
            })
        }
    }


    return (
        <WrapperRightSide>

            <TitleWrapper>
                {/* <GoBack onClick={() => history.goBack()}> */}
                {/* <GoBack onClick={() => history.replace({
                    pathname: '/dashboard',
                    //state: { course: details.course, title: details.course.course_name, },
                })}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack> */}

                <GoBack onClick={goBack}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                <Title>{details.course.course_name} - {details.course.course_code}</Title>
            </TitleWrapper>

            <CardsWrapper>
                {setType(details.flag).map((ty, i) =>
                    <TypeCard
                        key={i}
                        ty={ty}
                        course={details.course}
                    />
                )}
            </CardsWrapper>

        </WrapperRightSide>
    );
}

export default TypePage;