import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// PACKAGES
// import Select from 'react-select';
import styled from 'styled-components';

// COMPONENTS
import DeleteModal from '../../GeneralComponents/Modals/DeleteModal';
// import ToggleSwitchButton from '../../../GeneralComponents/Button/ToggleSwitchButton';

// REDUX
// import { useDispatch } from 'react-redux';
// import { getKits } from '../../../../Store/Actions/kitsActions';

import {
    Td,
    // ExpandTableRates,
    ExpandBtn,
    TrDiv,
    // LinkDiv,
    PopUp2,
    EditWrapper,
    Edit,
    TdHeader,
    // PopUpTd,
    // colourStyles
} from '../PagesShareStyle/PagesShareStyle';

const Sts = styled.div`
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    max-width: 90px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)') ||
        (props.color === 'In attesa' && 'var(--background1)')
    };
`;


const TestDetailsTable = ({ elem, teacherActivity, i }) => {
    //console.log("ELEM ", elem)

    return (
        <>
            < tr >
                <Td>{elem.type}</Td>
                <Td>{elem.total}</Td>
                <Td >
                    <div style={{ display: 'flex' }}>
                        <EditWrapper>
                            <Edit
                                to={{
                                    pathname: '/dashboard/teachers/type-details',
                                    state: { elem, teacherActivity, flag: 'course' }
                                }}
                            >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                            </Edit>
                            <PopUp2>Di più</PopUp2>
                        </EditWrapper>
                    </div>
                </Td>
            </tr >
        </>
    )
}

export default TestDetailsTable;

