import React, { useState } from 'react';

// PACKAGES
// import styled from 'styled-components';
import CalendarJs from '../SideBarCalendar/SideBarComponents/Calendar';

// STYLE
import {
    InputCss,
    TextArea,
    LabelCss,
    Negative,
    CalendarSPlace,
    BtnWrapper,
    Btn,
    // Status,
    PopUp,
    UploadPozWrapper,
    UploadWrapper,
    Placeholder,
    UploadFileInput,
} from './InputsStyle';

const Inputs = ({ props, handleChange, handleFile, setDay }) => {
    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedDayIs, setSelectedDayIs] = useState();

    //console.log('POPS', props);

    const handleCalendar = () => {
        if (props.inputType === 'date') {
            setOpenCalendar(true);
        };
    };

    const handleCancel = () => {
        setOpenCalendar(false);
    };

    const handleSetTheDay = () => {
        const selectDay = new Date(selectedDayIs);
        let dd = selectDay.getDate();
        let mm = selectDay.getMonth() + 1;
        let yyyy = selectDay.getFullYear();
        const select = mm + '/' + dd + '/' + yyyy;
        setDay(select);
        handleCancel();
    };

    const showMore = (txt) => {
        if (txt !== 'undefined' && txt !== undefined) {
            if (txt.length > 25) {
                return (
                    <>
                        {`${txt.slice(0, 25)}...`}
                        <PopUp>{txt}</PopUp>
                    </>
                )
            } else {
                return txt
            }
        }
    };

    const takeFileName = (obj) => {
        const arr = obj.split('/');
        // console.log('HAHAHAH', obj);
        const name = arr[arr.length - 1];
        return name
    };

    // console.log('FILE', props.value.name);
    //console.log('PROPS', props.value);

    return (
        <>
            {props.inputType !== 'file' ?
                props.inputType !== 'textarea' ?
                    <>
                        <LabelCss htmlFor={props.label}>{props.label}</LabelCss>
                        <InputCss
                            placeholder={props.placeholder}
                            type={props.inputType === 'date' ? 'text' : props.inputType}
                            name={props.name}
                            required
                            readOnly={props.inputType === 'date' ? true : false}
                            autoComplete='off'
                            value={props.value}
                            onChange={handleChange}
                            onClick={() => handleCalendar()}
                            onWheel={(e) => e.target.blur()}
                        >
                        </InputCss>
                    </>
                    :
                    <>
                        <LabelCss htmlFor={props.label}>{props.label}</LabelCss>
                        <TextArea
                            placeholder={props.placeholder}
                            name={props.name}
                            required
                            autoComplete='off'
                            value={props.value}
                            onChange={handleChange}
                            onClick={() => handleCalendar()}
                            onWheel={(e) => e.target.blur()}
                        >
                        </TextArea>
                    </>
                :
                <>
                    <UploadPozWrapper>
                        <LabelCss htmlFor='upload'>{props.label}</LabelCss>
                        <UploadWrapper>
                            {/* <h5>{props.value}</h5> */}
                            <span color={props.value}><i className="fas fa-upload"></i></span>

                            <UploadFileInput
                                type="file"
                                name={props.name}
                                // id="upload"
                                // placeholder="Caricare un file..."
                                onChange={handleFile}
                            />
                            {props.value === '' ?
                                <Placeholder>Caricare...</Placeholder>
                                :
                                < h5>{showMore(props.value.name !== undefined ? props.value.name : takeFileName(props.value))}</h5>
                            }
                        </UploadWrapper>
                    </UploadPozWrapper>
                </>
            }

            {openCalendar &&
                <>
                    <Negative
                        open={openCalendar}
                        onClick={handleCancel}
                    />
                    <CalendarSPlace>
                        <CalendarJs
                            setSelectedDayIs={setSelectedDayIs}
                        />
                        <BtnWrapper>
                            <Btn cancel onClick={handleCancel}>Annulla</Btn>
                            <Btn onClick={handleSetTheDay}>Ok</Btn>
                        </BtnWrapper>
                    </CalendarSPlace>
                </>
            }
        </>
    );
}

export default Inputs;