import React, { useState, useEffect } from 'react';

// PACKEGES
import styled from 'styled-components';

// REDUX
import { useDispatch } from 'react-redux';
import { sendTestMessage, sendSimulationMessage } from '../../../Store/Actions/testActions';

// SHARE STYLE
import {
    // IconWrapper,
    PopUp,
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
} from './ModalStyle';

const H4 = styled.h4`
    width:100%;
    text-align: center;
    color: var(--text3);
    padding: 10px;
`;

const IconWrapper = styled.div`
    position: fixed;
    bottom: 12px;
    right: 0px;
    z-index: 2;
    margin: 0 5px;
    background-color: var(--background4);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >i{
            font-size: 24px;
            color:var(--text1);
            padding: 15px;
                &:hover{
                    color:var(--err);
            }
        }
        &:hover ${PopUp}{
                        opacity: 1;
                        visibility: visible;
                    }
`;

const TextareaCss = styled.textarea`
    width: 100%;
    height: 150px;
    padding: 6px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    margin-top:10px;
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
    resize: none;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border: 1px solid var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

const LabelCss = styled.label`
    display:flex;
    align-items: center;
    color: var(--text1);
    margin: 20px 10px 0;
`;

const Err = styled.div`
    height: 20px;
    >h6{
        color: var(--err);
        letter-spacing: 1px;
        text-align: right;
        font-size: 16px;
    }
`;

// const Btns = styled.button`
//     width: 200px;
//     height:50px;
//     margin-right: 10px;
//     display:flex;
//     align-items: center;
//     justify-content:center;
//     color:var(--text2);
//     font-weight:bold;
//     font-size: 14px;
//     border-radius: var(--radius);
//     margin-top: 40px;
//     margin-bottom:20px;
//     letter-spacing:2px;
//     cursor: pointer;
//     transition: var(--transition) ease;
//     background: var(--background4);
//     border: none;
//     outline:none;
//     text-decoration: none;
//     box-shadow: var(--shadow);
//         >i{
//             margin-right: 10px;
//             font-size: 20px;
//         }
//         &:hover{
//             box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
//         }
//         &:active{
//             box-shadow: none;
//         }
// `;


const DeleteModal = ({ title, icon, id, tests, flag }) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');
    const [err, setErr] = useState(false);

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
        setMessage('');
        setErr(false);
    };

    const handleChange = evt => {
        setMessage(evt.target.value)
    };

    // console.log('PROPS', props);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message !== '') {
            const question = tests.find((idQue, index) => index === id);

            if (flag === 'test') {
                dispatch(sendTestMessage(message, question.idMatContent));
            } else if (flag === 'simulation') {
                dispatch(sendSimulationMessage(message, question.idMatContent))
            };
            // console.log('MODAL', question);
            handleClose(e);
        } else {
            setErr(true);
        }

    };

    // console.log('MODAL', id, tests);


    return (
        <>
            <IconWrapper style={{ color: 'var(--err)' }} onClick={() => setOpenModal(!openModal)}>
                <PopUp>{title}</PopUp>
                {icon}
            </IconWrapper>

            {/* <Btns  onClick={() => setOpenModal(!openModal)}>{props.name}</Btns> */}

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>{title}</H3>
                        <Hr></Hr>

                        <LabelCss htmlFor="message">Messaggio</LabelCss>
                        <TextareaCss
                            placeholder="Dicci qual è l'errore"
                            type="text"
                            name='message'
                            required
                            value={message}
                            onChange={(e) => handleChange(e)}
                        />
                        <Err>
                            {err &&
                                <h6>Si prega di segnalare l'errore</h6>
                            }
                        </Err>

                        <BtnWrapper>
                            <Btn disable onClick={handleClose}>Annulla</Btn>
                            <Btn onClick={handleSubmit}>Inviare</Btn>
                        </BtnWrapper>
                    </DialogBox>
                </>
            }

        </>

    );
}
export default DeleteModal;