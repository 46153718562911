import { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedQuestionsForTest, saveTest, resetTest } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../PagesShareStyle/PagesShareStyle';


const PreTpa = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const tests = useSelector(({ test }) => test.selectedQuestions);
    // const savedTest = useSelector(({ test }) => test.savedTest);

    // console.log('SAVE TEST', savedTest);
    // console.log('Pre TPA details', details);

    const formatPath = (path) => {
        const newPath = path.split(' ').join('-');
        const final = newPath.toLowerCase();
        return final
    };

    // useEffect(() => {
    //     dispatch(getSelectedQuestionsForTest(details.newArr));
    // }, [dispatch]);

    // useEffect(() => {
    //     if (tests !== undefined) {
    //         dispatch(saveTest(tests, details.course, details.flag));
    //     }
    // }, [tests])

    // 'MMPI','TOM', 'TEST GRAFICI', 'WARTEGG', 'FRASI DA COMPLETARE', 'NEO PI3', 'OPQ 32',  'TPC', 'BHS',

    // 'PFS','BIOGRAFICO', 'EQI', 'SIGMA03',  'SCID2', 'DIFESA TEST', 'CISS'

    // useEffect(() => {
    //     if (savedTest !== undefined) {
    //         // history.push('/dashboard/create-quiz/test', savedTest);

    //         setTimeout(() => {
    //             const url = '/dashboard/create-quiz/test/' + savedTest.idTestExercises;
    //             const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    //             if (newWindow) newWindow.opener = null;
    //             dispatch(resetTest())
    //             history.goBack()
    //         }, 2000)
    //     }
    // }, [savedTest]);

    // console.log("details", details);

    useEffect(() => {
        setTimeout(() => {
            let url;
            if (details.type === 'WARTEGG') {
                url = `/dashboard/tpa-grafici/${formatPath(details.type)} ${details.course.idCourse}`;
            };

            if (details.type === 'TEST GRAFICI') {
                url = `/dashboard/tpa-grafici/${formatPath(details.type)} ${details.course.idCourse} ${details.choose}`;
            };

            // if (details.type === 'WARTEGG' || details.type === 'TEST GRAFICI') {
            //     url = `/dashboard/tpa-grafici/${formatPath(details.type)} ${details.course.idCourse}`;
            // };

            if (details.type === 'BHS') {
                url = `/dashboard/tpa-bool/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'MMPI') {
                url = `/dashboard/tpa-bool/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'SIGMA03') {
                url = `/dashboard/tpa-bool/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'SCID2') {
                url = `/dashboard/tpa-bool/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'FRASI DA COMPLETARE') {
                url = `/dashboard/tpa-free/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'BIOGRAFICO') {
                url = `/dashboard/tpa-free/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'NEO PI3') {
                url = `/dashboard/tpa-one/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'CISS') {
                url = `/dashboard/tpa-one/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'PFS') {
                url = `/dashboard/tpa-one/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'TPC') {
                url = `/dashboard/tpa-multiple/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'TOM') {
                url = `/dashboard/tpa-multiple/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'DIFESA TEST') {
                url = `/dashboard/tpa-multiple/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'BIG 5') {
                url = `/dashboard/tpa-multiple/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'TEMPS-A') {
                url = `/dashboard/tpa-bool/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'MILLON') {
                url = `/dashboard/tpa-bool/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'TALEIA') {
                url = `/dashboard/tpa-multiple/${formatPath(details.type)} ${details.course.idCourse}`;
            };
            if (details.type === 'QE') {
                url = `/dashboard/tpa-multiple/${formatPath(details.type)} ${details.course.idCourse}`;
            };

            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
            // dispatch(resetTest())
            history.goBack()
        }, 2000)
    }, [dispatch]);

    return (
        <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img src={Loading} alt="Loading..."></img>
            <div>Inizia {details.type} test...</div>
        </WrapperRightSide>
    );
}

export default PreTpa;