import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    TestWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';


const Lesson = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background4);
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    border-bottom: 1px solid #b5b3b340;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const Left = styled.div`
    display: flex;
`;

const Right = styled.div`
    >span {
        font-size: 14px;
    }
    >i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) ${props => props.open && 'rotate(-90deg)'};
        color: var(--text3);
        transition: all .1s ease-in-out;
    }
`;

const Sts = styled.div`
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 2px;
    width: 70px;
    height: 24px;
    margin-right: 10px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)') ||
        (props.color === 'In attesa' && 'var(--background1)')
    }; */
     background-color: ${props =>
        (props.color === 0 && 'var(--text2)') ||
        (props.color <= 40 && 'var(--err)') ||
        (props.color > 40 && props.color <= 99 && 'var(--warning)') ||
        (props.color >= 100 && 'var(--success)')
    };
`;

const LessonsSubMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--background1);
    overflow: hidden;
    height: ${props => props.opened === 'true' ? ((props.tall * 60) + props.tall * 1) : 0}px;
    transition: height 0.4s ease;
`;

const BtnWrapper = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #5c5c5c91;
    text-decoration: none;
        &:hover{
            background: var(--text3);
        }
`;

const LessonsBtns = styled.div`
    min-height: 60px;
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    color: var(--text5);
`;

const PhysicalLessons = ({ mat, idCheckUser, studDetails, course, }) => {
    const dispatch = useDispatch();
    const [openLessons, setOpenLessons] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalPerLesson, setTotalPerLesson] = useState(0);

    useEffect(() => {
        if (mat !== undefined) {
            let subjectNr = {
                nrLessons: mat.PhisicalSubjects_has_PhisicalLessons.length,
                videoCompleted: 0,
                questCompleted: 0,
                totalQuest: 0,
            };

            if (mat.PhisicalSubjects_has_PhisicalLessons.length > 0) {
                let nrCompelete = 0;
                let nrQuestCompleted = 0;
                let totalQue = 0;
                for (let j = 0; j < mat.PhisicalSubjects_has_PhisicalLessons.length; j++) {
                    const nr = mat.PhisicalSubjects_has_PhisicalLessons[j].PhisicalLessonCompletes ? mat.PhisicalSubjects_has_PhisicalLessons[j].PhisicalLessonCompletes.length : 0;
                    //const nr = 0;
                    if (mat.PhisicalSubjects_has_PhisicalLessons[j].ondemand !== null) {
                        nrCompelete += nr;
                    } else {
                        nrCompelete += 1;
                    };

                    const questDone = mat.PhisicalSubjects_has_PhisicalLessons[j].PhisicalQuestionnaires;
                    //const questDone = []
                    const questNr = questDone.length;
                    totalQue += questNr;
                    for (let k = 0; k < questDone.length; k++) {
                        const nr = questDone[k].PhisicalQuestionnaireDones.length;
                        nrQuestCompleted += nr;
                    };
                };

                subjectNr.videoCompleted = nrCompelete;
                subjectNr.questCompleted = nrQuestCompleted;
                subjectNr.totalQuest = totalQue;
            };

            // console.log('Nr subject', subjectNr);
            let lessonsPercent = 0;
            let questsPercent = 0;
            let videoPercent = 0;

            if (subjectNr.videoCompleted > 0) {
                videoPercent += (0.5 * subjectNr.videoCompleted) / subjectNr.nrLessons;
            };
            lessonsPercent += videoPercent;

            questsPercent += (subjectNr.totalQuest !== 0 && subjectNr.questCompleted !== 0) ?
                ((subjectNr.questCompleted / subjectNr.totalQuest) * 0.5)
                :
                0;
            // console.log('lessonsPercent', lessonsPercent);
            // console.log('questPercent', questsPercent);

            const totalPercent = (lessonsPercent + questsPercent).toFixed(2) * 100;
            // console.log('totalPercent', totalPercent);

            setTotal(totalPercent);
            // setTotalPerLesson(totalPerLesson);
        };
    }, [mat]);

    const stsLesson = (less) => {
        let lessonNr = {
            videoCompleted: 0,
            questCompleted: 0,
            totalQuest: 0,
        };

        let nrCompelete = 0;
        let nrQuestCompleted = 0;
        let totalQue = 0;

        //console.log("LESSSSS", less)
        const nr = less.PhisicalLessonCompletes.length;

        // if (mat.OralSubjects_has_OralLessons.ondemand !== null) {
        if (less.ondemand !== null) {
            nrCompelete += nr;
        } else {
            nrCompelete += 1;
        };

        const questDone = less.PhisicalQuestionnaires;
        //const questDone = []
        const questNr = questDone.length;
        totalQue += questNr;
        for (let k = 0; k < questDone.length; k++) {
            const nr = questDone[k].PhisicalQuestionnaireDones.length;
            nrQuestCompleted += nr;
        };

        lessonNr.videoCompleted = nrCompelete;
        lessonNr.questCompleted = nrQuestCompleted;
        lessonNr.totalQuest = totalQue;

        // console.log('Nr lesson', lessonNr);
        let lessonsPercent = 0;
        let questsPercent = 0;
        let videoPercent = 0;

        if (lessonNr.videoCompleted > 0) {
            videoPercent += 0.5;
        };
        lessonsPercent += videoPercent;

        questsPercent += (lessonNr.totalQuest !== 0 && lessonNr.questCompleted !== 0) ?
            ((lessonNr.questCompleted / lessonNr.totalQuest) * 0.5)
            :
            0;
        // console.log('lessonsPercent', lessonsPercent);
        // console.log('questPercent', questsPercent);

        const totalPercent = (lessonsPercent + questsPercent).toFixed(2) * 100;
        // console.log('totalPercent', totalPercent);
        return (totalPercent)
    };

    //console.log("MAT", mat);
    // console.log("mat", mat.lessons);
    // console.log("total", total);


    return (
        <>
            <Lesson onClick={() => setOpenLessons(!openLessons)}>
                <Left>
                    <Sts color={total}>
                        {total === 0 && 'In attesa'}
                        {total > 0 && total < 40 && 'In corso'}
                        {(total > 40 && total <= 99) && 'In corso'}
                        {total === 100 && 'Completo'}
                    </Sts>
                    {mat.name}
                </Left>

                <Right
                    open={openLessons}
                >
                    <span>{mat.PhisicalSubjects_has_PhisicalLessons.length} lezioni</span>
                    {mat.PhisicalSubjects_has_PhisicalLessons.length > 0 &&
                        <i className="fa-solid fa-angle-left"></i>
                    }
                </Right>
            </Lesson>

            <LessonsSubMenu
                tall={`${mat.PhisicalSubjects_has_PhisicalLessons !== undefined && mat.PhisicalSubjects_has_PhisicalLessons.length}`}
                opened={openLessons === true ? 'true' : 'false'}
            >
                {mat.PhisicalSubjects_has_PhisicalLessons.map((less, i) =>
                    <BtnWrapper
                        key={i}
                        to={{
                            pathname: '/dashboard/prova-physical/lesson',
                            state: {
                                less,
                                idCheckUser: idCheckUser,
                                studDetails: studDetails,
                                course
                            }
                        }}
                    >
                        <LessonsBtns>
                            {mat.name} - {less.name}
                        </LessonsBtns>
                        {/* <Sts color={less.sts}>{less.sts}{console.log(stsLesson(less))}</Sts> */}

                        <Sts color={stsLesson(less)}>
                            {stsLesson(less) === 0 && 'In attesa'}
                            {stsLesson(less) > 0 && stsLesson(less) < 40 && 'In corso'}
                            {(stsLesson(less) > 40 && stsLesson(less) <= 99) && 'In corso'}
                            {stsLesson(less) === 100 && 'Completo'}
                        </Sts>
                    </BtnWrapper>
                )}
            </LessonsSubMenu>
        </>
    );
}
export default PhysicalLessons;
