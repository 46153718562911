const initState = {

}

const physicalReducers = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ALL_PHYSICAL_SUBJECTS':
            return {
                ...state,
                allPhysicalSubs: action.payload,
            };

        case 'GET_ALL_PHYSICAL_QUESTIONNAIRES':
            return {
                ...state,
                allPhysicalQuest: action.payload,
            };

        case 'DOWNLOAD_FILE_PHYSICAL':
            return {
                ...state,
                file: action.payload,
            };
        default:
            return state;
    }
};

export default physicalReducers;