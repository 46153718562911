import axios from 'axios';
axios.defaults.withCredentials = true;

export const getAllCourses = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllCourses`,)
        .then((res) => {
            // console.log("ALL_COURSES", res);
            dispatch({
                type: 'ALL_COURSES',
                payload: res.data,
            });
            dispatch({
                type: 'ALL_SUBJECTS',
                payload: undefined,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const findCourse = (course) => (dispatch, getState) => {

    const body = {
        idCourse: course
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findCourse`, body)
        .then((res) => {
            // console.log("A_COURSE", res.data);
            dispatch({
                type: 'A_COURSE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};