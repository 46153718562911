import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import ProgressBar from './ProgressBar';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubjects } from '../../../Store/Actions/subjectsActions';

import {
    Td,
    PopUp2,
    EditWrapper,
    Edit,
} from '../PagesShareStyle/PagesShareStyle';


const Overview = ({ elem, i, flag }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    // const materie = useSelector(({ subjects }) => subjects.allSubjects);
    // const [url, setUrl] = useState();
    // const [lessonsTotal, setLessonsTotal] = useState(0);
    // const [lessonsCompletedTotal, setLessonsCompletedTotal] = useState(0);
    // const [totalForLessons, setTotalForLessons] = useState(0);

    // const formatData2 = (data) => {
    //     // console.log(data);
    //     let format;
    //     if (data !== '') {
    //         const d = data.split('/');
    //         format = d[1] + '/' + d[0] + '/' + d[2];
    //     } else {
    //         format = ''
    //     }
    //     return format
    // };

    // useEffect(() => {
    //     let url;
    //     if (flag = 'Preselettiva') {
    //         url = '/dashboard/type';
    //     } else if (flag === 'Tpa') {
    //         url = '/dashboard/tpa-learning';
    //     } else if (flag === 'Tema') {
    //         url = '/dashboard/tema';
    //     } else if (flag === 'Orale') {
    //         url = '/dashboard/orale';
    //     } else if (flag === 'Fisica') {
    //         url = '/dashboard/fisica';
    //     };
    //     setUrl(url);
    // }, [flag]);

    // useEffect(() => {
    //     if (flag = 'Preselettiva') {
    //         dispatch(getAllSubjects(elem.course.id_course_Course.idCourse, profile.idUser));
    //     } else if (flag === 'Tpa') {

    //     } else if (flag === 'Tema') {

    //     } else if (flag === 'Orale') {

    //     } else if (flag === 'Fisica') {

    //     };
    // }, [dispatch]);

    // const totalForLessons = (materie) => {
    //     let subjectsNr = [];

    //     for (let i = 0; i < materie.length; i++) {
    //         // subjectsNr.push(materie[i].lessons.length);
    //         subjectsNr.push({
    //             nrLessons: materie[i].lessons.length,
    //             videoCompleted: 0,
    //             questCompleted: 0,
    //             totalQuest: 0,
    //         })
    //         if (materie[i].lessons.length > 0) {
    //             let nrCompelete = 0;
    //             let nrQuestCompleted = 0;
    //             let totalQue = 0;
    //             for (let j = 0; j < materie[i].lessons.length; j++) {
    //                 const nr = materie[i].lessons[j].Lessons_idLesson_Lesson.LessonCompletes.length;
    //                 if (materie[i].lessons[j].ondemand !== null) {
    //                     nrCompelete += nr;
    //                 } else {
    //                     nrCompelete += 1;
    //                 };

    //                 const questDone = materie[i].lessons[j].Lessons_idLesson_Lesson.Questionnaires;
    //                 // totalQuest = questDone.length;
    //                 const questNr = questDone.length;
    //                 // console.log('questNr',questNr);
    //                 totalQue += questNr;
    //                 for (let k = 0; k < questDone.length; k++) {
    //                     const nr = questDone[k].QuestionnairesDones.length;
    //                     nrQuestCompleted += nr;
    //                 };
    //                 // lessonsVideoCompleted.push(materie[i].lessons[j].Lessons_idLesson_Lesson.LessonCompletes.length);
    //             };
    //             const value = [...subjectsNr]
    //             value[i].videoCompleted = nrCompelete;
    //             value[i].questCompleted = nrQuestCompleted;
    //             value[i].totalQuest = totalQue;
    //         };
    //     };

    //     // console.log('NR', subjectsNr);
    //     let totalLessons = subjectsNr.length;
    //     let totalLessonsCompleted = 0;
    //     let lessonsPercent = 0;

    //     let totalQuest = 0;
    //     let totalQuestCompleted = 0;

    //     let questsPercent = 0;

    //     for (let i = 0; i < subjectsNr.length; i++) {
    //         // totalLessons += subjectsNr[i].nrLessons;
    //         // if (subjectsNr[i].questCompleted === subjectsNr[i].totalQuest && subjectsNr[i].totalQuest > 0) {
    //         if (subjectsNr[i].questCompleted === subjectsNr[i].totalQuest) {
    //             totalLessonsCompleted += 1;
    //         };

    //         let videoPercent = 0;
    //         if (subjectsNr[i].videoCompleted > 0) {
    //             videoPercent += (0.5 * subjectsNr[i].videoCompleted) / subjectsNr[i].nrLessons;
    //         }
    //         lessonsPercent += videoPercent / subjectsNr.length;

    //         totalQuest += subjectsNr[i].totalQuest;
    //         totalQuestCompleted += subjectsNr[i].questCompleted;

    //         // questsPercent += (totalQuest !== 0 && totalQuestCompleted !== 0) ?
    //         //     ((totalQuestCompleted / totalQuest) * 0.5) / subjectsNr.length
    //         //     :
    //         //     0;

    //         questsPercent += (totalQuest !== totalQuestCompleted) ?
    //             ((totalQuestCompleted / totalQuest) * 0.5) / subjectsNr.length
    //             :
    //             0;
    //     }
    //     // console.log('totalLessons', totalLessons);
    //     // console.log('totalLessonsCompleted', totalLessonsCompleted);
    //     // console.log('lessonsPercent', lessonsPercent);

    //     // console.log('questPercent', questsPercent);

    //     const totalPercent = (lessonsPercent + questsPercent).toFixed(2) * 100;

    //     // console.log('totalPercent', totalPercent);

    //     const total = {
    //         totalLessons: totalLessons,
    //         totalLessonsCompleted: totalLessonsCompleted,
    //         totalPercent: totalPercent,
    //     }
    //     // setLessonsTotal(totalLessons);
    //     // setLessonsCompletedTotal(totalLessonsCompleted);
    //     // setTotalForLessons(totalPercent);

    //     return total;
    // };

    // console.log('OVERVIEW', elem);
    // console.log('OVERVIEW STAT',statistics);
    // console.log('OVERVIEW MATERIA', materie);
    // console.log('OVERVIEW FLAG', flag);

    return (
        < tr >
            {flag === 'Preselettiva' &&
                <>
                    <Td>{elem.course.id_course_Course.course_name}</Td>
                    <Td>{elem.course.id_course_Course.course_code}</Td>
                    <Td>{elem.simulations.length}</Td>
                    {/* <Td>
                            <ProgressBar
                                total={elem.totalSimulations}
                            />
                        </Td> */}
                    <Td>{elem.tests.length}</Td>
                    <Td>
                        <ProgressBar
                            total={elem.totalTestsPercent}
                        />
                    </Td>
                    <Td>
                        <ProgressBar
                            total={elem.totalLessonsStats.totalPercent}
                        />
                    </Td>
                    <Td >
                        <div style={{ display: 'flex' }}>
                            <EditWrapper>
                                <Edit
                                    to={{
                                        pathname: '/dashboard/type',
                                        state: { course: elem.course.id_course_Course, flag: flag }
                                    }}
                                >
                                    <i className="fa-solid fa-right-from-bracket"></i>
                                </Edit>
                                <PopUp2>Vai al corso</PopUp2>
                            </EditWrapper>
                        </div>
                    </Td>
                </>
            }

            {flag === 'Tpa' &&
                <>
                    <Td>{elem.course.idCourse_Course.course_name}</Td>
                    <Td>{elem.course.idCourse_Course.course_code}</Td>
                    <Td>
                        <ProgressBar
                            total={elem.totalTpaPercent}
                        />
                    </Td>
                    <Td >
                        <div style={{ display: 'flex' }}>
                            <EditWrapper>
                                <Edit
                                    to={{
                                        pathname: '/dashboard/type',
                                        state: { course: elem.course.idCourse_Course, flag: flag }
                                    }}
                                >
                                    <i className="fa-solid fa-right-from-bracket"></i>
                                </Edit>
                                <PopUp2>Vai al corso</PopUp2>
                            </EditWrapper>
                        </div>
                    </Td>
                </>
            }

            {flag === 'Orale' &&
                elem.subjects.length > 0 &&
                <>
                    <Td>{elem.course.course_name}</Td>
                    <Td>{elem.course.course_code}</Td>
                    <Td>
                        <ProgressBar
                            total={elem.totalOralePercent}
                        />
                    </Td>
                    <Td >
                        <div style={{ display: 'flex' }}>
                            <EditWrapper>
                                <Edit
                                    to={{
                                        pathname: '/dashboard/type',
                                        state: { course: elem.course, flag: flag }
                                    }}
                                >
                                    <i className="fa-solid fa-right-from-bracket"></i>
                                </Edit>
                                <PopUp2>Vai al corso</PopUp2>
                            </EditWrapper>
                        </div>
                    </Td>
                </>
            }

        </tr >
    )
}

export default Overview;

