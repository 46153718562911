import React from 'react';

// PACKEGES
import styled from 'styled-components';

// COMPONENTS
// import InputCss from '../../GeneralComponents/Inputs/Inputs';

// REDUX
// import { useDispatch } from 'react-redux';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
} from './ModalStyle';

const Body = styled.div`
   padding: 10px;
   color: var(--text3);
`;


const DeleteModal = ({ title, body, setOpenAlerModal, setAction }) => {
    // const dispatch = useDispatch();

    const handleClose = (e) => {
        e.preventDefault();
        setOpenAlerModal(false);
        setAction(false);
    };

    const handleAction = evt => {
        setAction(true)
    };

    return (
        <>
            <ModalWrapper onClick={(e) => handleClose(e)} />

            <DialogBox>
                <H3>{title}</H3>
                <Hr></Hr>

                <Body>
                    Sei sicuro di voler abbandonare questa pagina? Ti ricordiamo che il lavoro fino ad ora svolto andrà perso!
                </Body>

                <BtnWrapper>
                    <Btn onClick={handleClose}>Annulla</Btn>
                    <Btn disable onClick={handleAction}>Sì</Btn>
                </BtnWrapper>
            </DialogBox>
        </>

    );
}
export default DeleteModal;