import { useEffect, useState } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../GeneralComponents/AllQuestions/AllQuestions';
import FooterBarForDrawing from '../../../GeneralComponents/TestViewsBars/FooterBarForDrawing';
import Drawing from './Drawing';


// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { saveTpaTest } from '../../../../Store/Actions/tpaActions';
import { getAllTeachers } from '../../../../Store/Actions/usersActions';
import { findCourse } from '../../../../Store/Actions/coursesActions';
import { newNotifications } from '../../../../Store/Actions/notificationsActions';

// STYLE
import {
    // WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../PagesShareStyle/PagesShareStyle';


const TestPageWithDrawing = ({ tests, setRest, setFinishTheTest, idCourse, type, tpaType }) => {
    const dispatch = useDispatch();
    const whoAmI = useSelector(({ auth }) => auth.user)
    const socket = useSelector(({ conversations }) => conversations.socket);
    const allCenterTeachers = useSelector(({ users }) => users.allCenterTeachers);
    const aCourse = useSelector(({ courses }) => courses.aCourse);
    const [id, setId] = useState(0);
    const [obj, setObj] = useState([]);

    useEffect(() => {
        let createObj = [];
        for (let i = 0; i < tests.length; i++) {
            createObj.push({})
        }
        setObj(createObj);
    }, [tests]);

    const setAnswer = (e, i) => {
        // console.log(e);
        const value = [...obj]
        value[id].asw = e.e;
        value[id].svg = e.svg;
        value[id].aswIndex = i;
        value[id].file = e.file;
        value[id].png = e.png;

        setObj(value);

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);
        // console.log(res)
    };

    useEffect(() => {
        if (whoAmI.role === 'STUDENTE') {
            dispatch(getAllTeachers(whoAmI));
            dispatch(findCourse(idCourse));
        };
    }, [whoAmI]);

    const submitTest = () => {
        setTimeout(() => {
            dispatch(saveTpaTest(
                type,
                tests,
                obj,
                whoAmI.idUser,
                idCourse,

                allCenterTeachers,
                whoAmI,
                aCourse[0],
                tpaType,
                1
            ));

            socket.emit('sendNotifToProf', {
                senderId: whoAmI.idUser,
                receiverId: allCenterTeachers,
                text: 'Test done!',
                author: whoAmI,
                course: aCourse[0],
                testType: tpaType
            });

            // dispatch(newNotifications(whoAmI.idUser,
            //     JSON.stringify(allCenterTeachers),
            //     whoAmI,
            //     aCourse[0],
            //     '',
            //     tpaType
            // ));
        }, 1000);
        setFinishTheTest(true);
        setTimeout(() => {
            window.close();
        }, 5000);
    };

    // console.log("TYPE", type);
    // console.log('OBJ', obj);
    // console.log("ID COURSE", idCourse);

    return (
        <>
            <TestWrapper draw >
                <AllQuestionsSideBar
                    id={id}
                    setId={setId}
                    //difficulty={tests[id].difficulty}
                    difficulty={''}
                    arr={tests}
                    obj={obj}
                    flag={'draw'}
                />

                {tests !== undefined &&
                    <Drawing
                        tests={tests}
                        id={id}
                        setAnswers={setAnswer}
                        obj={obj}
                    />
                }
            </TestWrapper>

            <FooterBarForDrawing
                id={id}
                setId={setId}
                tests={tests}
                submitTest={submitTest}
                obj={obj}
            />
        </>
    );
}

export default TestPageWithDrawing;
