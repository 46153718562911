import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

//COMPONENTS
import AllQuestionsSideBarDone from './AllQuestionSideBarDone'
import FooterBarForDone from '../../../GeneralComponents/TestViewsBars/FooterBarForDone';
// import FooterBarForStud2 from '../../../GeneralComponents/TestViewsBars/FooterBarForStud2';
import FooterBarForProf from '../../../GeneralComponents/TestViewsBars/FooterBarFotVerification';
import QuestionBool from '../QuestionBool';
import QuestionFree from '../QuestionFree';
import QuestionOne from '../QuestionOne';
import MultipleAnswers from '../MultipleAnswers';
import AllQuestionsSideBarForProfTPA from '../../../GeneralComponents/AllQuestions/AllQuestionsForVerificationTPA';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
    saveExistingTpaTest,
    saveTpaVerification,
    getAllTpaTestDone
} from '../../../../Store/Actions/tpaActions';
import { newNotifications, newNotificationsForProf, updateNotifications } from '../../../../Store/Actions/notificationsActions';
import { getAllTeachers } from '../../../../Store/Actions/usersActions';
import { findCourse } from '../../../../Store/Actions/coursesActions';
// import { getTestTpaDoneForStudent } from '../../../../Store/Actions/tpaActions';

//STYLE
// import { TestWrapper } from '../../PagesShareStyle/PagesShareStyle';

const GradeWrapper = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
        >span{
            background: ${props => props.sts ? 'var(--text1)' : 'var(--warning)'};
            color: var(--text5);
            border-radius: 50px;
            padding: 5px 20px;
        }
`;

const TestWrapper = styled.div`
    position: relative;
    width: calc(100% - 230px);
    margin-top: ${props => props.user === true ? '80px' : '20px'};
    height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
    background: var(--background2);
    overflow: auto;
        @media (max-width: 1100px) {
            width: 100%;
        };
`;

const TpaSwitchTestDone = ({ tests,
    setRest,
    setFinishTheTest,
    type,
    tpaType,
    opt,
    course,
    done,
    idDone,
    idTpa,
    doneAllObj,
    id,
    setId,
    allDetails }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const whoAmI = useSelector(({ auth }) => auth.user);
    const socket = useSelector(({ conversations }) => conversations.socket);
    const allCenterTeachers = useSelector(({ users }) => users.allCenterTeachers);
    const aCourse = useSelector(({ courses }) => courses.aCourse);
    // const tpaDoneForStudent = useSelector(({ tpa }) => tpa.tpaDoneForStudent);
    const [obj, setObj] = useState([]);
    const [grade, setGrade] = useState(doneAllObj.grade !== null ? doneAllObj.grade : '');
    const [loading, setLoading] = useState(false);
    const [commentsArr, setCommentsArr] = useState([]);
    const commObj = doneAllObj !== undefined && doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : null;

    useEffect(() => {
        let createObj = [];
        for (let i = 0; i < tests.length; i++) {
            createObj.push({})
        }
        setObj(createObj);
        if (commObj !== null) {
            setCommentsArr(commObj);
        } else {
            setCommentsArr(createObj);
        }
    }, [tests]);

    const setAnswer = (e, i) => {
        //console.log(e);
        const value = [...done]
        value[id].asw = e.target.innerHTML;
        value[id].aswIndex = i;
        setObj(value);

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);
    };

    const setAnswerFree = (e, message, i) => {
        const value = [...done]
        value[id].asw = e;
        //value[id].aswIndex = i;
        setObj(value);

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);
    };

    const setAnswerMultiple = (e, i) => {
        let asws = (done[id] !== undefined && Object.keys(done[id]).length > 0) ? done[id].asw : [];
        let indexes = (done[id] !== undefined && Object.keys(done[id]).length > 0) ? done[id].aswIndex : [];

        const findInd = indexes.find((ind) => ind === i)
        if (findInd === undefined) {
            asws.push(opt[i].value)
            indexes.push(i)
        } else {
            // const filteredAsws = asws.filter((asw, index) => index !== i)
            const filteredAsws = asws.filter(asw => asw !== e.target.innerHTML);
            const filteredIdxs = indexes.filter((ind) => ind !== i);
            asws = filteredAsws;
            indexes = filteredIdxs;
        }

        // console.log ('asw',asws)
        // console.log ('indexes',indexes)

        const value = [...done]
        value[id].asw = asws;
        value[id].aswIndex = indexes;
        setObj(value);

        let res = 0
        for (let i = 0; i < value.length; i++) {
            if (Object.keys(value[i]).length > 0) {
                res += 1
            }
        }
    };

    const setArrOfComments = (e) => {
        const arr = [...commentsArr]
        arr[id] = e;
        setCommentsArr(arr);
    };

    useEffect(() => {
        if (whoAmI.role === 'STUDENTE') {
            dispatch(getAllTeachers(whoAmI));
            dispatch(findCourse(course));
        };
    }, [whoAmI]);

    // useEffect(() => {
    //     if (allDetails.notifFlag !== undefined) {
    //         dispatch(getTestTpaDoneForStudent(allDetails.elem.idTpaDone));
    //     };
    // }, [allDetails]);

    // console.log(done, obj);

    const submitTest = () => {
        //console.log("AICI SE TERMIA")
        if (whoAmI.role === 'STUDENTE') {
            dispatch(saveExistingTpaTest(tpaType, tests, done, whoAmI.idUser, course, idDone));
            let verif = 0;
            for (let i = 0; i < done.length; i++) {
                if (Object.keys(done[i]).length > 0) {
                    verif = verif + 1
                }
            };

            if (verif === done.length) {
                socket.emit('sendNotifToProf', {
                    senderId: whoAmI.idUser,
                    receiverId: allCenterTeachers,
                    text: 'Test done!',
                    author: whoAmI,
                    course: aCourse[0],
                    testType: tpaType
                });

                dispatch(newNotifications(whoAmI.idUser,
                    allCenterTeachers,
                    whoAmI,
                    aCourse[0],
                    allDetails.elem,
                    tpaType
                ));

                dispatch(updateNotifications(allDetails.elem, whoAmI.idUser));
            };
        } else {
            dispatch(saveTpaVerification(obj, grade, idDone, commentsArr, whoAmI.idUser, allDetails.idNotif));

            socket.emit('sendNotif', {
                senderId: whoAmI.idUser,
                receiverId: allDetails?.allDetails.studDetails.idUsers,
                text: 'Verification done!',
                author: whoAmI,
                course: allDetails.allDetails.course,
                test: allDetails.elem,
                testType: tpaType
            })

            dispatch(newNotificationsForProf(whoAmI.idUser,
                allDetails?.allDetails.studDetails.idUsers,
                whoAmI,
                allDetails.allDetails.course,
                allDetails.elem,
                tpaType
            ));

            dispatch(updateNotifications(allDetails.elem, whoAmI.idUser));
        }

        setFinishTheTest(true);
        setTimeout(() => {
            // window.close();
            history.goBack();
        }, 5000);
    };

    // console.log('DONE', done);
    // console.log('OBJ', obj);
    // console.log("PARAMS", params);
    // console.log("TEST", done);
    // console.log("TEST", idDone);
    // console.log(whoAmI.role);
    // console.log('COMM', commentsArr);
    // console.log('TEST', tests);
    // console.log('DETAILS', allDetails);
    // console.log('whoAmI',whoAmI);
    // console.log(tpaType);
    // console.log('doneAllObj',doneAllObj);

    return (
        <>
            <TestWrapper user={whoAmI.role === 'STUDENTE' && true}>

                <GradeWrapper sts={allDetails !== undefined && allDetails.elem.grade !== null}>
                    <span>
                        Giudizio: {allDetails !== undefined &&
                            allDetails.elem.grade !== null ?
                            allDetails.elem.grade
                            :
                            'In attesa...'
                        }
                    </span>
                </GradeWrapper>
                {whoAmI.role === 'STUDENTE' ?
                    <AllQuestionsSideBarDone
                        id={id}
                        setId={setId}
                        //difficulty={tests[id].difficulty}
                        arr={tests}
                        obj={done}
                    />
                    :
                    <AllQuestionsSideBarForProfTPA
                        id={id}
                        setId={setId}
                        arr={tests}
                        obj={done}
                        grade={grade}
                        setGrade={setGrade}
                        setArrOfComments={setArrOfComments}
                        doneAllObj={doneAllObj}
                    // setAnswer={
                    //     (type === 'bool' && setAnswer) ||
                    //     (type === 'free' && setAnswerFree) ||
                    //     (type === 'one' && setAnswer) ||
                    //     (type === 'multiple' && setAnswerMultiple)
                    // }
                    // inputFields={inputFields}
                    // setInputFields={setInputFields}
                    />
                }

                {tests !== undefined &&
                    type === 'bool' ?
                    <QuestionBool
                        tests={tests}
                        id={id}
                        setAnswers={setAnswer}
                        // obj={obj}
                        obj={done}
                        opt={opt}
                        flag='verification'
                        doneAllObj={doneAllObj}
                    />
                    : type === 'free' ?
                        <QuestionFree
                            tests={tests}
                            id={id}
                            setAnswers={setAnswerFree}
                            // obj={obj}
                            obj={done}
                            flag='verification'
                            doneAllObj={doneAllObj}
                        />
                        : type === 'one' ?
                            <QuestionOne
                                tests={tests}
                                id={id}
                                setAnswers={setAnswer}
                                // obj={obj}
                                obj={done}
                                opt={opt}
                                flag='verification'
                                doneAllObj={doneAllObj}
                            />
                            : type === 'multiple' ?
                                <MultipleAnswers
                                    tests={tests}
                                    id={id}
                                    setAnswers={setAnswerMultiple}
                                    // obj={obj}
                                    obj={done}
                                    opt={opt}
                                    flag='verification'
                                    doneAllObj={doneAllObj}
                                />
                                :
                                <></>
                }
            </TestWrapper>

            {/* <FooterBar
                id={id}
                setId={setId}
                tests={tests}
                submitTest={submitTest}
                flag={'TPA'}
            /> */}

            {whoAmI.role === 'STUDENTE' ?
                // (type === 'wartegg' || type === 'test-grafici') ?
                //     <FooterBarForStud />
                //     :
                <FooterBarForDone
                    id={id}
                    setId={setId}
                    tests={tests}
                    submitTest={submitTest}
                    grade={grade}
                    loading={loading}
                    flag={'TPA'}
                    obj={done}
                    allDetails={allDetails}
                />
                // <FooterBarForStud2
                //     id={id}
                //     setId={setId}
                //     tests={tests}
                //     submitTest={submitTest}
                //     grade={grade}
                //     loading={loading}
                //     flag={'TPA'}
                // />
                :
                <FooterBarForProf
                    id={id}
                    setId={setId}
                    tests={tests}
                    submitTest={submitTest}
                    grade={grade}
                    loading={loading}
                    flag={'TPA'}
                />
            }
        </>

    )
}

export default TpaSwitchTestDone;