import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import EserciziCard from '../../GeneralComponents/Cards/EserciziCardNew';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
    bringTestsDone,
    bringSimulationsDone,
    getAllWrongQuezzes,
    resetBringSimulation,
    resetTest
} from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    TestWrapper,
    GoBack,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const CardsWrapper = styled.div`
    width:100%;
    /* height: calc(100vh - 160px); */
    /* margin: 30px 0 0; */
    padding: 20px 0;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: flex-start;
        &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 1350px) {
            grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 850px) {
            grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 600px) {
            grid-template-columns: repeat(1,auto);
            grid-template-columns: 1fr;
        }
`;

const SubTitle = styled.div`
   color:  var(--text5);
   font-size: 22px;
   text-align: center;
   background: linear-gradient(90deg,rgba(240,242,245,1) 0%,rgba(119,123,141,0.9500175070028011) 30%,rgba(108,113,132,1) 50%,rgba(96,101,122,0.8855917366946778) 70%,rgba(240,242,245,1) 100%);
`;


const ExercisesPage = ({ details }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const title = 'Prova Preliminare - PP1';

    const desc = 'Preparazione Concorso Allievi Agenti Plizia Penitenziari'

    const svolgere = [
        'Quiz monomateria', 'Quiz misti', 'Simulazione',
    ];
    const svolti = [
        'Quiz monomateria', 'Quiz misti', 'Simulazione'
    ];

    const errati = [
        'Quiz errati'
    ];

    useEffect(() => {
        dispatch(bringTestsDone(details.course));
        dispatch(bringSimulationsDone(details.course));
        dispatch(getAllWrongQuezzes(details.course));
        dispatch(resetBringSimulation());
        dispatch(resetTest());
    }, [dispatch]);

    // console.log('ExercisesPage', details);
    // console.log('history', history);

    return (
        <WrapperRightSide>

            <TitleWrapper>
                {/* <GoBack onClick={() => history.goBack()}> */}
                <GoBack onClick={() => history.replace({
                    pathname: '/dashboard/type',
                    state: { course: details.course, title: details.course.course_name, },
                })}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                {/* <Title>Esercizi</Title> */}

                <Title>{details.title} - {details.course.course_code}</Title>
                {/* <SubTitle>{desc}</SubTitle> */}
            </TitleWrapper>

            <div style={{ marginTop: '20px' }}>
                <SubTitle>Quiz da Svolgere</SubTitle>
                <CardsWrapper>
                    {svolgere !== undefined &&
                        svolgere.map((type, i) =>
                            <EserciziCard
                                key={i}
                                type={type}
                                course={details.course}
                            />
                        )}
                </CardsWrapper>

                <SubTitle>Quiz Svolti</SubTitle>
                <CardsWrapper>
                    {svolti !== undefined &&
                        svolti.map((type, i) =>
                            <EserciziCard
                                key={i}
                                type={type}
                                flag='svolti'
                                course={details.course}
                            />
                        )}
                </CardsWrapper>

                <SubTitle>Quiz Errati </SubTitle>
                <CardsWrapper>
                    {errati !== undefined &&
                        errati.map((type, i) =>
                            <EserciziCard
                                key={i}
                                type={type}
                                course={details.course}
                            />
                        )}
                </CardsWrapper>
            </div>

        </WrapperRightSide>
    );
}

export default ExercisesPage;