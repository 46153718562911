import { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedQuestionsForTest, saveTest, resetTest } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../PagesShareStyle/PagesShareStyle';


const PreTest = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tests = useSelector(({ test }) => test.selectedQuestions);
    const savedTest = useSelector(({ test }) => test.savedTest);

    // console.log('SAVE TEST', savedTest);
    console.log('PRE TEST DETAILS', details);

    useEffect(() => {
        dispatch(getSelectedQuestionsForTest(details.newArr));
    }, [dispatch]);

    useEffect(() => {
        if (tests !== undefined) {
            dispatch(saveTest(tests, details.course, details.flag));
        }
    }, [tests])

    useEffect(() => {
        if (savedTest !== undefined) {
            // history.push('/dashboard/create-quiz/test', savedTest);

            // setTimeout(() => {
            //     const url = '/dashboard/create-quiz/test/' + savedTest.idTestExercises;
            //     const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            //     if (newWindow) newWindow.opener = null;
            //     dispatch(resetTest())
            //     history.goBack()
            // }, 2000)


            setTimeout(() => {
                const url = '/dashboard/create-quiz/test/' + savedTest.idTestExercises;
                history.push({
                    pathname: url,
                    state: details.course
                });
            }, 2000)
        }
    }, [savedTest]);

    return (
        <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img src={Loading} alt="Loading..."></img>
            <div>Inizia test...</div>
        </WrapperRightSide>
    );
}

export default PreTest;