import React, { useState, useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';
// import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import _ from 'lodash';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getAllStudents } from '../../../Store/Actions/usersActions';
import { resetUserResults } from '../../../Store/Actions/subjectsActions';

// import { setSize } from '../../../Store/Actions/otherActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    BtnLink,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './StudentsTable'

// const options = [
//   { value: '5', label: '5' },
//   { value: '10', label: '10' },
//   { value: '15', label: '15' },
//   { value: '25', label: '25' },
//   { value: '50', label: '50' },
//   { value: '100', label: '100' },
// ];

const tableHead = [
    'Nome',
    'Cognome',
    'E-mail',
    'Centro',
    'Attivo dal:',
    'Fino al:',
    'Azioni',
];

// let users = [
//     {
//         idUser: 1,
//         firstName: 'Frede',
//         lastName: 'Bianchi',
//         email: 'frede@gmail.com',
//         status: '10%',
//     },
//     {
//         idUser: 2,
//         firstName: 'Rico',
//         lastName: 'Lamela',
//         email: 'rico@gmail.com',
//         status: '40%',
//     },
//     {
//         idUser: 3,
//         firstName: 'Mario',
//         lastName: 'Luca',
//         email: 'mario@gmail.com',
//         status: '20%',
//     },
//     {
//         idUser: 4,
//         firstName: 'Maribela',
//         lastName: 'Verdi',
//         email: 'maribela@gmail.com',
//         status: '70%',
//     },
// ]

const Students = () => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    // console.log("PRODILEE", profile);
    const students = useSelector(({ users }) => users.allStudents);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [usr, setUsr] = useState([]);
    const [stud, setStud] = useState([]);

    //console.log("STUDENTS", students)
    // console.log("PRODILEE", profile)

    // useEffect(() => {
    //     dispatch(getAllStudents());
    // }, [dispatch]);
    useEffect(() => {
        dispatch(getAllStudents());
        dispatch(resetUserResults());
    }, [dispatch]);

    useEffect(() => {
        const retArr = [];
        if (students !== undefined) {
            if (profile.role === 'ADMIN') {
                setStud(students.filter(user => user.user_role === 'STUDENTE'))
            }
            if (profile.role === 'DOCENTE') {
                //console.log("INTRA")
                let cent = JSON.parse(profile.center);
                let arr = [];
                console.log(cent)
                cent.forEach(cnt => {
                    let mata = (students.filter(std => JSON.parse(std.center)[0] === cnt && std.user_role === 'STUDENTE'));
                    //console.log("MAAAAAA", mata)
                    arr = arr.concat(mata)

                });
                setStud(arr)

            }
            if (profile.role === 'CENTRO') {

                let cent = JSON.parse(profile.center);
                cent.forEach(cnt => {

                    setStud(students.filter(std => JSON.parse(std.center)[0] === cnt && std.user_role === 'STUDENTE'))
                })
                // setStud(students.filter(user => user.user_role === 'STUDENTE'))

            }
        }
    }, [students]);

    console.log('STUDENTS', stud);

    return (
        <WrapperRightSide>
            <Title>STUDENTI</Title>

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    usr={stud}
                    flag={'users'}
                    setUsr={setUsr}
                />

            </ActionsWrapperSpace>

            <ActionsWrapper>
                {/* <BtnWrapper>
                    <BtnLink
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/users/newUser'>
                        <i className="fas fa-plus"></i>Utenti
                    </BtnLink>
                </BtnWrapper> */}

                {/* <Filter
          invs={invo}
          flag={'invoices'}
          setInvoice={setInvoice}
        /> */}
            </ActionsWrapper>

            <TableWrapper>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono Utenti da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono Utenti da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={usr}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default Students;