import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKEGES
import styled from 'styled-components';

// COMPONENTS
import PhysicalLessonPage from '../../Pages/Physical/LessonPage/PhysicalLessonPage';
import PhysicalStreaming from '../../Pages/Physical/LessonPage/PhysicalStreaming';
import PhysicalDocuments from '../../Pages/Physical/LessonPage/PhysicalDocuments';



const GeneralWrapper = styled.div`
    background: transparent;
    width:100%;
    /* padding: 20px 0 0; */
    display: flex;
    flex-direction: column;
        @media (max-width: 775px) {
                width:100%;
                margin-left:0;
            };
`;

const TabWrapper = styled.div`
    border-bottom: 1px solid var(--background3);
    overflow: hidden;
    margin-bottom: 20px;
        >button{
            border-radius: 5px 20px 0 0;
            margin-right: 1px;
        }
`;

const Btn = styled.button`
    padding: 10px 30px;
    border: none;
    outline:none;
    background: ${(props) => props.select === 'select' ? 'var(--acc-color)' : 'var(--background3)'};
    color: ${(props) => props.select === 'select' ? 'var(--text5)' : 'var(--text2)'};
    font-weight: ${(props) => props.select === 'select' && 'bold'};
    /* transform:  ${(props) => props.select === 'select' ? null : 'translateY(8px)'}; */
`;

export const TitleWrapper = styled.div`
  /* display: flex; */
`;

const GoBack = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: var(--text3);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: var(--background4);
    /* min-width: 100px; */
    /* border-radius: var(--radius); */
    box-shadow: var(--shadow);
    /* padding: 10px; */
    margin-bottom: 20px;
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
`;

const PhysicalTabs = ({ details }) => {
    const history = useHistory();
    const [curentBtn, setCurentBtn] = useState('');

    const filterCore = (e) => {
        setCurentBtn(e.currentTarget.id)
    };

    // console.log('Physical TABS', details);

    return (
        <GeneralWrapper>
            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
            </TitleWrapper>

            <TabWrapper>
                <Btn
                    id='1'
                    select={curentBtn === '1' || curentBtn === '' ? 'select' : null}
                    onClick={(e) => filterCore(e)}
                >Ondemand</Btn>
                <Btn
                    id='2'
                    select={curentBtn === '2' ? 'select' : null}
                    onClick={(e) => filterCore(e)}
                >Live streaming</Btn>
                <Btn
                    id='3'
                    select={curentBtn === '3' ? 'select' : null}
                    onClick={(e) => filterCore(e)}
                >Materiale didattico</Btn>

            </TabWrapper >

            {details !== undefined &&
                <>
                    {curentBtn === '' && <PhysicalLessonPage details={details} />}
                    {curentBtn === '1' && <PhysicalLessonPage details={details} />}
                    {curentBtn === '2' && <PhysicalStreaming details={details} />}
                    {curentBtn === '3' && <PhysicalDocuments details={details} />}
                </>
            }

        </GeneralWrapper>
    );
};

export default PhysicalTabs;