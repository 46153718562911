import React, { useState, useEffect } from 'react';

// ASSETS
import Loading from '../../../../Assets/loading.gif';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { bringTest } from '../../../../Store/Actions/testActions';

const FinishPage = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    font-size: 18px;
    letter-spacing: 2px;
    background: var(--background3);
    border: none;
    outline:none;
    text-decoration: none;
    backdrop-filter: blur(10px);
    pointer-events: auto;
        >h3{
            color: var(--text2);
            text-align: center;
            >span {
                color: ${props => props.color === 'true' ? 'var(--success)' : 'var(--err)'};
                font-size: 40px;
            }
        }
        >h5{
            position: absolute;
            top: 10px;
            right: 20px;
            color: var(--text3);
            >i{
                margin-left: 10px;
                font-size: 20px;
                cursor: pointer;
                color: var(--text3);
                &:hover{
                    color: var(--text2);
                }
            }
        }
        >i{
            font-size: 100px;
            color: var(--success);
            margin-bottom: 20px;
        }
        > img{
            width: 40px;
        }
`;


const QuestFinishTest = ({ quest }) => {
    const dispatch = useDispatch();
    const score = useSelector(({ subjects }) => subjects.score);
    const [timeLeft, setTimeLeft] = useState(5);
    const [load, setLoad] = useState(true);
    const [sc, setSc] = useState('0');

    const [correct, setCorrect] = useState(0);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    useEffect(() => {
        setTimeout(() => {
            setLoad(false)
        }, 2000)
    }, []);

    useEffect(() => {
        if (score !== undefined) {
            setSc(score)
        }
    }, [score]);

    console.log('SCORE', quest);
    console.log(score)

    return (
        <FinishPage>
            <h5>chiudere in {timeLeft}
                <i className="fa-solid fa-xmark" onClick={() => window.close()}></i>
            </h5>

            {load ?
                <>
                    <img src={Loading} alt="Loading..."></img>
                    <h3>Invio risposte...</h3>
                </>
                :
		 (sc < quest.QuestionaryQuizzes.length / 100 * quest.point * 1) ?
                    <>
                        <i className="fa-regular fa-face-frown" style={{ color: 'var(--err)' }}></i>
                        <h3>Siamo spiacenti</h3>
                        <h3>Non hai superato la verifica. Per farlo devi rispondere correttamente al 10% delle domande.</h3>
                        {/* <h3 color={sc < quest.QuestionaryQuizzes.length / 100 * quest.point * 1 ? 'false' : 'true'}>Hai ottenuto un punteggio del <span>{(sc * quest.point * 1) / (quest.QuestionaryQuizzes.length / 100 * quest.point * 1)}</span>, minimo del {quest.point}%. </h3> */}
                    </>
                    :
                    <>
                        <i className="fa-regular fa-thumbs-up"></i>
                        <h3>Il test è stato completato con successo!</h3>
                        <h3 color={sc < quest.QuestionaryQuizzes.length / 100 * quest.point * 1 ? 'false' : 'true'}>Hai risposto correctamente a <span>{sc}</span> domande. </h3>
                    </>
            }
        </FinishPage>
    );
}

export default QuestFinishTest;
