import axios from 'axios';
axios.defaults.withCredentials = true;

export const findAllSubjects = (courseId) => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findSubjects`, courseId)
        .then((res) => {
            // console.log("ALL_SUBJECTS", res.data);
            dispatch({
                type: 'ALL_SUBJECTS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getSelectedQuestionsForTest = (selectQuestionsArr) => (dispatch, getState) => {
    console.log(selectQuestionsArr);
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findSelectedQuestions`, selectQuestionsArr)
        .then((res) => {
            console.log("SELECTED_QUESTIONS", res.data);
            dispatch({
                type: 'SELECTED_QUESTIONS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resetTest = () => (dispatch, getState) => {

    dispatch({
        type: 'SELECTED_QUESTIONS',
        payload: undefined,
    });
    dispatch({
        type: 'SAVED_TEST',
        payload: undefined,
    });
};

export const saveTest = (test, course, flag) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        testType: flag,
        testQuestions: JSON.stringify(test),
        // userID: '1',
        userID: profile.idUser,
        courseID: course.idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveTest`, body)
        .then((res) => {
            // console.log("SAVED_TEST", res.data);
            dispatch({
                type: 'SAVED_TEST',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const bringTest = (id) => (dispatch, getState) => {

    const body = {
        testID: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/bringTest`, body)
        .then((res) => {
            // console.log("BRING_TEST", res.data);
            dispatch({
                type: 'BRING_TEST',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const bringTestsDone = (course) => (dispatch, getState) => {
    // export const bringTestsDone = (id, course) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        // userID: id,
        userID: profile.idUser,
        courseID: course.idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/bringTestsDone`, body)
        .then((res) => {
            // console.log("BRING_TESTS_DONE", res.data);
            dispatch({
                type: 'BRING_TESTS_DONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const sendTestAnswers = (obj, id) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        answers: JSON.stringify(obj),
        // userID: '1',
        userID: profile.idUser,
        idTest: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveAnswers`, body)
        .then((res) => {
            // console.log("SAVE_ANSWERS", res.data);
            dispatch({
                type: 'SAVED_ANSWERS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const sendTestMessage = (message, id) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        message: message,
        userID: profile.idUser,
        questionId: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/sendTestMessage`, body)
        .then((res) => {
            // console.log("SEND_MESSAGE", res.data);
            dispatch({
                type: 'SEND_MESSAGE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteTest = (id, flag) => (dispatch, getState) => {

    const body = {
        idTest: id,
        flag: flag
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteTest`, body)
        .then((res) => {
            //dispatch(bringSimulationsDone(19))
            // console.log("DELETE_TEST", res.data);
        })
        .catch((err) => {
            console.log(err);
        });
};

// SIMULATIONS
export const createSimulation = (id, choose) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        // testType: 'Simulazione',
        // userID: '1',
        userID: profile.idUser,
        courseID: id,
        choose: choose
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/createSimulation`, body)
        .then((res) => {
            // console.log("GET_SIMULATION", res.data);

            // let shuffled = res.data.questions
            //     .map(value => ({ value, sort: Math.random() }))
            //     .sort((a, b) => a.sort - b.sort)
            //     .map(({ value }) => value)
            // console.log(shuffled)

            dispatch({
                type: 'GET_SIMULATION',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'GET_SIMULATION',
                payload: 'error',
            });
            setTimeout(() => {
                dispatch({
                    type: 'GET_SIMULATION',
                    payload: undefined,
                });
            }, 3000)
        });
};

export const saveSimulation = (simulation, course, flag) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        simConfig: JSON.stringify(simulation),
        // userID: '1',
        userID: profile.idUser,
        courseID: course.idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveSimulation`, body)
        .then((res) => {
            // console.log("SAVED_SIMULATION", res.data);
            dispatch({
                type: 'SAVED_SIMULATION',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resetSimulation = () => (dispatch, getState) => {

    dispatch({
        type: 'GET_SIMULATION',
        payload: undefined,
    });
    dispatch({
        type: 'SAVED_SIMULATION',
        payload: undefined,
    });
};

export const bringSimulation = (id) => (dispatch, getState) => {

    const body = {
        simID: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/bringSimulation`, body)
        .then((res) => {
            // console.log("BRING_SIMULATION", res.data);
            dispatch({
                type: 'BRING_SIMULATION',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resetBringSimulation = () => (dispatch) => {
    dispatch({
        type: 'BRING_SIMULATION',
        payload: undefined,
    });
    dispatch({
        type: 'GET_SIMULATION',
        payload: undefined,
    });
    dispatch({
        type: 'SAVED_SIMULATION',
        payload: undefined,
    });
};

export const sendSimulationAnswers = (obj, id, finishTime, really) => (dispatch, getState) => {
    const profile = getState().auth.user;
    const body = {
        answers: JSON.stringify(obj),
        // userID: '1',
        userID: profile.idUser,
        idSimulation: id,
        finishTime: finishTime,
        really: really
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveSimulationAnswers`, body)
        .then((res) => {
            // console.log("SAVED_SIMULATION_ANSWERS", res.data);
            dispatch({
                type: 'SAVED_SIMULATION_ANSWERS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const bringSimulationsDone = (course) => (dispatch, getState) => {
    // export const bringSimulationsDone = (id, course) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        // userID: id,
        userID: profile.idUser,
        courseID: course.idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/bringSimulationsDone`, body)
        .then((res) => {
            // console.log("BRING_SIMULATIONS_DONE", res.data);
            dispatch({
                type: 'BRING_SIMULATIONS_DONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

// export const deleteUndoneSimulations = (course) => (dispatch, getState) => {
//     // export const bringSimulationsDone = (id, course) => (dispatch, getState) => {
//     const profile = getState().auth.user;

//     const body = {
//         // userID: id,
//         userID: profile.idUser,
//         courseID: course.idCourse
//     };

//     axios
//         .post(`${process.env.REACT_APP_URL_BACKEND}/deleteUndoneSimulations`, body)
//         .then((res) => {
//             // console.log("BRING_DELETE_SIMULATIONS_DONE", res.data);
//             dispatch({
//                 type: 'BRING_SIMULATIONS_DONE',
//                 payload: res.data,
//             });
//         })
//         .catch((err) => {
//             console.log(err);
//         });
// };

export const sendSimulationMessage = (message, id) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        message: message,
        userID: profile.idUser,
        questionId: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/sendSimulationsMessage`, body)
        .then((res) => {
            // console.log("SEND_MESSAGE", res.data);
            dispatch({
                type: 'SEND_MESSAGE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getSimulations = (course) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        // userID: id,
        userID: profile.idUser,
        courseID: course.idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getSimulations`, body)
        .then((res) => {
            // console.log("GET_SIMULATIONS_OPT", res.data);
            dispatch({
                type: 'GET_SIMULATIONS_OPT',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resetSimulations = (course) => (dispatch, getState) => {
    dispatch({
        type: 'GET_SIMULATIONS_OPT',
        payload: [],
    });
};

// WRONG 
export const getAllWrongQuezzes = (id) => (dispatch, getState) => {
    const profile = getState().auth.user;
    // console.log("id", id);

    const body = {
        // userID: '1',
        //userID: profile.idUser,
        userID: !id.Users_follow_Courses ? profile.idUser : id.Users_follow_Courses.idUsers_idUser
        ,
        courseID: id.idCourse,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllWrongQuezzes`, body)
        .then((res) => {
            // console.log("WRONG_QUEZZES", res);
            dispatch({
                type: 'WRONG_QUEZZES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getWrongQuizzesBySubject = (idSubject, idUser, idCourse) => (dispatch, getState) => {
    //const profile = getState().auth.user;
    //console.log("id", id)

    const body = {
        idSubject: idSubject,
        idUser: idUser,
        idCourse: idCourse
    };

    // console.log("BODY", body);

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getWrongQuezzesBySubject`, body)
        .then((res) => {
            // console.log("WRONG_QUEZZES", res);
            dispatch({
                type: 'WRONG_QUEZZES_BY_SUBJECT',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const createTestWithWrongQuestions = (id, selectQuestionsArr) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        // userID: '1',
        userID: profile.idUser,
        courseID: id.idCourse,
        selectQuestionsArr: selectQuestionsArr
    };
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/createTestWithWrongQuestions`, body)
        .then((res) => {
            console.log("TEST_WRONG_QUESTIONS", res.data);
            dispatch({
                type: 'TEST_WRONG_QUESTIONS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const sendTestAnswersForWrong = (obj, id, tests, idCourse) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        answers: obj,
        questions: tests,
        // userID: '1',
        userID: profile.idUser,
        idTest: id,
        idCourse: idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveAnswersForWrong`, body)
        .then((res) => {
            // console.log("SAVE_ANSWERS", res.data);
            dispatch({
                type: 'SAVED_ANSWERS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};
