import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const WrapperRightSide = styled.div`
    position: relative;
    width: calc(100% - 250px);
    /* height:calc(100vh - 80px); */
    min-height: 100vh;
    margin-left: 250px;
    /* margin-top: 80px; */
    padding: 100px 20px 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content:center; */
    background: var(--background2);
        @media (max-width: 1100px) {
            width:100%;
            margin-left:0;
        };
    >img{
        width: 40px;
        margin-bottom: 10px;
    }
    >i{
        font-size: 80px;
        color: var(--err);
        margin-bottom: 20px;
    }
`;

export const TestWrapperV = styled.div`
    position: relative;
    width: ${props => props.draw ? '100%' : 'calc(100% - 360px)'};
    margin-top: 80px;
    height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
    background: var(--background2);
    overflow: auto;
        @media (max-width: 1100px) {
            width: 100%;
        };
`;

export const TestWrapper = styled.div`
    position: relative;
    width: ${props => props.draw ? '100%' : 'calc(100% - 230px)'};
    margin-top: 80px;
    height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
    background: var(--background2);
    overflow: auto;
        @media (max-width: 1100px) {
            width: 100%;
        };
`;

export const TitleWrapper = styled.div`
  /* display: flex; */
`;

export const Title = styled.h3`
    width: 100%;
    color: var(--text2);
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
        >span{
            margin-left: 10px;
            color: var(--text4);
        }
        @media (max-width: 700px) {
            font-size: 3vw;
        };
        @media (max-width: 500px) {
            font-size: 4vw;
        };
`;

export const GoBack = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: var(--text3);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: var(--background4);
    /* min-width: 100px; */
    /* border-radius: var(--radius); */
    box-shadow: var(--shadow);
    /* padding: 10px; */
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
  /* color:var(--text3);
  cursor:pointer;
  font-size:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right:10px;
  background: var(--background4);
  width: 100px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
        &:hover{
            color: var(--text2)
        } */
`;

export const AccountsTitles = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color:${props => props.color === 'true' ? 'var(--background3)' : 'var(--background4)'};
    background-color:${props => props.selected && 'var(--background4)'};
    color:${props => props.selected && 'var(--text5)'};
    cursor: pointer;
        >i{
            margin-right: 10px;
            font-size: 20px;
        }

`;

export const BtnWrapper = styled.div`
     width: 100%;
    display: flex;
        /* @media (max-width: 775px) {
            flex-direction: column;
        }; */
`;

export const Btn = styled.button`
    width: 200px;
    height:50px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    font-size: 14px;
    border-radius: var(--radius);
    margin-top: 40px;
    margin-bottom:20px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    color: ${props => (props.theme === 'first' && 'var(--warning)') ||
        (props.theme === 'second' && 'var(--success)') ||
        (props.theme === 'third' && 'var(--info)') ||
        (props.theme === 'lavoro' && 'var(--err)') ||
        (props.theme === 'users' && 'var(--acc-color)')
    };
    background: var(--background4);
    border: none;
    outline:none;
    text-decoration: none;
    box-shadow: var(--shadow);
        >i{
            margin-right: 10px;
            font-size: 20px;
        }
        &:hover{
            box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
        }
        &:active{
            box-shadow: none;
        }
        @media (max-width: 775px) {
            margin-top: 10px;
        };
`;

export const BtnLink = styled(Link)`
    width: 200px;
    height:50px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    font-size: 14px;
    border-radius: var(--radius);
    margin-top: 40px;
    margin-bottom:20px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    color: ${props => (props.theme === 'first' && 'var(--warning)') ||
        (props.theme === 'second' && 'var(--success)') ||
        (props.theme === 'third' && 'var(--info)') ||
        (props.theme === 'lavoro' && 'var(--err)') ||
        (props.theme === 'users' && 'var(--acc-color)')
    };
    background: var(--background4);
    border: none;
    outline:none;
    text-decoration: none;
    box-shadow: var(--shadow);
        >i{
            margin-right: 10px;
            font-size: 20px;
        }
        &:hover{
            box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
        }
        &:active{
            box-shadow: none;
        }
        @media (max-width: 775px) {
            margin-top: 10px;
        };
`;

export const TableWrapper = styled.div`
    width:100%;
    /* min-height: calc(100vh - 280px); */
    /* height:auto; */
    overflow-y: auto;
    margin-top:10px;
    background: var(--background4);
    height: calc(100vh - 370px);
    box-shadow: var(--shadow);
    border-radius: var(--radius);
        >table{
            width: 100%;
        }
`;

export const TabelFooter = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-start;
        >h5{
            color: var(--text3);
            margin-left: 20px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
`;

export const SmallTableWrapper = styled.div`
    width:100%;
    overflow-y: auto;
    margin-top:10px;
    background-color: var(--background4);
        >table{
            width: 100%;
        }
`;

export const Tbody = styled.tbody`
    >tr:nth-child(odd){
        background-color: var(--accentBackground);
        /* background-color: var(--background5); */
    }
    /* >tr>td:first-child{
        width:50px;
        text-align:center;
    } */
    /* >tr>td:last-child{
            display:flex;
        } */
`;

export const Tr = styled.tr`
    color: ${props =>
        ((props.color === '' && props.rez === true) && 'var(--err)') ||
        ((props.color !== '' && props.rez === true) && 'var(--success)') ||
        ((props.color === 0) && 'var(--text2)')
    };
`;

export const TrDiv2 = styled.div`
    display: flex;
    justify-content:flex-end;
        >h5{
            border: none;
            width: 280px;
            padding: 10px;
            font-size: var(--fontM);
            color: var(--text5);
        }
`;

export const PopUpTd = styled.div`
    position:absolute;
    top: -30px;
    left: 10px;
    padding: 10px;
    background: #6f6f6f73;
    color: var(--text5);
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
`;

export const Td = styled.td`
    position:relative;
    border:none;
    /* min-width: 180px; */
    padding: 10px;
    font-size:var(--fontM);
        &:hover ${PopUpTd} {
            opacity: 1;
            visibility: visible;
        } 
        >div>a>i,
            >i{
                /* padding: 10px 20px 10px 0; */
                cursor: pointer;
                color: var(--text2);
                font-size: 18px;
                    &:hover{
                        color:var(--warning);
                }
            }
            /* >i:nth-child(1){
                color: var(--warning);
                    &:hover{
                        color:var(--warning);
                }
            }
            >i:nth-child(2){
                color:var(--color2);
                    &:hover{
                        color: var(--success);
                }
            }
            >i:nth-child(3){
                color: var(--err);
                    &:hover{
                        color:var(--color2);
                }
            }     */
`;

export const TdHeader = styled.div`
    display: flex;
    justify-content: right;
    margin: 0;
        >h5{
            border: none;
            /* width: 100%; */
            min-width: 280px;
            padding: 10px;
            color: var(--text4);
            font-weight: bold;
            font-size: var(--fontM);
        }
`;

export const ExpandBtn = styled.button`
    /* width: 25px; */
    height: 25px;
    width: 80px;
    border-radius: 25px;
    border: none;
    outline:none;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--acc-color);
    border: 2px solid var(--acc-color);
    background-color: transparent;
    /* margin-left: 20px; */
        >i{
            color: var(--acc-color);
            font-size: 14px;
            margin-left: 5px;
        }
`;

export const ExpandTableRates = styled.div`
    display: flex;
    justify-content:flex-end;
`;

export const TrDiv = styled.div`
    display: flex;
    justify-content:flex-end;
    margin: 0;
        >h5{
            border: none;
            /* width: 100%; */
            min-width: 280px;
            /* max-width: 250px; */
            padding: 10px;
            font-size: var(--fontM);
            color: var(--text5);
        }
    `;

export const Thead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--background4);
`;

export const Th = styled.th`
    text-align:left;
    border:none;
    /* width: 150px; */
    padding:10px 10px 20px;
    color:var(--text3);
`;

export const PopUp = styled.div`
    position:absolute;
    top: -35px;
    right: 0px;
    padding: 10px;
    background:#6f6f6f73;
    color: var(--text5);
    text-align: center;
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
    white-space: nowrap;
`;

export const PopUp2 = styled.div`
    position:absolute;
    top: -40px;
    right: 0px;
    padding: 10px;
    background: #6f6f6f73;
    color: var(--text5);
    text-align: center;
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
    white-space: nowrap;
`;

export const LinkDiv = styled.div`
      position: relative;
        &:hover ${PopUp} {
                opacity: 1;
                visibility: visible;
        }
        &:hover{
            >a>i{
            color: var(--warning);
            }
        }
`;

export const EditWrapper = styled.div`
     position: relative;
        &:hover ${PopUp2} {
                opacity: 1;
                visibility: visible;
        }

        &:hover{
            >a>i{
            color:var(--warning);
            }
        }
`;

export const Edit = styled(Link)`
        /* position: absolute;
        top: 10px;
        right: 60px;
        z-index: 1; */
        margin: 0 5px 0;
        background-color: var(--background4);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        text-decoration: none;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            >i{
                font-size: 16px;
                cursor: pointer;
                color:var(--text1);
                    &:hover{
                        color:var(--warning);
                }
            }
`;

export const ActionLink = styled(Link)`
        /* position: absolute;
        top: 10px;
        right: 60px;
        z-index: 1; */
        margin: 0 5px 0;
        background-color: var(--background4);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        text-decoration: none;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            >i{
                font-size: 16px;
                cursor: pointer;
                color:var(--text1);
                    &:hover{
                        color:var(--warning);
                }
            }
`;

export const NavTable = styled.div`
    width:100%;
    padding-top:10px;
    display: flex;
    justify-content: space-between;
        >h5{
            color: var(--text3);
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        >ul{
            display: flex;
            justify-content: flex-end;
            list-style: none;
            padding:0;
        }
        .previous,
        .next{
            color:var(--text2);
           >a{
            width:40px;
            height:40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:5px;
            cursor: pointer;
                :hover{
                    background:var(--hover);
                    color: var(--text5);
                }
                >i{
                    font-size: 20px;
                }
           }
        }
        .numbers,
        .breaak-me{
            width:40px;
            height:40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:5px;
            cursor: pointer;
            color:var(--text2);
            font-weight:bold;
                :hover{
                    background:var(--hover);
                    color: var(--text5);
                }
        }
        .active{
            background: var(--acc-color);
            color: var(--text5);
                :hover{
                    background:var(--acc-color);
                    color: var(--text5);
                }
        }
        .disabled{
            color: var(--text4);
            >a{
                :hover{
                        background:transparent;
                        color: var(--text4);
                        cursor: default;
                    }
            }
        }
`;


export const ActionsWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display:flex;
    justify-content: space-between;
`;

export const ActionsWrapperSpace = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

// export const Li = styled.li`
//     width:40px;
//     height:40px;
//     border-radius: 40px;
//     color:${props => props.active === 'active' ? 'var(--text5)':'var(--text2)'};
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin:5px;
//     cursor: pointer;
//     background: ${props => props.active === 'active' && 'var(--acc-color)'};
//         &:hover{
//             background:var(--hover);
//             color: var(--text5);
//         }
// `;

export const Loading = styled.div`
    width:100%;
    height: calc(100vh - 450px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >img{
            width:40px;
            height:40px;
        }
        >h2{
          color: var(--text3);
        }
        >i{
          font-size: 80px;
          padding-top:20px;
          color: var(--text3);
        }
`;

// export const TrLink = styled(Link)`
//         text-decoration: none;
//         color: var(--text1);
//         width: 100%;
// `;

export const colourStyles = {
    control: styles => ({
        ...styles,
        width: 100,
        backgroundColor: 'transparent',
        borderRadius: '4px',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text1)',
    }),
    menu: styles => ({
        ...styles,
        width: 100,
        backgroundColor: 'var(--background4)',
        border: 'none',
        fontSize: '14px',
        color: 'var(--text1)',
        zIndex: 2,
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)', border: '1px solid var(--border)', }),
    placeholder: styles => ({ ...styles, fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};

export const colourStyles3 = {
    control: (styles, state) => ({
        ...styles,
        width: 200,
        marginRight: '10px',
        borderRadius: 'var(--radius)',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text1)',
        backgroundColor: 'transparent',
        border: (state.isHovered ? '1px solid var(--acc-color)' : '1px solid var(--border)') ||
            (state.isFocused ? '1px solid var(--acc-color)' : '1px solid var(--border)'),
    }),
    menu: styles => ({
        ...styles,
        width: 200,
        backgroundColor: 'var(--background4)',
        border: 'none',
        fontSize: '14px',
        color: 'var(--text1)',
        zIndex: 2,
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)' }),
    placeholder: styles => ({ ...styles, fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};

export const colourStyles4 = {
    control: (styles, state) => ({
        ...styles,
        width: 300,
        marginRight: '10px',
        borderRadius: 'var(--radius)',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text1)',
        backgroundColor: 'transparent',
        border: (state.isHovered ? '1px solid var(--acc-color)' : '1px solid var(--border)') ||
            (state.isFocused ? '1px solid var(--acc-color)' : '1px solid var(--border)'),
    }),
    menu: styles => ({
        ...styles,
        width: 300,
        backgroundColor: 'var(--background4)',
        border: 'none',
        fontSize: '14px',
        color: 'var(--text1)',
        zIndex: 2,
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)' }),
    placeholder: styles => ({ ...styles, fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};


export const colourStyles5 = {
    control: (styles, state) => ({
        ...styles,
        width: '100%',
        marginRight: '10px',
        borderRadius: 'var(--radius)',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text1)',
        backgroundColor: 'transparent',
        border: (state.isHovered ? '1px solid var(--acc-color)' : '1px solid var(--border)') ||
            (state.isFocused ? '1px solid var(--acc-color)' : '1px solid var(--border)'),
    }),
    menu: styles => ({
        ...styles,
        width: '100%',
        backgroundColor: 'var(--background4)',
        border: 'none',
        fontSize: '14px',
        color: 'var(--text1)',
        zIndex: 2,
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)' }),
    placeholder: styles => ({ ...styles, fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};
