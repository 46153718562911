import React from 'react';

// ASSETS
import load from '../../../Assets/loadingBtn.gif';

// STYLE
import {
    // Form,
    BtnWrapper,
    Btn,
    // Title,
    // TitleWrapper,
    // GoBack,
    // LabelCss,
    Err,
    // colourStyles,
    //Select
} from '../../Pages/PagesShareStyle/DetailsShareStyle';

const LoadingButton = (props) => {

    return (
        <BtnWrapper>
            {props.stsBtn ?
                <Btn disabled><img src={load} alt='' />{props.label}</Btn>
                :
                <Btn type='submit'>{props.label}</Btn>
            }
            {props.err && <Err>*Si prega di completare tutti i campi</Err>}
        </BtnWrapper>
    )
}

export default LoadingButton;