const initState = {
    allSubjects: [],
    allQuestionnaires: []
};

const subjectsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ALL_SUBJECTS':
            return {
                ...state,
                allSubjects: action.payload,
            };
        case 'GET_QUESTIONNAIRES':
            return {
                ...state,
                allQuestionnaires: action.payload,
            };
        case 'DOWNLOAD_FILE':
            return {
                ...state,
                file: action.payload,
            };
        case 'CHECK_IF_EXISTS':
            return {
                ...state,
                ifExistDone: action.payload,
            };
        case 'SCORE_QUEST':
            return {
                ...state,
                score: action.payload,
            };
        case 'ALL_SUBJECTS_OF_USER':
            return {
                ...state,
                userAllSubjects: action.payload,
            };
        case 'GET_NOTEBOOKS':
            return {
                ...state,
                note: action.payload
            }
        default:
            return state;
    }
};

export default subjectsReducer;