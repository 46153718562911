import { useEffect, useState } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from './AllQuestions';
import FooterBar from './FooterBarSimulationVerification';
import QuestionTestVerification from './QuestionSimulationVerification';
import SendWrongModal from '../../../GeneralComponents/Modals/SendWrongModal';

// REDUX
import { useDispatch } from 'react-redux';
import { sendSimulationAnswers } from '../../../../Store/Actions/testActions';

// STYLE
import {
    // WrapperRightSide,
    // Title,
    TestWrapperV,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../PagesShareStyle/PagesShareStyle';


const SimulationVerificationPage = ({ tests, setCorrect, setIncorrect }) => {
    const dispatch = useDispatch();
    const [id, setId] = useState(0);
    const [test, setTest] = useState();
    const [obj, setObj] = useState();

    useEffect(() => {
        if (tests !== undefined) {
            if (tests.quiz_questions !== null) {
                setTest(JSON.parse(tests.sim_config).questions)
            }
            if (tests.sim_answers !== null) {
                setObj(JSON.parse(tests.sim_answers))
            } else {
                let createObj = [];
                const tst = JSON.parse(tests.sim_config).questions;
                for (let i = 0; i < tst.length; i++) {
                    createObj.push({})
                }
                setObj(createObj);
            }
        }
    }, [tests]);

    useEffect(() => {
        if (obj !== undefined) {
            let correct = 0;
            let incorrect = 0;

            for (let i = 0; i < obj.length; i++) {
                const elem1 = obj[i];
                const elem2 = test[i];

                if (elem1.answer_picked === elem2.answerOk) {
                    correct = correct + 1
                }

                if (elem1.answer_picked !== elem2.answerOk) {
                    incorrect = incorrect + 1
                }

                if (elem1.answer_picked === '' || elem2.answerOk === '') {
                    incorrect = incorrect + 1
                }
            }
            setCorrect(correct);
            setIncorrect(incorrect);
        };

        //     for (let i = 0; i < obj.length; i++) {
        //         const elem = obj[i];

        //         // if (elem !== undefined && Object.keys(elem).length > 0) {
        //         // console.log(elem.Answer_picked, elem.Answer_OK)

        //         // if (elem.Answer_picked === i) {
        //         if (elem.Answer_picked === elem.Answer_OK) {
        //             correct = correct + 1
        //         }

        //         if (elem.Answer_picked !== elem.Answer_OK) {
        //             incorrect = incorrect + 1
        //         }

        //         if (elem.Answer_picked === '' || elem.Answer_OK === '') {
        //             incorrect = incorrect + 1
        //         }
        //         // } else if (elem.Answer_OK === i) {
        //         //     incorrect =+ 1
        //         // }
        //         // }
        //     };

        // console.log('correct', correct);
        // console.log('incorrect', incorrect);

    }, [obj, setCorrect, setIncorrect]);

    const setAnswer = (e, i, letters) => {
        // console.log(i)
        const value = [...obj]
        value[id].asw = JSON.parse(test[id].answers)[e.target.id].ans;
        value[id].answer_picked = letters[i];
        value[id].aswIndex = i;
        setObj(value);

        dispatch(sendSimulationAnswers(value, tests.idSimulations));
    };

    // console.log('OBJ', obj);
    // console.log('TEST', test);
    // console.log(tests);
    // console.log(JSON.parse(tests.sim_config).questions);

    return (
        test !== undefined &&
        <>
            <TestWrapperV>
                <AllQuestionsSideBar
                    id={id}
                    setId={setId}
                    // difficulty={tests[id].difficulty}
                    difficulty={test[id].which_file}
                    // arr={tests}
                    arr={test}
                    obj={obj}
                />

                <QuestionTestVerification
                    tests={test}
                    id={id}
                    obj={obj}
                    setAnswers={setAnswer}
                />

                <SendWrongModal
                    icon={<i className="fa-solid fa-bug"></i>}
                    title='Segnala un errore'
                    id={id}
                    tests={test}
                    flag='simulation'
                />
            </TestWrapperV>

            <FooterBar
                id={id}
                setId={setId}
                // tests={tests}
                tests={test}
            />
        </>
    );
}

export default SimulationVerificationPage;
