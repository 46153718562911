import React from 'react';

// PACKAGES
import styled from 'styled-components';

const TestBody = styled.div`
    padding-bottom: 20px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const Question = styled.div`
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 0px;
    color: var(--text2);
    font-weight: bold;
`;

const Wrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    border: var(--radius);
    display: flex;
    flex-direction: column;
    justify-content: start;
        >img{
            width: 100%;
            height: 100%;
            max-width: 1000px;
            border-radius: var(--radius);
        }
        @media (max-width: 1490px) {
            display: flex;
            flex-direction: column;
            width: 100%;
        };
`;

const ResContainer = styled.div`
    padding: 10px;
    border-radius: var(--radius);
    display: flex;
    justify-content: flex-start;
    background: var(--background3);
    min-height: 100px;
    margin-right: 10px;
`;

const VerificationTestTraccia = ({ details }) => {

    // console.log('VERIF TEST TRACCIA', details);

    return (
        <TestBody>
            <Question
                dangerouslySetInnerHTML={{ __html: details.elem.idTraceAnalysis_TraceAnalysis.title }}
            />

            <Wrapper>
                <LabelCss htmlFor="asw1">tema di fondo</LabelCss>
                <ResContainer>
                    {details !== undefined && details.elem.arguments}
                </ResContainer>

                <LabelCss htmlFor="asw2">Parole chiave</LabelCss>
                <ResContainer>
                    {details !== undefined && details.elem.keywords}
                </ResContainer>
            </Wrapper>
        </TestBody>
    );
}

export default VerificationTestTraccia;