import React from 'react';
import { useHistory } from "react-router-dom";

//PACKAGES
import styled from 'styled-components';

import {
    Th,
    // Td,
} from '../../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import ProgressBar from './ProgressBarChart';

const Thead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 1;
    /* background: var(--background4); */
        >tr>th{
            font-weight: bold;
            color: var(--text4);
        }
`;

const Tbody = styled.tbody`
   width: 100%;
        >tr{
            border-bottom: 1px solid #c4c4c426;
            cursor: pointer;
            >td{
                font-weight: bold;
                color: var(--text5);
                padding: 20px 10px;
                font-size: 16px;
                height: 50px;
                vertical-align: middle;
            }
             >td:nth-child(1){
                width: 150px;
            }
        }
`;

const Td = styled.td`
    position:relative;
    border:none;
    min-width: 180px;
    padding: 10px;
    font-size:var(--fontM);
        >span{   
            display: inline-block;
            width: 150px;
            text-align: center;
            padding: 5px;
            border-radius: var(--radius);
            background: ${props => props.color && `${props.color}` + '2b'};
            color: ${props => props.color};
        }
`;

const Color = styled.div`
    width: 20px;
    height: 20px;
    /* border-radius: var(--radius); */
    background: ${props => props.color};
`;

const tableHead = [
    'Genere',
    '',
    ''
];

const tableBody = [
    'Preselettiva',
    'TPA',
    'Orale',
    'Tema',
    'Fisica'
];

const colors = [
    '#fd6ba1',
    '#6b98fd',
    '#fdeb6b',
    '#4ecee3',
    '#ff9e66',
];

const ChartTable = ({ statistics }) => {
    const history = useHistory();

    const handleEvent = (event) => {
        // console.log(event);

        const flag = event.for;

        history.push({
            pathname: '/dashboard/profile/overview',
            state: { flag }
        });
    };
    

    return (
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <Thead>
                <tr >
                    {tableHead.map((th, i) =>
                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                    )}
                </tr>
            </Thead>
            <Tbody>
                {statistics !== undefined &&
                    statistics.map((elem, i) =>
                        < tr key={i} onClick={() => handleEvent(elem)}>
                            <Td color={colors[i]}>
                                <span>{tableBody[i]}</span>
                            </Td>
                            {/* <Td><Color color={colors[i]}></Color></Td> */}
                            <Td>
                                <ProgressBar
                                    // lessonsTotal={lessonsTotal}
                                    // lessonsCompletedTotal={lessonsCompletedTotal}
                                    total={elem.totalPercent}
                                />
                            </Td>
                        </tr >
                    )
                }
            </Tbody>
        </table >
    )
}

export default ChartTable;