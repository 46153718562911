import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

// ASSETS
import pic from '../../../../../../Assets/Capture.PNG';
import canvasImage from '../../../../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../../../../GeneralComponents/TestViewsBars/NavBar';
import TestPage from '../../../../TestPage/OneAswTest/TestPageWithOneAsw';
import FinishPage from './QuestFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
} from '../../../../PagesShareStyle/PagesShareStyle';


const TemaMainTestPageForQuest = ({ details }) => {
    const dispatch = useDispatch();
    const params = useParams();
    // const tests = useSelector(({ test }) => test.bringTest);
    const theTest = details.que;
    const [finishTheTest, setFinishTheTest] = useState(false);
    const [rest, setRest] = useState(0);


    useEffect(() => {
        // dispatch(bringTest(params.id))
    }, [])

    // console.log('TEMA TEST QUEST', details);

    
    return (
        theTest !== undefined &&
        <WrapperRightSide>
            <NavBar
                total={theTest.length}
                rest={theTest.length - 1}
                flagExercises='exercises'
            //rest={rest}
            />

            <TestPage
                tests={theTest}
                setRest={setRest}
                // testID={details.idTestExercises}
                testID={details.quest}
                setFinishTheTest={setFinishTheTest}
                rest={rest}
                flag='quest-tema'
            />

            {finishTheTest &&
                <FinishPage
                    // testID={tests.idTestExercises}
                    quest={details.quest}
                />
            }
        </WrapperRightSide>
    );
}

export default TemaMainTestPageForQuest;


