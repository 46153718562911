import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PopUp = styled.div`
    position:absolute;
    top: 0px;
    right: 35px;
    /* width: 100px; */
    padding: 10px;
       background: #000000e3;
    color: var(--text5);
    /* text-align: center; */
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(8px);
    transition: var(--transition);
    white-space: nowrap;
    letter-spacing: 1px;
`;

export const Title = styled.h3`
    width:100%;
    color:var(--text1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
        >i{
            font-size: 18px;
            margin-right: 10px;
        }
         /* @media (max-width: 800px) {
            display: none;
         } */
`;

export const Subtitle = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    box-shadow: 0px 26px 10px -18px rgb(0 0 0 / 5%);
    z-index: 1;
        >span{
            color: var(--text4);
        }
`;

export const EmptyNotif = styled.div`
    width:100%;
    height: calc(100vh - 160px);
    color:var(--text4);
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >i{
            font-size: 60px;
        }
     @media (max-width: 800px) {
            display: none;
            height: calc(100vh - 332px);
    }
`;

export const Empty = styled.div`
    width:100%;
    height: 100%;
    color:var(--text4);
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    >i{
        font-size: 40px;
    }
`;

export const GoBack = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: var(--text3);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: var(--background4);
    box-shadow: var(--shadow);
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
`;

export const Container = styled.div`
    display: flex;
        @media (max-width: 800px) {
            display: none;
         }
`;

export const UnreadWrapper = styled.div`
    position: relative;
    float: left;
    width: 50%;
    height: calc(100vh - 180px);
    overflow: auto;
    margin: 20px 10px 0px 0px;
    padding: 10px;
    background: var(--background4);
    border-radius: var(--radius);
        @media (max-width: 800px) {
            width: 100%;
            height: auto;
            margin: 20px 0px 0px;
            padding: 10px;
         }
`;

export const NotifContainer = styled.div`
    height: calc(100vh - 285px);
    overflow: auto;
    padding-right: 10px;
    padding-left: 5px;
    padding-bottom: 15px;
        ::-webkit-scrollbar {
            width: 2px;
        };
        ::-webkit-scrollbar-thumb {
            background: #c4c4c491;
            border: none;
            border-radius: 10px;
                &:hover{
                    background: #636363;
                }
        };
        @media (max-width: 800px) {
            height: calc(100vh - 308px);
         }
`;

export const ReadWrapper = styled.div`
    position: relative;
    float: right;
    width: 50%;
    overflow: auto;
    height: calc(100vh - 180px);
    margin: 20px 0 0px;
    padding: 10px;
    background: var(--background4);
    border-radius: var(--radius);
        @media (max-width: 800px) {
            width: 100%;
            height: auto;
            margin: 20px 0px 0px;
            padding: 10px;
         }
`;

export const ReadBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: ${props => props.delete ? '#fd6ba12b' : "#6b98fd2b"};
    color: var(--text5);
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
         >i{
            font-size: 16px;
            color: ${props => props.delete ? '#fd6ba1' : "#6b98fd"};
            padding-left: 2px;
        }
        &:hover{
            background: ${props => props.delete ? '#fd6ba1' : "#6b98fd"};;
            color: var(--text5); 
            >i{
                color: var(--text5);
            }
            ${PopUp} {
                    opacity: 1;
                    visibility: visible;
                }
        }
        &:active{
                transform:translateY(2px);
        }  
`;