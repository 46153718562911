import React, { useState } from "react";

import styled from "styled-components";

const PopUp = styled.div`
  position: absolute;
  top: -30px;
  left: 0px;
  padding: 10px;
  min-width: 200px;
  background: #000;
  color: #fff;
  font-size: 12px;
  text-align: left;
  border-radius: var(--radius);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }
`;

const UploadWrapper = styled.div`
  position: relative;
  width: 400px;
  height: 50px;
  padding: 15px;
  margin-top: 20px;
  padding-right: 60px;
  border: 1px solid var(--borderColor);
  border-radius: var(--radius);
  background: var(--background4);
  color: var(--text1);
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  transition: border 0.4s ease-in-out;
  display: flex;
  align-items: center;
  &:hover {
    border-color: var(--accentColor);
  }
  &:focus {
    outline: none;
    border: 1px solid var(--accentColor);
    ~ i {
      color: var(--accentColor);
      transition: color 0.4s ease-in-out;
    }
  }
  > h5 {
    color: var(--text1);
    &:hover ${PopUp} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const UploadFileInput = styled.input`
  position: absolute;
  right: 5px;
  top: 50%;
  width: 50px;
  height: 40px;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  &::-webkit-file-upload-button {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin: 0;
  }
`;

const Placeholder = styled.h5`
  font-size: 16px !important;
  color: var(--text2) !important;
  letter-spacing: 0 !important;
  cursor: default;
`;

const UploadBtn = styled.span`
  position: absolute;
  right: 5px;
  top: 50%;
  width: 50px;
  height: 40px;
  transform: translateY(-50%);
  border-radius: var(--radius);
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.color !== "" ? "var( --success)" : "#3bafda"};
  color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const TheComponent = ({newFile, setNewFile}) => {
//   const [newFile, setNewFile] = useState("");

  const showMore = (txt) => {
    if (txt !== "undefined" && txt !== undefined) {
      if (txt.length > 25) {
        return (
          <>
            {`${txt.slice(0, 25)}...`}
            <PopUp>{txt}</PopUp>
          </>
        );
      } else {
        return txt;
      }
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      setNewFile(file);
    } else {
      setNewFile("");
    }
  };

  return (
    <>

      <UploadWrapper>
        <UploadFileInput
          type="file"
          name="upload"
          id="upload"
          onChange={handleFile}
        />

        {newFile === "" ? (
          <Placeholder>Scegli un file...</Placeholder>
        ) : (
          <h5>{showMore(newFile.name)}</h5>
        )}

        <UploadBtn color={newFile}>
          {newFile === "" ? (
            <i className="fa-solid fa-paperclip"></i>
          ) : (
            <i className="fa-solid fa-check"></i>
          )}
        </UploadBtn>
      </UploadWrapper>
    </>
  );
};
export default TheComponent;