import React from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
// import { useSelector, useDispatch } from 'react-redux';


const TestBody = styled.div`
    padding-bottom: 20px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const BigQuestion = styled.div`
    width: 80%;
    max-height: 250px;
    overflow: scroll;
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 20px;
    background: var(--background4);
    padding: 20px 10px;
    border-radius: var(--radius);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        ::-webkit-scrollbar {
            width: 4px; 
        };
        @media (max-width: 1650px) {
            width: 95%;
        };  
`;

const Question = styled.div`
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

const Letter = styled.div`
    color:  var(--text2);
    font-size: 24px;
    font-weight: bold;
    display: flex;
    padding: 20px;
    background-color: var(--background3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Answers = styled.div`
    width: 100%;
    color:  var(--text2);
    font-size: 20px;
    display: flex;
    padding: 20px;
    background-color: var(--background4);
    >img{
        max-width: 400px;
        max-height: 400px;
        pointer-events: none;
    }
`;

const AnswerWrapper = styled.div`
    width: 80%;
    display: flex;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    background: var(--background4);
    cursor: pointer;
    ${Letter}{
            background: ${props => props.selected === 'selected' && 'var(--warning)'}!important;
            color: ${props => props.selected === 'selected' && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => props.selected === 'selected' && '#f8940655'}!important;
        }
        &:hover ${Letter}{ 
            background: #99979791;
            color: var(--text5);
        }
        &:hover ${Answers}{ 
            background: #bbbbbb55;
        } 
        @media (max-width: 1100px) {
            width: 100%;
        };
`;

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z'];


const QuestionCom = ({ tests, id, setAnswers, obj }) => {

    // console.log("QUESTIONS", tests);

    return (
        <TestBody>
            {(tests[id].bigQuestion !== null && tests[id].bigQuestion !== undefined && tests[id].bigQuestion !== ' ') && <BigQuestion dangerouslySetInnerHTML={{ __html: tests[id].bigQuestion }} />}
            {/* {tests[id].bigQuestion === null && <BigQuestion>Nel 2013 Edward Snowden ha contribuito a rendere pubblici i programmi di sorveglianza messi in atto dalle intelligence inglese. Come viene giornalisticamente chiamato il programma con cui l'agenzia Government Communications Headquarters (Gchq) ha ascoltato e intercettato il traffico sui cavi sottomarini in fibra ottica.</BigQuestion>} */}

            {/* <Question>{tests[id].question}</Question> */}

            <Question
                dangerouslySetInnerHTML={{ __html: tests[id].question }}
            />

            {(tests[id].url_question !== undefined && tests[id].url_question !== ' ' && tests[id].url_question !== '') && <img src={tests[id].url_question} alt='img'></img>}
            {JSON.parse(tests[id].answers).map((ans, i) =>
                <AnswerWrapper
                    key={i}
                    onClick={(e) => setAnswers(e, i, letters)}
                    selected={obj[id] !== undefined && obj[id].aswIndex === i && 'selected'}
                >
                    <Letter >{letters[i]}</Letter>
                    {ans.url !== ' ' && ans.url !== '' ?
                        // <img src={ans.url} alt='img'></img>
                        <Answers id={i}><img src={ans.url} alt='img'></img></Answers>
                        :
                        <Answers id={i}
                            dangerouslySetInnerHTML={{ __html: ans.ans }}
                        />
                    }
                    {/* <Answers id={i}>{ans.ans}</Answers> */}
                </AnswerWrapper>
            )}
        </TestBody>
    );
}

export default QuestionCom;