import React, { useState, useEffect } from 'react';

// ASSETS
import brush from '../../../../../Assets/brush.png'

// PACKAGES
import styled from 'styled-components';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// COMPONENTS
import AllQuestionsForTemaVerification from '../../../../GeneralComponents/AllQuestions/AllQuestionsForTemaView';

// REDUX
import { useSelector } from 'react-redux';

import {
    Wrapper,
    CommentWrapper,
    // GradeWrapper,
    // LabelCss,
    QueContainer,
    // ResContainer,
    Section,
} from './Style';


const TestBody = styled.div`
    padding-bottom: 80px;
        >img{
            max-width: 400px;
            max-height: 400px;
        }
`;

const EditorContainer = styled.div`
	   /* .editorClassName{
            height: calc(100vh - 360px);
            background: white;
        } */
        .DraftEditor-root {
            position: relative;
            height: calc(100vh - 451px);
        }
     
        .editorClassName{
            height: calc(100vh - 415px);
            background: white;
            padding: 10px;
        }
        .rdw-colorpicker-modal{
            position: absolute;
            top: 50px;
            left: -180px;
            display: flex;
            flex-direction: column;
            width: 230px;
            height: 255px;
            border: 1px solid #F1F1F1;
            padding: 15px;
            border-radius: 2px;
            z-index: 100;
            background: white;
            box-shadow: 3px 3px 5px #bfbdbd;
        }
        .rdw-emoji-modal{
            overflow: auto;
            position: absolute;
            top: 50px;
            left: -190px;
            display: flex;
            flex-wrap: wrap;
            width: 235px;
            height: 180px;
            border: 1px solid #F1F1F1;
            padding: 10px;
            border-radius: 2px;
            z-index: 100;
            background: white;
            box-shadow: 3px 3px 5px #bfbdbd;
        }
        .rdw-option-wrapper{
            width: 40px;
            height: 40px;
            border-radius: var(--radius);
        }
        .rdw-fontsize-dropdown {
            width: 110px;
        }
        .rdw-dropdown-optionwrapper{
            width: 60px;
        }
        .rdw-fontfamily-optionwrapper{
            width: 110px;
        }
        .rdw-dropdown-optionwrapper{
            width: 110px;
        }
`;

const AvvioView = ({ oneTema }) => {
    // console.log('TEMA VIEW', oneTema);
    const [id, setId] = useState(0);
    const [obj, setObj] = useState([]);

    useEffect(() => {
        if (oneTema !== undefined) {
            let createObj = [];
            // const answ = JSON.parse(oneTema.answers);
            const answ = oneTema.profCorrection === null ? JSON.parse(oneTema.answers) : JSON.parse(oneTema.profCorrection);
            for (let i = 0; i < answ.length; i++) {
                createObj.push({
                    asw: EditorState.createWithContent(convertFromRaw(answ[i].aswTxt)),
                    aswIndex: answ[i].aswIndex,
                    check: answ[i].check,
                    aswTxt: answ[i].aswTxt
                })
            }
            setObj(createObj);
        }
    }, [oneTema]);


    return (
        <Wrapper style={{ width: 'calc(100% - 240px)' }}>
            <AllQuestionsForTemaVerification
                id={id}
                setId={setId}
                arr={oneTema !== undefined && JSON.parse(oneTema.questions)}
                obj={oneTema !== undefined && JSON.parse(oneTema.answers)}
                flag="tema"
                type='avvio'
            />

            <div style={{ width: '100%' }}>
                {oneTema !== undefined &&
                    <QueContainer
                        dangerouslySetInnerHTML={{ __html: JSON.parse(oneTema.questions)[id].title }}
                    />
                }

                <EditorContainer style={{ marginTop: '20px' }}>
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={obj.length !== 0 ? obj[id].asw : null}
                        readOnly={true}
                        toolbarHidden={true}
                        localization={{
                            locale: 'it',
                        }}
                    />
                </EditorContainer>
            </div>

            <CommentWrapper>
                {(oneTema.comments !== null &&
                    Object.keys(JSON.parse(oneTema.comments)[id]).length) &&
                    JSON.parse(oneTema.comments)[id].map((comment, i) =>
                        <div key={i}>
                            <span>{i + 1}.</span>
                            {comment.message}
                        </div>
                    )
                }
            </CommentWrapper>
        </Wrapper>
    );
}

export default AvvioView;