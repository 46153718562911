import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';
import FileDownload from 'js-file-download';

// COMPONENTS
import Quest from './Quest/Quest';

// PAGE

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllTemaQuestionnaires, downloadFile } from '../../../../../Store/Actions/temaActions';


// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../../../PagesShareStyle/PagesShareStyle';

const TitleWrapper = styled.div`
    position: relative;
    display: flex;
`;

const Title = styled.h3`
  /* width:100%; */
  color:var(--text2);
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const GoBack = styled.div`
  color: var(--text3);
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: var(--background4);
  min-width: 100px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  padding: 10px;
        &:hover{
            color: var(--text2)
        }
        >i{
            margin-right: 10px;
        }
`;

const VideoWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    background: var(--background4);
    >video{
        width: 100%;
    }
`;

const InfoWrapper = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const Info = styled.div`
    margin-top: 20px;
    width: 100%;
    font-size: var(--fontL);
    color: var(--text2);
`;

const Header = styled.div`
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Sts = styled.div`
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 2px;
    width: 70px;
    height: 24px;
    /* margin-right: 10px; */
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)') ||
        (props.color === 'In attesa' && 'var(--background1)')
    };
`;

const PopUp2 = styled.div`
    position:absolute;
    top: -45px;
    left: 0;
    padding: 10px;
    background: #6f6f6f73;
    color: var(--text5);
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
`;

const Uploaded = styled.div`
    position: relative;
    width:450px;
    height: 50px;
    color: var(--color2);
    font-weight: bold;
    font-size: 16px;
    border-radius: var(--radius);
    margin-right: 20px;
    margin-top: 40px;
    padding: 10px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    background: var(--background4);
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
    &:hover ${PopUp2} {
                opacity: 1;
                visibility: visible;
        }
    >i{
        margin-right: 10px;
    }
`;

const MiniBtn = styled.button`
    position: absolute;
    top: -25px;
    right: -10px;
	width: 20px;
    height: 20px;
    border: none;
    border-radius: 30px;
    /* margin: 10px; */
    background-color: var(--background3);
    /* border: 2px solid var(--err); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-weight: bold; */
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    padding: 0;
        >i{
            color: var(--text3);
        }
        &:hover{
            background-color: var(--background4);
            box-shadow: var(--shadow);
            >i{
                color: var(--err);
            }  
        }
`;

const EmptyPage = styled.div`
    width: 100%;
    height: calc(100vh - 365px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >h2{
            color: var(--text3);
        }
        >i {
            font-size: 80px;
            padding-top: 20px;
            color: var(--text3);
        }
`;

const TemaDocuments = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    // const quests = useSelector(({ tema }) => tema.allTemaQuest);
    const file = useSelector(({ tema }) => tema.file);
    // const [quest, setQuest] = useState();
    const [doc, setDoc] = useState();

    // useEffect(() => {
    //     const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
    //     dispatch(getAllTemaQuestionnaires(details.less.TemaLessons_idTemaLesson, idUser))
    // }, [dispatch]);

    const handleDownload = (doc) => {
        dispatch(downloadFile(doc))
    };

    useEffect(() => {
        if (file !== undefined) {
            FileDownload(file.data, file.name);
            //dispatch(clearFile());
        };
    }, [file]);


    useEffect(() => {
        if (details.less !== undefined) {
            let newOne = [];
            const docu = details.less.TemaLessons_idTemaLesson_TemaLesson.TemaDocumentsLessons;
            for (let i = 0; i < docu.length; i++) {
                if (docu[i].excluded === null) {
                    // setDoc(docu);
                    newOne.push(docu[i]);
                } else {
                    const findIfExists = JSON.parse(docu[i].excluded).find(id => id === details.course.idCourse);
                    if (findIfExists) {
                        newOne.push(docu[i]);
                    }
                }
            }
            setDoc(newOne);
        }
    }, [details]);

    // console.log("DOC", quests);
    // console.log('DOC DETAILS', details);
    // console.log("DOC FILE", file);

    return (
        <>
            <TitleWrapper>
                <Title>{details.less.TemaLessons_idTemaLesson_TemaLesson.name}</Title>
                <Sts color={details.less.TemaLessons_idTemaLesson_TemaLesson.sts}>{details.less.TemaLessons_idTemaLesson_TemaLesson.sts}</Sts>
            </TitleWrapper>

            <InfoWrapper >
                <Title>Nome della lezione: {details.less.TemaLessons_idTemaLesson_TemaLesson.name}</Title>
                <Info>Titolo della lezione: {details.less.TemaLessons_idTemaLesson_TemaLesson.title}</Info>
            </InfoWrapper>

            {doc !== undefined &&
                doc.length > 0 ?
                doc.map((document, i) =>
                    <Uploaded
                        key={i}
                        onClick={() => {
                            handleDownload(document)
                        }
                        }
                    >
                        <i className="fa-solid fa-link"></i>{document.name}
                    </Uploaded>
                )
                :
                <EmptyPage>
                    <h2>Nessun documento disponibile</h2>
                    <i className="fa-solid fa-folder-open"></i>
                </EmptyPage>
            }

            {/* {details.less !== undefined &&
                details.less.TemaLessons_idTemaLesson_TemaLesson.TemaDocumentsLessons
                    .length > 0 ?
                details.less.TemaLessons_idTemaLesson_TemaLesson.TemaDocumentsLessons
                    .map((doc, i) =>
                        <Uploaded
                            key={i}
                            onClick={() => {
                                // console.log(doc.name)
                                handleDownload(doc)
                            }
                            }
                        // onClick={() => console.log(doc.name)}
                        >
                            <i className="fa-solid fa-link"></i>{doc.name}
                        </Uploaded>
                    )
                :
                <EmptyPage>
                    <h2>Nessun documento disponibile</h2>
                    <i className="fa-solid fa-folder-open"></i>
                </EmptyPage>
            } */}
        </>
    );
}

export default TemaDocuments;