import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import PhysicalLearningPageHeader from './PhysicalLearningPageHeader';
import PhysicalSubjects from './PhysicalSubjects';
// import PhysicalTypologies from './PhysicalTypologies';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllPhysicalSubjects } from '../../../Store/Actions/physicalActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
    GoBack
} from '../PagesShareStyle/PagesShareStyle';

const Info = styled.div`
    width: 100%;
    font-size: var(--fontL);
    color: var(--text2);
    margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
    position: absolute;
    left: 28px;
    top: 110px;
    z-index: 1;
        @media (max-width: 1099px) {
            left: 30px;
        };
`;


const PhysicalLearningPage = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const materie = useSelector(({ physical }) => physical.allPhysicalSubs);

    const [lessonsTotal, setLessonsTotal] = useState(0);
    const [lessonsCompletedTotal, setLessonsCompletedTotal] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
        dispatch(getAllPhysicalSubjects(details.course.idCourse, idUser));
    }, [dispatch]);

    useEffect(() => {
        if (materie !== undefined) {
            let subjectsNr = [];
            console.log("MATERIE", materie)
            for (let i = 0; i < materie.length; i++) {
                // subjectsNr.push(materie[i].lessons.length);
                subjectsNr.push({
                    nrLessons: materie[i].PhisicalSubjects_has_PhisicalLessons.length,
                    videoCompleted: 0,
                    questCompleted: 0,
                    totalQuest: 0,
                })
                if (materie[i].PhisicalSubjects_has_PhisicalLessons.length > 0) {
                    let nrCompelete = 0;
                    let nrQuestCompleted = 0;
                    let totalQue = 0;
                    for (let j = 0; j < materie[i].PhisicalSubjects_has_PhisicalLessons.length; j++) {
                        const nr = materie[i].PhisicalSubjects_has_PhisicalLessons[j].PhisicalLessons_idPhisicalLesson_PhisicalLesson.PhisicalLessonCompletes.length;
                        if (materie[i].PhisicalSubjects_has_PhisicalLessons[j].PhisicalLessons_idPhisicalLesson_PhisicalLesson.ondemand !== null) {
                            nrCompelete += nr;
                        } else {
                            nrCompelete += 1;
                        };

                        const questDone = materie[i].PhisicalSubjects_has_PhisicalLessons[j].PhisicalLessons_idPhisicalLesson_PhisicalLesson.PhisicalQuestionnaires;

                        // totalQuest = questDone.length;
                        const questNr = questDone.length;
                        // console.log('questNr', questNr);
                        totalQue += questNr;
                        for (let k = 0; k < questDone.length; k++) {
                            const nr = questDone[k].PhisicalQuestionnaireDones.length;
                            nrQuestCompleted += nr;
                        };
                        // lessonsVideoCompleted.push(materie[i].lessons[j].Lessons_idLesson_Lesson.LessonCompletes.length);
                    };
                    const value = [...subjectsNr]
                    value[i].videoCompleted = nrCompelete;
                    value[i].questCompleted = nrQuestCompleted;
                    value[i].totalQuest = totalQue;
                };
            };

            console.log('NR', subjectsNr);
            let totalLessons = subjectsNr.length;
            let totalLessonsCompleted = 0;
            let lessonsPercent = 0;

            let totalQuest = 0;
            let totalQuestCompleted = 0;

            let questsPercent = 0;

            for (let i = 0; i < subjectsNr.length; i++) {
                // totalLessons += subjectsNr[i].nrLessons;
                // if (subjectsNr[i].questCompleted === subjectsNr[i].totalQuest && subjectsNr[i].totalQuest > 0) {
                if (subjectsNr[i].questCompleted === subjectsNr[i].totalQuest) {
                    totalLessonsCompleted += 1;
                };

                let videoPercent = 0;
                if (subjectsNr[i].videoCompleted > 0) {
                    videoPercent += (0.5 * subjectsNr[i].videoCompleted) / subjectsNr[i].nrLessons;
                }
                lessonsPercent += videoPercent / subjectsNr.length;

                totalQuest += subjectsNr[i].totalQuest;
                totalQuestCompleted += subjectsNr[i].questCompleted;

                // questsPercent += (totalQuest !== 0 && totalQuestCompleted !== 0) ?
                //     ((totalQuestCompleted / totalQuest) * 0.5) / subjectsNr.length
                //     :
                //     0;

                questsPercent += (totalQuest !== totalQuestCompleted) ?
                    ((totalQuestCompleted / totalQuest) * 0.5) / subjectsNr.length
                    :
                    0;
            }
            // console.log('totalLessons', totalLessons);
            // console.log('totalLessonsCompleted', totalLessonsCompleted);
            // console.log('lessonsPercent', lessonsPercent);

            // console.log('questPercent', questsPercent);

            const totalPercent = (lessonsPercent + questsPercent).toFixed(2) * 100;

            // console.log('totalPercent', totalPercent);

            setLessonsTotal(totalLessons);
            setLessonsCompletedTotal(totalLessonsCompleted);
            setTotal(totalPercent);
        };
    }, [materie]);

    // console.log("PHY DETAILS", details);
    console.log("PHY LEARNING PAGE DETAILS", materie);

    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
            </TitleWrapper>

            <PhysicalLearningPageHeader
                course={details.course}
                studDetails={details.studDetails}
                lessonsTotal={lessonsTotal}
                lessonsCompletedTotal={lessonsCompletedTotal}
                total={total}
            />

            <Info
                dangerouslySetInnerHTML={{ __html: details.course.desc }}
            />

            <PhysicalSubjects
                idCourse={details.course.idCourse}
                idCheckUser={details.idCheckUser}
                studDetails={details.studDetails}
                course={details.course}
            />

        </WrapperRightSide>
    );
}

export default PhysicalLearningPage;
