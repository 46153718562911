import React, { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';


// Redirect to the login page if the user is not logged in and goes to a NonPrivateRoute
export const PrivateRoute = memo(function PrivateRoute({ children, ...rest }) {
    const isAuthentificated = useSelector(({ auth }) => auth.isAuthentificated);
    // console.log('AUTH UTILS', isAuthentificated);

    return (
        <Route
            {...rest}
            render={() => {
                if (!isAuthentificated) {
                    return <Redirect to="/" />;
                }
                return children;
            }}
        />
    );
});

export const NonPrivateRoute = memo(function NonPrivateRoute({ children, ...rest }) {
    const isAuthentificated = useSelector(({ auth }) => auth.isAuthentificated);
    // console.log('AUTH UTILS NON', isAuthentificated);

    return (
        <Route
            {...rest}
            render={() => {
                if (isAuthentificated) {
                    return <Redirect to="/dashboard" />;
                }
                return children;
            }}
        />
    );
});

export const OtherNonPrivateRoute = ({path, children, ...rest }) => {
    const isAuthentificated = useSelector(({ auth }) => auth.isAuthentificated);
    console.log('AM INTRAT IN FCT', isAuthentificated, path);

    return (
        <Route
            {...rest}
            // exact ={path === '/' && true}
            exact = {false}
            render={() => {
                if (!isAuthentificated) {
                    return <Redirect to="/" />;
                }
                return children;
            }}
        />
        // !isAuthentificated ?
        //     <Route
        //         {...rest}
        //         render={() => {
        //             return <Redirect to="/" />;
        //         }
        //         }
        //     />

        //     :

        //     <Route
        //         {...rest}
        //         render={() => {
        //             return children
        //         }
        //         }
        //     />

    );
};

