const initState = {

};

const tpaReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ALL_TPA_EXERCICES':
            return {
                ...state,
                allTpa: action.payload,
            };

        case 'ALL_TPA_GRAFICI':
            return {
                ...state,
                allTpaGrafici: action.payload,
            };

        case 'ALL_TPA_TYPOLOGIES':
            return {
                ...state,
                allTypologies: action.payload,
            };
        case 'ALL_ALL_TPA_DONE':
            return {
                ...state,
                allDrawing: action.payload,
            };

        case 'ALL_TPA_DONE':
            return {
                ...state,
                allTpaDone: action.payload,
            };

        case 'TPA_DONE_FOR_USER':
            return {
                ...state,
                allTpaDoneForUser: action.payload,
            };

        case 'TPA_UPDATE_TABLE_TEST':
            return {
                ...state,
                table: action.payload,
            };

        // case 'TPA_DONE_FOR_STUDENT':
        //     return {
        //         ...state,
        //         tpaDoneForStudent: action.payload,
        //     };

        default:
            return state;
    }
};

export default tpaReducer;