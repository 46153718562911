import { useEffect, useState, useRef } from 'react';

//ASSETS
import avatar from '../../../Assets/avatar.png';
import bg from '../../../Assets/bg.png';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

// const Header = styled.div`
//     position: relative;
// `;

// const Visual = styled.div`
//     /* height: 350px; */
//     background-image: linear-gradient(-225deg,#A445B2 0%,#D41872 52%,#d32058 100%);
//     border-radius: var(--radius);
//     box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06);
//     margin-bottom: 40px;
//         >div{
//             position: relative;
//             width: 100%;
//             height: 100%;
//             background: var(--background3);
//             backdrop-filter: var(--blur);
//             border-radius: var(--radius);
//             display:flex;
//             justify-content: flex-start;
//             padding: 20px;

//                 /* >h3{
//                     position: absolute;
//                     bottom: -130px;
//                     left: 50%;
//                     transform:translate(-50%, 0px);
//                     font-weight: bold;
//                     color: var(--text1);
//                     z-index: 1;
//                 } */
//         }  
// `;

// const Info = styled.div`
//     margin-left: 10px;
//         >h3{
//             color: var(--text5);
//             font-weight: bold;
//         }
//         >h5{
//             color: var(--text5);
//             font-weight: 100;
//         }
// `;

// const LogoDisplay = styled.div`
//     position: relative;
//     height: 100px;
//     width: 100px;
//     border-radius: var(--radius);
//     display:flex;
//     align-items:center;
//     justify-content:center;
//     overflow: hidden;
//     background: var(--background3);
//     color: var(--text4);
//     font-size: 50px;
//     box-shadow: 0 .3125rem .625rem 0 rgba(0,0,0,.12);
// `;

// const LogoImg = styled.img`
//     height: 100%;
//     width: 100%;
//     position:absolute;
//     top:50%;
//     left:50%;
//     transform: translate(-50%, -50%);
//     object-fit: cover;
//     background: var(--background4);
// `;

// // LOGO
// const UploadPozWrapper = styled.div`
//     position: absolute;
//     bottom: 3px;
//     right: 3px;
//     /* left: 50%;
//     transform:translateX(50%); */
//     z-index: 1;
//     /* margin: 10px; */
// `;

// const UploadWrapper = styled.div`
//     position: relative;
//     text-align: center;
//     width: 30px;
//     height: 30px;
//     display: flex;
//         >span{
//             /* z-index: 0; */
//             width: 30px;
//             height: 30px;
//             border-radius: 30px;
//             cursor: pointer;
//             color: var(--text5);
//             font-size:20px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             background: var(--background1);
//             backdrop-filter: var(--blur);
//             >i{
//             font-size: 14px;
//             }
//         }
// `;

// const DeleteBtn = styled.button`
//     width: 30px;
//     height: 30px;
//     border-radius: 30px;
//     border: none;
//     cursor: pointer;
//     color: var(--text5);
//     font-size: 14px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background: var(--background1);
//     backdrop-filter: var(--blur);
// `;

// const UploadFileInput = styled.input`
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 30px;
//     height: 30px;
//     z-index: 1;
//     opacity: 0;
//     cursor: pointer;
//         &::-webkit-file-upload-button{
//             cursor: pointer;
//         }
// `;

const Wrapper = styled.div`
    display: flex;
    margin-bottom: 40px;
        @media (max-width: 800px) {
            flex-direction: column;
        };
`;

const ProfileImgWrapper = styled.div`
    /* margin-right: 20px; */
    min-height: 400px;
          @media (max-width: 800px) {
            width: 100%;
        };
`;

const ProfileDetails = styled.div`
    position: relative;
    width: 100%;
    min-height: 400px;
    background: white; 
    margin-left: 20px;
    border-radius: var(--radius);
    overflow: hidden;
    /* padding: 20px; */
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        >img{
            position: absolute;
            left: -20px;
            width: 110%;
            height: 100%;
            object-fit: cover;
        }
        >div{
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 1;
            >h4{
                color: var(--text2);
                font-weight: bold; 
                
            }
        }
         @media (max-width: 800px) {
            margin-left: 0px;
            margin-top: 40px;
        };
`;

const MoreInfo = styled.div`
    margin-top: 20px;
        >h5{
            >span {
                color: var(--text4);
            }
            font-weight: bold;
            color: var(--text2);
            margin-bottom: 5px;
        }
`;

const BackgroundEffect = styled.div`
    width: 450px;
    height: 100%;
    background-image: linear-gradient(-225deg,#A445B2 0%,#D41872 52%,#d32058 100%);
    background-image: linear-gradient(103.75deg, #33B1EE -13.9%, #7366ff 79.68%);
    border-radius: var(--radius);
    box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06);
        @media (max-width: 800px) {
            width: 100%;;
        };
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    min-height: 400px;
    background: var(--background3);
    backdrop-filter: var(--blur);
    border-radius: var(--radius);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

`;

const Info = styled.div`
    margin-left: 10px;
        >h2{
            margin-top: 20px;
            color: var(--text5);
            font-weight: bold;
        }
        >h5{
            text-align: center;
            color: var(--text5);
            font-weight: 100;
        }
`;

const LogoWrapper = styled.div`
    position: relative;
    height: 120px;
    width: 120px;
`;

const DisplayLogo = styled.div`
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 120px;
    overflow: hidden;
    display:flex;
    align-items:center;
    justify-content:center;
    background: var(--background3);
    color: var(--text4);
    font-size: 50px;
    box-shadow: 0 .3125rem .625rem 0 rgba(0,0,0,.12);
`;

const LogoImg = styled.img`
    height: 100%;
    width: 100%;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    background: var(--background4);
`;

// LOGO
const UploadButtonWrapper = styled.div`
    position: absolute;
    bottom: 3px;
    right: 3px;
    /* left: 50%;
    transform:translateX(50%); */
    z-index: 1;
    /* margin: 10px; */
`;

const UploadBtn = styled.div`
    position: relative;
    text-align: center;
    width: 30px;
    height: 30px;
    display: flex;
        >span{
            /* z-index: 0; */
            width: 30px;
            height: 30px;
            border-radius: 30px;
            cursor: pointer;
            color: var(--text5);
            font-size:20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--background1);
            backdrop-filter: var(--blur);
            >i{
            font-size: 14px;
            }
        }
        /* &:hover {
            width: 140px;
            >span{
                width: 140px;
            }
        } */
`;

const DeleteBtn = styled.button`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    color: var(--text5);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background1);
    backdrop-filter: var(--blur);
`;

const UploadFileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
        &::-webkit-file-upload-button{
            cursor: pointer;
        }
`;

const EditBtn = styled.button`
    border: none;
    outline: none;
    color: var(--text2);
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var( --background4);
    border-radius: var(--radius);
    padding: 10px;
    width: 150px;
    margin-top: 15px;
`;

const ProfileHeader = () => {
    const profile = useSelector(({ auth }) => auth.user);
    const [imagePreview, setImagePreview] = useState();

    // console.log("PROFILE", profile);
    // console.log('COURSES', courses);

    const handleDelete = () => {
        // dispatch(deleteProfilePicture());
    };

    // Handle the Picture
    const handleFile = (e) => {
        const image = e.target.files[0];
        // DISPLAY THE IMG/FILE
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.readyState === 2) {
                setImagePreview(reader.result);
            }
        };
        if (image !== undefined) {
            reader.readAsDataURL(image);
        };
        // setTimeout(() => {
        //     if (profile.flag === 'teammate') {
        //         dispatch(changeTeammateProfilePicture(image));
        //     } else {
        //         dispatch(changeProfilePicture(image));
        //     }
        // }, 2000);
    };

    const formatData = (data) => {
        // console.log("data", data)
        let d = data.split('/');
        let format = ''
        if (d.length > 1) {
            format = d[1] + '/' + d[0] + '/' + d[2];
        } else {
            d = data.split('-');
            format = d[2] + '/' + d[1] + '/' + d[0]
            //format
        }


        return format
    }


    return (

        // <Header>
        //     <Visual>
        //         <div>
        //             <LogoDisplay>
        //                 {imagePreview === null || imagePreview === undefined ?
        //                     profile !== undefined ?
        //                         // profile.userImg === '' ?
        //                         profile === '' ?
        //                             < LogoImg src={avatar} alt=""></LogoImg>
        //                             :
        //                             // < LogoImg src={profile.userImg} alt=""></LogoImg>
        //                             < LogoImg src={avatar} alt=""></LogoImg>
        //                         :
        //                         < LogoImg src={avatar} alt=""></LogoImg>
        //                     :
        //                     <LogoImg src={imagePreview} alt=""></LogoImg>
        //                 }

        //                 <UploadPozWrapper>
        //                     {profile !== undefined &&
        //                         profile.userImg !== undefined && profile.userImg !== null ?
        //                         <DeleteBtn onClick={handleDelete}>
        //                             <i className="far fa-trash-alt"></i>
        //                         </DeleteBtn>
        //                         :
        //                         <UploadWrapper>
        //                             <span ><i className="fa-solid fa-camera-retro"></i></span>
        //                             <UploadFileInput
        //                                 type="file"
        //                                 name="upload"
        //                                 id="upload"
        //                                 placeholder="Upload File"
        //                                 onChange={(e) => handleFile(e)} />
        //                         </UploadWrapper>
        //                     }
        //                 </UploadPozWrapper>
        //             </LogoDisplay>

        //             <Info>
        //                 <h3>{profile.firstName} {profile.lastName}</h3>
        //                 <h5>{profile.email}</h5>
        //             </Info>
        //         </div>
        //     </Visual>
        // </Header>
        <Wrapper>
            <ProfileImgWrapper>
                <BackgroundEffect>
                    <Container>
                        <LogoWrapper>
                            <DisplayLogo>
                                {imagePreview === null || imagePreview === undefined ?
                                    profile !== undefined ?
                                        // profile.userImg === '' ?
                                        profile === '' ?
                                            < LogoImg src={avatar} alt=""></LogoImg>
                                            :
                                            // < LogoImg src={profile.userImg} alt=""></LogoImg>
                                            < LogoImg src={avatar} alt=""></LogoImg>
                                        :
                                        < LogoImg src={avatar} alt=""></LogoImg>
                                    :
                                    <LogoImg src={imagePreview} alt=""></LogoImg>
                                }
                            </DisplayLogo>

                            <UploadButtonWrapper>
                                {profile !== undefined &&
                                    profile.userImg !== undefined && profile.userImg !== null ?
                                    <DeleteBtn onClick={handleDelete}>
                                        <i className="far fa-trash-alt"></i>
                                    </DeleteBtn>
                                    :
                                    <UploadBtn>
                                        <span ><i className="fa-solid fa-camera-retro"></i></span>
                                        <UploadFileInput
                                            type="file"
                                            name="upload"
                                            id="upload"
                                            placeholder="Upload File"
                                            onChange={(e) => handleFile(e)} />
                                    </UploadBtn>
                                }
                            </UploadButtonWrapper>
                        </LogoWrapper>

                        <Info>
                            <h2>{profile.firstName} {profile.lastName}</h2>
                            <h5>{profile.email}</h5>
                        </Info>

                        {/* <EditBtn>
                        Modificare
                    </EditBtn> */}
                    </Container>
                </BackgroundEffect>
            </ProfileImgWrapper>

            <ProfileDetails>
                <img src={bg} alt='bg'></img>
                <div>
                    <h4>
                        Maggiori informazioni
                    </h4>

                    <MoreInfo>
                        <h5><span>Ruolo: </span>{profile.role}</h5>
                        <h5><span>Attivo da: </span>{formatData(profile.active)}</h5>
                        <h5><span>Attivo fino al: </span>{formatData(profile.expire)}</h5>
                    </MoreInfo>
                </div>
            </ProfileDetails>
        </Wrapper>
    );
}

export default ProfileHeader;