const initState = {
  // readedNotif: [],
  // unreadNotif: [],
};

const notificationsReducer = (state = initState, action) => {
  switch (action.type) {

    case 'GET_READED_NOTIFICATIONS':
      return {
        ...state,
        readedNotif: action.payload,
      };

    case 'UNREAD_NOTIFICATIONS':
      return {
        ...state,
        unreadNotif: action.payload,
      };

    default:
      return state;
  }
};

export default notificationsReducer;