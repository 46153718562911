import React, { useState, useEffect, useRef } from 'react';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import ChatNavBar from './ChatNavBar';
import MessageBar from './MessageBar';
import MessageCard from './MessageCard';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { findMessages, getConversations } from '../../../../..//Store/Actions/conversationsActions';
import { setMsgListen } from '../../../../../Store/Actions/otherActions';

const Header = styled.div`
    position: relative;
    background: var(--background2);
`;

const Body = styled.div`
    position:relative;
    background: #0000000d;
    height: ${props => props.h === 'true' ? 'calc(100vh - 230px)' : 'calc(100vh - 160px)'};
    /* padding-bottom: 180px; */
    overflow: auto;
        >h3{
            position: absolute;
            width:100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align:center;
            color: var(--text4);
            padding: 20px;
        }
`;

const Bottom = styled.div`
    position: absolute;
    bottom: 0; 
    width: 100%;
    /* background: var(--background2); */
`;


const Chat = ({ chat, socket, curentUserChat, hasRead }) => {
    const dispatch = useDispatch();
    const scrollRef = useRef();
    const mounted = useRef(false);
    const user = useSelector(({ auth }) => auth.user);
    const conv = useSelector(({ conversations }) => conversations.conv);
    const mess = useSelector(({ conversations }) => conversations.mess);
    const [messages, setMessages] = useState();
    const [arrivalMsg, setArrivalMsg] = useState();

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (user !== undefined && chat !== undefined) {
            dispatch(findMessages(chat?.convId))
        }
    }, [dispatch, chat, user]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({
            block: 'nearest', inline: 'start'
        });
    }, [messages]);

    useEffect(() => {
        setMessages(mess);
    }, [mess]);

    useEffect(() => {
        if (socket !== null) {
            socket.on('getMessages', data => {
                // console.log('DATA', data);
                if (mounted.current === true) {
                    setArrivalMsg({
                        SenderID: data.senderId,
                        Text: data.text,
                        Chat: data.chat,
                        createdAt: Date.now(),
                    })
                    action(data.chat);
                    // hasRead(data);
                    dispatch(setMsgListen(data));
                }
            })
        }
    }, [socket]);

    useEffect(() => {
        if (chat !== undefined) {
            if (arrivalMsg !== undefined) {
                if (chat.convId === arrivalMsg.Chat.convId) {
                    setMessages((prev) => [...prev, arrivalMsg]);
                }
            }
        }
    }, [arrivalMsg, chat]);

    const action = (chat) => {
        if (conv?.some(con => conv.from === chat.convId || conv.to === chat.convId)) {
            return
        } else {
            dispatch(getConversations(chat.userId));
        }
    };

    // console.log('ARIVALE', arrivalMsg, conv);
    // console.log('CHAT', chat);
    // console.log('MESS', mess);
    // console.log('CURENT AICI', curentUserChat);

    return (
        <>
            <Header>
                <ChatNavBar curentUserChat={curentUserChat} />
            </Header>

            <Body h={curentUserChat !== undefined && 'true'}>
                {chat === undefined ?
                    <h3>Apri una conversazione per iniziare</h3>
                    :
                    <>
                        {messages !== undefined &&
                            messages.map((m, i) =>
                                <div key={i} ref={scrollRef}>
                                    <MessageCard
                                        message={m}
                                        own={m.SenderID === user.idUser.toString() ? 'true' : 'false'}
                                    />
                                </div>
                            )
                        }
                    </>
                }
            </Body>

            {curentUserChat !== undefined &&
                <Bottom>
                    {chat !== undefined &&
                        <MessageBar
                            user={user?.idUser}
                            conv={chat}
                            socket={socket}
                            setArrivalMsg={setArrivalMsg}
                            chat={chat}
                        />
                    }
                </Bottom>
            }

        </>
    );
}

export default Chat;
