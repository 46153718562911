import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//ASSETS
import avatar from '../../../Assets/avatar.png';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Header from './Header';
import ChartLine from './ChartLine';
import ChartPie from './ChartPie';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { getAllCourses } from '../../../Store/Actions/coursesActions';
import { resetStat } from '../../../Store/Actions/statisticsActions';

// STYLE
import {
    WrapperRightSide,
} from '../PagesShareStyle/PagesShareStyle';

const MessBtn = styled(Link)`
    position: fixed;
    bottom: 12px;
    right: 0px;
    z-index: 2;
    margin: 0 5px;
    background-color: var(--background4);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    cursor: pointer;
    opacity: 0.6;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >i{
            font-size: 24px;
            color: var(--text1);
            padding: 15px;
            &:hover{
                color: var(--acc-color);
            }
        }
        &:hover{
            transition: all .2s linear;
            opacity: 1;
        }
`;

const GeneralWrapper = styled.div`
    /* max-width: 1400px; */
`;

const Profile = () => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    const [imagePreview, setImagePreview] = useState();

    useEffect(() => {
        dispatch(resetStat());
    }, [dispatch]);

    // console.log('PROFILE', profile);

    return (
        <WrapperRightSide>
            {/* <Header />
            <ChartLine /> */}
            <GeneralWrapper>
                <Header />

                <ChartLine />

                <ChartPie />

                <MessBtn to='/dashboard/messenger'>
                    <i className="fa-brands fa-facebook-messenger"></i>
                </MessBtn>
            </GeneralWrapper>

        </WrapperRightSide>
    );
}

export default Profile;