import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';


// PACKAGES
import styled from 'styled-components';

// COMPONENTS


// PAGE


// REDUX
import { useSelector, useDispatch } from 'react-redux';
//import { checkIfExists } from '../../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    Btn,
} from '../../../PagesShareStyle/PagesShareStyle';



const QuestWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background4);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    border-bottom: 1px solid #b5b3b340;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const QuestLink = styled(Link)`
    color: var(--text1);
    text-decoration: none;
`;

const Left = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
        >span {
            font-size: 12px;
            color: var(--text2);
        }
`;

const Sts = styled.div`
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 0 10px;
    min-width: 70px;
    height: 24px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)') ||
        (props.color === 'In attesa' && 'var(--background1)')
    };
`;


const OraleQuest = ({ que }) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    // const questDone = useSelector(({ subjects }) => subjects.ifExistDone);
    //console.log("QUE", que)
    const questDone = que.OralQuestionnaireDones


    // useEffect(() => {
    //     dispatch(checkIfExists(que.idQuestionnaire))
    // }, [dispatch]);

    // console.log('QUEST', que);
    // console.log('QUEST DONE', questDone);
    // console.log('QUEST PROFILE', questDone.idUser === profile.idUser);

    return (
        questDone !== undefined &&
        <QuestLink
            // to={{
            //     pathname: questDone === null ? '/dashboard/pre-quest' : '/dashboard/quest-verification',
            //     // state: { que }
            //     state: questDone === null ? { que } : { que: que.QuestionaryQuizzes, quest: que, asw: JSON.parse(questDone.quezzes) }
            // }}

            to={{
                pathname: questDone.length === 0 ? '/dashboard/prova-orale/pre-quest' : '/dashboard/quest-verification',
                //: '/dashboard/quest-verification',
                // state: { que }
                state: questDone.length === 0 ? { que } : { que: que.OralQuestionaryQuizzs, quest: que, asw: JSON.parse(questDone[0].quezzes) }
            }}
        >
            <QuestWrapper>
                <Left>
                    {que.name}
                </Left>


                <Right>
                    {/* <Sts color={(questDone !== null && questDone.point >= que.point) ? 'Completo' : 'In attesa'}>
                            {(questDone !== null && questDone.point >= que.point) ? 'Completo' : 'In attesa'}
                        </Sts> */}

                    <Sts color={(questDone.length > 0 && Number(questDone[0].point) >= Number(que.point)) ? 'Completo' : 'In attesa'}>
                        {(questDone.length > 0 && Number(questDone[0].point) >= Number(que.point)) ?
                            `Completo ${questDone[0].point}%` : 'In attesa'
                        }
                    </Sts>

                </Right>

            </QuestWrapper>
        </QuestLink >
    );
}

export default OraleQuest;