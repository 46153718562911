import { useEffect, useState } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
// import MainTpaVerificationPageView from './MainTpaVerificationPageView';
import MainTpaVerificationPageView from '../TPA/TestVerification/MainTpaVerificationPage';

// REDUX
import { useDispatch } from 'react-redux';

// STYLE
import {
    // TestWrapper,
} from '../PagesShareStyle/PagesShareStyle';


const TestVerificationPageView = ({ tests, setCorrect, setIncorrect, details }) => {
    const dispatch = useDispatch();
    const [id, setId] = useState(0);
    const [test, setTest] = useState();
    const [obj, setObj] = useState();

    useEffect(() => {
        if (details.elem !== undefined) {
            if (details.elem.questions !== null) {
                setTest(JSON.parse(details.elem.questions))
            }
            if (details.elem.answers !== null) {
                setObj(JSON.parse(details.elem.answers))
            } else {
                let createObj = [];
                const tst = JSON.parse(details.elem.questions)
                for (let i = 0; i < tst.length; i++) {
                    createObj.push({})
                }
                setObj(createObj);
            }
        }
    }, [details.elem]);

    // useEffect(() => {
    //     if (obj !== undefined) {
    //         let correct = 0;
    //         let incorrect = 0;

    //         for (let i = 0; i < obj.length; i++) {
    //             const elem1 = obj[i];
    //             const elem2 = test[i];

    //             if (elem1.answer_picked === elem2.answerOk) {
    //                 correct = correct + 1
    //             }

    //             if (elem1.answer_picked !== elem2.answerOk) {
    //                 incorrect = incorrect + 1
    //             }

    //             if (elem1.answer_picked === '' || elem2.answerOk === '') {
    //                 incorrect = incorrect + 1
    //             }
    //         }
    //         setCorrect(correct);
    //         setIncorrect(incorrect);
    //     };
    // }, [obj, setCorrect, setIncorrect]);

    const setAnswer = (e, i, letters) => {
        // console.log(i)
        const value = [...obj]
        value[id].asw = JSON.parse(test[id].answers)[e.target.id].ans;
        value[id].answer_picked = letters[i];
        value[id].aswIndex = i;
        setObj(value);

        //dispatch(sendTestAnswers(value, tests.idTestExercises));
    };

    // console.log('OBJ', obj);
    // console.log('TPE VIEW', details);

    return (
        test !== undefined &&
        <MainTpaVerificationPageView
            quest={test}
            done={obj}
            course={details.elem.idCourse}
            type={details.elem.type}
            idDone={details.elem.idTpaDone}
            doneAllObj={details.elem}
        />
    );
}

export default TestVerificationPageView;