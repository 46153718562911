import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';
import axios from 'axios';

import Vimeo from '@u-wave/react-vimeo';
// import YouTube, { YouTubeProps } from 'react-youtube';

// onst { getVideoDurationInSeconds } = require('get-video-duration')

// COMPONENTS
import Quest from './Quest/Quest';

// PAGE

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllQuestionnaires, videoIsDone } from '../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';
import Profile from '../Profile/Profile';

const TitleWrapper = styled.div`
    position: relative;
    /* display: flex; */
`;

const Title = styled.h3`
  /* width:100%; */
  color:var(--text2);
  display: flex;
  align-items: center;
  font-weight: bold;
  /* margin-top: 10px; */
`;

// const GoBack = styled.div`
//   color: var(--text3);
//   cursor: pointer;
//   font-size: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 10px;
//   background: var(--background4);
//   min-width: 100px;
//   border-radius: var(--radius);
//   box-shadow: var(--shadow);
//   padding: 10px;
//         &:hover{
//             color: var(--text2)
//         }
//         >i{
//             margin-right: 10px;
//         }
// `;

// const GoBack = styled.div`
//     position: relative;
//     width: 40px;
//     height: 40px;
//     border-radius: 40px;
//     color: var(--text3);
//     cursor: pointer;
//     font-size: 20px;
//     display: inline-flex;
//     align-items: center;
//     justify-content: flex-start;
//     margin-right: 10px;
//     background: var(--background4);
//     box-shadow: var(--shadow);
//     overflow: hidden;
//     transition:  0.2s linear;
//         >span{
//             white-space: nowrap;
//             padding-right: 15px;
//             visibility: hidden;
//             opacity: 0;
//             margin-left: 10px;
//         }
//         >i{
//             padding: 0 12px;
//         }
//         &:hover{
//             color: var(--text2);
//             width: 250px;
//                 >span{
//                     visibility: visible;
//                     opacity: 1;
//                     transition: opacity 0.5s linear;
//                 }
//         }
// `;

const VideoWrapper = styled.div`
    position: relative;
    /* width: 100%;
    height: calc(100vh - 300px); */
    margin-top: 20px;
    background: var(--background4);
    display: flex;
    align-items: center;
    justify-content: center;
    /* .video{
        width: 100%;
    } */
    /* >video{
        width: 100%;
    } */
`;

const InfoWrapper = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const Info = styled.div`
    margin-top: 20px;
    width: 100%;
    font-size: var(--fontL);
    color: var(--text2);
`;

const Header = styled.div`
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Sts = styled.div`
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 2px 10px;
    /* min-width: 70px; */
    height: 24px;
    /* margin-right: 10px; */
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)') ||
        (props.color === 'In attesa' && 'var(--background1)')
    }; */
    background-color: ${props =>
        (props.color === 'Videolezione completata' && 'var(--success)') ||
        (props.color === 'Videolezione non completata' && 'var(--text2)')
    };
`;


const LessonPage = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const quest = useSelector(({ subjects }) => subjects.allQuestionnaires);
    const [quests, setQuests] = useState([]);
    const props = details.less.Lessons_idLesson_Lesson;
    const [videoTime, setVideoTime] = useState();

    useEffect(() => {
        const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
        dispatch(getAllQuestionnaires(details.less.Lessons_idLesson, idUser));
    }, [dispatch]);

    const endVideo = () => {
        dispatch(videoIsDone(
            details.less.Lessons_idLesson,
            details.less.Subjects_idSubject,
            details.course.idCourse,
            videoTime
        ));
        // console.log('end');
    };

    const custom = {
        width: '100%',
        maxWidth: '2000px'
    };

    const capitalizeFirstLetter = (str) => {
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    useEffect(() => {
        if (quest !== undefined && quest.length > 0) {
            let newQue = [];
            for (let i = 0; i < quest.length; i++) {
                if (quest[i].excluded === null) {
                    // setQuests(quest);
                    newQue.push(quest[i]);
                } else {
                    const findIfExists = JSON.parse(quest[i].excluded).find(id => id === details.course.idCourse);
                    if (findIfExists) {
                        newQue.push(quest[i]);
                    }
                    // setQuests(newQue);
                }
            }
            setQuests(newQue);
        }
    }, [quest]);

    // if (ret !== undefined) {
    //     for (let i = 0; i < ret.length; i++) {
    //         const prop = ret[i].lessons;
    //         for (let j = 0; j < prop.length; j++) {
    //             if (prop[j].Lessons_idLesson_Lesson.excluded === null) {
    //                 setMats(ret);
    //             } else {
    //                 let newProp = [];
    //                 const findIfExists = JSON.parse(prop[j].Lessons_idLesson_Lesson.excluded).find(id => id === course.idCourse);
    //                 if (!findIfExists) {
    //                     newProp.push(ret[i]);
    //                 }
    //                 setMats(newProp);
    //             }
    //         }
    //     }
    // }

    // console.log("QUEST LESSON", quest);
    // console.log('DETAILS LESSON', details);
    // console.log("PROPS", videoTime);

    return (
        <>
            <TitleWrapper>
                {/* <TitleWrapper >
                    <GoBack onClick={() => history.goBack()}>
                        <i className="fas fa-arrow-left"></i>
                        <span>Torna alla Materia</span>
                    </GoBack>
                </TitleWrapper> */}

                <Title>{props.lesson_name}</Title>

                {props.LessonCompletes.length > 0 ?
                    <Sts color={'Videolezione completata'}>{'Videolezione completata'}</Sts>
                    :
                    props.ondemand !== null ?
                        <Sts color={'Videolezione non completata'}>{'Videolezione non completata'}</Sts>
                        :
                        null
                }


                {details.studDetails !== undefined &&
                    <>
                        {capitalizeFirstLetter(details.studDetails.user_role)}: {details.studDetails.user_name} {details.studDetails.user_surname}
                    </>
                }
            </TitleWrapper>

            <VideoWrapper >
                <Vimeo
                    // video="https://player.vimeo.com/video/748050997"
                    video={props.ondemand}
                    style={custom}
                    onEnd={endVideo}
                    autoplay={false}
                    responsive={true}
                    sbackground={true}
                    onPause={(e) => console.log('PROPS', e)}
                    // onPause={(e) => console.log('PROPS', e)}
                    onProgress={(e) => setVideoTime(e.duration)}
                />
            </VideoWrapper>

            <InfoWrapper>
                <Title>{props.title}</Title>
                <Info>{props.lesson_body}</Info>
            </InfoWrapper>

            {/* <TitleWrapper style={{ marginTop: '20px' }}>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left"></i>
                    Torna alla Materia
                </GoBack>
            </TitleWrapper> */}

            {quests.length > 0 &&
                <>
                    <Header>Verifiche
                        <Sts
                            style={{ right: '10px' }}
                            color={details.less.sts}
                        >
                            {details.less.sts}
                        </Sts>
                    </Header>

                    {/* {details.less !== undefined &&
                details.less.quests.map((que, i) => */}
                    {quests !== undefined &&
                        quests.map((que, i) =>
                            <div key={i}>
                                <Quest
                                    que={que}
                                    course={details.course}
                                />
                            </div>
                        )}
                </>
            }
        </>
    );
}

export default LessonPage;


// useEffect(() => {
//     let WaitForIFrame = () => {
//         if (iframe.readyState != "complete") {
//             setTimeout("WaitForIFrame();", 200);
//         } else {
//             done();
//         }
//     };

//     function done() {
//         console.log('WE ARE DONE!')
//     }
//     console.log('AM INTRAT')
//     const iframe = document.getElementById("frame1")
//     // document.addEventListener("mousedown", handler, true);
//     // return () => {
//     //   document.removeEventListener("mousedown", handler, true);
//     // };
// }, []);

// let checkVideo = () => {
//     let WaitForIFrame = () => {
//         if (iframe.readyState != "complete") {
//             // setTimeout("WaitForIFrame();", 200);
//             console.log('LOADING')
//         } else {
//             done();
//         }
//     };

//     function done() {
//         console.log('WE ARE DONE!')
//     }
//     console.log('AM INTRAT')
//     const iframe = document.getElementById("iframe1")
//     // document.addEventListener("mousedown", handler, true);
//     // return () => {
//     //   document.removeEventListener("mousedown", handler, true);
//     // };
// };

// function onYouTubeIframeAPIReady() {

//     var player;
//     player = new YT.Player('muteYouTubeVideoPlayer', {
//       videoId: 'tgbNymZ7vqY', // YouTube Video ID
//       width: 560, // Player width (in px)
//       height: 316, // Player height (in px)
//       playerVars: {
//         autoplay: 1, // Auto-play the video on load
//         controls: 1, // Show pause/play buttons in player
//         showinfo: 0, // Hide the video title
//         modestbranding: 1, // Hide the Youtube Logo
//         loop: 1, // Run the video in a loop
//         fs: 0, // Hide the full screen button
//         cc_load_policy: 0, // Hide closed captions
//         iv_load_policy: 3, // Hide the Video Annotations
//         autohide: 0, // Hide video controls when playing
//       },
//       events: {
//         onReady: function (e) {
//           e.target.mute();
//         },
//       },
//     });
//   }

// let checkVideo = (e) => {
//     const YT = 'https://developers.google.com/youtube/js_api_reference'
//     const iframe = document.getElementById("iframe1");

//     function done() {
//                 console.log('WE ARE DONE!')
//             }
//     console.log('AM INTRAT2', iframe.onended)

//     // iframe.addEventListener("onended", done);
//     function onYouTubeIframeAPIReady() {
//         //creates the player object
//         iframe = new YT.Player('iframe1');

//         console.log('Video API is loaded');

//         //subscribe to events
//         iframe.addEventListener("onReady", "onYouTubePlayerReady");
//         iframe.addEventListener("onStateChange", "onYouTubePlayerStateChange");
//       }

//       function onYouTubePlayerReady() {
//         console.log('Video is ready to play');
//       }
// };


// useEffect(() => {
//     // const YT = 'https://www.googleapis.com/youtube/v3';
//     const fetcFct = async () => {
//         axios
//             .post(`https://www.youtube.com/iframe_api`)
//             .then((res) => {
//                 console.log('RES', res.data)
//             })
//             .catch((err) => {
//                 console.log('ERR', err)
//             });
//     }
//     fetcFct()
// }, []);

// async function loadVideo() {
//     window.YT.ready(function () {
//         new window.YT.Player("video", {
//             height: "390",
//             width: "640",
//             videoId: "M7lc1UVf-VE",
//             events: {
//                 onReady: onPlayerReady,
//                 onStateChange: onPlayerStateChange
//             }
//         });
//     });

//     function onPlayerReady(event) {
//         event.target.playVideo();
//     }

//     function onPlayerStateChange(event) {
//         var videoStatuses = Object.entries(window.YT.PlayerState);
//         console.log(videoStatuses.find(status => status[1] === event.data)[0]);
//     }
// }

//  useEffect(() => {
//     // const YT = 'https://www.googleapis.com/youtube/v3';
//     const fetcFct = async () => {
//         axios
//             .post(`https://www.youtube.com/iframe_api`)
//             .then((res) => {
//                 console.log('RES', res.data)
//             })
//             .catch((err) => {
//                 console.log('ERR', err)
//             });
//     }
//     fetcFct()
// }, []);

// const loadVideo = () => {

//     const fetcFct = async () => {
//         const config = {
//             "Access-Control-Allow-Origin": '*',
//         };

//         axios
//             .post(`https://player.vimeo.com/api/player.js`, config)
//             .then((res) => {
//                 console.log('RES', res.data)
//             })
//             .catch((err) => {
//                 console.log('ERR', err)
//             });
//     }
//     fetcFct();
//     // const videoIframe = findDOMNode(this.refs.videoStage).querySelector('iframe');
//     const videoIframe = document.getElementById("iframe1");

//     // if (videoIframe) {
//     //   console.log(videoIframe);

//     //   const player = new VimeoPlayer(videoIframe);

//     //   player.ready().then(() => {
//     //     player.on('play', data => console.log(data));
//     //     player.on('ended', data => console.log(data));
//     //     console.log(player);
//     //     player.play();
//     //   });
//     // }
// }
