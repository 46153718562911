import { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { createSimulation, saveSimulation, resetSimulation } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../PagesShareStyle/PagesShareStyle';


const PreSimulation = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const simulation = useSelector(({ test }) => test.simulation);
    const savedSim = useSelector(({ test }) => test.savedSimulation);
    const [error, setError] = useState(false);
    const [errorNoSim, setErrorNoSim] = useState(false);

    // console.log('SIMULATION', simulation);
    // console.log('QUE',simulation !== undefined &&JSON.parse(simulation.questions_numb))
    // console.log('savedSim',savedSim);

    useEffect(() => {
        dispatch(createSimulation(details.course.idCourse, details.choose));
    }, [dispatch]);

    useEffect(() => {
        if (simulation !== undefined) {
            if (simulation === 'error') {
                setError(!error);
                setTimeout(() => {
                    setError(!error);
                    history.goBack();
                }, 3000)
            } else if (simulation.questions.length === 0) {
                setErrorNoSim(!errorNoSim);
                setTimeout(() => {
                    setErrorNoSim(!errorNoSim);
                    history.goBack();
                }, 3000);
                dispatch(resetSimulation());
            } else {
                dispatch(saveSimulation(simulation, details.course, details.flag));
            }
        }
    }, [simulation])

    useEffect(() => {
        if (savedSim !== undefined) {
            // history.push('/dashboard/create-quiz/test', savedSim);

            setTimeout(() => {
                const url = '/dashboard/create-quiz/simulation/' + savedSim.idSimulations;
                history.push({
                    pathname: url,
                    state: details.course
                });

                // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                // if (newWindow) newWindow.opener = null;
                // dispatch(resetSimulation());
                // history.goBack();
            }, 2000)
        }
    }, [savedSim]);

    return (
        error || errorNoSim ?
            <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
                <i className="fa-solid fa-triangle-exclamation"></i>
                {error && <div>Si è verificato un errore. Per favore, riprova</div>}
                {errorNoSim && <div>Non ci sono simulazioni per questo corso</div>}
            </WrapperRightSide>
            :
            <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
                <img src={Loading} alt="Loading..."></img>
                <div>Generazione simulazione...</div>
            </WrapperRightSide>
    );
}

export default PreSimulation;