import React from "react";

// PACKAGES
import styled from 'styled-components';

const ContainerStyles = styled.div`
    display:flex;
    align-items: center;
    min-width: 100px;
`;

const ContainerFiller = styled.div`
    height: 10px;
    width: 100%;
    background: ${props => props.total === 'total' ? 'var(--color2)' : 'var(--background3)'};
    border-radius: 50px;
    padding: 3px;
`;

const FillerStyles = styled.div`
    position:relative;
    height: 100%;
    transition: width 1s ease-in-out;
    width: ${(props) => (props.width === 0 ? "3" : props.width)}%;
    background-color: ${props => (props.width <= 40 && 'var(--err)') ||
        (props.width > 40 && props.width <= 99 && 'var(--warning)') ||
        (props.width >= 100 && 'var(--success)')
    };
    color: var(--text4);
    border-radius: inherit;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const LabelWrapper = styled.span`
    width: 50px;
    position: relative;
    margin-right: 10px;
`;

const LabelStyles = styled.span`
    color: var(--text3);
    font-size: 14px;
    font-weight: bold;
`;


const ProgressBarChart = ({ count, flag, lessonsTotal, lessonsCompletedTotal, total }) => {

    return (
        <ContainerStyles >
            <LabelWrapper>
                {/* <LabelStyles >Completato {lessonsCompletedTotal} materia  su {lessonsTotal}</LabelStyles> */}
                <LabelStyles total={total}>{total}%</LabelStyles>
            </LabelWrapper>

            <ContainerFiller total={total}>
                <FillerStyles switch={flag} color={'var(--success)'} width={total > 100 ? 100 : total}></FillerStyles>
            </ContainerFiller>
        </ContainerStyles>
    );
};

export default ProgressBarChart;
