import React from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

// const GoBack = styled.div`
//   color:var(--text3);
//   cursor:pointer;
//   font-size: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right:10px;
//   background: var(--background4);
//   width: 100px;
//   height: 35px;
//   border-radius: var(--radius);
//   box-shadow: var(--shadow);
//         &:hover{
//             color: var(--text2)
//         }
// `;

const GoBack = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: var(--text3);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: var(--background4);
    box-shadow: var(--shadow);
        &:hover{
            color: var(--text2)
        }
`;

const Title = styled.h3`
  width:100%;
  color:var(--text2);
  display: flex;
  align-items: center;
`;


const BackButton = (props) => {
  let history = useHistory();

  return (
    <TitleWrapper>
      <GoBack onClick={() => history.goBack()}><i className="fas fa-arrow-left"></i></GoBack>
      <Title>{props.title}</Title>
    </TitleWrapper>
  )
};

export default BackButton;