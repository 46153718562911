import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import CourseCard from '../../GeneralComponents/Cards/CourseCard';

// PAGE
import MainTestPage from '../TestPage/MainTestPage';
import LearningPage from '../LearningPage/LearningPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllCourses } from '../../../Store/Actions/coursesActions';

// STYLE
import {
    WrapperRightSide,
} from '../PagesShareStyle/PagesShareStyle';

const Title = styled.h3`
    width:100%;
    color:var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(240,242,245);
    background: linear-gradient(90deg, rgba(240,242,245,1) 0%, rgba(119,123,141,0.9500175070028011) 30%, rgba(108,113,132,1) 50%, rgba(96,101,122,0.8855917366946778) 70%, rgba(240,242,245,1) 100%);
    /* background: rgb(240,242,245);
    background: linear-gradient(90deg, rgba(240,242,245,1) 0%, rgba(211,32,88,1) 30%, rgba(211,32,88,1) 50%, rgba(211,32,88,1) 70%, rgba(240,242,245,1) 100%);
    background: rgb(240,242,245);
    background: linear-gradient(90deg, rgba(240,242,245,1) 0%, rgba(39,55,143,1) 30%, rgba(39,55,143,1) 50%, rgba(39,55,143,1) 70%, rgba(240,242,245,1) 100%); */
`;

const CardsWrapper = styled.div`
    width:100%;
    padding: 30px 0;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: left;
        &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 3000px) {
            grid-template-columns: repeat(5,auto);
        }
        @media (max-width: 2500px) {
            grid-template-columns: repeat(4,auto);
        }
        @media (max-width: 2100px) {
            grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 1600px) {
            grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 1150px) {
            grid-template-columns: repeat(1,auto);
            justify-content: space-around;
        }
        /* @media (max-width: 500px) {
            grid-template-columns: repeat(1,auto);
        } */
`;


const MainPage = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector(({ auth }) => auth.user);
    // const courses = useSelector(({ courses }) => courses.allCourses);
    // const [coursesCat, setCoursesCat] = useState();
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        if (user !== undefined) {
            setCourses(user.follow);
        }
    }, [user]);

    useEffect(() => {
        dispatch(getAllCourses());
    }, [dispatch]);

    // useEffect(() => {
    //     if (courses !== undefined) {
    //         let arr = [];
    //         const arrCategory = courses.forEach(element => arr.push(element.administration));
    //         const elimSubCat = [...new Set(arr)];
    //         setCoursesCat(elimSubCat);
    //     }
    // }, [courses]);

    const coursesCat = [
        'Scuole Militari',
        'Live Streaming',
        'Nissolino',
        'Esercito Italiano',
        'Marina Militare',
        'Aeronautica Militare',
        'Arma dei Carabinieri',
        'Polizia di Stato',
        'Guardia di Finanza',
        'Polizia Penitenziaria',
        'Vigili del Fuoco',
        'Altri corsi di preparazione',
        'Nissolino School'
    ];

    //console.log('COURSES', user);
    //console.log('USER', user);
    //console.log("COURSE CAT", coursesCat);

    return (
        <WrapperRightSide>
            {coursesCat !== undefined &&
                coursesCat.map((cat, i) =>
                    <div key={i}>
                        {courses.filter((course) => course.administration === cat).length > 0 &&
                            <Title
                                style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                            >
                                {cat}
                            </Title>
                        }

                        {courses.filter((course) => course.administration === cat).length > 0 &&
                            <CardsWrapper>
                                {courses.filter((course) => course.administration === cat)
                                    .map((crs, j) =>
                                        <CourseCard
                                            key={j}
                                            course={crs}
                                        />
                                    )
                                }
                            </CardsWrapper>
                        }
                    </div>
                )
            }
        </WrapperRightSide>
    );
}

export default MainPage;
