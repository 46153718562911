import React from 'react';

// PACKAGES
import styled from 'styled-components';

// STYLE
import {
    Wrapper,
    CommentWrapper,
    GradeWrapper,
    LabelCss,
    QueContainer,
    ResContainer
} from './Style';


const VerificationTestScaletta = ({ details }) => {
    console.log('TEMA SCALETTA VIEW', details);

    return (
        <Wrapper>
            <QueContainer>
                {details.elem.idTraceDone_TraceAnalysisDone.idTraceAnalysis_TraceAnalysis.desc}
            </QueContainer>
            <LabelCss htmlFor="asw1">Introduzione</LabelCss>
            <ResContainer>
                {JSON.parse(details.elem.introduction).map((int, i) =>
                    <div key={i}>
                        <span>{int.intro}</span>
                        <span>-</span>
                        <span>{int.subIntro}</span>
                    </div>
                )}
            </ResContainer>

            <LabelCss htmlFor="asw2">Corpo del testo (parole chiave)</LabelCss>
            <ResContainer>
                {JSON.parse(details.elem.body).map((md, i) =>
                    <div key={i}>
                        <span>{md.midd}</span>
                        <span>-</span>
                        <span>{md.subMidd}</span>
                    </div>
                )}
            </ResContainer>

            <LabelCss htmlFor="asw2">Conclusione</LabelCss>
            <ResContainer>
                {JSON.parse(details.elem.end).map((con, i) =>
                    <div key={i}>
                        <span>{con.conc}</span>
                        <span>-</span>
                        <span>{con.subConc}</span>
                    </div>
                )}
            </ResContainer>
        </Wrapper>
    );
}

export default VerificationTestScaletta;