import { useState, useEffect } from 'react';

// PACKEGES
import { io } from 'socket.io-client';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { createSocket, setOnlineUsers, findAllUnreadMessages } from '../../../Store/Actions/conversationsActions';
import { globalNotif } from '../../../Store/Actions/otherActions';

export default function useSocket(user) {
    const dispatch = useDispatch();
    const unreadMess = useSelector(({ conversations }) => conversations.unreadMess);
    const socket = useSelector(({ conversations }) => conversations.socket);

    useEffect(() => {
        dispatch(createSocket(io(process.env.REACT_APP_URL_SOCKET)));
        dispatch(findAllUnreadMessages(user.idUser));
        //	dispatch(createSocket(io('https://nissolinocorsi.com:449')));
    }, [dispatch]);

    useEffect(() => {
        socket?.emit('addUser', user?.idUser)
        socket?.on('getOnlineUsers', users => {
            // console.log('ONLINE USERS', users);
            dispatch(setOnlineUsers(users));
        })
        socket?.on('getMessages', data => {
            dispatch(globalNotif(true));
        })
    }, [socket]);

    useEffect(() => {
        if (unreadMess !== undefined) {
            const find = unreadMess?.filter(un => un.SenderID !== user.idUser.toString());
            if (find.length > 0) {
                dispatch(globalNotif(true));
            }
        }
    }, [unreadMess]);

    return;
};