import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector } from 'react-redux';

const PopUp = styled.div`
    position:absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 10px;
    background: #6f6f6f73;
    color: var(--text5);
    /* text-align: center; */
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(8px);
    transition: var(--transition);
    /* white-space: nowrap; */
`;

const CardWrapper = styled.div`
    position: relative;
    width: 200px;
    height: 180px;
    background-color: var(--background4);
    margin: 10px 0px;
    padding: 10px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
`;

const TitleCard = styled.div`
    position: absolute;
    top: 10px;
    width: 130px;
    color:  var(--text2);
    font-size: 16px;
    text-align: center;
        &:hover ${PopUp} {
            opacity: 1;
            visibility: visible;
        }
`;

const Total = styled.div`
    width: 100%;
    color:  var(--text3);
    font-size: 14px;
    text-align: center;
    
`;

const Nav = styled.div`
    position: absolute;
    bottom: 10px;
    color:  var(--text2);
    display: flex;
        >span{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: var(--text4);
            cursor: default;
        }
`;

const Btn = styled.div`
    padding: 0px 5px;
    border:none;
    outline: none;
    color: ${props => props.active === 'color' ? 'var(--acc-color)' : 'var(--text3)'};
    font-weight: ${props => props.active === 'color' && 'bold'};;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    cursor: pointer;
        &:hover{
            transition: .2s ease-in-out;
            color:  var(--acc-color);
            font-weight: bold;
        }
        &:active{
                transform:translateY(2px);
        }  
`;

const InputCss = styled.input`
    width: 100%;
    height: 60px;
    /* padding: 6px 12px; */
    border: 1px solid var(--color6);
    border-radius: var(--radius);
    background: var(--color7);
    color: var(--text1);
    transition: var(--transition) ease;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    /* box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important; */
        &::placeholder { 
            color: var(--text2);
            font-size: 50px;
            font-weight: bold;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline: none;
            ${CardWrapper} {
                box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
            }
            /* caret-color: rgba(0,0,0,0); */
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
`;

const Choose = styled.div`
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
        >i{
            color: var(--text3);
        }
`;

const DialogBox = styled.div`
    position: absolute;
    top: 20px;
    right: -10px;
    width: 190px;
    min-height: 160px;
    max-height: 160px;
    padding: 10px 10px;
    overflow: auto;
    z-index: 1;
    text-align: left;
    color: var(--text2);
    border-radius: var(--radius);
    /* background: var(--background4); */
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    background: #5f678293;
    backdrop-filter: blur(10px);
        >h5{
            color: var(--text5);
            padding: 5px;
            &:hover{
                background: var(--background3);
                border-radius: var(--radius);
            }
        }
`;


const QuizCardWrong = ({ mat, addParam, index, arr }) => {
    const profile = useSelector((state) => state.auth.user);
    const [access, setAccess] = useState({
        nr: '',
        // difficulty: '',
        // idDifficulty: '',
        materia: mat.subject_name,
        subjectId: mat.idSubject,
        index: index,
        // option: '',
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (arr.length > 0) {
            setAccess({
                ...access,
                nr: Object.keys(arr[index]).length > 0 ? arr[index].nr : '',
                // difficulty: Object.keys(arr[index]).length > 0 ? arr[index].difficulty : '',
                // idDifficulty: Object.keys(arr[index]).length > 0 ? arr[index].idDifficulty : '',
                materia: mat.subject_name,
                index: index,
                // option: Object.keys(arr[index]).length > 0 ? arr[index].option : '',
            })
        }
    }, [arr]);

    const setDif = (props) => {
        // console.log(props.e.target.max, props.option, props.max);
        const max = props.e.target.max * 1

        // console.log(props.nr);

        setAccess({
            ...access,
            nr: (max < props.nr * 1) ?
                max
                :
                props.nr,
            // nr: (props.nr !== undefined) ? props.nr : access.nr,
            // nr: (props.nr !== undefined) ?
            //     props.nr
            //     :
            //     (max !== undefined && max < access.nr * 1) ?
            //         max
            //         :
            //         access.nr,
            // difficulty: (props.sts !== undefined) ? props.sts : '',
            // idDifficulty: (props.e.currentTarget.id !== undefined) ? props.e.currentTarget.id : '',
            // option: (props.option !== undefined) ? props.option : '',
        });

        addParam({
            ...access,
            nr: (max < props.nr * 1) ?
                max
                :
                props.nr,
            // nr: (props.nr !== undefined) ? props.nr : access.nr,
            // nr: (props.nr !== undefined) ?
            //     props.nr
            //     :
            //     (max !== undefined && max < access.nr * 1) ?
            //         max
            //         :
            //         access.nr,
            // difficulty: (props.sts !== undefined) ? props.sts : '',
            // idDifficulty: (props.e.currentTarget.id !== undefined) ? props.e.currentTarget.id : '',
            // option: (props.option !== undefined) ? props.option : '',
        });
        setOpen(false);
    };

    // console.log('waaaa', mat);

    return (
        <CardWrapper>
            <TitleCard>
                {mat.subject_name.length > 16 ?
                    mat.subject_name.slice(0, 16) + '...'
                    :
                    mat.subject_name
                }
                {mat.subject_name.length > 16 &&
                    <PopUp>{mat.subject_name}</PopUp>
                }
            </TitleCard>

            <InputCss
                placeholder="0"
                id='nr'
                type='number'
                name='nr'
                required
                value={access.nr}
                autoComplete='off'
                min='1'
                max={mat.wrongQuestions.length}
                // valueType="int"
                onChange={(e) => setDif({ e, nr: e.target.value })}
            ></InputCss>

            <Total>Totale {mat.wrongQuestions.length} domande</Total>

        </CardWrapper >
    );
};

export default QuizCardWrong;