import { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, Prompt } from "react-router-dom";

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../GeneralComponents/AllQuestions/AllQuestions';
import FooterBar from '../../../GeneralComponents/TestViewsBars/FooterBar';
import Question from './Question';
import AlertModal from '../../../GeneralComponents/Modals/AlertModal';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { sendTestAnswers, sendSimulationAnswers, sendTestAnswersForWrong, deleteTest } from '../../../../Store/Actions/testActions';
import { sendQuestAnswers } from '../../../../Store/Actions/subjectsActions';
import { sendOralQuestAnswers } from '../../../../Store/Actions/oraleActions';
import { sendPhysicalQuestAnswers } from '../../../../Store/Actions/physicalActions';
import { sendTemaQuestAnswers } from '../../../../Store/Actions/temaActions';

// STYLE
import {
    // WrapperRightSide,
    // Title,
    TestWrapper,
    TestWrapperV,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../PagesShareStyle/PagesShareStyle';


const TestPage = ({
    tests,
    testID, setFinishTheTest,
    setRest,
    rest,
    flag,
    course,
    setObjForTimer,
    params,
    details }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const finishTime = useSelector(({ other }) => other.finishTime);
    const [id, setId] = useState(0);
    const [obj, setObj] = useState([]);
    const [openAlerModal, setOpenAlerModal] = useState(false);
    const [choose, setChoose] = useState(false);
    const [blockNav, setBlockNav] = useState(true);
    const [confirmedNav, setConfirmedNav] = useState(false);
    const [loc, setLoc] = useState();

    useEffect(() => {
        let createObj = [];
        for (let i = 0; i < tests.length; i++) {
            createObj.push({})
        }
        setObj(createObj);
    }, [tests]);

    const setAnswer = (e, i, letters) => {
        // console.log(i)
        const value = [...obj]
        value[id].asw = JSON.parse(tests[id].answers)[e.target.id].ans;
        value[id].answer_picked = letters[i];
        value[id].aswIndex = i;
        setObj(value);

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);
        // console.log(res);

        if (flag === 'simulation') {
            setObjForTimer(value);
        }
    };

    let reallyFinished = false;
    const submitTest = () => {
        if (flag === 'simulation') {
            reallyFinished = true;
            dispatch(sendSimulationAnswers(obj, testID, finishTime, reallyFinished));
        } else if (flag === 'wrong') {
            dispatch(sendTestAnswersForWrong(obj, testID, tests, course.idCourse));
        } else if (flag === 'quest') {
            dispatch(sendQuestAnswers(obj, testID));
        } else if (flag === 'quest-oral') {
            dispatch(sendOralQuestAnswers(obj, testID));
        } else if (flag === 'quest-phy') {
            dispatch(sendPhysicalQuestAnswers(obj, testID));
        } else if (flag === 'quest-tema') {
            dispatch(sendTemaQuestAnswers(obj, testID));
        } else {
            dispatch(sendTestAnswers(obj, testID));
        }
        // if (flag !== 'wrong' || flag === 'quest' || flag === 'quest-oral') {
        if (flag !== 'wrong' &&
            flag !== 'quest' &&
            flag !== 'quest-oral' &&
            flag !== 'quest-phy' &&
            flag !== 'quest-tema') {
            setFinishTheTest(true);
            reallyFinished = true;

            setBlockNav(false);
            setTimeout(() => {
                // window.close();
                setFinishTheTest(false);
                history.replace({
                    pathname: '/dashboard/exercises',
                    state: { course: details, title: 'Prova Preselettiva', flag: 'submit' },
                    // replace: true
                });
            }, 5000);
        }
        if (flag === 'wrong') {
            setFinishTheTest(true);
            setTimeout(() => {
                // history.push('/dashboard/exercises');
                // history.goBack();
                // history.replace({
                //     pathname: '/dashboard/exercises',
                //     state: { course, title:course.course_name}

                // });
                history.go(-3);
            }, 5000);
        }
        if (flag === 'quest' ||
            flag === 'quest-oral' ||
            flag === 'quest-tema' ||
            flag === 'quest-phy') {
            setFinishTheTest(true);
            setTimeout(() => {
                history.go(-2);
            }, 5000);
        }
    };

    const onExit = useCallback(() => {
        if (flag === 'simulation') {
            dispatch(sendSimulationAnswers(obj, testID, finishTime, reallyFinished));
        }
    }, [])

    useEffect(() => {
        // const alertUser = event => {
        //     console.log(event);
        //     event.preventDefault()
        //     event.returnValue = 'Attenzione, se premi Ok tutte le modifiche andranno perse!'
        //     return (event.returnValue)
        // };
        // window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', onExit)
        return () => {
            // window.removeEventListener('beforeunload', alertUser)
            window.addEventListener('unload', onExit)
        };
    }, []);

    const handleBlockedNavigation = (nextLocation, action) => {
        console.log(nextLocation);
        const confirmedNavigation = confirmedNav;
        if (!confirmedNavigation && nextLocation) {
            setOpenAlerModal(true);
            setLoc(nextLocation);
            return false
        } else {
            setOpenAlerModal(false);
            setLoc(undefined);
            setConfirmedNav(false);
            setTimeout(() => {
                dispatch(deleteTest(params, flag));
                return true
            }, 250);
        }
    };

    const setActions = (e) => {
        setChoose(e);
        if (e) {
            setConfirmedNav(true);
            setTimeout(() => {
                handleBlockedNavigation(loc);
                history.push(loc);
            }, 250);
        }
    };

    // console.log('OBJ', obj);
    // console.log('TEST', tests);
    // console.log('TIME',finishTime);
    // console.log('openAlerModal', openAlerModal);
    // console.log('choose', choose);
    // console.log('flag', flag);
    // console.log('confirmedNav', confirmedNav);
    // console.log('loc', loc);
    // console.log(params)

    return (
        <>
            <TestWrapperV>
                <AllQuestionsSideBar
                    id={id}
                    setId={setId}
                    // difficulty={tests[id].difficulty}
                    difficulty={tests[id].which_file}
                    arr={tests}
                    obj={obj}
                />

                {tests !== undefined &&
                    <Question
                        tests={tests}
                        id={id}
                        setAnswers={setAnswer}
                        obj={obj}
                    />
                }
            </TestWrapperV>

            <FooterBar
                id={id}
                setId={setId}
                tests={tests}
                submitTest={submitTest}
                rest={rest}
                flag={flag}
            />

            {flag === 'simulation' &&
                blockNav === true &&
                < Prompt
                    when={true}
                    message={handleBlockedNavigation}
                />
            }

            {openAlerModal &&
                <AlertModal
                    title={'Attenzione'}
                    setOpenAlerModal={setOpenAlerModal}
                    setAction={setActions}
                />
            }
        </>
    );
}

export default TestPage;


// import { useEffect, useState, useCallback, useRef } from 'react';
// import { useHistory, useLocation, Prompt } from "react-router-dom";

// // ASSETS
// // import pic from '../../../Assets/Capture.PNG';

// // PACKAGES
// // import styled from 'styled-components';
// //import { useBeforeunload } from 'react-beforeunload';

// // COMPONENTS
// import AllQuestionsSideBar from '../../../GeneralComponents/AllQuestions/AllQuestions';
// import FooterBar from '../../../GeneralComponents/TestViewsBars/FooterBar';
// import Question from './Question';
// import AlertModal from '../../../GeneralComponents/Modals/AlertModal';


// // REDUX
// import { useSelector, useDispatch } from 'react-redux';
// import { sendTestAnswers, sendSimulationAnswers, sendTestAnswersForWrong, deleteTest } from '../../../../Store/Actions/testActions';
// import { sendQuestAnswers } from '../../../../Store/Actions/subjectsActions';
// import { sendOralQuestAnswers } from '../../../../Store/Actions/oraleActions';

// // STYLE
// import {
//     // WrapperRightSide,
//     // Title,
//     TestWrapper,
//     // ActionsWrapper,
//     // BtnWrapper,
//     // Btn,
// } from '../../PagesShareStyle/PagesShareStyle';

// // const useUnload = fn => {
// //     const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

// //     useEffect(() => {
// //         cb.current = fn;
// //     }, [fn]);

// //     useEffect(() => {
// //         const onUnload = (...args) => cb.current?.(...args);

// //         window.addEventListener("beforeunload", onUnload);

// //         return () => window.removeEventListener("beforeunload", onUnload);
// //     }, []);
// // };

// const TestPage = ({ tests, testID, setFinishTheTest, setRest, rest, flag, course, setObjForTimer, params }) => {
//     const dispatch = useDispatch();
//     const history = useHistory();
//     const location = useLocation();
//     const finishTime = useSelector(({ other }) => other.finishTime);
//     const [id, setId] = useState(0);
//     const [obj, setObj] = useState([]);
//     const [openAlerModal, setOpenAlerModal] = useState(false);
//     const [choose, setChoose] = useState(false);
//     // const [theChange, setTheChange] = useState(false);

//     console.log("COURSE", tests)
//     useEffect(() => {
//         let createObj = [];
//         for (let i = 0; i < tests.length; i++) {
//             createObj.push({})
//         }
//         setObj(createObj);
//     }, [tests]);

//     const setAnswer = (e, i, letters) => {
//         // console.log(i)
//         const value = [...obj]
//         value[id].asw = JSON.parse(tests[id].answers)[e.target.id].ans;
//         value[id].answer_picked = letters[i];
//         value[id].aswIndex = i;
//         setObj(value);

//         let res = 0;
//         for (let j = 0; j < value.length; j++) {
//             if (Object.keys(value[j]).length > 0) {
//                 res += 1
//             }
//         }
//         setRest(res);
//         // console.log(res);

//         if (flag === 'simulation') {
//             setObjForTimer(value);
//         }
//     };

//     let reallyFinished = false;
//     const submitTest = () => {
//         if (flag === 'simulation') {
//             reallyFinished = true;
//             dispatch(sendSimulationAnswers(obj, testID, finishTime, reallyFinished));
//         } else if (flag === 'wrong') {
//             dispatch(sendTestAnswersForWrong(obj, testID, tests, course.idCourse));
//         } else if (flag === 'quest') {
//             dispatch(sendQuestAnswers(obj, testID));
//         } else if (flag === 'quest-oral') {
//             dispatch(sendOralQuestAnswers(obj, testID));
//         } else {
//             dispatch(sendTestAnswers(obj, testID));
//         }
//         if (flag !== 'wrong' || flag === 'quest' || flag === 'quest-oral') {
//             setFinishTheTest(true);
//             reallyFinished = true;
//             setTimeout(() => {
//                 window.close();
//             }, 5000);

//         }
//         if (flag === 'wrong' || flag === 'quest' || flag === 'quest-oral') {
//             setFinishTheTest(true);
//             setTimeout(() => {
//                 // history.push('/dashboard/exercises');
//                 // history.goBack();
//                 // history.replace({
//                 //     pathname: '/dashboard/exercises',
//                 //     state: { course, title: course.course_name }

//                 // });
//                 history.go(-3);
//             }, 5000);
//         }
//     };

//     const onExit = useCallback(() => {
//         if (flag === 'simulation') {
//             dispatch(sendSimulationAnswers(obj, testID, finishTime, reallyFinished));
//         }
//     }, [])


//     // useUnload(e => {
//     //     e.preventDefault();
//     //     e.returnValue = '';
//     //     onExit()
//     // });

//     // useEffect(() => {

//     //     const alertUser = event => {
//     //         console.log(event);
//     //         event.preventDefault()
//     //         event.returnValue = 'Attenzione, se premi Ok tutte le modifiche andranno perse!'
//     //         return (event.returnValue)
//     //     };
//     //     window.addEventListener('beforeunload', alertUser)
//     //     window.addEventListener('unload', onExit)
//     //     return () => {
//     //         window.removeEventListener('beforeunload', alertUser)
//     //         window.addEventListener('unload', onExit)
//     //     };
//     // }, []);

//     // useEffect(() => {
//     //     for (let i = 0; i < obj.length; i++) {
//     //         if (Object.keys(obj[i]).length > 0) {
//     //             setTheChange(true)
//     //         }
//     //     }
//     // }, [obj]);

//     // useBeforeunload((event) => {
//     //     if (theChange) {
//     //         event.preventDefault();
//     //     } else {
//     //         dispatch(deleteTest(params, flag));
//     //     }
//     // });

//     const [confirmedNav, setConfirmedNav] = useState(false);
//     const [loc, setLoc] = useState();

//     const handleBlockedNavigation = (nextLocation, action) => {
//         //console.log("saluuuuuuttttttt")
//         const confirmedNavigation = confirmedNav
//         if (!confirmedNavigation && nextLocation) {
//             setOpenAlerModal(true);
//             setLoc(nextLocation);
//             return false
//         } else {
//             //console.log("CRISTOOUSSJHSJSKJSKSLSLSSL")
//             dispatch(deleteTest(params, flag));
//             return true
//         }

//     };

//     const setActions = (e) => {
//         setChoose(e);
//         if (e) {
//             setConfirmedNav(true);
//             setTimeout(() => {
//                 handleBlockedNavigation(loc);
//                 history.push(loc);
//             }, 250);
//         }
//     };

//     // console.log('OBJ', obj);
//     // console.log('TEST', tests);
//     // console.log('TIME',finishTime);
//     // console.log('openAlerModal', openAlerModal);
//     // console.log('choose', choose);
//     // console.log('flag', flag);

//     return (
//         <>
//             <TestWrapper>
//                 <AllQuestionsSideBar
//                     id={id}
//                     setId={setId}
//                     // difficulty={tests[id].difficulty}
//                     difficulty={tests[id].which_file}
//                     arr={tests}
//                     obj={obj}
//                 />

//                 {tests !== undefined &&
//                     <Question
//                         tests={tests}
//                         id={id}
//                         setAnswers={setAnswer}
//                         obj={obj}
//                     />
//                 }
//             </TestWrapper>

//             <FooterBar
//                 id={id}
//                 setId={setId}
//                 tests={tests}
//                 submitTest={submitTest}
//                 rest={rest}
//                 flag={flag}
//             />

//             {/* <Prompt
//                 when={true}
//                 message={location =>
//                     `Non puoi navigare mentre il test è in corso! \nSe premi OK tutte le modifiche andranno perse!`
//                 }
//             /> */}

//             {flag === 'simulation' &&
//                 < Prompt
//                     when={true}
//                     message={handleBlockedNavigation}
//                 />
//             }

//             {openAlerModal &&
//                 <AlertModal
//                     title={'Attenzione'}
//                     setOpenAlerModal={setOpenAlerModal}
//                     setAction={setActions}
//                 />
//             }
//         </>
//     );
// }

// export default TestPage;
