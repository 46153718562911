import React from "react";

// PACKAGES
import styled from 'styled-components';

const ContainerStyles = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-end;
`;

const ContainerFiller = styled.div`
    height: 15px;
    width: 100%;
    background: ${props => props.total === 'total' ? 'var(--color2)' : 'var(--accentBackground)'};
    border-radius: 50px;
    padding: 5px;
`;

const FillerStyles = styled.div`
    position:relative;
    height: 100%;
    transition: width 1s ease-in-out;
    width: ${(props) => (props.width === 0 ? "0.4" : props.width)}%;
    background-color: ${props => (props.width <= 40 && 'var(--err)') ||
        (props.width > 40 && props.width <= 99 && 'var(--warning)') ||
        (props.width >= 100 && 'var(--success)')
    };
    color: var(--text4);
    border-radius: inherit;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const LabelWrapper = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LabelStyles = styled.span`
    /* color: ${props => props.total === 'total' ? 'var(--text5)' : 'var(--text1)'}; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text3);
    font-size: 14px;
    font-weight: bold;
`;


const ProgressBar = ({ count, flag, lessonsTotal, lessonsCompletedTotal, total }) => {
    const test = (completed, total) => {
        // console.log(completed);
        if (completed !== undefined && total !== undefined) {
            return completed === 1 ? `Completato ${completed} lezione  su ${total}` :
                `Completato ${completed} lezioni  su ${total}`
        }
    };

    // console.log('PROGRESS TOTAL',total);

    return (
        <ContainerStyles >
            {/* <LabelStyles total={total}>{count === null ? '0%' : `${count}% `}</LabelStyles> */}
            <LabelWrapper>
                <LabelStyles >
                    {test(lessonsCompletedTotal, lessonsTotal)}
                </LabelStyles>
                {/* <LabelStyles total={total}>{count === null ? '0%' : `${20}% `}</LabelStyles> */}
                <LabelStyles total={total}>{total}%</LabelStyles>
            </LabelWrapper>

            <ContainerFiller total={total}>
                {/* <FillerStyles switch={flag} color={'var(--success)'} width={CountProgress()}></FillerStyles> */}
                <FillerStyles switch={flag} color={'var(--success)'} width={total > 100 ? 100 : total}></FillerStyles>
            </ContainerFiller>
        </ContainerStyles>
    );
};

export default ProgressBar;
