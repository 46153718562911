import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";


// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import PhysicalLessons from './PhysicalLessons';

// PAGE


// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllPhysicalSubjects } from '../../../Store/Actions/physicalActions';

//import { getAllSubjects } from '../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const MateriaWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Lesson = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background3);
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const Left = styled.div`
    
    
`;

const Right = styled.div`
    >span {
        font-size: 14px;
    }
    >i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) ${props => props.open === 'true' && 'rotate(-90deg)'};
        color: var(--text3);

    }
`;

const Sts = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    border-radius: var(--radius);
    background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
    >div:nth-last-child(1){
        border-radius:  0 0 var(--radius) var(--radius);
    }
`;


const PhysicalMateria = ({ idCourse, idCheckUser, studDetails, course }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    const materie = useSelector(({ physical }) => physical.allPhysicalSubs);

    const [openLessons, setOpenLessons] = useState(false);
    const [mats, setMats] = useState()

    useEffect(() => {
        const id = idCheckUser !== undefined ? idCheckUser : profile.idUser;
        dispatch(getAllPhysicalSubjects(idCourse, id));
    }, [dispatch]);

    useEffect(() => {
        let ret = []
        if (materie !== undefined) {
            materie.forEach(el => {
                //if (el.visibility === 'Tutti' || el.visibility === 'Apprendimento') {
                ret.push(el)
                // }
            })
            //ret.sort((a, b) => a.order - b.order)
            // setMats(ret);

            // FILTER AND EXCLUDE
            if (ret !== undefined) {
                let newOne = [];
                let lessons = [];
                //console.log("ret", ret)
                for (let i = 0; i < ret.length; i++) {
                    const prop = ret[i].PhisicalSubjects_has_PhisicalLessons;
                    for (let j = 0; j < prop.length; j++) {
                        if (prop[j].PhisicalLessons_idPhisicalLesson_PhisicalLesson.excluded === null) {
                            //setMats(ret);
                            lessons.push(prop[j].PhisicalLessons_idPhisicalLesson_PhisicalLesson)
                            //newOne.push(ret[i]);
                        } else {
                            const findIfExists = JSON.parse(prop[j].PhisicalLessons_idPhisicalLesson_PhisicalLesson.excluded).find(id => id === course.idCourse);
                            if (!findIfExists) {
                                lessons.push(prop[j].PhisicalLessons_idPhisicalLesson_PhisicalLesson)
                            }
                            //setMats(newOne);
                        }
                    }
                    let sub = {};
                    sub = { ...ret[i] }
                    sub.PhisicalSubjects_has_PhisicalLessons = lessons;
                    newOne.push(sub)
                    lessons = [];
                }
                //console.log("QUESTOOO", newOne)
                setMats(newOne);
            }
        }
    }, [materie]);

    // console.log('mats', mats);
    // console.log("DETAILS", idCourse);
    // console.log('MATERIA', mats);
    // console.log("MATERIE", materie);    
    // console.log("PROFILE", profile);


    return (
        (mats !== undefined && mats.length > 0) &&
        <MateriaWrapper>
            <Header>{mats !== undefined && mats.length} Materie</Header>
            {mats !== undefined &&
                mats.map((mat, i) =>
                    <div key={i}>
                        <PhysicalLessons
                            mat={mat}
                            idCheckUser={idCheckUser}
                            studDetails={studDetails}
                            course={course}
                        />
                    </div>
                )}
        </MateriaWrapper>
    );
}

export default PhysicalMateria;
