import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import EserciziCard from '../../../GeneralComponents/Cards/EserciziCardNew';
import TemaCard from '../../../../GeneralComponents/Cards/TemaCard';


// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { bringTestsDone, bringSimulationsDone, getAllWrongQuezzes } from '../../../Store/Actions/testActions';
import { getAllTemaTestDone } from '../../../../../Store/Actions/temaActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    TestWrapper,
    GoBack,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../../../PagesShareStyle/PagesShareStyle';

const CardsWrapper = styled.div`
    width:100%;
    /* height: calc(100vh - 160px); */
    /* margin: 30px 0 0; */
    padding: 20px 0;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(10, auto); */
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: flex-start;
        /* &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 1350px) {
            grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 850px) {
            grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 600px) {
            grid-template-columns: repeat(1,auto);
            grid-template-columns: 1fr;
        } */
`;

const SubTitle = styled.div`
   color:  var(--text5);
   font-size: 22px;
   text-align: center;
   background: linear-gradient(90deg,rgba(240,242,245,1) 0%,rgba(119,123,141,0.9500175070028011) 30%,rgba(108,113,132,1) 50%,rgba(96,101,122,0.8855917366946778) 70%,rgba(240,242,245,1) 100%);
`;

const svolgere = [
    'Analisi della traccia',
    'Elaborazione del Testo o Scaletta',
    'Coerenza e stesura del testo',
    'Stesura e revisione del tema',
    'Registri linguistici',
    'Avvio alla scrittura',
];


const TemaPreMainPageForProf = ({ details }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(({ auth }) => auth.user);
    const temaDone = useSelector(({ tema }) => tema.allTraceDone);
    const [getDone, setGetDone] = useState([]);

    useEffect(() => {
        if (temaDone !== undefined) {
            const key = 'flag';
            const unique = [...new Map(temaDone.map(item => [item[key], item])).values()]
            setGetDone(unique);
        };
    }, [temaDone]);

    useEffect(() => {
        dispatch(getAllTemaTestDone(details.studDetails.idUsers,details.course));
    }, [dispatch]);

    const capitalizeFirstLetter = (str) => {
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    // console.log('temaPreMainPage', temaDone);

    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                <Title>{details.title} - {details.course.course_code}</Title>
                {details.studDetails !== undefined &&
                    <>
                        {capitalizeFirstLetter(details.studDetails.user_role)}: {details.studDetails.user_name} {details.studDetails.user_surname}
                    </>
                }
            </TitleWrapper>

            <div style={{ marginTop: '20px' }}>
                <SubTitle>Test Svolti</SubTitle>
                <CardsWrapper>
                    {getDone !== undefined &&
                        getDone.map((type, i) =>
                            <TemaCard
                                key={i}
                                // type={type.type.toUpperCase().replace('-', ' ')}
                                type={(type.flag === "traceAnalysis" && svolgere[0]) ||
                                    (type.flag === "textProcessing" && svolgere[1]) ||
                                    (type.flag === "consistency" && svolgere[2]) ||
                                    (type.flag === "revisione" && svolgere[3]) ||
                                    (type.flag === "registri" && svolgere[4]) ||
                                    (type.flag === "avvio" && svolgere[5])
                                }
                                course={details.course}
                                done={type.done}
                                flag='svolti'
                                allDetails={details}
                                svolgere={svolgere}
                            />
                        )}
                </CardsWrapper>
            </div>

        </WrapperRightSide>
    );
}

export default TemaPreMainPageForProf;
