import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';

// COMPONENTS
import NotifRow from './NotifRow';

// REDUX
import { useSelector } from 'react-redux';
// import { getNotifNav } from '../../../../Store/Actions/paymentsActions';

// STYLE
import {
    Btn,
    // DialogBox,
    ReminderWrapper,
} from './StyleNavBar';

const Wrapper = styled.div`
    position: relative;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
        >div{
            margin-left: 5px;
            >h6{
                color: var(--text2);
                letter-spacing: 1px;
            }
        }
`;

const AvatarDisplay = styled.div`
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    border-radius: 50px;
    width:50px;
    height:50px;
    user-select: none;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >h4{
            font-size: 24px;
            display:flex;
            align-items:center;
            justify-content:center;
            color: var(--text2);
            font-weight: bold;
            background: var(--background4);
        }
        >img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
            cursor: pointer;
            background: var(--background2);
        }
`;

const DialogBox = styled.div`
    position: absolute;
    top: 80px;
    right: 0px;
    width: 200px;
    /* min-height: 200px; */
    padding: 10px;
    overflow: auto;
    z-index: 2;
    text-align: center;
    color: var(--text2);
    border-radius: var(--radius);
    background: var(--background4);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
     /* background: #5f678293;
    backdrop-filter: blur(10px); */
        >div{
            display: flex;
            align-items: center;
            padding: 10px;
            margin-top: 5px;
            cursor: pointer;
            border-radius: var(--radius);
            >h5{
                color: var(--text3);
                padding-left: 10px;
            }
            &:hover{
                background: var(--background2);
            }
        }
`;

const Hr = styled.hr`
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 1px solid #e9e9e9;
    width: 90%;
    margin: 10px auto;
`;

const BoxLink = styled(Link)`
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 5px;
    cursor: pointer;
    border-radius: var(--radius);
    text-decoration: none;
    color: var(--text2);
        >h5{
            color: var(--text3);
            padding-left: 10px;
        }
        &:hover{
            background: var(--background2);
        }
`;

const MessengerNotif = styled.div`
    position: absolute;
    top: 2px;
    left: 40px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #0084ff;
    border: 2px solid #fff;
`;

const MessengerNotifForBtn = styled.div`
    position: absolute;
    right: 10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: #0084ff;
`;


const ProfileOptions = ({ disconnect }) => {
    const user = useSelector(({ auth }) => auth.user);
    const globalNotif = useSelector(({ other }) => other.globalNotif);
    const openDialogRef = useRef();
    const notifNav = [];
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let handler = (e) => {
            if (openDialogRef.current) {
                if (openDialogRef.current.contains(e.target) === false) {
                    setOpen(false)
                }
            }
        };
        document.addEventListener("mousedown", handler, true);
        return () => {
            document.removeEventListener("mousedown", handler, true);
        }
    }, [open]);

    const getInitials = (user) => {
        if (user !== undefined) {
            const string = user.firstName + ' ' + user.lastName;
            const names = string.split(' ');
            let initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials
        }
    };


    return (
        <ReminderWrapper ref={openDialogRef}>
            {/* <Btn color='notif' onClick={() => setOpen(!open)}>
                {notifNav.length > 0 && <span></span>}
                <i className="fas fa-bell"></i>
            </Btn> */}

            <Wrapper onClick={() => setOpen(!open)}>
                <AvatarDisplay color='notif' >
                    <h4>{getInitials(user)}</h4>
                </AvatarDisplay>
                <div>
                    <h6>{user.firstName}</h6>
                    <h6>{user.lastName}</h6>
                    {/* <h6>{user.idUser}</h6> */}
                </div>

                {/* Uncomment for messages */}
                {/* {globalNotif &&
                    <MessengerNotif />
                } */}
            </Wrapper>

            {open &&
                <DialogBox >
                    <BoxLink onClick={() => setOpen(!open)} to='/dashboard/profile'>
                        <i className="fa-solid fa-id-card-clip"></i>
                        <h5>Profilo</h5>
                    </BoxLink>

                    {/* Uncomment for messages */}
                    {/* <BoxLink onClick={() => setOpen(!open)} to='/dashboard/messenger'>
                        <i className="fa-brands fa-facebook-messenger"></i>
                        <h5>Messenger</h5>

                        {globalNotif &&
                            <MessengerNotifForBtn />
                        }
                    </BoxLink> */}

                    <Hr></Hr>

                    <div style={{ color: 'var(--err)' }} onClick={() => disconnect()}>
                        <i className="fas fa-power-off"></i>
                        <h5>Disconnetti</h5>
                    </div>
                </DialogBox>
            }

        </ReminderWrapper>
    );
}

export default ProfileOptions;
