const initState = {
  friend: [],
  mess: [],
  socket: null,
};

const conversationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_CONVERSATIONS':
      return {
        ...state,
        conv: action.payload,
      };

    case 'GET_MESSAGES':
      return {
        ...state,
        mess: action.payload,
      };

      case 'GET_ALL_FRIENDS':
      return {
        ...state,
        allFriends: action.payload,
      };

    case 'GET_FRIENDS':
      return {
        ...state,
        friends: action.payload,
      };

    case 'FIND_ONLINE_FRIENDS':
      return {
        ...state,
        onlineFriends: action.payload,
      };

      case 'SET_ONLINE_FRIENDS':
      return {
        ...state,
        setOnlineFriends: action.payload,
      };

      case 'CREATE_SOCKET':
      return {
        ...state,
        socket: action.payload,
      };

      case 'UNREAD_MESS':
        return {
          ...state,
          unreadMess: action.payload,
        };

    default:
      return state;
  }
};

export default conversationReducer;