import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import TypeCard from '../../GeneralComponents/Cards/TypeCard';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    Title,
    //TitleWrapper,
    //TestWrapper,
    GoBack,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const TitleWrapper = styled.div`
  /* display: flex; */
`;

const CardsWrapper = styled.div`
    width:100%;
    /* padding: 20px 0 0 110px; */
    padding: 20px 0;
    /* overflow: auto; */
    display: grid;
    /* grid-template-columns: repeat(4, auto); */
    grid-template-columns: repeat(4, 3fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: left;
    /* justify-content: space-evenly; */
    justify-content: flex-start;
    justify-items: stretch;
        &::-webkit-scrollbar {
            display: none;
        };
        @media (max-width: 2900px) {
            /* grid-template-columns: repeat(3,auto); */
            grid-template-columns: repeat(3, 4fr);
        }
        @media (max-width: 1900px) {
            /* grid-template-columns: repeat(2,auto); */
            grid-template-columns: repeat(2, 6fr);
        }
        @media (max-width: 600px) {
            /* grid-template-columns: repeat(1,auto); */
            grid-template-columns: repeat(1, 12fr);
            justify-content: center;
        }
`;


const TypePageForProf = ({ details }) => {
    const history = useHistory();
    const [type, setType] = useState();

    useEffect(() => {
        const initialArr = JSON.parse(details.res.availableTests);
        let newArr = [];

        for (let i = 0; i < initialArr.length; i++) {
            newArr.push({
                // name: initialArr[i].prova,

                name: (initialArr[i].prova === 'Preselettiva' && 'Prova Preselettiva') ||
                    (initialArr[i].prova === 'TPA' && 'Test Psico Attitudinali') ||
                    (initialArr[i].prova === 'TEMA' && 'Prova Scritta - Tema') ||
                    (initialArr[i].prova === 'Orale' && 'Prova Orale') ||
                    (initialArr[i].prova === 'Fisica' && 'Prova Fisica'),

                icon:
                    (initialArr[i].prova === 'Preselettiva' && <i className="fa-brands fa-connectdevelop"></i>) ||
                    (initialArr[i].prova === 'TPA' && <i className="fa-solid fa-atom"></i>) ||
                    (initialArr[i].prova === 'TEMA' && <i className="fa-solid fa-book-open"></i>) ||
                    (initialArr[i].prova === 'Orale' && <i className="fa-solid fa-ear-listen"></i>) ||
                    (initialArr[i].prova === 'Fisica' && <i className="fa-solid fa-person-running"></i>)
            });
        };

        setType(newArr);
    }, [details]);

    const capitalizeFirstLetter = (str) => {
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    // console.log("PROF TYPE PAGE DETAILS", details);  
    // console.log('PAGE TYPE PAGE', JSON.parse(details.course.availableTests));
    // console.log('PROF TYPE PAGE', type);


    return (
        <WrapperRightSide>

            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                <Title>{details.res.course_name} - {details.res.course_code}</Title>
                {/* Student: {details.studDetails.user_name} {details.studDetails.user_surname} */}
                {details.studDetails !== undefined &&
                    <>
                        {capitalizeFirstLetter(details.studDetails.user_role)}: {details.studDetails.user_name} {details.studDetails.user_surname}
                    </>
                }
            </TitleWrapper>

            <CardsWrapper>
                {type !== undefined &&
                    type.map((ty, i) =>
                        <TypeCard
                            key={i}
                            ty={ty}
                            course={details.res}
                            flag={'prof'}
                            idCheckUser={details.idCheckUser}
                            studDetails={details.studDetails}
                        />
                    )}
            </CardsWrapper>

        </WrapperRightSide>
    );
}

export default TypePageForProf;