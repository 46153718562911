import React, { useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import DeleteModal from '../../GeneralComponents/Modals/DeleteModal';

// REDUX
// import { useDispatch } from 'react-redux';

import {
    Td,
    // ExpandTableRates,
    ExpandBtn,
    TrDiv,
    // LinkDiv,
    PopUp2,
    EditWrapper,
    Edit,
    TdHeader,
    // PopUpTd,
    // colourStyles
} from '../PagesShareStyle/PagesShareStyle';

const Sts = styled.div`
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    max-width: 90px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        (props.color === 'Incompleto' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
`;


const TestDetailsTable = ({ elem, i }) => {

    // console.log(JSON.parse(elem.sim_config));

    // const handleStatus = () => {
    //     if (elem.quiz_answers === null) {
    //         return 'Incompleto'
    //     } else {
    //         const asw = JSON.parse(elem.quiz_answers);
    //         const qui = JSON.parse(elem.quiz_questions);

    //         let rez = 0;
    //         for (let i = 0; i < asw.length; i++) {
    //             if (Object.keys(asw[i]).length > 0) {
    //                 rez += 1
    //             }
    //         }
    //         if (rez < qui.length) {
    //             return 'Incompleto'
    //         } else {
    //             return 'Completo'
    //         }
    //     }
    // };

    // const nrAnsw = () => {
    //     const asw = JSON.parse(elem.quiz_answers);
    //     const qui = JSON.parse(elem.quiz_questions);

    //     let rez = 0;
    //     for (let i = 0; i < asw.length; i++) {
    //         if (Object.keys(asw[i]).length > 0) {
    //             rez += 1
    //         }
    //     }
    //     return rez;
    // };

    const formatData = (data) => {
        // console.log(data);
        const extractData = data.split('T')
        let format;
        if (extractData[0] !== '') {
            const d = extractData[0].split('-');
            format = d[2] + '/' + d[1] + '/' + d[0];
        } else {
            format = ''
        }
        return format
    };

    const formatTime = () => {
        const time = JSON.parse(elem.sim_config).time;
        const hours = Math.trunc(time / 60);
        const minutes = time % 60;
        let formatHour;
        let formatMin;
        if (hours < 9) {
            formatHour = '0' + hours;
        } else {
            formatHour = hours;
        };
        if (minutes < 9) {
            formatMin = '0' + minutes;
        } else {
            formatMin = minutes;
        };
        const formatTime = formatHour + ":" + formatMin + ":" + '00';
        // console.log('TIME TO DIFF',formatTime, elem.finish_time);

        const startTime = moment(formatTime, 'HH:mm:ss');
        const endTime = moment(elem.finish_time, 'HH:mm:ss');
        const mins = moment.utc(moment(startTime, "HH:mm:ss").diff(moment(endTime, "HH:mm:ss"))).format("HH:mm:ss");

        return mins
    };


    return (
        <>
            < tr >
                <Td>{formatData(elem.date)}</Td>
                <Td>
                    {JSON.parse(elem.sim_config).time} min
                </Td>
                {/* <Td>{elem.finish_time}</Td> */}
                <Td>{formatTime()}</Td>
                <Td>{elem.points}</Td>

                <Td >
                    <div style={{ display: 'flex' }}>
                        <EditWrapper>
                            <Edit
                                to={{
                                    pathname: '/dashboard/simulation-verification',
                                    state: { elem, flag: 'simulation' }
                                }}
                            >
                                <i className="fa-solid fa-binoculars"></i>
                            </Edit>
                            <PopUp2>Visualizza simulazione</PopUp2>
                        </EditWrapper>

                        {/* <EditWrapper>
                            <Edit
                                to={{
                                    pathname: '/dashboard/users/results',
                                    state: { elem, flag: 'mat' }
                                }}
                            >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                            </Edit>
                            <PopUp2>Di più</PopUp2>
                        </EditWrapper> */}

                        {/* <DeleteModal
                            flag='course'
                            id={elem.idCourse}
                            icon={<i className="far fa-trash-alt"></i>}
                            title={'Rimuovi'}
                            body={`Stai per rimuovere ordina ${elem.name} dalla tua azienda.\nSei sicuro di voler procedere?`}
                        /> */}
                    </div>
                </Td>
            </tr >
        </>
    )
}

export default TestDetailsTable;
