export const handleSideBar = (props) => (dispatch) => {
    dispatch({
        type: 'HANDLE_SIDEBAR',
        payload: props,
    });
};

export const handleRightSideBar = (props) => (dispatch) => {
    dispatch({
        type: 'HANDLE_RIGHT_SIDEBAR',
        payload: props,
    });
};

export const handleCalendar = (props) => (dispatch) => {
    dispatch({
        type: 'HANDLE_SIDEBAR_CALENDAR',
        payload: props,
    });
};

export const setTheDay = (props) => (dispatch) => {
    dispatch({
        type: 'SET_THE_DAY',
        payload: props,
    });
};

export const setSize = (props) => (dispatch) => {
    dispatch({
        type: 'SET_PAGE_SIZE',
        payload: props,
    });
};

export const setPrevLocation = (props) => (dispatch) => {
    dispatch({
        type: 'SET_PREV_LOCATION',
        payload: props,
    });
};

export const finishSimulation = (props) => (dispatch) => {
    dispatch({
        type: 'SET_FINISH_SIMULATION',
        payload: props,
    });
};

export const finishTime = (props) => (dispatch) => {
    dispatch({
        type: 'SET_FINISH_TIME',
        payload: props,
    });
};

export const openMessSidebar = (props) => (dispatch) => {
    dispatch({
        type: 'SET_MESS_SIDEBAR',
        payload: props,
    });
};

export const openFriendsSidebar = (props) => (dispatch) => {
    dispatch({
        type: 'SET_FRIENDS_SIDEBAR',
        payload: props,
    });
};

export const globalNotif = (props) => (dispatch) => {
    dispatch({
        type: 'SET_GLOBAL_NOTIF',
        payload: props,
    });
}; 

export const setMsgListen = (props) => (dispatch) => {
    dispatch({
        type: 'SET_MSG_LISTEN',
        payload: props,
    });
}; 

export const setNotifListen = (props) => (dispatch) => {
    dispatch({
        type: 'SET_NOTIF_LISTEN',
        payload: props,
    });
}; 