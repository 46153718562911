import { useEffect, useState, useCallback } from 'react';
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// ASSETS
import pic from '../../../Assets/Capture.PNG';
import canvasImage from '../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../GeneralComponents/TestViewsBars/NavBar';
// import FooterBar from '../../GeneralComponents/TestBars/FooterBar';
import TestPage from './OneAswTest/TestPageWithOneAsw';
// import TestPage from './MultipleAswTest/TestPageWithMultipleAsw';
// import TestPage from './TextEditorTest/TestPageWithTextEditor';
// import TestPage from './DrawingTest/TestPageWithDrawing';

// import TestPage from './TestVerification/TestVerificationPage';
import FinishPage from './TestFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedQuestionsForTest, bringTest } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../PagesShareStyle/PagesShareStyle';


const MainTestPage = ({ details }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const tests = useSelector(({ test }) => test.bringTest);
    const [finishTheTest, setFinishTheTest] = useState(false);

    const [theTest, setTheTest] = useState();
    // const tests = JSON.parse(details.quiz_questions);

    // const tests = []

    // console.log('brinf', params.id)

    useEffect(() => {
        dispatch(bringTest(params.id))
    }, []);

    useEffect(() => {
        if (tests !== undefined) {
            setTheTest(JSON.parse(tests.quiz_questions));
        }
    }, [tests]);

    const [rest, setRest] = useState(0);

    // console.log("DETAILS", details);

    return (
        theTest !== undefined &&
        <WrapperRightSide>
            <NavBar
                total={theTest.length}
                //rest={theTest.length - 1}
                flagExercises='exercises'
                rest={rest}
            />

            <TestPage
                tests={theTest}
                setRest={setRest}
                // testID={details.idTestExercises}
                testID={tests.idTestExercises}
                setFinishTheTest={setFinishTheTest}
                rest={rest}
                params={params.id}
                details={details}
            />

            {/* <FooterBar /> */}

            {finishTheTest &&
                <FinishPage
                    testID={tests.idTestExercises}
                />
            }
        </WrapperRightSide>

        // tests !== undefined &&
        // <WrapperRightSide>
        //     <NavBar
        //         total={tests.length}
        //         rest={tests.length - 1}
        //     />

        //     <TestPage
        //         tests={tests}
        //         setRest={setRest}
        //         // testID={details.idTestExercises}
        //         testID={tests.idTestExercises}
        //     />

        //     {/* <FooterBar /> */}
        // </WrapperRightSide>
    );
}

export default MainTestPage;


