import axios from 'axios';
axios.defaults.withCredentials = true;

export const createConversations = (from, to) => (dispatch, getState) => {
  
  const body = {
    from: from,
    to: to,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/createConversations`, body)
    .then((res) => {
      console.log("CREATE_CONV", res);
      dispatch({
        type: 'GET_CONVERSATIONS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getConversations = (id) => (dispatch, getState) => {

  const body = {
    userId: id,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/getConversations`, body)
    .then((res) => {
      // console.log("GET_CONV", res)
      dispatch({
        type: 'GET_CONVERSATIONS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findMessages = (id) => (dispatch, getState) => {

  const body = {
    convId: id,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/getMessages`, body)
    .then((res) => {
      // console.log("GET_MESSAGES", res)
      dispatch({
        type: 'GET_MESSAGES',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const newMessage = (newMsg, userId, convId) => (dispatch, getState) => {

  const body = {
    convId: convId,
    senderId: userId,
    text: newMsg,
  };

  // const mess = getState().conversations.mess;

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/createMessages`, body)
    .then((res) => {
      // console.log("GET_MESSAGES", res.data);
      // dispatch({
      //   type: 'GET_MESSAGES',
      //   payload: [...mess, res.data],
      // });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findAllFriends = () => (dispatch, getState) => {

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/getAllFriends`)
    .then((res) => {
      // console.log("GET_ALL_FRIENDS", res);
      dispatch({
        type: 'GET_ALL_FRIENDS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findFriends = (ids) => (dispatch, getState) => {

  // const body = {
  //   friendId: ids,
  // };
  
  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/getFriends`, ids)
    .then((res) => {
      // console.log("GET_FRIENDS", res);
      dispatch({
        type: 'GET_FRIENDS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findOnlineFriends = (ids) => (dispatch, getState) => {

  // const body = {
  //   friendId: id,
  // };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/getFriends`, ids)
    .then((res) => {
      // console.log("GET_FRIENDS", res);
      dispatch({
        type: 'FIND_ONLINE_FRIENDS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setOnlineUsers = (users) => (dispatch, getState) => {
  dispatch({
    type: 'SET_ONLINE_FRIENDS',
    payload: users,
  });
};

export const createSocket = (users) => (dispatch, getState) => {
  dispatch({
    type: 'CREATE_SOCKET',
    payload: users,
  });
};

export const markMessAsRead = (id, chatId) => (dispatch, getState) => {

  const body = {
    idUser: id,
    chatId: chatId,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/markMessAsRead`, body)
    .then((res) => {
      // console.log("GET_FRIENDS", res);
      dispatch({
        type: 'MARK_AS_READ',
        payload: res.data,
      });
      // dispatch(findAllUnreadMessages(id));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findAllUnreadMessages = (id) => (dispatch, getState) => {

  const body = {
    idUser: id,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/findAllUnreadMessages`, body)
    .then((res) => {
      // console.log("GET_FRIENDS", res);
      dispatch({
        type: 'UNREAD_MESS',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};