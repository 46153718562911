import React, { useState } from 'react';

// PACKAGES
// import styled from 'styled-components';

// STYLE
import {
    TestBody,
    BigQuestion,
    Question,
    Letter,
    Answers,
    AnswerWrapper,
    AnswerWrapperCompleted,
    CommentWrapper,
    Label,
    SubLabel,
    Container,
    InputTextArea,
    AddBtn,
    RemoveBtn,
} from '../Style.js';

// REDUX
import { useSelector } from 'react-redux';

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z'];


const Conc = ({ tests, id, setAnswers, obj, opt, flag, doneAllObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const [inputFields, setInputFields] = useState([{ conc: '', subConc: '' }]);

    const handleInputChange = (index, event) => {

        // const values = [...inputFields];
        // values[index].date = event
        // setInputFields(values);
        if (flag !== 'sub') {
            const values = [...inputFields];
            values[index].conc = event.target.value
            setInputFields(values);

            setAnswers({ values, index, flag: 'conc' });
        } else {
            const values = [...inputFields];
            values[index].subConc = event.target.value
            setInputFields(values);

            setAnswers({ values, index, flag: 'conc' });
        }
    };

    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ conc: '', subConc: '' });
        setInputFields(values);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);

        setAnswers({ values, index, flag: 'conc' });
    };

    // console.log('CONC',inputFields);

    return (
        <>
            <Label htmlFor="asw1">Conclusione</Label>
            <SubLabel>
                <div>ARGOMENTI PRIMO LIVELLO</div>
                <div>SOTTO ARGOMENTI</div>
            </SubLabel>

            <Container>
                {inputFields.map((inputField, index) => (
                    <div key={`${inputField}~${index}`} style={{ position: 'relative' }}>
                        <InputTextArea
                            type='text'
                            placeholder='es. something'
                            name='argument'
                            // required={true}
                            value={inputFields[index].date}
                            // props={{
                            //     label: 'Data di pagamento',
                            //     placeholder: 'es.02/12/2022',
                            //     inputType: 'date',
                            //     name: 'date',
                            //     required: 'required',
                            //     value: `${inputFields[index].date}`,
                            // }}
                            // handleChange={(event) => handleInputChange(index, event)}
                            onChange={(event) => handleInputChange(index, event)}
                        />

                        <InputTextArea
                            type='text'
                            placeholder='es. something'
                            name='subArgument'
                            // required={true}
                            value={inputFields[index].date}
                            // handleChange={(event) => handleInputChange(index, event, flag = 'sub')}
                            onChange={(event) => handleInputChange(index, event, flag = 'sub')}
                        />

                        <RemoveBtn
                            className="btn btn-link"
                            type="button"
                            onClick={() => handleRemoveFields(index)}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </RemoveBtn>
                    </div>
                ))
                }

                <AddBtn
                    className="btn btn-link"
                    type="button"
                    onClick={() => handleAddFields()}
                >
                    <i className="fas fa-plus"></i>
                    Conclusione
                </AddBtn>
            </Container>
        </>
    );
}

export default Conc;