import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    TestWrapper,
    Btn,
} from '../../PagesShareStyle/PagesShareStyle';


const Lesson = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background4);
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    border-bottom: 1px solid #b5b3b340;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const Left = styled.div`
    display: flex;
`;

const Right = styled.div`
    >span {
        font-size: 14px;
    }
    >i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) ${props => props.open && 'rotate(-90deg)'};
        color: var(--text3);
        transition: all .1s ease-in-out;
    }
`;

const LessonsSubMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--background1);
    overflow: hidden;
    height: ${props => props.opened === 'true' ? ((props.tall * 60) + props.tall * 1) : 0}px;
    transition: height 0.4s ease;
`;

const BtnWrapper = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #5c5c5c91;
    text-decoration: none;
        &:hover{
            background: var(--text3);
        }
`;

const LessonsBtns = styled.div`
    min-height: 60px;
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    color: var(--text5);
`;


const TpaLessons = ({ type, idCheckUser, studDetails, course }) => {
    const dispatch = useDispatch();
    const [openLessons, setOpenLessons] = useState(false);
    const tpa = useSelector(({ tpa }) => tpa.allTypologies);
    const [arr, setArr] = useState([]);

    useEffect(() => {
        const newArr = tpa.filter(el => el.type === type)
        setArr(newArr);
    }, [type])

    // console.log("type", type);


    return (
        <>
            <Lesson onClick={() => setOpenLessons(!openLessons)}>
                <Left>
                    {type}
                </Left>

                <Right
                    open={openLessons}
                >
                    <span>{arr.length} lezioni</span>
                    {arr.length > 0 &&
                        <i className="fa-solid fa-angle-left"></i>
                    }
                </Right>
            </Lesson>

            <LessonsSubMenu
                tall={`${arr !== undefined && arr.length}`}
                opened={openLessons === true ? 'true' : 'false'}
            >
                {arr.map((less, i) =>
                    <BtnWrapper
                        key={i}
                        to={{
                            pathname: '/dashboard/tpa-lesson',
                            state: {
                                less,
                                idCheckUser: idCheckUser,
                                studDetails: studDetails,
                                course
                            }
                        }}
                    >
                        <LessonsBtns>
                            {less.lesson_name}
                        </LessonsBtns>
                    </BtnWrapper>
                )}
            </LessonsSubMenu>
        </>
    );
}

export default TpaLessons;