import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavWrapper = styled.div`
    position: fixed;
    top: 0;
    width: calc(100% - 250px);
    margin-left: 250px;
    background:var(--background4);
    backdrop-filter: var(--blur);
    z-index: 2;
    box-shadow: 0 10px 10px rgb(0 0 0 / 5%);
        @media (max-width: 1100px) {
               width:100%;
               margin-left:0px;
            };
`;

export const Nav = styled.div`
    position:relative;
    height: 80px;
    /* max-width:1200px; */
    width:100%;
    margin: auto;
    display:flex;
    justify-content:flex-start;
    align-items: center;
`;


export const BtnWrapper = styled.div`
    position: absolute;
    right: 20px;
    display: flex;
`;

export const Btn = styled.button`
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    color: var(--text2);
    background: var(--background4);
    text-decoration: none;
    border:none;
    outline:none;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:0;
    margin: 0 5px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >i{
            font-size: 20px;
        }
        /* &:active{box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%)} */
        &:hover{
            color: ${props => props.color === 'notif' ? 'var(--pp-color)' : 'var(--err)'};
        }
        >span{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            border: 2px solid var(--color4);
            background-color: var(--err);

        }
`;

export const MessBtn = styled(Link)`
    position: relative;
    width:50px;
    height:50px;
    border-radius:50px;
    color: var(--text2);
    background: var(--background4);
    text-decoration: none;
    border:none;
    outline:none;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:0;
    margin: 0 5px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    /* position: fixed;
    bottom: 12px;
    right: 0px;
    z-index: 2;
    margin: 0 5px;
    background-color: var(--background4);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
        >i{
            font-size: 20px;
            color: var(--text1);
            padding: 15px;
            &:hover{
                color: var(--acc-color);
            }
        }
`;

export const ReminderWrapper = styled.div`
    position: relative;
`;

export const DialogBox = styled.div`
    position: absolute;
    top: 80px;
    right: 0px;
    width: 350px;
    /* min-height: 200px; */
    max-height: 500px;
    padding: 10px 20px;
    overflow: auto;
    z-index: 2;
    text-align: center;
    color: var(--text2);
    border-radius: var(--radius);
    background: var(--background4);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
     /* background: #5f678293;
    backdrop-filter: blur(10px); */
        >h4{
            font-weight:bold;
            color: var(--text2);
        }
        >h5{
            color: var(--text3);
        }
        >h3{
            color: var(--text4);
            padding: 20px 0px;
        }
`;

export const NotifRow = styled.div`
    width: 100%;
    min-height: 50px;
    text-align: left;
    padding: 5px 10px;
    margin: 10px 0;
    background: var(--background2);
    border-radius: var(--radius);
    border-left: ${props =>
        (props.status === 'urgent' && '4px solid var(--err)') ||
        (props.status === 'noturgent' && '4px solid var(--warning)')
    };
        >h6{
            color: var(--text3);
            
        }
        >h5{
            color: var(--text2);
            font-weight: bold;
        }
`;

export const Type = styled.span`
    padding: 10px 10px 10px 10px;
    font-size: 14px;
    cursor: pointer;
    padding: 2px 6px;
    border-radius:var(--radius);
    color:white;
    /* width:75px; */
    background-image: ${(props) =>
        (props.type === 'activ' && 'linear-gradient(310deg,#17ad37,#98ec2d)') ||
        (props.type === 'passiv' && 'linear-gradient(310deg,#f53939,#fbcf33)')
    // (props.type === 'none' && 'linear-gradient(310deg,#33fbf8,#3333fb)')
    };
    display: ${(props) => props.type === 'none' && 'none'};
`;