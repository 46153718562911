import React from 'react';

// PACKAGES
import styled from 'styled-components';


const GeneralWrapper = styled.div`
    width: 100%;
`;

const Title = styled.h3`
    width: 100%;
    font-weight: bold;
    color: var(--text1);
    padding-bottom: 10px;
`;


const SectionsTitle = (props) => {
    // const dispatch = useDispatch();

    return (
        <GeneralWrapper>
            <Title>{props.title}</Title>
        </GeneralWrapper>
    );
}

export default SectionsTitle;