import React from 'react'

// PACKEGES
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position:absolute;
  left:40px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Icon = styled.div`
  >i{
      font-size:10px;
      color: var(--text5);
      position:absolute;
      top:50%;
      left: 50%;
      transform:translate(-45%, -50%);
  }  
`

const StyledCheckbox = styled.div`
    position: relative;
    width: 18px;
    height: 18px;
    background: ${props => !props.checked ? 'var(--color4)' : 'var(--acc-color)'};
    border-radius: 3px;
    transition: all 250ms;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
      ${Icon} {
        visibility: ${props => (props.checked ? 'visible' : 'hidden')}
      }
`


const Checkbox = ({ onChange, checked }) => {

  return (
    <CheckboxContainer>
      <HiddenCheckbox
        checked={checked}
        onChange={(e) => onChange(e)}
      />
      <StyledCheckbox checked={checked}>
        <Icon >
          <i className="fas fa-check"></i>
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
