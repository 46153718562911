import { useState, useEffect } from 'react';
// import { useHistory } from "react-router-dom";

// ASSETS
import pic from '../../../../Assets/Capture.PNG';
import canvasImage from '../../../../Assets/birds.png'

// PACKAGES
// import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from './NavBarTestVerification';
import TestPage from './TestVerificationPage';
import LoadingCard from '../../../GeneralComponents/Loading/LoadingCard'

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getWrongQuizzesBySubject } from '../../../../Store/Actions/testActions'

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
    GoBack,
} from '../../PagesShareStyle/PagesShareStyle';


const MainTestVerificationPage = ({ details }) => {
    const dispatch = useDispatch();
    const [rest, setRest] = useState(localStorage.setItem('rest', 0));
    const wrongQuezzes = useSelector(({ test }) => test.bringWrongQuezzesBySubject);
    const [correct, setCorrect] = useState(0);
    const [incorrect, setIncorrect] = useState(0);
    const [wrong, setWrong] = useState()

    useEffect(() => {
        dispatch(getWrongQuizzesBySubject(details.elem.idSubject, details.user, details.course));
        setWrong(wrongQuezzes)
    }, [dispatch, details])

    // useEffect(() => {
    //     let arr = [];
    //     if (wrongQuezzes !== undefined) {
    //         for (let i = 0; i < wrongQuezzes.length; i++) {
    //             arr.push(wrongQuezzes[i])
    //         }
    //     }
    //     setWrong(arr)
    //}, [wrongQuezzes])

    // console.log("THIS", wrongQuezzes);
    // console.log('REST', tests.length,localStorage.getItem('rest')*1);
    // console.log("TEST VERIF", details);
    // console.log("TYPE", details.type)
    // console.log("wrong", wrongQuezzes)

    return (
        details.type !== 'Quiz errati' ?
            <WrapperRightSide>
                <NavBar
                    correct={correct}
                    incorrect={incorrect}
                />

                <TestPage
                    // tests={tests}
                    tests={details.elem}
                    //tests={details.type !== 'Quiz errati' ? details.elem : wrong}
                    setRest={setRest}

                    setCorrect={setCorrect}
                    setIncorrect={setIncorrect}
                    rest={rest}
                />
            </WrapperRightSide>
            :
            wrongQuezzes !== undefined &&
            <WrapperRightSide>
                <NavBar
                    correct={correct}
                    incorrect={incorrect}
                />

                <TestPage
                    // tests={tests}
                    tests={wrongQuezzes}
                    //tests={details.type !== 'Quiz errati' ? details.elem : wrong}
                    setRest={setRest}

                    setCorrect={setCorrect}
                    setIncorrect={setIncorrect}
                />

            </WrapperRightSide>
    );
}

export default MainTestVerificationPage;


