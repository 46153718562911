import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import QuizCard from '../../GeneralComponents/Cards/QuizCard/QuizCard';
import QuizCard from './QuizCardWrong/QuizCardWrong';
import Tags from './WrongTags/WrongTags';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { findAllSubjects, getAllWrongQuezzes } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    Title,
    TitleWrapper,
    TestWrapper,
    GoBack,
    // ActionsWrapper,
    // BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const CardsWrapper = styled.div`
    width: 100%;
    /* min-height: 100vh; */
    /* height: calc(100vh - 270px); */
    margin: 20px 0 0;
    /* padding: 20px 0; */
    padding-bottom: 80px;
    /* overflow: auto; */
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(10, auto); */
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    /* display: grid;
    grid-template-columns: repeat(6, auto);
    grid-template-rows: max-content;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: flex-start; */
        /* &::-webkit-scrollbar {
            display: none;
        }; */
        /* @media (max-width: 1650px) {
            grid-template-columns: repeat(5,auto);
        }
        @media (max-width: 1450px) {
            grid-template-columns: repeat(4,auto);
        }
        @media (max-width: 1250px) {
            grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 800px) {
            grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 550px) {
            grid-template-columns: repeat(1,auto);
        } */
`;

const BtnWrapper = styled.div`
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: 30px;
    /* background: red; */
    display: flex;
    justify-content: flex-end;
        /* @media (max-width: 775px) {
            flex-direction: column;
        }; */
`;

const BtnLink = styled(Link)`
    width: 200px;
    height:50px;
    /* margin-right: 10px; */
    display:flex;
    align-items: center;
    justify-content: center;
    color:var(--text5);
    font-weight:bold;
    font-size: 14px;
    border-radius: var(--radius);
    /* margin-top: 40px; */
    /* margin-bottom:20px; */
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    color: var(--text5);
    background: var(--acc-color);
    border: none;
    outline:none;
    text-decoration: none;
    box-shadow: var(--shadow);
        &:hover{
            box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
        }
        &:active{
            box-shadow: none;
        }
        @media (max-width: 775px) {
            margin-top: 10px;
        };
`;

const DisabledLink = styled.div`
    width: 200px;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    font-size: 14px;
    border-radius: var(--radius);
    /* margin-top: 40px; */
    /* margin-bottom:20px; */
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    color: var(--text5);
    background: var(--background3);
    border: none;
    outline:none;
    text-decoration: none;
    box-shadow: var(--shadow);
        &:hover{
            box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
        }
        &:active{
            box-shadow: none;
        }
        @media (max-width: 775px) {
            margin-top: 10px;
        };
`;


const ExercisesPage = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const materia = useSelector(({ test }) => test.allSubjects);
    const wrong = useSelector(({ test }) => test.bringWrongQuezzes);

    const [arr, setArr] = useState([]);
    const [newArr, setNewArr] = useState(arr);
    const [activateBtn, setActivateBtn] = useState(false);
    const [newWrong, setNewWrong] = useState();

    useEffect(() => {
        // dispatch(findAllSubjects(details.course));
        dispatch(getAllWrongQuezzes(details.course));
    }, [dispatch]);

    // Create the empty arr
    useEffect(() => {
        let createObj = [];
        if (wrong !== undefined) {
            for (let i = 0; i < wrong.length; i++) {
                createObj.push({})
            }
            setArr(createObj);
        }
    }, [wrong]);

    // Populates the objects in the array
    const addParam = (e) => {
        const value = [...arr]
        value[e.index] = e;
        setArr(value);
    };

    // Clear/Change the objects in the array
    useEffect(() => {
        let emptyIndex = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].nr === '') {
                emptyIndex.push(i);
            }
        }
        // console.log('emptyIndex',emptyIndex);
        if (emptyIndex.length === 0) {
            setNewArr(arr);
        } else {
            const value = [...arr]
            for (let j = 0; j < emptyIndex.length; j++) {
                value[emptyIndex[j]] = {};
            }
            setNewArr(value);
        }
    }, [arr]);

    // CHECK IF NEWOBJ IS POPULATED
    useEffect(() => {
        let pop = [];
        for (let k = 0; k < newArr.length; k++) {
            if (Object.keys(newArr[k]).length > 0) {
                pop.push(1)
                // if (newArr[k].difficulty === '') {
                //     pop.push(0)
                // } else { pop.push(1) }
            } else (
                pop.push(0)
            )
        }
        const check = pop.find(p => p === 1)
        // console.log('CHECK', check);
        if (check !== undefined) {
            setActivateBtn(true);
        } else {
            setActivateBtn(false);
        }
    }, [newArr]);

    // console.log('WRONQ QUEARR', arr);
    // console.log('WRONQ QUE NEW ARR', newArr);
    // console.log('WRONQ QUE',wrong);
    console.log('WRONQ QUE DETAILS', details);

    return (
        wrong === undefined ?
            <WrapperRightSide style={{ alignItems: 'center', justifyContent: 'center' }}>
                <img src={Loading} alt="Loading..."></img>
                <div>Loading...</div>
            </WrapperRightSide>
            :
            <WrapperRightSide>

                <TitleWrapper>
                    <GoBack onClick={() => history.goBack()}>
                        <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                    </GoBack>
                    <Title>{details.type}</Title>
                </TitleWrapper>

                <Tags
                    arr={newArr}
                    setArr={setArr}
                />

                {/* <CardsWrapper style={{ minHeight: 'calc(100vh - 250px)' }}> */}
                <CardsWrapper>
                    {wrong !== undefined &&
                        wrong.map((mat, i) =>
                            <QuizCard
                                key={i}
                                mat={mat}
                                addParam={addParam}
                                index={i}
                                arr={newArr}
                                flag={details.flag}
                            />
                        )}
                </CardsWrapper>

                <BtnWrapper>
                    {activateBtn ?
                        <BtnLink
                            style={{ marginBottom: '0' }}
                            to={{
                                // pathname: '/dashboard/create-quiz/pre-test',
                                pathname: '/dashboard/create-quiz/pre-wrong',
                                state: { newArr, course: details.course, flag: 'misti' }
                            }}
                        >
                            Inizia Test
                        </BtnLink>
                        :
                        <DisabledLink>
                            Inizia Test
                        </DisabledLink>
                    }
                </BtnWrapper>

            </WrapperRightSide>
    );
}

export default ExercisesPage;