import React from 'react';
import styled from 'styled-components';

//ASSETS
import loadingGif from '../../../Assets/loadingGif.gif'


const LoadingWrapper = styled.div`
    position: relative;
    /* top:0;
    left:0; */
    width: 100%;
    height: 100px;
    //height:100vh;
    display:flex;
    align-items:center;
    justify-content:center;
    //z-index:888;
    /* background:var(--color4); */
    //background: var(--background1);
    //backdrop-filter: var(--blur);
        >img{
            width:30%;
            position: absolute;
        }
        >h3{
            position: absolute;
            top:55%;
            left: 50%;
            transform:translate(-45%, -50%);
            cursor: default;
        }
`;


const LoadingCard = (props) => {

    return (
        <LoadingWrapper>
            <img src={loadingGif} alt=''></img>
            {/* <h3>Loading {props.type}...</h3> */}
        </LoadingWrapper>
    );
};

export default LoadingCard;
