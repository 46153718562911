import React from 'react';
// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';

// PACKAGES
import { BrowserRouter } from 'react-router-dom';

// REDUX
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './Store/Reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

// const root = ReactDOM.createRoot(document.getElementById('root'));

ReactDOM.render(
  // root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();