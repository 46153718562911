import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

//PACKAGES
// import styled from 'styled-components';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateTestDone } from '../../../Store/Actions/tpaActions'
//import { getAllUsers } from '../../../Store/Actions/usersActions';
import { getAllTemaTestDoneBy } from '../../../Store/Actions/temaActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    GoBack,
    // colourStyles,
    // NavTable,
    BtnLink,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
// import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './TemaExercisesDoneTable';

const tableHead = [
    'Data',
    'Num. Domande',
    // 'Stato',
    'Valutazione',
    'Azioni',
];


const TemaExercisesDone = ({ details }) => {
    const dispatch = useDispatch();
    let history = useHistory();
    // const users = useSelector(({ users }) => users.allUsers);
    const profile = useSelector(({ auth }) => auth.user);
    const update = useSelector(({ tema }) => tema.allTraceDoneBy);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [usr, setUsr] = useState([]);
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        if (details.allDetails !== undefined && details.allDetails.studDetails !== undefined) {
            dispatch(getAllTemaTestDoneBy(details.allDetails.studDetails.idUsers, details));
        } else {
            dispatch(getAllTemaTestDoneBy(profile.idUser, details));
        }
    }, [dispatch, details]);

    // console.log("TEACHERS USR", users);
    // console.log('PROFILE', profile);
    // console.log('details', details);
    // console.log('update', update);
    // console.log('paginatedPosts', paginatedPosts);

    return (
        update !== undefined &&
        <WrapperRightSide>
            <GoBack onClick={() => history.goBack()}>
                <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
            </GoBack>

            <Title>{details.type}</Title>

            {/* <ActionsWrapperSpace>
                <div></div>

                <Search
                    usr={teachers}
                    flag={'users'}
                    setUsr={setUsr}
                />
            </ActionsWrapperSpace> */}

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <BtnLink
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/teachers/newTeacher'>
                        <i className="fas fa-plus"></i>Professore
                    </BtnLink>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 275px)' }}>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono test da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono test da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                            details={update}
                                            allDetails={details.allDetails}
                                            type={details.type}
                                            svolgere={details.svolgere}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={update}
                setPaginatedPosts={setPaginatedPosts}
            />
        </WrapperRightSide >
    )
}

export default TemaExercisesDone;