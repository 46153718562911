import React, { useState, useEffect } from 'react';

//ASSETS
import loadingGif from '../../../Assets/loadingBtn.gif'

// PACKEGES
import styled, { keyframes } from 'styled-components';

// REDUX
import { useSelector } from 'react-redux';

// SHARE STYLE


const NotifWrapper = styled.div`
    position:fixed;
    width: 100%;
    height:100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index:2;
    pointer-events: auto;
    cursor: pointer;
    background: #2525256e;
    backdrop-filter: blur(10px);
    padding: 20px;
    transition: background-color .1s;
`;

const enter = keyframes`
0% {
    transform:  translate(-50%, -100%);
}
45% {
    transform:  translate(-50%, -50%);
}
80% {
    visibility: visible;
    transform:  translate(-50%, -60%);
}
100% {
    visibility: visible;
    transform:  translate(-50%, -50%);
}
`;

const DialogBox = styled.div`
    /* visibility: hidden; */
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    width: 50%;
    max-width: 500px;
    min-width: 350px;
    height: 300px;
    background: var(--color4);
    border-radius: var(--radius);
    z-index: 2;
    padding: 10px;
    /* animation: ${enter} .4s forwards; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >img{
            width:80px;
        }
        >div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
                >img{
                width:80px;
                }
        }   
`;

const Success = styled.div`
    >i{
        font-size: 80px;
        color: var(--success);
    }
`;

const Error = styled.div`
    >i{
        font-size: 80px;
        color: var(--err);
    }
`;

// const Hr = styled.hr`
//     width: 100%;
//     border: none;
//     border-bottom: 1px solid var(--text4);
// `;

const H2 = styled.h2`
    width:100%;
    text-align: center;
    color: var(--text2);
    padding: 10px;
`;

const H3 = styled.h3`
    width:100%;
    text-align: center;
    color: var(--text3);
    padding: 10px;
`;

// const H4 = styled.h4`
//     width:100%;
//     text-align: center;
//     color: var(--text3);
//     padding: 10px;
// `;


const CustomNotificationHandler = (props) => {
    // const dispatch = useDispatch();
    const notifDash = useSelector((state) => state.customNotifHandler.handlerNotifDash);
    const loadingNotif = useSelector((state) => state.customNotifHandler.isLoading);
    // const [openNotif, setOpenNotif] = useState(false);
    const [status, setStatus] = useState();

    // console.log(loadingNotif);
    // console.log('notifDash', notifDash);
    // console.log('status', status);

    useEffect(() => {
        if (notifDash !== undefined) {
            createNotification(notifDash.flag, notifDash.msg);
        }
    }, [notifDash]);

    const createNotification = (flag, msg) => {
        switch (flag) {
            case 'info':
                setStatus('info')
                break;
            case 'success':
                setStatus('success')
                break;
            case 'warning':
                setStatus('warning')
                break;
            case 'error':
                setStatus('error')
                break;
            default:
        };
        setTimeout(() => {
            setStatus();
        }, 3000);
    };


    return (
        <>
            {loadingNotif &&
                <>
                    <NotifWrapper />

                    <DialogBox>
                        {status === undefined ?
                            <>
                                <img src={loadingGif} alt=''></img>
                                <H3>Loading...</H3>
                            </>
                            :
                            status === 'success' ?
                                <Success>
                                    <i className="fa-regular fa-circle-check"></i>
                                    <H2>Successo</H2>
                                    <H3>Operazione avvenuta successo</H3>
                                </Success>
                                :
                                <Error>
                                    <i className="fa-regular fa-circle-xmark"></i>
                                    <H2>Errore</H2>
                                    <H3>Si prega di controllare i campi</H3>
                                </Error>
                        }
                    </DialogBox>
                </>
            }
        </>
    );
}

export default CustomNotificationHandler;