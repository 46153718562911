import React, { useState } from 'react';

// PACKAGES
// import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// STYLE
import {
    TestBody,
    BigQuestion,
    Question,
    Letter,
    Answers,
    AnswerWrapper,
    AnswerWrapperCompleted,
    CommentWrapper,
    Label,
    SubLabel,
    Container,
    Input,
    AddBtn,
    RemoveBtn,

    DnDContainer,
    QuestsContainer,
    Title,
    Body,
    TheElement,
    Empty
} from './Style.js';

// REDUX
import { useSelector } from 'react-redux';

// const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z'];

// const list = [
//     {
//         name: 'John',
//         id: '1'
//     },
//     {
//         name: 'Cost',
//         id: '2'
//     },
//     {
//         name: 'Mira',
//         id: '3'
//     },
// ];

const TestCoerenza = ({ tests, id, setAnswers, obj, opt, flag, doneAllObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const [firstColumn, setFirstColumn] = useState(tests)
    const [secondColumn, setSecondColumn] = useState([])

    const reorder = (source, startIndex, endIndex) => {
        const result = Array.from(source.droppableId === 'container1' ? firstColumn : secondColumn);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    // Moves an item from one list to another list
    const move = (source, destination, droppableSource, droppableDestination) => {
        let sourceClone = Array.from(source.droppableId === 'container1' ? firstColumn : secondColumn);
        let destClone = Array.from(source.droppableId === 'container1' ? secondColumn : firstColumn);
        const [removed] = sourceClone.splice(source.index, 1);

        destClone.splice(destination.index, 0, removed);

        const result = {};
        result[droppableSource] = sourceClone;
        result[droppableDestination] = destClone;

        return result;
    };

    const onDragEnd = result => {
        const { source, destination } = result;

        // console.log(result);

        // Dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                source,
                source.index,
                destination.index
            );

            // console.log('REORDER', items);

            if (source.droppableId === 'container1') {
                setFirstColumn(items);
            } else {
                setSecondColumn(items);
            };
        } else {
            const result = move(
                source,
                destination,
                source.droppableId,
                destination.droppableId,
            );

            // console.log('MOVING', result);

            setFirstColumn(result.container1);
            setSecondColumn(result.container2);
            setAnswers(result.container2)
        }
    };

    const text = "Metti in ordine logico le seguenti frasi.";

    // console.log("COERENZA", tests);

    return (
        <TestBody>
            <Question
                // dangerouslySetInnerHTML={{ __html: tests[id].question }}
                dangerouslySetInnerHTML={{ __html: text }}
            />

            <DnDContainer>
                <DragDropContext onDragEnd={onDragEnd}>

                    <QuestsContainer>
                        <Title>Da ordinare</Title>
                        <Droppable droppableId='container1'>
                            {(provided) => (
                                <Body
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {firstColumn.map((item, i) => {
                                        return (
                                            <Draggable key={JSON.stringify(item.idConsistency)} draggableId={JSON.stringify(item.idConsistency)} index={i}>
                                                {(provided) => (
                                                    <TheElement
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <h5>{item.title}</h5>
                                                        {item.content}
                                                    </TheElement>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </Body>
                            )}
                        </Droppable>
                    </QuestsContainer>

                    <QuestsContainer>
                        <Title>Risposta</Title>
                        <Droppable droppableId='container2'>
                            {(provided) => (
                                secondColumn.length > 0 ?
                                    <Body
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {secondColumn.map((item, i) => {
                                            return (
                                                <Draggable key={JSON.stringify(item.idConsistency)} draggableId={JSON.stringify(item.idConsistency)} index={i}>
                                                    {(provided) => (
                                                        <TheElement
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <h5>{item.title}</h5>
                                                            {item.content}
                                                        </TheElement>
                                                    )}
                                                </Draggable>
                                            )
                                        })

                                        }
                                        {provided.placeholder}
                                    </Body>
                                    :
                                    <Empty
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <i className="fa-solid fa-diagram-next"></i>
                                        Trascina
                                        {provided.placeholder}
                                    </Empty>
                            )}
                        </Droppable>
                    </QuestsContainer>

                </DragDropContext>
            </DnDContainer>
        </TestBody >
    );
}

export default TestCoerenza;