import React, { useState, useEffect } from 'react';

// ASSETS
import Loading from '../../../Assets/loading.gif';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringTest } from '../../../Store/Actions/testActions';

const FinishPage = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    font-size: 18px;
    letter-spacing: 2px;
    background: var(--background3);
    border: none;
    outline:none;
    text-decoration: none;
    backdrop-filter: blur(10px);
    pointer-events: auto;
        >h3{
            color: var(--text2);
            text-align: center;
            >span {
                color: var(--text1);
                font-size: 40px;
            }
        }
        >h5{
            position: absolute;
            top: 10px;
            right: 20px;
            color: var(--text3);
            >i{
                margin-left: 10px;
                font-size: 20px;
                cursor: pointer;
                color: var(--text3);
                &:hover{
                    color: var(--text2);
                }
            }
        }
        >i{
            font-size: 80px;
            color: var(--success);
            margin-bottom: 20px;
        }
        > img{
            width: 40px;
        }
`;


const WrongFinishTest = ({ testID }) => {
    const dispatch = useDispatch();
    const testResults = useSelector(({ test }) => test.bringTest);
    const [timeLeft, setTimeLeft] = useState(5);
    const [load, setLoad] = useState(true);

    const [correct, setCorrect] = useState(0);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    useEffect(() => {
        setTimeout(() => {
            setLoad(false)
        }, 2000)
    }, []);

    // useEffect(() => {
    //     dispatch(bringTest(testID));
    // }, [dispatch]);

    // const calcResult = () => {
    //     let correct = 0;
    //     if (testResults.quiz_answers !== null) {
    //         const asw = JSON.parse(testResults.quiz_answers);
    //         const qui = JSON.parse(testResults.quiz_questions);

    //         for (let i = 0; i < qui.length; i++) {
    //             const elem1 = asw[i];
    //             const elem2 = qui[i];

    //             if (Object.keys(asw[i]).length > 0) {
    //                 if (elem1.answer_picked === elem2.answerOk) {
    //                     correct = correct + 1
    //                 }
    //             }

    //             // if (elem1.answer_picked !== elem2.answerOk) {
    //             //     incorrect = incorrect + 1
    //             // }
    //         }
    //     };
    //     return correct;
    // };

    // console.log('RESULTS', JSON.parse(testResults.quiz_questions));
    // console.log('RESULTS', testResults);

    return (
        <FinishPage>
            <h5>chiudere in {timeLeft}
                <i className="fa-solid fa-xmark" onClick={() => window.close()}></i>
            </h5>

            {load ?
                <>
                    <img src={Loading} alt="Loading..."></img>
                    <h3>Inviare risposte...</h3>
                </>
                :
                <>
                    <i className="fa-regular fa-circle-check"></i>
                    <h3>Il test è stato completato con<br></br> successo!</h3>
                    {/* <h3>Hai risposto correttamente a <span>{calcResult()}</span> su <span>{JSON.parse(testResults.quiz_questions).length}</span> domande</h3> */}
                </>
            }
        </FinishPage>
    );
}

//Salvataggio completato

export default WrongFinishTest;
