import React, { useState, useEffect, useRef } from 'react';

// PACKAGES
import styled from 'styled-components';
import { io } from 'socket.io-client';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { findAllFriends, findAllUnreadMessages } from '../../../Store/Actions/conversationsActions';

// COMPONENTS
// import NavBar from '../PagesComponents/NavBar/NavBar';
import Chat from './MessengerComponents/Chat/Chat';
import Menu from './MessengerComponents/Menu/Menu';
import Online from './MessengerComponents/Online/Online';

// STYLE
import { WrapperRightSide } from '../../Pages/PagesShareStyle/PagesShareStyle';

const GeneralWrapper = styled.div`
    background: var(--background2);
`;

const MessengerWrapper = styled.div`
    width:100%;
    height:calc(100vh - 80px);
    margin-top:var(--navBarH);
    display: flex;
`;

const Left = styled.div`
    /* background: var(--background3); */
    flex: 3.5;
    padding: 10px 5px 10px 10px;
    border-right: 1px solid var(--border2);
    /* background: var(--background4); */
        @media (max-width: 800px) {
              display: none;
        };
`;

const Center = styled.div`
    /* background: var(--background2); */
    position: relative;
    flex: 5.5;
`;

const Right = styled.div`
    /* background: var(--background4); */
    overflow: auto;
    flex: 3;
    padding: 10px;
    border-left: 1px solid var(--border2);
        @media (max-width: 800px) {
              display: none;
        };
`;

const ResponsiveSidebar = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100vh - 160px);
    right: 0;
    top: 155px;
    background: var(--background4);
    overflow: auto;
    padding: 10px;
`;


const Messenger = () => {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);
    const friends = useSelector(({ other }) => other.friendsSidebar);
    const mess = useSelector(({ other }) => other.messSidebar);
    const onlineUsers = useSelector(({ conversations }) => conversations.setOnlineFriends);
    const socket = useSelector(({ conversations }) => conversations.socket);
    const allFriends = useSelector(({ conversations }) => conversations.allFriends);
    const [currentChat, setCurrentChat] = useState();
    const [curentUserChat, setCurentUserChat] = useState();
    const [allFriendsFiltered, setAllFriendsFiltered] = useState();

    const handleConversation = (e) => {
        // console.log(e);
        setCurrentChat(e.chat);
        setCurentUserChat(e.user)
    };

    useEffect(() => {
        dispatch(findAllFriends());
        dispatch(findAllUnreadMessages(user.idUser));
    }, [dispatch]);

    useEffect(() => {
        if (allFriends !== undefined) {
            const userCenter = JSON.parse(user.center);
            // console.log(userCenter);
            const filter = allFriends.filter(friend => friend.idUsers !== user.idUser);

            let centerFriends = []
            for (let i = 0; i < filter.length; i++) {
                const center = JSON.parse(filter[i].center);
                for (let j = 0; j < center.length; j++) {
                    for (let k = 0; k < userCenter.length; k++) {
                        if (center[j] === userCenter[k]) {
                            centerFriends.push(filter[i])
                        }
                    }
                }
            };
            const key = 'idUsers';
            const unique = [...new Map(centerFriends.map(item => [item[key], item])).values()]
            // console.log(unique);
            // setAllFriendsFiltered(filter);
            setAllFriendsFiltered(unique);
        }
    }, [allFriends]);

    // const [listen, setListen] = useState()
    // const hasRead = (data) => {
    //     // setListen(data);
    // };

    // console.log('ARIVALE', arrivalMsg, conv);
    // console.log('CHAT', currentChat);
    // console.log('MESS', mess);
    // console.log('CURENT AICI', curentUserChat);
    // console.log('CHAT', mess);

    return (
        <WrapperRightSide style={{
            padding: '80px 0px 0px 0px',
            background: '#fff'
        }}>
            {/* <NavBar /> */}
            <MessengerWrapper>
                <Left>
                    <Menu
                        handleConversation={handleConversation}
                        currentChat={currentChat}
                        onlineUsers={onlineUsers}
                        // listen={listen}
                        curentUserChat={curentUserChat}
                        // setListen={setListen}
                    />
                </Left >

                <Center>
                    <Chat
                        chat={currentChat}
                        socket={socket}
                        curentUserChat={curentUserChat}
                        // hasRead={hasRead}
                    />
                </Center >

                <Right>
                    <Online
                        onlineUsers={onlineUsers}
                        currentId={user?.idUser}
                        chat={currentChat}
                        handleConversation={handleConversation}
                        allFriends={allFriendsFiltered}
                    />
                </Right >

                {mess &&
                    <ResponsiveSidebar>
                        <Menu
                            handleConversation={handleConversation}
                            currentChat={currentChat}
                            onlineUsers={onlineUsers}
                            // listen={listen}
                            curentUserChat={curentUserChat}
                        />
                    </ResponsiveSidebar >
                }

                {friends &&
                    <ResponsiveSidebar>
                        <Online
                            onlineUsers={onlineUsers}
                            currentId={user?.idUser}
                            chat={currentChat}
                            handleConversation={handleConversation}
                            allFriends={allFriendsFiltered}
                        />
                    </ResponsiveSidebar>
                }
            </MessengerWrapper>
        </WrapperRightSide>
    );
}

export default Messenger;