import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// ASSETS
import finish from '../../../Assets/finish.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';
import DynamicInputs from './DynamicInputsForTPA';

// REDUX
import { useSelector } from 'react-redux';

const BarWrapper = styled.div`
    position: fixed;
    top: 160px;
    right: 0;
    width: 250px;
    background-color: var(--background4);
    /* margin: 10px 0px; */
    padding: 5px;
    height: calc(100vh - 480px);
    /* border-radius: var(--radius); */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    @media (max-width: 1100px) {
            transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(100%)'};
            z-index: 2;
        };
`;

const Title = styled.div`
   color:  var(--text2);
   font-size: 24px;
   font-weight: bold;
`;

const Nav = styled.div`
   color:  var(--text2);
   display: flex;
   cursor: default;
   margin-bottom: 10px;
        >span{
            color: var(--text4);
        }
`;

const BtnLink = styled.div`
    padding: 0px 5px;
    border:none;
    outline: none;
    color: ${props => props.sts === 'true' ? 'var(--acc-color)' : 'var(--text3)'};
    font-weight: ${props => props.sts === 'true' ? 'bold' : 'none'};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: .2s ease-in-out;
        /* &:hover{
            transition: .2s ease-in-out;
            color:  var(--acc-color);
            font-weight: bold;
        } */
        /* &:active{
                transform:translateY(2px);
        }   */
`;

const QuestionsContainer = styled.div`
    max-height: calc(100vh - 170px);
    width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    ::-webkit-scrollbar {
        width: 5px;
    };

    ::-webkit-scrollbar-thumb {
        background: #636363b5;
    };
`;

const Box = styled.div`
    height: 50px;
    width: 50px;
    /* color: var(--text2); */
    color: ${props =>
        (props.prev === 'correct' && 'var(--text5)') ||
        (props.prev === 'incorrect' && 'var(--text5)') ||
        (props.select === 'select' ? 'var(--text5)' : 'var(--text2)')
    };
    background: ${props =>
        (props.select === 'select' && 'var(--warning)') ||
        (props.prev === 'correct' && '#ffbc5dd1') ||
        (props.prev === 'incorrect' && 'var(--err)')
    };
    font-size: 25px;
    font-weight: bold;
    margin: 10px 5px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
        &:hover{
            /* background: var(--background3); */
            background: ${props => props.select === 'select' ? 'var(--warning)' : 'var(--background3)'};
        }
        &:focus{
            background: var(--warning);
        }
`;

const CardWrapper = styled.div`
    position: absolute;
    right: 5px;
    bottom: -230px;
    width: 240px;
    height: 200px;
    background-color: var(--background4);
    margin: 10px 0px;
    padding: 10px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    box-shadow: var(--shadow);
    overflow: hidden;
`;

const TitleCard = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
    height: 50px;
    color:  var(--text5);
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--acc-color);
    border-radius: var(--radius) var(--radius) 0 0;
`;

const PctWrapper = styled.div`
    position: relative;
    /* width: 100%; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    >span{
        position: absolute;
        right: 0;
        font-size: 30px;
        color: var(--text4);
    }
`;

// const InputCss = styled.input`
//     width: ${props => (props.expand === 0 || props.expand === 1) ?
//         '80px'
//         :
//         props.expand <= 2 ?
//             `calc(${props.expand} * 80px /1.4)`
//             :
//             `calc(${props.expand} * 80px /1.7)`};
//     height: 60px;
//     padding: 10px 40px 0 10px;
//     border: 1px solid var(--color6);
//     border-radius: var(--radius);
//     background: var(--color7);
//     color: var(--text1);
//     /* transition: var(--transition) ease; */
//     font-size: 50px;
//     font-weight: bold;
//     text-align: center;
//     margin-top: 80px;
//     /* box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important; */
//         &::placeholder { 
//             color: var(--text2);
//             font-size: 50px;
//             font-weight: bold;
//         }
//         &:hover{
//             border-color: var(--border);
//         }
//         &:focus{
//             outline: none;
//             ${CardWrapper} {
//                 box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
//             }
//             /* caret-color: rgba(0,0,0,0); */
//         }
//         &:-webkit-autofill{
//             -webkit-text-fill-color: var(--text1);
//             -webkit-box-shadow: 0 0 0px 1000px #fff inset;
//             transition: background-color 5000s ease-in-out 0s; 
//         }
//         &::-webkit-outer-spin-button,
//         &::-webkit-inner-spin-button {
//             -webkit-appearance: none;
//             margin: 0;
//         }
// `;

const InputCss = styled.input`
    width:100%;
    height: 60px;
    border: 1px solid var(--text4);
    border-radius: var(--radius);
    color: var(--text1);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 80px;
    /* box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important; */
        &::placeholder { 
            color: var(--text4);
            font-size: 30px;
            font-weight: bold;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline: none;
            ${CardWrapper} {
                box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
            }
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
`;


const AllQuestionsForVerificationForTPA = ({ id, setId, arr, obj, grade, setGrade, setArrOfComments, doneAllObj }) => {
    const profile = useSelector((state) => state.auth.user);
    const openRightSideBar = useSelector(({ other }) => other.handleRightSideBar);
    const [is, setIs] = useState();

    const howIsIt = (elem, que) => {
        // console.log("ELEM", elem);
        if (elem !== undefined && Object.keys(elem).length > 0) {
            let is;

            if (elem.answer_picked === que.answerOk) {
                is = 'correct';
            }

            if (elem.answer_picked !== que.answerOk) {
                is = 'incorrect';
            }

            if (elem.answer_picked === '' || que.answerOk === '') {
                is = 'empty';
            }
            return is;
        };
    };

    const setQue = (id, sts) => {
        setId(id);
    };

    // const setAGrade = (e) => {
    //     e.preventDefault();
    //     if (e.target.value <= 1000) {
    //         setGrade(e.target.value);
    //     } else {
    //         setGrade('1000');
    //     }
    // };

    const setAGrade = (e) => {
        e.preventDefault();
        setGrade(e.target.value);
    };

    // console.log('Grade',grade.length);
    // console.log('HAAAA',obj);
    // console.log('CE MM', inputFields);
    // console.log(obj);

    return (
        <BarWrapper open={openRightSideBar}>
            <Title>Domanda</Title>

            <QuestionsContainer>
                {obj.map((input, i) =>
                    <DynamicInputs
                        key={i}
                        id={id}
                        index={i}
                        setArrOfComments={setArrOfComments}
                        doneAllObj={doneAllObj}
                    />
                )}

                {/* {arr.map((que, i) =>
                    <Box
                        key={i}
                        onClick={() => setQue(i, que)}
                        select={id === i && 'select'}
                        // prev={id > i && 'prev'}
                        // prev={obj[i] !== undefined && Object.keys(obj[i]).length > 0 && 'prev'}
                        prev={howIsIt(obj[i], que)}
                    >
                        {i + 1}
                       {obj.length - 1 === i &&
                               <img src={finish} alt='img'></img>
                            }
                    </Box>
                    // {arr.map((que, i) =>
                    //     <Box
                    //         key={i}
                    //         onClick={() => setQue(i, que)}
                    //         select={id === i && 'select'}
                    //         prev={howIsIt(obj[i], que)}
                    //     >
                    //         {i + 1}
                    //     </Box>
                    // )}
                )} */}
            </QuestionsContainer>

            <CardWrapper>
                <TitleCard>
                    Assegna valutazione
                </TitleCard>

                <PctWrapper>
                    <InputCss
                        // placeholder="0"
                        placeholder="Giudizio"
                        id='nr'
                        type='text'
                        name='nr'
                        required
                        value={grade}
                        autoComplete='off'
                        min='1'
                        max='1000'
                        expand={grade.length}
                        valueType="int"
                        onChange={setAGrade}
                    ></InputCss>
                    {/* <span>pt.</span> */}
                </PctWrapper>
            </CardWrapper >
        </BarWrapper >
    );
};

export default AllQuestionsForVerificationForTPA;