import React, { useState, useEffect } from 'react';
// import { useHistory } from "react-router-dom";

// ASSETS
// import load from '../../../../Assets/loadingBtn.gif';

// COMPONENTS
import InputCss from '../../GeneralComponents/Inputs/Inputs';
// import CalendarJS from '../../Deposit/GeneralComponentsForDeposit/Calendar/Calendar';
import LoadingButton from '../../GeneralComponents/Button/LoadingButton';
import GoBackTitle from '../../GeneralComponents/GoBackButton/GoBackButton';

// PACKAGES
// import styled from 'styled-components';
import Select from 'react-select';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
// import { editService } from '../../../../Store/Actions/servicesActions';
// import { getAreas } from '../../../../Store/Actions/areasActions';
import { editUser } from '../../../Store/Actions/usersActions';

// STYLE
import {
    Form,
    // BtnWrapper,
    // Btn,
    // Title,
    // TitleWrapper,
    // GoBack,
    // Input,
    LabelCss,
    // ProdImg,
    colourStyles,
} from '../PagesShareStyle/DetailsShareStyle';
import { WrapperRightSide } from '../PagesShareStyle/PagesShareStyle';


const EditCourse = ({ details }) => {
    const dispatch = useDispatch();
    // let history = useHistory();
    // const services = useSelector(({ services }) => services.allServices);
    // const areas = useSelector(({ areas }) => areas.allAreas);
    const [more, setMore] = useState({
        title: details !== undefined && details.elem.user_role,
        firstName: details !== undefined && details.elem.user_name,
        lastName: details !== undefined && details.elem.user_surname,
        email: details !== undefined && details.elem.user_email,
    });
    const [stsBtn, setStsBtn] = useState(false);
    const [optArea, setOptArea] = useState();
    // const [selectedDayIs, setSelectedDayIs] = useState(details !== undefined && details.inv.service_duration);
    const [err, setErr] = useState(false);

    const inputsLeft = [
        // {
        //     label: 'Titolo',
        //     inputType: 'text',
        //     placeholder: 'Inserisci il titolo',
        //     name: 'title',
        //     value: more.title,
        // },
        {
            label: 'Nome',
            inputType: 'text',
            placeholder: 'Inserisci il tuo nome',
            name: 'firstName',
            value: more.firstName,
        },
        {
            label: 'Cognome',
            inputType: 'text',
            placeholder: 'Inserisci il tuo cognome',
            name: 'lastName',
            value: more.lastName,
        },
        {
            label: 'E-mail',
            inputType: 'email',
            placeholder: 'Inserisci il tuo indirizzo e-mail',
            name: 'email',
            value: more.email,

        },
    ];

    // console.log('EDIT', details);
    // const notifDash = useSelector((state) => state.customNotifHandler.handlerNotifDash);
    // useEffect(() => {
    //     if (notifDash !== undefined) {
    //         if (notifDash.status === 200) {
    //             // setStsBtn(false);
    //             setTimeout(() => {
    //                 history.goBack();
    //             }, 2000);
    //         } else {
    //             setStsBtn(false)
    //         }
    //     }
    // }, [notifDash, history]);

    useEffect(() => {
        // dispatch(getAreas());
    }, [dispatch]);

    // useEffect(() => {
    //     if (areas !== undefined) {
    //         let opt = [];
    //         for (let i = 0; i < areas.length; i++) {
    //             opt.push({ label: areas[i].name, value: areas[i].idArea })
    //         };
    //         setOptArea(opt);
    //     }
    // }, [areas]);

    // const defaultValue = () => {
    //     if (details !== undefined) {
    //         let opt = [];
    //         for (let i = 0; i < details.inv.Area.length; i++) {
    //             opt.push({ label: details.inv.Area[i].name, value: details.inv.Area[i].idArea })
    //         };
    //         return (opt);
    //     }
    // };

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setMore({
            ...more,
            [name]: value
        });
    };

    const submitForm = (e) => {
        e.preventDefault();
        setStsBtn(true);
        dispatch(editUser(more, details.elem.idUsers));
        setTimeout(() => {
            setStsBtn(false);
        }, 4000);
    };

    // console.log('EDIT TEACHER DETAILS', details);

    return (
        <WrapperRightSide>

            <GoBackTitle
                title='Modificare professore'
            />

            <Form onSubmit={submitForm}>
                <div>
                    {inputsLeft.map((inp, i) =>
                        <InputCss
                            key={i}
                            props={inp}
                            handleChange={handleChange}
                        />
                    )
                    }
                </div>
                {/* <CalendarJS
                    setSelectedDayIs={e => setSelectedDayIs(e)}
                    defaultValue={selectedDayIs}
                /> */}
                {/* <LabelCss htmlFor='select'>Indirizzi di studio</LabelCss>
                <Select
                    name='area'
                    isMulti={true}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    // defaultValue={defaultValue()}
                    // onChange={(e) => handleSelect(e)}
                    onChange={(e) =>
                        setMore({
                            ...more,
                            area: e
                        })
                    }
                    options={optArea}
                    styles={colourStyles}
                    placeholder={'Scegli indirizzi di studio'}
                /> */}
                <LoadingButton
                    stsBtn={stsBtn}
                    label={'Modifica professore'}
                    err={err}
                />

            </Form>

        </WrapperRightSide>
    )
};

export default EditCourse;