import styled from 'styled-components';

export const List = styled.li`
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    color: var(--text4);
`;

export const SVGIcon = styled.svg`
    width:25px;
    height:25px;
    margin-right:10px;
        & path {
                fill: var(--color4);
             } 
`;

export const Icon = styled.div`
    margin-right: 14px;
`;

