import React, { useEffect, useState } from 'react';

// PACKAGES
import styled from 'styled-components';

const InputWrapper = styled.label`
	display: block;
    width: 100%;
    /* margin-bottom: 50px; */
        @media (max-width: 775px) {
            width: 100%;
        };
`;

const SrcWrapper = styled.div`
   position:relative;
`;

const SearchWrapper = styled.div`
   position:absolute;
   left: 15px;
   top: 50%;
   transform: translate(0, -50%);
   color: var(--text2);
   cursor: pointer;
`;

const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 6px 6px 50px;
    border: none;
    border-radius: 100px;
    border: 1px solid var(--border);
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
            /* box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%); */
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;


const Search = ({
    flag,
    toSearch, setSearched
}) => {
    const [search, setSearch] = useState('');

    useEffect(() => {
        let thisSearch = [];
        if (flag === 'chats') {
            if (toSearch !== undefined) {
                if (search === '') {
                    thisSearch = toSearch;
                } else {
                    thisSearch = toSearch.filter((sr) =>
                        (sr.user_name !== null && sr.user_name.toLowerCase().includes(search.toLowerCase())) ||
                        (sr.user_surname !== null && sr.user_surname.toLowerCase().includes(search.toLowerCase()))
                    );
                };
                setSearched(thisSearch);
                // setCourse(thisSearch.sort((a, b) => a.name.localeCompare(b.name)));
            }
        };

    }, [search,
        flag,
        toSearch, setSearch,
    ])


    return (
        <InputWrapper>
            <SrcWrapper>
                <InputCss
                    placeholder="Ricerca..."
                    type='text'
                    name='text'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                ></InputCss>
                <SearchWrapper>
                    <i className="fas fa-search"></i>
                </SearchWrapper>
            </SrcWrapper>
        </InputWrapper>
    );
};

export default Search;