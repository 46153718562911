import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getCourseProgress } from '../../../Store/Actions/usersActions';

const CardWrapper = styled.div`
    position: relative;
    /* width: 100%; */
    height: 30vw;
    /* max-width: 600px;
    max-height: 600px; */
    background-color: var(--background4);
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin: 20px; */
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        @media (max-width: 600px) {
            height: 200px;
        };
`;

const Progress = styled.div`
    position: absolute;
    top: 10px;
    right: 15px;
    color:  var(--text2);
    font-size: 16px;
    font-weight: bold;
`;

const Icon = styled.div`
    >i{
        /* font-size: 80px; */
        font-size: 8vw;
        color: var(--text3);
    }
`;

const Title = styled.div`
   color:  var(--text2);
   font-size: 1.5vw;
   font-weight: bold;
   margin-top: 10px;
        @media (min-width: 600px) {
            font-size: 3vw;
        };
        @media (max-width: 600px) {
            font-size: 4vw;
        };
        @media (min-width: 1000px) {
            font-size: 1.5vw;
        };
`;

const Nav = styled.div`
   color:  var(--text2);
   display: flex;
        >span{
            color: var(--text4);
            cursor: default;
        }
        @media (max-width: 400px) {
            flex-direction: column;
        };
`;

const BtnLink = styled(Link)`
    border:none;
    outline: none;
    /* color: var(--text3); */
    color: var(--text5);
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var( --background1);
    border-radius: var(--radius);
    padding: 10px;
    width: 140px;
    margin: 5px;
        &:hover{
            /* transition: .2s ease-in-out; */
            /* color:  var(--acc-color); */
            /* color: var(--text3); */
            background:var(--acc-color);
            font-weight: bold;
        }
        &:active{
                transform:translateY(2px);
        }
        @media (max-width: 700px) {
            padding: 5px;
            width: 100px;
        };
        @media (max-width: 600px) {
            font-size: 3vw;
            padding: 10px;
            width: 140px;
        };
`;


const TypeCard = ({ ty, course, flag, idCheckUser, studDetails }) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    //const progress = useSelector((users) => users.userCourseProgress);

    // 'Preselettiva' && 'Prova Preselettiva';
    // 'TPA' && 'Test Psico Attitudinali';
    // 'TEMA' && 'Prova Scritta - Tema';
    // 'Orale' && 'Prova Orale';
    // 'Fisica' && 'Prova Fisica';

    // console.log("TY", ty, "COURSE", course);
    // console.log("PROFILE", profile)
    // console.log("idCheckUser", idCheckUser);
    // console.log("COURSE", course);
    // console.log("progress", progress);
    // console.log("Flag", flag);
    // console.log("STUD DET", studDetails);

    useEffect(() => {
        dispatch(getCourseProgress(idCheckUser !== undefined ? idCheckUser : profile.idUser, course.idCourse))
    }, [dispatch])

    return (
        <CardWrapper>
            {/* {(profile !== undefined && profile.accesLevel === 'admin') &&
                <Admin
                    content={content}
                />
            } */}

            {/* <Progress>30%</Progress> */}
            <Icon>{ty.icon}</Icon>
            <Title>{ty.name} </Title>

            {/* {ty.name === 'Preselettiva' && */}
            {ty.name === 'Prova Preselettiva' &&
                <Nav>
                    {course.visibility === 'Tutti' ?
                        <>
                            <BtnLink
                                to={{
                                    pathname: '/dashboard/learning',
                                    state: { course, title: ty.name, idCheckUser: idCheckUser, studDetails: studDetails }
                                }}
                            >
                                Learning
                                {/* <i className="fas fa-arrow-circle-right"></i> */}
                            </BtnLink>
                            {/* <span>|</span> */}
                            <BtnLink
                                to={{
                                    pathname: flag === 'prof' ? '/dashboard/prof-exercises' : '/dashboard/exercises',
                                    state: { course, title: ty.name, studDetails: studDetails }
                                }}
                            >
                                Esercizi
                                {/* <i className="fas fa-arrow-circle-right"></i> */}
                            </BtnLink>
                        </>
                        :
                        course.visibility === 'Esercizi' ?
                            <>
                                <BtnLink
                                    to={{
                                        pathname: flag === 'prof' ? '/dashboard/prof-exercises' : '/dashboard/exercises',
                                        //pathname: '/dashboard/exercises',
                                        state: { course, title: ty.name, studDetails: studDetails }
                                    }}
                                >
                                    Esercizi
                                    {/* <i className="fas fa-arrow-circle-right"></i> */}
                                </BtnLink>
                            </>
                            :
                            <>
                                <BtnLink
                                    to={{
                                        pathname: '/dashboard/learning',
                                        state: { course, title: ty.name }
                                    }}
                                >
                                    Learning
                                    {/* <i className="fas fa-arrow-circle-right"></i> */}
                                </BtnLink>
                            </>
                    }
                </Nav>
            }

            {ty.name === 'Test Psico Attitudinali' &&
                <Nav>
                    {course.visibility === 'Tutti' &&
                        <>
                            <BtnLink
                                to={{
                                    pathname: '/dashboard/tpa-learning',
                                    // pathname: '/dashboard/learning/tpa/:type',
                                    state: { course, title: ty.name, idCheckUser: idCheckUser, studDetails: studDetails }
                                }}
                            >
                                Learning
                                {/* <i className="fas fa-arrow-circle-right"></i> */}
                            </BtnLink>
                            {/* <span>|</span> */}
                            <BtnLink
                                to={{
                                    pathname: flag === 'prof' ? '/dashboard/tpa-pre-ex-prof' : '/dashboard/tpa-pre-exercises',
                                    state: { course, title: ty.name, studDetails: studDetails }
                                }}
                            >
                                Esercizi
                                {/* <i className="fas fa-arrow-circle-right"></i> */}
                            </BtnLink>
                        </>
                    }
                </Nav>
            }

            {ty.name === 'Prova Orale' &&
                <Nav>
                    {course.visibility === 'Tutti' ?
                        <>
                            <BtnLink
                                to={{
                                    pathname: '/dashboard/orale-learning',
                                    state: { course, title: ty.name, idCheckUser: idCheckUser, studDetails: studDetails }
                                }}
                            >
                                Learning
                            </BtnLink>

                        </>
                        :
                        course.visibility === 'Apprendimento' ?

                            <>
                                <BtnLink
                                    to={{
                                        pathname: '/dashboard/orale-learning',
                                        state: { course, title: ty.name, idCheckUser: idCheckUser, studDetails: studDetails }
                                    }}
                                >
                                    Learning
                                    {/* <i className="fas fa-arrow-circle-right"></i> */}
                                </BtnLink>
                            </> : <></>
                    }
                </Nav>
            }

            {ty.name === 'Prova Fisica' &&
                <Nav>
                    {course.visibility === 'Tutti' ?
                        <>
                            <BtnLink
                                to={{
                                    pathname: '/dashboard/physical-learning',
                                    state: { course, title: ty.name, idCheckUser: idCheckUser, studDetails: studDetails }
                                }}
                            >
                                Learning
                            </BtnLink>

                        </>
                        :
                        course.visibility === 'Apprendimento' ?
                            <>
                                <BtnLink
                                    to={{
                                        pathname: '/dashboard/physical-learning',
                                        state: { course, title: ty.name, idCheckUser: idCheckUser, studDetails: studDetails }
                                    }}
                                >
                                    Learning
                                    {/* <i className="fas fa-arrow-circle-right"></i> */}
                                </BtnLink>
                            </> : <></>
                    }
                </Nav>
            }

            {ty.name === 'Prova Scritta - Tema' &&
                <Nav>
                    
                    {course.visibility === 'Tutti' &&
                        <>
                            <BtnLink
                                to={{
                                    pathname: '/dashboard/tema-learning',
                                    state: { course, title: ty.name, idCheckUser: idCheckUser, studDetails: studDetails }
                                }}
                            >
                                Learning
                            </BtnLink>
                            {/* <span>|</span> */}
                            <BtnLink
                                to={{
                                    pathname: flag === 'prof' ? '/dashboard/tema-pre-ex-prof' : '/dashboard/tema-pre-exercises',
                                    state: { course, title: ty.name, studDetails: studDetails }
                                }}
                            >
                                Esercizi
                            </BtnLink>
                        </>
                    }
                </Nav>
            }

        </CardWrapper >
    );
};

export default TypeCard;


{/* <BtnLink
to={{
    pathname: '/dashboard/learning',
    state: { course, title: ty.name }
}}
>
Learning
</BtnLink>
<span>|</span>
<BtnLink
to={{
    pathname: '/dashboard/exercises',
    state: { course, title: ty.name }
}}
>
Esercizi
</BtnLink> */}
