import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// ASSETS
import finish from '../../../Assets/finish.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';

// REDUX
import { useSelector } from 'react-redux';

const Textarea = styled.textarea`
    width: 230px;
    height: 250px;
    padding: 6px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    margin-top:10px;
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
    resize: none;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border: 1px solid var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        ::-webkit-scrollbar {
            width: 5px;
        };
`;

const LabelCss = styled.label`
    display:flex;
    align-items: center;
    color: var(--text1);
    margin: 20px 10px 0;
`;

const Btn = styled.button`
    position: absolute;
    top: 25px;
    right: 0px;
	width: 20px;
    height: 20px;
    border: none;
    border-radius: 30px;
    /* margin: 10px; */
    background-color: var(--background4);
    /* border: 2px solid var(--err); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-weight: bold; */
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    padding: 0;
        >i{
            color: var(--text3);
        }
        &:hover{
            background-color: var(--background3);
            box-shadow: var(--shadow);
            >i{
                color: var(--err);
            }  
        }
`;

const AddBtn = styled.button`
    width: 100%;
    height: 50px;
    margin: 3px;
    color: var(--color2);
    font-weight: bold;
    font-size: 16px;
    border-radius: var(--radius);
    margin-top: 20px;
    margin-bottom:20px;
    letter-spacing:2px;
    cursor: pointer;
    transition: var(--transition) ease;
    background: var(--background4);
    border: none;
    outline:none;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
`;

const Wrapper = styled.div`
    padding: 3px;
`;


const DynamicInputs = ({ message, setMessage, comment, setComment, inputFields, setInputFields }) => {
    const profile = useSelector((state) => state.auth.user);

    // const [sts, setSts] = useState();

    // const [vax, setVax] = useState(false);

    // const [inputFields, setInputFields] = useState([]);

    // const handleChange = evt => {
    //     const name = evt.target.name;
    //     const value = evt.target.value;
    //     setMore({
    //         ...more,
    //         [name]: value
    //     });
    // };

    // const handleInputChange = (index, event, flag) => {
    //     const values = [...inputFields];
    //     if (flag !== 'date') {
    //         values[index].amount = Number(event.target.value)
    //         setInputFields(values);
    //         setVax(!vax)
    //     } else {
    //         values[index].date = event
    //         setInputFields(values);
    //     }
    // };

    const handleInputChange = (index, e) => {
        const values = [...inputFields];
        values[index].message = e.target.value;
        setInputFields(values);
    };

    // useEffect(() => {
    //     if (dues && dues !== '' && dues.length > 0) {
    //         console.log("INTRA?????")

    //         const values = [...inputFields];
    //         for (let i = 0; i < dues.length; i++) {
    //             // values.push({ date: dues[i].date, amount: (dues[i].amount * 1.00).toFixed(2) });
    //             // values.push({ date: dues[i].date, amount: dues[i].amount });
    //             // values.push({
    //             //     date: dues[i].date,
    //             //     amount: dues[i].amount,
    //             //     idAccount: dues[i].idAccount,
    //             //     isPayed: dues[i].isPayed,
    //             //     invNum: dues[i].invNum,
    //             //     invDate: dues[i].invDate
    //             // });

    //             values.push({
    //                 ans: dues[i].ans,
    //                 url: dues[i].url,
    //             });


    //             //console.log("values", values);
    //         }

    //         setInputFields(values);
    //     }
    // }, [])

    //console.log("AICI", dues)


    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ message: '' });
        // values.push('');
        setInputFields(values);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };


    return (
        <>
            {inputFields.map((inputField, index) => (
                <div
                    key={`${inputField}~${index}`}
                    style={{ display: 'flex', position: 'relative' }} >
                    <div style={{ position: 'relative' }}>
                        <LabelCss htmlFor="message">{index + 1}.</LabelCss>
                        <Textarea
                            placeholder="Tu comentario aquí..."
                            type="textarea"
                            name='message'
                            required='required'
                            value={inputFields[index].message}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        <Btn
                            className="btn btn-link"
                            type="button"
                            onClick={() => handleRemoveFields(index)}
                        >
                            <i className="fa-regular fa-trash-can"></i>
                        </Btn>
                    </div>
                </div>
            ))
            }

            <AddBtn
                className="btn btn-link"
                type="button"
                onClick={() => handleAddFields()}
            >
                <i className="fas fa-plus"></i> Aggiungi commento
            </AddBtn>
        </>
    )
};

export default DynamicInputs;