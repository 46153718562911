import React, { useState } from 'react';
import { useHistory } from 'react-router';

// COMPONENTS
import AdminNav from '../AuthComponents/AuthNav';
// import AuthFooter from '../AuthComponents/AuthFooter';
import Checkbox from '../AuthComponents/Checkbox';

// ASSETS
import spin from '../../Assets/loadingBtn2.gif';
import avatar from '../../Assets/avatar.png';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, setPsw } from '../../Store/Actions/authActions';

// SHARE STYLE
import {
    AuthWrapper,
    FormWrapper,
    H2,
    // Hr,
    InputWrapper,
    PswWrapper,
    EyeWrapper,
    // LabelCss,
    InputCss,
    Label,
    CheckTxt,
    BtnWrapper,
    Btn,
    AvatarWrapper,
    Avatar,
    LinkCss,
    LinksDiv,
    Err,
    IconWrapper
} from '../ShareStyle/ShareStyle';


const SetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector((state) => state.auth.setPswLoading);
    const [showPassword, setShowPassword] = useState(true);
    const [access, setAccess] = useState({
        email: '',
        psw: '',
        confirmPsw: '',
    });
    const [checked, setChecked] = useState(false);
    const [matchErr, setMatchErr] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleCheckboxChange = event => {
        setChecked(event.target.checked)
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (access.psw !== access.confirmPsw) {
            setMatchErr(true);
            setTimeout(() => {
                setMatchErr(false);
            }, 4000);
        } else {
            dispatch(setPsw(access));
            setTimeout(() => {
                history.push('/')
            }, 3000);
        }
    };

    return (
        <AuthWrapper>
            <AdminNav />

            <FormWrapper onSubmit={submitForm}>
                <H2>Impostare password</H2>

                <IconWrapper>
                    <i className="fa-solid fa-fingerprint"></i>
                </IconWrapper>

                <InputWrapper>
                    {/* <LabelCss htmlFor="email">Utente</LabelCss> */}
                    <InputCss
                        placeholder="Inserisci il tuo e-mail"
                        type="text"
                        name='email'
                        required
                        value={access.email}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                email: e.target.value
                            })
                        }
                    >
                    </InputCss>
                    <i className="fas fa-user"></i>
                </InputWrapper>

                <PswWrapper>
                    {/* <LabelCss htmlFor='psw'>Password</LabelCss> */}
                    <InputCss
                        placeholder="Imposta una password"
                        type={showPassword ? 'password' : 'text'}
                        name='password'
                        required
                        value={access.psw}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                psw: e.target.value
                            })
                        }
                    ></InputCss>
                    <EyeWrapper onClick={() => handleShowPassword()}>
                        {showPassword ?
                            <i className="far fa-eye-slash"></i>
                            :
                            <i className="far fa-eye"></i>
                        }
                    </EyeWrapper>
                    <i className="fas fa-key"></i>
                </PswWrapper>

                <PswWrapper>
                    {/* <LabelCss htmlFor='psw'>Password</LabelCss> */}
                    <InputCss
                        placeholder="Conferma password"
                        type={showPassword ? 'password' : 'text'}
                        name='password'
                        required
                        value={access.confirmPsw}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                confirmPsw: e.target.value
                            })
                        }
                    ></InputCss>
                    <EyeWrapper onClick={() => handleShowPassword()}>
                        {showPassword ?
                            <i className="far fa-eye-slash"></i>
                            :
                            <i className="far fa-eye"></i>
                        }
                    </EyeWrapper>
                    <i className="fas fa-key"></i>
                </PswWrapper>


                <BtnWrapper >
                    {loading ?
                        <Btn style={{ margin: '40px 0px 0px' }} disabled>
                            <img src={spin} alt='loading'></img>
                            Loading...
                        </Btn>
                        :
                        <Btn style={{ margin: '40px 0px 0px' }} type='submit'>Impostare</Btn>
                    }
                </BtnWrapper>

                <LinksDiv>
                    {/* <LinkCss to='/forgotPsw'>Imposta una password</LinkCss > */}
                    {matchErr &&
                        <Err>Le passwords non <span>corrispondono!</span></Err>
                    }
                </LinksDiv>
            </FormWrapper>

            {/* <AuthFooter /> */}
        </AuthWrapper>
    );
}
export default SetPassword;