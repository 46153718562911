import React from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector } from 'react-redux';


const TestBody = styled.div`
    padding-bottom: 20px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const BigQuestion = styled.div`
    width: 80%;
    max-height: 250px;
    overflow: scroll;
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 20px;
    background: var(--background4);
    padding: 20px 10px;
    border-radius: var(--radius);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        ::-webkit-scrollbar {
            width: 4px; 
        };
        @media (max-width: 1650px) {
            width: 95%;
        };  
`;

const Question = styled.div`
    /* justify-content: center;
    text-align: center; */
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

const Letter = styled.div`
    color:  var(--text2);
    font-size: 24px;
    font-weight: bold;
    display: flex;
    padding: 20px;
    background-color: var(--background3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Answers = styled.div`
    width: 100%;
    color:  var(--text2);
    font-size: 20px;
    display: flex;
    padding: 20px;
    justify-content: center;
    text-align: center;
    background-color: var(--background4);
    >img{
        max-width: 400px;
        max-height: 400px;
        pointer-events: none;
    }
`;

const AnswerWrapper = styled.div`
    width: 80%;
    justify-content: center;
    text-align: center;
    display: flex-end;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    background: var(--background4);
    cursor: pointer;
    ${Letter}{
            background: ${props => props.selected === 'selected' && 'var(--warning)'}!important;
            color: ${props => props.selected === 'selected' && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => props.selected === 'selected' && '#f8940655'}!important;
        }
        &:hover ${Letter}{ 
            background: #99979791;
            color: var(--text5);
        }
        &:hover ${Answers}{ 
            background: #bbbbbb55;
        } 
`;

const AnswerWrapperCompleted = styled.div`
    width: 30%;
    display: flex;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    background: var(--background4);
    cursor: pointer;
        ${Letter}{
            background: ${props => (props.selected === 'selected' && 'var(--warning)') ||
        (props.selected === 'this' && 'var(--success)') ||
        (props.selected === 'correct' && 'var(--success)') ||
        (props.selected === 'incorrect' && 'var(--err)')
    }!important;
            color: ${props => props.selected !== undefined && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => (props.selected === 'selected' && '#f8940655') ||
        (props.selected === 'this' && '#5fd07555') ||
        (props.selected === 'correct' && '#ffbc5dd1') ||
        (props.selected === 'incorrect' && '#e91e6355')
    }!important;
        }
        @media (max-width: 800px) {
            width: 95%;
        };  
`;

const CommentWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
        >div{
            width: 100%
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
`;

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z'];


const QuestionBool = ({ tests, id, setAnswers, obj, opt, flag, doneAllObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const commObj = doneAllObj !== undefined && doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : [];

    const filterSelections = (i) => {
        if (obj[id] !== undefined && Object.keys(obj[id]).length > 0) {

            if (obj[id].aswIndex === i) {
                return 'correct';
            }
        }
    };

    // console.log('OBJ', obj);
    // console.log('doneAllObj', doneAllObj);

    return (
        <TestBody>
            <Question
                dangerouslySetInnerHTML={{ __html: tests[id].question }}
            />
            {opt.map((ans, i) =>
                <AnswerWrapperCompleted
                    key={i}
                    onClick={(e) => setAnswers(e, i, letters)}
                    //selected={obj[id] !== undefined && obj[id].aswIndex === i && 'selected'}
                    selected={filterSelections(i)}

                >
                    <Answers id={i}
                        dangerouslySetInnerHTML={{ __html: ans.value }}
                    />

                </AnswerWrapperCompleted>
            )}

            {flag === 'verification' &&
                whoAmI.role === 'STUDENTE' &&
                commObj.length > 0 &&
                (Object.keys(commObj[id]).length) > 0 &&
                <CommentWrapper>
                    {commObj[id].map((com, i) =>
                        <div key={i}>
                            {i + 1}. <br></br>
                            {com.message}
                        </div>
                    )
                    }
                </CommentWrapper>
            }
        </TestBody>
    );
}

export default QuestionBool;