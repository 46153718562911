import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// PACKAGES
// import Select from 'react-select';

// COMPONENTS
import DeleteModal from '../../GeneralComponents/Modals/DeleteModal';
// import ToggleSwitchButton from '../../../GeneralComponents/Button/ToggleSwitchButton';

// REDUX
// import { useDispatch } from 'react-redux';
// import { getKits } from '../../../../Store/Actions/kitsActions';

import {
    Td,
    // ExpandTableRates,
    ExpandBtn,
    TrDiv,
    // LinkDiv,
    PopUp2,
    EditWrapper,
    Edit,
    TdHeader,
    // PopUpTd,
    // colourStyles
} from '../PagesShareStyle/PagesShareStyle';

// const type = [
//     { label: 'Ritiro in sede', value: 'Ritiro in sede' },
//     { label: 'Spedizione', value: 'Spedizione' },
// ]


const UsersTable = ({ elem, i }) => {
    // const dispatch = useDispatch();
    // const [rate, setRate] = useState(false);
    // const kits = useSelector(({ kits }) => kits.allKits);
    // const [kit, setKit] = useState();
    const [viewArea, setViewArea] = useState(false);

    // useEffect(() => {
    //     dispatch(getKits());
    // }, [dispatch]);

    // const formatData2 = (data) => {
    //     // console.log(data);
    //     let format;
    //     if (data !== '') {
    //         const d = data.split('/');
    //         format = d[1] + '/' + d[0] + '/' + d[2];
    //     } else {
    //         format = ''
    //     }
    //     return format
    // };
    // console.log('order', inv)
    // console.log('kit', kit);

    // useEffect(() => {
    //     if (kits !== undefined) {
    //         const filterKit = kits.filter(k => k.idKit === inv.what_bought * 1);
    //         setKit(filterKit[0])
    //     }
    // }, [inv, kits]);

    // const setStatus = (e) => {
    //     // dispatch(setSts(e))
    // };

    const handleViewMore = () => {
        setViewArea(!viewArea);
    };

    // console.log(inv);

    return (
        <>
            < tr >
                <Td>{elem.user_name}</Td>
                <Td>{elem.user_surname}</Td>
                <Td>{elem.user_email}</Td>
                <Td>{elem.user_role}</Td>

                {/* <Td>
                    {inv.Area.length > 0 &&
                        <ExpandBtn
                            onClick={handleViewMore}
                        // style={{ marginLeft: '10px' }}
                        >
                            {!viewArea ?
                                'di più'
                                :
                                ' meno'
                            }
                            {!viewArea ?
                                <i className="fas fa-chevron-down"></i>
                                :
                                <i className="fas fa-chevron-up"></i>
                            }
                        </ExpandBtn>
                    }
                </Td> */}
                <Td >
                    <div style={{ display: 'flex' }}>
                        <EditWrapper>
                            <Edit
                                to={{
                                    pathname: '/dashboard/users/editUser',
                                    state: { elem, flag: 'course' }
                                }}
                            >
                                <i className="fas fa-pen"></i>
                            </Edit>
                            <PopUp2>Modificare</PopUp2>
                        </EditWrapper>

                        <EditWrapper>
                            <Edit
                                to={{
                                    pathname: '/dashboard/users/results',
                                    state: { elem, flag: 'mat' }
                                }}
                            >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                            </Edit>
                            <PopUp2>Di più</PopUp2>
                        </EditWrapper>

                        {/* <DeleteModal
                            flag='course'
                            id={elem.idCourse}
                            icon={<i className="far fa-trash-alt"></i>}
                            title={'Rimuovi'}
                            body={`Stai per rimuovere ordina ${elem.name} dalla tua azienda.\nSei sicuro di voler procedere?`}
                        /> */}
                    </div>
                </Td>
            </tr >

            {viewArea &&
                <tr>
                    <Td colSpan='12' style={{ padding: '0px' }}>
                        <div style={{ padding: '10px 0px', backgroundColor: 'var(--text1)' }}>
                            <TdHeader>
                                <h5>Nome servizi</h5>
                                <h5>Descrizione</h5>

                            </TdHeader>
                            {elem !== undefined &&
                                elem.Area.map((line, l) =>
                                    // <ExpandTableRates
                                    //     key={l}
                                    // >
                                    <TrDiv key={l}>
                                        <h5>{line.name}</h5>
                                        <h5>{line.desc}</h5>
                                    </TrDiv>
                                    // </ExpandTableRates >
                                )
                            }
                        </div>
                    </Td>
                    <td style={{ padding: '0', border: 'none' }}></td>
                </tr>
            }
        </>
    )
}

export default UsersTable;

