import React from 'react';
import { Link } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';

// ASSETS
import logo from '../../Assets/logo-nissolino.png';


const NavWrapper = styled.div`
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    background:#f5f5f5cf;
    //background:#E7E7E7;

    backdrop-filter: var(--blur);
`;

const Nav = styled.div`
    position:relative;
    height: 100px;
    max-width:1200px;
    margin: auto;
    display:flex;
    justify-content:center;
    align-items: center;
`;

const Logo = styled.img`
    width: 250px;
    margin:5px;
`;

const AdminNav = () => {
    return (
        <NavWrapper>
            <Nav>
                <Link to='/'><Logo src={logo} alt='logo'></Logo></Link>
            </Nav>
        </NavWrapper>
    );
}

export default AdminNav;