const initState = {

};

const usersReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ALL_USERS':
            return {
                ...state,
                allUsers: action.payload,
            };

        case 'ALL_STUDENTS':
            return {
                ...state,
                allStudents: action.payload,
            };
        case 'ALL_PROFS_AND_STUDS':
            return {
                ...state,
                allProfsAndStuds: action.payload,
            };

        case 'PROGRESS_USER_COURSE':
            return {
                ...state,
                userCourseProgress: action.payload,
            };

        case 'TEACHER_ACTIVITY':
            return {
                ...state,
                teacherActivity: action.payload,
            };

        case 'ALL_CENTER_TEACHERS':
            return {
                ...state,
                allCenterTeachers: action.payload,
            };

        default:
            return state;
    }
};

export default usersReducer;