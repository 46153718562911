import React, { useState, useEffect } from 'react';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Title from '../SectionsTitle';
import OnlineUserCard from './OnlineUserCard';
import OfflineUserCard from './OfflineUserCard';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { findOnlineFriends, createConversations } from '../../../../../Store/Actions/conversationsActions';
import { openFriendsSidebar } from '../../../../../Store/Actions/otherActions';

const GeneralWrapper = styled.div`
    /* background: var(--background4); */
    >h5{
        color: var(--text4);
        padding-top: 10px;
        display: flex;
        align-items: center;
        >span{
            display: inline-block;
            text-align: center;
            min-width: 22px;
            height: 22px;
            border-radius: 22px;
            margin-left: 10px;
            background-color: var(--background3);
            color: var(--text3);
            padding: 3px;
            font-size: 12px;
        }
    }
`;


const Online = ({ onlineUsers, currentId, chat, handleConversation, allFriends }) => {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);
    const onlineFriends = useSelector(({ conversations }) => conversations.onlineFriends);
    const conv = useSelector(({ conversations }) => conversations.conv);
    const [filtered, setFiltered] = useState();

    useEffect(() => {
        const filterOnlineUsers = onlineUsers?.filter(usr => usr.userId !== user.idUser);
        setFiltered(filterOnlineUsers)
    }, [onlineUsers]);

    useEffect(() => {
        if (filtered !== undefined) {
            dispatch(findOnlineFriends(filtered));
        }
    }, [filtered]);

    const handleNewConversation = (e) => {
        // console.log('PROPS',e);
        // console.log('CONV', conv);
        const conversation = conv.find(con =>
            con.from === e.idUsers.toString() ||
            con.to === e.idUsers.toString()
        );
        // console.log('FIND CONV', conversation);
        if (conversation) {
            handleConversation({ chat: { convId: conversation.idConversations }, user: e })
        } else {
            dispatch(createConversations(user.idUser, e.idUsers))
        }
    };

    const friendsSidebar = () => {
        dispatch(openFriendsSidebar(false))
    };

    // console.log('Online friends', conv);
    // console.log('filtered', onlineFriends);

    return (
        <GeneralWrapper>
            <Title title='Amici' />
            {onlineFriends?.length > 0 &&
                <h5>Online
                    <span>{onlineFriends?.length}</span>
                </h5>
            }

            {onlineFriends !== undefined &&
                onlineFriends.map((online, i) =>
                    <div key={i} onClick={() => { handleNewConversation(online); friendsSidebar() }}>
                        <OnlineUserCard
                            key={i}
                            online={online}
                        />
                    </div>
                )
            }

            <h5>Offline
                <span>{allFriends?.length >= 0 && allFriends?.length}</span>
            </h5>

            {allFriends !== undefined &&
                allFriends.map((offline, i) =>
                    <div key={i} onClick={() => { handleNewConversation(offline); friendsSidebar() }}>
                        <OfflineUserCard
                            key={i}
                            offline={offline}
                        />
                    </div>
                )
            }
        </GeneralWrapper>
    );
}

export default Online;