import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// STYLE
// import {
//     NotifRow,
//     Type
// } from './StyleNavBar';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'timeago.js';
import * as timeago from 'timeago.js';

const NotifLink = styled(Link)`
    text-decoration: none;
`;

const CardTitle = styled.div`
    width: 100%;
    min-height: 30px;
    border-bottom: 1px solid #00000017;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--text1);
    font-weight: bold;
    margin-bottom: 10px;
`;

const NotifRow = styled.div`
    position: relative;
    width: 100%;
    min-height: 50px;
    text-align: left;
    padding: 5px 10px 20px 10px;
    margin: 10px 0;
    background: var(--background2);
    border-radius: var(--radius);
    border-left: ${props =>
        (props.status === 'urgent' && '4px solid var(--err)') ||
        (props.status === 'noturgent' && '4px solid var(--warning)')
    };
        >h5{
            color: var(--text2);
            font-weight: bold;
            font-size: 12px;
        }
        >h6{
            position: absolute;
            right: 10px;
            color: var(--text4);
        }
`;


const Reminder = ({ notif, open, setOpen }) => {
    const [sts, setSts] = useState();
    const [tp, setTp] = useState();
    const profile = useSelector(({ auth }) => auth.user);
    const notifDate = new Date(notif.createdAt);
    const notifDateTransform = notifDate.getDate();

    let locale = function (number, index, totalSec) {
        // number: the time ago / time in number;
        // index: the index of array below;
        // totalSec: total seconds between date to be formatted and today's date;
        return [
            //   ['just now', 'right now'],
            //   ['%s seconds ago', 'in %s seconds'],
            //   ['1 minute ago', 'in 1 minute'],
            //   ['%s minutes ago', 'in %s minutes'],
            //   ['1 hour ago', 'in 1 hour'],
            //   ['%s hours ago', 'in %s hours'],
            //   ['1 day ago', 'in 1 day'],
            //   ['%s days ago', 'in %s days'],
            //   ['1 week ago', 'in 1 week'],
            //   ['%s weeks ago', 'in %s weeks'],
            //   ['1 month ago', 'in 1 month'],
            //   ['%s months ago', 'in %s months'],
            //   ['1 year ago', 'in 1 year'],
            //   ['%s years ago', 'in %s years']
            ['proprio adesso', 'proprio ora'],
            ['%s secondi fa', 'tra %s secondi'],
            ['1 minuto fa', 'tra 1 minuto'],
            ['%s minuti fa', 'tra %s minuti'],
            ['1 ora fa', 'tra 1 ora'],
            ['%s ore fa', 'tra %s ore'],
            ['1 giorno fa', 'tra 1 giorno'],
            ['%s giorni fa', 'tra %s giorni'],
            ['1 settimana fa', 'tra 1 settimana'],
            ['%s settimane fa', 'tra %s settimane'],
            ['1 mese fa', 'tra 1 mese'],
            ['%s mesi fa', 'tra %s mesi'],
            ['1 anno fa', 'tra 1 anno'],
            ['%s anni fa', 'tra %s anni']
        ][index];
    };
    timeago.register('it_IT', locale);

    const formatData = (data) => {
        console.log("data", data)
        let d = data.split('/');
        let format = ''
        if (d.length > 1) {
            format = d[1] + '/' + d[0] + '/' + d[2];
        } else {
            d = data.split('-');
            format = d[2] + '/' + d[1] + '/' + d[0]
            //format
        }

        return format
    };

    // console.log('Notif', notif);

    return (
        <NotifLink to="/dashboard/notifications" onClick={() => setOpen(!open)}>
            <NotifRow status='urgent' >
                {/* <CardTitle>Il tuo lavoro è stato corretto</CardTitle> */}
                <CardTitle>{profile.role === 'STUDENTE' ?
                    'Il tuo lavoro è stato corretto' : 'È stato fatto un nuovo test'}
                </CardTitle>
                <h5>Concorso: {JSON.parse(notif.Course).course_name}</h5>
                <h5>Tipo di esercizio: {notif.TestType.toUpperCase().replace('-', ' ')}</h5>
                <h5>{profile.role === 'STUDENTE' ?
                    'Tutor:' : 'Studente:'} {notif.Author.firstName} {notif.Author.lastName}
                </h5>
                <h6>{format(notif.createdAt, 'it_IT')}</h6>
            </NotifRow>
        </NotifLink>
    );
}

export default Reminder;