import { useState, useEffect } from 'react';

// PACKAGES
import styled from 'styled-components';

// CALENDAR
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// REDUX
import { useSelector } from 'react-redux';

const CalendarContainer = styled.div`
  width:100%;
  min-height:150px;
  margin-top: 20px;
  /* background: var(--background1); */
        >h1{
            color:var(--text2);
            text-align: center;
             >span{
                 font-size:18px;
                 /* margin-left:5px; */
             }
        }
        >h5{
            color:var(--acc-color);
            text-align: center;
        }
        .react-calendar {
            min-height: 315px;
            background: var(--background4);
            border: none;
            font-family: var(--fontFamily);
            border-radius: 0 0 var(--radius) var(--radius);
        }
        .react-calendar__navigation {
            margin:0;
            .react-calendar__navigation__arrow {
                font-size: 25px;
                color:var(--text2);
            }

            .react-calendar__navigation__label {
                font-size: 14px;
                color:var(--text2);
            }
        }

        .react-calendar__tile {
            height: 40px;
            width: 40px;
            border-radius: 40px;
            padding:0;
            color:var(--text1);
        }

        .react-calendar__tile--now{
            background:var(--warning);
            color:var(--text5)!important;
                &:hover,
                &:focus{
                    background:var(--warning);
                }
        }
        

        .react-calendar__month-view__days__day {
            font-size: 12px;
        }

        .react-calendar__month-view__weekdays__weekday {
            font-size: 12px;
            color:var(--text2);
            abbr[title] {
                text-decoration: none!important;
            } 
        }

        .react-calendar__month-view__days__day--weekend{
            color: var(--err);
        }

        .react-calendar__month-view__days__day--neighboringMonth{
            color:var(--text3);
        }

        .react-calendar__tile--active {
            background: var(--acc-color);
            color:var(--text5);
        }

        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
            background:  var(--acc-color);
        }
`;

const Header = styled.div`
  width:100%;
  height:50px;
  background: var(--acc-color);
  display: flex;
  align-items: center;
  justify-content:center;
  color:var(--text5);
  border-radius: var(--radius) var(--radius) 0 0;
`;


const CalendarJs = ({ setSelectedDayIs }) => {
    const openCalendar = useSelector(({ other }) => other.handleCalendar);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        if (openCalendar === false) {
            setDate(new Date());

            const selectDay = new Date();
            let dd = selectDay.getDate();
            let mm = selectDay.getMonth() + 1;
            let yyyy = selectDay.getFullYear();
            const select = mm + '/' + dd + '/' + yyyy;
            setSelectedDayIs(new Date(select).getTime())
        }
    }, [openCalendar, setSelectedDayIs])

    const handleCalendar = (nextValue) => {
        // console.log(nextValue)
        setDate(nextValue);
        setSelectedDayIs(nextValue.getTime());
    };


    return (
        <CalendarContainer>
            <Header>Calendario</Header>
            <Calendar
                locale='it-IT'
                // selectRange={true}
                onChange={(e) => handleCalendar(e)}
                value={date}
            />
        </CalendarContainer>
    );
}

export default CalendarJs;
