import axios from 'axios';
axios.defaults.withCredentials = true;

export const getAllOralSubjects = (idCourse, idUser) => (dispatch, getState) => {

    const body = {
        idCourse: idCourse,
        idUser: idUser,
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllOralSubjects`, body)
        .then((res) => {
            // console.log("ALL_SUBJECTS", res.data);
            dispatch({
                type: 'GET_ALL_ORAL_SUBJECTS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};



export const getAllOralQuestionnaires = (idOralLesson, idUser) => (dispatch, getState) => {

    // console.log("LESSON", idOralLesson, " USER ", idUser);
    const body = {
        idOralLesson: idOralLesson,
        idUser: idUser
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllOralQuestionnaires`, body)
        .then((res) => {
            // console.log("ALL_QUEST", res.data);
            dispatch({
                type: 'GET_ALL_ORAL_QUESTIONNAIRES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const downloadFile = (doc) => (dispatch, getState) => {
    const body = {
        file: doc.url
    };

    const config = {
        responseType: 'blob'
    };

    const fileName = doc.name
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/downloadOralFile`, body, config)
        .then((res) => {
            // console.log("DOWNLOAD_FILE", res.data);
            const data = {
                name: fileName,
                data: res.data
            }
            dispatch({
                type: 'DOWNLOAD_FILE_ORALE',
                payload: data,
            });
            setTimeout(() => {
                dispatch({
                    type: 'DOWNLOAD_FILE_ORALE',
                    payload: undefined,
                })
            }, 2000);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const sendOralQuestAnswers = (obj, questInfo) => (dispatch, getState) => {
    const profile = getState().auth.user;

    // console.log("astaaaa", questInfo);
    const body = {
        answers: JSON.stringify(obj),
        // userID: '1',
        userID: profile.idUser,
        idOralQuest: questInfo.idOralQuestionnaire,
        name: questInfo.name,
        point: questInfo.point,
        allQuestions: JSON.stringify(questInfo.OralQuestionaryQuizzs)
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/sendOralQuestAnswers`, body)
        .then((res) => {
            // console.log("SAVE_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const videoOraleIsDone = (idOralLesson, idOralSubject, idCourse, time) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        userID: profile.idUser,
        idOralLesson: idOralLesson,
        idOralSubject: idOralSubject,
        idCourse: idCourse,
        videoTime: time
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/videoOraleIsDone`, body)
        .then((res) => {
            // console.log("SAVE_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};


