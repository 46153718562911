import React, { useState, useEffect } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsForTemaVerification from '../../../../GeneralComponents/AllQuestions/AllQuestionsForTemaView';

// REDUX
import { useSelector } from 'react-redux';

import {
    Wrapper,
    CommentWrapper,
    // GradeWrapper,
    // LabelCss,
    QueContainer,
    // ResContainer,
    Section,
} from './Style';


const TestBody = styled.div`
    padding-bottom: 80px;
        >img{
            max-width: 400px;
            max-height: 400px;
        }
`;

const RegistriView = ({ oneTema }) => {
    // console.log('TEMA VIEW', oneTema);
    const [id, setId] = useState(0);
    const [text, setText] = useState('');;

    useEffect(() => {
        const obj = JSON.parse(oneTema.answers);
        if (obj.length > 0) {
            if (Object.keys(obj[id]).length > 0) {
                setText(obj[id].asw)
            } else {
                setText('');
            }
        }
    }, [id, oneTema]);


    return (
        <Wrapper style={{ width: 'calc(100% - 240px)' }}>
            <AllQuestionsForTemaVerification
                id={id}
                setId={setId}
                arr={oneTema !== undefined && JSON.parse(oneTema.questions)}
                obj={oneTema !== undefined && JSON.parse(oneTema.answers)}
                flag="tema"
            />

            <div style={{width: '100%'}}>
                {oneTema !== undefined &&
                    <QueContainer
                        dangerouslySetInnerHTML={{ __html: JSON.parse(oneTema.questions)[id].body }}
                    />
                }

                <Section>
                    {text !== '' ?
                        text
                        :
                        'Non hai scritto alcuna risposta'
                    }
                </Section>

            </div>

            <CommentWrapper>
                {(oneTema.comments !== null &&
                    Object.keys(JSON.parse(oneTema.comments)[id]).length )> 0 &&
                    JSON.parse(oneTema.comments)[id].map((comment, i) =>
                        <div key={i}>
                            <span>{i + 1}.</span>
                            {comment.message}
                        </div>
                    )
                }
            </CommentWrapper>
        </Wrapper>
    );
}

export default RegistriView;