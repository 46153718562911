import React, { useEffect, useState } from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
// import { useSelector, useDispatch } from 'react-redux';


const TestBody = styled.div`
    padding-bottom: 20px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const BigQuestion = styled.div`
    width: 80%;
    max-height: 250px;
    overflow: scroll;
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 20px;
    background: var(--background4);
    padding: 20px 10px;
    border-radius: var(--radius);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        ::-webkit-scrollbar {
            width: 4px; 
        };
        @media (max-width: 1650px) {
            width: 95%;
        };  
`;

const Question = styled.div`
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
`;

const Letter = styled.div`
    color:  var(--text2);
    font-size: 24px;
    font-weight: bold;
    display: flex;
    padding: 20px;
    background-color: var(--background3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Answers = styled.div`
    width: 100%;
    color: var(--text2);
    font-size: 20px;
    display: flex;
    padding: 20px;
    background-color: var(--background4);
    >img{
        max-width: 400px;
        max-height: 400px;
        pointer-events: none;
    }
`;

// const AnswerWrapperBlank = styled.div`
//     width: 80%;
//     display: flex;
//     overflow: hidden;
//     border-radius: var(--radius);
//     margin-top: 20px;
//     cursor: pointer;
//         ${Letter}{
//             background: ${props => (props.selected === 'selected' && 'var(--warning)') ||
//         (props.selected === 'this' && 'var(--success)') ||
//         (props.selected === 'correct' && 'var(--success)') ||
//         (props.selected === 'incorrect' && 'var(--err)')
//     // (props.selected === 'unselected' && null) 
//     }!important;
            
//             color: ${props => props.selected !== undefined && 'var(--text5)'}!important;
//         }
//         ${Answers}{ 
//             /* background: ${props => props.selected === 'selected' && '#f8940655'}!important; */
//             background: ${props => (props.selected === 'selected' && '#f8940655') ||
//             (props.selected === 'this' && '#5fd07555') ||
//         (props.selected === 'correct' && '#5fd07555') ||
//         (props.selected === 'incorrect' && '#e91e6355')
//     // (props.selected === 'unselected' && null) 
//     }!important;
//         }
//         &:hover ${Letter}{ 
//             background: #99979791!important;
//             color: var(--text5);
//         }
//         &:hover ${Answers}{ 
//             background: #bbbbbb55!important;
//         } 
// `;

const AnswerWrapperBlank = styled.div`
   width: 80%;
    display: flex;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    background: var(--background4);
    cursor: pointer;
    ${Letter}{
            background: ${props => props.selected === 'selected' && 'var(--warning)'}!important;
            color: ${props => props.selected === 'selected' && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => props.selected === 'selected' && '#f8940655'}!important;
        }
        &:hover ${Letter}{ 
            background: #99979791;
            color: var(--text5);
        }
        &:hover ${Answers}{ 
            background: #bbbbbb55;
        }  
      
`;

const AnswerWrapperCompleted = styled.div`
    width: 80%;
    display: flex;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    /* background: var(--background4); */
    cursor: pointer;
        ${Letter}{
            background: ${props => (props.selected === 'selected' && 'var(--warning)') ||
            (props.selected === 'this' && 'var(--success)') ||
            (props.selected === 'correct' && 'var(--success)') ||
            (props.selected === 'incorrect' && 'var(--err)')
    }!important;
            color: ${props => props.selected !== undefined && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => (props.selected === 'selected' && '#f8940655') ||
            (props.selected === 'this' && '#5fd07555') ||
            (props.selected === 'correct' && '#5fd07555') ||
            (props.selected === 'incorrect' && '#e91e6355')
    }!important;
        }
`;


const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z'];


const QuestionTestVerification = ({ tests, id, setAnswers, obj}) => {
    const [res, setRes] = useState([]);

    const filterSelections = (i) => {
        if (obj[id] !== undefined && Object.keys(obj[id]).length > 0) {
            // console.log(obj[id].Answer_picked, obj[id].Answer_OK)

            if (obj[id].answer_picked === i) {
                if (obj[id].answer_picked === tests[id].answerOk) {
                    return 'correct';
                   }
        
                   if (obj[id].answer_picked !== tests[id].answerOk) {
                    return 'incorrect';
                   }
            } else if (tests[id].answerOk === i) {
                return 'this'
            }
        }

        // if (obj[id] !== undefined && Object.keys(obj[id]).length > 0) {
        //     // console.log(obj[id].Answer_picked, obj[id].Answer_OK)

        //     if (obj[id].Answer_picked === i) {
        //         if (obj[id].Answer_picked === obj[id].Answer_OK) {
        //             return 'correct';
        //            }
        
        //            if (obj[id].Answer_picked !== obj[id].Answer_OK) {
        //             return 'incorrect';
        //            }
        //     } else if (obj[id].Answer_OK === i) {
        //         return 'this'
        //     }
        // }
    };


    return (
        <TestBody>
            {/* {tests[id].bigQuestion !== null && <BigQuestion>{tests[id].bigQuestion}</BigQuestion>} */}
            {tests[id].bigQuestion !== null && <BigQuestion dangerouslySetInnerHTML={{ __html: tests[id].bigQuestion }}/>}


            {/* <Question>{tests[id].question}</Question> */}
            <Question
                dangerouslySetInnerHTML={{ __html: tests[id].question }}
            />
            {(tests[id].url_question !== undefined && tests[id].url_question !== ' '&& tests[id].url_question !== '' )
                && <img src={tests[id].url_question} alt='img'></img>
            }
            
            {JSON.parse(tests[id].answers).map((ans, i) =>
                // Object.keys(obj[id]).length > 0 ?
                <AnswerWrapperCompleted
                    key={i}
                    selected={filterSelections(letters[i])}
                >
                    <Letter>{letters[i]}</Letter>
                    {ans.url !== ' '&& ans.url !== ''?
                        <Answers id={i}><img src={ans.url} alt='img'></img></Answers>          
                        :
                        <Answers id={i}
                            dangerouslySetInnerHTML={{ __html: ans.ans }}
                        />
                    }
                </AnswerWrapperCompleted>
                // :
                // <AnswerWrapperBlank
                //     key={i}
                //     onClick={(e) => setAnswers(e, i, letters)}
                //     selected={obj[id] !== undefined && obj[id].aswIndex === i && 'selected'}
                // >
                //     <Letter >{letters[i]}</Letter>
                //     {ans.url !== ''?
                //         // <img src={ans.url} alt='img'></img>   
                //         <Answers id={i}><img src={ans.url} alt='img'></img></Answers>
                //         :
                //         <Answers id={i}>{ans.ans}</Answers>
                //     }
                // </AnswerWrapperBlank>
            )}
        </TestBody>
        
        // <TestBody>
        //     <Question>{tests[id].question}</Question>
        //     {tests[id].img !== undefined && <img src={tests[id].img} alt='img'></img>}
        //     {tests[id].answers.map((ans, i) =>
        //         <AnswerWrapper
        //             key={i}
        //             onClick={(e) => setAnswers(e, i)}
        //             // selected={console.log(makeASelection(i))}
        //             selected={makeASelection(letters[i])}
        //         >
        //             <Letter>{letters[i]}</Letter>
        //             <Answers id={i}>{ans}</Answers>
        //         </AnswerWrapper>
        //     )}
        // </TestBody>
    );
}

export default QuestionTestVerification;

// useEffect(() => {
//     if (obj[id] !== undefined && Object.keys(obj[id]).length > 0) {
//         let createObj = [];
//         for (let i = 0; i < tests[id].answers.length; i++) {
//             createObj.push({})
//         }
//         setRes(createObj);
//     }
// }, [tests, id, obj])

// if (obj[id] !== undefined && Object.keys(obj[id]).length > 0) {
    // const asw = obj[id].aswIndex.sort((a, b) => a - b);
    // const correctAsw = obj[id].correctAsw.sort((a, b) => a - b);
    // console.log(asw, correctAsw);


// const makeASelection = (i) => {
//     if (obj[id] !== undefined && Object.keys(obj[id]).length > 0) {
//         const asw = obj[id].aswIndex.sort((a, b) => a - b);
//         const correctAsw = obj[id].correctAsw.sort((a, b) => a - b);
//         console.log(asw, correctAsw);
        
//         // let da= [];
//         // for (let j = 0; j < correctAsw.length; j++) {
//         //     for (let k = 0; k < asw.length; k++) {
//         //         da.push([ correctAsw[j], asw[k]]);
//         //     }
//         // }
//         // console.log(da, i);

//         for (let j = 0; j < correctAsw.length; j++) {
//             for (let k = 0; k < asw.length; k++) {

//                 if (correctAsw[j] === i) {
//                     if (res.length > 0) {
//                         const value = [...res];
//                         value[i].type = 'this'
//                         value[i].index = i
//                     }
//                 }

//                 if (asw[k] === i && asw[k] === correctAsw[j]) {
//                     // console.log(correctAsw[j] === asw[k])    
//                 // if (correctAsw[j] === asw[k]) {
                    
//                     if (res.length > 0) {
//                         const value = [...res];
//                         value[i].type = 'correct'
//                         value[i].index = i
//                     }
//                 }

//                 if (asw[k] === i && asw[k] !== asw[k]) {
//                     console.log('aici', i)
//                     // res.push({ type: 'incorrect', index: i })
//                     if (res.length > 0) {
//                         const value = [...res];
//                         value[i].type = 'incorrect'
//                         value[i].index = i
//                     }
//                 }




//                 // if (asw[k] === i) {
//                 //     console.log(asw[k], correctAsw[j], i)
//                 //     if (asw[k] === correctAsw[j]) {
//                 //         if (res.length > 0) {
//                 //             const value = [...res];
//                 //             value[i].type = 'correct'
//                 //             value[i].index = i
//                 //         }
//                 //     }
//                 //     if (asw[k] !== correctAsw[j]) {
//                 //         // res.push({ type: 'incorrect', index: i })
//                 //         if (res.length > 0) {
//                 //             const value = [...res];
//                 //             value[i].type = 'incorrect'
//                 //             value[i].index = i
//                 //         }
//                 //     }
//                 //     if ((asw[k] === correctAsw[j]) === false && (asw[k] !== correctAsw[j]) === false) {
//                 //         if (res.length > 0) {
//                 //             const value = [...res];
//                 //             value[i].type = 'selected'
//                 //             value[i].index = i
//                 //         }
//                 //     }
//                 // };

//             }
//         }


//         for (let l = 0; l < res.length; l++) {
//             console.log('hai', res, res[l].index, i);
//             if (res[l].index === undefined) {
//                 return res[l].type
//             }
//             if (res[l].index === i) {
//                 // console.log(res[l].type)
//                 return res[l].type
//             }
//         };

//     }
// };