import { newNotifications } from './notificationsActions';
import axios from 'axios';
axios.defaults.withCredentials = true;

export const getAllTemaSubjects = (idCourse, idUser) => (dispatch, getState) => {

    const body = {
        idCourse: idCourse,
        idUser: idUser,
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllTemaSubjects`, body)
        .then((res) => {
            // console.log("GET_ALL_TEMA_SUBJECTS", res.data);
            dispatch({
                type: 'GET_ALL_TEMA_SUBJECTS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllTemaQuestionnaires = (idTemaLesson, idUser) => (dispatch, getState) => {

    // console.log("LESSON", idTemaLesson, " USER ", idUser);
    const body = {
        idTemaLesson: idTemaLesson,
        idUser: idUser
    }
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllTemaQuestionnaires`, body)
        .then((res) => {
            // console.log("ALL_TEMA_QUEST", res.data);
            dispatch({
                type: 'GET_ALL_TEMA_QUESTIONNAIRES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const downloadFile = (doc) => (dispatch, getState) => {
    const body = {
        file: doc.url
    };

    const config = {
        responseType: 'blob'
    };

    const fileName = doc.name
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/downloadTemaFile`, body, config)
        .then((res) => {
            // console.log("DOWNLOAD_FILE", res.data);
            const data = {
                name: fileName,
                data: res.data
            }
            dispatch({
                type: 'DOWNLOAD_FILE_TEMA',
                payload: data,
            });
            setTimeout(() => {
                dispatch({
                    type: 'DOWNLOAD_FILE_TEMA',
                    payload: undefined,
                })
            }, 2000);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const sendTemaQuestAnswers = (obj, questInfo) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        answers: JSON.stringify(obj),
        // userID: '1',
        userID: profile.idUser,
        idTemaQuest: questInfo.idTemaQuestionnaire,
        name: questInfo.name,
        point: questInfo.point,
        allQuestions: JSON.stringify(questInfo.TemaQuestionaryQuizzs)
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/sendTemaQuestAnswers`, body)
        .then((res) => {
            // console.log("SAVE_TEMA_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const videoTemaIsDone = (idTemaLesson, idTemaSubject, idCourse, time) => (dispatch, getState) => {
    const profile = getState().auth.user;

    const body = {
        userID: profile.idUser,
        idTemaLesson: idTemaLesson,
        idTemaSubject: idTemaSubject,
        idCourse: idCourse,
        videoTime: time
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/videoTemaIsDone`, body)
        .then((res) => {
            console.log("SAVE_QUEST_ANSWERS", res.data);
            dispatch({
                type: 'SCORE_QUEST',
                payload: res.data.score,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};



export const getAllTrace = () => (dispatch, getState) => {

    const body = {
        // idUser: id
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllTrace`, body)
        .then((res) => {
            //console.log("ALL_TRACE", res.data);
            dispatch({
                type: 'ALL_TRACE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllScaletta = () => (dispatch, getState) => {

    const profile = getState().auth.user;
    const body = {
        idUser: profile.idUser
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllScaletta`, body)
        .then((res) => {
            //console.log("ALL_TRACE", res.data);
            dispatch({
                type: 'ALL_SCALETTA',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllRevisione = (id) => (dispatch, getState) => {
    const profile = getState().auth.user;
    const body = {
        idUser: profile.idUser
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllRevisione`, body)
        .then((res) => {
            //console.log("ALL_REVISIONE", res.data);
            dispatch({
                type: 'ALL_REVISIONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllAvvio = (id) => (dispatch, getState) => {
    const profile = getState().auth.user;
    const body = {
        idUser: profile.idUser
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllAvvio`, body)
        .then((res) => {
            //console.log("ALL_AVVIO", res.data);
            dispatch({
                type: 'ALL_AVVIO',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getOneTraceExercise = (id) => (dispatch, getState) => {

    const body = {
        testId: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getOneTraceExercise`, body)
        .then((res) => {
            // console.log("ONE_TRACE_EXERCICES", res.data);
            dispatch({
                // type: 'ONE_TRACE_EXERCICES',
                type: 'ONE_EXERCICES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getOneScalettaExercise = (id) => (dispatch, getState) => {

    console.log("ASTAAAAA", id)
    const body = {
        testId: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getOneScalettaExercise`, body)
        .then((res) => {
            // console.log("ONE_SCALETTA_EXERCICES", res.data);
            dispatch({
                // type: 'ONE_SCALETTA_EXERCICES',
                type: 'ONE_EXERCICES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllCoerenzaExercise = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllCoerenzaExercise`)
        .then((res) => {
            // console.log("ALL_COERENZA_EXERCICES", res.data);
            dispatch({
                // type: 'ALL_COERENZA_EXERCICES',
                type: 'ONE_EXERCICES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getOneRevisioneExercise = (id) => (dispatch, getState) => {

    const body = {
        testId: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getOneRevisioneExercise`, body)
        .then((res) => {
            // console.log("ONE_REVISIONE_EXERCICES", res.data);
            dispatch({
                // type: 'ONE_REVISIONE_EXERCICES',
                type: 'ONE_EXERCICES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllRegistriExercise = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllRegistriExercise`)
        .then((res) => {
            // console.log("ALL_REGISTRI_EXERCICES", res.data);
            dispatch({
                // type: 'ALL_REGISTRI_EXERCICES',
                type: 'ONE_EXERCICES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllAvvioExercise = (id) => (dispatch, getState) => {

    const body = {
        testId: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllAvvioExercise`, body)
        .then((res) => {
            // console.log("ALL_AVVIO_EXERCICES", res.data);
            dispatch({
                // type: 'ALL_AVVIO_EXERCICES',
                type: 'ONE_EXERCICES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};


export const saveTemaTest = (param, quests, ans, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType,
    verif) => (dispatch, getState) => {
        const profile = getState().auth.user;

        let config;
        let body;

        if (ans[0].file === '' || ans[0].file === undefined) {
            config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            body = {
                type: param,
                questions: quests,
                answers: ans,
                idUser: idUser,
                idCourse: idCourse
            };
        } else {
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            body = new FormData()
            body.append('file', ans[0].file)
            body.append('questions', JSON.stringify(quests))
            body.append('type', param)
            body.append('idUser', idUser)
            body.append('idCourse', idCourse)
            body.append('flag', 'file')
        }

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveTemaTest`, body, config)
            .then((res) => {
                // console.log("ALL_TEMA_EXERCICES", res.data);
                if (verif === ans.length) {
                    dispatch(newNotifications(idUser,
                        allCenterTeachers,
                        whoAmI,
                        aCourse,
                        res.data,
                        tpaType
                    ));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

export const saveTraceTest = (tests, obj, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType, flag) => (dispatch, getState) => {

        const body = {
            idTest: tests.idTraceAnalysis,
            obj: obj,
            idUser: idUser,
            idCourse: idCourse
        }
        console.log("WHO AM I", whoAmI)

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveTraceTest`, body)
            .then((res) => {
                //console.log(SAVE_TRACE", res.data);
                // dispatch({
                //     type: 'ALL_TEMA_DONE',
                //     payload: res.data,
                // });
                // dispatch(getAllTemaTestDone(idUser));
                if (whoAmI.role === 'STUDENTE') {
                    dispatch(newNotifications(
                        idUser,
                        allCenterTeachers,
                        whoAmI,
                        aCourse,
                        res.data,
                        tpaType,
                        flag,
                        tests,
                    ));
                }

            })
            .catch((err) => {
                console.log(err);
            });
    };

export const saveScalettaTest = (tests, obj, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType, flag) => (dispatch, getState) => {

        console.log("TESTSSSSS", tests)
        const body = {
            idTest: tests.idTraceAnalysisDone,
            obj: obj,
            idUser: idUser,
            idCourse: idCourse
        }

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveScalettaTest`, body)
            .then((res) => {
                //console.log(SAVE_TRACE", res.data);
                // dispatch({
                //     type: 'ALL_TEMA_DONE',
                //     payload: res.data,
                // });
                // dispatch(getAllTemaTestDone(idUser));
                dispatch(newNotifications(idUser,
                    allCenterTeachers,
                    whoAmI,
                    aCourse,
                    res.data,
                    tpaType,
                    flag,
                    tests,
                ));
            })
            .catch((err) => {
                console.log(err);
            });
    };

export const saveCoerenzaTest = (tests, obj, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType, flag) => (dispatch, getState) => {

        const body = {
            // idTest: tests.idConsistency,
            obj: obj,
            idUser: idUser,
            idCourse: idCourse
        };

        console.log('AAAAAAAAAAAAAAAAAAAAA', tests);

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveCoerenzaTest`, body)
            .then((res) => {
                //console.log(SAVE_TRACE", res.data);
                // dispatch({
                //     type: 'ALL_TEMA_DONE',
                //     payload: res.data,
                // });
                // dispatch(getAllTemaTestDone(idUser));
                dispatch(newNotifications(idUser,
                    allCenterTeachers,
                    whoAmI,
                    aCourse,
                    res.data,
                    tpaType,
                    flag,
                    tests,
                ));
            })
            .catch((err) => {
                console.log(err);
            });
    };

export const saveRevisioneTest = (tests, obj, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType, flag) => (dispatch, getState) => {

        const body = {
            idTest: tests[0].idTextProcessing,
            obj: obj,
            idUser: idUser,
            idCourse: idCourse
        };

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveRevisioneTest`, body)
            .then((res) => {
                //console.log(SAVE_TRACE", res.data);
                // dispatch({
                //     type: 'ALL_TEMA_DONE',
                //     payload: res.data,
                // });
                // dispatch(getAllTemaTestDone(idUser));
                dispatch(newNotifications(idUser,
                    allCenterTeachers,
                    whoAmI,
                    aCourse,
                    res.data,
                    tpaType,
                    flag,
                    tests,
                ));
            })
            .catch((err) => {
                console.log(err);
            });
    };

export const saveRegistriTest = (tests, obj, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType, flag) => (dispatch, getState) => {

        const body = {
            que: tests,
            obj: obj,
            idUser: idUser,
            idCourse: idCourse
        };

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveRegistriTest`, body)
            .then((res) => {
                //console.log(SAVE_REGISTRI", res.data);
                // dispatch(getAllTemaTestDone(idUser));
                dispatch(newNotifications(idUser,
                    allCenterTeachers,
                    whoAmI,
                    aCourse,
                    res.data,
                    tpaType,
                    flag,
                    tests,
                ));
            })
            .catch((err) => {
                console.log(err);
            });
    };

export const saveAvvioTest = (tests, obj, idUser, idCourse,
    allCenterTeachers,
    whoAmI,
    aCourse,
    tpaType, flag) => (dispatch, getState) => {

        const body = {
            que: tests,
            obj: obj,
            idUser: idUser,
            idCourse: idCourse
        };

        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/saveAvvioTest`, body)
            .then((res) => {
                //console.log(SAVE_AVVIO", res.data);
                // dispatch(getAllTemaTestDone(idUser));
                dispatch(newNotifications(idUser,
                    allCenterTeachers,
                    whoAmI,
                    aCourse,
                    res.data,
                    tpaType,
                    flag
                ));
            })
            .catch((err) => {
                console.log(err);
            });
    };

export const getAllTemaTestDone = (id, course) => (dispatch, getState) => {

    console.log(course)
    const body = {
        idUser: id,
        idCourse: course.idCourse
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllTemaTestDone`, body)
        .then((res) => {
            //console.log("ALL_TEMA_DONE", res.data);
            dispatch({
                type: 'ALL_TEMA_DONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllTemaTestDoneBy = (id, details) => (dispatch, getState) => {

    const body = {
        idUser: id,
        type: details.type,
        svolgere: details.svolgere,
    };

    dispatch({
        type: 'ONE_TEMA_DONE',
        payload: undefined,
    });

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllTemaTestDoneBy`, body)
        .then((res) => {
            //console.log("ALL_TEMA_DONE_BY", res.data);
            dispatch({
                type: 'ALL_TEMA_DONE_BY',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getSelectedTemaTestDone = (id, details) => (dispatch, getState) => {

    console.log(id, details);
    const body = {
        idTema: id,
        type: details.type,
        svolgere: details.svolgere,
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getSelectedTemaTestDone`, body)
        .then((res) => {
            dispatch({
                type: 'ONE_TEMA_DONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const saveTemaVerification = (obj, grade, idTema, inputFields, whoAmI, flag, svolgere) => (dispatch, getState) => {

    const body = {
        grade: grade,
        idTema: (flag === svolgere[0] && idTema.elem.idTraceAnalysisDone) ||
            (flag === svolgere[1] && idTema.elem.idTextProcessingDone) ||
            (flag === svolgere[2] && idTema.elem.idConsistencyDone) ||
            (flag === svolgere[3] && idTema.elem.idRevision) ||
            (flag === svolgere[4] && idTema.elem.idLaguageRegistersDone),
        inputFields: inputFields,
        idProf: whoAmI,
        flag: flag,
        svolgere
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveTemaVerification`, body)
        .then((res) => {
            // console.log("TPA_VERIFICATION", res.data);
            // dispatch({
            //     type: 'TPA_VERIFICATION',
            //     payload: res.data,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const saveTemaAvvioVerification = (obj, grade, idTema, inputFields, whoAmI, flag, svolgere, objAvvio) => (dispatch, getState) => {

    const body = {
        grade: grade,
        idTema: idTema.elem.idStartWritingDone,
        inputFields: inputFields,
        idProf: whoAmI,
        flag: flag,
        svolgere,
        profCorrection: objAvvio,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/saveTemaVerification`, body)
        .then((res) => {
            // console.log("TPA_VERIFICATION", res.data);
            // dispatch({
            //     type: 'TPA_VERIFICATION',
            //     payload: res.data,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const clearOnTemaFormNotif = () => (dispatch, getState) => {
    dispatch({
        type: 'ONE_TEMA_DONE',
        payload: undefined,
    });
};