import React from 'react';

// PACKAGES
import styled from 'styled-components';
import { format } from 'timeago.js';
import * as timeago from 'timeago.js';

// ASSETS
import user from '../../../../../Assets/avatar.png';

// COMPONENTS

// REDUX
// import { useDispatch } from 'react-redux';

const SideWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: ${props => props.own === 'true' ? 'flex-end' : 'flex-start'};
`;

const GeneralWrapper = styled.div`
    /* position: sticky;
    top:0;
    background: var(--background2); */
    padding: 10px;
    display: flex;
    align-items: flex-start;
        >img{
            width: 32px;
            height: 32px;
            border-radius: var(--radius);
            margin-right: 10px;
            object-fit: cover;
            cursor: pointer;
        }
        >h4{
            color: var(--text1);
            font-weight: bold;
            cursor: pointer;
        }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
        >h6{
            color: var(--text3);
            text-align: ${props => props.own === 'true' ? 'end' : 'start'};
        }
`;

const Text = styled.div`
    max-width:300px;
    background-color: ${props => props.own === 'true' ? '#0084ff' : '#fff'};
    /* background-color: ${props => props.own === 'true' ? '#0084ff' : '#e4e6eb'}; */
    padding: 10px;
    border-radius: ${props => props.own === 'true' ? '20px 20px 0px 20px' : '20px 20px 20px 0px'};
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%), 0 8px 9px -5px hsl(0deg 0% 8% / 6%);    
        >p{
            letter-spacing: 0px;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.2em;
            color: ${props => props.own === 'true' ? 'var(--text5)' : 'var(--text1)'};
        }
`;

let locale = function (number, index, totalSec) {
    // number: the time ago / time in number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
        //   ['just now', 'right now'],
        //   ['%s seconds ago', 'in %s seconds'],
        //   ['1 minute ago', 'in 1 minute'],
        //   ['%s minutes ago', 'in %s minutes'],
        //   ['1 hour ago', 'in 1 hour'],
        //   ['%s hours ago', 'in %s hours'],
        //   ['1 day ago', 'in 1 day'],
        //   ['%s days ago', 'in %s days'],
        //   ['1 week ago', 'in 1 week'],
        //   ['%s weeks ago', 'in %s weeks'],
        //   ['1 month ago', 'in 1 month'],
        //   ['%s months ago', 'in %s months'],
        //   ['1 year ago', 'in 1 year'],
        //   ['%s years ago', 'in %s years']
        ['proprio adesso', 'proprio ora'],
        ['%s secondi fa', 'tra %s secondi'],
        ['1 minuto fa', 'tra 1 minuto'],
        ['%s minuti fa', 'tra %s minuti'],
        ['1 ora fa', 'tra 1 ora'],
        ['%s ore fa', 'tra %s ore'],
        ['1 giorno fa', 'tra 1 giorno'],
        ['%s giorni fa', 'tra %s giorni'],
        ['1 settimana fa', 'tra 1 settimana'],
        ['%s settimane fa', 'tra %s settimane'],
        ['1 mese fa', 'tra 1 mese'],
        ['%s mesi fa', 'tra %s mesi'],
        ['1 anno fa', 'tra 1 anno'],
        ['%s anni fa', 'tra %s anni']
    ][index];
};
timeago.register('it_IT', locale);


const MessageCard = ({ message, own }) => {
    // const dispatch = useDispatch();

    return (
        <SideWrapper own={own}>
            <GeneralWrapper >
                {/* {own === 'false' &&
                    <img src={user} alt='img'></img>
                } */}
                <TextWrapper own={own}>
                    <Text own={own}>
                        <p>{message.Text}</p>
                    </Text >
                    <h6 >{format(message.createdAt, 'it_IT')}</h6>
                </TextWrapper>
            </GeneralWrapper>
        </SideWrapper>
    );
}

export default MessageCard;