import React, { useState, useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';
// import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import _ from 'lodash';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
// import { getServices } from '../../../../Store/Actions/servicesActions';
// import { setSize } from '../../../Store/Actions/otherActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    // colourStyles,
    // NavTable,
    Loading,
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './TestDetailsTable';

// const options = [
//   { value: '5', label: '5' },
//   { value: '10', label: '10' },
//   { value: '15', label: '15' },
//   { value: '25', label: '25' },
//   { value: '50', label: '50' },
//   { value: '100', label: '100' },
// ];

const tableHead = [
    'Tipi di test',
    'Punti',
    'Stato',
    'Azioni',
];

let types = [
    {
        idUser: 1,
        type: 'MMPI',
        points: '100',
        status: 'Completo'
    },
    {
        idUser: 2,
        type: 'Test grafici',
        points: '',
        status: 'In attesa'
    },
    {
        idUser: 3,
        type: 'NEO',
        points: '90',
        status: 'In corso'
    },
    {
        idUser: 4,
        type: 'WARTEGG',
        points: '100',
        status: 'Completo'
    },
];

const TestDetails = () => {
    const dispatch = useDispatch();
    // const profile = useSelector(({ auth }) => auth.user);
    // const services = useSelector(({ services }) => services.allServices);
    // const pageSize = useSelector(({ other }) => other.setSize);
    const [paginatedPosts, setPaginatedPosts] = useState();
    const [type, setType] = useState([]);

    // useEffect(() => {
    //   // dispatch(getServices());
    // }, [dispatch]);

    return (
        <WrapperRightSide>
            <Title>Utenti</Title>

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    type={types}
                    flag={'users'}
                    setType={setType}
                />

            </ActionsWrapperSpace>

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <Btn
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/courses/newCourse'>
                        <i className="fas fa-plus"></i>Utenti
                    </Btn>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 300px)' }}>
                {paginatedPosts === undefined ?
                    <>
                        <Loading>
                            <h2>Non ci sono Prove da mostrare</h2>
                            <i className="fas fa-clipboard-list"></i>
                        </Loading>
                    </>
                    :
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono Prove da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {tableHead.map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={types}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default TestDetails;