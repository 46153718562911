import React, { useEffect } from 'react';

//PACKAGES
// import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import _ from 'lodash';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { setSize } from '../../../Store/Actions/otherActions';

import {
    colourStyles,
    NavTable,
} from './NavTableStyle';

const options = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
];

const Nav = ({ obj, setPaginatedPosts }) => {
    const dispatch = useDispatch();
    const pageSize = useSelector(({ other }) => other.setSize);

    const setPageSize = (e) => {
        dispatch(setSize(e))
    };

    // console.log('test renders');

    const pageCount = obj ? Math.ceil(obj.length / pageSize) : 0;

    useEffect(() => {
        setPaginatedPosts(_(obj).slice(0).take(pageSize).value());
    }, [obj, pageSize, setPaginatedPosts]);

    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        const startIndex = (selectedPage - 1) * pageSize;
        const paginatedPosts = _(obj).slice(startIndex).take(pageSize).value();
        // setCurrentPage(selectedPage)
        setPaginatedPosts(paginatedPosts);
    };

    return (

        <NavTable>
            <div style={{ display: 'flex' }}>
                <Select
                    name='pages'
                    isMulti={false}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    menuPlacement='top'
                    defaultValue={pageSize}
                    onChange={(e) =>
                        setPageSize(e.value)
                    }
                    options={options}
                    styles={colourStyles}
                    placeholder={pageSize}
                />
                <h5>{obj !== undefined && obj.length} risultati</h5>
            </div>

            <ReactPaginate
                previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                breakLabel={'...'}
                breakLinkClassName={'breaak-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={changePage}
                containerClassName={'pagination'}
                pageLinkClassName={'numbers'}
                activeLinkClassName={'active'}
            />
        </NavTable>
    )
}

export default Nav;