const initState = {

};

const temaReducers = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ALL_TEMA_SUBJECTS':
            return {
                ...state,
                allTemaSubs: action.payload,
            };

        case 'GET_ALL_TEMA_QUESTIONNAIRES':
            return {
                ...state,
                allTemaQuest: action.payload,
            };

        case 'DOWNLOAD_FILE_TEMA':
            return {
                ...state,
                file: action.payload,
            };

        case 'ALL_TRACE':
            return {
                ...state,
                trace: action.payload,
            };

        case 'ALL_SCALETTA':
            return {
                ...state,
                scaletta: action.payload,
            };

        case 'ALL_REVISIONE':
            return {
                ...state,
                revision: action.payload,
            };

        case 'ALL_AVVIO':
            return {
                ...state,
                avvio: action.payload,
            };

        case 'ONE_EXERCICES':
            return {
                ...state,
                oneExercices: action.payload,
            };

        case 'ALL_COERENZA_EXERCICES':
            return {
                ...state,
                oneCoerenzaExercices: action.payload,
            };

        // case 'ONE_TRACE_EXERCICES':
        //     return {
        //         ...state,
        //         oneTrace: action.payload,
        //     };

        // case 'ONE_SCALETTA_EXERCICES':
        //     return {
        //         ...state,
        //         oneScaletta: action.payload,
        //     };

        case 'ALL_TEMA_DONE':
            return {
                ...state,
                allTraceDone: action.payload,
            };

        case 'ALL_TEMA_DONE_BY':
            return {
                ...state,
                allTraceDoneBy: action.payload,
            };

        case 'ONE_TEMA_DONE':
            return {
                ...state,
                oneTemaDone: action.payload,
            };
        default:
            return state;
    }
};

export default temaReducers;