import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// ASSETS
// import pic from '../../../Assets/Capture.PNG';
// import canvasImage from '../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
//import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../../GeneralComponents/TestViewsBars/NavBarForVerification';
//'../GeneralComponents/TestViewsBars/NavBar';
// import FooterBar from '../../GeneralComponents/TestBars/FooterBar';
import TestPageWithDrawingVerification from '../../TestPage/DrawingTest/DrawingVerification/TestPageWithDrawingVerification';
//'../../TestPage/DrawingTest/TestPageWithDrawing';
//'../TestPage/DrawingTest/TestPageWithDrawing';
import TpaSwitchTestDone from './TpaSwitchTestDone'
//'./TpaSwitchTestDone';
// import TestPage from './MultipleAswTest/TestPageWithMultipleAsw';
// import TestPage from './TextEditorTest/TestPageWithTextEditor';
// import TestPage from './DrawingTest/TestPageWithDrawing';

// import TestPage from './TestVerification/TestVerificationPage';
import FinishPage from '../TpaFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringTpaExercises } from '../../../../Store/Actions/tpaActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../../PagesShareStyle/PagesShareStyle';

//     TRUE/FALSE: [BHS,MMPI,SIGMA]
//     SCRIS LIBER: [FRASI, BIOGRAFICO]
//     MULTIPLE CHOISES ONE ANS: [NEO,CISS,PFS]
//     MULTIPLE CHOISES TWO ANS: [TPC,TOM,EQI,DIFESA,BIG5]


const Box = styled.div`
    height: 30px;
    width: 30px;
    /* color: var(--text2); */
    color: ${props =>
        (props.prev === 'correct' && 'var(--text5)') ||
        (props.prev === 'incorrect' && 'var(--text5)') ||
        (props.select === 'select' ? 'var(--text5)' : 'var(--text2)')
    };
    background: ${props =>
        (props.select === 'select' && 'var(--warning)') ||
        (props.prev === 'correct' && '#ffbc5dd1') ||
        (props.prev === 'incorrect' && 'var(--err)')
    };
    font-size: 18px;
    font-weight: bold;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
        &:hover{
            /* background: var(--background3); */
            background: ${props => props.select === 'select' ? 'var(--warning)' : 'var(--background3)'};
        }
        &:focus{
            background: var(--warning);
        }
`;

const QuestionsContainer = styled.div`
    max-height: 180px;  
    margin-top: 80px;
    width: calc(100% - 250px);;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: var(--background4);
    padding: 10px;
        ::-webkit-scrollbar {
            width: 5px;
        };
        ::-webkit-scrollbar-thumb {
            background: #636363b5;
        };
        @media (max-width: 1100px) {
                width: 100%;
        };
`;

const boolOptions = [
    { label: 'Vero', value: 'Vero' },
    { label: 'Falso', value: 'Falso' }
];

const boolScidOptions = [
    { label: 'SI', value: 'SI' },
    { label: 'NO', value: 'NO' }
];

const oneCissOptions = [
    { label: 'Per niente', value: 'Per niente' },
    { label: 'Poco', value: 'Poco' },
    { label: 'Abbastanza', value: 'Abbastanza' },
    { label: 'Molto', value: 'Molto' },
    { label: 'Moltissimo', value: 'Moltissimo' }
];

const oneNeoOptions = [
    { label: 'Sicuramente in disaccordo', value: 'Sicuramente in disaccordo' },
    { label: 'In disaccordo', value: 'In disaccordo' },
    { label: 'Indeciso', value: 'Indeciso' },
    { label: 'In accordo', value: 'In accordo' },
    { label: `Sicuramente d'accordo`, value: `Sicuramente d'accordo` }
];

const multipleTomOptions = [
    { label: 'Molto in disaccordo', value: 'Molto in disaccordo' },
    { label: 'Abbastanza in disaccordo', value: 'Abbastanza in disaccordo' },
    { label: 'Leggermente in disaccordo', value: 'Leggermente in disaccordo' },
    { label: `Né d'accordo né in disaccordo`, value: `Né d'accordo né in disaccordo` },
    { label: `Leggermente d'accordo`, value: `Leggermente d'accordo` },
    { label: `Abbastanza d'accordo`, value: `Abbastanza d'accordo` },
    { label: `Molto d'accordo`, value: `Molto d'accordo` },
];

const multipleTpcOptions = [
    { label: 'Completamete in disaccordo', value: 'Completamete in disaccordo' },
    { label: 'Molto in disaccordo', value: 'Molto in disaccordo' },
    { label: 'Abbastanza in disaccordo', value: 'Abbastanza in disaccordo' },
    { label: `Né d'accordo né in disaccordo`, value: `Né d'accordo né in disaccordo` },
    { label: `Abbastanza d'accordo`, value: `Abbastanza d'accordo` },
    { label: `Molto d'accordo`, value: `Molto d'accordo` },
    { label: `Completamente d'accordo`, value: `Completamente d'accordo` },
];

const multipleDifesaOptions = [
    { label: 'MAI', value: 'MAI' },
    { label: 'QUALCHE VOLTA', value: 'QUALCHE VOLTA' },
    { label: 'SPESSO', value: 'SPESSO' },
    { label: 'SEMPRE', value: 'SEMPRE' },
];

const multipleBigOptions = [
    { label: 'Assolutamente vero', value: 'Assolutamente vero' },
    { label: 'Abbastaza vero', value: 'Abbastaza vero' },
    { label: 'Né vero né falso', value: 'Né vero né falso' },
    { label: 'Abbastaza falso', value: 'Abbastaza falso' },
    { label: 'Assolutamente falso', value: 'Assolutamente falso' },
];

const oneQeOptions = [
    { label: `Sempre d'accordo`, value: `Sempre d'accordo` },
    { label: `Spesso d'accordo`, value: `Spesso d'accordo` },
    { label: `Qualche volta d'accordo`, value: `Qualche volta d'accordo` },
    { label: `Raramente d'accordo`, value: `Raramente d'accordo` },
    { label: `Mai d'accordo`, value: `Mai d'accordo` },
];

const MainTpaTestPage = ({ quest, done, course, type, idDone, doneAllObj, allDetails }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const whoAmI = useSelector(({ auth }) => auth.user);
    const tests = useSelector(({ tpa }) => tpa.allTpa);
    const [finishTheTest, setFinishTheTest] = useState(false);
    const [rest, setRest] = useState(0);

    const [theTest, setTheTest] = useState();

    useEffect(() => {
        if (type === 'test-grafici') {
            dispatch(bringTpaExercises(type))
        } else {
            dispatch(bringTpaExercises(type))
        }
    }, [dispatch]);


    // useEffect(() => {
    //     if (tests !== undefined) {
    //         setTheTest(JSON.parse(tests.quiz_questions));
    //     }
    // }, [tests]);

    //console.log("MainTpaTestPage", type, quest, done)
    useEffect(() => {
        setTheTest(tests);
    }, [tests]);

    //console.log("TYPE", type)
    //console.log('TPA', quest);
    // console.log('TPA PARAMS', params);
    // console.log('DONE', doneAllObj);
    // console.log(idDone);

    const howIsIt = (elem, que) => {
        //console.log("ELEM", elem)
        if (elem !== undefined && Object.keys(elem).length > 0) {
            let is;

            if (elem.answer_picked === que.answerOk) {
                is = 'correct';
            }

            if (elem.answer_picked !== que.answerOk) {
                is = 'incorrect';
            }

            if (elem.answer_picked === '' || que.answerOk === '') {
                is = 'empty';
            }
            return is;
        };
    };

    const [id, setId] = useState(0);
    const setQue = (id, sts) => {
        setId(id);
    };

    return (
        quest !== undefined &&
        <WrapperRightSide>
            <NavBar
                total={quest.length}
                // rest={theTest.length - 1}
                flagExercises='tpa'
                rest={rest}
            />

            {(type !== 'wartegg' && type !== 'test-grafici') &&
                whoAmI.role !== 'STUDENTE' &&
                <QuestionsContainer>
                    {quest.map((que, i) =>
                        <Box
                            key={i}
                            onClick={() => setQue(i, que)}
                            select={id === i && 'select'}
                            // prev={id > i && 'prev'}
                            //prev={done[i] !== undefined && Object.keys(done[i]).length > 0 && 'prev'}
                            prev={howIsIt(done[i], que)}
                        >
                            {i + 1}

                        </Box>

                    )}
                </QuestionsContainer>
            }

            {/* <TestPageWithDrawing
                tests={theTest}
                setRest={setRest}
                // testID={details.idTestExercises}
                // testID={tests.idTestExercises}
                setFinishTheTest={setFinishTheTest}
                rest={rest}
            /> */}

            {(type === 'wartegg' || type === 'test-grafici') ?
                <TestPageWithDrawingVerification
                    tests={quest}
                    setRest={setRest}
                    // testID={details.idTestExercises}
                    // testID={tests.idTestExercises}
                    setFinishTheTest={setFinishTheTest}
                    rest={rest}
                    idCourse={course}
                    type={type}
                    idTpa={idDone}
                    doneAllObj={doneAllObj}
                    allDetails={allDetails}
                />
                :
                (type === 'bhs' || type === 'mmpi' || type === 'sigma03' || type === 'scid2' || type === 'temps-a' || type === 'millon') ?
                    <TpaSwitchTestDone
                        tests={quest}
                        setRest={setRest}
                        // testID={details.idTestExercises}
                        // testID={tests.idTestExercises}
                        setFinishTheTest={setFinishTheTest}
                        rest={rest}
                        type={'bool'}
                        tpaType={type}
                        opt={type === 'scid2' ? boolScidOptions : boolOptions}
                        course={course}
                        done={done}
                        idDone={idDone}
                        doneAllObj={doneAllObj}
                        id={id}
                        setId={setId}
                        allDetails={allDetails}
                    /> :
                    (type === 'frasi-da-completare' || type === 'biografico') ?
                        <TpaSwitchTestDone

                            tests={quest}
                            setRest={setRest}
                            // testID={details.idTestExercises}
                            // testID={tests.idTestExercises}
                            setFinishTheTest={setFinishTheTest}
                            rest={rest}
                            type={'free'}
                            tpaType={type}
                            opt={''}
                            course={course}
                            done={done}
                            idDone={idDone}
                            doneAllObj={doneAllObj}
                            id={id}
                            setId={setId}
                            allDetails={allDetails}
                        /> :
                        (type === 'neo-pi3' || type === 'ciss' || type === 'pfs' || type === 'qe' || type === 'taleia') ?
                            <TpaSwitchTestDone

                                tests={quest}
                                setRest={setRest}
                                // testID={details.idTestExercises}
                                // testID={tests.idTestExercises}
                                setFinishTheTest={setFinishTheTest}
                                rest={rest}
                                type={'one'}
                                tpaType={type}
                                opt={
                                    type === 'neo-pi3' ?
                                        oneNeoOptions : type === 'ciss' ?
                                            oneCissOptions : type === 'qe' ? oneQeOptions : multipleDifesaOptions
                                }
                                course={course}
                                done={done}
                                idDone={idDone}
                                doneAllObj={doneAllObj}
                                id={id}
                                setId={setId}
                                allDetails={allDetails}
                            /> :
                            (type === 'tpc' || type === 'tom' || type === 'difesa-test' || type === 'big-5') ?
                                <TpaSwitchTestDone

                                    tests={quest}
                                    setRest={setRest}
                                    // testID={details.idTestExercises}
                                    // testID={tests.idTestExercises}
                                    setFinishTheTest={setFinishTheTest}
                                    rest={rest}
                                    type={'multiple'}
                                    tpaType={type}
                                    opt={
                                        type === 'tom' ?
                                            multipleTomOptions : type === 'tpc' ?
                                                multipleTpcOptions : type === 'difesa-test' ?
                                                    multipleDifesaOptions : multipleBigOptions
                                    }
                                    course={course}
                                    done={done}
                                    idDone={idDone}
                                    doneAllObj={doneAllObj}
                                    id={id}
                                    setId={setId}
                                    allDetails={allDetails}
                                /> : ''
            }

            {finishTheTest &&
                <FinishPage />
            }
        </WrapperRightSide>
    );
}

export default MainTpaTestPage;


