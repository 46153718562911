import { set } from 'lodash';
import React, { useState, useEffect } from 'react';

// PACKAGES
import styled from 'styled-components';

const Nav = styled.div`
    position: absolute;
    bottom: 10px;
    color:  var(--text2);
    display: flex;
        >span{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: var(--text4);
            cursor: default;
            border: 1px solid #c1c4d152;
            margin: 2px;
        }
`;

const Btn = styled.div`
    width: 55px;
    padding: 0px 5px;
    border:none;
    outline: none;
    color: ${props => props.active === 'color' ? 'var(--acc-color)' : 'var(--text3)'};
    color: ${props => props.disabled === 'disabled' && 'var(--text4)'};
    font-weight: ${props => props.active === 'color' && 'bold'};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
        &:hover{
            transition: .2s ease-in-out;
            /* color:  var(--acc-color); */
            /* color: ${props => props.disabled === 'disabled' ? 'var(--text4)' : 'var(--acc-color)'}; */
            /* color: ${props => props.disabled === 'disabled' && 'var(--text4)'}; */
            background: ${props => props.disabled !== 'disabled' && '#c1c1c133'};
            /* font-weight: bold; */
            /* font-weight: ${props => props.disabled === 'disabled' ? null : 'bold'}; */
        }
        /* &:active{
                transform:translateY(2px);
        }   */
`;


const NavCard = ({ mat, setDif, access }) => {
    const [difArr, setDifArr] = useState([]);

    const handleDif = ({ e, sts }) => {
        // console.log(e.currentTarget.id, sts, max);
        if (access.nr !== '') {
            let dif = [];
            if (difArr.length === 0) {
                dif.push(sts)
            } else {
                const findStsExist = difArr.find(s => s === sts)
                if (findStsExist) {
                    const del = difArr.filter(c => c !== sts)
                    dif = del
                } else {
                    dif = difArr.concat(sts)
                }

            };
            setDifArr(dif);

            // SET MAX
            let maxi = [];
            const findEasyExist = dif.find(s => s === 'Facile');
            if (findEasyExist) { maxi.push(mat.easyQuestions.length) }

            const findModerateExist = dif.find(s => s === 'Medio');
            if (findModerateExist) { maxi.push(mat.moderateQuestions.length) }

            const findDifficultExist = dif.find(s => s === 'Difficile');
            if (findDifficultExist) { maxi.push(mat.difficultQuestions.length) }

            console.log('DIF ARR', maxi)
            let sum = '';
            for (let i = 0; i < maxi.length; i++) {
                sum = sum * 1 + maxi[i]
            }
            setDif({ e, sts: dif, max: sum });
        }
    };

    const handleColor = (e) => {
        const ifIs = difArr.find(is => is === e)
        if (ifIs) {
            return true
        }
    };

    // CLOSE DIFFICULTY
    useEffect(() => {
        if (access.nr === '') {
            setDifArr([]);
        }
    }, [access]);

    // console.log('NavCard', difficulty);
    // console.log('NavCard difArr', difArr);
    // console.log(calcMax);
    // console.log('AAAAAAAAAAAAAa', difArr);
    // console.log('AAAAAAAAAAAAAa', mat);

    return (
        <Nav>
            {mat.easyQuestions.length > 0 ?
                <Btn
                    id='1'
                    onClick={(e) => handleDif({ e, sts: 'Facile' })}
                    active={handleColor('Facile') === true && 'color'}
                >
                    <span>{mat.easyQuestions.length}</span>
                    Facile
                </Btn>
                :
                <Btn disabled='disabled'>
                    <span>{mat.easyQuestions.length}</span>
                    Facile
                </Btn>
            }
            <span></span>

            {mat.moderateQuestions.length > 0 ?
                <Btn
                    id='2'
                    onClick={(e) => handleDif({ e, sts: 'Medio' })}
                    active={handleColor('Medio') === true && 'color'}
                >
                    <span>{mat.moderateQuestions.length}</span>
                    Medio
                </Btn>
                :
                <Btn disabled='disabled'>
                    <span>{mat.moderateQuestions.length}</span>
                    Medio
                </Btn>
            }
            <span></span>

            {mat.difficultQuestions.length > 0 ?
                <Btn
                    id='3'
                    onClick={(e) => handleDif({ e, sts: 'Difficile' })}
                    active={handleColor('Difficile') === true && 'color'}
                >
                    <span>{mat.difficultQuestions.length}</span>
                    Difficile
                </Btn>
                :
                <Btn disabled='disabled'>
                    <span>{mat.difficultQuestions.length}</span>
                    Difficile
                </Btn>
            }
        </Nav>
    );
};

export default NavCard;

        // <Nav>
        //     {mat.easyQuestions.length > 0 ?
        //         <Btn
        //             id='1'
        //             onClick={(e) => setDif({ e, sts: 'Facile', max: mat.easyQuestions.length })}
        //             // onClick={(e) => setAccess({ ...access, difficulty: 'Facile', idDifficulty: e.currentTarget.id })}
        //             active={access.idDifficulty === '1' && 'color'}
        //         >
        //             <span>{mat.easyQuestions.length}</span>
        //             Facile
        //         </Btn>
        //         :
        //         <Btn disabled='disabled'>
        //             <span>{mat.easyQuestions.length}</span>
        //             Facile
        //         </Btn>
        //     }
        //     <span>|</span>

        //     {mat.moderateQuestions.length > 0 ?
        //         <Btn
        //             id='2'
        //             onClick={(e) => setDif({ e, sts: 'Medio', max: mat.moderateQuestions.length })}
        //             // onClick={(e) => setAccess({ ...access, difficulty: 'Medio', idDifficulty: e.currentTarget.id })}
        //             active={access.idDifficulty === '2' && 'color'}
        //         >
        //             <span>{mat.moderateQuestions.length}</span>
        //             Medio
        //         </Btn>
        //         :
        //         <Btn disabled='disabled'>
        //             <span>{mat.moderateQuestions.length}</span>
        //             Medio
        //         </Btn>
        //     }
        //     <span>|</span>

        //     {mat.difficultQuestions.length > 0 ?
        //         <Btn
        //             id='3'
        //             onClick={(e) => setDif({ e, sts: 'Difficile', max: mat.difficultQuestions.length })}
        //             // onClick={(e) => setAccess({ ...access, difficulty: 'Difficile', idDifficulty: e.currentTarget.id })}
        //             active={access.idDifficulty === '3' && 'color'}
        //         >
        //             <span>{mat.difficultQuestions.length}</span>
        //             Difficile
        //         </Btn>
        //         :
        //         <Btn disabled='disabled'>
        //             <span>{mat.difficultQuestions.length}</span>
        //             Difficile
        //         </Btn>
        //     }
        // </Nav>