import { useEffect, useState, useCallback } from 'react';
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// ASSETS
import pic from '../../../Assets/Capture.PNG';
import canvasImage from '../../../Assets/birds.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions';
import NavBar from '../../GeneralComponents/TestViewsBars/NavBar';
import TestPage from '../TestPage/OneAswTest/TestPageWithOneAsw';
import FinishPage from './WrongFinishPage';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { bringSimulation } from '../../../Store/Actions/testActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    // TestWrapper,
    // ActionsWrapper,
    // BtnWrapper,
    // Btn,
} from '../PagesShareStyle/PagesShareStyle';


const TestPageForWrong = ({ details }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const simulation = useSelector(({ test }) => test.bringSimulation);
    const [theSimulation, setTheSimulation] = useState();
    const [finishTheTest, setFinishTheTest] = useState(false);

    // const wrongTest = useSelector(({ test }) => test.testWrongQuezzes);

    const wrongTest = details.savedTest;

    // console.log('brinf', theSimulation);

    // useEffect(() => {
    //     dispatch(bringSimulation(params.id))
    // }, [dispatch]);

    // useEffect(() => {
    //     if (simulation !== undefined) {
    //         setTheSimulation(JSON.parse(simulation.sim_config));
    //     }
    // }, [simulation]);

    const [rest, setRest] = useState(0);

    // console.log('TestPageForWrong', details);

    return (
        wrongTest !== undefined &&
        <WrapperRightSide>
            <NavBar
                total={wrongTest.length}
                flagExercises = 'wrong'
                rest={rest}
            />

            <TestPage
                tests={wrongTest}
                setRest={setRest}
                setFinishTheTest={setFinishTheTest}
                rest={rest}
                flag='wrong'
                course={details.course} //To see!!!
            />

            {finishTheTest &&
                <FinishPage />
            }
        </WrapperRightSide>
    );
}

export default TestPageForWrong;


