import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// ASSETS
import loading from '../../../Assets/loadingGif.gif';

//PACKAGES
import styled from 'styled-components';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getPunctualStatistics } from '../../../Store/Actions/statisticsActions';

import {
    WrapperRightSide,
    Title,
    ActionsWrapper,
    ActionsWrapperSpace,
    BtnWrapper,
    Btn,
    TableWrapper,
    Thead,
    Tbody,
    Th,
    Loading,
    GoBack
} from '../PagesShareStyle/PagesShareStyle';

//COMPONENTS
import NavTable from '../../GeneralComponents/NavTable/NavTable';
import Search from '../../GeneralComponents/Search/Search';
// import Filter from '../../GeneralComponents/Filter/Filter';
import Table from './OverviewTable';

const TitleWrapper = styled.div`
    display: flex;
`;

const LoadingDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >img{
            width: 120px;
        }
        >h4{
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, -90%);
            white-space: nowrap;
        }
`;

// let tableHead = [
//     'Name',
//     'Code',
//     'Tot. simulazioni svolte',
//     // '% Sup. Simulazioni',
//     'Quiz totali',
//     '% Sup. Quiz',
//     '% Lezioni',
//     'Azioni',
// ];

const Overview = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const statistics = useSelector(({ statistics }) => statistics.allStatistics);
    const [paginatedPosts, setPaginatedPosts] = useState();
    // const [coursesArr, setCoursesArr] = useState([]);
    const [type, setType] = useState([]);
    // const [tableHead, setTableHead] = useState([]);

    const setHead = (flag) => {
        let tableHead = [];
        if (flag === 'Preselettiva') {
            tableHead = [
                'Name',
                'Code',
                'Tot. simulazioni svolte',
                // '% Sup. Simulazioni',
                'Quiz totali',
                '% Sup. Quiz',
                '% Lezioni',
                'Azioni',
            ];
        } else if (flag === 'Tpa') {
            tableHead = [
                'Name',
                'Code',
                '%',
                'Azioni',
            ];
        } else if (flag === 'Tema') {
            tableHead = [];
        } else if (flag === 'Orale') {
            tableHead = [
                'Name',
                'Code',
                '%',
                'Azioni',
            ];
        } else if (flag === 'Fisica') {
            tableHead = [];
        };
        return tableHead
    }

    //  useEffect(() => {
    //     if (details.flag = 'Preselettiva') {
    //         setTableHead(
    //             [
    //                 'Name',
    //                 'Code',
    //                 'Tot. simulazioni svolte',
    //                 // '% Sup. Simulazioni',
    //                 'Quiz totali',
    //                 '% Sup. Quiz',
    //                 '% Lezioni',
    //                 'Azioni',
    //             ]
    //         );
    //     } else if (details.flag === 'Tpa') {
    //         setTableHead(
    //             [
    //                 'Name',
    //                 'Code',
    //                 'Tot. simulazioni svolte',
    //                 // '% Sup. Simulazioni',
    //                 'Quiz totali',
    //                 '% Sup. Quiz',
    //                 '% Lezioni',
    //                 'Azioni',
    //             ]
    //         );
    //     } else if (details.flag === 'Tema') {
    //         setTableHead(
    //             [
    //                 'Name',
    //                 'Code',
    //                 'Tot. simulazioni svolte',
    //                 // '% Sup. Simulazioni',
    //                 'Quiz totali',
    //                 '% Sup. Quiz',
    //                 '% Lezioni',
    //                 'Azioni',
    //             ]
    //         );
    //     } else if (details.flag === 'Orale') {
    //         setTableHead(
    //             [
    //                 'Name',
    //                 'Code',
    //                 'Tot. simulazioni svolte',
    //                 // '% Sup. Simulazioni',
    //                 'Quiz totali',
    //                 '% Sup. Quiz',
    //                 '% Lezioni',
    //                 'Azioni',
    //             ]
    //         );
    //     } else if (details.flag === 'Fisica') {
    //         setTableHead(
    //             [
    //                 'Name',
    //                 'Code',
    //                 'Tot. simulazioni svolte',
    //                 // '% Sup. Simulazioni',
    //                 'Quiz totali',
    //                 '% Sup. Quiz',
    //                 '% Lezioni',
    //                 'Azioni',
    //             ]
    //         );
    //     };
    // }, [details]);

    useEffect(() => {
        dispatch(getPunctualStatistics(details.flag, profile.idUser));
    }, [dispatch]);

    // console.log('OVERVIEW DETAILS', details);
    // console.log('OVERVIEW STAT', statistics);
    // console.log('PAG',paginatedPosts);

    return (
        // statistics !== undefined &&
        <WrapperRightSide>
            <TitleWrapper>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
                </GoBack>
                <Title style={{ marginTop: '0' }}>{details.flag}</Title>
            </TitleWrapper>

            <ActionsWrapperSpace>
                <div></div>

                <Search
                    type={statistics}
                    flag={'statistics'}
                    setType={setType}
                />

            </ActionsWrapperSpace>

            {/* <ActionsWrapper>
                <BtnWrapper>
                    <Btn
                        style={{ margin: '0' }}
                        theme={'users'}
                        to='/dashboard/courses/newCourse'>
                        <i className="fas fa-plus"></i>Utenti
                    </Btn>
                </BtnWrapper>

                <Filter
                    invs={invo}
                    flag={'invoices'}
                    setInvoice={setInvoice}
                />
            </ActionsWrapper> */}

            <TableWrapper style={{ height: 'calc(100vh - 300px)' }}>
                {statistics === undefined ?
                    <LoadingDiv>
                        <img src={loading} alt='Loading...'></img>
                        <h4 style={{ color: 'var(--text2)' }}>Caricamento dati...</h4>
                    </LoadingDiv>
                    :
                    paginatedPosts !== undefined&&
                    paginatedPosts.length === 0 ?
                        <>
                            <Loading>
                                <h2>Non ci sono Prove da mostrare</h2>
                                <i className="fas fa-clipboard-list"></i>
                            </Loading>
                        </>
                        :
                        <table style={{ borderCollapse: 'collapse' }}>
                            <Thead>
                                <tr >
                                    {setHead(details.flag).map((th, i) =>
                                        <Th style={{ whiteSpace: 'nowrap' }} key={i}>{th}</Th>
                                    )}
                                </tr>
                            </Thead>
                            <Tbody>
                                {paginatedPosts !== undefined &&
                                    paginatedPosts.map((elem, i) =>
                                        <Table
                                            elem={elem}
                                            key={i}
                                            flag={details.flag}
                                        // statistics={statistics !== undefined && statistics}
                                        />
                                    )
                                }
                            </Tbody>
                        </table >
                }
            </TableWrapper >

            <NavTable
                obj={type}
                setPaginatedPosts={setPaginatedPosts}
            />

        </WrapperRightSide >
    )
}

export default Overview;