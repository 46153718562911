import React, { useEffect } from 'react';

// PACKAGES
import styled from 'styled-components';

// ASSETS
import user from '../../../../../Assets/avatar.png';

// REDUX
// import { useDispatch, useSelector } from 'react-redux';


const GeneralWrapper = styled.div`
    width: 99%;
    margin: 10px 0 0;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: var(--radius);
    opacity: .6;
        >div{
            position: relative;
            >img{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
                object-fit: cover;
                background: var(--background2);
            }
            >span{
                position: absolute;
                bottom: 5px;
                right: 2px;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                border: 3px solid var(--background4);
                background-color: #c4c4c4;
            }
        }
        >h5{
            color: var(--text1);
            font-weight: bold;
            margin-left: 10px;
        }
         &:hover{
            background-color:var(--background2);
            opacity: 1;
                >div>span{
                    border: 3px solid var(--background2);
                }
        }
`;

const AvatarDisplay = styled.div`
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    color: var(--text4);
    margin-right: 10px;
    padding: 10px 0;
        >h4{
            height: 40px;
            width: 40px;
            border-radius: 40px;
            font-size: 20px;
            display:flex;
            align-items:center;
            justify-content:center;
            color: var(--text2);
            background: var(--background3);
        }
        >img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
            cursor: pointer;
            background: var(--background2);
        }
`;

const OnlineUserCard = ({ offline }) => {
    // const dispatch = useDispatch();
    // const onlineFriends = useSelector(({ conversations }) => conversations.onlineFriends);

    // useEffect(() => {
    //     dispatch(findOnlineFriends(online.userId))
    // }, [online]);

    const getInitials = (profile) => {
        if (profile !== undefined) {
            let string;

            string = offline.user_name + ' ' + offline.user_surname;

            const names = string.split(' ');
            let initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials
        }
    };

    // console.log('online', onlineFriends);

    return (
        offline !== undefined &&
        <GeneralWrapper>
            <div>
                {/* <img src={user} alt='img'></img> */}
                <AvatarDisplay><h4>{getInitials(offline)}</h4></AvatarDisplay>
                <span></span>
            </div>
            <h5>{offline.user_name + ' ' + offline.user_surname}</h5>
        </GeneralWrapper>
    );
}

export default OnlineUserCard;