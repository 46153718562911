import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// ASSETS
import finish from '../../../Assets/finish.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import Admin from '../../Pages/Admin/Admin';

// REDUX
import { useSelector } from 'react-redux';

const BarWrapper = styled.div`
    position: fixed;
    top: 160px;
    right: 0;
    width: 250px;
    background-color: var(--background4);
    /* margin: 10px 0px; */
    padding: 5px;
    height: calc(100vh - 240px);
    /* border-radius: var(--radius); */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
        /* @media (max-width: 1100px) {
            display: none;
        }; */
        @media (max-width: 1100px) {
            transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(100%)'};
            z-index: 2;
        };
        
`;

const Title = styled.div`
   color:  var(--text2);
   font-size: 24px;
   font-weight: bold;
`;

const Nav = styled.div`
   color:  var(--text2);
   display: flex;
   cursor: default;
   margin-bottom: 10px;
        >span{
            color: var(--text4);
        }
`;

const BtnLink = styled.div`
    padding: 0px 5px;
    border:none;
    outline: none;
    color: ${props => props.sts === 'true' ? 'var(--acc-color)' : 'var(--text3)'};
    font-weight: ${props => props.sts === 'true' ? 'bold' : 'none'};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: .2s ease-in-out;
        /* &:hover{
            transition: .2s ease-in-out;
            color:  var(--acc-color);
            font-weight: bold;
        } */
        /* &:active{
                transform:translateY(2px);
        }   */
`;

const QuestionsContainer = styled.div`
    max-height: calc(100vh - 170px);
    width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const Box = styled.div`
    position: relative;
    overflow: hidden;
    height: 50px;
    width: 50px;
    /* color: var(--text2); */
    color: ${props =>
        (props.prev === 'prev' && 'var(--text5)') ||
        (props.select === 'select' ? 'var(--text5)' : 'var(--text2)')
    };
    background: ${props =>
        (props.select === 'select' && 'var(--warning)') ||
        (props.prev === 'prev' && 'var(--success)')
    };
    font-size: 25px;
    font-weight: bold;
    margin: 10px 5px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
        &:hover{
            /* background: var(--background3); */
            background: ${props => props.select === 'select' ? 'var(--warning)' : 'var(--background3)'};
        }
        &:focus{
            background: var(--warning);
        }
        >img{
            position: absolute;
            top: 5px;
            right: -5px;
            width: 30px;
            opacity: .5;
            transform: rotate(45deg);
        }
`;


const AllQuestionsSideBar = ({ id, setId, difficulty, arr, obj, flag, temaType }) => {
    const profile = useSelector((state) => state.auth.user);
    const openRightSideBar = useSelector(({ other }) => other.handleRightSideBar);
    // const [id, setId] = useState(0);
    const [sts, setSts] = useState(difficulty);

    const setQue = (id, sts) => {
        setId(id);
        // setSts(sts.difficulty);
    };

    useEffect(() => {
        setSts(difficulty);
    }, [difficulty])


    return (
        flag !== 'draw' &&
        <BarWrapper open={openRightSideBar}>
            {/* {(profile !== undefined && profile.accesLevel === 'admin') &&
                <Admin
                    content={content}
                />
            } */}

            <Title>Domande</Title>

            {/* {flag !== 'draw' &&
                <Nav>
                    <BtnLink sts={sts === 'A' && 'true'}>
                        Facile
                    </BtnLink>
                    <span>|</span>
                    <BtnLink sts={sts === 'B' && 'true'}>
                        Medio
                    </BtnLink>
                    <span>|</span>
                    <BtnLink sts={sts === 'C' && 'true'}>
                        Difficile
                    </BtnLink>
                </Nav>
            } */}

            {/* <Nav>
                <BtnLink sts={sts === 'Facile' && 'true'}>
                    Facile
                </BtnLink>
                <span>|</span>
                <BtnLink sts={sts === 'Medio' && 'true'}>
                    Medio
                </BtnLink>
                <span>|</span>
                <BtnLink sts={sts === 'Difficile' && 'true'}>
                    Difficile
                </BtnLink>
            </Nav> */}

            <QuestionsContainer>
                {arr.map((que, i) =>
                    <Box
                        key={i}
                        onClick={() => setQue(i, que)}
                        select={id === i && 'select'}
                        // prev={id > i && 'prev'}
                        prev={temaType !== 'avvio-alla-scrittura' ?
                            obj[i] !== undefined && Object.keys(obj[i]).length > 0 && 'prev'
                            :
                            obj[i] !== undefined && obj[i].check === true && 'prev'
                        }
                    >
                        {i + 1}
                        {obj.length - 1 === i &&
                            <img src={finish} alt='img'></img>
                        }
                    </Box>
                )}
            </QuestionsContainer>

        </BarWrapper >
    );
};

export default AllQuestionsSideBar;