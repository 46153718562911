import React, { useState } from 'react';

// PACKAGES
import styled from 'styled-components';

// REDUX
import { useSelector } from 'react-redux';

const TagsWrapper = styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* height: 60px;
    min-height: 60px;
    overflow: auto;
         &::-webkit-scrollbar {
            display: none;
        }; */
`;

const Tag = styled.div`
    position: relative;
    padding: 0px 40px 0px  10px;
    margin: 5px;
    height: 30px;
    background-color:var(--background4);
    border-radius: var(--radius);
    color:  var(--text2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        >div{
            color:  var(--text2);
            
            font-size: 12px;
        }
        >div:nth-child(2){
            font-weight: bold;
            margin-right: 5px;
            font-size: 14px;
        }
        >span{
            margin: 0 5px;
        }
        >i{
            position: absolute;
            right: 10px;
            font-size: 12px;
            color:  var(--text3);
            cursor: pointer;
                &:hover{
                    color:  var(--acc-color);
                }
        }
`;

const Sts = styled.div`
    color:  var(--acc-color)!important;
    font-weight: bold;
`;



const Tags = ({ arr, setArr }) => {
    const profile = useSelector((state) => state.auth.user);
    // const [arr, setArr] = useState(
    //     [
    //         {
    //             amount: 4,
    //             materia: 'Geografia',
    //             difficulty: 'Medio'
    //         },
    //         {
    //             amount: 5,
    //             materia: 'Educazione civica',
    //             difficulty: 'Facile'
    //         },
    //         {
    //             amount: 15,
    //             materia: 'Grammatica',
    //             difficulty: 'Medio'
    //         },
    //         {
    //             amount: 2,
    //             materia: 'Inglese',
    //             difficulty: 'Facile'
    //         },
    //         {
    //             amount: 11,
    //             materia: 'Letteratura',
    //             difficulty: 'Difficile'
    //         },
    //         {
    //             amount: 7,
    //             materia: 'Matematica',
    //             difficulty: 'Medio'
    //         },
    //         {
    //             amount: 8,
    //             materia: 'Scienze',
    //             difficulty: 'Difficile'
    //         },
    //     ]
    // );

    // const deleteTag = (i) => {
    //     const filterArr = arr.filter((tag, index) => index !== i);
    //     console.log(filterArr)
    //     setArr(filterArr);
    // };



    const deleteTag = (i) => {
        const value = [...arr];
        value[i] = {};
        setArr(value);
    };

    const calcSts = (e) => {
        if (e.length === 3 || e.length === 0) {
            return 'Tutti'
        } else if (e.length === 2) {
            const sts = `${e[0]} & ${e[1]}`
            return sts
        } else {
            const sts = `${e[0]}`
            return sts
        }
    };

    // console.log("TAGS", arr);


    return (
        <TagsWrapper>
            {arr.map((tag, i) =>
                Object.keys(tag).length > 0 &&
                <Tag
                    key={i}
                >
                    <i className="fa-solid fa-xmark" onClick={() => deleteTag(i)}></i>
                    <div>{tag.nr}</div>
                    <div>{tag.materia}</div>
                    <span> - </span>
                    {(tag.difficulty === '' && tag.option === '') && <Sts>Tutti</Sts>}
                    {tag.difficulty !== '' && <Sts>{calcSts(tag.difficulty)}</Sts>}
                    {tag.option !== '' && <Sts>{tag.option}</Sts>}
                    {/* {tag.difficulty !== '' ?
                        <Sts>{tag.difficulty}</Sts>
                        :
                        <Sts>{tag.option}</Sts>
                    } */}

                </Tag>
            )}
        </TagsWrapper>
    );
};

export default Tags;