import axios from 'axios';
axios.defaults.withCredentials = true;

export const fetchUser = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_USER' });

    axios
        .get(`${process.env.REACT_APP_URL_BACKEND}/auth/checkUser`,)
        .then((res) => {
            // console.log("CHECK USER", res)
            dispatch({
                type: 'FETCHED_USER',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch(logOut());
        });
};

export const setPsw = (access) => (dispatch) => {
    dispatch({ type: 'LOADING_SET_PSW' });

    const loginCredentials = {
        email: access.email,
        psw: access.psw,
    };

    // console.log('LOGIN', loginCredentials)
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/auth/setPsw`, loginCredentials)
        .then((res) => {
            // console.log("SET_PSW_SUCCESFUL", res);
            dispatch({
                type: 'SET_PSW_SUCCESFUL',
                payload: res.data,
            });
            // dispatch(fetchUser());
            dispatch(notifHandler('success', 'La password è stata impostata correttamente!'));
        })
        .catch((err) => {
            if (err) {
                dispatch({
                    type: 'LOGIN_ERROR',
                    payload: err.response.data.message,
                });
                setTimeout(() => {
                    dispatch({ type: 'LOGIN_ERROR' });
                }, 50000);
                dispatch(notifHandler('error', err.response.data.message));
            }
        });
};


export const loginUser = (access) => (dispatch) => {
    dispatch({ type: 'LOADING_SIGNIN' });

    const loginCredentials = {
        email: access.email,
        psw: access.psw,
    };

    // console.log('LOGIN', loginCredentials)
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/auth/login`, loginCredentials)
        .then((res) => {
            console.log("LOGIN RES", res.data.user);
            dispatch({
                type: 'LOGIN_SUCCESFUL',
                payload: res.data.user,
            });
            // dispatch(fetchUser());
            // dispatch(notifHandler('success', 'Successfully logged in!'));
        })
        .catch((err) => {
            // console.log("LOGIN ERR", err.response.data.message);
            // console.log("LOGIN ERR", err);
            if (err) {
                dispatch({
                    type: 'LOGIN_ERROR',
                    payload: err.response.data.message,
                });
                setTimeout(() => {
                    dispatch({ type: 'LOGIN_ERROR' });
                }, 50000);
                dispatch(notifHandler('error', err.response.data.message));
            }
        });
};


export const openModal = (props) => (dispatch) => {
    dispatch({
        type: 'OPEN_MODAL',
        payload: props,
    });
};

export const logOut = () => dispatch => {
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/auth/logOut`)
        .then((res) => {
            // console.log("FETCH COMP", res.data)
            dispatch({
                type: 'LOGOUT_SUCCESFUL',
                // payload: res.data,
            });
            dispatch({
                type: 'GET_MESSAGES',
                payload: [],
            });
        })
        .catch((err) => {
            console.log(err);
        });
    // return {
    //   type: 'LOGOUT_SUCCESFUL',
    // };
};

const notifHandler = (flag, msg) => (dispatch) => {
    const obj = {
        flag: flag,
        msg: msg,
    };
    dispatch({
        type: 'NOTIF_HANDLER_AUTH',
        payload: obj,
    });
    setInterval(() => {
        dispatch({
            type: 'NOTIF_HANDLER_AUTH',
            payload: undefined,
        });
    }, 3000);
};


// export const tokenConfig = (getState) => {
//   const token = getState().auth.token;

//   // Headers
//   const config = {
//     headers: {
//       'Content-type': 'application/json',
//     },
//   };

//   if (token) {
//     config.headers['x-access-token'] = token;
//   }

//   return config;
// };
