import { useEffect } from 'react';
// import { useHistory } from "react-router-dom";

import pic from '../../../../Assets/card-pic.png'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
// import ProgressBar from './ProgressBar';

// PAGE


// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../../PagesShareStyle/PagesShareStyle';

const HeaderWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const ImgWrapper = styled.div`
    width: 100%;
    height: 300px;
    border-radius: var(--radius);
    overflow: hidden;
        >img{
            width: 100%;
            transform: translateY(-50%);
            /* height: 150%; */
        }
`;

const TitleWrapper = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 50%);
    width: 95%;
    background-color: #ffffffd4;
    backdrop-filter: blur(3px);
    border-radius: var(--radius);
    padding: 20px;
`;

const Title = styled.div`
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
        >h3{
            color: var(--text1);
            font-weight: bold;
        }
        >h6{
            width: 50%; 
            max-width: 500px;
            color: var(--text2);
            font-weight: 100!important;
        }
`;

const Sts = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    border-radius: var(--radius);
    background-color: ${props =>
        (props.color === 'red' && 'var(--err)') ||
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
`;


const LearningPageHeader = ({ course, studDetails, lessonsTotal, lessonsCompletedTotal, total }) => {
    const dispatch = useDispatch();
    //console.log("course", course)
    // const kits = useSelector(({ kits }) => kits.allKits);

    useEffect(() => {
        // dispatch(getKits());
    }, [dispatch]);

    console.log("studDetails", studDetails)
    const capitalizeFirstLetter = (str) => {
        console.log(str)
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <HeaderWrapper>
            <ImgWrapper>
                {/* <img src={course.url} alt='pic'></img> */}
                <img src={pic} alt='pic'></img>
            </ImgWrapper>

            {/* <TitleWrapper> */}
            <Title>
                <h3>{course.course_name} - {course.course_code}</h3>
                {/* <h6>Corso di preparazione online rivolto a chi ha intenzione di superare la Prova Preliminare del Concorso Accademia Esercito.</h6> */}
                {studDetails !== undefined &&
                    <>
                        {capitalizeFirstLetter(studDetails.user_role)}: {studDetails.user_name} {studDetails.user_surname}
                    </>
                }
                {/* <Sts
                        color={total < 100 ?
                            total <= 40 ?
                                'red'
                                :
                                'In corso'
                            : 'Completo'
                        }
                    >
                        {total < 100 ? 'In corso' : 'Completo'}
                    </Sts> */}
            </Title>

            {/* <ProgressBar
                    lessonsTotal={lessonsTotal}
                    lessonsCompletedTotal={lessonsCompletedTotal}
                    total={total}
                /> */}
            {/* </TitleWrapper> */}

        </HeaderWrapper>
    );
}

export default LearningPageHeader;
