import React from 'react';
// import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Time from '../../../../GeneralComponents/TestViewsBars/Time';

// REDUX
// import { useSelector } from 'react-redux';

// STYLE
import {
    GoBack,
} from '../../../PagesShareStyle/PagesShareStyle';

const BarWrapper = styled.div`
    position: fixed;
    top: 80px;
    left: 0px;
    z-index: 1;
    margin-left: 250px;
    width: calc(100% - 250px);
    height: 80px;
    background-color: var(--background4);
    padding: 5px;
    /* border-radius: var(--radius); */
    display: flex;
    align-items: center;
    justify-content: center;
        @media (max-width: 1100px) {
                width:100%;
                margin-left:0px;
                };

`;

const Elem = styled.div`
   color:  var(--text2);
   font-size: 14px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
   border-left: 1px solid var(--text4);
   border-right: 1px solid var(--text4);
        >span{
            font-size: 22px;
        }
`;


const NavBarQuestVerification = ({ correct, incorrect }) => {
    const history = useHistory();

    return (
        <BarWrapper>
            <GoBack style={{ position: 'absolute', left: '20px' }} onClick={() => history.goBack()}>
                <i className="fas fa-arrow-left" style={{ marginRight: '0' }}></i>
            </GoBack>
            <Elem><span>{correct}</span>Risposte esatte</Elem>
            <Elem><span>{incorrect}</span>Risposte errate</Elem>
        </BarWrapper>
    );
};

export default NavBarQuestVerification;
