import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";


// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import TpaLessons from './TpaLessons';

// PAGE


// REDUX
import { useSelector, useDispatch } from 'react-redux';
// import { getAllSubjects } from '../../../Store/Actions/subjectsActions';
import { getAllTypologies } from '../../../../Store/Actions/tpaActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../../PagesShareStyle/PagesShareStyle';

const TypologieWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Lesson = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background3);
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const Left = styled.div`
    
    
`;

const Right = styled.div`
    >span {
        font-size: 14px;
    }
    >i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) ${props => props.open === 'true' && 'rotate(-90deg)'};
        color: var(--text3);

    }
`;

const Sts = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    border-radius: var(--radius);
    background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
    >div:nth-last-child(1){
        border-radius:  0 0 var(--radius) var(--radius);
    }
`;


const Typologies = ({ idCourse, idCheckUser, studDetails, course }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    // const materie = useSelector(({ subjects }) => subjects.allSubjects);
    const tpa = useSelector(({ tpa }) => tpa.allTypologies);
    const [openLessons, setOpenLessons] = useState(false);
    const [tpas, setTpas] = useState([]);

    useEffect(() => {
        const id = idCheckUser !== undefined ? idCheckUser : profile.idUser;
        // dispatch(getAllSubjects(idCourse, id));
        dispatch(getAllTypologies());
    }, [dispatch]);

    useEffect(() => {
        let typs = []
        //console.log("");
        if (tpa !== undefined) {
            tpa.forEach(el => {
                typs.push(el.type)
            })

            typs.sort((a, b) => a.order - b.order)
            // console.log("ret", ret);

            const unique = [...new Set(typs)]
            setTpas(unique);
        }
    }, [tpa]);

    // console.log('tpa', tpa);
    // console.log("DETAILS", idCourse);
    // console.log("PROFILE", profile);


    return (
        <TypologieWrapper>
            {tpas !== undefined && tpas.length > 0 && 
                <>
                    <Header>{tpas !== undefined && tpas.length} Tipologie</Header>
                    {tpas !== undefined &&
                        tpas.map((type, i) =>
                            <div key={i}>
                                <TpaLessons
                                    type={type}
                                    idCheckUser={idCheckUser}
                                    studDetails={studDetails}
                                    course={course}
                                />
                            </div>
                        )}
                </>
            }
        </TypologieWrapper>
    );
}

export default Typologies;