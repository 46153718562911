import styled from 'styled-components';

export const InputCss = styled.input`
    width:300px;
    height: 50px;
    padding: 6px;
    border: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
    margin-top:10px;
    color: var(--text1);
    transition: var(--transition) ease;
    background: transparent;
        &::placeholder { 
            color: var(--text3);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
            /* box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%); */
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text1);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
        &::-webkit-calendar-picker-indicator{
            filter: invert(50%) sepia(4%) saturate(41%) hue-rotate(313deg) brightness(112%) contrast(100%);
            cursor: pointer;
            font-size: 20px; 
        }
`;

export const TextArea = styled.textarea`
    width: 100%;
	max-width: 680px;
    height: 150px;
    resize: none;
    padding: 6px;
    margin-top: 10px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    background: transparent;
    color: var(--text1);
    transition: var(--transition) ease;
    font-size: 16px;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border-color: var(--acc-color);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--acc-color);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

export const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 0px 0px;
    color: var(--text1);
`;

export const Negative = styled.div`
    z-index: 2;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(175 175 175 / 50%);
    backdrop-filter: var(--blur);
    opacity: ${({ open }) => open ? '1' : '0'};
    transition: opacity 224ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: ${({ open }) => open ? 'auto' : 'none'};
`;

export const CalendarSPlace = styled.div`
	position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
`;

export const BtnWrapper = styled.div`
	width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: var(--background4);
`;
export const Btn = styled.button`
	width:100px;
    padding:5px 15px;
    margin-left:5px;
    border:none;
    outline: none;
    border-radius: var(--radius);
    background: ${props => props.cancel ? 'var(--err)' : 'var(--success)'};
`;

export const Status = styled.div`
	position:absolute;
    top: 50%;
    /* right: -50px; */
    
`;

// FILE
export const PopUp = styled.div`
    position:absolute;
    top: -40px;
    left: 0px;
    padding: 10px;
    min-width: 200px;
    background: #6f6f6f73;
    color: var(--text5);
    font-size: 12px;
    text-align: left;
    border-radius: var(--radius);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
`;

export const UploadPozWrapper = styled.div`
   width:300px;
   position: relative;
`;

export const UploadWrapper = styled.div`
    width:300px;
    height: 50px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    padding: 6px;
    margin-top: 10px;
    display: flex;
    align-items: center;
        &:hover{
            border-color: var(--acc-color);
        }
        >h5{
            /* position: absolute; */
            width: 100%;
            left: 10px;
            top: 50%;
            /* transform:translateY(-50%); */
            color: var(--text1);
            &:hover ${PopUp} {
                opacity: 1;
                visibility: visible;
            } 
        }
        >span{
            position: absolute;
            right: -60px;
            width: 50px;
            height: 50px;
            border-radius: var(--radius);
            cursor: pointer;
            color: var(--text5);
            font-size:20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${props => props.children[0].props.color !== '' ? 'var( --success)' : 'var(--background4)'};
            color: ${props => props.children[0].props.color !== '' ? 'var(--text5)' : 'var(--text2)'};
            backdrop-filter: blur(2px);
            box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        }
`;

export const Placeholder = styled.h5`
    font-size: 14px!important;
    color: var(--text3)!important;
    letter-spacing: 0 !important;
`;

export const UploadFileInput = styled.input`
    position: absolute;
    right: -60px;
    width: 50px;
    height: 50px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
        &::placeholder{
            color: var(--text2);
        }
        &::-webkit-file-upload-button{
            cursor: pointer;
        }
`;

export const ViewDoc = styled.a`
    position: absolute;
    right: -120px;
    background-color: var(--background4);
    color: var(--text2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: var(--radius);
    text-decoration: none;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;