import React, { useState, useEffect } from 'react';
// import { useHistory } from "react-router-dom";

// ASSETS
// import load from '../../../../Assets/loadingBtn.gif';

// PACKAGES
// import styled from 'styled-components';
import Select from 'react-select';

// COMPONENTS
import InputCss from '../../GeneralComponents/Inputs/Inputs';
// import CalendarJS from '../../Deposit/GeneralComponentsForDeposit/Calendar/Calendar';
import LoadingButton from '../../GeneralComponents/Button/LoadingButton';
import GoBackTitle from '../../GeneralComponents/GoBackButton/GoBackButton';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
// import { newService } from '../../../../Store/Actions/servicesActions';
// import { getAreas } from '../../../../Store/Actions/areasActions';

// STYLE
import {
    Form,
    // BtnWrapper,
    // Btn,
    // Title,
    // TitleWrapper,
    // GoBack,
    // Input,
    LabelCss,
    colourStyles,
} from '../PagesShareStyle/DetailsShareStyle';
import { WrapperRightSide } from '../PagesShareStyle/PagesShareStyle';


const NewUser = ({ details }) => {
    const dispatch = useDispatch();
    // let history = useHistory();
    // const areas = useSelector(({ areas }) => areas.allAreas);
    // const areas = []
    const [more, setMore] = useState({
        firstName: '',
        lastName: '',
        email: '',

    });
    const [stsBtn, setStsBtn] = useState(false);
    const [optArea, setOptArea] = useState();
    // const [selectedDayIs, setSelectedDayIs] = useState('');
    const [err, setErr] = useState(false);


    // console.log(selectedDayIs)
    // console.log(more);
    // console.log(optArea);

    const inputsLeft = [
        {
            label: 'Nome',
            inputType: 'text',
            placeholder: 'Inserisci il tuo nome',
            name: 'firstName',
            value: more.firstName,
        },
        {
            label: 'Cognome',
            inputType: 'text',
            placeholder: 'Inserisci il tuo cognome',
            name: 'lastName',
            value: more.lastName,
        },
        {
            label: 'E-mail',
            inputType: 'email',
            placeholder: 'Inserisci il tuo indirizzo e-mail',
            name: 'email',
            value: more.email,

        },
    ];

    // const notifDash = useSelector((state) => state.customNotifHandler.handlerNotifDash);
    // useEffect(() => {
    //     if (notifDash !== undefined) {
    //         if (notifDash.status === 200) {
    //             // setStsBtn(false);
    //             setTimeout(() => {
    //                 history.goBack();
    //             }, 2000);
    //         } else {
    //             setStsBtn(false);
    //         }
    //     }
    // }, [notifDash, history]);

    useEffect(() => {
        // dispatch(getAreas());
    }, [dispatch]);

    // useEffect(() => {
    //     if (areas !== undefined) {
    //         let opt = [];
    //         for (let i = 0; i < areas.length; i++) {
    //             opt.push({ label: areas[i].name, value: areas[i].idArea })
    //         };
    //         setOptArea(opt);
    //     }
    // }, [areas]);

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setMore({
            ...more,
            [name]: value
        });
    };

    // const submitForm = (e) => {
    //     e.preventDefault();
    //     setStsBtn(true);
    //     dispatch(newService(more, selectedDayIs));
    //     // setTimeout(() => {
    //     //     history.goBack();
    //     // }, 2000);
    // };

    const submitForm = (e) => {
        e.preventDefault();
        if (more.name !== '') {
            setStsBtn(true);
            // dispatch(newService(more));
        } else {
            setErr(true);
            setTimeout(() => {
                setErr(false);
            }, 4000);
        }
    };


    return (
        <WrapperRightSide>

            <GoBackTitle
                title='Nuovo utenti'
            />

            <Form onSubmit={submitForm}>
                <div>
                    {inputsLeft.map((inp, i) =>
                        <InputCss
                            key={i}
                            props={inp}
                            handleChange={handleChange}
                        />
                    )
                    }
                </div>
                {/* <CalendarJS
                    setSelectedDayIs={e => setSelectedDayIs(e)}
                /> */}

                {/* <LabelCss htmlFor='select'>Indirizzi di studio inclusi</LabelCss>
                <Select
                    name='area'
                    isMulti={true}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    // defaultValue={{ value: more.typeCenter, label: more.typeCenter }}
                    // onChange={(e) => handleSelect(e)}
                    onChange={(e) =>
                        setMore({
                            ...more,
                            area: e
                        })
                    }
                    options={optArea}
                    styles={colourStyles}
                    placeholder={'Scegli indirizzi di studio'}
                /> */}

                <LoadingButton
                    stsBtn={stsBtn}
                    label={'Creare utenti'}
                    err={err}
                />

            </Form>

        </WrapperRightSide>
    )
};

export default NewUser;