import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";


// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Lessons from './Lessons';

// PAGE


// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllSubjects } from '../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const MateriaWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Lesson = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background3);
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const Left = styled.div`
    
    
`;

const Right = styled.div`
    >span {
        font-size: 14px;
    }
    >i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) ${props => props.open === 'true' && 'rotate(-90deg)'};
        color: var(--text3);

    }
`;

const Sts = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    border-radius: var(--radius);
    background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
    >div:nth-last-child(1){
        border-radius:  0 0 var(--radius) var(--radius);
    }
`;


const Materia = ({ idCourse, idCheckUser, studDetails, course }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    const materie = useSelector(({ subjects }) => subjects.allSubjects);
    const [openLessons, setOpenLessons] = useState(false);
    const [mats, setMats] = useState();

    useEffect(() => {
        const id = idCheckUser !== undefined ? idCheckUser : profile.idUser;
        dispatch(getAllSubjects(idCourse, id));
    }, [dispatch]);

    useEffect(() => {
        let ret = []
        //console.log("")
        // if (profile.user_role === 'DOCENTE') {
        if (materie !== undefined) {
            //console.log("MATERIEEEEEEE", materie)
            if (profile.role === 'DOCENTE') {
                console.log(profile.skills)
                materie.forEach(el => {
                    if (
                        (el.visibility === 'Tutti' || el.visibility === 'Apprendimento') &&
                        profile.skills.includes(el.area)
                    ) {
                        ret.push(el)
                    }
                })
                ret.sort((a, b) => a.order - b.order)
                console.log("ret", ret);
                // setMats(ret);

                // FILTER AND EXCLUDE
                if (ret !== undefined) {
                    for (let i = 0; i < ret.length; i++) {
                        const prop = ret[i].lessons;
                        let flag = false;
                        for (let j = 0; j < prop.length; j++) {
                            if (prop[j].Lessons_idLesson_Lesson.excluded === null) {
                                setMats(ret);
                                //newQue.push(ret[i]);
                            } else {
                                flag = true
                                let newQue = [];
                                const findIfExists = JSON.parse(prop[j].Lessons_idLesson_Lesson.excluded).find(id => id === course.idCourse);
                                if (!findIfExists) {
                                    newQue.push(ret[i]);
                                }
                                setMats(newQue);
                            }
                        }
                        if (flag) { setMats(ret) }
                    }

                    //setMats(newQue);
                }
            } else {
                materie.forEach(el => {
                    if (
                        (el.visibility === 'Tutti' || el.visibility === 'Apprendimento')

                    ) {
                        ret.push(el)
                    }
                })
                ret.sort((a, b) => a.order - b.order)
                //console.log("ret", ret);
                // setMats(ret);
                //FILTER AND EXCLUDE
                if (ret !== undefined) {
                    console.log("RET", ret)
                    for (let i = 0; i < ret.length; i++) {
                        const prop = ret[i].lessons;
                        //console.log("PORP", prop)
                        let flag = false;
                        for (let j = 0; j < prop.length; j++) {
                            let newProp = [];
                            if (prop[j].Lessons_idLesson_Lesson.excluded === null) {
                                //console.log("DIOOOO", prop[j])
                                //newProp.push(ret)
                                //setMats(newProp);
                                setMats(ret)

                            } else {
                                flag = true
                                const findIfExists = JSON.parse(prop[j].Lessons_idLesson_Lesson.excluded).find(id => id === course.idCourse);
                                if (!findIfExists) {
                                    newProp.push(ret[i]);
                                }
                                setMats(newProp);
                            }
                        }
                        if (flag) { setMats(ret) }
                    }
                }
                // if (ret !== undefined) {
                //     let newQue = [];
                //     for (let i = 0; i < ret.length; i++) {
                //         const prop = ret[i].lessons;
                //         for (let j = 0; j < prop.length; j++) {
                //             if (prop[j].Lessons_idLesson_Lesson.excluded === null) {
                //                 // setMats(ret);
                //                 newQue.push(ret[i]);
                //             } else {
                //                 const findIfExists = JSON.parse(prop[j].Lessons_idLesson_Lesson.excluded).find(id => id === course.idCourse);
                //                 if (!findIfExists) {
                //                     newQue.push(ret[i]);
                //                 }
                //                 // setMats(newQue);
                //             }
                //         }
                //     }
                //     setMats(newQue);
                // }
            }
        }
        //} 
        // else {
        //     if (materie !== undefined) {
        //         materie.forEach(el => {
        //             if (
        //                 (el.visibility === 'Tutti' || el.visibility === 'Apprendimento') &&
        //                 profile.skills.includes(el.area)
        //             ) {
        //                 ret.push(el)
        //             }
        //         })
        //         ret.sort((a, b) => a.order - b.order)
        //         // console.log("ret", ret);
        //         setMats(ret);
        //     }
        // }
    }, [materie]);

    console.log('mats', mats)
    // console.log("DETAILS", idCourse);
    // console.log('MATERIA', mats);
    // console.log("MATERIE", materie);    
    // console.log("PROFILE", profile);


    return (
        (mats !== undefined && mats.length > 0) &&
        <MateriaWrapper>
            {mats !== undefined && mats.length > 0 &&
                <>
                    <Header>{mats.length} Materie</Header>
                    {mats.map((mat, i) =>
                        <div key={i}>
                            <Lessons
                                mat={mat}
                                idCheckUser={idCheckUser}
                                studDetails={studDetails}
                                course={course}
                            />
                        </div>
                    )}
                </>
            }
        </MateriaWrapper>
    );
};

export default Materia;

