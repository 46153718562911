import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

//COMPONENTS
import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions'
import FooterBar from '../../GeneralComponents/TestViewsBars/FooterBar';
import QuestionBool from './QuestionBool';
import QuestionFree from './QuestionFree';
import QuestionOne from './QuestionOne';
import MultipleAnswers from './MultipleAnswers';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { saveTpaTest, getAllTpaTestDone } from '../../../Store/Actions/tpaActions';
import { getAllTeachers } from '../../../Store/Actions/usersActions';
import { findCourse } from '../../../Store/Actions/coursesActions';
import { newNotifications } from '../../../Store/Actions/notificationsActions';

//STYLE
import { TestWrapper } from '../PagesShareStyle/PagesShareStyle';

const TpaSwitchTest = ({ tests, setRest, setFinishTheTest, type, opt, course, tpaType }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const whoAmI = useSelector(({ auth }) => auth.user);
    const allCenterTeachers = useSelector(({ users }) => users.allCenterTeachers);
    const aCourse = useSelector(({ courses }) => courses.aCourse);
    const socket = useSelector(({ conversations }) => conversations.socket);
    const [id, setId] = useState(0);
    const [obj, setObj] = useState([]);

    useEffect(() => {
        let createObj = [];
        for (let i = 0; i < tests.length; i++) {
            createObj.push({})
        }
        setObj(createObj);
    }, [tests]);

    const setAnswer = (e, i) => {
        //console.log(e);
        const value = [...obj]
        value[id].asw = e.target.innerHTML;
        value[id].aswIndex = i;
        setObj(value);

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);

        // console.log(res)
    };

    const setAnswerFree = (e, i) => {
        //console.log("ASTA", e)
        const value = [...obj]
        value[id].asw = e;
        //value[id].aswIndex = i;
        setObj(value);

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);
    };

    const setAnswerMultiple = (e, i) => {

        let asws = (obj[id] !== undefined && Object.keys(obj[id]).length > 0) ? obj[id].asw : [];
        let indexes = (obj[id] !== undefined && Object.keys(obj[id]).length > 0) ? obj[id].aswIndex : [];

        const findInd = indexes.find((ind) => ind === i)
        if (findInd === undefined) {
            asws.push(opt[i].value)
            indexes.push(i)
        } else {
            // const filteredAsws = asws.filter((asw, index) => index !== i)
            const filteredAsws = asws.filter(asw => asw !== e.target.innerHTML)
            const filteredIdxs = indexes.filter((ind) => ind !== i)
            asws = filteredAsws
            indexes = filteredIdxs
        }

        // console.log ('asw',asws)
        // console.log ('indexes',indexes)

        const value = [...obj]
        value[id].asw = asws;
        value[id].aswIndex = indexes;
        setObj(value);

        let res = 0
        for (let i = 0; i < value.length; i++) {
            if (Object.keys(value[i]).length > 0) {
                res += 1
            }
        }
        // setRest(res);
        // localStorage.setItem('rest', res);
        // console.log(res)
    };

    useEffect(() => {
        if (whoAmI.role === 'STUDENTE') {
            dispatch(getAllTeachers(whoAmI));
            dispatch(findCourse(course));
        };
    }, [whoAmI]);

    const submitTest = () => {
        let verif = 0;
        for (let i = 0; i < obj.length; i++) {
            if (Object.keys(obj[i]).length > 0) {
                verif = + 1
            }
        };

        dispatch(saveTpaTest(
            params.type.split(' ')[0],
            tests,
            obj,
            whoAmI.idUser,
            course,

            allCenterTeachers,
            whoAmI,
            aCourse[0],
            tpaType,
            verif
        ));

        if (verif === obj.length) {
            socket.emit('sendNotifToProf', {
                senderId: whoAmI.idUser,
                receiverId: allCenterTeachers,
                text: 'Test done!',
                author: whoAmI,
                course: aCourse[0],
                testType: tpaType
            });

            // dispatch(newNotifications(whoAmI.idUser,
            //     JSON.stringify(allCenterTeachers),
            //     whoAmI,
            //     aCourse[0],
            //     //{ questions: JSON.stringify(tests), answers: JSON.stringify(obj) },
            //     '',
            //     tpaType
            // ));
        };

        dispatch(getAllTpaTestDone(whoAmI.idUser))
        setFinishTheTest(true);
        setTimeout(() => {
            window.close();
        }, 5000);
    };

    // console.log('OBJ', obj);
    // console.log("PARAMS", params);
    // console.log("TEST", type);
    // console.log("COURSE", whoAmI);
    // console.log("ALL TEACHERS", allCenterTeachers);
    // console.log("TEST", tests);

    return (
        <>
            <TestWrapper >
                <AllQuestionsSideBar
                    id={id}
                    setId={setId}
                    //difficulty={tests[id].difficulty}
                    arr={tests}
                    obj={obj}
                />

                {tests !== undefined &&
                    type === 'bool' ?
                    <QuestionBool
                        tests={tests}
                        id={id}
                        setAnswers={setAnswer}
                        obj={obj}
                        opt={opt}
                    />
                    : type === 'free' ?
                        <QuestionFree
                            tests={tests}
                            id={id}
                            setAnswers={setAnswerFree}
                            obj={obj}

                        />
                        : type === 'one' ?
                            <QuestionOne
                                tests={tests}
                                id={id}
                                setAnswers={setAnswer}
                                obj={obj}
                                opt={opt}

                            />
                            : type === 'multiple' ?
                                <MultipleAnswers
                                    tests={tests}
                                    id={id}
                                    setAnswers={setAnswerMultiple}
                                    obj={obj}
                                    opt={opt}

                                />
                                :
                                <></>
                }
            </TestWrapper>

            <FooterBar
                id={id}
                setId={setId}
                tests={tests}
                submitTest={submitTest}
                flag={'TPA'}
                obj={obj}
            />
        </>

    )
}

export default TpaSwitchTest;