import React, { useState } from 'react';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector } from 'react-redux';

import {
    Td,
    // ExpandTableRates,
    ExpandBtn,
    TrDiv,
    // LinkDiv,
    PopUp2,
    EditWrapper,
    Edit,
    TdHeader,
    // PopUpTd,
    // colourStyles
} from '../PagesShareStyle/PagesShareStyle';

const Sts = styled.div`
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    max-width: 90px;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        (props.color === 'Incompleto' && 'var(--warning)') ||
        (props.color === 'Completato' && 'var(--success)')
    };
`;


const TemaExercisesDoneTable = ({ elem, i, details, allDetails, type, svolgere }) => {
    const profile = useSelector(({ auth }) => auth.user);
    const [viewArea, setViewArea] = useState(false);

    const handleStatus = () => {
        if (elem.answers === null) {
            return 'Incompleto'
        } else {
            const asw = JSON.parse(elem.answers);
            const qui = JSON.parse(elem.questions);

            let rez = 0;
            for (let i = 0; i < asw.length; i++) {
                if (Object.keys(asw[i]).length > 0) {
                    rez += 1
                }
            }
            if (rez < qui.length) {
                return 'Incompleto'
            } else {
                return 'Completato'
            }
        }
    };

    const nrAnsw = (type) => {
        if (elem.answers !== undefined) {
            const asw = JSON.parse(elem.answers);
            const qui = JSON.parse(elem.questions);

            let rez = 0;
            if (type !== 'Avvio alla scrittura') {
                for (let i = 0; i < asw.length; i++) {
                    if (Object.keys(asw[i]).length > 0) {
                        rez += 1
                    }
                }
            } else {
                for (let i = 0; i < asw.length; i++) {
                    if (asw[i].check === true) {
                        rez += 1
                    }
                };
            }

            return rez;
        }
    };

    const formatData = (data) => {
        // console.log(data);
        if (data !== null) {
            const extractData = data.split('T')
            let format;
            if (extractData[0] !== '') {
                const d = extractData[0].split('-');
                format = d[2] + '/' + d[1] + '/' + d[0];
            } else {
                format = ''
            }
            return format
        } else return ''

    };

    // console.log('PROFILE', profile);
    // console.log('TEMA TABLE ', details);
    // console.log("ELEM", type);
    // console.log('elem', elem);

    return (
        <>
            < tr >
                {/* <Td>{formatData(elem.obj.date)}</Td> */}
                <Td>{formatData(elem.date)}</Td>

                {(type === 'Registri linguistici' ||
                    type === 'Avvio alla scrittura') ?
                    <Td>
                        {elem.answers === null && elem.answers !== undefined ? '0' : nrAnsw(type)}/
                        {elem.questions !== undefined && JSON.parse(elem.questions).length}
                    </Td>
                    :
                    <Td>
                        1/1
                    </Td>
                }

                {/* {(type === 'Registri linguistici' ||
                    type === 'avvio-alla-scrittura') &&
                    <Td>
                        <Sts
                            color={handleStatus()}
                        >
                            {handleStatus()}
                        </Sts>
                    </Td>
                } */}

                <Td>
                    {elem.grade !== null ?
                        elem.grade
                        :
                        'In attesa'
                    }
                </Td>
                <Td >
                    <div style={{ display: 'flex' }}>
                        <EditWrapper>
                            {/* {details.type === 'WARTEGG' || details.type === 'TEST GRAFICI' ? */}
                            {
                                // elem.type === 'wartegg' || elem.type === 'test-grafici' ?
                                profile.role === 'STUDENTE' ?
                                    <Edit
                                        to={{
                                            pathname: '/dashboard/view-tema-test-done',
                                            state: { elem, type, svolgere }
                                        }}
                                    >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </Edit>
                                    :
                                    <Edit
                                        to={{
                                            pathname: '/dashboard/tema-test-verification',
                                            state: { elem, allDetails, type, svolgere }
                                        }}
                                    >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </Edit>
                                // :
                                // <Edit
                                //     to={{
                                //         pathname: '/dashboard/tpa-test-verification',
                                //         state: { elem, allDetails }
                                //     }}
                                // >
                                //     <i className="fa-solid fa-ellipsis-vertical"></i>
                                // </Edit>
                            }
                            <PopUp2>Di più</PopUp2>
                        </EditWrapper>
                    </div>
                </Td>
            </tr >
        </>
    )
}

export default TemaExercisesDoneTable;

