import axios from 'axios';
axios.defaults.withCredentials = true;

export const getAllUsers = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllUsers`,)
        .then((res) => {
            // console.log("ALL_COURSES", res);
            dispatch({
                type: 'ALL_USERS',
                payload: res.data,
            });
            // dispatch({
            //     type: 'ALL_SUBJECTS',
            //     payload: undefined,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllStudents = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllStudents`,)
        .then((res) => {
            // console.log("ALL_COURSES", res);
            dispatch({
                type: 'ALL_STUDENTS',
                payload: res.data,
            });
            // dispatch({
            //     type: 'ALL_SUBJECTS',
            //     payload: undefined,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllTeachersAndStudents = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllTeachersAndStudents `,)
        .then((res) => {
            // console.log("ALL_COURSES", res);
            dispatch({
                type: 'ALL_PROFS_AND_STUDS',
                payload: res.data,
            });
            // dispatch({
            //     type: 'ALL_SUBJECTS',
            //     payload: undefined,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCourseProgress = (idUser, idCourse) => (dispatch, getState) => {

    const body = {
        idUser: idUser,
        idCourse: idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getUserProgressStatusCourse `, body)
        .then((res) => {
            // console.log("ALL_COURSES", res);
            dispatch({
                type: 'PROGRESS_USER_COURSE',
                payload: res.data,
            });
            // dispatch({
            //     type: 'ALL_SUBJECTS',
            //     payload: undefined,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const editUser = (more, idUser) => (dispatch, getState) => {

    const body = {
        idUser: idUser,
        user_name: more.firstName,
        user_surname: more.lastName,
        user_email: more.email
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/editUser`, body)
        .then((res) => {
            // console.log("EDIT_USER", res);
            dispatch({
                type: 'ALL_USERS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const bringTestsDoneForUsers = (course) => (dispatch, getState) => {
    // const profile = getState().auth.user;
    const id = course.Users_follow_Courses.idUsers_idUser;

    const body = {
        userID: id,
        // userID: profile.idUser,
        courseID: course.idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/bringTestsDone`, body)
        .then((res) => {
            // console.log("BRING_TESTS_DONE", res.data);
            dispatch({
                type: 'BRING_TESTS_DONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const bringSimulationsDoneForUsers = (course) => (dispatch, getState) => {
    // const profile = getState().auth.user;
    const id = course.Users_follow_Courses.idUsers_idUser;

    const body = {
        userID: id,
        // userID: profile.idUser,
        courseID: course.idCourse
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/bringSimulationsDone`, body)
        .then((res) => {
            // console.log("BRING_SIMULATIONS_DONE", res.data);
            dispatch({
                type: 'BRING_SIMULATIONS_DONE',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllWrongQuezzes = (id) => (dispatch, getState) => {
    // const profile = getState().auth.user;
    const idUsr = id.Users_follow_Courses.idUsers_idUser;


    const body = {
        userID: idUsr,
        // userID: profile.idUser,
        courseID: id.idCourse,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getAllWrongQuezzes`, body)
        .then((res) => {
            // console.log("WRONG_QUEZZES", res);
            dispatch({
                type: 'WRONG_QUEZZES',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteUser = (id) => (dispatch, getState) => {

    const body = {
        idUser: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteUser`, body)
        .then((res) => {
            // console.log("DELETE_USER", res);
            // dispatch({
            //     type: 'DELETE_USER',
            //     payload: res.data,
            // });
        })
        .catch((err) => {
            console.log(err);
        });
};

// export const getUserGeneralStatus = (idCourse, idUser) => (dispatch, getState) => {

//     const body = {
//         idUser: idUser,
//         idCourse: idCourse,
//     };

//     axios
//         .post(`${process.env.REACT_APP_URL_BACKEND}/getUserGeneralStatus`, body)
//         .then((res) => {
//             console.log("GENERAL_STATUS", res);
//             // dispatch({
//             //     type: 'GENERAL_STATUS',
//             //     payload: res.data,
//             // });
//         })
//         .catch((err) => {
//             console.log(err);
//         });
// };

export const getActvity = (idTeacher) => (dispatch, getState) => {

    const body = {
        teacher: idTeacher,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/getActvity`, body)
        .then((res) => {
            // console.log("TEACHER_ACTIVITY", res);
            dispatch({
                type: 'TEACHER_ACTIVITY',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllTeachers = (whoAmI) => (dispatch, getState) => {

    const body = {
        profile: whoAmI
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllTeachers`, body)
        .then((res) => {
            // console.log("ALL_CENTER_TEACHERS", res.data);
            dispatch({
                type: 'ALL_CENTER_TEACHERS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllTeachersForTema = (whoAmI) => (dispatch, getState) => {

    const body = {
        profile: whoAmI
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/findAllTeachersForTema`, body)
        .then((res) => {
            // console.log("ALL_CENTER_TEACHERS_FOR_TEMA", res.data);
            dispatch({
                type: 'ALL_CENTER_TEACHERS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};