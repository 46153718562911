import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const NavTable = styled.div`
    width:100%;
    padding-top:10px;
    display: flex;
    justify-content: space-between;
        >div>h5{
            color: var(--text3);
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
        }
        >ul{
            display: flex;
            justify-content: flex-end;
            list-style: none;
            padding:0;
        }
        .previous,
        .next{
            color:var(--text2);
           >a{
            width:40px;
            height:40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:5px;
            cursor: pointer;
                :hover{
                    background:var(--hover);
                    color: var(--text5);
                }
                >i{
                    font-size: 20px;
                }
           }
        }
        .numbers,
        .breaak-me{
            width:40px;
            height:40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:5px;
            cursor: pointer;
            color:var(--text2);
            font-weight:bold;
                :hover{
                    background:var(--hover);
                    color: var(--text5);
                }
        }
        .active{
            background: var(--acc-color);
            color: var(--text5);
                :hover{
                    background:var(--acc-color);
                    color: var(--text5);
                }
        }
        .disabled{
            color: var(--text4);
            >a{
                :hover{
                        background:transparent;
                        color: var(--text4);
                        cursor: default;
                    }
            }
        }
`;

export const colourStyles = {
    control: styles => ({
        ...styles,
        width: 100,
        backgroundColor: 'transparent',
        borderRadius: '4px',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
        color: 'var(--text1)',
    }),
    menu: styles => ({
        ...styles,
        width: 100,
        backgroundColor: 'var(--background4)',
        border: 'none',
        fontSize: '14px',
        color: 'var(--text1)',
        zIndex: 2,
    }),

    input: styles => ({ ...styles, fontSize: '14px', color: 'var(--text1)', border: '1px solid var(--border)', }),
    placeholder: styles => ({ ...styles, fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};