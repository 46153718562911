import React, { useState } from 'react';

// PACKEGES
import styled from 'styled-components';

// REDUX
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../../Store/Actions/usersActions';

// SHARE STYLE
import {
    IconWrapper,
    PopUp,
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
} from './ModalStyle';

const H4 = styled.h4`
    width:100%;
    text-align: center;
    color: var(--text3);
    padding: 10px;
`;

// const Btns = styled.button`
//     width: 200px;
//     height:50px;
//     margin-right: 10px;
//     display:flex;
//     align-items: center;
//     justify-content:center;
//     color:var(--text2);
//     font-weight:bold;
//     font-size: 14px;
//     border-radius: var(--radius);
//     margin-top: 40px;
//     margin-bottom:20px;
//     letter-spacing:2px;
//     cursor: pointer;
//     transition: var(--transition) ease;
//     background: var(--background4);
//     border: none;
//     outline:none;
//     text-decoration: none;
//     box-shadow: var(--shadow);
//         >i{
//             margin-right: 10px;
//             font-size: 20px;
//         }
//         &:hover{
//             box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
//         }
//         &:active{
//             box-shadow: none;
//         }
// `;


const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
    };

    // console.log('PROPS', props)

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.flag === 'event') {
            // dispatch(deleteEvent(props.id))
        }
        if (props.flag === 'user') {
            dispatch(deleteUser(props.id))
        }
        handleClose(e);
    };

    return (
        <>
            <IconWrapper style={{ color: 'var(--err)' }} onClick={() => setOpenModal(!openModal)}>
                <PopUp>{props.title}</PopUp>
                {props.icon}
            </IconWrapper>

            {/* <Btns  onClick={() => setOpenModal(!openModal)}>{props.name}</Btns> */}

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>{props.title}</H3>
                        <Hr></Hr>

                        <H4>{props.body}</H4>

                        <BtnWrapper>
                            <Btn disable onClick={handleClose}>Annulla</Btn>
                            <Btn onClick={handleSubmit}>Rimuovi</Btn>
                        </BtnWrapper>
                    </DialogBox>

                </>
            }

        </>

    );
}
export default DeleteModal;