import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

//COMPONENTS
import AllQuestionsSideBar from '../../GeneralComponents/AllQuestions/AllQuestions';
import FooterBarForSimpleTema from '../../GeneralComponents/TestViewsBars/FooterBarForSimpleTema';
import TestTraccia from './TestTraccia';
import TestScaletta from './TestScaletta/TestScaletta';
import TestCoerenza from './TestCoerenza';
import TestRevisione from './TestRevisione';
import TestRegistri from './TestRegistri';
import TestAvvio from './TestAvvio';

// import QuestionBool from './QuestionBool';
// import QuestionFree from './QuestionFree';
// import QuestionOne from './QuestionOne';
// import MultipleAnswers from './MultipleAnswers';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
    saveTemaTest,
    getAllTemaTestDone,
    saveTraceTest,
    saveScalettaTest,
    saveCoerenzaTest,
    saveRevisioneTest,
    saveRegistriTest,
    saveAvvioTest
} from '../../../Store/Actions/temaActions';
import { getAllTeachersForTema } from '../../../Store/Actions/usersActions';
import { findCourse } from '../../../Store/Actions/coursesActions';
import { newNotifications } from '../../../Store/Actions/notificationsActions';

//STYLE
// import { TestWrapper } from '../PagesShareStyle/PagesShareStyle';


const TestWrapper = styled.div`
    position: relative;
    width: ${props => props.nav ? 'calc(100% - 240px)' : '100%'};
    margin-top: ${props => props.nav && '80px'};;
    /* height: calc(100vh - 260px); */
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    background: var(--background2);
    overflow: auto;
        @media (max-width: 1100px) {
            width: 100%;
        };
`;

const TemaSwitchTest = ({ tests, setRest, rest, setFinishTheTest, opt, course, temaType }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const whoAmI = useSelector(({ auth }) => auth.user);
    const allCenterTeachers = useSelector(({ users }) => users.allCenterTeachers);
    const aCourse = useSelector(({ courses }) => courses.aCourse);
    const socket = useSelector(({ conversations }) => conversations.socket);
    const [id, setId] = useState(0);
    const [obj, setObj] = useState([]);

    console.log("TESTS", tests)
    console.log("REST", rest)
    useEffect(() => {
        if (whoAmI.role === 'STUDENTE') {
            dispatch(getAllTeachersForTema(whoAmI));
            dispatch(findCourse(course));
        } else {
            dispatch(findCourse(course));
        }
    }, [whoAmI]);

    useEffect(() => {
        let createObj = [];
        // if (temaType === 'registri-linguistici' || temaType === 'avvio-alla-scrittura') {
        if (temaType === 'registri-linguistici') {
            for (let i = 0; i < tests.length; i++) {
                createObj.push({})
            }
            setObj(createObj);
        }
    }, [tests, temaType]);

    const setAnswerWithMultipleQue = (e, i) => {
        // console.log(e);
        const value = [...obj]
        if (e !== '') {
            value[id].asw = e;
            value[id].aswIndex = i;
            setObj(value);
        } else {
            value[id] = {}
            setObj(value);
        }

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            if (Object.keys(value[j]).length > 0) {
                res += 1
            }
        }
        setRest(res);
    };

    const setAnswerAvvio = (e, i, text) => {
        // console.log('heheheheheh', JSON.stringify(e));
        console.log('heheheheheh', text);
        const value = [...obj]
        // value[id].asw = e;
        // value[id].aswIndex = i;
        // setObj(value);

        // if (test[id].body === text){

        // }

        if (e !== null) {
            value[id].asw = e;
            value[id].aswTxt = text;
            value[id].aswIndex = i;
            value[id].check = true;
            setObj(value);
        } else {
            setObj(obj);
        }

        let res = 0;
        for (let j = 0; j < value.length; j++) {
            // if (Object.keys(value[j]).length > 0) {
            //     res += 1
            // }
            if (obj[j].check === true) {
                res += 1
            }
        }
        setRest(res);
    };

    const [inputFields, setInputFields] = useState({
        intro: [{ intro: '', subIntro: '' }],
        midd: [{ midd: '', subMidd: '' }],
        conc: [{ conc: '', subConc: '' }]
    });

    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');

    const setAnswer = (e) => {
        setObj(e);
    };

    const setAnswersForScaletta = (e) => {
        if (e.flag === 'intro') {
            setInputFields({
                ...inputFields,
                intro: e.values
            })
        };

        if (e.flag === 'midd') {
            setInputFields({
                ...inputFields,
                midd: e.values
            })
        };

        if (e.flag === 'conc') {
            setInputFields({
                ...inputFields,
                conc: e.values
            })
        };

        setObj([inputFields])
    };

    const setAnswersForCoerenza = (e) => {
        setObj(e)
    };

    console.log("A course", aCourse)
    const submitTest = () => {
        // let verif = 0;
        // for (let i = 0; i < obj.length; i++) {
        //     if (Object.keys(obj[i]).length > 0) {
        //         verif = + 1
        //     }
        // };

        if (temaType === 'analisi-della-traccia') {
            dispatch(saveTraceTest(
                tests,
                obj,
                whoAmI.idUser,
                course,

                allCenterTeachers,
                whoAmI,
                aCourse[0],
                temaType,
                'traccia',
            ));
        }

        if (temaType === 'elaborazione-del-testo-o-scaletta') {
            console.log("MATAAAAAA")
            console.log("ASTAAAA", tests)
            dispatch(saveScalettaTest(
                tests,
                obj,
                whoAmI.idUser,
                course,

                allCenterTeachers,
                whoAmI,
                aCourse[0],
                temaType,
                'scaletta'
            ));
        }

        if (temaType === 'coerenza-e-stesura-del-testo') {
            dispatch(saveCoerenzaTest(
                tests,
                obj,
                whoAmI.idUser,
                course,

                allCenterTeachers,
                whoAmI,
                aCourse[0],
                temaType,
                'coerenza'
            ));
        }

        if (temaType === 'stesura-e-revisione-del-tema') {
            dispatch(saveRevisioneTest(
                tests,
                obj,
                whoAmI.idUser,
                course,

                allCenterTeachers,
                whoAmI,
                aCourse[0],
                temaType,
                'revisione'
            ));
        }

        if (temaType === 'registri-linguistici') {
            dispatch(saveRegistriTest(
                tests,
                obj,
                whoAmI.idUser,
                course,

                allCenterTeachers,
                whoAmI,
                aCourse[0],
                temaType,
                'registri'
            ));
        }

        if (temaType === 'avvio-alla-scrittura') {
            dispatch(saveAvvioTest(
                tests,
                obj,
                whoAmI.idUser,
                course,

                allCenterTeachers,
                whoAmI,
                aCourse[0],
                temaType,
                'avvio'
            ));
        }

        // dispatch(saveTemaTest(
        //     params.type.split(' ')[0],
        //     tests,
        //     obj,
        //     whoAmI.idUser,
        //     course,

        //     allCenterTeachers,
        //     whoAmI,
        //     aCourse[0],
        //     temaType,
        //     verif
        // ));

        // console.log('VERIF + OBJ', verif, obj.length, obj);

        // if (verif === obj.length) {
        // socket.emit('sendNotifToProf', {
        //     senderId: whoAmI.idUser,
        //     receiverId: allCenterTeachers,
        //     text: 'Test done!',
        //     author: whoAmI,
        //     // course: aCourse[0],
        //     testType: temaType
        // });
        // };

        socket.emit('sendNotifToProf', {
            senderId: whoAmI.idUser,
            receiverId: allCenterTeachers,
            text: 'Test done!',
            author: whoAmI,
            course: aCourse[0],
            testType: temaType
        });

        dispatch(getAllTemaTestDone(whoAmI.idUser, aCourse[0]));
        setFinishTheTest(true);
        setTimeout(() => {
            window.close();
        }, 5000);
    };

    console.log('OBJ', obj);
    // console.log("PARAMS", params);
    // console.log("TEST", type);
    console.log("COURSE", aCourse);
    // console.log("ALL TEACHERS", allCenterTeachers);
    console.log("TEST", tests);
    // console.log('TEST SCALETTA', inputFields);
    // console.log('temaType', temaType);
    // console.log('id', id);

    return (
        <>
            <TestWrapper
                nav={(temaType === 'registri-linguistici' ||
                    temaType === 'avvio-alla-scrittura') && true}
            >
                {(temaType === 'registri-linguistici' ||
                    temaType === 'avvio-alla-scrittura') &&
                    <AllQuestionsSideBar
                        id={id}
                        setId={setId}
                        arr={tests}
                        obj={obj}
                        flag="tema"
                        temaType={temaType}
                    />
                }

                {/* 1. */}
                {temaType === 'analisi-della-traccia' &&
                    <TestTraccia
                        tests={tests}
                        id={id}
                        setAnswers={setAnswer}
                        obj={obj}
                        opt={opt}
                    />
                }
                {/* 2. */}
                {temaType === 'elaborazione-del-testo-o-scaletta' &&
                    <TestScaletta
                        tests={tests}
                        id={id}
                        setAnswers={setAnswersForScaletta}
                        obj={obj}
                        opt={opt}
                    />
                }
                {/* 3. */}
                {temaType === 'coerenza-e-stesura-del-testo' &&
                    <TestCoerenza
                        tests={tests}
                        id={id}
                        setAnswers={setAnswersForCoerenza}
                        obj={obj}
                        opt={opt}
                    />
                }
                {/* 4. */}
                {temaType === 'stesura-e-revisione-del-tema' &&
                    <TestRevisione
                        tests={tests}
                        id={id}
                        setAnswers={setAnswer}
                        obj={obj}
                        opt={opt}
                    />
                }
                {/* 5. */}
                {temaType === 'registri-linguistici' &&
                    <TestRegistri
                        tests={tests}
                        id={id}
                        setAnswers={setAnswerWithMultipleQue}
                        obj={obj}
                        opt={opt}
                    />
                }
                {/* 6. */}
                {temaType === 'avvio-alla-scrittura' &&
                    <TestAvvio
                        tests={tests}
                        id={id}
                        setAnswers={setAnswerAvvio}
                        obj={obj}
                        opt={opt}
                        setObj={setObj}
                    />
                }
            </TestWrapper>

            <FooterBarForSimpleTema
                id={id}
                setId={setId}
                tests={tests}
                submitTest={submitTest}
                flag={'Tema'}
                obj={obj}
                temaType={temaType}
                rest={rest}
            />
        </>
    )
}

export default TemaSwitchTest;