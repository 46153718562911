import React from 'react';

// ASSETS
// import pic from '../../../Assets/Capture.PNG'

// PACKAGES
import styled from 'styled-components';

// COMPONENTS

// REDUX
import { useSelector } from 'react-redux';


const TestBody = styled.div`
    padding-bottom: 20px;
    >img{
        max-width: 400px;
        max-height: 400px;
    }
`;

const Question = styled.div`
    color:  var(--text2);
    font-size: 20px;
    font-weight: bold;
 
`;

const Letter = styled.div`
    color:  var(--text2);
    font-size: 24px;
    font-weight: bold;
    display: flex;
    padding: 20px;
    background-color: var(--background3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Answers = styled.div`
    width: 100%;
    color:  var(--text2);
    font-size: 20px;
    display: flex;
    padding: 20px;
    background-color: var(--background4);
`;

const AnswerWrapper = styled.div`
    width: 80%;
    display: flex;
    overflow: hidden;
    border-radius: var(--radius);
    margin-top: 20px;
    cursor: pointer;
    ${Letter}{
            background: ${props => props.selected === 'selected' && 'var(--warning)'}!important;
            color: ${props => props.selected === 'selected' && 'var(--text5)'}!important;
        }
        ${Answers}{ 
            background: ${props => props.selected === 'selected' && '#f8940655'}!important;
        }
        &:hover ${Letter}{ 
            background: #99979791;
            color: var(--text5);
        }
        &:hover ${Answers}{ 
            background: #bbbbbb55;
        } 
`;

const CommentWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
        >div{
            width: 100%
            height: auto;
            min-width: 250px;
            background: var(--background4);
            border-radius: var(--radius);
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
            >span{
                margin-bottom: 10px;
            }
        }
`;

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z'];


const MultipleAnswers = ({ tests, id, setAnswers, obj, opt, flag, doneAllObj }) => {
    const whoAmI = useSelector(({ auth }) => auth.user);
    const commObj = doneAllObj !== undefined && doneAllObj.comments !== null ? JSON.parse(doneAllObj.comments) : [];

    const makeASelection = (j) => {
        if (obj[id] !== undefined && Object.keys(obj[id]).length > 0) {
            for (let i = 0; i < obj[id].aswIndex.length; i++) {
                if (obj[id].aswIndex[i] === j) return 'selected'
            }
        }
    };

    //console.log("QUESTIONS", tests);
    // console.log('OBJ', obj);
    // console.log('commObj', doneAllObj);

    return (
        <TestBody>
            <Question>{tests[id].question}</Question>

            {opt.map((ans, i) =>
                <AnswerWrapper
                    key={i}
                    onClick={(e) => setAnswers(e, i)}
                    selected={makeASelection(i)}
                >
                    <Letter>{letters[i]}</Letter>
                    {/* <Answers id={i}>{ans.value}</Answers> */}
                    <Answers id={i}
                        dangerouslySetInnerHTML={{ __html: ans.value }}
                    />
                </AnswerWrapper>
            )}

            {flag === 'verification' &&
                whoAmI.role === 'STUDENTE' &&
                commObj.length > 0 &&
                (Object.keys(commObj[id]).length) > 0 &&
                <CommentWrapper>
                    {commObj[id].map((com, i) =>
                            <div key={i}>
                                {i + 1}. <br></br>
                                {com.message}
                            </div>
                        )
                    }
                </CommentWrapper>
            }
        </TestBody>
    );
}

export default MultipleAnswers;