import React from 'react';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS


const Body = styled.div`
    margin-left:250px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid var(--text4);
    background: var(--background2);
        >h6{
            color: var(--text2);
        }
        >h4{
            color: var(--text3);
            padding-bottom: 10px;
        }
        @media (max-width: 1100px) {
            width:100%;
            margin-left:0;
        };  
`;

const LinkContainer = styled.div`
    width: 100%;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;    
`;

const LinkWrapper = styled.div`
    border-radius: 35px;
    color: var(--text1);
    border: 1px solid var(--text1);
    margin: 0 2px;
        >a{
            width: 35px;
            height: 35px;
            border-radius: 35px;
            text-decoration: none;
            color: var(--text1);
            display: flex;
            align-items: center;
            justify-content: center;
                &:hover{
                    background: var(--text1);
                    >i {
                        color: var(--text5);
                    }
                }
        }
`;


const Footer = () => {

    const socialBtns = [
        {
            name: 'Facebook',
            icon: <i className="fa-brands fa-facebook-f"></i>,
            path: 'https://www.facebook.com/checkpoint/828281030927956/?next=https%3A%2F%2Fwww.facebook.com%2Fnissolinocorsi%2F',
        },
        {
            name: 'Instagram',
            icon: <i className="fa-brands fa-instagram"></i>,
            path: 'https://www.instagram.com/nissolinocorsi/',
        },
        {
            name: 'YouTube',
            icon: <i className="fa-brands fa-youtube"></i>,
            path: 'https://www.youtube.com/c/nissolinocorsiroma',
        },
        {
            name: 'Twitter',
            icon: <i className="fa-brands fa-twitter"></i>,
            path: 'https://twitter.com/nissolinocorsi',
        },
        {
            name: 'LinkedIn',
            icon: <i className="fa-brands fa-linkedin-in"></i>,
            path: 'https://www.linkedin.com/company/nissolinocorsi/',
        },
    ]

    return (
        <Body>
            <h4>Seguici su:</h4>
            <LinkContainer >
                {socialBtns.map((soc, i) =>
                    <LinkWrapper
                        key={i}
                    >
                        <a href={soc.path} target="_blank" rel="noopener noreferrer">
                            {soc.icon}
                        </a>
                    </LinkWrapper>
                )
                }
            </LinkContainer>

            <div>
                <h6>
                    Copyright © 2020 - www.nissolinocorsi.com è di proprietà della EdiForm Srl — Sede legale: Via Alessio Olivieri, 27 - 00124 Roma
                </h6>

                <h6>
                    PEC: ediform@pec.it - P.IVA: 15503921007 - N. REA: RM 1595258 — Il sito internet è realizzato e interamente gestito da EdiForm Srl
                </h6>
            </div>
        </Body >
    );
}

export default Footer;