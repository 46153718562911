import React, { useState, useEffect } from 'react';

// PACKAGES
import styled from 'styled-components';

const TitleWrapper = styled.div`
    position: relative;
`;

const Title = styled.h3`
  color:var(--text2);
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const VideoWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    background: var(--background4);
    >video{
        width: 100%;
    }
`;

const InfoWrapper = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const Info = styled.div`
    margin-top: 20px;
    width: 100%;
    font-size: var(--fontL);
    color: var(--text2);
`;

const EmptyPage = styled.div`
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        >h2{
            color: var(--text3);
        }
        >i {
            font-size: 80px;
            padding-top: 20px;
            color: var(--text3);
        }
`;


const PhysicalStreaming = ({ details }) => {
    const [str, setStr] = useState();

    useEffect(() => {
        if (details.less !== undefined) {
            let newOne = [];
            const stre = details.less.PhisicalStreamingLessons;
            for (let i = 0; i < stre.length; i++) {
                if (stre[i].excluded === null) {
                    // setStr(stre);
                    newOne.push(stre[i]);
                } else {
                    const findIfExists = JSON.parse(stre[i].excluded).find(id => id === details.course.idCourse);
                    if (findIfExists) {
                        newOne.push(stre[i]);
                    }
                }
            }
            setStr(newOne);
        }
    }, [details]);

    // console.log('DETAILS', details);

    return (
        str !== undefined &&
            str.length > 0 ?
            str.map((strm, i) =>

                // details.less !== undefined &&
                //     details.less.PhisicalLessons_idPhisicalLesson_PhisicalLesson.PhisicalStreamingLessons.length > 0 ?
                //     details.less.PhisicalLessons_idPhisicalLesson_PhisicalLesson
                //         .PhisicalStreamingLessons
                //         .map((strm, i) =>
                // //ACCADEMIC YEAR TREBUIE CONFRUNTAT CU ANUL USERULUI
                // strm.accademicYear === '2022' ?
                //     <>
                //         <InfoWrapper>
                //             <Title>Titolo della lezione: {strm.name}</Title>
                //             <Info>Classe: {strm.class}</Info>
                //         </InfoWrapper>
                //         <VideoWrapper key={i}>
                //             <video
                //                 src={strm[i]}
                //                 // src={url2}
                //                 // poster={url2}
                //                 controlsList="nodownload"
                //                 preload="auto"
                //                 // preload="metadata"
                //                 // muted="muted"
                //                 type="video/mp4"
                //                 playsInline
                //                 controls
                //             />
                //         </VideoWrapper>
                //     </>
                //     : <div></div>
                <div key={i}>
                    <InfoWrapper>
                        <Title>Titolo della lezione: {strm.name}</Title>
                        <Info>Classe: {strm.class}</Info>
                    </InfoWrapper>
                    <VideoWrapper key={i}>
                        <video
                            src={strm[i]}
                            // src={url2}
                            // poster={url2}
                            controlsList="nodownload"
                            preload="auto"
                            // preload="metadata"
                            // muted="muted"
                            type="video/mp4"
                            playsInline
                            controls
                        />
                    </VideoWrapper>
                </div>
            )
            :
            <EmptyPage>
                <h2>Nessuno streaming disponibile</h2>
                <i className="fa-solid fa-film"></i>
            </EmptyPage>
    );
}

export default PhysicalStreaming;
